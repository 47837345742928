import { Q } from '@nozbe/watermelondb';
import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { CONTACTS_FILTER } from 'shared/constants/contacts/filters';
import { SORT_TYPE } from 'shared/constants/sort';
import { ContactsFiltersObject } from 'shared/types/contactsFilters';
import { SortObject } from 'shared/types/sort';

import { checkIfAnyFilterIsActive } from '@/helpers/filters';
import { Context } from '@/types/context';

const INITIAL_FILTERS_STATE: ContactsFiltersObject = {
    [CONTACTS_FILTER.invoicing]: [],
    [CONTACTS_FILTER.role]: [],
    [CONTACTS_FILTER.show_hidden]: false,
    [CONTACTS_FILTER.show_ids]: [],
};

const INITIAL_SORT_STATE: SortObject = {
    columnId: 'ContactsList:table_columns:contact',
    value: SORT_TYPE.ASC,
    query: (sort: SORT_TYPE) => Q.sortBy('first_name', sort),
};

const ContactsFiltersContext = createContext<Context.ContactsFilters.Value>({
    clearContextState: () => null,
    filtersState: INITIAL_FILTERS_STATE,
    searchState: '',
    setSearchState: () => null,
    sortState: INITIAL_SORT_STATE,
    setSortState: () => null,
    isAnyFilterActive: false,
    isFiltersPanelVisible: false,
    resetFilters: () => null,
    setFiltersState: () => null,
    setIsFiltersPanelVisible: () => null,
});

const contactsFiltersContextRef: React.MutableRefObject<Context.ContactsFilters.Value | null> =
    createRef();

const ContactsFiltersProvider = ({
    children,
}: Context.ContactsFilters.Props) => {
    const [filtersState, setFiltersState] = useState<ContactsFiltersObject>(
        INITIAL_FILTERS_STATE,
    );
    const [isFiltersPanelVisible, setIsFiltersPanelVisible] = useState(false);

    const [searchState, setSearchState] = useState<string>('');

    const [sortState, setSortState] = useState<SortObject>(INITIAL_SORT_STATE);

    const isAnyFilterActive = useMemo((): boolean => {
        return checkIfAnyFilterIsActive(filtersState);
    }, [filtersState]);

    const resetFilters = useCallback(() => {
        setFiltersState(INITIAL_FILTERS_STATE);
        setSearchState('');
        setSortState(INITIAL_SORT_STATE);
    }, []);

    const clearContextState = useCallback(() => {
        resetFilters();
    }, [resetFilters]);

    const contextValue: Context.ContactsFilters.Value = {
        clearContextState,
        filtersState,
        searchState,
        setSearchState,
        sortState,
        setSortState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        setFiltersState,
        setIsFiltersPanelVisible,
    };

    contactsFiltersContextRef.current = contextValue;

    return (
        <ContactsFiltersContext.Provider value={contextValue}>
            {children}
        </ContactsFiltersContext.Provider>
    );
};

function useContactsFiltersContext() {
    const contactsFiltersContext = useContext(ContactsFiltersContext);

    return contactsFiltersContext;
}

function clearContactsFiltersContextState() {
    return contactsFiltersContextRef.current?.clearContextState();
}

export {
    ContactsFiltersContext,
    ContactsFiltersProvider,
    useContactsFiltersContext,
    clearContactsFiltersContextState,
};
