import React from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceInvoicingProduct from 'shared/db/models/InvoiceInvoicingProduct';

import { TruncatedListSection } from '@/components';

import ProductsList from './components/ProdcuctsList';
import type { Props } from './types';

export function ProductsSection({
    currency,
    isLoading,
    subtotal,
    tax,
    taxRate,
    taxIncluded,
    products,
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            <TruncatedListSection
                ListComponent={({
                    items,
                }: {
                    items: InvoiceInvoicingProduct[];
                }) => (
                    <ProductsList
                        products={items}
                        currency={currency}
                        subtotal={subtotal}
                        tax={tax}
                        taxRate={taxRate}
                        taxIncluded={taxIncluded}
                        subtotalText={t(
                            'InvoiceSystem:invoice_summary:subtotal_products',
                        )}
                    />
                )}
                listComponentProps={{ items: products }}
                title={`${t('Generic:products')} (${products.length})`}
                isLoading={isLoading}
            />
        </>
    );
}
