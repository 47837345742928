import { OrganisationAnalytics } from '../organisation';

import { ContactsEvents } from './events';

import { LogEvent } from '../../types/analytics';
import { ContactModel } from '../../types/Contacts';
import { HorseModel } from '../../types/Horses';

function logUserAddedRole(logEvent: LogEvent, params: { role: string }) {
    logEvent(ContactsEvents.userAddedContactRole, {
        role: params.role,
    });
}

function logUserAddedHorseToContact(logEvent: LogEvent) {
    logEvent(ContactsEvents.userAddedHorse);
}

async function logUserUpdatedContact(
    logEvent: LogEvent,
    params: {
        contact: ContactModel;
        previousRole?: string | null;
        previousHorses: HorseModel[];
    },
) {
    const currentHorses = await params.contact.horses.fetch();

    logEvent(ContactsEvents.userUpdatedContact, {
        billable: params.contact.billable,
        created_at: params.contact.createdAt,
        hidden: params.contact.hidden,
        id: params.contact.id,
        role: params.contact.role,
        organisation_id: params.contact.organisationId,
        updated_at: params.contact.updatedAt,
        user_id: params.contact.userId,
    });

    const isNewHorseAdded = currentHorses.some((currentHorse) =>
        params.previousHorses.every(
            (previousHorse) => previousHorse.id !== currentHorse.id,
        ),
    );

    if (isNewHorseAdded) {
        logUserAddedHorseToContact(logEvent);
    }

    if (params.contact.role && params.contact.role !== params.previousRole) {
        logUserAddedRole(logEvent, { role: params.contact.role });
    }
}

async function logUserCreatedContact(
    logEvent: LogEvent,
    params: { contact: ContactModel; isOwner: boolean | null },
) {
    logEvent(ContactsEvents.userCreatedContact, {
        billable: params.contact.billable,
        created_at: params.contact.createdAt,
        hidden: params.contact.hidden,
        id: params.contact.id,
        role: params.contact.role,
        organisation_id: params.contact.organisationId,
        updated_at: params.contact.updatedAt,
        user_id: params.contact.userId,
    });

    if (params.contact.role) {
        logUserAddedRole(logEvent, { role: params.contact.role });
    }
    const horsesCount = await params.contact.horses.fetchCount();

    if (horsesCount > 0) {
        logUserAddedHorseToContact(logEvent);
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedContact(logEvent);
    }
}

function logUserDeletedContact(logEvent: LogEvent) {
    logEvent(ContactsEvents.userDeletedContact);
}

function logUserUsedContactsSearchBar(logEvent: LogEvent) {
    logEvent(ContactsEvents.userUsedContactsSearchBar);
}

function logUserAddedContactFromContactsBook(logEvent: LogEvent) {
    logEvent(ContactsEvents.userAddedContactFromContactsBook);
}

export const ContactsAnalytics = {
    logUserCreatedContact,
    logUserDeletedContact,
    logUserUsedContactsSearchBar,
    logUserUpdatedContact,
    logUserAddedContactFromContactsBook,
};
