import { t } from 'i18next';
import React, { useMemo } from 'react';
import { DateHeaderProps } from 'react-big-calendar';
import { monthLabel } from 'shared/constants/date';
import moment from 'shared/utils/moment';

import { COLOR } from '@/theme/colors';

function MonthDateCellHeader({ date, label }: DateHeaderProps) {
    const value = useMemo(() => {
        const value = parseInt(label, 10);
        return value;
    }, [label]);

    const firstDayOfMonthLabel = useMemo(() => {
        const month = moment(date).month();

        if (value === 1) {
            return t(monthLabel[month]);
        }
    }, [date, value]);

    const isToday = useMemo(() => {
        const now = moment();
        const formattedDate = moment(date);
        return now.isSame(formattedDate, 'day');
    }, [date]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                paddingInlineStart: '8px',
                paddingBlock: '8px',
            }}
        >
            <span
                style={{
                    textAlign: isToday ? 'center' : 'start',
                    fontSize: '15px',
                    width: '25px',
                    height: '25px',
                    fontWeight: '700',
                    lineHeight: '25px',
                    backgroundColor: isToday ? COLOR.deepCerulean : undefined,
                    borderRadius: isToday ? '50%' : undefined,
                    color: isToday ? COLOR.white : undefined,
                }}
            >
                {value}
            </span>
            <span
                style={{
                    textAlign: 'start',
                    fontSize: '15px',
                    fontWeight: '700',
                    lineHeight: '25px',
                }}
            >
                {firstDayOfMonthLabel}
            </span>
        </div>
    );
}

export default MonthDateCellHeader;
