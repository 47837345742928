import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import { TeamIcon } from '@/assets/svg';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import Tooltip from '../Tooltip';

import { Props } from './types';

function TeamInvitationBadge({ name, owner, imageURL }: Props) {
    return (
        <Box sx={{ height: '64px', display: 'flex', gap: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '64px',
                    height: '64px',
                    borderWidth: '2px',
                    borderColor: ENTITY_COLOR.teams,
                }}
                className="rounded-lg"
            >
                <Avatar
                    sx={{
                        width: '56px',
                        height: '56px',
                        borderRadius: 0.65,
                        backgroundColor: COLOR.eclipse,
                    }}
                    variant={'square'}
                    src={imageURL}
                >
                    <TeamIcon height={40} width={40} color={'grey'} />
                </Avatar>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '147px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: 400,
                        color: COLOR.gray,
                    }}
                >
                    {t('SignIn:invitation:owner_invited_you', { owner })}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 0.5,
                        mt: '2px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '15px',
                            fontWeight: 700,
                            color: '#1D1D1D',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {name}
                    </Typography>
                    <Tooltip
                        title={
                            t('SignIn:invitation:description', {
                                owner,
                                team: name,
                            }) || ''
                        }
                        placement="bottom"
                    >
                        <InfoOutlinedIcon
                            sx={{
                                width: '18px',
                                height: '18px',
                                display: 'flex',
                                alignSelf: 'center',
                                color: COLOR.bahamaBlue,
                            }}
                        />
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default TeamInvitationBadge;
