import Product from '../db/services/Product';
import {
    AddProductParams,
    ProductGroup,
    ProductModel,
    ProductQuantities,
    ProductType,
} from '../types/Products';
import { EntryProductModel } from '../types/EntryProduct';

export function getProductName(product: ProductModel) {
    const { brand, family, name } = product;

    return brand || family ? `${brand ?? ''} ${family ?? ''}` : name;
}

export function getProductMaterialShapeAndClips(product: ProductModel) {
    const { material, shape, clips } = product;

    return material || shape || clips
        ? `${material ?? ''} ${shape ?? ''} ${clips ?? ''}`.trim()
        : '';
}

export function mapEntryProducts(products: EntryProductModel[]) {
    return Promise.all(
        products.map(async (product) => {
            const [productModel] = await product.product.fetch();

            return {
                name: getProductName(productModel),
                materialShapeAndClips:
                    getProductMaterialShapeAndClips(productModel),
                productId: productModel.id,
                size: getProductSize(productModel),
                productType: productModel.productType,
                quantity: product.quantity,
            };
        }),
    );
}

export const getGroupedProducts = (
    products: ProductModel[],
): ProductModel[][] =>
    Object.values(
        products.reduce((groupedProducts, productToGroup) => {
            let group;
            if (productToGroup.productType === ProductType.custom) {
                group = productToGroup.name;
            } else {
                group = `${productToGroup.brand ?? ''}${
                    productToGroup.family ?? ''
                }${productToGroup.material ?? ''}${productToGroup.shape ?? ''}${
                    productToGroup.clips ?? ''
                }`;
            }

            return {
                ...groupedProducts,
                [group]: [...(groupedProducts[group] ?? []), productToGroup],
            };
        }, {}),
    );

export const getAllSizesGrouped = async (
    products: ProductModel[],
    productService: Product,
): Promise<ProductModel[][]> => {
    const groupedProducts = getGroupedProducts(products);

    const productGroups: ProductGroup[] = groupedProducts.map(
        (productsInGroup) => ({
            name: productsInGroup[0].name,
            brand: productsInGroup[0].brand,
            clips: productsInGroup[0].clips,
            family: productsInGroup[0].family,
            shape: productsInGroup[0].shape,
            material: productsInGroup[0].material,
        }),
    );

    const allProducts = await productService.getByGroups(productGroups);

    return getGroupedProducts(allProducts);
};

export const getAddProductsDictionary = (
    products: AddProductParams[],
): ProductQuantities => {
    return products.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.id]: {
                quantity: curr.quantity,
                unitType: curr.unitType,
            },
        };
    }, {});
};

export const getProductSize = (product: ProductModel): string => {
    let productSize = '';

    if (product.productType === ProductType.shoes) {
        productSize = product.shoeSize || '';
    }

    if (product.productType === ProductType.nails) {
        productSize = product.nailSize || '';
    }

    if (
        [ProductType.tools, ProductType.other, ProductType.rasps].includes(
            product.productType,
        )
    ) {
        productSize = product.productSize || '';
    }

    return productSize;
};
