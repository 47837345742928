import { Box } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { ContactModel } from 'shared/types/Contacts';
import { EntryModel } from 'shared/types/Entries';
import { EventModel } from 'shared/types/Events';

import {
    AddEditContactModal,
    AddEditEntryModal,
    AddEditHorseModal,
    AddEditScheduleModal,
    ContactsSection,
    EmptyState,
    EntriesSection,
    NotesSection,
    SchedulesSection,
} from '@/components';
import { ROUTE } from '@/router/routes';
import { t } from '@/services/translations/config';
import { getRoutePath } from '@/utils/router';

import { Header } from './components';
import { Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

function HorseDetails({
    horse,
    horseContacts,
    horseEntries,
    horseEvents,
    isLoading,
}: Props) {
    const navigate = useNavigate();

    const [isAddAppointmentModalOpen, setIsAddAppointmentModalOpen] =
        useState(false);
    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const [isAddEntryModalOpen, setIsAddEntryModalOpen] = useState(false);

    const openAddContactModal = useCallback(
        () => setIsAddContactModalOpen(true),
        [],
    );

    const closeAddContactModal = useCallback(
        () => setIsAddContactModalOpen(false),
        [],
    );

    const openAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(true),
        [],
    );
    const closeAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(false),
        [],
    );

    const openAddAppointmentModal = useCallback(
        () => setIsAddAppointmentModalOpen(true),
        [],
    );

    const closeAddAppointmentModal = useCallback(
        () => setIsAddAppointmentModalOpen(false),
        [],
    );

    const navigateToNewEntryPage = useCallback(
        (entry: EntryModel) => {
            const route = getRoutePath(ROUTE.entry, { id: entry.id });

            navigate(route);
        },
        [navigate],
    );

    const navigateToNewAppointmentPage = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, { id: event.id });

            navigate(route);
        },
        [navigate],
    );

    const navigateToNewContactPage = useCallback(
        (contact: ContactModel) => {
            const route = getRoutePath(ROUTE.contact, { id: contact.id });

            navigate(route);
        },
        [navigate],
    );

    if (!horse && !isLoading) {
        return <EmptyState message={t('ShowHorse:no_horse_found')} />;
    }

    return (
        <div>
            <Header horse={horse} isLoading={isLoading} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="HorsePage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <SchedulesSection
                            events={horseEvents || []}
                            isLoading={isLoading}
                            onAddButtonClick={openAddAppointmentModal}
                            showAddButton
                            testIdPrefix={'HorseSchedulesSection'}
                        />
                    </Box>

                    <Box
                        data-test-id="HorsePage-EntriesSection"
                        sx={sectionStyles}
                    >
                        <EntriesSection
                            entries={horseEntries || []}
                            isLoading={isLoading}
                            showAddButton
                            onAddButtonClick={openAddEntryModal}
                        />
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="HorsePage-ContactsSection"
                        sx={sectionStyles}
                    >
                        <ContactsSection
                            testIdPrefix={'HorseContactsSection'}
                            contacts={horseContacts || []}
                            isLoading={isLoading}
                            onAddButtonClick={openAddContactModal}
                            showAddButton
                        />
                    </Box>
                    <Box
                        data-test-id="HorsePage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <NotesSection
                            comments={horse?.comments || ''}
                            containerTestId={'HorseNotesSection'}
                            isLoading={isLoading}
                            textTestId={'HorsePage-NotesText'}
                        />
                    </Box>
                </Box>
            </Box>
            {isAddContactModalOpen ? (
                <AddEditContactModal
                    CreateHorseModal={AddEditHorseModal}
                    close={closeAddContactModal}
                    horse={horse || undefined}
                    onSave={navigateToNewContactPage}
                />
            ) : null}
            {isAddEntryModalOpen ? (
                <AddEditEntryModal
                    close={closeAddEntryModal}
                    horse={horse || undefined}
                    onSave={navigateToNewEntryPage}
                />
            ) : null}
            {isAddAppointmentModalOpen ? (
                <AddEditScheduleModal
                    contacts={horseContacts || []}
                    horses={horse ? [horse] : []}
                    onClose={closeAddAppointmentModal}
                    onSubmit={navigateToNewAppointmentPage}
                />
            ) : null}
        </div>
    );
}

const enhance = withObservables<Props, unknown>(['horse'], ({ horse }) => {
    return {
        horseContacts: horse
            ? horse.contacts.extend(
                  Q.where('hidden', false),
                  Q.sortBy('name', Q.asc),
              )
            : of([]),
        horseEntries: horse
            ? horse.entries.extend(Q.sortBy('logged_time', Q.desc))
            : of([]),
        horseEvents: horse
            ? horse.events.extend(Q.sortBy('starts_time', Q.desc))
            : of([]),
    };
});

export default enhance(HorseDetails);
