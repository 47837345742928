import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BackLinkButton } from '@/components';
import { useEventInvitationContext } from '@/context/EventInvitationContext';
import { ROUTE } from '@/router/routes';

import { SchedulesGroupedList } from '../SchedulesPage/components';

function ScheduleUpdatesPage() {
    const { t } = useTranslation();
    const { attentionNeededEvents } = useEventInvitationContext();

    return (
        <Box className="flex flex-col gap-8 relative">
            <BackLinkButton
                text={t('Appointment:button:back')}
                to={ROUTE.schedule}
                testID="AppointmentPage-BackButton"
            />

            <SchedulesGroupedList
                futureEvents={attentionNeededEvents}
                componentType="extended"
            />
        </Box>
    );
}

export default ScheduleUpdatesPage;
