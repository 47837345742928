import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { ZIndex } from '@/constants/zIndex';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ImagePreview({ close, url }: Props) {
    const stopEventFromPropagating = useCallback(
        (event) => event.stopPropagation(),
        [],
    );

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                close();
            }
        },
        [close],
    );

    const removeKeyDownEvent = useCallback(
        () => document.removeEventListener('keydown', handleKeyPress),
        [handleKeyPress],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return removeKeyDownEvent;
    }, [handleKeyPress, removeKeyDownEvent]);

    return (
        <Backdrop
            open
            sx={{
                backgroundColor: COLOR.black60,
                opacity: 1,
                zIndex: ZIndex.index10,
                color: COLOR.white,
                px: 4,
            }}
            onClick={close}
        >
            <Button
                onClick={close}
                variant="text"
                color="primary"
                size="medium"
                sx={{
                    position: 'absolute',
                    zIndex: 9,
                    borderRadius: '9999px',
                    minWidth: 0,
                    top: 0,
                    right: 0,
                    m: 4,
                    p: 1,
                    bgcolor: COLOR.paleSky02,
                }}
            >
                <CloseIcon htmlColor={COLOR.ebonyClay} sx={{ fontSize: 18 }} />
            </Button>
            <Box className="flex flex-col items-center justify-center">
                <img
                    src={url}
                    className="max-h-screen py-8"
                    onClick={stopEventFromPropagating}
                />
            </Box>
        </Backdrop>
    );
}

export default ImagePreview;
