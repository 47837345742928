// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class OrganisationUserColor extends Model {
    static table = 'organisation_user_colors';

    static associations = {
        organisation_users: {
            type: 'has_many',
            foreignKey: 'organisation_user_color_id',
        },
    };

    @field('code') code;
    @field('position') position;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
