import * as FirebaseFirestore from 'firebase/firestore';
import { DocumentData } from 'firebase/firestore';
import * as FirebaseStorage from 'firebase/storage';
import { IMAGES_DIR } from 'shared/constants/firebase/images';
import PhotoConfig from 'shared/constants/photos';
import { FirebaseImage } from 'shared/types/firebase/types';
import { Image as ImageModel } from 'shared/types/imageService';
import { Photo } from 'shared/types/Photo';
import { v4 as uuidv4 } from 'uuid';

import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import {
    FirestoreImagesDocument,
    FirestoreImagesDocumentsObject,
} from '@/types/firestore';

import Logger from '../logger';

export class FirebaseImagesService {
    constructor(
        private setInProgress: (inProgress: boolean) => void = () => null,
    ) {}

    async getImagesCollectionDocuments(): Promise<FirestoreImagesDocument[]> {
        try {
            this.setInProgress(true);
            const images =
                await FirebaseFirestoreAPI.getCollectionDocuments<FirestoreImagesDocument>(
                    FIRESTORE_COLLECTION.images,
                );
            this.setInProgress(false);
            return images;
        } catch (error) {
            Logger.logError(error);
            this.setInProgress(false);
            return [];
        }
    }

    getImagesByEntityId(
        imagesObject: FirestoreImagesDocumentsObject,
        entityId: string,
    ): FirestoreImagesDocument[] | undefined {
        return imagesObject[entityId];
    }

    getSingleImageByEntityId(
        imagesObject: FirestoreImagesDocumentsObject | null,
        entityId: string,
    ): FirestoreImagesDocument | undefined {
        if (!imagesObject) {
            return;
        }

        const [imageDoc] =
            this.getImagesByEntityId(imagesObject, entityId) || [];

        return imageDoc;
    }

    subscribeToSync(): FirebaseFirestore.Unsubscribe | undefined {
        try {
            const { onSnapshotsInSync } = FirebaseFirestore;
            const { firestoreDB } = FirebaseFirestoreAPI;

            return onSnapshotsInSync(firestoreDB, {
                error: () => this.setInProgress(false),
            });
        } catch (err) {
            Logger.logError(err);
        }
    }

    subscribeToCollection<T extends DocumentData>(
        collectionName: FIRESTORE_COLLECTION,
        onChange: (docs: T[]) => void,
    ): FirebaseFirestore.Unsubscribe | undefined {
        try {
            const { collection, onSnapshot, query } = FirebaseFirestore;
            const { collectionConverter, firestoreDB } = FirebaseFirestoreAPI;

            const dbQuery = query(
                collection(firestoreDB, collectionName).withConverter(
                    collectionConverter<T>(),
                ),
            );

            return onSnapshot(dbQuery, (querySnapshot) => {
                const documentsData: T[] = [];

                querySnapshot.forEach((doc) => {
                    documentsData.push(doc.data());
                });

                onChange(documentsData);
            });
        } catch (err) {
            Logger.logError(err);
        }
    }

    async removeByDocId(docId: string): Promise<void> {
        const { getStorage, ref, deleteObject } = FirebaseStorage;
        const { removeDocumentInCollection } = FirebaseFirestoreAPI;
        try {
            this.setInProgress(true);
            const imageRef = ref(getStorage(), `${IMAGES_DIR}/${docId}`);
            await deleteObject(imageRef);

            await removeDocumentInCollection(
                FIRESTORE_COLLECTION.images,
                docId,
            );
        } catch (error) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }

        this.setInProgress(false);
    }

    async remove(entityId: string): Promise<void> {
        if (!entityId) {
            return;
        }

        const { getCollectionDocumentsByField } = FirebaseFirestoreAPI;

        this.setInProgress(true);

        const imageDocs = await getCollectionDocumentsByField(
            FIRESTORE_COLLECTION.images,
            'entityID',
            entityId,
        );

        for (const doc of imageDocs) {
            await this.removeByDocId(doc.id);
        }

        this.setInProgress(false);
    }

    getImageElementFromDataUrl(dataURL: string): Promise<HTMLImageElement> {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.src = dataURL;
        });
    }

    async compressImage(image: Photo): Promise<Blob> {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!image.uri) {
            throw new Error();
        }

        const imageElement = await this.getImageElementFromDataUrl(image.uri);

        const originalWidth = imageElement.width;
        const originalHeight = imageElement.height;

        let newWidth = originalWidth;
        let newHeight = originalHeight;

        if (
            originalWidth > originalHeight &&
            originalWidth > PhotoConfig.COMPRESS_LARGE_DIMENSION
        ) {
            newWidth = PhotoConfig.COMPRESS_LARGE_DIMENSION;
            newHeight =
                (PhotoConfig.COMPRESS_LARGE_DIMENSION * originalHeight) /
                originalWidth;
        } else if (originalHeight > PhotoConfig.COMPRESS_LARGE_DIMENSION) {
            newWidth =
                (PhotoConfig.COMPRESS_LARGE_DIMENSION * originalWidth) /
                originalHeight;
            newHeight = PhotoConfig.COMPRESS_LARGE_DIMENSION;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        if (!context) {
            throw new Error();
        }

        context.drawImage(imageElement, 0, 0, newWidth, newHeight);

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        resolve(blob);
                        if (image.uri) {
                            URL.revokeObjectURL(image.uri);
                        }
                    } else {
                        throw new Error();
                    }
                },
                PhotoConfig.COMPRESS_FORMAT,
                PhotoConfig.COMPRESS_QUALITY,
            );
        });
    }

    async uploadImage(params: ImageModel): Promise<void> {
        try {
            const { uploadBytes, getStorage, ref, getDownloadURL } =
                FirebaseStorage;
            const {
                addDocumentToCollection,
                updateDocumentInCollection,
                getCollectionDocumentById,
            } = FirebaseFirestoreAPI;

            const {
                image,
                entityID,
                entityType,
                annotationImage = '',
                ownerID = '',
                userIDs = [],
                documentID = '',
                shouldUploadImage = true,
                organisationID = '',
            } = params;

            if (!image || !entityID || !entityType) {
                throw new Error();
            }

            this.setInProgress(true);

            let docID = documentID;
            let existingImageDoc: FirebaseImage | null;

            try {
                existingImageDoc =
                    await getCollectionDocumentById<FirebaseImage>(
                        FIRESTORE_COLLECTION.images,
                        docID,
                    );
            } catch (err) {
                docID = uuidv4();
                existingImageDoc = null;
            }

            let downloadUrl = image.uri;

            if (shouldUploadImage && existingImageDoc?.imageURL !== image.uri) {
                const compressedFile = await this.compressImage(image);

                const imageRef = ref(getStorage(), `${IMAGES_DIR}/${docID}`);

                const uploadResult = await uploadBytes(
                    imageRef,
                    compressedFile,
                );

                downloadUrl = await getDownloadURL(uploadResult.ref);
            }

            if (!existingImageDoc) {
                await addDocumentToCollection<FirebaseImage>(
                    FIRESTORE_COLLECTION.images,
                    docID,
                    {
                        createdAt: new Date(),
                        entityID,
                        entityType,
                        imageURL: downloadUrl,
                        annotationImage,
                        ownerID,
                        userIDs,
                        organisationID,
                        prio: image.prio || 0,
                        documentID: docID,
                    },
                );
            } else {
                await updateDocumentInCollection<FirebaseImage>(
                    FIRESTORE_COLLECTION.images,
                    docID,
                    { imageURL: downloadUrl },
                );
            }
        } catch (error) {
            this.setInProgress(false);
            Logger.logError(error);
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }

        this.setInProgress(false);
    }
}
