import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Box,
    Typography,
    SxProps,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStatusMessage, getSyncStatus } from 'shared/utils/sync';

import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase, useMomentFromNow, useNetwork } from '@/hooks';
import { createDatabaseDump } from '@/services/database/dumpService';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { Snackbar } from '@/services/toastNotifications';
import { getLastPulledAt } from '@/services/webStorage/localStorage/sync';
import { COLOR } from '@/theme/colors';

import StatusIcon from '../StatusIcon';

const infoIconStyles: SxProps = {
    display: 'flex',
    alignSelf: 'center',
    width: 24,
    height: 24,
    mr: 0.5,
};

const rowStyles: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 2,
};

function SyncInfoModal({ isOpen, onButtonPress, testID }) {
    const { t } = useTranslation();
    const { isSyncInProgress, hasSyncIssue, isUnsynced } = useDBSyncContext();
    const { userProfileData } = useUserContext();
    const { invalidateUserAuth } = useAuthContext();
    const isOnline = useNetwork();
    const { getDatabase } = useDatabase();

    const [isLoading, setIsLoading] = useState(false);

    const status = useMemo(
        () =>
            getSyncStatus({
                hasSyncIssue,
                isOnline,
                isSyncInProgress,
                isUnsynced,
            }),
        [hasSyncIssue, isOnline, isSyncInProgress, isUnsynced],
    );

    const [lastSyncedAt, setLastSyncedAt] = useState('');

    useEffect(() => {
        if (!isSyncInProgress) {
            setLastSyncedAt(getLastPulledAt() || Date.now().toString());
        }
    }, [isSyncInProgress]);

    const lastSyncedAtString = useMomentFromNow(+lastSyncedAt, 1000);

    const syncMessage = useMemo(() => {
        if (status === 'offline' || status === 'error') {
            return `${t('Generic:last_synced')} ${lastSyncedAtString}`;
        } else if (status === 'synced') {
            return t('SyncStatus:synced_extended');
        }

        return getStatusMessage(status, t);
    }, [lastSyncedAtString, status, t]);

    const _onButtonPress = useCallback(async () => {
        if (hasSyncIssue) {
            const database = getDatabase();

            try {
                setIsLoading(true);
                await createDatabaseDump({
                    database,
                    userID: userProfileData?.id || '',
                });

                // If there is some auth issue (you changed password on other device and token is invalid) our BE logout can throw 401 error
                // That's why we don't call our BE signOut here
                await FirebaseAuthAPI.signOut();
                setIsLoading(false);

                invalidateUserAuth();
            } catch (error) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        }

        onButtonPress();
    }, [
        getDatabase,
        hasSyncIssue,
        invalidateUserAuth,
        onButtonPress,
        t,
        userProfileData?.id,
    ]);

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-test-id={testID}
            maxWidth={'md'}
        >
            <DialogTitle
                sx={{
                    paddingTop: 5,
                    paddingLeft: 5,
                    color: status === 'error' ? COLOR.errorRed : undefined,
                    flexDirection: 'row',
                    display: 'flex',
                }}
            >
                {status === 'error' ? (
                    <>
                        <InfoOutlinedIcon sx={infoIconStyles} />
                        {t('SyncStatus:data_sync_issue')}
                    </>
                ) : (
                    t('Generic:warning')
                )}
            </DialogTitle>
            <DialogContent sx={{ paddingX: 5, paddingBottom: 5 }}>
                <DialogContentText>
                    {status === 'error'
                        ? t('SyncStatus:sync_issue')
                        : `${t('SyncStatus:tooltip:title')} ${t(
                              'SyncStatus:tooltip:message',
                          )}`}
                </DialogContentText>

                <Box sx={rowStyles}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor:
                                status === 'synced'
                                    ? COLOR.successLightGreen
                                    : undefined,
                            mr: 2,
                            padding: 0.5,
                            borderRadius: 1,
                        }}
                    >
                        <StatusIcon status={status} size={24} />
                        <Typography sx={{ ml: 0.5 }}>{syncMessage}</Typography>
                    </Box>

                    <Button
                        sx={{
                            backgroundColor:
                                status === 'error' ? COLOR.errorRed : undefined,
                        }}
                        variant="contained"
                        onClick={_onButtonPress}
                        autoFocus
                        data-test-id={`${testID}-Understood`}
                        disabled={isLoading}
                    >
                        {status === 'error'
                            ? t('SyncStatus:create_backup_and_proceed')
                            : t('Generic:understood')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SyncInfoModal;
