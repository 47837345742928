import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, SxProps, Tooltip, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { ProductImages } from 'shared/constants/products/images';
import { getProductSize } from 'shared/utils/product';

import { useAddProductsContext } from '@/context/AddProductsContext';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { ProductIcon, ProductSizeItem } from './components';
import { Props } from './types';

const alignSelfStart: SxProps = { alignSelf: 'flex-start', mt: 0.5 };
const sizeCaptions: SxProps = {
    fontWeight: 400,
    fontSize: 14,
    color: COLOR.paleSky,
};

function ProductItem({
    type,
    title,
    subtitle,
    icon,
    productGroup,
    style,
    isOpen,
    onClick,
    testIdPrefix,
    showUnitTypes,
}: Props) {
    const { productQuantities, incrementQuantity, handleQuantityChange } =
        useAddProductsContext();

    const isSelected = useMemo(
        () =>
            productGroup.some(
                (product) =>
                    productQuantities[product.id]?.quantity &&
                    parseInt(productQuantities[product.id].quantity, 10) > 0,
            ),
        [productGroup, productQuantities],
    );

    const handleProductSizeIconClick = useCallback(
        (productId: string) => {
            const productQuantity = productQuantities[productId]?.quantity
                ? parseInt(productQuantities[productId]?.quantity, 10)
                : 0;

            if (productQuantity === 0) {
                incrementQuantity(productId);
            } else if (productQuantity > 0) {
                handleQuantityChange(productId, '0');
            }
        },
        [handleQuantityChange, incrementQuantity, productQuantities],
    );

    const selectedProductsIds = useMemo(() => {
        const keys = Object.keys(productQuantities);

        return keys.filter(
            (key) =>
                productQuantities[key]?.quantity &&
                parseInt(productQuantities[key].quantity, 10) > 0,
        );
    }, [productQuantities]);

    const handleIconClick = useCallback(() => {
        onClick();
        if (productGroup.length === 1) {
            const [product] = productGroup;
            handleProductSizeIconClick(product.id);
        } else {
            const products = productGroup.filter((product) =>
                selectedProductsIds.some(
                    (selectedProductId) => selectedProductId === product.id,
                ),
            );

            products.forEach((product) =>
                handleProductSizeIconClick(product.id),
            );
        }
    }, [
        handleProductSizeIconClick,
        onClick,
        productGroup,
        selectedProductsIds,
    ]);

    return (
        <Box
            data-test-id={`${testIdPrefix}-${title}${subtitle ?? ''}`.trim()}
            sx={{ pl: 4, pr: 6, ...style }}
        >
            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    mt: 2,
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    data-test-id={`${title}${subtitle ?? ''}-collapseHeader`}
                    sx={{
                        display: 'flex',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={onClick}
                >
                    <img className="w-14 h-14 mr-2" src={ProductImages[type]} />
                    <Box sx={{ mt: 0.5 }}>
                        <Tooltip title={title}>
                            <Typography
                                fontSize={16}
                                fontWeight={700}
                                noWrap={true}
                                maxWidth={showUnitTypes ? 400 : 250}
                                data-test-id="ProductItem-Title"
                            >
                                {title}
                            </Typography>
                        </Tooltip>
                        <Typography
                            fontSize={14}
                            fontWeight={400}
                            color={COLOR.ebonyClay}
                            data-test-id="ProductItem-Subtitle"
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                    {isOpen ? (
                        <ExpandLess sx={alignSelfStart} />
                    ) : (
                        <ExpandMore sx={alignSelfStart} />
                    )}
                </Box>
                {!isOpen && (
                    <ProductIcon
                        sx={{
                            mt: 1,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={handleIconClick}
                        defaultIcon={icon}
                        selected={isSelected}
                    />
                )}
            </Box>
            <Collapse in={isOpen}>
                {/* Condition below is very important, because ProductSizeItem is a such heavy component
                 and scrolling collapsed ProductItems in list is very laggy without it */}
                {isOpen ? (
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                my: 2,
                            }}
                        >
                            <Typography sx={{ mr: 15, ...sizeCaptions }}>
                                {t('Inventory:size')}
                            </Typography>
                            <Typography sx={sizeCaptions}>
                                {showUnitTypes
                                    ? t('Inventory:quantity')
                                    : t('Inventory:pieces')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}
                        >
                            {productGroup.map((product) => {
                                const productSize = getProductSize(product);

                                return (
                                    <ProductSizeItem
                                        testID={`${title}${subtitle ?? ''}-${
                                            productSize || 'oneSize'
                                        }`}
                                        key={product.id}
                                        icon={icon}
                                        size={productSize || '-'}
                                        product={product}
                                        showUnitTypes={showUnitTypes}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                ) : null}
            </Collapse>
        </Box>
    );
}

export default memo(ProductItem);
