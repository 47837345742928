import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SelectedProductSizesTableItem from './components/SelectedProductSizesTableItem';
import { Props } from './types';

function SelectedProductSizesTable({
    productSizes,
    onQuantityChange,
    onUnitTypeChange,
    type,
    renderCustomColumn,
    testIdPrefix,
    isShoppingListProducts,
    showInventory,
    addedToInventory,
    boxUnitSize,
}: Props) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                '& > th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell width="14%">
                                <Box>
                                    <Typography>
                                        {t('Inventory:product_sizes:size')}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box>
                                    <Typography>
                                        {t('Inventory:product_sizes:amount')}
                                    </Typography>
                                </Box>
                            </TableCell>
                            {isShoppingListProducts ? (
                                <>
                                    <TableCell />
                                    <TableCell>
                                        <Typography>
                                            {t('ShoppingList:table:unit')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {t('ShoppingList:table:pieces')}
                                        </Typography>
                                    </TableCell>
                                    {showInventory ? (
                                        <TableCell align="right">
                                            {t('ShoppingList:table:inventory')}
                                        </TableCell>
                                    ) : null}
                                </>
                            ) : null}
                            {renderCustomColumn ? <TableCell /> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productSizes.map((productSize) => (
                            <SelectedProductSizesTableItem
                                key={productSize.productId}
                                productSize={productSize}
                                showInventory={showInventory}
                                onQuantityChange={onQuantityChange}
                                onUnitTypeChange={onUnitTypeChange}
                                type={type}
                                renderCustomColumn={renderCustomColumn}
                                testIdPrefix={testIdPrefix}
                                isShoppingListProducts={isShoppingListProducts}
                                addedToInventory={addedToInventory}
                                boxUnitSize={boxUnitSize}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default memo(SelectedProductSizesTable);
