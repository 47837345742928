import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarLinksList } from '@/components';

import { MoreMenu } from './components';
import { Props } from './types';

function TopPartSection({ visibleLinks, hiddenLinks }: Props) {
    const { pathname: currentPathname } = useLocation();

    return (
        <Box sx={{ mb: 6 }}>
            <SidebarLinksList
                currentPathname={currentPathname}
                links={visibleLinks}
            />
            <MoreMenu
                hiddenLinks={hiddenLinks}
                currentPathname={currentPathname}
            />
        </Box>
    );
}

export default TopPartSection;
