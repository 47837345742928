import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Entry from 'shared/db/services/Entry';
import { EntryModel } from 'shared/types/Entries';
import { EntryQueryOptions } from 'shared/types/entriesFilters';
import { FILTER_TYPE } from 'shared/types/filter';

import EntriesList from '@/components/EntriesList';
import SelectItemsModal from '@/components/SelectItemsModal';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { Props as EntriesListProps } from '../../../EntriesList/types';

import { Props } from './types';

function SelectEntriesModal({
    close,
    isOpen,
    onSelectEntries,
    selectedEntries,
    multiSelect,
    selectCondition,
    conditionWarning,
    entriesToSelect,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const fetchEntries = useCallback(
        async (query = '') => {
            const entriesService = new Entry({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const options: EntryQueryOptions = {
                filterType: FILTER_TYPE.LOKI,
                searchText: query,
                invoiceOrder: true,
            };

            if (entriesToSelect) {
                const [queries, sortQuery] =
                    entriesService.getEntriesFilterQuery(options);

                return entriesToSelect
                    .extend(...queries)
                    .extend(sortQuery)
                    .fetch();
            }

            return await entriesService.getFilteredEntries(options);
        },
        [ImagesService, database, entriesToSelect],
    );

    return (
        <SelectItemsModal<EntryModel, EntriesListProps>
            fetchItemsFn={fetchEntries}
            selectedItems={selectedEntries}
            isOpen={isOpen}
            onSelectItems={onSelectEntries}
            close={close}
            emptyMessage={t('EntriesSelection:blankslate')}
            noItemsFoundMessage={t(
                'EntriesSelection:no_entry_found_blankslate',
            )}
            selectedItemsCountTextKey="EntriesSelection:selected_entries_number"
            addSelectedItemsButtonText={t(
                multiSelect
                    ? 'EntriesSelection:add_selected_entries'
                    : 'EntriesSelection:add_selected_entry',
            )}
            testIdPrefix="SelectEntriesModal"
            title={t(
                `EntriesSelection:${multiSelect ? 'select' : 'single_select'}`,
            )}
            ListComponent={EntriesList}
            listComponentProps={{
                testIdPrefix: 'SelectEntriesModal',
                selectable: true,
                itemWrapperStyle: 'w-full mb-4 h-[74px]',
                size: 'large',
                multiSelect,
                selectCondition,
                conditionWarning,
            }}
            multiSelect={multiSelect}
        />
    );
}

export default SelectEntriesModal;
