import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function Badge({
    backgroundColor = COLOR.errorRed,
    count,
    textColor = COLOR.white,
    isCircle = false,
    fontSize,
    sx,
}: Props) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundColor,
                borderRadius: isCircle ? '9999px' : 1.1,
                display: 'flex',
                justifyContent: 'center',
                px: isCircle ? 0 : 1,
                ...sx,
            }}
        >
            {count ? (
                <Typography
                    sx={fontSize ? { fontSize } : undefined}
                    color={textColor}
                    variant="subtitle2"
                >
                    {count}
                </Typography>
            ) : null}
        </Box>
    );
}

export default Badge;
