import React, { memo } from 'react';

import { Props } from './types';

function EnvInfoRibbon({ envName }: Props) {
    const bgColor =
        envName.toUpperCase() === 'STAGING' ? 'bg-green-700' : 'bg-blue-500';

    return (
        <div
            className={`${bgColor} fixed bottom-9 -right-24 w-72 text-center text-white font-bold`}
            style={{ transform: 'rotate(315deg)' }}
        >
            {envName.toUpperCase()}
        </div>
    );
}

export default memo(EnvInfoRibbon);
