// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, readonly, lazy } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Product extends Model {
    static table = 'products';

    static associations = {
        entry_products: { type: 'has_many', foreignKey: 'product_id' },
        inventory_products: { type: 'has_many', foreignKey: 'product_id' },
        product_sales_units: { type: 'has_many', foreignKey: 'product_id' },
    };

    @lazy
    productSalesUnits = this.collections
        .get('product_sales_units')
        .query(Q.where('product_id', this.id));

    @field('name') name;
    @field('product_type') productType;
    @field('brand') brand;
    @field('family') family;
    @field('shape') shape;
    @field('dimensions') dimensions;
    @field('clips') clips;
    @field('shoe_size') shoeSize;
    @field('nail_size') nailSize;
    @field('product_size') productSize;
    @field('sku_apac') skuApac;
    @field('sku_emea') skuEmea;
    @field('uom_dealers') uomDealers;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @field('material') material;
    @field('box_unit_size') boxUnitSize;
    @field('shoe_size_sort_num') shoeSizeSortNum;
    @field('not_available') notAvailable;
}
