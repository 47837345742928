import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useCallback, useMemo } from 'react';

import { Props } from './types';

const baseIconStyles: SxProps<Theme> = {
    transform: 'rotate(180deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
};

function RotativeExpandMoreIcon({ isExpanded, height, width, color }: Props) {
    const getUpdatedIconStyles = useCallback<() => SxProps<Theme>>(
        () =>
            isExpanded
                ? baseIconStyles
                : { ...baseIconStyles, transform: 'rotate(0deg)' },
        [isExpanded],
    );

    const iconStyles: SxProps<Theme> = useMemo(getUpdatedIconStyles, [
        getUpdatedIconStyles,
    ]);

    return (
        <ExpandMoreIcon
            sx={iconStyles}
            width={width}
            height={height}
            htmlColor={color}
        />
    );
}

export default RotativeExpandMoreIcon;
