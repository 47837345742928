import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
    CollapsibleContainer,
    ConnectProviderWarningModal,
} from '@/components';
import { useInvoicingSystemContext } from '@/context/InvoicingSystemContext';
import { ROUTE } from '@/router/routes';

const buttonStyles = {
    alignSelf: 'center',
    minWidth: 175,
};

function InvoicingAndAdministrationSection() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { isInvoicingAvailable } = useInvoicingSystemContext();

    const [isProviderWarningModalVisible, setProviderModalWarningVisible] =
        useState(false);

    const handleConnectToProviderClick = () => {
        isInvoicingAvailable
            ? navigate(ROUTE.accountingProviders)
            : setProviderModalWarningVisible(true);
    };

    const handleProceedClick = () => {
        setProviderModalWarningVisible(false);
        navigate(ROUTE.accountingProviders);
    };

    const handleCancelClick = () => {
        setProviderModalWarningVisible(false);
    };

    return (
        <>
            <CollapsibleContainer
                title={t('Options:button:invoicing_administration')}
                testID="SettingsPage-InvoicingAndAdministration"
            >
                <Alert severity="info" sx={{ mb: 3 }}>
                    {t('Settings:invoices_admin:heading_explanation')}
                </Alert>
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            flex: 2,
                        }}
                    >
                        <Typography fontWeight="bold" fontSize={14}>
                            {t(
                                'Settings:invoices_admin:configure_procedures_header',
                            )}
                        </Typography>
                        <Typography fontSize={14}>
                            {t(
                                'Settings:invoices_admin:configure_procedures_explanation',
                            )}
                        </Typography>
                    </Box>
                    <Button
                        component={Link}
                        to={ROUTE.procedures}
                        variant="contained"
                        sx={buttonStyles}
                        data-test-id="InvoicingAndAdministrationSection-ConfigureProceduresButton"
                    >
                        {t(
                            'Settings:invoices_admin:configure_procedures_action',
                        )}
                    </Button>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            flex: 2,
                        }}
                    >
                        <Typography fontWeight="bold" fontSize={14}>
                            {t(
                                'Settings:invoices_admin:connect_provider_header',
                            )}
                        </Typography>
                        <Typography fontSize={14}>
                            {t(
                                'Settings:invoices_admin:connect_provider_explanation_xero_only',
                            )}
                        </Typography>
                    </Box>
                    <Button
                        onClick={handleConnectToProviderClick}
                        variant="contained"
                        sx={buttonStyles}
                        data-test-id="InvoicingAndAdministrationSection-ConnectToProviderButton"
                    >
                        {t('Settings:invoices_admin:connect_provider_action')}
                    </Button>
                </Box>
            </CollapsibleContainer>
            {isProviderWarningModalVisible ? (
                <ConnectProviderWarningModal
                    onCancel={handleCancelClick}
                    onProceed={handleProceedClick}
                />
            ) : null}
        </>
    );
}

export default InvoicingAndAdministrationSection;
