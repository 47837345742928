import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SelectableController<T>({
    items,
    selectedItems,
    actionLabel,
    onActionPerformed,
    close,
    onSelectAll,
}: Props<T>) {
    const currentLength = selectedItems.length;
    const totalLength = items.length;

    const onCancel = useCallback(() => close(), [close]);

    const handleOnSelectAll = useCallback(
        () => onSelectAll(items),
        [items, onSelectAll],
    );

    return (
        <div className="flex flex-row justify-between items-center px-3.5 py-2 bg-whiteSmoke rounded-sm">
            <div>
                <Button
                    variant="text"
                    disableRipple
                    sx={{
                        p: 0,
                        '&:hover': { backgroundColor: COLOR.whiteSmoke },
                    }}
                    onClick={handleOnSelectAll}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24.5px',
                            color: COLOR.deepCerulean,
                        }}
                    >
                        {t(
                            currentLength === totalLength
                                ? 'SelectableController:labels:unselect_all'
                                : 'SelectableController:labels:select_all',
                        )}
                    </Typography>
                </Button>
            </div>
            <div className="flex flex-row justify-end items-center gap-7">
                <Button
                    variant="text"
                    disableRipple
                    disabled={!currentLength}
                    sx={{
                        p: 0,
                        '&:hover': { backgroundColor: COLOR.whiteSmoke },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24.5px',
                            color: currentLength
                                ? COLOR.black
                                : COLOR.anotherGrey,
                        }}
                    >
                        {t('SelectableController:labels:count_label', {
                            number: currentLength,
                            total: totalLength,
                        })}
                    </Typography>
                </Button>
                <Button
                    variant="text"
                    disableRipple
                    sx={{
                        p: 0,
                        '&:hover': { backgroundColor: COLOR.whiteSmoke },
                    }}
                    onClick={onCancel}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24.5px',
                            color: COLOR.errorRed,
                        }}
                    >
                        {t('SelectableController:labels:cancel')}
                    </Typography>
                </Button>
                <Button
                    variant="text"
                    disableRipple
                    disabled={!currentLength}
                    sx={{
                        p: 0,
                        '&:hover': { backgroundColor: COLOR.whiteSmoke },
                    }}
                    onClick={onActionPerformed}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24.5px',
                            color: currentLength
                                ? COLOR.deepCerulean
                                : COLOR.anotherGrey,
                        }}
                    >
                        {actionLabel}
                    </Typography>
                </Button>
            </div>
        </div>
    );
}

export default SelectableController;
