// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class User extends Model {
    static table = 'users';

    static associations = {
        entry_users: { type: 'has_many', foreignKey: 'user_id' },
        event_users: { type: 'has_many', foreignKey: 'user_id' },
        user_answers: { type: 'has_many', foreignKey: 'user_id' },
        horses: { type: 'has_many', foreignKey: 'user_id' },
        organisation_users: { type: 'has_many', foreignKey: 'user_id' },
        inventoryChanges: { type: 'has_many', foreignKey: 'user_id' },
        locations: { type: 'belongs_to', key: 'location_id' },
    };

    @field('email') email;
    @field('password_digest') passwordDigest;
    @field('reset_password_token') resetPasswordToken;
    @field('api_refresh_token') apiRefreshToken;
    @field('accounting_disconnect_message') accountingDisconnectMessage;
    @field('region') region;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @field('first_name') firstName;
    @field('last_name') lastName;

    @field('company_name') companyName;
    @field('phone') phone;
    @field('phone_prefix') phonePrefix;
    @field('accounting_provider') accountingProvider;
    @field('address') address;
    @field('location_id') locationID;
    @field('trace_id') traceID;
    @field('profiling') profiling;
    @field('sales_account_code') salesAccountCode;
    @field('lock_accounting_provider') lockAccountingProvider;
    @relation('locations', 'location_id') locations;
    @field('menu_order') menuOrder;
    @field('freshchat_restore_id') freshchatRestoreId;
    @field('is_team_created') isTeamCreated;
    @field('timezone') timezone;
    @field('is_procedures_account_setup_set') isProceduresAccountSetupSet;
    @field('is_invoicing_account_setup_set') isInvoicingAccountSetupSet;
    @field('is_contacts_setup') isContactsSetup;
    @field('is_appointment_onboarded') isAppointmentOnboarded;

    // @deprecated: This field was created to display the onboarding screen for contact notificaiton
    // but it's not used anymore. We cannot remove it on WatermelonDB side as it's not supported in the migrations
    @field('is_appointment_contact_selection_onboarded')
    isAppointmentContactSelectionOnboarded;
    @field('is_entry_creation_from_event_enabled')
    isEntryCreationFromEventEnabled;
    // unix timestamp
    @field('terms_accepted_at') termsAcceptedAt;
    // unix timestamp
    @field('terms_created_at') termsCreatedAt;
    @field('ignore') isInternal;
    @field('is_plus_button_onboarded') isPlusButtonOnboarded;
}
