import HardwareIcon from '@mui/icons-material/Hardware';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarEventIcon } from '@/assets/svg';
import { Button, Modal } from '@/components';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function AppointmentOnboardingModal({ onContinue }: Props) {
    const { t } = useTranslation();

    return (
        <Modal isOpen center hideBackdrop styles="bg-transparent shadow-none">
            <Box
                sx={{
                    px: 5.375,
                    mx: 5,
                    pt: 5.375,
                    pb: 3.375,
                    bgcolor: COLOR.white,
                    borderRadius: '1rem',
                    boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography fontWeight="bold" fontSize={22} sx={{ mb: 1.5 }}>
                    {t('Event:onboarding:title')}
                </Typography>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: t('Event:onboarding:description'),
                    }}
                    sx={{ mb: 3.375 }}
                />
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            gap: 1.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1.25,
                                alignItems: 'center',
                            }}
                        >
                            <HardwareIcon
                                width={21}
                                height={23}
                                htmlColor={ENTITY_COLOR.schedule}
                            />
                            <Typography
                                fontSize={16}
                                fontWeight="bold"
                                color={ENTITY_COLOR.schedule}
                            >
                                {t('Event:onboarding:service:title')}
                            </Typography>
                        </Box>
                        <span className="font-light">
                            {t('Event:onboarding:service:description')}
                        </span>
                    </Box>
                    <Box sx={{ position: 'relative', px: 3.5 }}>
                        <Box
                            sx={{
                                width: '1px',
                                bgcolor: ENTITY_COLOR.schedule,
                                mt: 0.25,
                                mb: 1,
                                height: 'calc(100% - 20px);',
                                position: 'absolute',
                                top: 5,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            gap: 1.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1.25,
                                alignItems: 'center',
                            }}
                        >
                            <CalendarEventIcon
                                width={26}
                                height={28}
                                color={ENTITY_COLOR.schedule}
                            />
                            <Typography
                                fontSize={16}
                                fontWeight="bold"
                                color={ENTITY_COLOR.schedule}
                            >
                                {t('Event:onboarding:standard:title')}
                            </Typography>
                        </Box>
                        <span className="font-light">
                            {t('Event:onboarding:standard:description')}
                        </span>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 4,
                    }}
                >
                    <Button
                        caption={t('Event:onboarding:continue')}
                        onClick={onContinue}
                        variant="contained"
                        sx={{
                            bgcolor: ENTITY_COLOR.schedule,
                            color: COLOR.white,
                            '&:hover': {
                                bgcolor: ENTITY_COLOR.schedule,
                                filter: 'brightness(1.1)',
                            },
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default AppointmentOnboardingModal;
