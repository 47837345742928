import React from 'react';

import { ExportFormatsListItem } from './components';
import { Props } from './types';

function ExportFormatsList({
    formats,
    onFormatItemClick,
    selectedFormat,
}: Props) {
    return (
        <>
            {formats.map((format) => (
                <ExportFormatsListItem
                    format={format}
                    key={format.type}
                    onClick={onFormatItemClick}
                    selectedFormat={selectedFormat}
                />
            ))}
        </>
    );
}

export default ExportFormatsList;
