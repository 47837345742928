import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import { LocationModel, LocationPayload } from '../../types/Location';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class Location {
    private database: Database;
    private collection: Collection<LocationModel>;
    private table = 'locations';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: LocationPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdLocation = await this.collection.create((location) => {
                location.placeID = payload.placeID;
                location.name = payload.name;
                location.lat = `${payload.lat}`;
                location.lng = `${payload.lng}`;
                location.url = payload.url;
                location.utcOffset = payload.utcOffset;
                location.userId = userId;
                location.organisationId = organisationID;
            });

            this.options.logDBAction({
                message: 'Create location',
                modelName: this.table,
                payload: createdLocation,
            });
        });
    }

    async addUnique(payload: LocationPayload | undefined, userId: string) {
        if (!payload?.placeID) return;

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        // do not add the location if already exists
        const existingLocation = await this.getByParam(
            'place_id',
            payload.placeID,
        );
        if (existingLocation?.length > 0) return existingLocation[0];

        let locationElement: LocationModel | undefined = undefined;

        await this.database.write(async () => {
            locationElement = await this.collection.create((location) => {
                location.placeID = payload.placeID;
                location.name = payload.name;
                location.lat = `${payload.lat}`;
                location.lng = `${payload.lng}`;
                location.url = payload.url;
                location.utcOffset = payload.utcOffset;
                location.userId = userId;
                location.organisationId = organisationID;
            });

            this.options.logDBAction({
                message: 'Created unique location',
                modelName: this.table,
                payload: locationElement,
            });
        });

        return locationElement;
    }

    async update(id: string, payload: LocationPayload) {
        const locationElement = await this.getByID(id);

        await this.database.write(async () => {
            await locationElement.update((location) => {
                location.placeID = payload.placeID;
                location.name = payload.name;
                location.lat = `${payload.lat}`;
                location.lng = `${payload.lng}`;
                location.url = payload.url;
                location.utcOffset = payload.utcOffset;
                location.userId = locationElement.userId;
                location.organisationId = locationElement.organisationId;
            });

            this.options.logDBAction({
                message: 'Update location',
                modelName: this.table,
                payload: locationElement,
            });
        });
    }

    async deleteByID(id: string) {
        const locationElement = await this.getByID(id);

        await this.database.write(async () => {
            await locationElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete location',
                modelName: this.table,
                payload: locationElement,
            });
        });
    }
}

export default Location;
