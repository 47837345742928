import { Box } from '@mui/material';
import React from 'react';

import { InvoiceItemsFinancialSummary } from '@/components';

import ProductsListItem from '../ProductsListItem/';

import type { Props } from './types';

export function ProductsList({
    products,
    currency,
    subtotal,
    tax,
    taxRate,
    taxIncluded,
    subtotalText,
}: Props) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: '12px',
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                {products.map((item) => (
                    <ProductsListItem
                        currency={currency}
                        key={item.id}
                        product={item}
                    />
                ))}
            </Box>
            <InvoiceItemsFinancialSummary
                subtotal={subtotal}
                tax={tax}
                taxRate={taxRate}
                subtotalText={subtotalText}
                currency={currency}
                taxIncluded={taxIncluded}
            />
        </>
    );
}
