import { Box, Typography } from '@mui/material';
import React from 'react';

import { Props } from './types';

function SectionHeader({ title }: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24);',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'flex', gap: 2, mb: '11px', ml: '20px' }}>
                <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
}

export default SectionHeader;
