import { LogEvent } from '../../types/analytics';
import { ProcedureModel } from '../../types/Procedures';

import { ProceduresEvents } from './events';

function logUserCreatedProcedureFromSettings(
    logEvent: LogEvent,
    params: { procedure: ProcedureModel },
) {
    const eventParams = {
        category: params.procedure.category,
        name: params.procedure.name,
        price: params.procedure.price,
    };

    logEvent(ProceduresEvents.userCreatedProcedureFromSettings, {
        ...eventParams,
    });
}

export const ProceduresAnalytics = {
    logUserCreatedProcedureFromSettings,
};
