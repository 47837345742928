import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function AccountSetupModal({ item, onCancel }: Props) {
    const navigate = useNavigate();

    const handlePositiveActionClick = useCallback(() => {
        navigate(item.navigationPath);
    }, [item.navigationPath, navigate]);

    return (
        <Modal
            open
            sx={{
                overflowY: 'scroll',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Fade in timeout={400}>
                <Box
                    sx={{
                        '&:focus': {
                            outline: 'none',
                        },
                        overflow: 'hidden',
                        backgroundColor: COLOR.white,
                        borderRadius: '8px',
                        boxShadow: '3px 3px 20px #0000005D',
                        width: '350px',
                        height: '480px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Box className="p-3 flex flex-col gap-3 items-center">
                        <Box
                            sx={{
                                mt: 3,
                                width: 70,
                                height: 70,
                                background: `linear-gradient(to left top, ${item.iconPrimaryColor}, ${item.iconSecondaryColor})`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 0.7,
                            }}
                        >
                            <item.icon
                                color={COLOR.white}
                                width={50}
                                height={50}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: COLOR.accountSetupBlack,
                                textAlign: 'center',
                            }}
                        >
                            {t(item.title)}
                        </Typography>
                        <Typography
                            sx={{
                                mt: 2,
                                px: 2,
                                fontSize: 12,
                                fontWeight: 300,
                                color: COLOR.accountSetupBlack,
                                textAlign: 'center',
                            }}
                        >
                            {t(item.description)}
                        </Typography>
                        <Typography
                            sx={{
                                mt: 2,
                                px: 2,
                                fontSize: 12,
                                fontWeight: 300,
                                color: COLOR.accountSetupBlack,
                                textAlign: 'center',
                            }}
                        >
                            {t('Dashboard:account_setups:disclaimer')}
                        </Typography>
                    </Box>
                    <Box className="p-3 w-full flex flex-col gap-3 items-stretch">
                        <Button
                            sx={{
                                backgroundColor: item.iconPrimaryColor,
                                color: COLOR.white,
                                '&:hover': {
                                    backgroundColor: item.iconPrimaryColor,
                                    color: COLOR.white,
                                },
                            }}
                            onClick={handlePositiveActionClick}
                            variant="contained"
                            type="button"
                        >
                            {t(item.positiveAction)}
                        </Button>
                        <Button
                            sx={{
                                color: item.iconPrimaryColor,
                                borderColor: item.iconPrimaryColor,
                                '&:hover': {
                                    borderColor: item.iconPrimaryColor,
                                    color: item.iconPrimaryColor,
                                    backgroundColor: COLOR.white,
                                },
                            }}
                            onClick={onCancel}
                            variant="outlined"
                            type="button"
                        >
                            {t(item.negativeAction)}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AccountSetupModal;
