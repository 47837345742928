import * as yup from 'yup';

import { t } from '@/services/translations/config';

const addShoppingListForm = yup.object().shape({
    title: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('ShoppingListsPage:fields:title'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('ShoppingListsPage:fields:title'),
            }),
        ),
});

const addShoppingListOptionalForm = yup.object().shape({
    createNewShoppingList: yup.boolean(),
    title: yup.string().when('createNewShoppingList', {
        is: true,
        then: yup
            .string()
            .min(
                2,
                t('SignUp:error:field_must_contain_at_least_2_characters', {
                    field: t('ShoppingListsPage:fields:title'),
                }),
            )
            .required(
                t('SignUp:error:field_required', {
                    field: t('ShoppingListsPage:fields:title'),
                }),
            ),
    }),
});

export const ShoppingListValidationSchema = {
    addShoppingListForm,
    addShoppingListOptionalForm,
};
