import {
    styled,
    Tooltip as MUITooltip,
    tooltipClasses,
    TooltipProps,
} from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

const Tooltip = styled(
    ({ className, children, placement = 'top', ...props }: TooltipProps) => {
        return (
            <MUITooltip
                classes={{ popper: className }}
                data-test-id="Tooltip-test-id"
                placement={placement}
                {...props}
            >
                {children}
            </MUITooltip>
        );
    },
)(() => ({
    // Adjusts tooltip element styles
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: COLOR.white,
        boxShadow: '0px 1px 4px 2px rgba(181,181,181,0.3)',
        padding: '12px 14px',
        color: COLOR.scorpion,
        fontSize: 14,
    },
}));

export default Tooltip;
