export enum ENTRY_TITLE {
    service = 'service',
    lost_shoe = 'lost_shoe',
    emergency = 'emergency',
    vet_consult = 'vet_consult',
    assess_horse = 'assess_horse',
    full_set = 'full_set',
    half_set_front = 'half_set_front',
    half_set_hinds = 'half_set_hinds',
    full_trim = 'full_trim',
    trim_front = 'trim_front',
    trim_hind = 'trim_hind',
    reset_remove = 'reset_remove',
    other = 'other',
}

export const ENTRIES_TITLES_DICTIONARY: Record<ENTRY_TITLE, string> = {
    [ENTRY_TITLE.service]: 'Entry:titles:service',
    [ENTRY_TITLE.lost_shoe]: 'Entry:titles:lost_shoe',
    [ENTRY_TITLE.emergency]: 'Entry:titles:emergency',
    [ENTRY_TITLE.vet_consult]: 'Entry:titles:vet_consult',
    [ENTRY_TITLE.assess_horse]: 'Entry:titles:assess_horse',
    [ENTRY_TITLE.full_set]: 'Entry:titles:full_set',
    [ENTRY_TITLE.half_set_front]: 'Entry:titles:half_set_front',
    [ENTRY_TITLE.half_set_hinds]: 'Entry:titles:half_set_hinds',
    [ENTRY_TITLE.full_trim]: 'Entry:titles:full_trim',
    [ENTRY_TITLE.trim_front]: 'Entry:titles:trim_front',
    [ENTRY_TITLE.trim_hind]: 'Entry:titles:trim_hind',
    [ENTRY_TITLE.reset_remove]: 'Entry:titles:reset_remove',
    [ENTRY_TITLE.other]: 'Entry:titles:other',
};
