import { Box, Tab as MUITab, Tabs as MUITabs } from '@mui/material';
import React, {
    useCallback,
    useLayoutEffect,
    useMemo, useRef,
    useState,
} from 'react';

import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function Tabs({
    sx,
    tabs,
    onTabChange,
    activeColor,
    initialTabValue,
    onTabsLayout,
}: Props) {
    const tabsRefs = useRef<(HTMLElement | null)[]>([]);

    const initialTab = useMemo(
        () => tabs.findIndex((tab) => tab.value === initialTabValue),
        [initialTabValue, tabs],
    );

    const [selectedTab, setSelectedTab] = useState(
        initialTab >= 0 ? initialTab : 0,
    );

    const handleTabChange = useCallback(
        (_, tab: number) => {
            setSelectedTab(tab);
            onTabChange && onTabChange(tabs[tab].value, tab);
        },
        [onTabChange, tabs],
    );

    useLayoutEffect(() => {
        if (onTabsLayout) {
            const layout = tabsRefs.current.reduce(
                (previousLayout, currentLayout) => ({
                    width:
                        (currentLayout?.clientWidth ?? 0) +
                        previousLayout.width,
                    height:
                        (currentLayout?.clientHeight ?? 0) +
                        previousLayout.height,
                }),
                { width: 0, height: 0 },
            );

            onTabsLayout(layout);
        }
    }, [onTabsLayout]);

    return (
        <Box
            sx={{
                borderBottom: 2,
                borderColor: COLOR.silver,
                ...sx,
            }}
        >
            <MUITabs
                value={selectedTab}
                aria-label="appointment type tabs"
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: activeColor,
                        height: '5px',
                    },
                }}
                sx={{ mb: '-3px' }}
                onChange={handleTabChange}
            >
                {tabs.map(({ label, value, icon }, index) => (
                    <MUITab
                        sx={{
                            fontSize: 15,
                            minHeight: 0,
                            padding: 1.5,
                            gap: 0.75,
                            lineHeight: '19px',
                        }}
                        style={{
                            color: activeColor ? ENTITY_COLOR.schedule : 'auto',
                        }}
                        label={label}
                        key={value}
                        icon={icon}
                        iconPosition="start"
                        ref={(ref) => {
                            tabsRefs.current[index] = ref;
                        }}
                    />
                ))}
            </MUITabs>
        </Box>
    );
}

export default Tabs;
