import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select as MUISelect,
    SelectChangeEvent,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { t } from '@/services/translations/config';

import { Props } from './types';

function Select({
    label,
    multiselect,
    onChange,
    options,
    renderSelectedValue,
    selectProps,
    selectedValue,
    testID,
    styles = {},
}: Props) {
    const [value, setValue] = useState<string[]>(selectedValue || []);

    useEffect(() => {
        setValue(selectedValue || []);
    }, [selectedValue]);

    const handleChange = useCallback(
        (event: SelectChangeEvent<string | string[]>) => {
            const { value } = event.target;

            const newValue = typeof value === 'string' ? [value] : value;

            setValue(newValue);
            onChange(newValue);
        },
        [onChange],
    );

    const renderValue = useCallback(
        (value: string[]) => {
            if (renderSelectedValue) {
                return renderSelectedValue(value);
            }

            return value.join(', ');
        },
        [renderSelectedValue],
    );

    return (
        <FormControl sx={{ width: 200, ...styles }} data-test-id={testID}>
            <InputLabel
                data-test-id="Select-Label"
                id="select-label"
                sx={{ top: -7 }}
            >
                {label}
            </InputLabel>
            <MUISelect
                data-test-id="Select"
                label={label}
                labelId="select-label"
                multiple={multiselect}
                onChange={handleChange}
                renderValue={renderValue}
                size="small"
                value={value}
                {...selectProps}
            >
                {options?.map((option) => (
                    <MenuItem
                        data-test-id={`Select-Option-${option.value}`}
                        key={option.id}
                        sx={{ fontSize: 14 }}
                        value={option.value}
                    >
                        {multiselect ? (
                            <Checkbox
                                checked={
                                    !!value.find(
                                        (value) => value === option.value,
                                    )
                                }
                                data-test-id="Select-OptionCheckbox"
                                size="small"
                                sx={{ padding: 0, py: 0.5, ml: -0.5, mr: 1 }}
                            />
                        ) : null}
                        {option.translate
                            ? t(option.translationKey)
                            : option.displayName}
                    </MenuItem>
                ))}
            </MUISelect>
        </FormControl>
    );
}

export default Select;
