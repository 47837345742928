import {
    CircularProgress,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function AccountingProviderSalesCodeSelect({
    disabled,
    isLoading,
    onChange,
    salesCodes,
    value,
}: Props) {
    const { t } = useTranslation();

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange],
    );

    return (
        <>
            <TextField
                data-test-id="AccountingProvider-SalesCodeSelect"
                disabled={disabled}
                InputProps={{
                    startAdornment: isLoading ? (
                        <InputAdornment position="start">
                            <CircularProgress color="inherit" size={20} />
                        </InputAdornment>
                    ) : undefined,
                }}
                label={t('Invoicing:configuration_label')}
                onChange={handleOnChange}
                select
                sx={{ mb: 1.5, width: 300 }}
                value={value}
            >
                {salesCodes.map((salesCode) => (
                    <MenuItem
                        data-test-id={`AccountingProvider-SalesCodeSelect-${salesCode.name}`}
                        key={salesCode.value}
                        value={salesCode.value}
                    >
                        {`${salesCode.name} (${salesCode.value})`}
                    </MenuItem>
                ))}
            </TextField>
            <Typography
                color={COLOR.regentGray}
                fontSize={14}
                fontStyle="italic"
            >
                {t('Invoicing:configuration_note')}
            </Typography>
        </>
    );
}

export default AccountingProviderSalesCodeSelect;
