import { Box } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from, of, switchMap } from 'rxjs';
import OrganisationUser from 'shared/db/services/OrganisationUser';
import User from 'shared/db/services/User';
import { ROLE } from 'shared/types/OrganisationUser';
import { UserModel } from 'shared/types/User';
import { mapEntryProducts } from 'shared/utils/product';

import { EmptyState } from '@/components';
import EntryProductsSection from '@/components/EntryProductsSection';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import EntryNotesSection from '../../../../components/EntryNotesSection';
import EntryProceduresSection from '../../../../components/EntryProceduresSection';
import HorsesSection from '../../../../components/HorsesSection';
import PhotosSection from '../../../../components/PhotosSection';

import { HeaderSection } from './components';
import { Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

function EntryDetails({
    entry,
    isLoading,
    horse,
    procedures,
    products,
    onCreateInvoice,
}: Props) {
    const { t } = useTranslation();
    const { images, ImagesService } = useImagesContext();
    const { getDatabase } = useDatabase();
    const { isSyncInProgress } = useDBSyncContext();
    const { orgUser } = useOrgUserContext();

    const [users, setUsers] = useState<UserModel[]>([]);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const entryImagesUrls: string[] = useMemo(
        () =>
            entry && images
                ? ImagesService.getImagesByEntityId(images, entry.id)?.map(
                      (image) => image.imageURL,
                  ) ?? []
                : [],
        [ImagesService, entry, images],
    );

    const fetchEntryUsers = useCallback(async () => {
        const userService = new User({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
        });

        const organisationUserService = new OrganisationUser(database);

        const entryUsers = await userService
            .getByEntryId(entry?.id ?? '')
            .fetch();

        const activeEntryUsers: UserModel[] = [];

        for (const user of entryUsers) {
            const [organisationUser] = await organisationUserService
                .getById(user.id)
                .fetch();

            if (organisationUser.active) {
                activeEntryUsers.push(user);
            }
        }

        setUsers(activeEntryUsers);
    }, [ImagesService, database, entry?.id]);

    useEffect(() => {
        if (entry?.id && !isSyncInProgress) {
            fetchEntryUsers();
        }
    }, [entry?.id, fetchEntryUsers, isSyncInProgress]);

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    return !isLoading && !entry ? (
        <EmptyState message={t('Entry:not_found')} />
    ) : (
        <Box>
            <HeaderSection
                entry={entry}
                entryHorse={horse}
                isLoading={isLoading}
                users={users}
                onCreateInvoice={onCreateInvoice}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="EntryPage-ProceduresSection"
                        sx={sectionStyles}
                    >
                        <EntryProceduresSection
                            isLoading={isLoading}
                            testIdPrefix="EntryProceduresSection"
                            procedures={procedures || []}
                            showPrice={isOrgUserAdmin}
                        />
                    </Box>
                    <Box
                        sx={sectionStyles}
                        data-test-id="EntryPage-HorseSection"
                    >
                        <HorsesSection
                            testIdPrefix="EntryHorseSection"
                            horses={horse ? [horse] : []}
                            isLoading={isLoading}
                            isSingleHorse
                        />
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box
                        sx={sectionStyles}
                        data-test-id="EntryPage-ProductsSection"
                    >
                        <EntryProductsSection
                            isLoading={isLoading}
                            testIdPrefix="EntryProductsSection"
                            products={products || []}
                        />
                    </Box>
                    <Box sx={sectionStyles} data-test-id="EntryNotesSection">
                        <EntryNotesSection
                            publicNotes={entry?.notes ?? ''}
                            privateNotes={entry?.privateNotes ?? ''}
                            isLoading={isLoading}
                            testIdPrefix="EntryNotesSection"
                        />
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box
                        sx={{ sectionStyles }}
                        data-test-id="EntryPage-PhotosSection"
                    >
                        <PhotosSection
                            isLoading={isLoading}
                            emptyMessage={t('Entry:photos_blankslate')}
                            title={t('Entry:section:photos')}
                            testIdPrefix="EntryPhotosSection"
                            photosUrls={entryImagesUrls}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(['entry'], ({ entry }) => ({
    horse: entry ? entry.horse.observe() : of(null),
    procedures: entry
        ? entry.entryProcedures.observeWithColumns(['quantity', 'price'])
        : of([]),
    products: entry
        ? entry.entryProducts
              .observeWithColumns(['quantity'])
              .pipe(switchMap((products) => from(mapEntryProducts(products))))
        : of([]),
}));

export default enhance(EntryDetails);
