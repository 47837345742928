import { Box } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import moment from 'moment/moment';
import React, { useCallback, useMemo } from 'react';
import { of } from 'rxjs/internal/observable/of';
import { ContactModel } from 'shared/types/Contacts';
import { EventCategory } from 'shared/types/Events';

import {
    AppointmentInviteContactSection,
    ContactsSection,
    EntriesSection,
    HorsesSection,
    NotesSection,
} from '@/components';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';

import ContactRSVPStatus from '../../../../components/ContactRSVPStatus';

import HeaderSection from './components';
import { Props } from './types';
import useRSVPOverlay from './useRSVPOverlay';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
    position: 'relative',
};

function AppointmentDetails(props: Props) {
    const {
        event,
        isLoading,
        contacts,
        entries,
        horses,
        onContactSmsClick,
        onSmsDisabledClick,
        onContactEmailClick,
        onEmailDisabledClick,
        contactsInvitationState,
        contactsRSVPStatus,
        onEditAppointmentPress,
    } = props;
    const { showRSVPInvitationOverlay, showRSVPDeclinedAppointmentOverlay } =
        useRSVPOverlayContext();

    const isInPast = useMemo(
        () =>
            event?.startsTime
                ? moment(event.startsTime).isBefore(moment())
                : false,
        [event?.startsTime],
    );

    const sortedContacts = useMemo(() => {
        return contacts.sort((contact) => {
            const contactState = contactsInvitationState[contact.id];

            if (contactState?.email || contactState?.sms) {
                return -1;
            } else {
                return 1;
            }
        });
    }, [contacts, contactsInvitationState]);

    const { contactsToOverlay } = useRSVPOverlay(
        sortedContacts,
        contactsRSVPStatus,
    );

    const renderExtraContentComponent = useCallback(
        (item: ContactModel) => {
            const contactState = contactsInvitationState[item.id];

            return (
                <>
                    <AppointmentInviteContactSection
                        state={contactsInvitationState}
                        onSmsClick={onContactSmsClick}
                        onEmailClick={onContactEmailClick}
                        disable={isInPast}
                        mode="view"
                        onSmsDisabledClick={onSmsDisabledClick}
                        onEmailDisabledClick={onEmailDisabledClick}
                        item={item}
                    />
                    {contactState?.sms || contactState?.email ? (
                        <ContactRSVPStatus
                            contact={item}
                            contactsToOverlay={contactsToOverlay}
                            status={contactsRSVPStatus[item.id]}
                            onEditAppointmentPress={onEditAppointmentPress}
                        />
                    ) : null}
                </>
            );
        },
        [
            contactsInvitationState,
            contactsRSVPStatus,
            contactsToOverlay,
            isInPast,
            onContactEmailClick,
            onContactSmsClick,
            onEmailDisabledClick,
            onSmsDisabledClick,
            onEditAppointmentPress,
        ],
    );

    return (
        <Box>
            <HeaderSection event={event} isLoading={isLoading} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <Box
                        id="contactSectionContainer"
                        data-test-id="AppointmentPage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <ContactsSection
                            testIdPrefix={'AppointmentContactsSection'}
                            contacts={sortedContacts}
                            isLoading={isLoading}
                            renderExtraContentComponent={
                                renderExtraContentComponent
                            }
                        />
                    </Box>
                    <Box
                        id="entrySectionContainer"
                        data-test-id="AppointmentPage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <EntriesSection
                            entries={entries}
                            isLoading={isLoading}
                        />
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    {event?.category === EventCategory.service ? (
                        <Box
                            data-test-id="AppointmentPage-HorsesSection"
                            sx={sectionStyles}
                        >
                            <HorsesSection
                                testIdPrefix={'AppointmentHorsesSection'}
                                horses={horses}
                                isLoading={isLoading}
                            />
                        </Box>
                    ) : null}

                    <Box
                        data-test-id="AppointmentPage-NotesSection"
                        sx={sectionStyles}
                    >
                        <NotesSection
                            containerTestId={'AppointmentNotesSection'}
                            textTestId={'AppointmentPage-NotesText'}
                            comments={event?.notes || ''}
                            isLoading={isLoading}
                        />
                    </Box>
                </Box>
            </Box>
            {((showRSVPInvitationOverlay ||
                showRSVPDeclinedAppointmentOverlay) &&
                contactsToOverlay.awaiting) ||
            contactsToOverlay.rejected ? (
                <Box sx={{ height: 500 }} />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(['event'], ({ event }) => {
    return {
        contacts: event ? event.contacts : of([]),
        entries: event ? event.entries : of([]),
        horses: event ? event.horses : of([]),
    };
});

export default enhance(AppointmentDetails);
