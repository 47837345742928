import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

import { ClockIcon, TeamIcon, TeamSwitcherIcon } from '@/assets/svg';
import { useEntityImage } from '@/hooks/useEntityImage';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function TeamItem({
    hasPendingApproval,
    id,
    name,
    ownerName,
    onButtonPress,
}: Props) {
    const image = useEntityImage(id);
    const avatarURL = image?.imageURL || '';

    return (
        <Box
            sx={{
                flexDirection: 'row',
                backgroundColor: COLOR.backgroundGrey,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                pr: '28px',
                mt: '20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                }}
            >
                <Avatar
                    sx={{
                        height: '64px',
                        width: '64px',
                        borderRadius: '8px',
                        backgroundColor: COLOR.eclipse,
                    }}
                    variant={'square'}
                    src={avatarURL}
                >
                    <TeamIcon height={40} width={40} color={'grey'} />
                </Avatar>
                <Box
                    sx={{
                        marginLeft: '16px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '11px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: hasPendingApproval
                                    ? COLOR.gray
                                    : undefined,
                                fontSize: 16,
                                fontWeight: 'bold',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '250px',
                            }}
                        >
                            {name}
                        </Typography>
                        {hasPendingApproval ? (
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginRight: '7px',
                                    scale: '125%',
                                }}
                            >
                                <ClockIcon
                                    color={COLOR.black}
                                    height={24}
                                    width={28}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Typography
                        sx={{
                            color: '#212B36',
                            fontSize: 12,
                            marginTop: '3px',
                            opacity: '40%',
                        }}
                    >
                        {t('TeamsPage:team_owned_by', {
                            owner: ownerName,
                        })}
                    </Typography>
                    {hasPendingApproval ? (
                        <Typography
                            sx={{
                                color: COLOR.black,
                                fontSize: 12,
                                fontWeight: 'bold',
                                marginBottom: '8px',
                                marginTop: '3px',
                            }}
                        >
                            {t('TeamsPage:awaiting_membership_confirmation')}
                        </Typography>
                    ) : null}
                </Box>
            </Box>
            {!hasPendingApproval ? (
                <Box
                    sx={{
                        alignItems: 'center',
                        flexDirection: 'column',
                        display: 'flex',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        minWidth: '40px',
                    }}
                    onClick={onButtonPress}
                >
                    <Box
                        sx={{
                            scale: '125%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <TeamSwitcherIcon
                            color={COLOR.black}
                            height={24}
                            width={28}
                        />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 12,
                            color: '#212B36',
                            marginTop: -0.5,
                        }}
                    >
                        {t('Actions:switch')}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
}

export default TeamItem;
