import apiClient from '@/services/networking/apiClient';
import {
    PullDBChangesRequestPayload,
    PullDBChangesResponsePayload,
    PushDBChangesRequestPayload,
    PushDBChangesResponsePayload,
} from '@/types/dbSync';

import { FirebaseRemoteConfigAPI } from '../firebase/remoteConfig';

async function pullChanges(
    payload: PullDBChangesRequestPayload,
): Promise<PullDBChangesResponsePayload> {
    const appVersion = FirebaseRemoteConfigAPI.getMobileAppVersion();
    const { data } = await apiClient.get<PullDBChangesResponsePayload>(
        `/sync/changes?app_type=web&app_version=${appVersion}&last_pulled_at=${
            payload.lastPulledAt || ''
        }`,
    );

    return data;
}

async function pushChanges(
    payload: PushDBChangesRequestPayload,
): Promise<PushDBChangesResponsePayload> {
    const { data } = await apiClient.post<PushDBChangesResponsePayload>(
        '/sync/changes?app_type=web',
        payload,
    );

    return data;
}

export const DBSyncAPI = {
    pullChanges,
    pushChanges,
};
