import React from 'react';
import { generateDummyArray } from 'shared/utils/other';

import { InventoryListSkeletonItem } from '@/components/SelectedProductsListSkeleton/components';

import { Props } from './types';

function SelectedProductsListSkeleton({ numberOfItems = 5 }: Props) {
    const skeletonsArray = generateDummyArray(numberOfItems);

    return (
        <>
            {skeletonsArray.map((item) => (
                <InventoryListSkeletonItem key={item} />
            ))}
        </>
    );
}

export default SelectedProductsListSkeleton;
