import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export function useDebounce<T>(callback: (...args: T[]) => void, delay = 500) {
    return useRef(debounce(callback, delay)).current;
}

export function useDebouncedValue<T>(value: T, delay = 500): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return (): void => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
}
