import { Dialog } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
    InitialContent,
    PriceEditingContent,
    QuantityEditingContent,
} from './components';
import { ModalState, Props } from './types';

function EditProcedureDataModal({
    close,
    isOpen,
    onSavePriceButtonClick,
    onSaveQuantityButtonClick,
    procedure,
    allowToEditPrice = true,
}: Props) {
    const [modalState, setModalState] = useState<ModalState>('initial');

    useEffect(() => {
        if (isOpen) {
            setModalState('initial');
        }
    }, [isOpen]);

    const setModalStateToQuantityEditing = useCallback(() => {
        setModalState('quantity_editing');
    }, []);

    const setModalStateToPriceEditing = useCallback(() => {
        setModalState('price_editing');
    }, []);

    const renderModalContent = useCallback(() => {
        switch (modalState) {
            default:
            case 'initial': {
                return (
                    <InitialContent
                        onCancelButtonClick={close}
                        onEditPriceButtonClick={setModalStateToPriceEditing}
                        onEditQuantityButtonClick={
                            setModalStateToQuantityEditing
                        }
                        procedureName={procedure?.name || ''}
                        allowToEditPrice={allowToEditPrice}
                    />
                );
            }

            case 'price_editing': {
                return (
                    <PriceEditingContent
                        closeModal={close}
                        onCancelButtonClick={close}
                        onSaveButtonClick={onSavePriceButtonClick}
                        procedure={procedure}
                    />
                );
            }

            case 'quantity_editing': {
                return (
                    <QuantityEditingContent
                        closeModal={close}
                        onCancelButtonClick={close}
                        onSaveButtonClick={onSaveQuantityButtonClick}
                        procedure={procedure}
                    />
                );
            }
        }
    }, [
        allowToEditPrice,
        close,
        modalState,
        onSavePriceButtonClick,
        onSaveQuantityButtonClick,
        procedure,
        setModalStateToPriceEditing,
        setModalStateToQuantityEditing,
    ]);

    return (
        <Dialog data-test-id="EditProcedureDataModal" open={isOpen}>
            {renderModalContent()}
        </Dialog>
    );
}

export default EditProcedureDataModal;
