import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function PersonPlusIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            x="0px"
            y="0px"
            viewBox="0 0 50.2 37"
        >
            <g id="Group_917" transform="translate(-168.916 -403.491)">
                <path
                    id="Path_446"
                    d="M182.3,421c4.8,0,8.7-3.9,8.7-8.7s-3.9-8.7-8.7-8.7s-8.7,3.9-8.7,8.7l0,0C173.6,417.1,177.5,421,182.3,421
		C182.3,421,182.3,421,182.3,421"
                />
                <path
                    id="Path_447"
                    d="M182.3,423.4c-4.5,0-13.4,3.8-13.4,11.4v5.7h26.8v-5.7C195.8,427.2,186.8,423.4,182.3,423.4"
                />
                <g id="Group_649" transform="translate(196.385 411.057)">
                    <path
                        id="svgexport-17-2"
                        d="M12.3,2C6.6,2,2,6.6,2,12.4s4.6,10.3,10.3,10.3s10.3-4.6,10.3-10.3l0,0C22.7,6.6,18.1,2,12.3,2z
			 M16.5,13.4h-3.1v3.1c0,0.6-0.5,1-1,1s-1-0.5-1-1v-3.1H8.2c-0.6,0-1-0.5-1-1s0.5-1,1-1h3.1V8.2c0-0.6,0.5-1,1-1s1,0.5,1,1v3.1h3.1
			c0.6,0,1,0.5,1,1S17.1,13.4,16.5,13.4z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default PersonPlusIcon;
