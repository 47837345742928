import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductImages } from 'shared/constants/products/images';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function EntryProduct({
    entryProduct,
    isClickable,
    isCompact = false,
    onEditButtonClick,
    onItemClick,
    onRemove,
    testIdPrefix,
}: Props) {
    const { t } = useTranslation();

    const handleRemoveProduct = useCallback(
        (event) => {
            event.stopPropagation();

            if (onRemove) {
                onRemove(entryProduct);
            }
        },
        [entryProduct, onRemove],
    );

    const handleEditButtonClick = useCallback(
        (event) => {
            event.stopPropagation();

            if (onEditButtonClick) {
                onEditButtonClick(entryProduct);
            }
        },
        [entryProduct, onEditButtonClick],
    );

    const handleItemClick = useCallback(() => {
        if (isClickable && onItemClick) {
            onItemClick(entryProduct);
        }
    }, [entryProduct, isClickable, onItemClick]);

    const getQuantitySizeElement = useCallback(
        (title: string, value: string | number, testID: string) => (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="body2" color={COLOR.paleSky}>
                    {title}
                </Typography>
                <Typography
                    data-test-id={testID}
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {value}
                </Typography>
            </Box>
        ),
        [],
    );

    return (
        <Box
            onClick={handleItemClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                    cursor: isClickable ? 'pointer' : 'default',
                    backgroundColor: isClickable
                        ? COLOR.transparentLightBlue
                        : 'inherit',
                },
                width: '100%',
            }}
            data-test-id={`${testIdPrefix}-${entryProduct.name}${
                entryProduct.materialShapeAndClips ?? ''
            }${entryProduct.size}`.trim()}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 2, ml: 1.25, width: '100%' }}>
                    {isCompact ? (
                        <Box
                            sx={{
                                bgcolor: COLOR.vividYellow12,
                                display: 'flex',
                                flexDirection: 'column',
                                width: 100,
                                height: 74,
                                px: 2,
                                justifyContent: 'center',
                            }}
                        >
                            {getQuantitySizeElement(
                                t('Entry:product:size'),
                                entryProduct.size || '-',
                                `${testIdPrefix}-Product-Size`,
                            )}
                            {getQuantitySizeElement(
                                t('Entry:product:compact_quantity'),
                                entryProduct.quantity,
                                `${testIdPrefix}-Product-Quantity`,
                            )}
                        </Box>
                    ) : null}
                    <img
                        className={
                            isCompact ? 'w-[74px] h-[74px]' : 'w-20 h-20'
                        }
                        src={ProductImages[entryProduct.productType]}
                    />
                    <Box sx={{ mt: 1, flex: 1 }}>
                        <Typography
                            fontWeight={700}
                            fontSize={16}
                            data-test-id={`${testIdPrefix}-Product-Name`}
                        >
                            {entryProduct.name}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                data-test-id={`${testIdPrefix}-Product-MaterialShapeAndClips`}
                            >
                                {entryProduct.materialShapeAndClips}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {onEditButtonClick ? (
                    <Button
                        className="min-w-0 max-h-8 px-1 ml-2 mr-1 mt-2"
                        onClick={handleEditButtonClick}
                        size="small"
                        data-test-id={`${testIdPrefix}-EditButton`}
                    >
                        <EditRoundedIcon />
                    </Button>
                ) : null}
                {onRemove ? (
                    <Button
                        className="min-w-0 max-h-8 px-1 ml-2 mr-1 mt-2"
                        onClick={handleRemoveProduct}
                        size="small"
                        data-test-id={`${testIdPrefix}-RemoveButton`}
                    >
                        <DeleteOutlineRoundedIcon />
                    </Button>
                ) : null}
            </Box>
            {!isCompact ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            px: 2,
                            py: 1.25,
                            bgcolor: COLOR.vividYellow12,
                            width: 100,
                        }}
                    >
                        <Typography
                            data-test-id={`${testIdPrefix}-Product-Size`}
                        >
                            {entryProduct.size ||
                                t('ShoppingList:products:one_size_label')}
                        </Typography>
                    </Box>
                    <Typography
                        data-test-id={`${testIdPrefix}-Product-Quantity`}
                        sx={{
                            alignSelf: 'flex-start',
                            mr: 1.75,
                        }}
                    >
                        {entryProduct.quantity}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
}

export default EntryProduct;
