import { SnackbarProvider } from 'notistack';
import React, { createRef, useState } from 'react';

import { Context } from '@/types/context';

const snackbarInstanceRef: React.MutableRefObject<SnackbarProvider | null> =
    createRef();

const SnackbarContextProvider = ({ children }: Context.Snackbar.Props) => {
    const [snackbarInstance, setSnackbarInstance] =
        useState<SnackbarProvider | null>(null);

    snackbarInstanceRef.current = snackbarInstance;

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            maxSnack={3}
            ref={setSnackbarInstance}
        >
            {children}
        </SnackbarProvider>
    );
};

export { SnackbarContextProvider, snackbarInstanceRef };
