import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useState,
} from 'react';
import { INVENTORY_FILTER } from 'shared/constants/inventory/filters';
import { InventoryFiltersObject } from 'shared/types/inventoryFilters';
import { ProductType } from 'shared/types/Products';

import { Context } from '@/types/context';

const INITIAL_FILTERS_STATE: InventoryFiltersObject = {
    [INVENTORY_FILTER.type]: ProductType.shoes,
};

const InventoryFiltersContext = createContext<Context.InventoryFilters.Value>({
    filtersState: INITIAL_FILTERS_STATE,
    clearContextState: () => null,
    setFiltersState: () => null,
});

const inventoryFiltersContextRef: React.MutableRefObject<Context.InventoryFilters.Value | null> =
    createRef();

const InventoryFiltersProvider = ({
    children,
}: Context.InventoryFilters.Props) => {
    const [filtersState, setFiltersState] = useState<InventoryFiltersObject>(
        INITIAL_FILTERS_STATE,
    );

    const resetFilters = useCallback(
        () => setFiltersState(INITIAL_FILTERS_STATE),
        [],
    );

    const clearContextState = useCallback(() => resetFilters(), [resetFilters]);

    const contextValue: Context.InventoryFilters.Value = {
        filtersState,
        setFiltersState,
        clearContextState,
    };

    inventoryFiltersContextRef.current = contextValue;

    return (
        <InventoryFiltersContext.Provider value={contextValue}>
            {children}
        </InventoryFiltersContext.Provider>
    );
};

function useInventoryFiltersContext() {
    const inventoryFiltersContext = useContext(InventoryFiltersContext);

    return inventoryFiltersContext;
}

function clearInventoryFiltersContextState() {
    return inventoryFiltersContextRef.current?.clearContextState();
}

export {
    InventoryFiltersContext,
    InventoryFiltersProvider,
    useInventoryFiltersContext,
    clearInventoryFiltersContextState,
};
