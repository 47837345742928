import Product from '../../db/services/Product';
import { LogEvent } from '../../types/analytics';
import { InventoryProductModel } from '../../types/InventoryProduct';
import { ProductModel, ProductType } from '../../types/Products';
import { getProductSize } from '../../utils/product';

import { InventoryEvents } from './events';
import { OrganisationAnalytics } from '../organisation';

function getProductParams(product: ProductModel, quantity: number) {
    return {
        brand: product.brand,
        shape: product.shape,
        type: product.productType,
        material: product.material,
        clip: product.clips,
        size: getProductSize(product),
        family: product.family,
        quantity,
    };
}

function logUserAddedShoeToInventory(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        quantity: number;
    },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(InventoryEvents.userAddedShoeToInventory, {
        ...eventParams,
    });
}

function logUserAddedNailToInventory(
    logEvent: LogEvent,
    params: { product: ProductModel; quantity: number },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(InventoryEvents.userAddedNailToInventory, {
        ...eventParams,
    });
}

function logUserAddedRaspToInventory(
    logEvent: LogEvent,
    params: { product: ProductModel; quantity: number },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(InventoryEvents.userAddedRaspToInventory, {
        ...eventParams,
    });
}

function logUserAddedCustomProductToInventory(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        quantity: number;
    },
) {
    logEvent(InventoryEvents.userAddedCustomProductToInventory, {
        name: params.product.name,
        quantity: params.quantity,
    });
}

async function logUserAddedProductToInventory(
    logEvent: LogEvent,
    params: {
        inventoryProduct: InventoryProductModel;
        productService: Product;
        isOwner: boolean | null;
    },
) {
    const product = await params.productService.getByID(
        params.inventoryProduct.productId,
    );

    logEvent(InventoryEvents.userAddedProductToInventory, {
        ...product._raw,
        quantity: params.inventoryProduct.quantity,
    });

    if (product.productType === ProductType.nails) {
        logUserAddedNailToInventory(logEvent, {
            product,
            quantity: params.inventoryProduct.quantity,
        });
    } else if (product.productType === ProductType.shoes) {
        logUserAddedShoeToInventory(logEvent, {
            product,
            quantity: params.inventoryProduct.quantity,
        });
    } else if (product.productType === ProductType.rasps) {
        logUserAddedRaspToInventory(logEvent, {
            product,
            quantity: params.inventoryProduct.quantity,
        });
    } else if (product.productType === ProductType.custom) {
        logUserAddedCustomProductToInventory(logEvent, {
            product,
            quantity: params.inventoryProduct.quantity,
        });
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberAddedProductToInventory(logEvent);
    }
}

async function logUserChangedAmountOfProductInInventory(
    logEvent: LogEvent,
    params: {
        inventoryProduct: InventoryProductModel;
        quantityChange: number;
        productService: Product;
    },
) {
    const product = await params.productService.getByID(
        params.inventoryProduct.productId,
    );

    logEvent(InventoryEvents.userChangedProductAmountInInventory, {
        ...product._raw,
        quantity: params.inventoryProduct.quantity,
        quantityChange: params.quantityChange,
    });
}

export const InventoryAnalytics = {
    logUserAddedProductToInventory,
    logUserChangedAmountOfProductInInventory,
};
