import React, { PropsWithChildren } from 'react';

import type { Props } from './types';

export function ExternalLink({ href, children }: PropsWithChildren<Props>) {
    return (
        <a href={href} target="_blank" className="underline text-nevada" rel="noreferrer">
            {children}
        </a>
    );
}
