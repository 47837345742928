import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography, Backdrop } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import {
    EQUINET_PRIVACY_POLICY_PAGE,
    EQUINET_WEBSITE,
} from '@/constants/externalLinks';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function HelpBanner({ showBanner, close }: Props) {
    const logo = useMemo(
        () => <Box alt="Equinet Logo" component="img" src={equinetLogoImage} />,
        [],
    );

    const mobileContent = useMemo(() => {
        return (
            <div className="md:hidden">
                <div className="relative flex flex-row justify-between items-center py-4 border-b-[2px] border-anotherGrey">
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '24px',
                            color: COLOR.gameboyGrey,
                        }}
                    >
                        {t('ExternalAppointment:labels:about')}
                    </Typography>
                    <Button
                        variant="text"
                        disableElevation
                        onClick={close}
                        startIcon={<CloseIcon sx={{ color: COLOR.black }} />}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: COLOR.gameboyGrey,
                            }}
                        >
                            {t(
                                'ExternalAppointment:actions:cancel_close_action',
                            )}
                        </Typography>
                    </Button>
                </div>
                <div className="pt-5">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: COLOR.gameboyGrey,
                        }}
                    >
                        {t('ExternalAppointment:labels:about_disclaimer')}
                    </Typography>
                </div>
                <div className="pt-9">
                    <Button
                        variant="text"
                        disableElevation
                        target="_blank"
                        href={EQUINET_WEBSITE}
                        rel="noopener noreferrer"
                        endIcon={
                            <ArrowForwardIcon sx={{ color: COLOR.skyBlue }} />
                        }
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: '700',
                                lineHeight: '19.5px',
                                color: COLOR.skyBlue,
                            }}
                        >
                            {t('ExternalAppointment:labels:learn_more')}
                        </Typography>
                    </Button>
                </div>
                <div className="pt-2">
                    <Button
                        variant="text"
                        disableElevation
                        target="_blank"
                        href={EQUINET_PRIVACY_POLICY_PAGE}
                        rel="noopener noreferrer"
                        endIcon={
                            <ArrowForwardIcon sx={{ color: COLOR.skyBlue }} />
                        }
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: '700',
                                lineHeight: '19.5px',
                                color: COLOR.skyBlue,
                            }}
                        >
                            {t('ExternalAppointment:labels:privacy')}
                        </Typography>
                    </Button>
                </div>
                <div className="pt-24 pb-5 w-[223px] mx-auto">{logo}</div>
            </div>
        );
    }, [close, logo]);

    const desktopContent = useMemo(() => {
        return (
            <div className="hidden md:flex flex-row gap-14 pt-5 pb-10">
                <div className="flex-2">
                    <div className="pb-4 max-w-[95%] border-b-[3px] border-anotherGrey">
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '24px',
                                color: COLOR.gameboyGrey,
                            }}
                        >
                            {t('ExternalAppointment:labels:about')}
                        </Typography>
                    </div>
                    <div className="pt-5">
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: COLOR.gameboyGrey,
                            }}
                        >
                            {t('ExternalAppointment:labels:about_disclaimer')}
                        </Typography>
                    </div>
                </div>
                <div className="flex-3">
                    <div className="w-[223px]">{logo}</div>
                    <div className="pt-5">
                        <Button
                            variant="text"
                            disableElevation
                            target="_blank"
                            href={EQUINET_WEBSITE}
                            rel="noopener noreferrer"
                            endIcon={
                                <ArrowForwardIcon
                                    sx={{ color: COLOR.skyBlue }}
                                />
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '700',
                                    lineHeight: '19.5px',
                                    color: COLOR.skyBlue,
                                }}
                            >
                                {t('ExternalAppointment:labels:learn_more')}
                            </Typography>
                        </Button>
                    </div>
                    <div className="pt-2">
                        <Button
                            variant="text"
                            disableElevation
                            target="_blank"
                            href={EQUINET_PRIVACY_POLICY_PAGE}
                            rel="noopener noreferrer"
                            endIcon={
                                <ArrowForwardIcon
                                    sx={{ color: COLOR.skyBlue }}
                                />
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    fontWeight: '700',
                                    lineHeight: '19.5px',
                                    color: COLOR.skyBlue,
                                }}
                            >
                                {t('ExternalAppointment:labels:privacy')}
                            </Typography>
                        </Button>
                    </div>
                </div>
                <div>
                    <Button
                        variant="text"
                        disableElevation
                        onClick={close}
                        startIcon={<CloseIcon sx={{ color: COLOR.black }} />}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: COLOR.gameboyGrey,
                            }}
                        >
                            {t(
                                'ExternalAppointment:actions:cancel_close_action',
                            )}
                        </Typography>
                    </Button>
                </div>
            </div>
        );
    }, [close, logo]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBanner}
        >
            <AnimateHeight
                height={showBanner ? 'auto' : 0}
                duration={100}
                className="absolute bottom-0 inset-x-0 bg-whiteDarker2 drop-shadow-greyer border-t-[3px] border-deepCerulean px-5 md:px-40"
                aria-controls={showBanner ? 'banner' : undefined}
                aria-haspopup={true}
                aria-expanded={showBanner ? true : undefined}
            >
                {mobileContent}
                {desktopContent}
            </AnimateHeight>
        </Backdrop>
    );
}

export default HelpBanner;
