import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';

import { Props, SHARE_SHOPPING_LIST_TYPE } from './types';

function ShareWithDealerAlert({
    shoppingListName,
    isOpen,
    onConfirm,
    onClose,
}: Props) {
    const { t } = useTranslation();

    const { isSyncInProgress } = useDBSyncContext();

    const handleShareViaBackend = useCallback(() => {
        onClose();
        onConfirm(SHARE_SHOPPING_LIST_TYPE.VIA_BACKEND);
    }, [onClose, onConfirm]);

    const handleShareViaClient = useCallback(() => {
        onClose();
        onConfirm(SHARE_SHOPPING_LIST_TYPE.VIA_CLIENT);
    }, [onClose, onConfirm]);

    return (
        <Dialog open={isOpen} data-test-id="ShareWithDealerAlert">
            <DialogTitle>{t('Actions:confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t(
                        'SingleShoppingList:button:share_with_dealer_description',
                        {
                            shopping_list: shoppingListName,
                        },
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Box>
                    <Button onClick={onClose}>{t('Actions:cancel')}</Button>
                </Box>
                <Box>
                    <TooltipSyncButton
                        onClick={handleShareViaBackend}
                        showTooltip={isSyncInProgress}
                        disabled={isSyncInProgress}
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        testID="ShareViaBackendButton"
                    >
                        {t(
                            'SingleShoppingList:button:share_with_dealer_via_backend',
                        )}
                    </TooltipSyncButton>
                    <Button
                        onClick={handleShareViaClient}
                        color="primary"
                        autoFocus
                        data-test-id="ShareViaClientButton"
                    >
                        {t(
                            'SingleShoppingList:button:share_with_dealer_via_client',
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default ShareWithDealerAlert;
