import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertDialog } from '@/components';

type Options = {
    closeEntityForm: VoidFunction;
    shouldShowAlertOnCancel: boolean;
};

type ReturnValues = {
    handleFormCancelButtonClick: VoidFunction;
    renderCancelAlert: () => JSX.Element;
    showCancelAlert: boolean;
};

export function useCancelEntityFormAlert({
    closeEntityForm,
    shouldShowAlertOnCancel,
}: Options): ReturnValues {
    const { t } = useTranslation();

    const [showCancelAlert, setShowCancelAlert] = useState(false);

    const showCancelFormAlert = useCallback(() => setShowCancelAlert(true), []);

    const hideCancelFormAlert = useCallback(
        () => setShowCancelAlert(false),
        [],
    );

    const handleFormCancelButtonClick = useCallback(() => {
        if (shouldShowAlertOnCancel) {
            showCancelFormAlert();
        } else {
            closeEntityForm();
        }
    }, [closeEntityForm, shouldShowAlertOnCancel, showCancelFormAlert]);

    return {
        handleFormCancelButtonClick,
        renderCancelAlert: () => (
            <AlertDialog
                fullWidth
                close={hideCancelFormAlert}
                testID="CancelFormAlert"
                title={t('Options:alert:form_cancel_title')}
                message={t('Options:alert:form_cancel_message')}
                confirmText={t('Options:alert:form_cancel_confirm_button')}
                onConfirmButtonClick={closeEntityForm}
            />
        ),
        showCancelAlert,
    };
}
