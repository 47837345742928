import { CONTACTS_INVOICING_FILTERS_DICTIONARY } from 'shared/constants/contacts/filters';
import { CONTACT_ROLES_DICTIONARY } from 'shared/constants/contacts/roles';

import { generateSelectOptionsFromDictionary } from '@/helpers/filters';
import { SelectOption } from '@/types/select';

export function getContactRoleFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(CONTACT_ROLES_DICTIONARY);
}

export function getContactInvoicingFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(
        CONTACTS_INVOICING_FILTERS_DICTIONARY,
    );
}
