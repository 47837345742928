import { APP_ROUTE } from '../../../mobile/src/navigation/routes';
import { LogEvent } from '../../types/analytics';
import { NavigationEvents } from './events';

function logUserSwitchedToContacts(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToContacts);
}

function logUserSwitchedToHorses(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToHorses);
}

function logUserSwitchedToEntries(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToEntries);
}

function logUserSwitchedToInvoices(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToInvoices);
}

function logUserSwitchedToSchedule(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToSchedule);
}

function logUserSwitchedToInventory(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToInventory);
}

function logUserSwitchedToShoppingLists(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToShoppingLists);
}

function logUserSwitchedToMessages(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToMessages);
}

function logUserSwitchedToTeams(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToTeams);
}

function logUserSwitchedToInvoicingAndAdministration(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToInvoicingAndAdministration);
}

function logUserClickedOnHelpButton(logEvent: LogEvent) {
    logEvent(NavigationEvents.userClickedOnHelpButton);
}

function logUserSwitchedToSettings(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToSettings);
}

function logUserSwitchedToAccount(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToAccount);
}

function logUserSwitchedToCalendarSync(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToCalendarSync);
}

function logUserSwitchedToPrivacySettings(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToPrivacySettings);
}

function logUserSwitchedToSubscriptionsAndNotifications(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToSubscriptionsAndNotifications);
}

function logUserSwitchedToFAQ(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToFAQ);
}

function logUserSwitchedToPrivacyPolicy(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToPrivacyPolicy);
}

function logUserSwitchedToTermsAndConditions(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToTermsAndConditions);
}

function logUserSwitchedToWhatIsEquinet(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToWhatIsEquinet);
}

function logUserOpenedContactUsModal(logEvent: LogEvent) {
    logEvent(NavigationEvents.userOpenedContactUsModal);
}

function logUserSelectedContactUsEmail(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToWhatIsEquinet);
}

function logUserSelectedContactUsPhoneCall(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSelectedContactUsPhoneCall);
}

function logUserSwitchedToCredits(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSwitchedToCredits);
}

function logUserClickedSendIssueReport(logEvent: LogEvent) {
    logEvent(NavigationEvents.userClickedSendIssueReport);
}

function logUserSentIssueReport(logEvent: LogEvent) {
    logEvent(NavigationEvents.userSentIssueReport);
}

function logGenericUserSwitchedToView(
    logEvent: LogEvent,
    params: { route: APP_ROUTE },
) {
    logEvent(`${NavigationEvents.genericUserSwitchedTo}_${params.route}`);
}

function logUserClickedPlusButton(
    logEvent: LogEvent,
    params: { entity: string },
) {
    logEvent(NavigationEvents.userClickedOnPlusButton, {
        entity: params.entity,
    });
}

function logUserCustomisedMenuOrder(
    logEvent: LogEvent,
    params: { preferredRoutes: APP_ROUTE[] },
) {
    logEvent(NavigationEvents.userCustomisedMenuOrder, {
        preferredRoutes: params.preferredRoutes,
    });
}

function logUserOpenedTeamMembersArea(
    logEvent: LogEvent,
    params: { entity: 'entries' | 'events' },
) {
    logEvent(NavigationEvents.userOpenedTeamMembersArea, {
        screen: params.entity,
    });
}

export const NavigationAnalytics = {
    logUserSwitchedToContacts,
    logUserSwitchedToHorses,
    logUserSwitchedToEntries,
    logUserSwitchedToInvoices,
    logUserSwitchedToSchedule,
    logUserSwitchedToInventory,
    logUserSwitchedToShoppingLists,
    logUserSwitchedToMessages,
    logUserSwitchedToTeams,
    logUserSwitchedToInvoicingAndAdministration,
    logUserClickedOnHelpButton,
    logUserSwitchedToSettings,
    logUserSwitchedToAccount,
    logUserSwitchedToCalendarSync,
    logUserSwitchedToPrivacySettings,
    logUserSwitchedToSubscriptionsAndNotifications,
    logUserSwitchedToFAQ,
    logUserSwitchedToTermsAndConditions,
    logUserSwitchedToWhatIsEquinet,
    logUserOpenedContactUsModal,
    logUserSelectedContactUsEmail,
    logUserSelectedContactUsPhoneCall,
    logUserSwitchedToCredits,
    logUserClickedSendIssueReport,
    logUserSentIssueReport,
    logGenericUserSwitchedToView,
    logUserClickedPlusButton,
    logUserCustomisedMenuOrder,
    logUserOpenedTeamMembersArea,
    logUserSwitchedToPrivacyPolicy,
};
