import i18next, { TFunctionKeys, TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCALE } from 'shared/constants/localization/types';
import locales from 'shared/locales';

import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { getItemFromLocalStorage } from '@/services/webStorage/localStorage';

function getLanguage(): LOCALE {
    const language = getItemFromLocalStorage<LOCALE>(WEB_STORAGE_KEYS.locale);

    return language || LOCALE.en_AU;
}

function init(): void {
    const language = getLanguage();

    i18next.use(initReactI18next).init({
        debug: !!process.env.__DEV__,
        defaultNS: 'common',
        fallbackLng: LOCALE.en_AU,
        interpolation: {
            escapeValue: false,
        },
        keySeparator: ':',
        lng: language,
        ns: ['common'],
        nsSeparator: '.',
        resources: locales,
        saveMissingPlurals: false,
    });
}

function t(scope: TFunctionKeys, options?: TOptions): string {
    return i18next.t(scope, {
        ...options,
        locale: i18next.language || LOCALE.en_AU,
    });
}

init();

export { t };

export default i18next;
