import React from 'react';

import { useEventStatus } from '@/hooks';

import { RSVPBadge } from './components';
import { Props } from './types';

function EventRSVPStatus({ eventId }: Props) {
    const { eventStatus } = useEventStatus(eventId);

    if (!eventStatus) {
        return null;
    }

    return (
        <div className="flex flex-row flex-wrap gap-2 items-center">
            {eventStatus.confirmed ? (
                <RSVPBadge status={eventStatus.confirmed} type="confirmed" />
            ) : null}
            {eventStatus.declined ? (
                <RSVPBadge status={eventStatus.declined} type="declined" />
            ) : null}
        </div>
    );
}

export default EventRSVPStatus;
