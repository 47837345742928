import { ContactModel } from 'shared/types/Contacts';
import { COLOR } from '../constants/colors';
import {
    INVOICE_STATUS,
    INVOICES_STATUSES_DICTIONARY,
} from '../constants/invoices/statuses';
import Entry from '../db/services/Entry';
import { DBServiceOptionsWithImages } from '../types/dbService';
import { InvoiceFinancialDetails, InvoiceModel } from '../types/Invoice';

import { getLocal, isFuture } from './date';
import InvoiceInvoicingProduct from 'shared/db/services/InvoiceInvoicingProduct';
import InvoiceInvoicingProductModel from 'shared/db/models/InvoiceInvoicingProduct';
import { EntryProcedureModel } from 'shared/types/EntryProcedure';

export const generateReference = () => {
    let reference = 'EQ';

    const MIN = 10000;
    const MAX = 99999;
    const randomNumber = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN;
    const date = getLocal().toFormat('yyyyLLdd');

    reference = reference + date + randomNumber;

    return reference;
};

export const calculateInvoiceDetailsFromItems = ({
    taxRate,
    productsTaxRate,
    procedures,
    products,
}: {
    taxRate: number;
    productsTaxRate: number;
    procedures: EntryProcedureModel[];
    products?: Pick<InvoiceInvoicingProductModel, 'price' | 'quantity'>[];
}): InvoiceFinancialDetails => {
    const proceduresSum = procedures.reduce(
        (acc, curr) => acc + Number(curr.price) * Number(curr.quantity),
        0,
    );

    const proceduresTax = taxRate ? (proceduresSum * taxRate) / 100 : 0;

    const productsSum = products
        ? products.reduce(
              (acc, { price, quantity }) => acc + Number(price) * quantity,
              0,
          )
        : 0;

    const productsTax = productsTaxRate
        ? (productsSum * productsTaxRate) / 100
        : 0;

    const subtotalSum = proceduresSum + productsSum;
    const totalSum = proceduresSum + proceduresTax + productsSum + productsTax;

    return {
        subtotalSum,
        totalSum,
        proceduresSum,
        proceduresTax,
        productsSum,
        productsTax,
    };
};

export const calculateInvoiceDetails = async (
    options: DBServiceOptionsWithImages,
    invoice: InvoiceModel,
): Promise<{ totalSum: number; subtotalSum: number }> => {
    const entryService = new Entry(options);
    const invoicingProductService = new InvoiceInvoicingProduct({
        database: options.database,
        logDBAction: options.logDBAction,
    });

    const entries = await entryService.getByParam('invoice_id', invoice.id);
    const products = await invoicingProductService.getByParam(
        'invoice_id',
        invoice.id,
    );

    let procedures: EntryProcedureModel[] = [];

    for (let entry of entries) {
        const entryProcedures = await entry.entryProcedures.fetch();

        procedures = procedures.concat(entryProcedures);
    }

    const taxRate = Number(invoice.taxRate) || 0;
    const productsTaxRate = Number(invoice.productsTaxRate) || taxRate;

    const { subtotalSum, totalSum } = calculateInvoiceDetailsFromItems({
        taxRate,
        productsTaxRate,
        procedures,
        products,
    });

    return { subtotalSum, totalSum };
};

export const calculateInvoicesTotalSum = async ({
    options,
    invoices,
    invoiceSystemEnabled,
}: {
    options: DBServiceOptionsWithImages;
    invoices: InvoiceModel[];
    invoiceSystemEnabled?: boolean;
}): Promise<number> => {
    const results = await Promise.all(
        invoices.map(async (invoice) => {
            const { totalSum, subtotalSum } = await calculateInvoiceDetails(
                options,
                invoice,
            );

            return invoice.applyTaxes && invoiceSystemEnabled
                ? totalSum
                : subtotalSum;
        }),
    );

    return results.reduce((total, invoiceSum) => total + invoiceSum, 0);
};

export const getInvoiceStatus = ({
    hasAccountingProvider,
    sentToContact,
    sentToContactTime,
    status,
    isInternalProvider,
    translateFn,
}: {
    hasAccountingProvider: boolean;
    translateFn: (translationKey: string) => string;
    status: string;
    sentToContactTime: string;
    sentToContact: boolean;
    isInternalProvider?: boolean;
}): string => {
    try {
        if (!!hasAccountingProvider && status === INVOICE_STATUS.authorised) {
            if (sentToContactTime) {
                return translateFn(
                    INVOICES_STATUSES_DICTIONARY[
                        sentToContact
                            ? INVOICE_STATUS.sent
                            : INVOICE_STATUS.queued
                    ],
                );
            }
        }

        if (status === INVOICE_STATUS.authorised && isInternalProvider) {
            return translateFn(
                INVOICES_STATUSES_DICTIONARY[INVOICE_STATUS.approved],
            );
        }

        return translateFn(INVOICES_STATUSES_DICTIONARY[status.toUpperCase()]);
    } catch {
        return status;
    }
};

export const getInvoiceStatusColor = (
    status: string,
    dueDateTime: string,
): { boldText: boolean; color: string } => {
    let parsedStatus = '';

    if (status && dueDateTime) {
        if (
            !isFuture(dueDateTime, -1) &&
            [INVOICE_STATUS.authorised, INVOICE_STATUS.sent].includes(
                status as INVOICE_STATUS,
            )
        ) {
            parsedStatus = INVOICE_STATUS.overdue;
        } else {
            parsedStatus = status;
        }
    }

    switch (parsedStatus) {
        case INVOICE_STATUS.paid:
            return {
                boldText: true,
                color: COLOR.green,
            };
        case INVOICE_STATUS.draft:
            return {
                boldText: false,
                color: COLOR.light_gray,
            };
        case INVOICE_STATUS.overdue:
            return {
                boldText: true,
                color: COLOR.flamingo,
            };
        default:
            return {
                boldText: false,
                color: COLOR.black,
            };
    }
};

export const getInvoiceBusinessOrContactName = (contact?: ContactModel) => {
    if (!contact) {
        return '-';
    }

    const contactName = `${contact.firstName} ${
        contact.lastName ? `${contact.lastName}` : ''
    }`;

    return contact.businessName
        ? `${contactName} (${contact.businessName})`
        : contactName;
};

export const getInvoiceFileName = (invoiceNumber: string) =>
    `INV-${invoiceNumber}`;
