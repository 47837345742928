import { Add } from '@mui/icons-material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShoppingListsAnalytics } from 'shared/analytics/shoppingLists';
import { SHOPPING_LISTS } from 'shared/constants/firebase/firestore';
import Organisation from 'shared/db/services/Organisation';
import Product from 'shared/db/services/Product';
import ShoppingList from 'shared/db/services/ShoppingList';
import ShoppingListProduct from 'shared/db/services/ShoppingListProduct';
import User from 'shared/db/services/User';
import { AddProductParams } from 'shared/types/Products';
import {
    ShoppingListModel,
    ShoppingListPayload,
    ShoppingProductParserParams,
} from 'shared/types/ShoppingList';
import {
    ShoppingListProductChange,
    ShoppingListProductModel,
} from 'shared/types/ShoppingListProduct';
import moment from 'shared/utils/moment';
import {
    getAddProductsDictionary,
    getGroupedProducts,
} from 'shared/utils/product';
import {
    getShoppingListProducts,
    shoppingProductParser,
    updateShoppingListProductSize,
} from 'shared/utils/shoppingList';

import { BackLinkButton, ErrorState, RemoveEntityAlert } from '@/components';
import DuplicateEntityAlert from '@/components/DuplicateEntityAlert';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { formatDate } from '@/utils/date';
import { getRoutePath } from '@/utils/router';

import { ShoppingListDetails } from './components';
import AddShoppingListProductModal from './components/AddShoppingListProductModal';

function ShoppingListPage() {
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const { getDatabase } = useDatabase();
    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();

    const [shoppingList, setShoppingList] = useState<ShoppingListModel | null>(
        null,
    );
    const [shoppingListProducts, setShoppingListProducts] = useState<
        ShoppingListProductModel[]
    >([]);
    const [isError, setIsError] = useState(false);
    const [isRemoveShoppingListAlertOpen, setIsRemoveShoppingListAlertOpen] =
        useState<boolean>(false);
    const [
        isDuplicateShoppingListAlertOpen,
        setIsDuplicateShoppingListAlertOpen,
    ] = useState<boolean>(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

    const database = useMemo(() => getDatabase(), [getDatabase]);
    const shoppingListService = useMemo(() => {
        return new ShoppingList({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
            logError: Logger.logError,
        });
    }, [ImagesService, database]);

    const fetchShoppingList = useCallback(async () => {
        setIsError(false);

        try {
            const shoppingListService = new ShoppingList({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            });

            if (!id) {
                throw new Error();
            }

            const shoppingList = await shoppingListService.getByID(id);
            const products = await shoppingList.shoppingListProducts.fetch();

            setShoppingList(shoppingList);
            setShoppingListProducts(products);
        } catch {
            setIsError(true);
        }
    }, [ImagesService, database, id]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchShoppingList();
        }
    }, [fetchShoppingList, isInitialSyncInProgress]);

    const handleDeleteShoppingList = useCallback(
        () => setIsRemoveShoppingListAlertOpen(true),
        [],
    );

    const closeRemoveShoppingListAlert = useCallback(
        () => setIsRemoveShoppingListAlertOpen(false),
        [],
    );

    const handleDuplicateShoppingList = useCallback(
        () => setIsDuplicateShoppingListAlertOpen(true),
        [],
    );

    const closeDuplicateShoppingListAlert = useCallback(
        () => setIsDuplicateShoppingListAlertOpen(false),
        [],
    );

    const removeShoppingList = useCallback(async () => {
        try {
            if (!shoppingList) {
                throw new Error();
            }

            await FirebaseFirestoreAPI.removeDocumentInCollection(
                SHOPPING_LISTS,
                shoppingList.id,
            );
            await shoppingListService.deleteByID(shoppingList.id);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:shopping_list'),
                }),
            });

            navigate(ROUTE.shoppingLists, { replace: true });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [navigate, shoppingList, shoppingListService]);

    const duplicateShoppingList = useCallback(async () => {
        const todayDate = formatDate(moment().startOf('day').toISOString());
        const userId = userProfileData?.id || '';

        try {
            const params: ShoppingProductParserParams = {
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
                userId: userProfileData?.id,
            };
            const products = await shoppingList?.shoppingListProducts.fetch();

            const parsedProducts = await shoppingProductParser(
                database,
                products,
                params,
            );

            const payload: ShoppingListPayload = {
                name: `List ${todayDate}`,
                shoppingListProducts: parsedProducts,
            };

            const duplicatedShoppingList = await shoppingListService.add(
                payload,
                userId,
            );

            ShoppingListsAnalytics.logUserDuplicatedShoppingList(
                FirebaseAnalytics.logEvent,
            );

            const path = getRoutePath(ROUTE.shoppingList, {
                id: duplicatedShoppingList.id,
            });

            navigate(path, { replace: true });

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_duplicated_successfully', {
                    entity: t('Entities:shopping_list'),
                }),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [
        ImagesService,
        database,
        navigate,
        shoppingList?.shoppingListProducts,
        shoppingListService,
        userProfileData?.id,
    ]);

    const openAddProductModal = useCallback(
        () => setIsAddProductModalOpen(true),
        [],
    );

    const closeAddProductModal = useCallback(
        () => setIsAddProductModalOpen(false),
        [],
    );

    const handleAddProductsToShoppingList = useCallback(
        async (products: AddProductParams[]) => {
            try {
                if (!shoppingList || !id) {
                    throw new Error();
                }

                const userService = new User({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const productService = new Product({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                    logError: Logger.logError,
                });

                const shoppingListProductService = new ShoppingListProduct({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const organisationService = new Organisation({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const productDictionary = getAddProductsDictionary(products);
                const ids = Object.keys(productDictionary);

                const productSizesToAdd = await productService.getByIDs(ids);

                const allGroupedProductsFromShoppingList =
                    getGroupedProducts(productSizesToAdd);

                const shoppingListProductsToAdd = await getShoppingListProducts(
                    {
                        groupedProductsForShoppingList:
                            allGroupedProductsFromShoppingList,
                        userId: userProfileData?.id ?? '',
                        params: {
                            imageService: ImagesService,
                            logDBAction: Logger.logRecordActivity,
                            logError: Logger.logError,
                            userId: userProfileData?.id ?? '',
                        },
                        database,
                        userService,
                        productService,
                        shoppingListProducts,
                        productQuantities: productDictionary,
                    },
                );

                const shoppingListProductChanges: ShoppingListProductChange[] =
                    [];

                shoppingListProductsToAdd.forEach((product) =>
                    shoppingListProductChanges.push(
                        ...product.productSizes.map((size) => {
                            const product = productDictionary[size.productId];
                            return {
                                quantity:
                                    (size.quantity ?? 0) +
                                    (parseInt(product.quantity, 10) || 1),
                                productSize: size,
                                selectedUnit: product.unitType,
                            } as ShoppingListProductChange;
                        }),
                    ),
                );

                const updatedShoppingListProducts =
                    await updateShoppingListProductSize({
                        changes: shoppingListProductChanges,
                        shoppingListId: id,
                        userId: userProfileData?.id ?? '',
                        shoppingListProductService,
                        organisationService,
                    });

                if (updatedShoppingListProducts.length) {
                    ShoppingListsAnalytics.logUserUpdatedShoppingListProducts(
                        FirebaseAnalytics.logEvent,
                        {
                            changes: shoppingListProductChanges,
                            shoppingListProducts: updatedShoppingListProducts,
                        },
                    );
                }

                closeAddProductModal();
                fetchShoppingList();
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            shoppingList,
            id,
            database,
            ImagesService,
            userProfileData?.id,
            shoppingListProducts,
            closeAddProductModal,
            fetchShoppingList,
        ],
    );

    const handleAddToInventoryClick = useCallback(async () => {
        try {
            if (!shoppingList?.id) {
                throw new Error();
            }

            const shoppingListService = new ShoppingList({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            });

            await shoppingListService.addToInventory(
                shoppingList.id,
                userProfileData?.id ?? '',
            );

            ShoppingListsAnalytics.logUserAddedShoppingListToInventory(
                FirebaseAnalytics.logEvent,
            );

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_edited_successfully', {
                    entity: t('Inventory:view_title'),
                }),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('Inventory:errors:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [ImagesService, database, shoppingList?.id, userProfileData?.id]);

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <BackLinkButton
                    text={t('SingleShoppingList:button:back')}
                    to={ROUTE.shoppingLists}
                    testID="ShoppingListPage-BackButton"
                />
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TooltipSyncButton
                        showTooltip={isSyncInProgress}
                        disabled={isError || isSyncInProgress}
                        onClick={handleDeleteShoppingList}
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        sx={{ display: 'flex', gap: 1.5 }}
                        testID="ShoppingListPage-DeleteButton"
                        color="inherit"
                    >
                        <DeleteOutlineOutlinedIcon />
                        <Typography fontSize="small" fontWeight={700}>
                            {t('SingleShoppingList:button:delete')}
                        </Typography>
                    </TooltipSyncButton>
                    <TooltipSyncButton
                        disabled={isError || isSyncInProgress}
                        onClick={handleDuplicateShoppingList}
                        showTooltip={isSyncInProgress}
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        sx={{ display: 'flex', gap: 1.5 }}
                        testID="ShoppingListPage-DuplicateButton"
                        color="inherit"
                    >
                        <ContentCopyRoundedIcon />
                        <Typography fontSize="small" fontWeight={700}>
                            {t('SingleShoppingList:button:duplicate')}
                        </Typography>
                    </TooltipSyncButton>
                    <Button
                        data-test-id="ShoppingListPage-AddProductButton"
                        variant="contained"
                        startIcon={<Add />}
                        onClick={openAddProductModal}
                    >
                        <Typography
                            sx={{
                                ml: 1,
                                fontSize: 14,
                                fontWeight: 700,
                            }}
                        >
                            {t('SingleShoppingList:button:add_product')}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <RemoveEntityAlert
                dialogText={t('SingleShoppingList:alert:shopping_list_remove', {
                    name: shoppingList?.name ?? '',
                })}
                isOpen={isRemoveShoppingListAlertOpen}
                onClose={closeRemoveShoppingListAlert}
                onRemove={removeShoppingList}
                testID="RemoveShoppingListAlert"
            />
            <DuplicateEntityAlert
                dialogText={t('ShoppingList:alert:shopping_list_duplicate', {
                    name: shoppingList?.name ?? '',
                })}
                isOpen={isDuplicateShoppingListAlertOpen}
                onClose={closeDuplicateShoppingListAlert}
                onDuplicate={duplicateShoppingList}
                testID="ShoppingListActionAlert"
            />
            {isError ? (
                <ErrorState
                    onRefreshClick={fetchShoppingList}
                    testID="ShoppingListPage-ErrorState"
                />
            ) : (
                <ShoppingListDetails
                    shoppingList={shoppingList}
                    isLoading={isInitialSyncInProgress}
                    products={shoppingListProducts}
                    refetchShoppingList={fetchShoppingList}
                    onAddToInventoryClick={handleAddToInventoryClick}
                />
            )}
            {isAddProductModalOpen ? (
                <AddShoppingListProductModal
                    onCancel={closeAddProductModal}
                    onMainAction={handleAddProductsToShoppingList}
                />
            ) : null}
        </Box>
    );
}

export default ShoppingListPage;
