import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function NotchedOutlinedBox({ label, children, sx, ...props }: Props) {
    return (
        <Box
            sx={{
                position: 'relative',
                borderRadius: 1,
                borderWidth: '1px',
                borderColor: COLOR.black23,
                py: 2,
                ...sx,
            }}
            {...props}
        >
            <Typography
                fontSize="12px"
                fontWeight={400}
                color={COLOR.regentGray}
                sx={{
                    left: 8,
                    top: -10,
                    bgcolor: COLOR.white,
                    px: '5px',
                    lineHeight: '1.4375em',
                    position: 'absolute',
                }}
            >
                {label}
            </Typography>

            {children}
        </Box>
    );
}

export default NotchedOutlinedBox;
