export function validateQuantityValue(
    value: string,
    translateFn: (string: string) => void,
): Promise<void> {
    return new Promise((resolve, reject) => {
        const parsedValue = Number(value);

        if (!value.length) {
            reject(translateFn('Generic:error:you_have_to_provide_number'));
        }

        if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
            reject(translateFn('Generic:error:not_correct_number_value'));
        }

        if (parsedValue <= 0) {
            reject(translateFn('Generic:error:value_greater_than_zero'));
        }

        resolve();
    });
}

export function validatePriceValue(
    value: string,
    translateFn: (string: string) => void,
): Promise<void> {
    return new Promise((resolve, reject) => {
        const parsedValue = Number(value);

        if (!value.length) {
            reject(translateFn('Generic:error:you_have_to_provide_number'));
        }

        if (isNaN(parsedValue)) {
            reject(translateFn('Generic:error:not_correct_number_value'));
        }

        if (parsedValue < 0) {
            reject(translateFn('Generic:error:value_greater_or_equal_zero'));
        }

        resolve();
    });
}
