import { useEffect, useState } from 'react';
import { humanizeDateDiff } from 'shared/utils/date';

/** Returns "X time ago" string from passed date and update it in interval */
function useMomentFromNow(date: Date | string | number, interval?: number) {
    const [result, setResult] = useState(humanizeDateDiff(date));

    useEffect(() => {
        if (interval) {
            const int = setInterval(() => {
                setResult(humanizeDateDiff(date));
            }, interval);

            return () => clearInterval(int);
        }
    });

    return result;
}

export { useMomentFromNow };
