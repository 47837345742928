import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ScheduleIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <path
                d="M37.9 3.8h-4.6v-1c0-.5-.4-1-1-1s-1 .4-1 1v1H10.9v-1c0-.5-.4-1-1-1s-1 .4-1 1v1H4.6C2.2 3.8.3 5.7.3 8v28.5c0 2.3 1.9 4.2 4.2 4.2h33.3c2.3 0 4.2-1.9 4.2-4.2V8c.2-2.3-1.7-4.2-4.1-4.2zM8.6 34.7c0 .5-.4.9-1 .9H5c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.1c0 .5-.4.9-1 .9H5c-.5 0-1-.4-1-.9V26c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9H5c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm7.5 12.3c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.1c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9V26c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm7.4 18.5c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.1c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9V26c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zM31 28.6c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9V26c0-.5.4-.9 1-.9H30c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9H30c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9H30c.5 0 1 .4 1 .9v2.6zm7.4 12.4c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9V26c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm0-6.2c0 .5-.4.9-1 .9h-2.6c-.5 0-1-.4-1-.9v-2.6c0-.5.4-.9 1-.9h2.6c.5 0 1 .4 1 .9v2.6zm1.8-8.1H2.3V8c0-1.2 1-2.2 2.2-2.2H9c0 .5.4 1 1 1s1-.4 1-1h20.4c0 .5.4 1 1 1 .5 0 1-.4 1-1H38c1.2 0 2.2 1 2.2 2.2v.1z"
                fill={color}
            />
        </svg>
    );
}

export default memo(ScheduleIcon);
