import React, { memo } from 'react';
import { generateDummyArray } from 'shared/utils/other';

import { TableSkeletonItem } from './components';
import { Props } from './types';

function TableSkeleton({ numberOfItems = 5 }: Props) {
    const skeletonsArray = generateDummyArray(numberOfItems);

    return (
        <>
            {skeletonsArray.map((item) => (
                <TableSkeletonItem key={item} />
            ))}
        </>
    );
}

export default memo(TableSkeleton);
