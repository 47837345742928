import { Moment } from 'moment';
import * as yup from 'yup';

import { t } from '@/services/translations/config';

const addEditEntryForm = yup.object().shape({
    date: yup
        .mixed()
        .required(
            t('SignUp:error:field_required', {
                field: t('AddEventForm:labels:date'),
            }),
        )
        .test(
            'date',
            t('AddEventForm:error:date_validation'),
            (value: Moment | null) => !!value?.isValid(),
        ),
    time: yup
        .mixed()
        .required(
            t('SignUp:error:field_required', {
                field: t('AddEventForm:labels:start_time'),
            }),
        )
        .test(
            'startTime',
            t('AddEventForm:error:start_time_validation'),
            (value: Moment | null) => !!value?.isValid(),
        ),
});

export const EntryValidationSchema = { addEditEntryForm };
