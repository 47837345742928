import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { HeaderProps } from 'react-big-calendar';
import { weekDays } from 'shared/constants/date';

function MonthColumnHeader({ label }: HeaderProps) {
    const weekDay = useMemo(() => t(weekDays[label]), [label]);
    return (
        <Box sx={{ padding: '10px', textAlign: 'start' }}>
            <Typography
                sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '22px' }}
            >
                {weekDay}
            </Typography>
        </Box>
    );
}

export default MonthColumnHeader;
