import { Avatar, AvatarGroup, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { UserWithColor } from '@/services/user/types';
import { mapUsersWithColors } from '@/services/user/user';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function OrganisationMembersAvatarGroup({
    users,
    database,
    max,
    testID,
    size,
    fontSize,
    borderSize,
}: Props) {
    const [usersWithColors, setUsersWithColors] = useState<UserWithColor[]>([]);

    const updateUsersWithColors = useCallback(async () => {
        setUsersWithColors(await mapUsersWithColors(users, database));
    }, [database, users]);

    useEffect(() => {
        updateUsersWithColors();
    }, [updateUsersWithColors]);

    return (
        <AvatarGroup
            sx={{
                '& .MuiAvatar-root': {
                    width: size,
                    height: size,
                    fontSize,
                    border: `${borderSize}px solid ${COLOR.white} !important`,
                },
            }}
            data-test-id={testID}
            max={max}
        >
            {usersWithColors.map((userWithColor) => {
                const avatarText = `${userWithColor.user.firstName?.[0] ?? ''}${
                    userWithColor.user.lastName?.[0] ?? ''
                }`;

                return (
                    <Avatar
                        sx={{
                            width: size,
                            height: size,
                            backgroundColor:
                                userWithColor.userColor ?? 'lightgray',
                            border: `${borderSize}px solid ${COLOR.white} !important`,
                        }}
                        variant="circular"
                        key={userWithColor.user.id}
                        data-test-id={`${testID}${avatarText}`}
                    >
                        <Typography sx={{ fontSize }}>{avatarText}</Typography>
                    </Avatar>
                );
            })}
        </AvatarGroup>
    );
}

export default OrganisationMembersAvatarGroup;
