import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import clsx from 'clsx';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useMemo } from 'react';

import { CalendarEventIcon } from '@/assets/svg';
import { Modal } from '@/components';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import { getAddToCalendarDateProps } from '../../getAddToCalendarProps';
import { INVITE_STATUS } from '../../useExternalAppointmentPage';
import AttendeesSection from '../AttendeesSection';
import HorsesSection from '../HorsesSection';
import NotesSection from '../NotesSection';

import { Props } from './types';

function DesktopView({
    appointmentDetails,
    status,
    isLoading,
    showCancellationModal,
    hideCancellationModal,
    cancelAppointment,
    isAppointmentCancelled,
}: Props) {
    const parsedDate = useMemo(
        () =>
            DateTime.fromFormat(
                appointmentDetails?.date || '',
                'ccc, d LLLL, y',
            ),
        [appointmentDetails?.date],
    );

    const appointmentCategory = useMemo(() => {
        if (isAppointmentCancelled) {
            return t('ExternalAppointment:labels:cancelled_appointment');
        }

        if (appointmentDetails?.category === 'service') {
            return t('ExternalAppointment:labels:service_appointment');
        }
        return t('ExternalAppointment:labels:appointment');
    }, [appointmentDetails?.category, isAppointmentCancelled]);

    const lastUpdatedLabel = useMemo(() => {
        return t('ExternalAppointment:labels:last_update', {
            date: formatDate(
                moment(appointmentDetails?.updatedAt).toISOString(),
                'datetime',
            ),
        });
    }, [appointmentDetails?.updatedAt]);

    const isRejected = status === INVITE_STATUS.REJECTED;
    const isExpired = status === INVITE_STATUS.EXPIRED;

    const cancellationModal = useMemo(() => {
        return (
            <Modal
                isOpen
                center
                disableRestoreFocus
                styles="w-[591px] px-6 py-5"
                modalContentStyles="hidden md:flex"
            >
                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-1">
                        <ArrowBackIcon sx={{ color: COLOR.black }} />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '24px',
                                color: COLOR.black,
                            }}
                        >
                            {t(
                                'ExternalAppointment:actions:cancel_appointment',
                            )}
                        </Typography>
                    </div>
                    <Button
                        variant="text"
                        disableElevation
                        onClick={hideCancellationModal}
                        startIcon={<CloseIcon sx={{ color: COLOR.black }} />}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: COLOR.gameboyGrey,
                            }}
                        >
                            {t(
                                'ExternalAppointment:actions:cancel_close_action',
                            )}
                        </Typography>
                    </Button>
                </div>
                <div
                    className={clsx('mt-5 w-full border-md', {
                        'bg-stateYellow': isRejected,
                    })}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: isRejected ? '700' : '300',
                            lineHeight: '26px',
                            color: COLOR.ebonyClay,
                            p: 1,
                        }}
                    >
                        {t(
                            isRejected
                                ? 'ExternalAppointment:actions:cancelled_disclaimer'
                                : 'ExternalAppointment:actions:cancel_disclaimer',
                        )}
                    </Typography>
                </div>
                <div className="flex flex-row w-full pt-5">
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={cancelAppointment}
                        className="py-4 w-full"
                        disabled={isLoading || isRejected}
                        sx={{
                            background:
                                status !== INVITE_STATUS.REJECTED
                                    ? `linear-gradient(${COLOR.cancelRed}, ${COLOR.cancelRedLight})`
                                    : `linear-gradient(${COLOR.cancelRed20}, ${COLOR.cancelRedLight20})`,
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={10}
                                sx={{ color: 'inherit' }}
                            />
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '19.5px',
                                    color: COLOR.white,
                                }}
                            >
                                {t(
                                    isRejected
                                        ? 'ExternalAppointment:actions:cancelled_appointment'
                                        : 'ExternalAppointment:actions:cancel_appointment',
                                )}
                            </Typography>
                        )}
                    </Button>
                </div>
            </Modal>
        );
    }, [
        cancelAppointment,
        hideCancellationModal,
        isLoading,
        status,
        isRejected,
    ]);

    return (
        <>
            <Box
                className="hidden md:flex flex-1"
                sx={{
                    height: '100%',
                    background: `linear-gradient(180deg, ${COLOR.desktopGradientGrey}, ${COLOR.white}, ${COLOR.amazingWhiterGrey})`,
                }}
            >
                <div className="flex flex-col w-full md:px-24 lg:px-48 pt-9">
                    <div className="flex flex-row h-[195px] items-stretch rounded-t-lg bg-headerGrey">
                        <div className="w-[128px] h-full bg-dateWidgetGrey rounded-t-lg">
                            <div className="pt-6 px-8">
                                <Typography
                                    sx={{
                                        fontSize: '21px',
                                        fontWeight: '300',
                                        lineHeight: '25px',
                                        textAlign: 'center',
                                        color: COLOR.white,
                                        textDecoration: isAppointmentCancelled
                                            ? 'line-through'
                                            : undefined,
                                    }}
                                >
                                    {parsedDate.weekdayShort || ''}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '54px',
                                        fontWeight: '700',
                                        lineHeight: '62px',
                                        textAlign: 'center',
                                        color: COLOR.white,
                                        textDecoration: isAppointmentCancelled
                                            ? 'line-through'
                                            : undefined,
                                    }}
                                >
                                    {parsedDate.day || ''}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '21px',
                                        fontWeight: '300',
                                        lineHeight: '25px',
                                        textAlign: 'center',
                                        color: COLOR.white,
                                        textDecoration: isAppointmentCancelled
                                            ? 'line-through'
                                            : undefined,
                                    }}
                                >
                                    {parsedDate.monthShort || ''}
                                </Typography>
                            </div>
                        </div>
                        <div
                            className={clsx(
                                'grow flex flex-col justify-between pl-6 pt-5',
                                {
                                    'opacity-50': isExpired,
                                },
                            )}
                        >
                            <div>
                                <div className="flex flex-row items-center gap-1">
                                    {appointmentDetails?.category ===
                                    'service' ? (
                                        <HardwareIcon
                                            sx={{
                                                marginLeft: '-3px',
                                                color: isAppointmentCancelled
                                                    ? COLOR.cancelledGrey
                                                    : undefined,
                                            }}
                                        />
                                    ) : (
                                        <CalendarEventIcon
                                            width={26}
                                            height={28}
                                            color={
                                                isAppointmentCancelled
                                                    ? COLOR.cancelledGrey
                                                    : COLOR.black
                                            }
                                        />
                                    )}

                                    <Typography
                                        sx={{
                                            fontSize: '28px',
                                            fontWeight: '700',
                                            lineHeight: '33px',
                                            color: isAppointmentCancelled
                                                ? COLOR.cancelledGrey
                                                : COLOR.desktopTitleBlack,
                                            textDecoration:
                                                isAppointmentCancelled
                                                    ? 'line-through'
                                                    : undefined,
                                        }}
                                    >
                                        {appointmentDetails?.title}
                                    </Typography>
                                </div>
                                <div className="pt-2">
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '300',
                                            lineHeight: '24px',
                                            color: COLOR.desktopTitleBlack,
                                            textDecoration:
                                                isAppointmentCancelled
                                                    ? 'line-through'
                                                    : undefined,
                                        }}
                                    >
                                        {appointmentDetails?.date}
                                    </Typography>
                                </div>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '24px',
                                        color: COLOR.ebonyClay,
                                        textDecoration: isAppointmentCancelled
                                            ? 'line-through'
                                            : undefined,
                                    }}
                                >
                                    {appointmentDetails?.time}
                                </Typography>
                            </div>
                            {appointmentDetails?.address ? (
                                <div className="flex flex-row items-center pb-4 gap-1">
                                    <LocationOnIcon
                                        sx={{
                                            color: COLOR.paleSky,
                                            marginLeft: '-3px',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            lineHeight: '16px',
                                            color: COLOR.paleSky,
                                        }}
                                    >
                                        {appointmentDetails.address}
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                        <div className="max-w-[295px] px-5 mt-5 mb-4 border-l-[1px] border-borderGrey">
                            <div className="flex flex-col justify-between h-full">
                                <div>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            color: isAppointmentCancelled
                                                ? COLOR.errorRed
                                                : COLOR.accountSetupBlack,
                                        }}
                                    >
                                        {appointmentCategory}
                                    </Typography>
                                    <Typography
                                        className="pt-2"
                                        sx={{
                                            fontSize: '11px',
                                            fontWeight: '700',
                                            lineHeight: '18px',
                                            color: COLOR.accountSetupBlack59,
                                        }}
                                    >
                                        {t(
                                            'ExternalAppointment:labels:created_by',
                                            {
                                                name: appointmentDetails?.author
                                                    .name,
                                            },
                                        )}
                                        {appointmentDetails?.author
                                            .business_name
                                            ? ` (${appointmentDetails.author.business_name})`
                                            : null}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '11px',
                                            fontWeight: '400',
                                            lineHeight: '18px',
                                            color: COLOR.accountSetupBlack59,
                                        }}
                                    >
                                        {lastUpdatedLabel}
                                    </Typography>
                                </div>
                                {!isAppointmentCancelled && !isExpired ? (
                                    <div>
                                        <AddToCalendarButton
                                            name={
                                                appointmentDetails?.title || ''
                                            }
                                            description={
                                                appointmentDetails?.notes || ''
                                            }
                                            {...getAddToCalendarDateProps(
                                                appointmentDetails?.starts_at,
                                                appointmentDetails?.ends_at,
                                            )}
                                            location={
                                                appointmentDetails?.address ||
                                                ''
                                            }
                                            organizer={`${appointmentDetails?.author.name}|${appointmentDetails?.author.email}`}
                                            status="CONFIRMED"
                                            options={[
                                                'Apple',
                                                'Google',
                                                'iCal',
                                                'Outlook.com',
                                                'Yahoo',
                                            ]}
                                            buttonStyle="text"
                                            trigger="click"
                                            hideBackground
                                            styleLight="--btn-border-top: #2f4377"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-row gap-14">
                            {appointmentDetails?.notes ||
                            appointmentDetails?.horses.length ? (
                                <div className="flex flex-col flex-1">
                                    {appointmentDetails?.notes ? (
                                        <NotesSection
                                            notes={appointmentDetails.notes}
                                            style="pl-5 py-1"
                                            lineThrough={isAppointmentCancelled}
                                        />
                                    ) : null}
                                    {appointmentDetails?.horses.length ? (
                                        <HorsesSection
                                            horses={appointmentDetails.horses}
                                            isCancelled={isAppointmentCancelled}
                                            style="pl-5 py-1"
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                            {appointmentDetails?.attendees.length ? (
                                <div className="flex flex-col flex-1">
                                    <AttendeesSection
                                        attendees={appointmentDetails.attendees}
                                        isCancelled={isAppointmentCancelled}
                                        style="pl-5 py-1"
                                    />
                                </div>
                            ) : null}
                            {(!appointmentDetails?.notes &&
                                !appointmentDetails?.horses.length) ||
                            !appointmentDetails?.attendees.length ? (
                                <div className="flex flex-col flex-1"></div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Box>
            {showCancellationModal ? cancellationModal : null}
        </>
    );
}

export default DesktopView;
