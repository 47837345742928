import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import {
    HorseContactModel,
    HorseContactPayload,
} from '../../types/HorseContact';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class HorseContact {
    private database: Database;
    private collection: Collection<HorseContactModel>;
    private table = 'horse_contacts';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: HorseContactPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdHorseContact = await this.collection.create(
                (horseContact) => {
                    horseContact.horseId = payload.horseId;
                    horseContact.contactId = payload.contactId;
                    horseContact.userId = userId;
                    horseContact.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create horse contact',
                modelName: this.table,
                payload: createdHorseContact,
            });
        });
    }

    async deleteByID(id: string) {
        const horseContactElement = await this.getByID(id);

        await this.database.write(async () => {
            await horseContactElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete horse contact',
                modelName: this.table,
                payload: horseContactElement,
            });
        });
    }
}

export default HorseContact;
