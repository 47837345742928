import { SyncStatusArgs, SyncStatusType } from '../types/sync';

export const getSyncStatus = ({
    hasSyncIssue,
    isOnline,
    isSyncInProgress,
    isUnsynced,
}: SyncStatusArgs): SyncStatusType => {
    if (hasSyncIssue) {
        return 'error';
    }

    if (isOnline) {
        if (isSyncInProgress) {
            return 'in_progress';
        } else {
            return isUnsynced ? 'waiting' : 'synced';
        }
    }

    return 'offline';
};

export const getStatusMessage = (
    status: SyncStatusType,
    t: Function,
): string => {
    switch (status) {
        case 'offline':
            return t('SyncStatus:offline', {
                defaultValue: 'Working offline',
            });

        case 'in_progress':
            return t('SyncStatus:in_progress', {
                defaultValue: 'Syncing data...',
            });

        case 'waiting':
            return t('SyncStatus:waiting', {
                defaultValue: 'Waiting for sync...',
            });

        case 'error':
            return t('SyncStatus:error', {
                defaultValue: 'Data sync error',
            });

        case 'synced':
        default:
            return t('SyncStatus:synced', {
                defaultValue: 'All data synced',
            });
    }
};
