// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class ShoppingListProduct extends Model {
    static table = 'shopping_list_products';

    static associations = {
        shopping_lists: { type: 'belongs_to', key: 'shopping_list_id' },
        products: { type: 'belongs_to', key: 'product_id' },
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
    };

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('shopping_lists', 'shopping_list_id') shoppingList;
    @relation('products', 'product_id') product;
    @relation('product_sales_units', 'product_sales_unit_id')
    product_sales_unit;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('shopping_list_id') shoppingListId;
    @field('product_id') productId;
    @field('product_sales_unit_id') productSalesUnitId;
    @field('unit_type') unitType;
    @field('quantity') quantity;
    @field('total_quantity') totalQuantity;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
