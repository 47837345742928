import { INVOICES_STATUS_FILTERS_DICTIONARY } from 'shared/constants/invoices/statuses';
import { InvoicesFiltersObject } from 'shared/types/invoicesFilters';
import moment from 'shared/utils/moment';

import { SelectOption } from '@/types/select';

import { generateSelectOptionsFromDictionary } from '../filters';

export function getInvoiceStatusFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(
        INVOICES_STATUS_FILTERS_DICTIONARY,
    );
}

export function getInitialDateRangeFilterValue(): InvoicesFiltersObject['date_range'] {
    const todayDate = moment().endOf('day').toDate();
    const monthAgoDate = moment(todayDate)
        .subtract(1, 'months')
        .endOf('day')
        .toDate();

    return {
        isActive: false,
        from: moment(monthAgoDate),
        to: moment(todayDate),
    };
}
