// Colors' names have been taken from https://chir.ag/projects/name-that-color

export enum COLOR {
    athensGray = '#F9FAFB',
    bahamaBlue = '#005892',
    deepCerulean = '#0057B8',
    deepCerulean08 = 'rgba(0, 109, 180, 0.08)',
    ebonyClay = '#212B36',
    transparentEbonyClay = 'rgba(33, 43, 54, 0.6)',
    formError = '#C23F38',
    geyser = '#DCE0E4',
    paleSky = '#637381',
    backgroundGrey = '#6B72800D',
    paleSky02 = 'rgba(145, 158, 171, 0.8)',
    regentGray = '#919EAB',
    transparentLightBlue = 'rgba(239, 246, 255, 0.5)',
    lightGrayishBlue = 'rgb(217, 238, 251)',
    transparentLightGrayishBlue = 'rgba(217, 238, 251, 0.5)',
    lightGrayishBlue2 = 'rgb(244, 246, 248)',
    transparentLightGrayishBlue2 = 'rgba(244, 246, 248, 0.5)',
    transparentLightGrayBackground = 'rgb(220, 224, 228, 0.5)',
    black = 'rgba(0, 0, 0)',
    black60 = 'rgba(0, 0, 0, 0.6)',
    black23 = 'rgba(0, 0, 0, 0.23)',
    codGray = '#171717',
    activeBlack = '#2D363E',
    white = '#FFFFFF',
    whiteDarker = '#F4F4F4',
    whiteDarker2 = '#FDFDFD',
    white60 = 'rgba(255, 255, 255, 0.6)',
    white15 = 'rgba(255, 255, 255, 0.15)',
    successGreen = '#229A16',
    successLightGreen = '#C8E6C9',
    errorRed = '#B72136',
    warningYellow = '#B78103',
    lightWarningYellow = 'rgb(255, 247, 205)',
    transparentLightWarningYellow = 'rgba(255, 247, 205, 0.5)',
    vividYellow = '#FFC107',
    vividYellow12 = 'rgba(255, 193, 7, 0.12)',
    lightBlue = '#D9EEFB',
    lightGray = '#DFE3E8',
    settingsGray = '#F8F8F8',
    wineBerry = '#5F2120',
    scorpion = '#787878',
    badgeGrey = '#00000033',
    badgeTextGrey = '#1D1D1D',
    black89 = '#00000089',
    gray = '#848484',
    gray2 = '#868686',
    eclipse = '#393939',
    gainsboro = '#DCDCDC',
    mischka = '#A7A8AA',
    cranberry = '#EA4C65',
    osloGray = '#888D92',
    alto = '#D5D5D5',
    accountSetupBlue = '#F0F4FA',
    accountSetupBlack = '#212B36',
    accountSetupBlack59 = 'rgba(33, 43, 54, 0.59)',
    accountSetupBlack_50 = 'rgba(33, 43, 54, 0.5)',
    whiteSmoke = '#F5F5F5',
    silver = '#C6C6C6',
    skyBlue = '#006DB4',
    scheduleDarkerBlue = '#346382',
    scrollGray = '#C7C7C7',
    aliceBlue = '#ECF2F8',
    athensGrayDarker = '#F0F2F4',
    cozumel = '#09B59A',
    ghost = '#B6B6B8',
    heartPurple = '#ad154f',
    mexicanRed = '#963B3B',
    anotherGrey = '#8B8B8B',
    vividBlack = '#212121',
    iconGrey = '#6F6F6F',
    vscBlack = 'rgba(33, 43, 54, 1)',
    darkYellow = '#8D642D',
    shoppingListAvatar = '#E9EDEE',
    azure = '#E9F2F2',
    tutu = '#FDEBEB',
    persianRed = '#D82B2B',
    persianGreen = '#06B499',
    suvaGrey = '#909090',
    englishLightGreen = '#20A16F',
    englishLightGreen10 = 'rgba(32, 161, 111, 0.1)',
    englishVividGreen = '#2BAD7B',
    englishVividGreen10 = 'rgba(43, 173, 123, 0.1)',
    rescheduleYellow = '#D8812B',
    coolestGrey = '#575757',
    amazingWhiterGrey = '#FAFAFA',
    gandalfGrey = '#F0F2F2',
    gameboyGrey = '#4D4D4D',
    battleRed = '#AF1311',
    borderGrey = '#DBDBDB',
    cancelledGrey = 'rgba(38, 38, 38, 0.5)',
    avatarGrey = '#707070',
    desktopGradientGrey = '#EAEAEA',
    dateWidgetGrey = '#62686F',
    headerGrey = '#F5F6F7',
    desktopTitleBlack = '#262626',
    cancelRed = 'rgba(198, 69, 69, 1)',
    cancelRed20 = 'rgba(198, 69, 69, 0.7)',
    cancelRedLight = 'rgba(211, 79, 99, 1)',
    cancelRedLight20 = 'rgba(211, 79, 99, 0.7)',
    stateYellow = '#F9F5EE',
    spanishRed = '#D82B2B',
    confirmGreen = 'rgba(8, 180, 153, 1)',
    sandwisp = '#F4DF9C',
    nevada = '#5D7181',
    alabaster = '#F8F8F8',
}

export enum ENTITY_COLOR {
    dashboard = '#0057B8',
    contacts = '#986E80',
    entries = '#4E6088',
    schedule = '#3A6E91',
    inventory = '#958A61',
    invoices = '#579881',
    shoppingLists = '#BA835A',
    horses = '#77766E',
    settings = '#535B5F',
    teams = '#6A5E97',
    formErrorRed = '#d32f2f',
}

export enum ENTITY_SECONDARY_COLOR {
    dashboard = '#0F8DCD',
    contacts = '#B38C9C',
    entries = '#8A97B3',
    schedule = '#6C95B0',
    inventory = '#ABA17D',
    invoices = '#7DB5A2',
    shoppingLists = '#CB946C',
    horses = '#9B9B94',
    settings = '#71797C',
    teams = '#8D83B0',
}

export enum ENTITY_COLOR_TRANSPARENT_40 {
    invoices = 'rgba(87, 152, 129, 0.4)',
}
