import { Box } from '@mui/material';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import SidebarLinksList from '@/components/SidebarLinksList';

import { Props } from './types';

function SidebarFullVersion({ links }: Props) {
    const { pathname: currentPathname } = useLocation();

    return (
        <Box sx={{ mb: 6 }}>
            <SidebarLinksList currentPathname={currentPathname} links={links} />
        </Box>
    );
}

export default memo(SidebarFullVersion);
