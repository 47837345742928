// INFO: Color names are generated from http://chir.ag/projects/name-that-color

export enum COLOR {
    black = '#000000',
    flamingo = '#EA4C65',
    gray = '#7F7F7F',
    green = '#579881',
    light_green = '#57988133',
    maroon = '#AE154E',
    light_gray = '#00000029',
    dark_yellow = '#8D642D',
}
