// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Procedure extends Model {
    static table = 'procedures';

    static associations = {
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
    };

    @lazy
    entries = this.collections
        .get('entries')
        .query(Q.on('entry_procedures', 'procedure_id', this.id));

    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('category') category;
    @field('name') name;
    @field('description') description;
    @field('price') price;
    @field('available') available;

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
