import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { HorsesAnalytics } from 'shared/analytics/horses';
import Horse from 'shared/db/services/Horse';
import { ContactModel } from 'shared/types/Contacts';
import { HorseModel, HorsePayload } from 'shared/types/Horses';
import { Location } from 'shared/types/Location';
import { diffBetweenDatesByRange, getLocal } from 'shared/utils/date';
import {
    getHorseBreeds,
    getHorseGenders,
    getHorseShoeingCycleWeeks,
    getHorseWorkTypes,
} from 'shared/utils/horses';
import moment from 'shared/utils/moment';

import { HorseAvatar, HorseIcon } from '@/assets/svg';
import {
    AddEditModalHeader,
    AlertDialog,
    GooglePlacesAutocomplete,
    Modal,
    PhoneNumber,
    PhotoSelector,
    RemoveEntityAlert,
} from '@/components';
import BackdropLoader from '@/components/Loaders/BackdropLoader';
import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useDatabase,
    useCancelEntityFormAlert,
    useEscapeButton,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';
import { HorseValidationSchema } from '@/validations/horse';

import SelectContactsSection from '../SelectContactsSection';
import TooltipSyncButton from '../TooltipButton';

import { AddEditHorseFormValues, Props } from './types';

interface ListInterface {
    value: string;
    name: string;
}

const foalDateFormat = 'YYYY-MM-DD';

const INITIAL_VALUES: AddEditHorseFormValues = {
    foalDate: '',
    age: '',
    name: '',
    comments: '',
    gender: '',
    contacts: [],
    breed: '',
    workType: '',
    stablePhone: {
        full: '',
        prefix: '',
        number: '',
    },
    visible: true,
    shoeingCycle: '',
    shoeingCycleNotify: false,
    stableAddress: '',
    stableName: '',
    microchipNo: '',
    sireName: '',
    damName: '',
};

function AddEditHorseModal({
    close,
    isEditMode,
    horse,
    contact,
    onSave,
    CreateContactModal,
    onRemove,
    closeOnSubmit = false,
    forceContactsSelection = true,
    allowContactsSelection = true,
}: Props) {
    const { userProfileData } = useUserContext();
    const { images, ImagesService, isImagesSyncInProgress } =
        useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    const database = useDatabase();
    const { t } = useTranslation();

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const [initialValues, setInitialValues] =
        useState<AddEditHorseFormValues>(INITIAL_VALUES);
    const [isRemoveHorseAlertVisible, setIsRemoveHorseAlertVisible] =
        useState(false);
    const [currentAvatarUrl, setCurrentAvatarUrl] = useState<
        string | null | undefined
    >(null);
    const [isCreating, setIsCreating] = useState(false);
    const previousContactsRef = useRef<ContactModel[]>([]);
    const previousAgeRef = useRef<string>();

    const horseAvatar = useMemo(() => {
        if (!horse) {
            return null;
        }

        return ImagesService.getSingleImageByEntityId(images, horse.id);
    }, [horse, images, ImagesService]);

    const horseAvatarUrl = horseAvatar?.imageURL;

    useEffect(() => setCurrentAvatarUrl(horseAvatarUrl), [horseAvatarUrl]);

    useEffect(() => {
        if (contact) {
            const initialValues = { ...INITIAL_VALUES };
            initialValues['contacts'] = [contact];
            setInitialValues(initialValues);
        }
    }, [contact]);

    const prepareInitialValues = useCallback(async () => {
        if (!horse) {
            return;
        }

        try {
            const horseContacts = await horse.contacts.fetch();
            const horseStableLocation = await horse.location?.fetch();

            const age = horse.foalDate
                ? diffBetweenDatesByRange(
                      getLocal(),
                      horse.foalDate,
                      'years',
                  ).toString()
                : '';

            previousContactsRef.current = horseContacts;

            previousAgeRef.current = age;

            const values: AddEditHorseFormValues = {
                foalDate: horse.foalDate || '',
                age,
                name: horse.name || '',
                comments: horse.comments || '',
                gender: horse.gender || '',
                contacts: horseContacts || [],
                breed: horse.breed || '',
                workType: horse.workType || '',
                stablePhone: {
                    full:
                        horse.stablePhonePrefix && horse.stablePhone
                            ? `${horse.stablePhonePrefix}${horse.stablePhone}`
                            : '',
                    number: horse.stablePhone || '',
                    prefix: horse.stablePhonePrefix || '',
                },
                visible: !horse.hidden,
                shoeingCycle: horse.shoeingCycle
                    ? horse.shoeingCycle?.toString()
                    : '',
                shoeingCycleNotify: !!horse.shoeingCycleNotify,
                stableAddress: horse.stableAddress || '',
                stableName: horse.stableName || '',
                microchipNo: horse.microchipNo || '',
                sireName: horse.sireName || '',
                damName: horse.damName || '',
                location: horseStableLocation || null,
            };

            setInitialValues(values);
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [horse, t]);

    useEffect(() => {
        if (isEditMode) {
            prepareInitialValues();
        }
    }, [isEditMode, prepareInitialValues]);

    const [isNoContactsAlertOpen, setIsNoContactsAlertOpen] =
        useState<boolean>(false);

    const openNoContactsAlert = useCallback(
        () => setIsNoContactsAlertOpen(true),
        [setIsNoContactsAlertOpen],
    );

    const closeNoContactsAlert = useCallback(
        () => setIsNoContactsAlertOpen(false),
        [setIsNoContactsAlertOpen],
    );

    const handleSubmit = useCallback(
        async (values: AddEditHorseFormValues) => {
            if (forceContactsSelection && !values.contacts?.length) {
                openNoContactsAlert();
                return;
            }

            setIsCreating(true);

            try {
                const horseService = new Horse({
                    database: database.getDatabase(),
                    logDBAction: Logger.logRecordActivity,
                    imageService: ImagesService,
                });

                const {
                    foalDate,
                    name,
                    age,
                    stableAddress,
                    shoeingCycleNotify,
                    shoeingCycle,
                    stablePhone,
                    visible,
                    workType,
                    breed,
                    gender,
                    comments,
                    stableName,
                    contacts,
                    location,
                    microchipNo,
                    sireName,
                    damName,
                } = values;

                const payload: HorsePayload = {
                    breed,
                    gender,
                    comments,
                    stableName,
                    foalDate: foalDate || '',
                    name,
                    hidden: !visible,
                    stablePhone: stablePhone.number,
                    stablePhonePrefix:
                        stablePhone.prefix && stablePhone.number
                            ? stablePhone.prefix
                            : '',
                    shoeingCycle: shoeingCycle
                        ? parseInt(shoeingCycle, 10)
                        : null,
                    shoeingCycleNotify,
                    stableAddress,
                    workType,
                    microchipNo,
                    sireName,
                    damName,
                    contacts,
                    location,
                    removeAvatar: isEditMode && !currentAvatarUrl,
                    image: currentAvatarUrl
                        ? {
                              uri: currentAvatarUrl,
                              documentID: horseAvatar?.documentID,
                          }
                        : undefined,
                };

                const userId = userProfileData?.id || '';

                let newUpdatedHorse: HorseModel;

                if (isEditMode) {
                    if (!horse?.id) {
                        throw new Error();
                    }

                    newUpdatedHorse = await horseService.update(
                        horse.id,
                        payload,
                        userId,
                    );

                    HorsesAnalytics.logUserUpdatedHorse(
                        FirebaseAnalytics.logEvent,
                        {
                            age,
                            horse: newUpdatedHorse,
                            previousContacts: previousContactsRef.current,
                            previousGender: horse.gender,
                            previousBreed: horse.breed,
                            previousWorkType: horse.workType,
                            previousShoeingCycle: horse.shoeingCycle,
                            previousAge: previousAgeRef.current,
                        },
                    );
                } else {
                    newUpdatedHorse = await horseService.add(payload, userId);

                    HorsesAnalytics.logUserCreatedHorse(
                        FirebaseAnalytics.logEvent,
                        {
                            age,
                            horse: newUpdatedHorse,
                            isOwner: userOrganisation?.owner ?? true,
                        },
                    );
                }

                if (onSave) {
                    onSave(newUpdatedHorse);
                }

                let toastNotificationMessage = t(
                    'App:Messages:has_been_created_successfully',
                    {
                        entity: t('Entities:horse'),
                    },
                );

                if (isEditMode) {
                    toastNotificationMessage = t(
                        'App:Messages:has_been_edited_successfully',
                        {
                            entity: t('Entities:horse'),
                        },
                    );
                }

                Snackbar.showToastNotification({
                    message: toastNotificationMessage,
                });

                if (closeOnSubmit || isEditMode) {
                    close();
                }
            } catch {
                setIsCreating(false);

                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            forceContactsSelection,
            openNoContactsAlert,
            database,
            ImagesService,
            isEditMode,
            currentAvatarUrl,
            horseAvatar?.documentID,
            userProfileData?.id,
            onSave,
            t,
            closeOnSubmit,
            horse?.id,
            horse?.gender,
            horse?.breed,
            horse?.workType,
            horse?.shoeingCycle,
            userOrganisation?.owner,
            close,
        ],
    );

    const form = useFormik<AddEditHorseFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: HorseValidationSchema.addEditHorseForm,
    });

    const handleSaveButtonClick = useCallback(
        () => form.handleSubmit(),
        [form],
    );

    const onDateChanged = useCallback(
        (value: moment.Moment | null) => {
            const date = value?.format(foalDateFormat) || null;

            if (date) {
                storeItemInLocalStorage(WEB_STORAGE_KEYS.lastBirthDate, date);
            }

            return Promise.all([
                form.setFieldValue('foalDate', date || ''),
                form.setFieldValue(
                    'age',
                    date
                        ? diffBetweenDatesByRange(
                              getLocal(),
                              date,
                              'years',
                          ).toString()
                        : '',
                ),
            ]);
        },
        [form],
    );

    const onPhoneNumberChange = useCallback(
        (
            phone: string,
            data: {
                name: string;
                dialCode: string;
                countryCode: string;
            },
        ) => {
            form.setFieldValue('stablePhone', {
                full: phone,
                number: data.dialCode
                    ? phone.substring(data.dialCode.length + 1)
                    : '',
                prefix: data.dialCode ? `+${data.dialCode}` : '',
            });
        },
        [form],
    );

    const getYearTranslation = useCallback(
        (year: number) =>
            `${year} ${
                year === 1
                    ? t('HorseProfile:picker:year')
                    : t('HorseProfile:picker:years')
            }`,
        [t],
    );

    const years = useMemo(
        () =>
            Array(51)
                .fill(null)
                .map((_, index) => ({
                    value: index.toString(),
                    name: getYearTranslation(index),
                })),
        [getYearTranslation],
    );

    const handleSelectContacts = useCallback(
        (contacts: ContactModel[]) => {
            form.setFieldValue('contacts', contacts);
        },
        [form],
    );

    const handleRemoveContact = useCallback(
        (contact: ContactModel) => {
            const previousContacts = form.values.contacts;
            const newContacts = previousContacts?.filter(
                (prevContact) => prevContact.id !== contact.id,
            );

            form.setFieldValue('contacts', newContacts);
        },
        [form],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel: form.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    const genderList = useMemo(() => getHorseGenders(t), [t]);
    const breedList = useMemo(() => getHorseBreeds(t), [t]);
    const workTypeList = useMemo(() => getHorseWorkTypes(t), [t]);
    const weeks = useMemo(() => getHorseShoeingCycleWeeks(t), [t]);

    const getMenuItems = useCallback(
        (items: ListInterface[], itemsPrefixId: string) => [
            <MenuItem
                key="None"
                value=""
                data-test-id={`AddEditHorseForm-${itemsPrefixId}None`}
            >
                {t('Actions:none')}
            </MenuItem>,
            ...items.map(({ value, name }) => (
                <MenuItem
                    key={value}
                    value={value}
                    data-test-id={`AddEditHorseForm-${itemsPrefixId}${name}`}
                >
                    {name}
                </MenuItem>
            )),
        ],
        [t],
    );

    const handleRemoveHorse = useCallback(async () => {
        try {
            const horseService = new Horse({
                database: database.getDatabase(),
                imageService: ImagesService,
                logDBAction: () => null,
            });

            const userId = userProfileData?.id || '';

            if (!horse?.id || !userId) {
                throw new Error();
            }

            await horseService.deleteByID(horse?.id, userId);

            HorsesAnalytics.logUserDeletedHorse(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:horse'),
                }),
            });

            close();

            if (onRemove) {
                onRemove();
            }
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [
        close,
        database,
        horse?.id,
        t,
        onRemove,
        userProfileData?.id,
        ImagesService,
    ]);

    const showRemoveHorseAlert = useCallback(() => {
        setIsRemoveHorseAlertVisible(true);
    }, []);

    const closeRemoveHorseAlert = useCallback(() => {
        setIsRemoveHorseAlertVisible(false);
    }, []);

    const handlePlaceChange = useCallback(
        (location: Location) => {
            form.setFieldValue('location', location);
            form.setFieldValue('stableAddress', location?.name || '');
        },
        [form],
    );

    const handlePhotoChange = useCallback(
        (url: string) => setCurrentAvatarUrl(url),
        [],
    );

    const renderDateTimePickerInput = useCallback(
        (params, field: string, testID: string) => (
            <TextField
                {...params}
                fullWidth
                error={!!form.errors[field] && !!form.touched[field]}
                helperText={form.touched[field] ? form.errors[field] : ''}
                onBlur={form.handleBlur}
                name={field}
                id={field}
                data-test-id={testID}
                aria-readonly={false}
            />
        ),
        [form.errors, form.handleBlur, form.touched],
    );

    const renderDateInput = useCallback(
        (params) =>
            renderDateTimePickerInput(
                params,
                'foalDate',
                'AddEditHorseForm-BirthDate',
            ),
        [renderDateTimePickerInput],
    );

    const defaultDate = useMemo(() => {
        const date = getItemFromLocalStorage<string>(
            WEB_STORAGE_KEYS.lastBirthDate,
        );
        if (date && date !== 'Invalid date') {
            return moment(date);
        } else {
            return moment();
        }
    }, []);

    const handleAgeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const age = event.target.value;
            form.setFieldValue('age', age);

            const date = moment()
                .month(defaultDate.month())
                .date(defaultDate.date())
                .subtract(age, 'years')
                .format(foalDateFormat);

            form.setFieldValue('foalDate', date);
        },
        [defaultDate, form],
    );

    return (
        <Modal
            center
            isOpen
            disableRestoreFocus
            testID="AddEditHorseModal"
            styles="relative py-0 w-[768px] h-[85%]"
        >
            <BackdropLoader
                isLoading={
                    (isImagesSyncInProgress || isSyncInProgress) && isCreating
                }
                sx={{
                    position: 'absolute',
                    borderRadius: '1rem',
                    backgroundColor: COLOR.white60,
                    color: COLOR.ebonyClay,
                }}
                loadingText={t(
                    isEditMode
                        ? 'AddHorseForm:edit_loading_text'
                        : 'AddHorseForm:add_loading_text',
                )}
            />
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <AddEditModalHeader
                    backgroundColor={ENTITY_COLOR.horses}
                    cancel={handleFormCancelButtonClick}
                    save={handleSaveButtonClick}
                    testIdPrefix="AddEditHorseForm"
                    title={t(
                        isEditMode
                            ? 'EditHorse:view_title'
                            : 'NewHorse:view_title',
                    )}
                    teamName={
                        userOrganisations && userOrganisations?.length > 1
                            ? userOrganisation?.name || ''
                            : undefined
                    }
                    avatarUrl={
                        userOrganisations && userOrganisations?.length > 1
                            ? teamAvatar?.imageURL || ''
                            : undefined
                    }
                    icon={<HorseIcon color={COLOR.white} />}
                />
                <Box
                    className="px-12 pb-7"
                    sx={{
                        overflow: 'auto',
                        pt: 3,
                    }}
                >
                    <PhotoSelector
                        onPhotoChanged={handlePhotoChange}
                        currentPhotoUrl={currentAvatarUrl}
                        sx={{ mb: 3.5 }}
                        placeholder={
                            <HorseAvatar
                                color={COLOR.mischka}
                                width={60}
                                height={70}
                            />
                        }
                    />
                    <Box className="flex flex-col gap-10">
                        <TextField
                            fullWidth
                            data-test-id="AddEditHorseForm-Name"
                            id="name"
                            label={t('AddHorseForm:labels:name')}
                            error={!!form.errors.name && !!form.touched.name}
                            helperText={
                                form.touched.name ? form.errors.name : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.name}
                            name="name"
                        />
                        {CreateContactModal && allowContactsSelection ? (
                            <SelectContactsSection
                                onSelectContacts={handleSelectContacts}
                                onRemoveContact={handleRemoveContact}
                                selectedContacts={form.values.contacts}
                                CreateContactModal={CreateContactModal}
                                testIdPrefix="AddEditHorseForm"
                            />
                        ) : null}
                        <Box className="flex gap-3 items-center">
                            <TextField
                                className="flex-1"
                                fullWidth
                                select
                                data-test-id="AddEditHorseForm-ShoeCycle"
                                id="shoeingCycle"
                                label={t('AddHorseForm:labels:shoeingCycle')}
                                error={
                                    !!form.errors.shoeingCycle &&
                                    !!form.touched.shoeingCycle
                                }
                                helperText={
                                    form.touched.shoeingCycle
                                        ? form.errors.shoeingCycle
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="shoeingCycle"
                                value={form.values.shoeingCycle}
                            >
                                {getMenuItems(weeks, 'ShoeCycleItem')}
                            </TextField>
                            <Box className="flex-1 gap-3">
                                <FormControlLabel
                                    checked={form.values.shoeingCycleNotify}
                                    control={
                                        <Switch
                                            id="shoeingCycleNotify"
                                            name="shoeingCycleNotify"
                                            data-test-id="AddEditHorseForm-Notify"
                                            onBlur={form.handleBlur}
                                            onChange={form.handleChange}
                                            value={
                                                form.values.shoeingCycleNotify
                                            }
                                        />
                                    }
                                    label={t('AddHorseForm:labels:notify')}
                                />
                            </Box>
                        </Box>
                        <Box className="flex gap-3">
                            <DesktopDatePicker
                                label={t('AddHorseForm:labels:birthdate')}
                                value={form.values.foalDate}
                                onChange={onDateChanged}
                                renderInput={renderDateInput}
                                defaultCalendarMonth={defaultDate}
                                onClose={() => {
                                    form.handleChange('foalDate');
                                    form.handleBlur('foalDate');
                                }}
                                disableMaskedInput
                            />
                            <TextField
                                fullWidth
                                data-test-id="AddEditHorseForm-Age"
                                select
                                id="age"
                                label={t('AddHorseForm:labels:age')}
                                error={!!form.errors.age && !!form.touched.age}
                                helperText={
                                    form.touched.age ? form.errors.age : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={handleAgeChange}
                                name="age"
                                value={form.values.age}
                            >
                                {getMenuItems(years, 'AgeItem')}
                            </TextField>
                        </Box>
                        <Box className="flex">
                            <TextField
                                fullWidth
                                select
                                data-test-id="AddEditHorseForm-Gender"
                                id="gender"
                                label={t('AddHorseForm:labels:gender')}
                                error={
                                    !!form.errors.gender &&
                                    !!form.touched.gender
                                }
                                helperText={
                                    form.touched.gender
                                        ? form.errors.gender
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="gender"
                                value={form.values.gender}
                            >
                                {getMenuItems(genderList, 'GenderItem')}
                            </TextField>
                        </Box>
                        <Box className="flex gap-3">
                            <TextField
                                fullWidth
                                select
                                data-test-id="AddEditHorseForm-Breed"
                                id="breed"
                                label={t('AddHorseForm:labels:breed')}
                                error={
                                    !!form.errors.breed && !!form.touched.breed
                                }
                                helperText={
                                    form.touched.breed ? form.errors.breed : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="breed"
                                value={form.values.breed}
                            >
                                {getMenuItems(breedList, 'BreedItem')}
                            </TextField>
                            <TextField
                                fullWidth
                                select
                                data-test-id="AddEditHorseForm-WorkType"
                                id="workType"
                                label={t('AddHorseForm:labels:workType')}
                                error={
                                    !!form.errors.workType &&
                                    !!form.touched.workType
                                }
                                helperText={
                                    form.touched.workType
                                        ? form.errors.workType
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="workType"
                                value={form.values.workType}
                            >
                                {getMenuItems(workTypeList, 'WorkTypeItem')}
                            </TextField>
                        </Box>
                        <TextField
                            fullWidth
                            data-test-id="AddEditHorseForm-microshipNo"
                            id="microshipNo"
                            label={t('AddHorseForm:labels:microchipNo')}
                            error={
                                !!form.errors.microchipNo &&
                                !!form.touched.microchipNo
                            }
                            helperText={
                                form.touched.microchipNo
                                    ? form.errors.microchipNo
                                    : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.microchipNo}
                            name="microchipNo"
                        />
                        <Box className="flex gap-3">
                            <TextField
                                fullWidth
                                data-test-id="AddEditHorseForm-SireName"
                                id="sireName"
                                label={t('AddHorseForm:labels:sireName')}
                                error={
                                    !!form.errors.sireName &&
                                    !!form.touched.sireName
                                }
                                helperText={
                                    form.touched.sireName
                                        ? form.errors.sireName
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="sireName"
                                value={form.values.sireName}
                            />
                            <TextField
                                fullWidth
                                data-test-id="AddEditHorseForm-DamName"
                                id="damName"
                                label={t('AddHorseForm:labels:damName')}
                                error={
                                    !!form.errors.damName &&
                                    !!form.touched.damName
                                }
                                helperText={
                                    form.touched.damName
                                        ? form.errors.damName
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="damName"
                                value={form.values.damName}
                            />
                        </Box>
                        <Box className="flex flex-col gap-5">
                            <GooglePlacesAutocomplete
                                label={t(
                                    'AddHorseForm:labels:location_address',
                                )}
                                onBlur={form.handleBlur}
                                onPlaceChange={handlePlaceChange}
                                testId="AddEditHorseForm-LocationAddress"
                                value={
                                    form.values.location
                                        ? {
                                              description:
                                                  form.values.location.name,
                                              place_id:
                                                  form.values.location.placeID,
                                          }
                                        : form.values.stableAddress
                                        ? {
                                              description:
                                                  form.values.stableAddress,
                                              place_id: '',
                                          }
                                        : null
                                }
                            />
                            <Box className="flex gap-3">
                                <TextField
                                    fullWidth
                                    data-test-id="AddEditHorseForm-LocationName"
                                    id="stableName"
                                    label={t(
                                        'AddHorseForm:labels:location_name',
                                    )}
                                    error={
                                        !!form.errors.stableName &&
                                        !!form.touched.stableName
                                    }
                                    helperText={
                                        form.touched.stableName
                                            ? form.errors.stableName
                                            : ''
                                    }
                                    onBlur={form.handleBlur}
                                    onChange={form.handleChange}
                                    name="stableName"
                                    value={form.values.stableName}
                                />
                                <PhoneNumber
                                    error={
                                        !!form.errors.stablePhone?.number &&
                                        !!form.touched.stablePhone?.number
                                    }
                                    fullWidth
                                    helperText={
                                        form.touched.stablePhone?.number
                                            ? form.errors.stablePhone?.number
                                            : ''
                                    }
                                    id="stablePhone"
                                    data-test-id="AddEditHorseForm-LocationPhone"
                                    label={t(
                                        'AddHorseForm:labels:location_phone',
                                    )}
                                    name="stablePhone.number"
                                    onBlur={form.handleBlur}
                                    onChange={onPhoneNumberChange}
                                    defaultCountry={
                                        isEditMode
                                            ? undefined
                                            : userProfileData?.region?.toLowerCase()
                                    }
                                    value={form.values.stablePhone.full}
                                />
                            </Box>
                        </Box>
                        <TextField
                            multiline
                            rows={3}
                            fullWidth
                            id="comments"
                            data-test-id="AddEditHorseForm-Notes"
                            label={t('AddHorseForm:labels:notes')}
                            error={
                                !!form.errors.comments &&
                                !!form.touched.comments
                            }
                            helperText={
                                form.touched.comments
                                    ? form.errors.comments
                                    : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            name="comments"
                            value={form.values.comments}
                        />
                        <Box>
                            <FormControlLabel
                                checked={form.values.visible}
                                control={
                                    <Switch
                                        id="visible"
                                        name="visible"
                                        data-test-id="AddEditHorseForm-Visible"
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        value={form.values.visible}
                                    />
                                }
                                label={t('AddHorseForm:labels:visible')}
                            />
                        </Box>
                    </Box>
                </Box>
                {isEditMode ? (
                    <>
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            showTooltip={isSyncInProgress}
                            onClick={showRemoveHorseAlert}
                            disabled={isSyncInProgress}
                            testID="AddEditHorseForm-RemoveButton"
                            sx={{ mt: 3, alignSelf: 'center' }}
                            stylesAt="container"
                            color="error"
                            startIcon={<DeleteOutlineRoundedIcon />}
                        >
                            {t('HorseProfile:button:remove')}
                        </TooltipSyncButton>
                        <RemoveEntityAlert
                            dialogText={t(
                                'HorseProfile:alert:remove_subtitle',
                                { name: horse?.name || '' },
                            )}
                            isOpen={isRemoveHorseAlertVisible}
                            onClose={closeRemoveHorseAlert}
                            onRemove={handleRemoveHorse}
                            testID="RemoveHorseAlert"
                        />
                    </>
                ) : null}
            </Box>
            {isNoContactsAlertOpen ? (
                <AlertDialog
                    close={closeNoContactsAlert}
                    testID="AddEditHorseForm-NoContactsAlert"
                    title={t('Contact:alert:no_contact_title')}
                    message={t('Contact:alert:no_contact_message')}
                    onConfirmButtonClick={closeNoContactsAlert}
                    showCancelButton={false}
                />
            ) : null}
            {showCancelAlert ? renderCancelAlert() : null}
        </Modal>
    );
}

export default AddEditHorseModal;
