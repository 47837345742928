import apiClient from '@/services/networking/apiClient';
import { ExportInvoicesParams } from '@/types/invoicing';

async function exportInvoices(params: ExportInvoicesParams): Promise<void> {
    await apiClient.post('/accounting/export_invoices', {
        export_format: params.format,
        invoice_ids: params.invoicesIds,
    });
}

export const InvoicingAPI = {
    exportInvoices,
};
