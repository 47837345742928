import React from 'react';

import { COLOR } from '@/theme/colors';
import { SVGIconProps } from '@/types/svg';

function ReadTickIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.063 8.901"
            width={width}
            height={height}
            fill={color}
        >
            <g
                id="Group_1802"
                data-name="Group 1802"
                transform="translate(0.001 0)"
            >
                <path
                    id="Path_958"
                    data-name="Path 958"
                    d="M14.066,0,8.159,6.658l-.633-.713-1,1.113L8.158,8.9l6.9-7.78Z"
                />
                <path
                    id="Path_959"
                    data-name="Path 959"
                    d="M9.689,0,3.781,6.658,1,3.52,0,4.641,3.78,8.9l6.905-7.78Z"
                />
            </g>
        </svg>
    );
}

export default ReadTickIcon;
