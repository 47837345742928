import { Database, Collection } from '@nozbe/watermelondb';

import { DBServiceOptions } from '../../types/dbService';
import { OrganisationAccountingsModel } from '../../types/OrganisationAccountings';

class OrganisationAccountings {
    private database: Database;
    private collection: Collection<OrganisationAccountingsModel>;
    private table = 'organisation_accountings';
    private options: DBServiceOptions;

    constructor(options: DBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    get() {
        return this.collection.query();
    }

    async updateField(
        organisationAccountingsId: string,
        fieldName: string,
        fieldValue: string | number | boolean | null,
    ) {
        const orgElement = await this.collection.find(
            organisationAccountingsId,
        );

        await this.database.write(async () => {
            await orgElement.update((org) => (org[fieldName] = fieldValue));

            this.options.logDBAction({
                message: `Update organisation accountings field ${fieldName}`,
                modelName: this.table,
                payload: orgElement,
            });
        });
    }

    async disconnectFromProvider(organisationAccountingsId: string) {
        const orgElement = await this.collection.find(
            organisationAccountingsId,
        );

        await this.database.write(async () => {
            await orgElement.update((org) => {
                org.accountingProvider = null;
                org.salesAccountCode = null;
            });

            this.options.logDBAction({
                message: `Disconnect from accounting provider`,
                modelName: this.table,
                payload: orgElement,
            });
        });
    }
}
export default OrganisationAccountings;
