import { Box, Collapse, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';

import { RotativeExpandMoreIcon } from '@/assets/svg';
import { Badge } from '@/components';

import { Props } from './types';

export default function CollapsibleContainer({
    badgeCount,
    title,
    children,
    testID,
    auxiliaryElement,
    collapse = false,
}: Props) {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapse);

    useEffect(() => {
        setIsCollapsed(collapse);
    }, [collapse]);

    const p: SxProps<Theme> = { py: 1.5, px: 2 };

    const toggleCollapsible = useCallback(
        () => setIsCollapsed((collapsed) => !collapsed),
        [],
    );

    return (
        <div data-test-id={testID}>
            <Box
                sx={{
                    display: 'flex',
                    boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24);',
                    ...p,
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                onClick={toggleCollapsible}
            >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
                    {badgeCount && badgeCount > 0 ? (
                        <Badge count={badgeCount} />
                    ) : null}
                </Box>
                <Box sx={{ display: 'flex', gap: 3.5 }}>
                    {auxiliaryElement}
                    <RotativeExpandMoreIcon isExpanded={!isCollapsed} />
                </Box>
            </Box>
            <Collapse in={!isCollapsed}>
                <Box sx={p}>{children}</Box>
            </Collapse>
        </div>
    );
}
