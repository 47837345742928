import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useState,
} from 'react';

import { Context } from '@/types/context';

const PageScrollableElementRefContext =
    createContext<Context.PageScrollableElementRef.Value>({
        scrollableElementRef: null,
        setScrollableElementRef: () => null,
        clearContextState: () => null,
    });

const pageScrollableElementRefContextRef: React.MutableRefObject<Context.PageScrollableElementRef.Value | null> =
    createRef();

const PageScrollableElementRefProvider = ({
    children,
}: Context.PageScrollableElementRef.Props) => {
    const [scrollableElementRef, setScrollableElementRef] =
        useState<HTMLElement | null>(null);

    const resetScrollableElementRef = useCallback(
        () => setScrollableElementRef(null),
        [],
    );

    const clearContextState = useCallback(
        () => resetScrollableElementRef(),
        [resetScrollableElementRef],
    );

    const contextValue: Context.PageScrollableElementRef.Value = {
        scrollableElementRef,
        setScrollableElementRef,
        clearContextState,
    };

    pageScrollableElementRefContextRef.current = contextValue;

    return (
        <PageScrollableElementRefContext.Provider value={contextValue}>
            {children}
        </PageScrollableElementRefContext.Provider>
    );
};

function usePageScrollableElementRef() {
    return useContext(PageScrollableElementRefContext);
}

function clearPageScrollableElementRef() {
    pageScrollableElementRefContextRef.current?.clearContextState();
}

export {
    PageScrollableElementRefContext,
    PageScrollableElementRefProvider,
    usePageScrollableElementRef,
    clearPageScrollableElementRef,
};
