import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RSVPBackgroundImage1 from '@/assets/images/rsvp-intro-a.png';
import RSVPBackgroundImage2 from '@/assets/images/rsvp-intro-b.png';
import { Modal } from '@/components';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { OverlayDots } from './components';

type Props = {
    afterShow: VoidFunction;
};

type InvitationOverlay = {
    title: string;
    disclaimer1: string;
    disclaimer2?: string;
    image: string;
    height: string;
    transform: string;
};

const overlays: InvitationOverlay[] = [
    {
        title: 'Event:rsvp:overlay:invitation:first:title',
        disclaimer1: 'Event:rsvp:overlay:invitation:first:disclaimer',
        image: RSVPBackgroundImage1,
        height: '450px',
        transform: '-100%',
    },
    {
        title: 'Event:rsvp:overlay:invitation:second:title',
        disclaimer1: 'Event:rsvp:overlay:invitation:second:disclaimer1',
        disclaimer2: 'Event:rsvp:overlay:invitation:second:disclaimer2',
        image: RSVPBackgroundImage2,
        height: '500px',
        transform: '-100%',
    },
];

function RSVPInvitationOverlay({ afterShow }: Props) {
    const { t } = useTranslation();
    const { dismissOverlay } = useRSVPOverlayContext();
    const [currentPosition, setCurrentPosition] = useState(0);

    const currentOverlay = overlays[currentPosition];

    const isFirstContent = currentPosition === 0;

    const forwardAnimation = () => {
        if (isFirstContent) {
            setCurrentPosition(1);
        } else {
            dismissOverlay('invitation');
        }
    };

    const backwardAnimation = () => {
        setCurrentPosition(0);
    };

    useEffect(() => {
        setTimeout(afterShow, 300);
    }, [afterShow]);

    return (
        <Modal isOpen center styles="bg-transparent shadow-none items-center">
            <Box
                sx={{
                    px: 5.375,
                    pt: 5.375,
                    pb: 3.375,
                    mt: 8,
                    background: 'linear-gradient(#E9ECF0, #FFFFFF)',
                    borderRadius: '1rem',
                    boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    maxWidth: '500px',
                    transition: 'height 0.4s ease-in-out',
                    height: currentOverlay.height,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        transition:
                            'opacity 0.4s ease-in-out, height 0.4s ease-in-out',
                        opacity: isFirstContent ? 1 : 0,
                        height: isFirstContent ? 28 : 0,
                    }}
                >
                    <Box
                        sx={{
                            width: 2,
                            height: 20,
                            backgroundColor: ENTITY_COLOR.schedule,
                        }}
                    />
                    <Box
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: ENTITY_COLOR.schedule,
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontWeight: '700',
                        color: ENTITY_COLOR.schedule,
                        lineHeight: '20px',
                    }}
                >
                    {t(currentOverlay.title)}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#121212',
                        lineHeight: '20px',
                        marginTop: 1,
                    }}
                >
                    {t(currentOverlay.disclaimer1)}
                </Typography>
                {currentOverlay.disclaimer2 ? (
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#121212',
                            lineHeight: '20px',
                            marginTop: 2,
                            textAlign: 'center',
                        }}
                    >
                        {t(currentOverlay.disclaimer2)}
                    </Typography>
                ) : null}
                <Box
                    sx={{
                        display: 'flex',
                        overflow: 'hidden',
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        style={{
                            width: '60%',
                            margin: '0 auto',
                            position: 'absolute',
                            left: '-50%',
                            marginTop: 32,
                            backgroundSize: 'contain',
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'bottom',
                            transition: 'transform 0.4s ease-in-out',
                            transform: `translateX(${
                                overlays[0] === currentOverlay
                                    ? '115%'
                                    : '-115%'
                            })`,
                        }}
                        src={overlays[0].image}
                    />
                    <img
                        style={{
                            width: '60%',
                            margin: '0 auto',
                            position: 'absolute',
                            right: '-50%',
                            marginTop: 32,
                            backgroundSize: 'contain',
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'bottom',
                            transition: 'transform 0.4s ease-in-out',
                            transform: `translateX(${
                                overlays[1] === currentOverlay
                                    ? '-115%'
                                    : '115%'
                            })`,
                        }}
                        src={overlays[1].image}
                    />
                </Box>
                <OverlayDots
                    numberOfDots={overlays.length}
                    currentPosition={currentPosition}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        margin: '27px',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        background: '#3E3E3E',
                        transition: 'opacity 0.4s ease-in-out',
                        opacity: isFirstContent ? 0 : 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: COLOR.white,
                        '&:hover': {
                            bgcolor: '#3E3E3E',
                            filter: 'brightness(1.1)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={backwardAnimation}
                >
                    <ArrowBackIcon />
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        margin: '27px',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        background: isFirstContent
                            ? '#3E3E3E'
                            : ENTITY_COLOR.schedule,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: COLOR.white,
                        '&:hover': {
                            bgcolor: isFirstContent
                                ? '#3E3E3E'
                                : ENTITY_COLOR.schedule,
                            filter: 'brightness(1.1)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={forwardAnimation}
                >
                    {isFirstContent ? <ArrowForwardIcon /> : <CheckIcon />}
                </Box>
            </Box>
        </Modal>
    );
}

export default memo(RSVPInvitationOverlay);
