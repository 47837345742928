import { Box, Chip } from '@mui/material';
import React, { memo } from 'react';

import { Props } from './types';

function SelectedContactsList({
    onRemoveContactSelection,
    selectedContacts,
}: Props) {
    return (
        <Box className="flex flex-row flex-wrap">
            {selectedContacts.map((contact) => (
                <Chip
                    key={contact.id}
                    label={contact.name}
                    sx={{ height: 30, mr: 1, mb: 1 }}
                    onDelete={onRemoveContactSelection(contact)}
                />
            ))}
        </Box>
    );
}

export default memo(SelectedContactsList);
