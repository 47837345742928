import {
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    Typography,
} from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { of } from 'rxjs';
import { ENTRIES_FILTER } from 'shared/constants/entries/filters';
import Database from 'shared/db/services/Database.web';
import { EntriesFiltersContactOption } from 'shared/types/entriesFilters';

import { withSyncContext } from '@/hoc';

import { SelectedContactsList } from './components';
import { Props } from './types';

function ContactsFilterSection({
    contacts,
    filtersState,
    onFiltersChange,
}: Props) {
    const { t } = useTranslation();

    const handleRemoveContactSelection = useCallback(
        (contactToRemove: EntriesFiltersContactOption) => () => {
            const prevFiltersState = [...filtersState[ENTRIES_FILTER.contacts]];

            const setContactsFilterValue = onFiltersChange(
                ENTRIES_FILTER.contacts,
            );

            const newContacts = prevFiltersState.filter(
                (contact) => contact.id !== contactToRemove.id,
            );

            setContactsFilterValue(newContacts);
        },
        [filtersState, onFiltersChange],
    );

    const contactsOptions = useMemo(() => {
        if (!contacts?.length) {
            return [];
        }

        return contacts.map((contact) => ({
            id: contact.id,
            name: `${contact.firstName}${
                contact.lastName ? ` ${contact.lastName}` : ''
            }`,
        }));
    }, [contacts]);

    const renderOption = useCallback(
        (
            props: React.HTMLAttributes<HTMLLIElement>,
            option: EntriesFiltersContactOption,
            { selected },
        ) => {
            return (
                <li
                    {...props}
                    data-test-id={`EntriesPage-FiltersPanel-ContactsOption-${option.name}`}
                    key={option.id}
                >
                    <Checkbox
                        checked={selected}
                        size="small"
                        sx={{ padding: 0, py: 0.5, ml: -0.5, mr: 1 }}
                    />
                    <Typography fontSize={14}>{option.name}</Typography>
                </li>
            );
        },
        [],
    );

    const getOptionLabel = useCallback(
        (option: EntriesFiltersContactOption) => {
            return option.name;
        },
        [],
    );

    const renderTags = useCallback(() => {
        return null;
    }, []);

    const handleContactsSelectChange = useCallback(
        (_event, optionsValue: EntriesFiltersContactOption[]) => {
            const setContactsFilterValue = onFiltersChange(
                ENTRIES_FILTER.contacts,
            );

            setContactsFilterValue(optionsValue);
        },
        [onFiltersChange],
    );

    return (
        <Box className="mb-4 mr-6">
            <Box className="mb-4">
                <Autocomplete
                    clearOnBlur={false}
                    disableClearable
                    fullWidth
                    multiple
                    onChange={handleContactsSelectChange}
                    options={contactsOptions}
                    disableCloseOnSelect
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    style={{ flexWrap: 'nowrap', width: 200 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            data-test-id="EntriesPage-FiltersPanel-ContactsSelect"
                            label={t('EntriesList:filters:contacts:label')}
                            size="small"
                        />
                    )}
                    renderTags={renderTags}
                    value={filtersState.contacts}
                />
            </Box>
            {filtersState.contacts.length ? (
                <SelectedContactsList
                    onRemoveContactSelection={handleRemoveContactSelection}
                    selectedContacts={filtersState.contacts}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        return {
            contacts: isInitialSyncInProgress
                ? of([])
                : database.collections
                      .get('contacts')
                      .query(
                          Q.sortBy('first_name', 'asc'),
                          Q.sortBy('last_name', 'asc'),
                          Q.where('hidden', false),
                      ),
        };
    },
);

export default withSyncContext<Props>(enhance(memo(ContactsFilterSection)));
