const toString = (array: any[] = [], { separator = ' ' }): string | null => {
    try {
        return array.join(separator);
    } catch (error) {
        return null;
    }
};

/**
 * Example:
 * const array1 = [{ name: "John" }, { name: "Jane" }, { name: "Susan" }]
 * const array2 = [{ name: "John" }, { name: "Jane" }]
 *
 * getUniqueElementsFromTwoArrays(array1, array2, "name")
 * => [{ name: "Susan "}]
 */
const getUniqueElementsFromTwoArrays = <T, E>(
    array1: ReadonlyArray<T>,
    array2: ReadonlyArray<E>,
    key1: keyof T,
    key2: string,
): T[] => {
    return array1.filter((item) => {
        return array2.findIndex((item2) => item2[key2] === item[key1]) === -1;
    });
};

/**
 * Example:
 * const array1 = [{ name: "John" }, { name: "Jane" }, { name: "Susan" }]
 * const array2 = [{ name: "John" }, { name: "Jane" }]
 *
 * getMatchingElementsFromTwoArrays(array1, array2, "name")
 * => [{ name: "John" }, { name: "Jane" }]
 */
const getMatchingElementsFromTwoArrays = <T, E>(
    array1: ReadonlyArray<T>,
    array2: ReadonlyArray<E>,
    key1: keyof T,
    key2: string,
): T[] => {
    return array1.filter((item1) =>
        array2.find((item2) => item1[key1] === item2[key2]),
    );
};

/**
 * Example:
 * const array = [{ name: "John" }, { name: "Susan" }, { name: "John" }];
 *
 * getDuplicatesFreeArrayByKey(array, "name")
 * => [{ name: "John" }, { name: "Susan" }]
 */
function getDuplicatesFreeArrayByKey<T, K extends keyof T>(
    array: T[],
    key: K,
): T[] {
    const uniqueItemsSet = new Set();

    return array.filter((item) => {
        const value = item[key];

        return uniqueItemsSet.has(value) ? false : uniqueItemsSet.add(value);
    });
}

export {
    toString,
    getUniqueElementsFromTwoArrays,
    getMatchingElementsFromTwoArrays,
    getDuplicatesFreeArrayByKey,
};
