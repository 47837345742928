import { Box, Button, Fade, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function AppointmentInvitationInPastModal({ onClose }: Props) {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            closeAfterTransition
            center
            styles="py-5 px-5 rounded-lg shadow-md max-w-xl"
        >
            <Fade
                in
                timeout={{
                    enter: 500,
                    exit: 300,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: COLOR.black,
                        }}
                    >
                        {t('Event:invite_contact:in_past_modal:title')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: COLOR.black,
                        }}
                    >
                        {t('Event:invite_contact:in_past_modal:description')}
                    </Typography>
                    <Box sx={{ alignSelf: 'flex-end', mt: 2 }}>
                        <Button
                            onClick={onClose}
                            className="px-5 py-2.5"
                            variant="contained"
                            sx={{
                                backgroundColor: ENTITY_COLOR.schedule,
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.schedule,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: COLOR.white,
                                }}
                            >
                                {t(
                                    'Event:invite_contact:in_past_modal:understood',
                                )}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AppointmentInvitationInPastModal;
