import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EntryModel } from 'shared/types/Entries';

import { TruncatedListSection } from '@/components';
import EntriesList from '@/components/EntriesList';
import { ROUTE } from '@/router/routes';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function EntriesSection({
    buttonText,
    emptyMessage,
    entries,
    financialDetails,
    isLoading,
    onAddButtonClick,
    onButtonClick,
    showAddButton,
    showButtonAlways,
    title,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToEntry = useCallback(
        (entry: EntryModel) => {
            const route = getRoutePath(ROUTE.entry, { id: entry.id });

            navigate(route);
        },
        [navigate],
    );

    return (
        <TruncatedListSection
            ListComponent={EntriesList}
            listComponentProps={{
                clickable: true,
                financialDetails,
                items: entries,
                itemWrapperStyle: 'pb-0.5',
                onItemClick: navigateToEntry,
                size: 'large',
                testIdPrefix: 'HorseEntriesSection',
            }}
            title={
                title ||
                t('ShowHorse:section:Entries_with_length', {
                    length: entries.length || 0,
                })
            }
            emptyMessage={emptyMessage || t('ShowHorse:empty:entries')}
            entityName={t('Entities:entry').toLowerCase()}
            buttonText={buttonText || t('Invoicing:section:more_entries')}
            isLoading={isLoading}
            onAddButtonClick={onAddButtonClick}
            onButtonClick={onButtonClick}
            showAddButton={showAddButton}
            showButtonAlways={showButtonAlways}
        />
    );
}

export default EntriesSection;
