import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import {
    APPLE_APP_STORE_APP_URL,
    GOOGLE_PLAY_STORE_APP_URL,
} from 'shared/constants/externalUrls';
import { getAppStoreAppUrl } from 'shared/utils/externalUrls';

import { InvoiceIcon } from '@/assets/svg';
import {
    AppStoreBadgeLink,
    ExternalLink,
    PlayStoreBadgeLink,
} from '@/components';
import { APP_CONFIG } from '@/constants/appConfig';
import { useRegion } from '@/hooks/useRegion';
import { COLOR, ENTITY_COLOR_TRANSPARENT_40 } from '@/theme/colors';

const STORES_SECTION_WIDTH = 135;
const QR_CODE_SIZE = 113;

export function InvoicingNotAvailableSection() {
    const { t } = useTranslation();

    const userRegion = useRegion();

    const regionParams = userRegion?.code
        ? `?region=${userRegion.code.toLocaleLowerCase()}`
        : '';
    const equinetAppLink = `${APP_CONFIG.firebaseHostDomain}/get-mobile-app${regionParams}`;
    const appStoreUrl = userRegion?.code
        ? getAppStoreAppUrl(userRegion.code)
        : APPLE_APP_STORE_APP_URL;

    return (
        <Box className="flex items-center justify-center gap-16 h-full min-w-[360px]">
            <Box className="flex flex-col items-center w-[312px] pb-[20px]">
                <InvoiceIcon
                    color={ENTITY_COLOR_TRANSPARENT_40.invoices}
                    height={200}
                    width={150}
                />
                <Box className="mb-[12px] mt-[-20px] flex justify-center">
                    <Typography
                        sx={{
                            fontSize: 22,
                            fontWeight: 700,
                            textAlign: 'center',
                            width: 240,
                        }}
                    >
                        {t('InvoiceSystem:web_coming_soon:title')}
                    </Typography>
                </Box>
                <Box className="p-[14px] bg-alabaster rounded-[8px]">
                    <Typography
                        sx={{
                            fontSize: 11,
                            color: COLOR.nevada,
                        }}
                    >
                        {t('InvoiceSystem:web_coming_soon:description')}{' '}
                        <ExternalLink href={appStoreUrl}>iOS</ExternalLink>{' '}
                        {t('Generic:or')}{' '}
                        <ExternalLink href={GOOGLE_PLAY_STORE_APP_URL}>
                            Android
                        </ExternalLink>
                        .
                    </Typography>
                </Box>
            </Box>
            <Box className={`flex flex-col w-[${STORES_SECTION_WIDTH}px]`}>
                <QRCode
                    size={QR_CODE_SIZE}
                    style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                        minWidth: 120,
                        padding: 10,
                        border: `1px ${COLOR.alto} solid`,
                        borderRadius: 10,
                    }}
                    value={equinetAppLink}
                    viewBox={`0 0 ${STORES_SECTION_WIDTH} ${STORES_SECTION_WIDTH}`}
                />
                <Box className="border-b-[1px] border-alto my-[12px]" />
                <Box className="mb-[12px]">
                    <AppStoreBadgeLink />
                </Box>
                <PlayStoreBadgeLink />
            </Box>
        </Box>
    );
}
