import AppleIcon from '@mui/icons-material/Apple';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppAnalytics } from 'shared/analytics/app';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';
import { LOCALE } from 'shared/constants/localization/types';
import AppService from 'shared/db/services/App';

import XeroAuthButtonIcon from '@/assets/svg/XeroAuthButtonIcon';
import {
    LocaleSelector,
    SignInFlowServerErrorAlert,
    UnveilPasswordButton,
} from '@/components';
import { CUSTOM_ERROR } from '@/constants/customErrors';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { getLocaleFromLocalStorage } from '@/services/localization';
import Logger from '@/services/logger';
import { ServerAuthAPI } from '@/services/networking/auth';
import {
    getLastUserEmailFromLocalStorage,
    saveLastUserEmailInLocalStorage,
} from '@/services/webStorage/localStorage/user';
import { SignInFormValues } from '@/types/auth';
import { handleSignInFlowError } from '@/utils/auth';
import { AuthValidationSchema } from '@/validations/auth';

import { Props } from './types';

const TEXT_FIELD_HEIGHT = 48;

function SignInForm({
    providerErrorCode,
    setLanguage,
    providerErrorMessage,
    handleXeroSignIn,
    handleAppleSignIn,
}: Props) {
    const initialValues: SignInFormValues = {
        email: '',
        password: '',
        language: getLocaleFromLocalStorage() || LOCALE.en_AU,
    };

    const { t } = useTranslation();

    const { authenticateUser } = useAuthContext();
    const { setIsInitialSyncInProgress } = useDBSyncContext();
    const { xeroAuthEnabled } = useFeatureFlagsContext();
    const { getDatabase } = useDatabase();

    const [isAuthPending, setIsAuthPending] = useState(false);
    const [authErrorCode, setAuthErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(
        null,
    );
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (providerErrorCode && providerErrorMessage) {
            setAuthErrorCode(providerErrorCode);
            setAuthErrorMessage(providerErrorMessage);
        } else if (providerErrorMessage) {
            setAuthErrorMessage(providerErrorMessage);
        }
    }, [providerErrorCode, providerErrorMessage]);

    const handleOnSubmit = useCallback(
        async (values: SignInFormValues) => {
            setAuthErrorCode(null);
            setAuthErrorMessage(null);
            setIsAuthPending(true);

            try {
                const database = getDatabase();
                const appService = new AppService({
                    database,
                    logInfo: Logger.logInfo,
                });

                const { email, password, language } = values;

                await FirebaseAuthAPI.signIn({ email, password });
                await ServerAuthAPI.signInWithFirebase({
                    email,
                    language,
                });

                const firebaseToken = await FirebaseAuthAPI.getAuthToken();

                if (!firebaseToken) {
                    throw new Error(CUSTOM_ERROR.no_firebase_token_found);
                }

                const prevUserEmail = getLastUserEmailFromLocalStorage();

                if (prevUserEmail !== email) {
                    await appService.clearDatabase();
                }

                authenticateUser({
                    firebaseToken,
                    rememberUser: true,
                });

                saveLastUserEmailInLocalStorage(email);

                AppAnalytics.logUserLoggedIn(FirebaseAnalytics.logEvent);

                setIsInitialSyncInProgress(true);
            } catch (error) {
                handleSignInFlowError({
                    error,
                    setAuthErrorCode,
                    setAuthErrorMessage,
                });
            } finally {
                setIsAuthPending(false);
            }
        },
        [authenticateUser, getDatabase, setIsInitialSyncInProgress],
    );

    const form = useFormik<SignInFormValues>({
        initialValues,
        onSubmit: handleOnSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.signInForm,
    });

    const signInWithXero = useCallback(
        () => handleXeroSignIn(form.values),
        [form.values, handleXeroSignIn],
    );

    const signInWithApple = useCallback(
        () => handleAppleSignIn(form.values),
        [form.values, handleAppleSignIn],
    );

    const handleSignInButtonClick = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    const handleShowPasswordIconClick = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    const onLanguageChange = useCallback(
        (language: LOCALE) => {
            form.setFieldValue('language', language);
            setLanguage(language);
        },
        [form, setLanguage],
    );

    return (
        <>
            <Box
                component="form"
                onSubmit={form.handleSubmit}
                sx={{
                    mt: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        mb: 10,
                        maxWidth: 520,
                        ml: { xs: 4, md: 10 },
                        mr: { xs: 4, md: 0 },
                    }}
                >
                    {authErrorCode || authErrorMessage ? (
                        <SignInFlowServerErrorAlert
                            error={{
                                errorCode: authErrorCode,
                                message: authErrorMessage
                                    ? authErrorMessage
                                    : undefined,
                            }}
                            testId="SignInPage-ErrorAlert"
                        />
                    ) : null}
                    <TextField
                        data-test-id="SignInPage-EmailInput"
                        error={!!form.errors.email && !!form.touched.email}
                        fullWidth
                        helperText={form.touched.email ? form.errors.email : ''}
                        id="email"
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={t('SignIn:label:email_address')}
                        name="email"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ mb: 3.5 }}
                        value={form.values.email}
                    />
                    <TextField
                        data-test-id="SignInPage-PasswordInput"
                        error={
                            !!form.errors.password && !!form.touched.password
                        }
                        fullWidth
                        helperText={
                            form.touched.password ? form.errors.password : ''
                        }
                        id="password"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                            endAdornment: (
                                <UnveilPasswordButton
                                    isPasswordVisible={showPassword}
                                    onClick={handleShowPasswordIconClick}
                                />
                            ),
                        }}
                        label={t('SignIn:label:password')}
                        name="password"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ mb: 1.5 }}
                        type={showPassword ? 'text' : 'password'}
                        value={form.values.password}
                    />
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 1.5,
                        }}
                    >
                        <Typography fontWeight={700} variant="caption">
                            <Link
                                data-test-id="SignInPage-ForgotPasswordLink"
                                to={ROUTE.forgotPassword}
                            >
                                {t('SignIn:button:forgot_password')}
                            </Link>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            data-test-id="SignInPage-LoginButton"
                            disabled={isAuthPending}
                            fullWidth
                            onClick={handleSignInButtonClick}
                            size="large"
                            sx={{ fontWeight: 700, height: 48 }}
                            type="submit"
                            variant="contained"
                        >
                            {isAuthPending ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                t('SignIn:button:sign_in')
                            )}
                        </Button>
                        <span>{t('SignIn:info:or')}</span>
                        {xeroAuthEnabled ? (
                            <Button
                                data-test-id="SignInPage-XeroLoginButton"
                                fullWidth
                                onClick={signInWithXero}
                                size="large"
                                startIcon={<XeroAuthButtonIcon />}
                                sx={{
                                    fontWeight: 700,
                                    height: 48,
                                }}
                                variant="contained"
                            >
                                {t('SignIn:button:xero')}
                            </Button>
                        ) : (
                            xeroAuthEnabled
                        )}
                        <Button
                            data-test-id="SignInPage-LoginButton"
                            disabled={isAuthPending}
                            fullWidth
                            onClick={signInWithApple}
                            size="large"
                            sx={{
                                fontWeight: 700,
                                height: 48,
                                alignItems: 'center',
                                display: 'flex',
                            }}
                            type="button"
                            variant="contained"
                        >
                            {isAuthPending ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <div className="flex items-center">
                                    <AppleIcon
                                        sx={{ width: 35, height: 35, mr: 1 }}
                                    />
                                    {t('SignIn:button:apple')}
                                </div>
                            )}
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        ml: { xs: 2, md: 10 },
                    }}
                >
                    <LocaleSelector
                        languageOnly
                        onLanguageChange={onLanguageChange}
                        languageValue={form.values.language}
                        testIdPrefix="SignInPage"
                    />
                </Box>
            </Box>
        </>
    );
}

export default memo(SignInForm);
