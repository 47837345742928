import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { LOCALE } from 'shared/constants/localization/types';
import { Region } from 'shared/types/regions';

import { updateLocale } from '@/services/localization';
import { RegionsAPI } from '@/services/networking/regions';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { Context } from '@/types/context';

const LocalizationContext = createContext<Context.Localization.Value>({
    regions: [],
    languages: [],
    isLoading: false,
    initialRegion: {
        status: 'idle',
        region: null,
    },
});

const LocalizationContextProvider = ({
    children,
}: Context.Localization.Props) => {
    const [initialRegion, setInitialRegion] =
        useState<Context.Localization.InitialRegion>({
            status: 'idle',
            region: null,
        });
    const [regions, setRegions] = useState<Region[]>([]);
    const [languages, setLanguages] = useState<LOCALE[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const detectUserRegion = useCallback((regions: Region[]) => {
        setInitialRegion((prevState) => ({
            ...prevState,
            status: 'loading',
        }));

        const language = navigator.language;
        const [, userRegion] = language.split('-');

        const bestMatch =
            regions.find(
                (fetchedRegion) => fetchedRegion.code === userRegion,
            ) ||
            regions.find(
                (fetchedRegion) => fetchedRegion.proposed_language === language,
            );

        if (!bestMatch) {
            setInitialRegion((prevState) => ({
                ...prevState,
                status: 'initialized',
            }));

            return;
        }

        setInitialRegion({
            status: 'initialized',
            region: bestMatch,
        });
        updateLocale(bestMatch.proposed_language);
    }, []);

    const fetchRegions = useCallback(async () => {
        try {
            setIsLoading(true);
            const fetchedRegions = await RegionsAPI.getRegions();
            detectUserRegion(fetchedRegions.payload);

            setRegions(fetchedRegions.payload);

            const languages = [
                ...new Set(
                    fetchedRegions.payload.map(
                        (region) => region.proposed_language,
                    ),
                ),
            ];

            setLanguages(languages);
        } catch {
            Snackbar.showToastNotification({
                message: t('Regions:error'),
                options: { variant: 'error' },
            });
        } finally {
            setIsLoading(false);
        }
    }, [detectUserRegion]);

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    const contextValue: Context.Localization.Value = {
        regions,
        languages,
        isLoading,
        initialRegion,
    };

    return (
        <LocalizationContext.Provider value={contextValue}>
            {children}
        </LocalizationContext.Provider>
    );
};

function useLocalizationContext() {
    const context = useContext(LocalizationContext);

    return context;
}

export {
    LocalizationContext,
    LocalizationContextProvider,
    useLocalizationContext,
};
