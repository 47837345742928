import CloseIcon from '@mui/icons-material/Close';
import { Button, Box, Avatar } from '@mui/material';
import React, { useCallback } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function PhotoPlaceholder({ onRemove, photoUrl, clickable, onClick }: Props) {
    const handlePhotoClick = useCallback(
        () => onClick && onClick(photoUrl),
        [onClick, photoUrl],
    );

    const handleRemovePhotoClick = useCallback(
        () => onRemove && onRemove(photoUrl),
        [onRemove, photoUrl],
    );
    return (
        <Box
            sx={{
                dislay: 'flex',
                justifyContent: 'flex-end',
                position: 'relative',
                borderRadius: '9999px',
            }}
        >
            {onRemove ? (
                <Button
                    onClick={handleRemovePhotoClick}
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{
                        borderRadius: '9999px',
                        position: 'absolute',
                        zIndex: 9,
                        minWidth: 0,
                        right: 0,
                        bgcolor: COLOR.paleSky02,
                        ml: 1.25,
                        mb: 0.375,
                    }}
                >
                    <CloseIcon
                        htmlColor={COLOR.ebonyClay}
                        sx={{ fontSize: 16 }}
                    />
                </Button>
            ) : null}
            <Avatar
                key={photoUrl}
                sx={{
                    width: 125,
                    height: 125,
                    mt: 2,
                    cursor: clickable ? 'pointer' : 'default',
                }}
                src={photoUrl}
                variant="square"
                onClick={clickable ? handlePhotoClick : undefined}
            />
        </Box>
    );
}

export default PhotoPlaceholder;
