import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Avatar } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InvoiceStatusAvatar({ size = 72, statusColor }: Props) {
    return (
        <Avatar
            sx={{
                width: size,
                height: size,
                backgroundColor: COLOR.lightGray,
                mr: 3,
            }}
            variant="circular"
        >
            <DescriptionOutlinedIcon
                style={{ width: '35', height: '35', color: statusColor }}
            />
        </Avatar>
    );
}

export default InvoiceStatusAvatar;
