// IMPORT ALL LOCALIZATIONS SUPPORTED IN APP
import moment from 'moment';

// en-ca has the same rules as us
import 'moment/locale/en-ca';
import 'moment/locale/en-au';
import 'moment/locale/nl';
import 'moment/locale/en-nz';
import 'moment-timezone/index';

const abbrs = {
    NZST: 'New Zealand Standard Time',
    '+1245': 'Chatham Standard Time',
    AEST: 'Australian Eastern Standard Time',
    ACST: 'Australian Central Standard Time',
    '+0845': 'Australian Central Western Standard Time',
    '+1030': 'Lord Howe Standard Time',
    AWST: 'Australian Western Standard Time',
    NZDT: 'New Zealand Daylight Time',
    '+1345': 'Chatham Daylight Time',
    AEDT: 'Australian Eastern Daylight Time',
    ACDT: 'Australian Central Daylight Time',
    '+11': 'Lord Howe Daylight Time',
};

moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
};

export default moment;
