export enum HorsesEvents {
    userUpdatedHorse = 'user_updated_horse',
    userCreatedHorse = 'user_created_horse',
    userAddedContactToHorse = 'user_added_contact_to_horse',
    userDeletedHorse = 'user_deleted_horse',
    userUsedHorsesSearchBar = 'user_used_horses_search_bar',
    userAddedHorseBreed = 'user_added_horse_breed',
    userAddedHorseWorkType = 'user_added_horse_work_type',
    userAddedHorseGender = 'user_added_horse_gender',
    userAddedHorseAge = 'user_added_horse_age',
    userAddedHorseShoeCycle = 'user_added_horse_shoe_cycle',
}
