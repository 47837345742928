import React from 'react';

import { useDBSyncContext } from '@/context/DBSyncContext';

function withSyncContext<T>(Component: (props: T) => JSX.Element) {
    function ComponentWithInitialSyncContext(props: T) {
        const { isInitialSyncInProgress, isSyncInProgress } =
            useDBSyncContext();

        return (
            <Component
                isInitialSyncInProgress={isInitialSyncInProgress}
                isSyncInProgress={isSyncInProgress}
                {...props}
            />
        );
    }

    ComponentWithInitialSyncContext.displayName = Component.name;

    return ComponentWithInitialSyncContext;
}

export default withSyncContext;
