// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, readonly, lazy } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Region extends Model {
    static table = 'regions';

    static associations = {
        product_sales_unit_regions: {
            type: 'has_many',
            foreignKey: 'region_id',
        },
    };

    @lazy
    productSalesUnitRegions = this.collections
        .get('product_sales_unit_regions')
        .query(Q.where('region_id', this.id));

    @field('code') code;
    @field('name') name;
    @field('currency_name') currencyName;
    @field('currency_symbol') currencySymbol;
    @field('proposed_language') proposedLanguage;
    @field('default_timezone') defaultTimezone;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
