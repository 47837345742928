import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { capitalizeFirstLetter } from 'shared/utils/string';

import { Props } from './types';

function DataItem({ label, testID, value, underline = false }: Props) {
    const formattedLabel = useMemo(() => {
        return capitalizeFirstLetter(label);
    }, [label]);

    return (
        <Box
            className={clsx('flex flex-1 max-h-[26px]', {
                'border-b-[1px] border-mischka': underline,
            })}
        >
            {label ? (
                <Typography
                    className="mr-1"
                    fontWeight={400}
                    lineHeight={1.8}
                    variant="body2"
                >
                    {`${formattedLabel}:`}
                </Typography>
            ) : null}
            <Typography
                data-test-id={testID}
                fontWeight={300}
                lineHeight={1.8}
                variant="body2"
            >
                {value}
            </Typography>
        </Box>
    );
}

export default DataItem;
