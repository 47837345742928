import { LogEvent } from '../../types/analytics';
import { EntryModel } from '../../types/Entries';
import { EntryProcedureModel } from '../../types/EntryProcedure';
import { EntryProductModel } from '../../types/EntryProduct';
import { ProductModel, ProductType } from '../../types/Products';
import { getProductSize } from '../../utils/product';

import { OrganisationAnalytics } from '../organisation';
import { EntriesEvents } from './events';

function logUserAddedEntryProcedures(
    logEvent: LogEvent,
    params: { entryProcedures: EntryProcedureModel[] },
) {
    params.entryProcedures.forEach(async (entryProcedure) => {
        const procedure = await entryProcedure?.procedure.fetch();

        if (procedure) {
            const eventParams = {
                category: procedure.category,
                name: procedure.name,
                price: procedure.price,
                quantity: entryProcedure.quantity,
            };

            logEvent(EntriesEvents.userAddedEntryProcedure, {
                ...eventParams,
            });
        }
    });
}

function getProductParams(product: ProductModel, quantity: number) {
    return {
        brand: product.brand,
        shape: product.shape,
        type: product.productType,
        material: product.material,
        clip: product.clips,
        size: getProductSize(product),
        family: product.family,
        quantity,
    };
}

function logUserAddedEntryShoe(
    logEvent: LogEvent,
    params: { product: ProductModel; quantity: number },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(EntriesEvents.userAddedEntryShoe, {
        ...eventParams,
    });
}

function logUserAddedEntryNail(
    logEvent: LogEvent,
    params: { product: ProductModel; quantity: number },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(EntriesEvents.userAddedEntryNail, {
        ...eventParams,
    });
}

function logUserAddedEntryCustomProduct(
    logEvent: LogEvent,
    params: { product: ProductModel; quantity: number },
) {
    logEvent(EntriesEvents.userAddedEntryCustomProduct, {
        name: params.product.name,
        quantity: params.quantity,
    });
}

function logUserAddedEntryJobType(
    logEvent: LogEvent,
    params: { jobType: string },
) {
    logEvent(EntriesEvents.userAddedEntryJobType, {
        jobType: params.jobType,
    });
}

async function logUserAddedEntryProducts(
    logEvent: LogEvent,
    params: { entryProducts: EntryProductModel[] },
) {
    const products = await Promise.all(
        params.entryProducts.map((entryProduct) =>
            entryProduct.product.fetch().then(([product]) => product),
        ),
    );

    if (products.length) {
        products.forEach((product, index) => {
            const quantity = params.entryProducts[index].quantity;

            if (product && product.productType === ProductType.nails) {
                logUserAddedEntryNail(logEvent, { product, quantity });
            } else if (product && product.productType === ProductType.shoes) {
                logUserAddedEntryShoe(logEvent, { product, quantity });
            } else if (product && product.productType === ProductType.custom) {
                logUserAddedEntryCustomProduct(logEvent, { product, quantity });
            }
        });
    }
}

function logUserDeletedEntryProduct(logEvent: LogEvent) {
    logEvent(EntriesEvents.userDeletedEntryProduct);
}

function logUserAddedEntryPhotos(
    logEvent: LogEvent,
    params: { isOwner: boolean | null },
) {
    logEvent(EntriesEvents.userAddedEntryPhotos);

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberAddedPhotoToEntry(logEvent);
    }
}

function logUserUpdatedEntryPhotos(logEvent: LogEvent) {
    logEvent(EntriesEvents.userUpdatedEntryPhotos);
}

function logUserAddedEntryPrivateNotes(logEvent: LogEvent) {
    logEvent(EntriesEvents.userAddedEntryPrivateNotes);
}

async function logUserCreatedEntry(
    logEvent: LogEvent,
    params: {
        entry: EntryModel;
        logCreatedPhotosEvent: boolean;
        isOwner: boolean | null;
        membersIds: string[];
    },
) {
    logEvent(EntriesEvents.userCreatedEntry, {
        created_at: params.entry.createdAt,
        horse_id: params.entry.horseId,
        id: params.entry.id,
        logged_time: params.entry.loggedTime,
        organisation_id: params.entry.organisationId,
        title: params.entry.title,
        updated_at: params.entry.updatedAt,
        user_id: params.entry.userId,
        membersIds: params.membersIds,
    });

    if (params.entry.title) {
        logUserAddedEntryJobType(logEvent, { jobType: params.entry.title });
    }

    if (params.entry.privateNotes) {
        logUserAddedEntryPrivateNotes(logEvent);
    }

    const entryProcedures = await params.entry.entryProcedures.fetch();

    if (entryProcedures.length) {
        logUserAddedEntryProcedures(logEvent, { entryProcedures });
    }

    const entryProducts = await params.entry.entryProducts.fetch();

    if (entryProducts.length) {
        logUserAddedEntryProducts(logEvent, { entryProducts });
    }

    if (params.logCreatedPhotosEvent) {
        logUserAddedEntryPhotos(logEvent, { isOwner: params.isOwner });
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedEntry(logEvent);
    }
}

async function logUserUpdatedEntry(
    logEvent: LogEvent,
    params: {
        entry: EntryModel;
        logUpdatedPhotosEvent: boolean;
        previousEntryProducts: EntryProductModel[];
        previousEntryProcedures: EntryProcedureModel[];
        previousPrivateNotes: string;
        previousTitle: string;
        membersIds: string[];
    },
) {
    logEvent(EntriesEvents.userUpdatedEntry, {
        created_at: params.entry.createdAt,
        horse_id: params.entry.horseId,
        id: params.entry.id,
        logged_time: params.entry.loggedTime,
        organisation_id: params.entry.organisationId,
        title: params.entry.title,
        updated_at: params.entry.updatedAt,
        user_id: params.entry.userId,
        membersIds: params.membersIds,
    });

    const currentEntryProducts = await params.entry.entryProducts.fetch();
    const currentEntryProcedures = await params.entry.entryProcedures.fetch();

    if (currentEntryProducts.length) {
        const newEntryProducts = currentEntryProducts.filter(
            (currentEntryProduct) =>
                params.previousEntryProducts.every(
                    (previousEntryProduct) =>
                        previousEntryProduct.productId !==
                        currentEntryProduct.productId,
                ),
        );

        if (newEntryProducts.length) {
            logUserAddedEntryProducts(logEvent, {
                entryProducts: newEntryProducts,
            });
        }
    }

    if (params.previousEntryProducts.length) {
        const isProductDeleted = params.previousEntryProducts.some(
            (previousEntryProduct) =>
                currentEntryProducts.every(
                    (currentEntryProduct) =>
                        previousEntryProduct.productId !==
                        currentEntryProduct.productId,
                ),
        );

        if (isProductDeleted) {
            logUserDeletedEntryProduct(logEvent);
        }
    }

    if (currentEntryProcedures.length) {
        const newEntryProcedures = currentEntryProcedures.filter(
            (currentEntryProcedure) =>
                params.previousEntryProcedures.every(
                    (previousEntryProcedure) =>
                        currentEntryProcedure.procedureId !==
                        previousEntryProcedure.procedureId,
                ),
        );

        if (newEntryProcedures.length) {
            logUserAddedEntryProcedures(logEvent, {
                entryProcedures: newEntryProcedures,
            });
        }
    }

    if (params.entry.title && params.entry.title !== params.previousTitle) {
        logUserAddedEntryJobType(logEvent, { jobType: params.entry.title });
    }

    if (params.entry.privateNotes && !params.previousPrivateNotes) {
        logUserAddedEntryPrivateNotes(logEvent);
    }

    if (params.logUpdatedPhotosEvent) {
        logUserUpdatedEntryPhotos(logEvent);
    }
}

function logUserDeletedEntry(logEvent: LogEvent) {
    logEvent(EntriesEvents.userDeletedEntry);
}

function logUserExportedEntry(logEvent: LogEvent) {
    logEvent(EntriesEvents.userExportedEntry);
}

function logUserUsedEntriesSearchBar(logEvent: LogEvent) {
    logEvent(EntriesEvents.userUsedEntriesSearchBar);
}

function logUserUsedPreviousProductsCombination(logEvent: LogEvent) {
    logEvent(EntriesEvents.userUsedPreviousProductsCombination);
}

export const EntriesAnalytics = {
    logUserCreatedEntry,
    logUserDeletedEntry,
    logUserUpdatedEntry,
    logUserExportedEntry,
    logUserUsedEntriesSearchBar,
    logUserUsedPreviousProductsCombination,
};
