// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import relation from '@nozbe/watermelondb/decorators/relation';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class EventUser extends Model {
    static table = 'event_users';

    static associations = {
        event: { type: 'belongs_to', key: 'event_id' },
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
    };

    @field('event_id') eventId;
    @field('user_id') userId;
    @field('organisation_id') organisationId;

    @readonly @date('client_created_at') clientCreatedAt;
    @readonly @date('client_updated_at') clientUpdatedAt;
    @readonly @date('archived_at') archivedAt;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @relation('events', 'event_id') event;
    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
}
