import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ROUTE } from '@/router/routes';

export function useRouteMatch(paths: ROUTE[]): boolean {
    const { pathname } = useLocation();

    const isMatch = useMemo((): boolean => {
        return paths.some((path) => matchPath(path, pathname));
    }, [paths, pathname]);

    return isMatch;
}
