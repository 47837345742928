import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function EntriesIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <g id="Shapes">
                <path
                    fill={color}
                    d="M33.9 3.9h-5.6v.7c0 1.3-1 2.3-2.3 2.3h-9.5c-1.3 0-2.3-1-2.3-2.3v-.7H8.6c-.8 0-1.6.4-2.1 1-.4.4-.7 1.1-.7 1.8v30.8c0 1.5 1.3 2.8 2.8 2.8h25.3c1.5 0 2.8-1.3 2.8-2.8V6.7c0-1.6-1.2-2.8-2.8-2.8zM11.7 26.2c0 .1 0 .1-.1.2 0 .1-.1.1-.1.2s-.1.1-.1.2c-.2.2-.3.2-.4.3-.1.1-.3.1-.4.1s-.3 0-.4-.1c-.3-.1-.4-.1-.5-.3-.1-.1-.1-.1-.1-.2s-.1-.1-.1-.2 0-.1-.1-.2v-.4c0-.1 0-.1.1-.2 0-.1.1-.1.1-.2s.1-.1.1-.2c.1-.1.2-.2.4-.2.3-.1.6-.1.9 0 .1.1.3.1.4.2.1.1.1.1.1.2.1.1.1.1.1.2s.1.1.1.2v.4zm-.1-5.8c-.1.1-.2.3-.3.4-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3-.1-.1-.2-.2-.2-.4-.1-.1-.1-.3-.1-.5 0-.3.1-.6.3-.8.4-.4 1.2-.4 1.7 0 .2.2.3.5.3.8 0 .2 0 .4-.1.5zm0-5.8c-.1.1-.2.3-.3.4-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3-.1-.1-.2-.2-.3-.4-.1-.1-.1-.3-.1-.4 0-.3.1-.6.4-.8.4-.4 1.2-.4 1.7 0 .2.2.3.5.3.8 0 .1 0 .2-.1.4zm14.7 12.6H14.5c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h11.7c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.1 1.2zm5.7-6.1H14.6c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2H32c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2zm0-5.8H14.6c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2H32c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2z"
                />
                <path
                    fill={color}
                    d="M16.5 2.3H26c.5 0 .8.4.8.8v1.6c0 .5-.4.8-.8.8h-9.5c-.5 0-.8-.4-.8-.8V3.1c0-.5.3-.8.8-.8z"
                />
            </g>
        </svg>
    );
}

export default memo(EntriesIcon);
