import { Box, Button, Fade, Typography } from '@mui/material';
import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    EQUINET_PRIVACY_POLICY_PAGE,
    EQUINET_SUPPORT_PAGE,
    EQUINET_TERMS_AND_CONDITIONS_PAGE,
} from '@/constants/externalLinks';
import { COLOR } from '@/theme/colors';

import Modal from '../../../../../../components/Modal';

import { Props } from './types';

function TermsUpdatedDialog({ date, onDisagree, onConfirm, type }: Props) {
    const { t } = useTranslation();

    const formattedDate = useMemo(() => moment(date).format('LL'), [date]);

    const isAcceptType = type === 'accept';

    return (
        <Modal
            isOpen
            closeAfterTransition
            center
            styles=" p-2 pb-4 rounded-lg shadow-md"
        >
            <Fade
                in
                timeout={{
                    enter: 500,
                    exit: 300,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: COLOR.black,
                        }}
                    >
                        {t(
                            isAcceptType
                                ? 'App:Terms:Dialog:title_agree'
                                : 'App:Terms:Dialog:title_block',
                        )}
                    </Typography>
                    <Typography
                        sx={{
                            whiteSpace: 'pre-wrap',
                            fontSize: 14,
                            color: COLOR.black,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: t(
                                isAcceptType
                                    ? 'App:Terms:Dialog:message'
                                    : 'App:Terms:Dialog:block',
                                {
                                    terms: `<a style="font-size: 14px; color: black; font-weight: bold; text-decoration: underline" href="${EQUINET_TERMS_AND_CONDITIONS_PAGE}" target="_blank">${t(
                                        'SignUp:link:terms_of_service',
                                    )}</a>`,
                                    date: formattedDate,
                                },
                            ),
                        }}
                    />
                    {isAcceptType ? (
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                fontSize: 14,
                                color: COLOR.black,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: t('App:Terms:Dialog:privacy_message', {
                                    privacy: `<a style="font-size: 14px; color: black; font-weight: bold; text-decoration: underline" href="${EQUINET_PRIVACY_POLICY_PAGE}" target="_blank">${t(
                                        'App:Terms:Dialog:here',
                                    )}</a>`,
                                }),
                            }}
                        />
                    ) : (
                        <Typography
                            sx={{
                                whiteSpace: 'pre-wrap',
                                fontSize: 14,
                                color: COLOR.black,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: t('App:Terms:Dialog:support_message', {
                                    support: `<a style="font-size: 14px; color: black; font-weight: bold; text-decoration: underline" href="${EQUINET_SUPPORT_PAGE}" target="_blank">${t(
                                        'App:Terms:Dialog:support',
                                    )}</a>`,
                                }),
                            }}
                        />
                    )}
                    <Box sx={{ alignSelf: 'flex-end', mt: 2 }}>
                        {isAcceptType ? (
                            <Button
                                onClick={onDisagree}
                                className="px-8 py-2.5"
                                variant="text"
                                sx={{
                                    color: COLOR.ebonyClay,
                                    '&:hover': {
                                        backgroundColor: COLOR.white,
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: COLOR.ebonyClay,
                                    }}
                                >
                                    {t('App:Terms:Dialog:disagree')}
                                </Typography>
                            </Button>
                        ) : null}
                        <Button
                            onClick={onConfirm}
                            className="px-5 py-2.5"
                            variant="contained"
                            sx={{
                                backgroundColor: COLOR.deepCerulean,
                                '&:hover': {
                                    backgroundColor: COLOR.deepCerulean,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: COLOR.white,
                                }}
                            >
                                {isAcceptType
                                    ? t('Actions:Accept')
                                    : t('App:Terms:Dialog:understand')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default TermsUpdatedDialog;
