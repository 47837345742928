import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { routes } from '@/router/routes';

import { NonSecuredRoute, PageWrapper, SecuredRoute } from './components';

function Router() {
    return (
        <PageWrapper>
            <Routes>
                {routes.map((route) => (
                    <Route
                        element={
                            route.secure ? (
                                <SecuredRoute>{route.component}</SecuredRoute>
                            ) : (
                                <NonSecuredRoute>
                                    {route.component}
                                </NonSecuredRoute>
                            )
                        }
                        key={route.path}
                        path={route.path}
                    />
                ))}
            </Routes>
        </PageWrapper>
    );
}

export default Router;
