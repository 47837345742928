import apiClient from '@/services/networking/apiClient';
import { GetRegionsResponsePayload } from '@/types/regions';

async function getRegions(): Promise<GetRegionsResponsePayload> {
    const { data } = await apiClient.get<GetRegionsResponsePayload>('/regions');

    return data;
}

export const RegionsAPI = {
    getRegions,
};
