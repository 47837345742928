import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useState,
} from 'react';

import { OrganisationsInvitationsAPI } from '@/services/networking/invitations';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { Context } from '@/types/context';

const OrganisationIncomingInvitationContext =
    createContext<Context.OrganisationIncomingInvitation.Value>({
        clearContextState: () => null,
        clearDynamicLinkInvitationId: () => null,
        detectPendingTeamInvitation: () => null,
        invitationDetails: null,
        invitationDetailsUpdatedId: null,
        setInvitationDetailsUpdatedId: () => null,
    });

// eslint-disable-next-line
const organisationIncomingInvitationContextRef: React.MutableRefObject<Context.OrganisationIncomingInvitation.Value | null> =
    createRef();

const OrganisationIncomingInvitationContextProvider = ({
    children,
}: Context.OrganisationIncomingInvitation.Props) => {
    const [invitationDetails, setInvitationDetails] =
        useState<Context.OrganisationIncomingInvitation.InvitationDetails | null>(
            null,
        );

    // We need to store the updated_invitation_id after openInvitation request as we get a different invitation_id
    // that is needed for the next invitation status change requests (Accepting/declining) invitation
    const [invitationDetailsUpdatedId, setInvitationDetailsUpdatedId] =
        useState<string | null>(null);

    const fetchInvitationDetails = useCallback(async (invitationId: string) => {
        try {
            const result =
                await OrganisationsInvitationsAPI.getInvitationDetails({
                    invitationId,
                });

            const {
                organisation_name,
                organisation_image_url,
                organisation_owner_name,
            } = result.payload;

            setInvitationDetails({
                invitationId,
                teamName: organisation_name,
                teamOwner: organisation_owner_name,
                teamImageUrl: organisation_image_url,
            });
        } catch (err) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, []);

    const clearDynamicLinkInvitationId = useCallback(
        (params: {
            searchParams: URLSearchParams;
            setSearchParams: (searchParams: URLSearchParams) => void;
        }) => {
            setInvitationDetailsUpdatedId(null);
            setInvitationDetails(null);

            params.searchParams.delete('invitationId');
            params.setSearchParams(params.searchParams);
        },
        [setInvitationDetailsUpdatedId],
    );

    const detectPendingTeamInvitation = useCallback(
        (searchParams: URLSearchParams) => {
            const invitationId = searchParams.get('invitationId');

            if (invitationId) {
                fetchInvitationDetails(invitationId);
            }
        },
        [fetchInvitationDetails],
    );

    const clearContextState = useCallback(() => {
        setInvitationDetails(null);
        setInvitationDetailsUpdatedId(null);
    }, []);

    const contextValue: Context.OrganisationIncomingInvitation.Value = {
        clearContextState,
        clearDynamicLinkInvitationId,
        detectPendingTeamInvitation,
        invitationDetails,
        invitationDetailsUpdatedId,
        setInvitationDetailsUpdatedId,
    };

    organisationIncomingInvitationContextRef.current = contextValue;

    return (
        <OrganisationIncomingInvitationContext.Provider value={contextValue}>
            {children}
        </OrganisationIncomingInvitationContext.Provider>
    );
};

function useOrganisationIncomingInvitationContext() {
    const organisationIncomingInvitationContext = useContext(
        OrganisationIncomingInvitationContext,
    );

    return organisationIncomingInvitationContext;
}

function clearOrganisationIncomingInvitationContextState() {
    return organisationIncomingInvitationContextRef.current?.clearContextState();
}

export {
    OrganisationIncomingInvitationContext,
    OrganisationIncomingInvitationContextProvider,
    organisationIncomingInvitationContextRef,
    useOrganisationIncomingInvitationContext,
    clearOrganisationIncomingInvitationContextState,
};
