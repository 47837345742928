export enum OrganisationEvents {
    teamMemberCreatedContact = 'team_member_created_contact',
    teamMemberCreatedHorse = 'team_member_created_horse',
    teamMemberCreatedEntry = 'team_member_created_entry',
    teamMemberAddedPhotoToEntry = 'team_member_added_photo_to_entry',
    teamMemberCreatedInvoice = 'team_member_created_invoice',
    teamMemberCreatedEvent = 'team_member_created_event',
    teamMemberCreatedShoppingList = 'team_member_created_horse',
    teamMemberUpdatedShoppingListTitle = 'team_member_updated_shopping_list_title',
    teamMemberAddedProductToInventory = 'team_member_added_product_to_inventory',
    teamAdminDeletedMemberFromTeam = 'team_admin_deleted_member_from_team',
    teamAdminGeneratedInvitation = 'team_admin_generated_invitation',
    teamMemberGeneratedInvitation = 'team_member_generated_invitation',
    teamAdminGeneratedInvitationViaEmail = 'team_admin_sent_email_invitation',
    teamMemberGeneratedInvitationViaEmail = 'team_member_sent_email_invitation',
    userCreateTeam = 'user_create_team',
    teamInvitationApproved = 'team_invitation_approved',
}
