// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { Associations } from '@nozbe/watermelondb/Model';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class UserAnswer extends Model {
    static table = 'user_answers';

    static associations: Associations = {
        answers: { type: 'belongs_to', key: 'answer_id' },
        user: { type: 'belongs_to', key: 'user_id' },
    };

    @field('user_id') userId;
    @field('answer_id') answerId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
