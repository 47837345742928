import { Avatar, Box, Skeleton } from '@mui/material';
import React from 'react';

import multipleImagesIcon from '@/assets/images/multiple_photos.png';
import { CameraIcon } from '@/assets/svg';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function EntryAvatar({ isLoading, photosCount, imageUrl }: Props) {
    if (isLoading) {
        return (
            <Box className="min-w-[194px]">
                <Skeleton className="w-14 h-16 mr-0.5" variant="rectangular" />
            </Box>
        );
    }

    return (
        <div className="relative mr-4 h-[74px] w-[74px] rounded-md">
            <Avatar className="w-full h-full" src={imageUrl} variant="rounded">
                <CameraIcon color={COLOR.mischka} />
            </Avatar>
            {photosCount ? (
                <div className="absolute bottom-1 right-1">
                    <img
                        className="w-[15px] h-[15px]"
                        alt="multiple_image_icon"
                        src={multipleImagesIcon}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default EntryAvatar;
