import { isObject } from 'shared/utils/data';

import { t } from '@/services/translations/config';
import { SelectOption } from '@/types/select';

type FilterValue<T> =
    | string
    | string[]
    | boolean
    | Record<string, string>[]
    | (Record<string, T> & { isActive: boolean });

export function renderShoeingCycleFilterValues(
    selectedOptions: string[],
): string {
    return selectedOptions
        .map(
            (selectedOption) =>
                `${selectedOption} ${t(
                    Number(selectedOption) === 1
                        ? 'Generic:week'
                        : 'Generic:weeks',
                )}`,
        )
        .join(', ');
}

export function renderSelectedFilterValues(
    translationsObject: Record<string, string>,
) {
    return (selectedOptions: string[]): string => {
        return selectedOptions
            .map((selectedOption) => t(translationsObject[selectedOption]))
            .join(', ');
    };
}

function isObjectWithActiveFlag<T>(
    object: FilterValue<T>,
): object is Record<string, T> & { isActive: boolean } {
    return (
        (object as Record<string, T> & { isActive: boolean }).isActive !==
        undefined
    );
}

export function checkIfAnyFilterIsActive<T>(
    filtersState: Record<string, FilterValue<T>>,
): boolean {
    const commonAppliedFiltersArray: string[] = [];

    for (const filterKey in filtersState) {
        const filterValue = filtersState[filterKey];

        if (Array.isArray(filterValue)) {
            // This is just to avoid type error of pushing objects into `string[]` array.
            // We care only about pushing any value to `commonAppliedFiltersArray`
            // if any occurs in `filterValue` so that `!!commonAppliedFiltersArray.length`
            // gives correct result at the end of the function
            const flatFilterValue = filterValue.map((value) =>
                typeof value !== 'string' ? 'value' : value,
            );

            commonAppliedFiltersArray.push(...flatFilterValue);
        }

        if (typeof filterValue === 'boolean' && filterValue === true) {
            commonAppliedFiltersArray.push(filterKey);
        }

        if (typeof filterValue === 'string' && !!filterValue) {
            commonAppliedFiltersArray.push(filterKey);
        }

        if (isObject(filterValue) && isObjectWithActiveFlag(filterValue)) {
            if (filterValue.isActive) {
                commonAppliedFiltersArray.push(filterKey);
            }
        }
    }

    return !!commonAppliedFiltersArray.length;
}

export function generateSelectOptionsFromDictionary<T extends string>(
    object: Record<T, string>,
): SelectOption[] {
    return Object.keys(object).reduce((acc, key) => {
        return [
            ...acc,
            {
                id: key,
                translate: true,
                translationKey: object[key],
                value: key,
            },
        ];
    }, [] as SelectOption[]);
}
