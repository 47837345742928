import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

import { APP_CONFIG } from '@/constants/appConfig';

function init() {
    if (!process.env.__DEV__) {
        Sentry.init({
            dsn: APP_CONFIG.sentryDns,
            environment: APP_CONFIG.environmentName,
            tracesSampler: (context) => {
                return context?.request?.url?.includes('sync/changes') ||
                    process.env.ENVIRONMENT_NAME !== 'production'
                    ? 1.0
                    : 0.7;
            },
        });
    }
}

function recordError(err, level?: SeverityLevel) {
    let capturedError;

    if (typeof err === 'object') {
        capturedError = new Error();
        capturedError.name = err.title || 'Error';
        capturedError.message = err.message || 'Error message';

        const errDataKeys = err.data && Object.keys(err.data);
        if (errDataKeys && errDataKeys.length) {
            errDataKeys.forEach((key) => Sentry.setExtra(key, err.data[key]));
        }
    } else {
        capturedError = err;
    }

    if (level) {
        Sentry.withScope((scope) => {
            scope.setLevel(level);
            Sentry.captureException(capturedError);
        });
    } else {
        Sentry.captureException(capturedError);
    }
}

function setUser(user: string) {
    Sentry.setUser({ id: user });
}

export default { init, recordError, setUser };
