import { LogEvent } from '../../types/analytics';
import { EventModel } from '../../types/Events';

import { OrganisationAnalytics } from '../organisation';
import { SchedulesEvents } from './events';

function logUserSetEventAlert(logEvent: LogEvent, params: { alert: string }) {
    logEvent(SchedulesEvents.userSetEventAlert, {
        alert: params.alert,
    });
}

function logUserClickedEventNotifyContacts(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userClickedEventNotifyContacts);
}

function logUserClickedEventInviteContacts(
    logEvent: LogEvent,
    numberOfInvitedContacts: number,
) {
    logEvent(SchedulesEvents.userClickedEventInviteContacts, {
        numberOfInvitedContacts,
    });
}

function logUserCreatedEvent(
    logEvent: LogEvent,
    params: {
        event: EventModel;
        isOwner: boolean | null;
        numberOfInvitedContacts?: number;
        membersIds: string[];
    },
) {
    logEvent(SchedulesEvents.userCreatedEvent, {
        category: params.event.category,
        created_at: params.event.createdAt,
        ends_time: params.event.endsTime,
        id: params.event.id,
        organisation_id: params.event.organisationId,
        starts_time: params.event.startsTime,
        title: params.event.title,
        updated_at: params.event.updatedAt,
        user_id: params.event.userId,
        membersIds: params.membersIds,
    });

    if (params.event.userReminder) {
        logUserSetEventAlert(logEvent, {
            alert: params.event.userReminder,
        });
    }

    if (params.event.contactsReminder) {
        logUserClickedEventNotifyContacts(logEvent);
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedEvent(logEvent);
    }

    if (params.numberOfInvitedContacts) {
        logUserClickedEventInviteContacts(
            logEvent,
            params.numberOfInvitedContacts,
        );
    }
}

function logUserUpdatedEvent(
    logEvent: LogEvent,
    params: {
        event: EventModel;
        previousUserReminder?: string | null;
        previousContactsReminder?: boolean;
        numberOfInvitedContacts?: number;
        previousNumberOfInvitedContacts?: number;
        membersIds: string[];
    },
) {
    logEvent(SchedulesEvents.userUpdatedEvent, {
        category: params.event.category,
        created_at: params.event.createdAt,
        ends_time: params.event.endsTime,
        id: params.event.id,
        organisation_id: params.event.organisationId,
        starts_time: params.event.startsTime,
        title: params.event.title,
        updated_at: params.event.updatedAt,
        user_id: params.event.userId,
        membersIds: params.membersIds,
    });

    if (
        params.event.userReminder &&
        params.event.userReminder !== params.previousUserReminder
    ) {
        logUserSetEventAlert(logEvent, {
            alert: params.event.userReminder,
        });
    }

    if (params.event.contactsReminder && !params.previousContactsReminder) {
        logUserClickedEventNotifyContacts(logEvent);
    }

    if (
        params.numberOfInvitedContacts &&
        params.numberOfInvitedContacts !==
            params.previousNumberOfInvitedContacts
    ) {
        logUserClickedEventInviteContacts(
            logEvent,
            params.numberOfInvitedContacts,
        );
    }
}

function logUserDeletedEvent(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userDeletedEvent);
}

function logUserSwitchedToEventsListView(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userSwitchedToEventsListView);
}

function logUserSwitchedToEventsDayView(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userSwitchedToEventsDayView);
}

function logUserSwitchedToEventsWeekView(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userSwitchedToEventsWeekView);
}

function logUserSwitchedToEventsMonthView(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userSwitchedToEventsMonthView);
}

function logUserDuplicatedEvent(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userDuplicatedEvent);
}

function logUserClickedEventNavigateButton(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userClickedEventNavigateButton);
}

function logUserClickedSMSButton(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userClickedSMSButton);
}

function logUserSentEventSMS(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userSentEventSMS);
}

function logUserCreatedEventFromCalendarView(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userCreatedEventFromCalendarView);
}

function logUserAcceptedAppointment(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userAcceptedAppointment);
}

function logUserDeclinedAppointment(logEvent: LogEvent) {
    logEvent(SchedulesEvents.userDeclinedAppointment);
}

function logUserSwitchedEventsView(
    logEvent: LogEvent,
    params: {
        view: 'day' | 'month' | 'week' | 'agenda';
    },
) {
    switch (params.view) {
        case 'day':
            logUserSwitchedToEventsDayView(logEvent);
            break;
        case 'month':
            logUserSwitchedToEventsMonthView(logEvent);
            break;
        case 'week':
            logUserSwitchedToEventsWeekView(logEvent);
            break;
        case 'agenda':
            logUserSwitchedToEventsListView(logEvent);
            break;
    }
}

export const SchedulesAnalytics = {
    logUserCreatedEvent,
    logUserUpdatedEvent,
    logUserDeletedEvent,
    logUserSwitchedEventsView,
    logUserDuplicatedEvent,
    logUserClickedEventNavigateButton,
    logUserClickedSMSButton,
    logUserSentEventSMS,
    logUserCreatedEventFromCalendarView,
    logUserAcceptedAppointment,
    logUserDeclinedAppointment,
};
