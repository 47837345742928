import { LinearProgress } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import BackdropLoader from '@/components/Loaders/BackdropLoader';

import { Props } from './types';

function Loaders({ isInitialSyncInProgress, isSyncInProgress }: Props) {
    const { t } = useTranslation();

    return (
        <>
            {isSyncInProgress ? (
                <LinearProgress
                    sx={{ position: 'absolute', left: 0, top: 0, right: 0 }}
                    data-test-id="LinearLoadingSpinner"
                />
            ) : null}
            <BackdropLoader
                isLoading={isInitialSyncInProgress}
                loadingText={t('Login:initial_sync')}
                testID="BackdropLoader"
            />
        </>
    );
}

export default memo(Loaders);
