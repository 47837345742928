import { Q } from '@nozbe/watermelondb';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Database from 'shared/db/services/Database.web';
import Organisation from 'shared/db/services/Organisation';
import Product from 'shared/db/services/Product';
import ShoppingList from 'shared/db/services/ShoppingList';
import ShoppingListProduct from 'shared/db/services/ShoppingListProduct';
import User from 'shared/db/services/User';
import { InventoryProductSize } from 'shared/types/Products';
import {
    ShoppingListModel,
    ShoppingListPayload,
    ShoppingListProductSize,
} from 'shared/types/ShoppingList';
import { ShoppingListProductChange } from 'shared/types/ShoppingListProduct';
import {
    getAddProductsDictionary,
    getGroupedProducts,
} from 'shared/utils/product';
import {
    getShoppingListProducts,
    updateShoppingListProductSize,
} from 'shared/utils/shoppingList';

import { SelectItemsModal, ShoppingListsList } from '@/components';
import { Props as ShoppingListsListProps } from '@/components/ShoppingListsList/types';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useCancelEntityFormAlert,
    useEscapeButton,
} from '@/hooks';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { formatDate } from '@/utils/date';
import { getRoutePath } from '@/utils/router';

import { Footer } from './components';
import { Props } from './types';

function AddProductToShoppingListModal({ close, productSizeToAdd }: Props) {
    const previousShoppingListSelection = useRef<ShoppingListModel | null>(
        null,
    );

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { isSyncInProgress } = useDBSyncContext();
    const { userProfileData } = useUserContext();

    const [createNewShoppingList, setCreateNewShoppingList] = useState(false);
    const [isUpdatingShoppingLists, setIsUpdatingShoppingLists] =
        useState(false);
    const [selectedShoppingList, setSelectedShoppingList] =
        useState<ShoppingListModel | null>(null);

    const { ImagesService } = useImagesContext();

    const shoppingListService = useMemo(
        () =>
            new ShoppingList({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService],
    );

    const productService = useMemo(
        () =>
            new Product({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService],
    );
    const userService = useMemo(
        () =>
            new User({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService],
    );

    const fetchShoppingLists = useCallback(async () => {
        const queries = [
            Q.sortBy('added_to_inventory_time', 'asc'),
            Q.sortBy('created_at', 'desc'),
        ];

        const shoppingLists = await shoppingListService.getAll(...queries);

        return shoppingLists;
    }, [shoppingListService]);

    const handleOnSelectedShoppingListsChange = useCallback(
        (shoppingLists: ShoppingListModel[]) => {
            const [shoppingList] = shoppingLists;

            setSelectedShoppingList(shoppingList);

            previousShoppingListSelection.current = shoppingList;
        },
        [],
    );

    const isShoppingListNotAddedToInventory = useCallback(
        (shoppingList: ShoppingListModel) => {
            return !shoppingList.addedToInventoryTime;
        },
        [],
    );

    const renderConditionWarning = useCallback(
        (shoppingList: ShoppingListModel) => {
            if (!shoppingList.addedToInventoryTime) {
                return '';
            }

            return `${t('ShoppingList:added_to_inventory')} ${formatDate(
                shoppingList.addedToInventoryTime,
                'date',
            )}`;
        },
        [t],
    );

    const addProductToShoppingList = useCallback(
        async (
            productToAdd: InventoryProductSize,
            shoppingList: ShoppingListModel,
        ) => {
            if (!userProfileData?.id) {
                throw new Error();
            }

            const shoppingListProductService = new ShoppingListProduct({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const organisationService = new Organisation({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const productDictionary = getAddProductsDictionary([
                {
                    id: productToAdd.productId,
                    quantity: '1',
                },
            ]);

            const product = await productService.getByID(
                productToAdd.productId,
            );

            const allGroupedProductsFromShoppingList = getGroupedProducts([
                product,
            ]);

            const shoppingListProducts =
                await shoppingList.shoppingListProducts.fetch();

            const shoppingListProductsToAdd = await getShoppingListProducts({
                groupedProductsForShoppingList:
                    allGroupedProductsFromShoppingList,
                userId: userProfileData.id,
                params: {
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                    logError: Logger.logError,
                    userId: userProfileData.id,
                },
                database: Database.getDatabase(),
                userService,
                productService,
                shoppingListProducts,
                productQuantities: productDictionary,
            });

            const shoppingListProductChanges: ShoppingListProductChange[] = [];

            shoppingListProductsToAdd.forEach((product) =>
                shoppingListProductChanges.push(
                    ...product.productSizes.map((size) => {
                        const product = productDictionary[size.productId];

                        return {
                            quantity:
                                (size.quantity ?? 0) +
                                (parseInt(product.quantity, 10) || 1),
                            productSize: size,
                            selectedUnit: product.unitType,
                        } as ShoppingListProductChange;
                    }),
                ),
            );

            await updateShoppingListProductSize({
                changes: shoppingListProductChanges,
                shoppingListId: shoppingList.id,
                userId: userProfileData.id,
                shoppingListProductService,
                organisationService,
            });
        },
        [ImagesService, productService, userProfileData?.id, userService],
    );

    const createNewShoppingListWithProduct = useCallback(
        async (shoppingListTitle: string) => {
            if (!userProfileData?.id) {
                throw new Error();
            }

            const productsDictionary = getAddProductsDictionary([
                {
                    id: productSizeToAdd.productId,
                    quantity: '1',
                },
            ]);

            const product = await productService.getByID(
                productSizeToAdd.productId,
            );

            const allGroupedProductsFromShoppingList = getGroupedProducts([
                product,
            ]);

            const shoppingListProducts = await getShoppingListProducts({
                groupedProductsForShoppingList:
                    allGroupedProductsFromShoppingList,
                userId: userProfileData.id,
                params: {
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                    logError: Logger.logError,
                    userId: userProfileData.id,
                },
                database: Database.getDatabase(),
                userService,
                productService,
            });

            const shoppingListProductsWithQuantities = shoppingListProducts.map(
                (shoppingListProduct) => ({
                    ...shoppingListProduct,
                    productSizes: shoppingListProduct.productSizes.map(
                        (productSize) => {
                            const product =
                                productsDictionary[productSize.productId];
                            return {
                                ...productSize,
                                quantity: parseInt(product.quantity, 10),
                                selectedUnit:
                                    product.unitType ||
                                    productSize.selectedUnit,
                            } as ShoppingListProductSize;
                        },
                    ),
                }),
            );

            const payload: ShoppingListPayload = {
                name: shoppingListTitle,
                shoppingListProducts: shoppingListProductsWithQuantities,
            };

            const shoppingList = await shoppingListService.add(
                payload,
                userProfileData.id,
            );

            return shoppingList.id;
        },
        [
            ImagesService,
            productService,
            productSizeToAdd.productId,
            shoppingListService,
            userProfileData?.id,
            userService,
        ],
    );

    const navigateToShoppingList = useCallback(
        (id: string) => {
            const path = getRoutePath(ROUTE.shoppingList, { id });

            navigate(path);
        },
        [navigate],
    );

    const handleAddToShoppingListButtonClick = useCallback(
        async (newShoppingListTitle: string) => {
            try {
                let shoppingListIdToNavigate = '';

                setIsUpdatingShoppingLists(true);

                if (createNewShoppingList) {
                    const newShoppingListId =
                        await createNewShoppingListWithProduct(
                            newShoppingListTitle,
                        );

                    shoppingListIdToNavigate = newShoppingListId;
                } else {
                    if (!selectedShoppingList) {
                        throw new Error();
                    }

                    await addProductToShoppingList(
                        productSizeToAdd,
                        selectedShoppingList,
                    );

                    shoppingListIdToNavigate = selectedShoppingList.id;
                }

                setIsUpdatingShoppingLists(false);
                navigateToShoppingList(shoppingListIdToNavigate);

                Snackbar.showToastNotification({
                    message: t(
                        'ShoppingList:products:added_to_shopping_list_successfully',
                    ),
                });
            } catch (error) {
                setIsUpdatingShoppingLists(false);

                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            addProductToShoppingList,
            createNewShoppingList,
            createNewShoppingListWithProduct,
            navigateToShoppingList,
            productSizeToAdd,
            selectedShoppingList,
            t,
        ],
    );

    const handleNewShoppingListSwitchChange = useCallback(
        (checked: boolean) => {
            setCreateNewShoppingList(checked);

            // Clear shopping list selection when new shopping list switch is checked and
            // bring previous selection (if exists) back when switch is unchecked
            setSelectedShoppingList(
                checked ? null : previousShoppingListSelection.current,
            );
        },
        [],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel:
                !!selectedShoppingList || createNewShoppingList,
        });

    useEscapeButton(handleFormCancelButtonClick);

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    return (
        <>
            {showCancelAlert ? renderCancelAlert() : null}
            <SelectItemsModal<ShoppingListModel, ShoppingListsListProps>
                fetchItemsFn={fetchShoppingLists}
                selectedItems={
                    selectedShoppingList ? [selectedShoppingList] : []
                }
                isOpen
                onSelectedItemsChange={handleOnSelectedShoppingListsChange}
                close={close}
                emptyMessage={t(
                    'ShoppingListsPage:no_shopping_lists_has_been_added',
                )}
                noItemsFoundMessage={t(
                    'ContactsList:no_contact_found_blankslate',
                )}
                testIdPrefix="SelectShoppingListsModal"
                title={t('ShoppingList:select_shopping_list')}
                ListComponent={ShoppingListsList}
                listComponentProps={{
                    conditionWarning: renderConditionWarning,
                    highlightAvatar: false,
                    itemWrapperStyle: 'mb-4',
                    multiSelect: false,
                    renderDateText: isShoppingListNotAddedToInventory,
                    selectCondition: isShoppingListNotAddedToInventory,
                    selectable: true,
                    testIdPrefix: 'SelectShoppingListsModal',
                }}
                onCancelButtonClick={close}
                CustomFooterComponent={
                    <Footer
                        onCancelButtonClick={handleFormCancelButtonClick}
                        isShoppingListSelected={!!selectedShoppingList}
                        onAddToShoppingListButtonClick={
                            handleAddToShoppingListButtonClick
                        }
                        onNewShoppingListSwitchChange={
                            handleNewShoppingListSwitchChange
                        }
                    />
                }
                searchable={false}
                multiSelect={false}
                backdropLoaderText={t('ShoppingList:updating_shopping_list')}
                showBackdropLoader
                blockListItemsSelection={createNewShoppingList}
                isLoading={isSyncInProgress || isUpdatingShoppingLists}
            />
        </>
    );
}

export default AddProductToShoppingListModal;
