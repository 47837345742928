import React, { memo } from 'react';

import { InvoicesListItem } from './components';
import { Props } from './types';

function InvoicesList({
    clickable,
    items,
    itemWrapperStyle,
    selectedItems,
    onItemClick,
    onRemove,
    testIdPrefix,
    selectable = false,
    showInvoices = false,
    removable = false,
    size = 'normal',
}: Props) {
    return (
        <>
            {items.map((invoice) => {
                return (
                    <InvoicesListItem
                        key={invoice.id}
                        clickable={clickable}
                        testID={`${testIdPrefix}-InvoicesListItem`}
                        invoice={invoice}
                        onRemove={onRemove}
                        wrapperStyles={itemWrapperStyle}
                        isSelected={selectedItems && selectedItems.has(invoice)}
                        onClick={onItemClick}
                        selectable={selectable}
                        showInvoices={showInvoices}
                        removable={removable}
                        size={size}
                    />
                );
            })}
        </>
    );
}
export default memo(InvoicesList) as (props: Props) => JSX.Element;
