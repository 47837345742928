import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { HorseModel } from 'shared/types/Horses';

import { COLOR } from '@/theme/colors';

export function renderTableItemHorsesText(
    horses: HorseModel[],
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        fontSize?: number;
        fontWeight?: number;
        testId?: string;
    },
): JSX.Element {
    const [horse, ...horsesRest] = horses;

    const firstPart = horse.name;
    const secondPart = horsesRest.length
        ? translateFn('EventsList:event:horses', {
              horses_count: horsesRest.length,
          })
        : '';

    const horsesRestTooltipText = horsesRest
        .map((horse) => horse.name)
        .join(', ');

    return (
        <Typography
            data-test-id={options?.testId}
            fontSize={options?.fontSize || 13}
            fontWeight={options?.fontWeight || 300}
        >
            {firstPart}
            {secondPart ? (
                <>
                    {` ${translateFn('Generic:and')} `}
                    <Tooltip
                        arrow
                        title={horsesRestTooltipText}
                        placement="top"
                    >
                        <span className="underline">{secondPart}</span>
                    </Tooltip>
                </>
            ) : (
                ''
            )}
        </Typography>
    );
}

export function renderEventHorses(
    horses: HorseModel[],
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        fontSize?: number;
        fontWeight?: number;
        testId?: string;
    },
): JSX.Element {
    if (horses.length <= 2) {
        const horsesLabel = horses.map((horse) => horse.name).join(', ');
        return (
            <Typography
                data-test-id={options?.testId}
                fontSize={options?.fontSize || 11}
                fontWeight={options?.fontWeight || 300}
                noWrap
            >
                {horsesLabel}
            </Typography>
        );
    } else {
        const [horse1, horse2, ...horsesRest] = horses;

        const firstPart = `${horse1.name}, ${horse2?.name}`;

        const secondPart = horsesRest.length
            ? translateFn('EventsList:event:plus_this_horses', {
                  horses_count: horsesRest.length,
              })
            : '';

        const horsesRestTooltipText = horsesRest
            .map((horse) => horse.name)
            .join(', ');

        return (
            <>
                <Typography
                    data-test-id={options?.testId}
                    fontSize={options?.fontSize || 11}
                    fontWeight={options?.fontWeight || 300}
                    noWrap
                >
                    {firstPart}
                </Typography>
                {secondPart ? (
                    <Tooltip
                        arrow
                        title={horsesRestTooltipText}
                        placement="top"
                    >
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 700,
                                color: COLOR.accountSetupBlack,
                            }}
                        >
                            {secondPart}
                        </Typography>
                    </Tooltip>
                ) : (
                    ''
                )}
            </>
        );
    }
}
export function renderDashboardEventHorses(
    horses: HorseModel[],
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        fontSize?: number;
        fontWeight?: number;
        testId?: string;
    },
): JSX.Element {
    const label = translateFn('EventsList:event:plus_horses', {
        horses_count: horses.length,
    });

    const horsesRestTooltipText = horses.map((horse) => horse.name).join(', ');

    return (
        <Tooltip arrow title={horsesRestTooltipText} placement="top">
            <Typography
                data-test-id={options?.testId}
                fontSize={options?.fontSize || 11}
                fontWeight={options?.fontWeight || 300}
            >
                {label}
            </Typography>
        </Tooltip>
    );
}
