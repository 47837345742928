import axios from 'axios';

import { APP_CONFIG } from '@/constants/appConfig';
import {
    GetAppointmentDetailsPayload,
    ReplyInvitationPayload,
    ReplyInvitationResponse,
} from '@/types/appointments';

const BASE_URL = APP_CONFIG.apiUrl;

async function getAppointmentDetails(
    token: string,
): Promise<GetAppointmentDetailsPayload> {
    const { data } = await axios.get<GetAppointmentDetailsPayload>(
        `${BASE_URL}/appointments`,
        {
            params: {
                token,
            },
        },
    );

    return data;
}

async function replyInvitation(
    payload: ReplyInvitationPayload,
): Promise<ReplyInvitationResponse> {
    const { data } = await axios.post<ReplyInvitationResponse>(
        `${BASE_URL}/appointments`,
        payload,
    );

    return data;
}

export const ExternalAppointmentAPI = {
    getAppointmentDetails,
    replyInvitation,
};
