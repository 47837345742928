// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, lazy, readonly } from '@nozbe/watermelondb/decorators';
import relation from '@nozbe/watermelondb/decorators/relation';
import { Associations } from '@nozbe/watermelondb/Model';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class OrganisationUser extends Model {
    static table = 'organisation_users';

    static associations: Associations = {
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        organisation_user_colors: {
            type: 'belongs_to',
            key: 'organisation_user_color_id',
        },
    };

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('organisation_user_colors', 'organisation_user_color_id')
    organisationUserColors;

    @field('active') active;
    @field('organisation_id') organisationId;
    @field('organisation_user_color_id') organisationUserColorId;
    @field('role') role;
    @field('user_id') userId;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
