import * as yup from 'yup';

import { t } from '@/services/translations/config';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const priceValidator = yup
    .string()
    .transform((_value, originalValue) => originalValue.replace(/,/, '.'))
    .test('is-number', t('Procedure:validation:not_number'), (val: string) => {
        if (val !== undefined) {
            const number = Number(val);

            return !isNaN(number);
        }
        return true;
    })
    .test(
        'is-decimal',
        t('Procedure:validation:decimal_case'),
        (val: string) => {
            if (val !== undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        },
    )
    .required(
        t('SignUp:error:field_required', {
            field: t('Procedure:label:price'),
        }),
    );

const addEditProcedureForm = yup.object().shape({
    category: yup.string().required(
        t('SignUp:error:field_required', {
            field: t('Procedure:label:category'),
        }),
    ),
    name: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('Procedure:label:name'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('Procedure:label:name'),
            }),
        ),
    price: priceValidator,
});

export const ProcedureValidationSchema = { addEditProcedureForm };
