import { useCallback, useEffect } from 'react';

export function useEscapeButton(action: VoidFunction) {
    const escapeWithESC = useCallback(
        (e) => {
            if (e.code === 'Escape') {
                action();
            }
        },
        [action],
    );

    useEffect(() => {
        window.addEventListener('keyup', escapeWithESC);
        return () => window.removeEventListener('keyup', escapeWithESC);
    }, [escapeWithESC]);
}
