import { Notifications, EventAvailable } from '@mui/icons-material';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { of } from 'rxjs';
import { SchedulesAnalytics } from 'shared/analytics/schedules/schedules';
import Database from 'shared/db/services/Database.web';
import { getUsersByEventId } from 'shared/db/utils';
import { EventCategory } from 'shared/types/Events';
import { getContactsText } from 'shared/utils/contacts';
import { parseAlert, parseInvitations } from 'shared/utils/events';
import moment from 'shared/utils/moment';

import { CalendarEventIcon, UserProfileIcon } from '@/assets/svg';
import { DateBox, OrganisationMembersAvatarGroup } from '@/components';
import { useFormatDate } from '@/hooks';
import { useEntityColor } from '@/hooks/useEntityColor';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseImagesService } from '@/services/firebase/images';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const database = Database.getDatabase();

function HeaderSection({ isLoading, event, users, contacts }: Props) {
    const formatDate = useFormatDate();
    const { t } = useTranslation();

    const contactsList = getContactsText(contacts ?? []);
    const eventHasContactsInvited = contactsList !== '';

    const duration = useMemo(
        () =>
            Math.round(
                10 *
                    moment
                        .duration(
                            moment(event?.endsTime).diff(
                                moment(event?.startsTime),
                            ),
                        )
                        .asHours(),
            ) / 10,
        [event?.startsTime, event?.endsTime],
    );

    const startTime = useMemo(
        () => formatDate(event?.startsTime, 'time'),
        [event?.startsTime, formatDate],
    );
    const startDate = useMemo(
        () => formatDate(event?.startsTime, 'date'),
        [event?.startsTime, formatDate],
    );

    const endsTime = useMemo(
        () => formatDate(event?.endsTime, 'time'),
        [event?.endsTime, formatDate],
    );

    const invites = useMemo(
        () => parseInvitations(event?.ownersReminder, event?.contactsReminder),
        [event?.ownersReminder, event?.contactsReminder],
    );
    const alert = useMemo(
        () => parseAlert(event?.userReminder),
        [event?.userReminder],
    );

    const dateColor = useEntityColor(users);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'stretch',
                p: 2,
                bgcolor: COLOR.transparentLightGrayBackground,
                borderRadius: '16px 16px 2px 2px',
                mb: 7.5,
            }}
        >
            <Box>
                {isLoading || !event?.startsTime ? (
                    <Skeleton
                        sx={{ mr: 1 }}
                        variant={'rectangular'}
                        width={100}
                        height={100}
                    />
                ) : (
                    <DateBox
                        large
                        textColor={COLOR.white}
                        backgroundColor={dateColor || COLOR.paleSky02}
                        date={event?.startsTime}
                        lineHeight="32px"
                    />
                )}
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
                >
                    {eventHasContactsInvited ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                ml: 2,
                            }}
                        >
                            <Box className="flex-shrink-0">
                                <UserProfileIcon width={24} height={26} />
                            </Box>
                            <Typography
                                className="text-ebonyClay"
                                data-test-id="AppointmentPage-TitleTextContacts"
                                fontSize={22}
                                fontWeight={700}
                                sx={{
                                    overflow: 'hidden',
                                    display: ' -webkit-box',
                                    '-webkit-box-orient': 'vertical',
                                    '-webkit-line-clamp': '1',
                                }}
                            >
                                {!isLoading ? (
                                    contactsList
                                ) : (
                                    <Skeleton width={150} variant="text" />
                                )}
                            </Typography>
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            ml: 2,
                        }}
                    >
                        {event?.category === EventCategory.standard ? (
                            <CalendarEventIcon
                                width={24}
                                height={26}
                                color={COLOR.black}
                            />
                        ) : (
                            <HardwareIcon
                                sx={{
                                    color: COLOR.black,
                                }}
                            />
                        )}
                        <Typography
                            className="text-ebonyClay"
                            data-test-id="AppointmentPage-TitleText"
                            fontSize={eventHasContactsInvited ? 16 : 22}
                            fontWeight={
                                eventHasContactsInvited ? undefined : 700
                            }
                        >
                            {!isLoading ? (
                                event?.title
                            ) : (
                                <Skeleton width={150} variant="text" />
                            )}
                        </Typography>
                    </Box>
                    <Typography
                        className="text-ebonyClay"
                        data-test-id="AppointmentPage-TypeText"
                        fontSize={14}
                        fontWeight={700}
                        sx={{ ml: 2 }}
                    >
                        {event?.category === EventCategory.standard
                            ? t('ExternalAppointment:labels:appointment')
                            : t(
                                  'ExternalAppointment:labels:service_appointment',
                              )}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        className="text-ebonyClay"
                        data-test-id="AppointmentPage-Date"
                        fontSize={12}
                        fontWeight={300}
                        sx={{ ml: 2 }}
                    >
                        {!isLoading ? (
                            startDate ?? ''
                        ) : (
                            <Skeleton width={150} variant="text" />
                        )}
                    </Typography>
                    <Typography
                        className="text-ebonyClay"
                        data-test-id="AppointmentPage-Time"
                        fontSize={12}
                        fontWeight={700}
                        sx={{ ml: 2 }}
                    >
                        {!isLoading ? (
                            `${startTime} - ${endsTime} (${duration} h)`
                        ) : (
                            <Skeleton width={150} variant="text" />
                        )}
                    </Typography>
                </Box>
                {event?.address ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="small"
                            color="inherit"
                            disabled={isLoading}
                            startIcon={<NearMeOutlinedIcon />}
                            target="_blank"
                            onClick={() =>
                                SchedulesAnalytics.logUserClickedEventNavigateButton(
                                    FirebaseAnalytics.logEvent,
                                )
                            }
                            href={`http://maps.google.com/?q=${event?.address}`}
                            rel="noopener noreferrer"
                            data-test-id="AppointmentPage-LocationButton"
                            sx={{ ml: 1.5, color: COLOR.skyBlue }}
                        >
                            <Typography
                                fontSize={12}
                                fontWeight={700}
                                color={COLOR.skyBlue}
                            >
                                {!isLoading ? (
                                    t('Appointment:button:route')
                                ) : (
                                    <Skeleton width={200} variant="text" />
                                )}
                            </Typography>
                        </Button>

                        <LocationOnIcon
                            sx={{ color: COLOR.paleSky, width: 24, height: 24 }}
                        />
                        <Typography
                            className="text-paleSky"
                            fontSize={12}
                            fontWeight={300}
                            sx={{ ml: '3px' }}
                        >
                            {!isLoading ? (
                                event?.address ? (
                                    event?.address
                                ) : (
                                    t('Appointment:no_address')
                                )
                            ) : (
                                <Skeleton width={200} variant="text" />
                            )}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
            <Box
                sx={{
                    justifySelf: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'flex-end',
                }}
            >
                <Box sx={{ justifySelf: 'flex-start' }}>
                    <Typography
                        className="text-ebonyClay"
                        data-test-id="AppointmentPage-Alert"
                        fontSize={14}
                        fontWeight={400}
                    >
                        {!isLoading ? (
                            <>
                                <Notifications sx={{ mr: 1 }} />
                                {t(alert)}
                            </>
                        ) : (
                            <Skeleton width={150} variant="text" />
                        )}
                    </Typography>
                    <Typography
                        className="text-ebonyClay"
                        data-test-id="AppointmentPage-Invite"
                        fontSize={14}
                        fontWeight={400}
                    >
                        {!isLoading ? (
                            <>
                                <EventAvailable sx={{ mr: 1 }} />
                                {t(invites)}
                            </>
                        ) : (
                            <Skeleton width={150} variant="text" />
                        )}
                    </Typography>
                </Box>
                <Box>
                    {!!users && (
                        <OrganisationMembersAvatarGroup
                            database={database}
                            users={users}
                            testID="SchedulesListItem-Members"
                            max={3}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['event'],
    ({ event }: Props) => {
        return {
            users: event
                ? getUsersByEventId(event.id ?? '', new FirebaseImagesService())
                : of([]),
            contacts: event ? event.contacts.observe() : of([]),
        };
    },
);

export default enhance(HeaderSection);
