import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectableProceduresGroupedList from '@/components/SelectableProceduresGroupedList';
import { Props as SelectableProceduresGroupedListProps } from '@/components/SelectableProceduresGroupedList/types';
import TruncatedListSection from '@/components/TruncatedListSection';

import { Props } from './types';

function EntryProceduresSection({
    procedures,
    testIdPrefix,
    isLoading,
    showPrice = true,
}: Props) {
    const { t } = useTranslation();

    return (
        <TruncatedListSection<SelectableProceduresGroupedListProps>
            ListComponent={SelectableProceduresGroupedList}
            title={t('Procedures:section:procedures')}
            buttonText={t('Procedures:button:more')}
            emptyMessage={t('Procedures:section:blankslate')}
            listComponentProps={{
                clickable: false,
                items: procedures,
                itemWrapperStyles: 'px-4 rounded-lg',
                testIdPrefix,
                backgroundColor: 'lightBlue',
                itemHeight: '[74px]',
                showTotalPrice: true,
                showPrice,
            }}
            isLoading={isLoading}
        />
    );
}

export default EntryProceduresSection;
