import React from 'react';

import { EntryProduct } from './components';
import { Props } from './types';

function EntryProductsList({
    isClickable,
    isCompact = false,
    items,
    onEditButtonClick,
    onItemClick,
    onRemove,
    testIdPrefix,
}: Props) {
    return (
        <>
            {items.map((product) => (
                <EntryProduct
                    entryProduct={product}
                    isClickable={isClickable}
                    isCompact={isCompact}
                    key={product.productId}
                    onEditButtonClick={onEditButtonClick}
                    onItemClick={onItemClick}
                    onRemove={onRemove}
                    testIdPrefix={testIdPrefix}
                />
            ))}
        </>
    );
}

export default EntryProductsList;
