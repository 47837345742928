import { Popover, Typography } from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { HamburgerIcon } from '@/assets/svg';
import SidebarLinksList from '@/components/SidebarLinksList';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { getLinksPaths } from '@/helpers/sidebar';
import { useRouteMatch } from '@/hooks';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function MoreMenu({ currentPathname, hiddenLinks }: Props) {
    const { isInitialSyncInProgress } = useDBSyncContext();
    const moreButtonRef = useRef<HTMLDivElement | null>(null);

    const moreMenuLinksPaths = useMemo(
        () => getLinksPaths(hiddenLinks),
        [hiddenLinks],
    );

    const isMoreButtonActive = useRouteMatch(moreMenuLinksPaths);
    const { t } = useTranslation();

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );

    const handleMoreButtonClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElement(event.currentTarget);
        },
        [],
    );

    const handleMenuClose = useCallback(() => {
        setAnchorElement(null);
    }, []);

    useEffect(() => {
        if (isInitialSyncInProgress) {
            handleMenuClose();
        }
    }, [handleMenuClose, isInitialSyncInProgress]);

    return (
        <>
            <div
                className={`flex items-center h-12 mb-0.5 rounded-lg hover:cursor-pointer ${
                    isMoreButtonActive ? 'bg-blue-50' : 'hover:bg-blue-50/50'
                } `}
                onClick={handleMoreButtonClick}
                ref={moreButtonRef}
                role="button"
            >
                <div className="my-3.5 ml-5">
                    <HamburgerIcon
                        color={
                            isMoreButtonActive ? COLOR.deepCerulean : undefined
                        }
                    />
                </div>
                <Typography
                    sx={{ ml: 1.5 }}
                    color={
                        isMoreButtonActive ? COLOR.deepCerulean : COLOR.paleSky
                    }
                    fontWeight={isMoreButtonActive ? 700 : 400}
                    variant="body2"
                    data-test-id="SidebarLinksListItem-More"
                >
                    {t('Sidebar:more')}
                </Typography>
            </div>
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClick={handleMenuClose}
                onClose={handleMenuClose}
                open={!!anchorElement}
                PaperProps={{
                    sx: {
                        minWidth: moreButtonRef.current?.clientWidth || '246px',
                        py: 1,
                    },
                }}
            >
                <SidebarLinksList
                    currentPathname={currentPathname}
                    links={hiddenLinks}
                />
            </Popover>
        </>
    );
}

export default MoreMenu;
