import { OrganisationsAPI } from '@/services/networking/organisations';
import { Organisation } from '@/types/organisations';

async function getUserDefaultOrganisation(): Promise<Organisation | undefined> {
    const data = await OrganisationsAPI.getOrganisations();

    const defaultOrganisation = data?.payload?.organisations?.find(
        (org) => org.owner === true,
    );

    return defaultOrganisation;
}

export { getUserDefaultOrganisation };
