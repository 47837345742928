import {
    InvoicesDueFiltersDictionary,
    InvoicesStatusFiltersDictionary,
} from '../../types/invoicesFilters';

export enum INVOICE_STATUS {
    approved = 'APPROVED',
    authorised = 'AUTHORISED',
    sent = 'SENT',
    draft = 'DRAFT',
    overdue = 'OVERDUE',
    paid = 'PAID',
    queued = 'QUEUED',
}

export const INVOICES_STATUSES_DICTIONARY: Record<INVOICE_STATUS, string> = {
    [INVOICE_STATUS.approved]: 'Invoice:filters:status:approved',
    [INVOICE_STATUS.authorised]: 'Invoice:filters:status:authorised',
    [INVOICE_STATUS.sent]: 'Invoice:filters:status:sent',
    [INVOICE_STATUS.draft]: 'Invoice:filters:status:draft',
    [INVOICE_STATUS.overdue]: 'Invoice:filters:due:overdue',
    [INVOICE_STATUS.paid]: 'Invoice:filters:status:paid',
    [INVOICE_STATUS.queued]: 'Invoice:filters:status:queued',
};

export const INVOICES_STATUS_FILTERS_DICTIONARY: InvoicesStatusFiltersDictionary =
    {
        [INVOICE_STATUS.authorised]: 'Invoice:filters:status:authorised',
        [INVOICE_STATUS.draft]: 'Invoice:filters:status:draft',
        [INVOICE_STATUS.paid]: 'Invoice:filters:status:paid',
        [INVOICE_STATUS.queued]: 'Invoice:filters:status:queued',
        [INVOICE_STATUS.sent]: 'Invoice:filters:status:sent',
    };

export const INVOICES_DUE_FILTERS_DICTIONARY: InvoicesDueFiltersDictionary = {
    [INVOICE_STATUS.overdue]: 'Invoice:filters:due:overdue',
};
