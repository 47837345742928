export enum ShoppingListsEvents {
    userCreatedShoppingList = 'user_created_shopping_list',
    userChangedShoppingListTitle = 'user_changed_shopping_list_title',
    userAddedProductToShoppingList = 'user_added_product_to_shopping_list',
    userAddedShoeToShoppingList = 'user_added_shoe_to_shopping_list',
    userAddedNailToShoppingList = 'user_added_nail_to_shopping_list',
    userAddedRaspToShoppingList = 'user_added_rasp_to_shopping_list',
    userAddedCustomProductToShoppingList = 'user_added_custom_product_to_shopping_li',
    userChangedAmountOfProductInShoppingList = 'user_changed_product_amount_in_shopping',
    userSharedShoppingListWithDealer = 'user_shared_shopping_list_with_dealer',
    userDownloadedShoppingList = 'user_downloaded_shopping_list',
    userAddedShoppingListToInventory = 'user_added_shopping_list_to_inventory',
    userUpdatedShoppingListTitle = 'user_updated_shopping_list_title',
    userDuplicatedShoppingList = 'user_duplicated_shopping_list',
    userClickedQuickSelectFromInventoryButton = 'user_clicked_quick_select_from_inventory',
}
