// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    lazy,
    relation,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class ProductSalesUnitRegion extends Model {
    static table = 'product_sales_unit_regions';

    static associations = {
        product_sales_units: {
            type: 'belongs_to',
            key: 'product_sales_unit_id',
        },
        regions: { type: 'belongs_to', key: 'region_id' },
    };

    @field('product_sales_unit_id') productSalesUnitId;
    @field('region_id') regionId;

    @relation('product_sales_units', 'product_sales_unit_id') productSalesUnit;
    @relation('regions', 'region_id') region;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
