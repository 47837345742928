import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryAnalytics } from 'shared/analytics/inventory';
import InventoryChange from 'shared/db/services/InventoryChange';
import InventoryProductService from 'shared/db/services/InventoryProduct';
import Product from 'shared/db/services/Product';
import { AddProductParams } from 'shared/types/Products';
import { getNowISO } from 'shared/utils/date';

import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks/useDatabase';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

export function useAddProductsToInventory(): (
    products: AddProductParams[],
) => Promise<void> {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();
    const { userOrganisation } = useOrganisationsContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const productService = useMemo(
        () =>
            new Product({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const inventoryChangesService = useMemo(
        () =>
            new InventoryChange({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const inventoryProductService = useMemo(
        () =>
            new InventoryProductService({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    return useCallback(
        async (products: AddProductParams[]) => {
            products.forEach(async (product) => {
                const alreadyCreatedInventoryProduct =
                    await inventoryProductService.getByParam(
                        'product_id',
                        product.id,
                    );

                try {
                    if (alreadyCreatedInventoryProduct.length === 1) {
                        const quantityChange = parseInt(product.quantity, 10);
                        await inventoryChangesService.add(
                            {
                                quantityChange,
                                inventoryProductId:
                                    alreadyCreatedInventoryProduct[0].id,
                                processAt: new Date(getNowISO()),
                            },
                            userProfileData?.id || '',
                        );

                        InventoryAnalytics.logUserChangedAmountOfProductInInventory(
                            FirebaseAnalytics.logEvent,
                            {
                                inventoryProduct:
                                    alreadyCreatedInventoryProduct[0],
                                quantityChange,
                                productService,
                            },
                        );
                    } else {
                        const payload = {
                            productId: product.id,
                            quantity: 0,
                            favourite: false,
                        };
                        const newInvProduct = await inventoryProductService.add(
                            payload,
                            userProfileData?.id || '',
                        );

                        await inventoryChangesService.add(
                            {
                                quantityChange: parseInt(product.quantity, 10),
                                inventoryProductId: newInvProduct.id,
                                processAt: new Date(getNowISO()),
                            },
                            userProfileData?.id || '',
                        );

                        InventoryAnalytics.logUserAddedProductToInventory(
                            FirebaseAnalytics.logEvent,
                            {
                                inventoryProduct: newInvProduct,
                                productService,
                                isOwner: userOrganisation?.owner ?? true,
                            },
                        );
                    }
                } catch (err) {
                    Snackbar.showToastNotification({
                        message: t('Inventory:errors:something_went_wrong'),
                        options: {
                            variant: 'error',
                        },
                    });
                }
            });
        },
        [
            inventoryProductService,
            inventoryChangesService,
            userProfileData?.id,
            productService,
            t,
            userOrganisation?.owner,
        ],
    );
}
