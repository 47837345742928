// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    lazy,
    children,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class EntryProduct extends Model {
    static table = 'entry_products';

    static associations = {
        entries: { type: 'belongs_to', key: 'entry_id' },
        products: { type: 'belongs_to', key: 'product_id' },
    };

    @field('entry_id') entryId;
    @field('product_id') productId;
    @field('quantity') quantity;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    product = this.collections
        .get('products')
        .query(Q.where('id', this.productId));
}
