import { Model } from '@nozbe/watermelondb';

export enum EVENT_CONTACT_STATUS {
    AWAITING = 'awaiting',
    REJECTED = 'rejected',
    ACCEPTED = 'accepted',
}

export type EventContactModel = {
    eventId: string;
    contactId: string;
    userId: string;
    organisationId: string;
    sentSMS: boolean;
    sentSMSAt: string;
    sentEmail: boolean;
    sentEmailAt: string;
    status: EVENT_CONTACT_STATUS;
} & Model;

export type EventContactPayload = {
    eventId: string;
    contactId: string;
    userId: string;
    organisationId: string;
    sentSMS: boolean;
    sentEmail: boolean;
    status: EVENT_CONTACT_STATUS;
};
