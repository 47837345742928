import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntryModel } from 'shared/types/Entries';

import { EntriesIcon } from '@/assets/svg';
import { COLOR } from '@/theme/colors';

import EntriesList from '../EntriesList';
import SelectionOutlinedBox from '../SelectionOutlinedBox';

import SelectEntriesModal from './components';
import { Props } from './types';

function SelectEntriesSection({
    onRemoveEntry,
    onSelectEntries,
    selectedEntries,
    CreateEntryModal,
    buttonsPosition = 'bottom',
    testIdPrefix,
    multiselect = true,
    selectCondition,
    conditionWarning,
    disabled,
    entriesToSelect,
    entriesAllowedHorses,
}: Props) {
    const { t } = useTranslation();

    const [isSelectEntriesModalOpen, setIsSelectEntriesModalOpen] =
        useState(false);

    const openSelectEntriesModal = useCallback(() => {
        setIsSelectEntriesModalOpen(true);
    }, []);

    const closeSelectEntriesModal = useCallback(() => {
        setIsSelectEntriesModalOpen(false);
    }, []);

    const [isAddEntryModalOpen, setIsAddEntryModalOpen] =
        useState<boolean>(false);

    const openAddContactModal = useCallback(
        () => setIsAddEntryModalOpen(true),
        [setIsAddEntryModalOpen],
    );

    const closeAddContactModal = useCallback(
        () => setIsAddEntryModalOpen(false),
        [setIsAddEntryModalOpen],
    );

    const [entryToEdit, setEntryToEdit] = useState<EntryModel | null>(null);

    const closeEditEntry = useCallback(() => setEntryToEdit(null), []);

    const addCreatedEntryToSelection = useCallback(
        (entry: EntryModel) => onSelectEntries([...selectedEntries, entry]),
        [onSelectEntries, selectedEntries],
    );

    const onItemClick = useCallback(
        (entry: EntryModel) => setEntryToEdit(entry),
        [],
    );

    return (
        <SelectionOutlinedBox
            onSelect={openSelectEntriesModal}
            onAdd={openAddContactModal}
            disabled={disabled}
            title={t('SelectEntrySection:title')}
            addLabel={t('SelectEntrySection:add')}
            selectLabel={t('SelectEntrySection:select')}
            SelectIcon={EntriesIcon}
            buttonsPosition={buttonsPosition}
            addButtonTestId={`${testIdPrefix}-CreateEntriesButton`}
            selectButtonTestId={`${testIdPrefix}-SelectEntriesButton`}
        >
            {!selectedEntries.length ? (
                <Typography
                    color={COLOR.regentGray}
                    variant="body2"
                    fontSize="small"
                    data-test-id={`${testIdPrefix}-SelectedEntriesEmptyStateText`}
                    sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    {t('SelectEntrySection:blankslate')}
                </Typography>
            ) : (
                <EntriesList
                    testIdPrefix={testIdPrefix}
                    items={selectedEntries}
                    onRemove={onRemoveEntry}
                    removable={!disabled}
                    clickable
                    onItemClick={onItemClick}
                    itemWrapperStyle="border border-gray-200 rounded-xl w-full mb-4"
                    size="large"
                />
            )}
            <SelectEntriesModal
                close={closeSelectEntriesModal}
                isOpen={isSelectEntriesModalOpen}
                onSelectEntries={onSelectEntries}
                selectedEntries={selectedEntries}
                multiSelect={multiselect}
                selectCondition={selectCondition}
                conditionWarning={conditionWarning}
                entriesToSelect={entriesToSelect}
            />
            {isAddEntryModalOpen ? (
                <CreateEntryModal
                    close={closeAddContactModal}
                    onSave={addCreatedEntryToSelection}
                    horsesToSelect={entriesAllowedHorses}
                    closeOnSubmit
                />
            ) : null}
            {entryToEdit ? (
                <CreateEntryModal
                    entry={entryToEdit}
                    isEditMode
                    close={closeEditEntry}
                    horsesToSelect={entriesAllowedHorses}
                />
            ) : null}
        </SelectionOutlinedBox>
    );
}

export default SelectEntriesSection;
