import { Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import {
    Calendar as ReactBigCalendar,
    NavigateAction,
    View,
    momentLocalizer,
} from 'react-big-calendar';
import { CalendarEvent } from 'shared/types/Events';
import moment from 'shared/utils/moment';

import { useCalendarContext } from '@/context/CalendarContext';
import { getLocaleFromLocalStorage } from '@/services/localization';
import { COLOR } from '@/theme/colors';

import AgendaComponent from './components/AgendaComponent/AgendaComponent';
import { Props } from './types';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const mLocalizer = momentLocalizer(moment);

function Calendar({
    date,
    events,
    onNavigate,
    defaultView,
    onViewChange,
    selectable = true,
    onEventClick,
    onSlotClick,
    config,
    slotGroupGetter,
}: Props) {
    const { view } = useCalendarContext();

    const customDayPropGetter = useCallback(() => {
        return {
            style: {
                backgroundColor: COLOR.white,
            },
        };
    }, []);

    const customEventPropGetter = useCallback(
        (event: CalendarEvent) => {
            switch (defaultView) {
                case 'month':
                    return {
                        style: {
                            backgroundColor: event.color,
                            opacity: 0.8,
                            paddingHorizontal: '12px',
                            borderRadius: '8px',
                            marginTop: 2,
                        },
                    };
                case 'day':
                case 'week':
                    return {
                        style: {
                            backgroundColor: event.color,
                            borderRadius: '8px',
                            opacity: 0.8,
                        },
                    };
                case 'agenda':
                default:
                    return {};
            }
        },
        [defaultView],
    );

    const handleOnNavigate = useCallback(
        (newDate: Date, _: View, action: NavigateAction) => {
            let finalDate: Date;

            if (action === 'TODAY' || !newDate) {
                finalDate = new Date(moment().toISOString());
            } else {
                finalDate = newDate;
            }
            onNavigate(finalDate);
        },
        [onNavigate],
    );

    const timeFormat = useMemo(
        () => ({
            timeGutterFormat: (date: Date, culture, localizer) =>
                localizer.format(date, 'h a', culture),
            eventTimeRangeFormat: () => {
                return '';
            },
        }),
        [],
    );

    const now = useMemo(() => moment().minutes(0).toDate(), []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: '100%',
                minHeight: 'inherit',
                height: '100%',
            }}
        >
            <ReactBigCalendar
                dayLayoutAlgorithm={'no-overlap'}
                culture={getLocaleFromLocalStorage() || 'en-au'}
                components={config}
                formats={timeFormat}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={customEventPropGetter}
                slotGroupPropGetter={slotGroupGetter}
                date={date}
                onNavigate={handleOnNavigate}
                defaultView={defaultView}
                onView={onViewChange}
                views={{
                    month: true,
                    week: true,
                    day: true,
                    agenda: AgendaComponent,
                }}
                events={events}
                localizer={mLocalizer}
                onSelectEvent={onEventClick}
                selectable={selectable}
                onSelectSlot={onSlotClick}
                scrollToTime={now}
                style={{
                    flex: 1,
                    minHeight: view === 'month' ? 960 : undefined,
                }}
            />
        </Box>
    );
}

export default Calendar;
