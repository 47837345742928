import { ROLE } from 'shared/types/OrganisationUser';

import { ROUTE } from '@/router/routes';
import { RouteItem } from '@/types/router';

function getSecuredRoutes(routeItems: RouteItem[]): ROUTE[] {
    return routeItems
        .filter((routeItem) => routeItem.secure)
        .map((routeItem) => routeItem.path);
}

function getNonSecuredRoutes(routeItems: RouteItem[]): ROUTE[] {
    return routeItems
        .filter((routeItem) => !routeItem.secure)
        .map((routeItem) => routeItem.path);
}

function getRoleAllowedRoutes(routeItems: RouteItem[], role: ROLE): ROUTE[] {
    return routeItems
        .filter(
            (routeItem) =>
                !routeItem.requiredRoles ||
                routeItem.requiredRoles.length === 0 ||
                routeItem.requiredRoles.some(
                    (requiredRole) => requiredRole === role,
                ),
        )
        .map((routeItem) => routeItem.path);
}

export function isRouteMatch(route: ROUTE, location: string) {
    const splitRoute = route.split('/');
    const splitLocation = location.split('/');

    return splitLocation.every(
        (locationPart, index) =>
            locationPart === splitRoute[index] ||
            (splitRoute[index]?.startsWith(':') && locationPart != null),
    );
}

export { getSecuredRoutes, getNonSecuredRoutes, getRoleAllowedRoutes };
