import {
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { memo } from 'react';

import { EmptyState } from '@/components';
import { COLOR } from '@/theme/colors';

import { ShoppingListsItem } from './components';
import { Props } from './types';

const headerFontStyle: SxProps = {
    color: COLOR.paleSky,
    fontWeight: 700,
    fontSize: 13,
};

function ShoppingListsTable({ emptyStateMessage, rows }: Props) {
    return (
        <TableContainer>
            {!rows.length && emptyStateMessage ? (
                <EmptyState
                    testID={'ShoppingListsTable-EmptyStateMessage'}
                    message={emptyStateMessage}
                />
            ) : null}
            {rows.length ? (
                <Table sx={{ minWidth: 650 }} aria-label="shopping lists table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                                <Typography sx={headerFontStyle}>
                                    {t('ShoppingListsPage:columns:name')}
                                </Typography>
                            </TableCell>

                            <TableCell align="left">
                                <Typography
                                    variant="caption"
                                    sx={headerFontStyle}
                                >
                                    {t(
                                        'ShoppingListsPage:columns:added_to_inventory',
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography
                                    variant="caption"
                                    sx={headerFontStyle}
                                >
                                    {t('ShoppingListsPage:columns:created_by')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <ShoppingListsItem
                                shoppingList={row}
                                key={row.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : null}
        </TableContainer>
    );
}

export default memo(ShoppingListsTable);
