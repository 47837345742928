import axios from 'axios';

import { APP_CONFIG } from '@/constants/appConfig';

import Logger from '../logger';

const FIREBASE_DEEP_LING_GENERATION_ENDPOINT =
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=';

export async function createFirebaseDynamicLink(url: string) {
    const params = {
        dynamicLinkInfo: {
            domainUriPrefix: APP_CONFIG.firebaseDeepLinkPrefix,
            link: url,
            androidInfo: {
                androidPackageName: APP_CONFIG.mobileAppPackageIdentifier,
            },
            iosInfo: {
                iosBundleId: APP_CONFIG.mobileAppPackageIdentifier,
                iosAppStoreId: '1459492568',
            },
            navigationInfo: {
                enableForcedRedirect: true,
            },
        },
    };

    try {
        const res = await axios.post<
            unknown,
            {
                data: {
                    shortLink: string;
                };
            }
        >(
            `${FIREBASE_DEEP_LING_GENERATION_ENDPOINT}${APP_CONFIG.firebaseOptions.apiKey}`,
            params,
        );
        return res.data.shortLink;
    } catch (err) {
        Logger.logError(err);
        return undefined;
    }
}
