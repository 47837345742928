import { Box, Skeleton, useMediaQuery } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useMemo, useState } from 'react';
import { of } from 'rxjs';
import Database from 'shared/db/services/Database.web';
import User from 'shared/db/services/User';
import { InvitationData } from 'shared/types/api/user';

import {
    AlertDialog,
    UserInvitationDeclineModal,
    TeamInvitationModal,
} from '@/components';
import {
    getUserOrganisationId,
    useOrganisationsContext,
} from '@/context/OrganisationsContext';
import { withSyncContext, withUserProfileData } from '@/hoc';
import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { OrganisationsAPI } from '@/services/networking/organisations';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import {
    OrganisationMember,
    OrganisationOtherTeamsListItem,
} from '@/types/organisations';

import {
    CreateTeamInfo,
    AddEditTeamModal,
    Header,
    OtherTeamsSection,
    MembersSection,
    InvitationsSection,
    EditMemberModal,
    UserInvitationsSection,
} from './components';
import { useTeamsPage } from './hook/useTeamsPage';
import { Props } from './types';

const sectionStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    mx: '-25px',
};

const columnItemStyles = {
    mb: 5,
};

function TeamsPage({ userProfile, organisation }: Props) {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showInvitationModal, setShowInvitationModal] = useState(false);
    const [showUserInvitationModal, setShowUserInvitationModal] = useState<{
        show: boolean;
        invitation: InvitationData | null;
    }>({ show: false, invitation: null });

    const [showEditMemberModal, setShowEditMemberModal] = useState(false);
    const [memberToEdit, setMemberToEdit] =
        useState<OrganisationMember | null>();

    const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
    const [deleteMember, setDeleteMember] =
        useState<OrganisationMember | null>();

    const displayTwoColumns = useMediaQuery('(min-width: 1300px)');

    const columnStyles = {
        mx: '25px',
        width: `calc(${displayTwoColumns ? '50%' : '100%'} - 50px)`,
    };

    const {
        fetchMembers,
        fetchUserOrganisationsData,
        members,
        pendingOwnerApprovalInvitations,
        setCurrentOrganisation,
        userOrganisationsAvatarsUrls,
        userOrganisations,
        userOrganisation,
        userInvitationsToAccept,
        userInvitationsToBeApprovedByOwners,
    } = useOrganisationsContext();

    const user = userProfile?.[0];

    const {
        acceptInvitation,
        approveInvitation,
        changeTeam,
        declineInvitation,
        dismissInvitation,
        rejectInvitation,
        removeMember,
        updateMemberRole,
    } = useTeamsPage();

    const isTeamCreated = user?.isTeamCreated;

    const isLoading = user === undefined;

    const teamAvatarUrl = userOrganisation
        ? userOrganisationsAvatarsUrls?.[userOrganisation.id]
        : undefined;

    const isCurrentUserAnOwner = userOrganisation?.owner;

    const otherTeams = useMemo((): OrganisationOtherTeamsListItem[] => {
        const userOrganisationsAlreadyApproved =
            userOrganisations?.reduce(
                (acc: OrganisationOtherTeamsListItem[], organisation) => {
                    if (
                        organisation.id !== userOrganisation?.id &&
                        (organisation.owner ? isTeamCreated : true)
                    ) {
                        acc.push({
                            ...organisation,
                            hasPendingApproval: false,
                        });

                        return acc;
                    } else {
                        return acc;
                    }
                },
                [],
            ) || [];

        return [
            ...userOrganisationsAlreadyApproved,
            ...userInvitationsToBeApprovedByOwners,
        ];
    }, [
        isTeamCreated,
        userOrganisation?.id,
        userInvitationsToBeApprovedByOwners,
        userOrganisations,
    ]);

    const displayDeclineUserInvitationActionModal = useCallback(
        (invitation: InvitationData) => {
            setShowUserInvitationModal({
                show: true,
                invitation,
            });
        },
        [],
    );

    const hideUserInvitationActionModal = useCallback(() => {
        setShowUserInvitationModal({
            show: false,
            invitation: null,
        });
    }, []);

    const onCreateTeam = useCallback(async () => {
        if (!userOrganisation?.owner) {
            try {
                const userOrganisationsData =
                    await OrganisationsAPI.getOrganisations();

                const defaultOrg =
                    userOrganisationsData?.payload.organisations.find(
                        (org) => org.owner,
                    );

                if (defaultOrg) {
                    await setCurrentOrganisation(defaultOrg);
                }
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        }

        setShowCreateModal(true);
    }, [setCurrentOrganisation, userOrganisation]);

    const displayTeamModal = useCallback(() => {
        setShowCreateModal(true);
    }, []);

    const hideTeamModal = useCallback(() => {
        setShowCreateModal(false);
    }, []);

    const displayInvitationModal = useCallback(() => {
        setShowInvitationModal(true);
    }, []);

    const hideInvitationModal = useCallback(() => {
        setShowInvitationModal(false);
    }, []);

    const displayEditMemberModal = useCallback((member: OrganisationMember) => {
        setMemberToEdit(member);
        setShowEditMemberModal(true);
    }, []);

    const hideEditMemberModal = useCallback(() => {
        setShowEditMemberModal(false);
        setMemberToEdit(null);
    }, []);

    const displayDeleteMemberDialog = useCallback(
        (member: OrganisationMember) => {
            setDeleteMember(member);
            setShowDeleteMemberDialog(true);
        },
        [],
    );

    const hideDeleteMemberDialog = useCallback(() => {
        setShowDeleteMemberDialog(false);
    }, []);

    const onEditMember = useCallback(
        async (member: OrganisationMember) => {
            hideEditMemberModal();
            try {
                if (organisation?.id) {
                    await updateMemberRole({
                        memberId: member.id,
                        organisationId: organisation.id,
                        userRole: member.role,
                    });
                }

                await fetchMembers();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [fetchMembers, hideEditMemberModal, organisation?.id, updateMemberRole],
    );

    const onDeleteMember = useCallback(async () => {
        try {
            if (organisation?.id && deleteMember) {
                await removeMember(deleteMember.id, organisation.id);
                await fetchMembers();
            }
        } catch (err) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [deleteMember, fetchMembers, organisation?.id, removeMember]);

    const onApproveInvitation = useCallback(
        async (invitationId: string) => {
            try {
                if (organisation?.id) {
                    await approveInvitation(invitationId);
                    await fetchMembers();
                }
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [approveInvitation, fetchMembers, organisation?.id],
    );

    const onRejectInvitation = useCallback(
        async (invitationId: string) => {
            try {
                if (organisation?.id) {
                    await rejectInvitation(invitationId);
                    await fetchMembers();
                }
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [fetchMembers, organisation?.id, rejectInvitation],
    );

    const onAcceptInvitation = useCallback(
        async (invitationId: string) => {
            try {
                await acceptInvitation(invitationId);
                await fetchUserOrganisationsData();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [acceptInvitation, fetchUserOrganisationsData],
    );

    const onDeclineInvitation = useCallback(
        async (invitationId: string) => {
            try {
                await declineInvitation(invitationId);
                await fetchUserOrganisationsData();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [declineInvitation, fetchUserOrganisationsData],
    );

    const onDismissInvitation = useCallback(
        async (invitationId: string) => {
            try {
                await dismissInvitation(invitationId);
                await fetchUserOrganisationsData();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [dismissInvitation, fetchUserOrganisationsData],
    );

    const handleUserInvitationDeclineModalConfirmAction =
        useCallback(async () => {
            try {
                await onDeclineInvitation(
                    showUserInvitationModal.invitation?.id || '',
                );
                hideUserInvitationActionModal();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        }, [
            hideUserInvitationActionModal,
            onDeclineInvitation,
            showUserInvitationModal.invitation,
        ]);

    const showOtherTeamsSection = !!otherTeams?.length;
    const showTeamInvitationsSection = !!userInvitationsToAccept?.length;
    const showPendingApprovalsSection =
        isCurrentUserAnOwner && pendingOwnerApprovalInvitations?.length;

    return (
        <Box>
            <Header
                isLoading={isLoading}
                isTeamActive={!!isTeamCreated || !isCurrentUserAnOwner}
                isYourTeam={userOrganisation?.owner}
                teamPhotoUrl={teamAvatarUrl}
                teamName={organisation?.name || ''}
                onEditTeam={isCurrentUserAnOwner ? displayTeamModal : undefined}
                onInviteNewMember={
                    isCurrentUserAnOwner ? displayInvitationModal : undefined
                }
            />
            <Box
                sx={{
                    mb: '80px',
                    mt: '65px',
                    mx: 'auto',
                    pl: '50px',
                    pr: '80px',
                    maxWidth: '1300px',
                    width: '100%',
                }}
            >
                {isLoading ? (
                    <Box sx={sectionStyles}>
                        <Box sx={columnStyles}>
                            <Skeleton
                                data-test-id={'TeamsHeader-Skeleton'}
                                height={500}
                            />
                        </Box>
                        <Box sx={columnStyles}>
                            <Skeleton
                                data-test-id={'TeamsHeader-Skeleton'}
                                height={500}
                            />
                        </Box>
                    </Box>
                ) : !isTeamCreated && isCurrentUserAnOwner ? (
                    <Box sx={sectionStyles}>
                        <Box sx={columnStyles}>
                            <Box sx={columnItemStyles}>
                                <CreateTeamInfo onCreateTeam={onCreateTeam} />
                            </Box>
                        </Box>
                        <Box sx={columnStyles}>
                            {otherTeams && otherTeams.length ? (
                                <Box
                                    data-test-id="TeamsPage-OtherTeamsSection"
                                    sx={columnItemStyles}
                                >
                                    <OtherTeamsSection
                                        organisations={otherTeams}
                                        onTeamChange={changeTeam}
                                    />
                                </Box>
                            ) : null}
                            {userInvitationsToAccept &&
                            userInvitationsToAccept.length ? (
                                <Box
                                    data-test-id="TeamsPage-UserInvitationsSection"
                                    sx={columnItemStyles}
                                >
                                    <UserInvitationsSection
                                        invitations={userInvitationsToAccept}
                                        onAccept={onAcceptInvitation}
                                        onDecline={
                                            displayDeclineUserInvitationActionModal
                                        }
                                        onDismiss={onDismissInvitation}
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                ) : (
                    <Box sx={sectionStyles}>
                        {showOtherTeamsSection || showTeamInvitationsSection ? (
                            <Box sx={columnStyles}>
                                {showOtherTeamsSection ? (
                                    <Box
                                        data-test-id="TeamsPage-OtherTeamsSection"
                                        sx={columnItemStyles}
                                    >
                                        <OtherTeamsSection
                                            organisations={otherTeams}
                                            onTeamChange={changeTeam}
                                        />
                                    </Box>
                                ) : null}
                                {showTeamInvitationsSection ? (
                                    <Box
                                        data-test-id="TeamsPage-UserInvitationsSection"
                                        sx={columnItemStyles}
                                    >
                                        <UserInvitationsSection
                                            invitations={
                                                userInvitationsToAccept
                                            }
                                            onAccept={onAcceptInvitation}
                                            onDecline={
                                                displayDeclineUserInvitationActionModal
                                            }
                                            onDismiss={onDismissInvitation}
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                        ) : null}
                        <Box sx={columnStyles}>
                            {showPendingApprovalsSection ? (
                                <Box
                                    data-test-id="TeamsPage-InvitationsSection"
                                    sx={columnItemStyles}
                                >
                                    <InvitationsSection
                                        invitations={
                                            pendingOwnerApprovalInvitations
                                        }
                                        onApprove={onApproveInvitation}
                                        onDelete={onRejectInvitation}
                                    />
                                </Box>
                            ) : null}

                            <Box
                                data-test-id="TeamsPage-MembersSection"
                                sx={columnItemStyles}
                            >
                                <MembersSection
                                    members={members}
                                    onDelete={
                                        isCurrentUserAnOwner
                                            ? displayDeleteMemberDialog
                                            : undefined
                                    }
                                    onEdit={
                                        isCurrentUserAnOwner
                                            ? displayEditMemberModal
                                            : undefined
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            {showCreateModal ? (
                <AddEditTeamModal
                    isEditMode={user?.isTeamCreated}
                    onCancel={hideTeamModal}
                    onSubmit={hideTeamModal}
                    businessName={
                        user?.isTeamCreated
                            ? organisation?.name
                            : userProfile?.[0]?.companyName || undefined
                    }
                />
            ) : null}
            {showInvitationModal ? (
                <TeamInvitationModal handleClose={hideInvitationModal} />
            ) : null}
            {showUserInvitationModal.show ? (
                <UserInvitationDeclineModal
                    onConfirm={handleUserInvitationDeclineModalConfirmAction}
                    onCancel={hideUserInvitationActionModal}
                    teamName={
                        showUserInvitationModal.invitation?.organisation_name ||
                        ''
                    }
                    teamOwner={
                        showUserInvitationModal.invitation?.owner_name || ''
                    }
                />
            ) : null}

            {showEditMemberModal && memberToEdit ? (
                <EditMemberModal
                    member={memberToEdit}
                    onCancel={hideEditMemberModal}
                    onSubmit={onEditMember}
                />
            ) : null}
            {showDeleteMemberDialog ? (
                <AlertDialog
                    close={hideDeleteMemberDialog}
                    testID="DeleteTeamMemberDialog"
                    title={t('TeamsPage:dialog:delete_member_title', {
                        defaultValue: 'Are you sure?',
                    })}
                    message={t('TeamsPage:dialog:delete_member_message', {
                        defaultValue:
                            'Do you want to remove {{name}} from the Team?',
                        name: deleteMember?.name,
                    })}
                    onConfirmButtonClick={onDeleteMember}
                    confirmText={t('Actions:remove')}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress', 'userProfileData'],
    ({ isInitialSyncInProgress, userProfileData }) => {
        const database = Database.getDatabase();

        const userService = new User({
            database,
            imageService: new FirebaseImagesService(),
            logDBAction: Logger.logRecordActivity,
        });

        const organisationId = getUserOrganisationId();

        return {
            userProfile:
                isInitialSyncInProgress || !userProfileData?.id
                    ? of(undefined)
                    : userService.getById(userProfileData.id),
            organisation:
                isInitialSyncInProgress || !organisationId
                    ? of(undefined)
                    : database.collections
                          .get('organisations')
                          .findAndObserve(organisationId),
        };
    },
);

export default withSyncContext<Props>(withUserProfileData(enhance(TeamsPage)));
