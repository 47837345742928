import React from 'react';

import { AccountingProvidersListItem } from './components';
import { Props } from './types';

function AccountingProvidersList({
    disableButtons,
    providers,
    ...props
}: Props) {
    return (
        <>
            {providers.map((provider) => {
                return (
                    <AccountingProvidersListItem
                        disableButton={disableButtons}
                        key={provider.type}
                        provider={provider}
                        {...props}
                    />
                );
            })}
        </>
    );
}

export default AccountingProvidersList;
