import React, { memo } from 'react';

import { useImagesContext } from '@/context/ImagesContext';

import { HorsesListItem } from './components';
import { Props } from './types';

function HorsesList({
    clickable,
    items,
    itemWrapperStyle,
    selectedItems,
    onItemClick,
    onRemove,
    testIdPrefix,
    selectable = false,
    showContacts = false,
    showEntryDate = false,
    removable = false,
    size = 'normal',
    multiSelect = true,
}: Props) {
    const { images, ImagesService } = useImagesContext();

    return (
        <>
            {items.map((horse) => {
                const avatarUrl = ImagesService.getSingleImageByEntityId(
                    images,
                    horse.id,
                )?.imageURL;

                return (
                    <HorsesListItem
                        avatarUrl={avatarUrl}
                        clickable={clickable}
                        testID={`${testIdPrefix}-HorsesListItem`}
                        key={horse.id}
                        horse={horse}
                        onRemove={onRemove}
                        wrapperStyles={itemWrapperStyle}
                        isSelected={selectedItems && selectedItems.has(horse)}
                        onClick={onItemClick}
                        selectable={selectable}
                        showContacts={showContacts}
                        showEntryDate={showEntryDate}
                        removable={removable}
                        size={size}
                        multiSelect={multiSelect}
                    />
                );
            })}
        </>
    );
}

export default memo(HorsesList) as (props: Props) => JSX.Element;
