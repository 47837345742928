export enum SHARE_SHOPPING_LIST_TYPE {
    VIA_BACKEND,
    VIA_CLIENT,
}

export type Props = {
    shoppingListName: string;
    isOpen: boolean;
    onConfirm: (shareOption: SHARE_SHOPPING_LIST_TYPE) => void;
    onClose: VoidFunction;
};
