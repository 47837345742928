import { Collection, Database } from '@nozbe/watermelondb';

import { DBServiceOptions } from '../../types/dbService';
import { UserSettingsModel } from '../../types/UserSettings';

class UserSettings {
    private database: Database;
    private collection: Collection<UserSettingsModel>;
    private table = 'user_settings';
    private options: DBServiceOptions;

    constructor(options: DBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async setIsEnabled(id: string, isEnabled: boolean) {
        const userSettingsElement = await this.getByID(id);

        await this.database.write(async () => {
            await userSettingsElement.update((setting) => {
                setting.enabled = isEnabled;
            });

            this.options.logDBAction({
                message: 'UserSettings enable',
                modelName: this.table,
                payload: userSettingsElement,
            });
        });
    }
}

export default UserSettings;
