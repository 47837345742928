import { useCallback, useEffect, useMemo, useState } from 'react';
import Database from 'shared/db/services/Database.web';
import UserAnswer from 'shared/db/services/UserAnswer';
import { AnswerModel } from 'shared/types/answer';
import { QuestionModel } from 'shared/types/question';

import { useImagesContext } from '@/context/ImagesContext';
import Logger from '@/services/logger';

type UseIntroductionQuestionsParams = {
    close: VoidFunction;
    questions?: QuestionModel[];
};

export function useIntroductionQuestions({
    close,
    questions,
}: UseIntroductionQuestionsParams) {
    const { ImagesService } = useImagesContext();

    const [showQuestions, setShowQuestions] = useState(false);
    const [showFinishAnimation, setShowFinishAnimation] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] =
        useState<QuestionModel | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<AnswerModel[]>([]);
    const [userAnswers, setUserAnswers] = useState<AnswerModel[]>([]);

    const userAnswerService = useMemo(
        () =>
            new UserAnswer({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService],
    );

    const displayQuestions = useCallback(() => setShowQuestions(true), []);

    /**
     * Add selected option to the array of selected options for a specific question
     *
     * Note: This will be reset when we call the nextQuestion
     */
    const selectOption = useCallback(
        (option: AnswerModel) => {
            if (currentQuestion?.isMultiSelect) {
                if (selectedOptions.includes(option)) {
                    const updatedOptions = selectedOptions.filter(
                        (opt) => opt.id !== option.id,
                    );
                    setSelectedOptions(updatedOptions);
                } else {
                    setSelectedOptions([...selectedOptions, option]);
                }
            } else {
                setSelectedOptions([option]);
            }
        },
        [currentQuestion?.isMultiSelect, selectedOptions],
    );

    /**
     * After showing the finish animation we want to close the modal after some seconds
     */
    useEffect(() => {
        if (showFinishAnimation) {
            setTimeout(() => close(), 2000);
        }
    }, [close, showFinishAnimation]);

    /**
     * After last question we will save all the answers and show finish animation
     */
    const showLeaveAnimation = useCallback(async () => {
        setShowFinishAnimation(true);
    }, []);

    /**
     * Here we increment currentQuestionIndex which will trigger the currentQuestion
     * to change into the next item on questions[]
     */
    const nextQuestion = useCallback(() => {
        const length = questions?.length ?? 0;
        const isLastQuestion =
            questions?.length !== 0 && currentQuestionIndex === length - 1;

        if (isLastQuestion) {
            showLeaveAnimation();
        } else {
            setCurrentQuestionIndex((prev) => prev + 1);
            setSelectedOptions([]);
        }
    }, [currentQuestionIndex, showLeaveAnimation, questions?.length]);

    /**
     * Regist user answer to a specific question, then reset selected options and call nextQuestion
     */
    const addUserAnswer = useCallback(
        async (answers: AnswerModel[]) => {
            setUserAnswers([...userAnswers, ...answers]);
            setSelectedOptions([]);

            const answersIds = answers.map((answer) => ({
                answerId: answer.id,
            }));

            if (answersIds.length) {
                await userAnswerService.batchDelete(answersIds);
                await userAnswerService.batchAdd(answersIds);
            }

            nextQuestion();
        },
        [nextQuestion, userAnswers, userAnswerService],
    );

    /**
     * So when the currentQuestionIndex change, this will be executed and if we are in last question
     * Then we will be calling the leaveAnimation flow
     * Otherwise we will update the currentQuestion object to the next item on onboardingQuestions[]
     */
    useEffect(() => {
        setCurrentQuestion(questions?.[currentQuestionIndex] ?? null);
    }, [currentQuestionIndex, questions]);

    return {
        showQuestions,
        currentQuestion,
        currentQuestionIndex,
        selectedOptions,
        showFinishAnimation,
        selectOption,
        addUserAnswer,
        nextQuestion,
        displayQuestions,
    };
}
