import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function DividerSelectSection({
    buttonsPosition,
    onAdd,
    onSelect,
    addLabel,
    selectLabel,
    addButtonTestId,
    selectButtonTestId,
    title,
    children,
    testId,
}: Props) {
    const defaultButtons = (
        <Box data-test-id={testId} sx={{ display: 'flex', gap: 2 }}>
            {onAdd && addLabel ? (
                <Button
                    data-test-id={addButtonTestId}
                    onClick={onAdd}
                    size="small"
                    sx={{ fontWeight: 700 }}
                    variant="text"
                    startIcon={<Add htmlColor={COLOR.deepCerulean} />}
                >
                    {addLabel}
                </Button>
            ) : null}
            <Button
                data-test-id={selectButtonTestId}
                onClick={onSelect}
                size="small"
                sx={{ fontWeight: 700 }}
                variant="outlined"
            >
                {selectLabel}
            </Button>
        </Box>
    );

    return (
        <Box className="border-1 border-t border-t-gray-200 mt-8 pt-8">
            <Box className="flex items-center justify-between mb-4 px-12">
                <Typography
                    color={COLOR.paleSky}
                    fontSize="1.2rem"
                    fontWeight={700}
                >
                    {title}
                </Typography>
                {buttonsPosition === 'top' ? defaultButtons : null}
            </Box>
            <Box className="flex flex-1 justify-between flex-wrap max-h-80 overflow-auto px-12">
                {children}
            </Box>
            {buttonsPosition === 'bottom' ? defaultButtons : null}
        </Box>
    );
}

export default DividerSelectSection;
