import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { ButtonBase, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { Props } from './types';

function MenuItem({
    caption,
    onClick,
    isActive,
    insetLeft,
    arrow = 'right',
    testID,
}: Props) {
    const chevron = useMemo(() => {
        switch (arrow) {
            case 'up':
                return <ExpandLess />;

            case 'down':
                return <ExpandMore />;

            case 'right':
            default:
                return isActive ? (
                    <ChevronRight className={'text-deepCerulean'} />
                ) : null;
        }
    }, [arrow, isActive]);

    return (
        <ButtonBase
            data-test-id={testID}
            className={`${insetLeft ? 'ml-3' : ''}`}
            onClick={onClick}
            disableRipple
        >
            <Typography
                className={`${
                    isActive
                        ? 'text-deepCerulean'
                        : 'text-slate-500  hover:text-slate-500/50'
                }`}
            >
                {caption}
            </Typography>
            {chevron}
        </ButtonBase>
    );
}
export default MenuItem;
