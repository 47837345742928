// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class ShoppingList extends Model {
    static table = 'shopping_lists';

    static associations = {
        shopping_list_products: {
            type: 'has_many',
            foreignKey: 'shopping_list_id',
        },
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
    };

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('name') name;
    @field('added_to_inventory_time') addedToInventoryTime;
    @field('sent_to_dealer_time') sentToDealerTime;
    @field('requested_sending_to_dealer_time') requestedSendingToDealerTime;
    @field('dealer_id') dealerId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    shoppingListProducts = this.collections
        .get('shopping_list_products')
        .query(Q.where('shopping_list_id', this.id));
}
