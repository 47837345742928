import { Box, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectableListItem } from '@/components';
import { useRegion } from '@/hooks/useRegion';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SelectableProceduresListItem({
    editable,
    procedure,
    selectable,
    isSelected,
    onClick,
    onEditButtonClick,
    onRemove,
    clickable,
    wrapperStyles,
    testID,
    removable,
    backgroundColor,
    itemHeight,
    showQuantity = true,
    showTotalPrice,
    showPrice = true,
}: Props) {
    const { t } = useTranslation();

    const userRegion = useRegion();
    const currency = userRegion?.currencySymbol || '$';

    const procedureTotalPrice = useMemo(() => {
        return procedure.price
            ? (Number(procedure.price) * procedure.quantity).toFixed(2)
            : '';
    }, [procedure.price, procedure.quantity]);

    const procedureUnitPrice = useMemo(() => {
        return procedure.price ? Number(procedure.price).toFixed(2) : '';
    }, [procedure.price]);

    const renderProcedurePrice = useCallback(
        () => (
            <Typography
                color={COLOR.paleSky}
                variant="caption"
                sx={{ pr: 2 }}
                data-test-id={`${testID}-Price`}
            >
                {showTotalPrice ? (
                    <>
                        <Tooltip
                            title={`Unit price: ${currency} ${procedureUnitPrice}`}
                        >
                            <Typography
                                className="underline"
                                fontWeight={700}
                                sx={{ mr: 1 }}
                                variant="caption"
                            >
                                {t('Generic:total')}:
                            </Typography>
                        </Tooltip>{' '}
                    </>
                ) : (
                    ''
                )}
                {`${currency} ${
                    showTotalPrice ? procedureTotalPrice : procedureUnitPrice
                }`}
            </Typography>
        ),
        [
            currency,
            procedureTotalPrice,
            procedureUnitPrice,
            showTotalPrice,
            testID,
            t,
        ],
    );

    return (
        <SelectableListItem
            hideAvatar
            editable={editable}
            clickable={clickable}
            isSelected={isSelected}
            item={procedure}
            onEditButtonClick={onEditButtonClick}
            onClick={onClick}
            onRemove={onRemove}
            removable={removable}
            renderExtraInfo={showPrice ? renderProcedurePrice : undefined}
            selectable={selectable}
            showExtraInfo={showPrice}
            avatarSize="normal"
            testID={`${testID}-${procedure.name}`}
            wrapperStyles={wrapperStyles}
            backgroundColor={backgroundColor}
            itemHeight={itemHeight}
        >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pl: 2 }}>
                {showQuantity ? (
                    <Typography
                        color={COLOR.paleSky}
                        data-test-id={`${testID}-Quantity`}
                        variant="body2"
                    >
                        {`${procedure.quantity} x`}
                    </Typography>
                ) : null}
                <Typography
                    flex={1}
                    fontWeight={700}
                    noWrap
                    data-test-id={`${testID}-Name`}
                >
                    {procedure.name}
                </Typography>
            </Box>
        </SelectableListItem>
    );
}

export default SelectableProceduresListItem;
