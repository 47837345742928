// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { EntryProcedure } from 'shared/types/EntryProcedure';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Entry extends Model {
    static table = 'entries';

    static associations = {
        horses: { type: 'belongs_to', key: 'horse_id' },
        events: { type: 'belongs_to', key: 'event_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        invoices: { type: 'belongs_to', key: 'invoice_id' },
        entry_products: { type: 'has_many', foreignKey: 'entry_id' },
        entry_procedures: { type: 'has_many', foreignKey: 'entry_id' },
        entry_users: { type: 'has_many', foreignKey: 'entry_id' },
    };

    @field('logged_time') loggedTime;
    @field('title') title;
    @field('notes') notes;
    @field('private_notes') privateNotes;
    @field('event_id') eventID;
    @field('horse_id') horseId;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('invoice_id') invoiceId;

    @relation('horses', 'horse_id') horse;
    @relation('events', 'event_id') event;
    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('invoices', 'invoice_id') invoice;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    entryProducts = this.collections
        .get('entry_products')
        .query(Q.where('entry_id', this.id));

    @lazy
    entryProcedures = this.collections
        .get<EntryProcedure>('entry_procedures')
        .query(Q.where('entry_id', this.id));

    @lazy
    entryUsers = this.collections
        .get('users')
        .query(Q.on('entry_users', 'entry_id', this.id));
}
