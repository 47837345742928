import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

const historyItemStyle = {
    mb: 1,
};

const historyItemText = {
    fontSize: 14,
    fontWeight: 400,
    color: COLOR.black,
};
const historyItemDescription = {
    fontSize: 13,
    fontWeight: 300,
    color: COLOR.paleSky,
};

function InvoiceHistoryItem({ text, description, textColor }: Props) {
    const color = useMemo(() => {
        if (!textColor) {
            return COLOR.black;
        }
        return textColor;
    }, [textColor]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...historyItemStyle,
            }}
        >
            <Typography color={color} sx={historyItemText}>
                {text}
            </Typography>
            <Typography sx={historyItemDescription}>{description}</Typography>
        </Box>
    );
}

export default InvoiceHistoryItem;
