import { ProductType } from '../../types/Products';

export const ProductTypesTranslationKeys = {
    [ProductType.shoes]: 'Inventory:section:shoes',
    [ProductType.nails]: 'Inventory:section:nails',
    [ProductType.rasps]: 'Inventory:section:rasps',
    [ProductType.tools]: 'Inventory:section:tools',
    [ProductType.other]: 'Inventory:section:other',
    [ProductType.custom]: 'Inventory:section:custom_products',
};
