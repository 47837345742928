import { Model } from '@nozbe/watermelondb';

export enum ROLE {
    admin = 'admin',
    member = 'member',
}

export type OrganisationUserModel = {
    role: ROLE;
    active: boolean;
    createdAt?: Date;
    organisationId: string;
    organisationUserColorId: string;
    userId: string;
    updatedAt?: Date;
} & Model;
