// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    children,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Horse extends Model {
    static table = 'horses';

    static associations = {
        entries: { type: 'has_many', foreignKey: 'horse_id' },
        event_horses: { type: 'has_many', foreignKey: 'horse_id' },
        horse_contacts: { type: 'has_many', foreignKey: 'horse_id' },
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        locations: { type: 'belongs_to', key: 'location_id' },
    };

    @lazy
    contacts = this.collections
        .get('contacts')
        .query(Q.on('horse_contacts', 'horse_id', this.id));

    @lazy
    owners = this.collections
        .get('contacts')
        .query(
            Q.on('horse_contacts', 'horse_id', this.id),
            Q.where('role', 'owner'),
        );

    @lazy
    entries = this.collections
        .get('entries')
        .query(Q.where('horse_id', this.id));

    @lazy
    events = this.collections
        .get('events')
        .query(Q.on('event_horses', 'horse_id', this.id));

    @field('name') name;
    @field('foal_date') foalDate;
    @field('gender') gender;
    @field('shoe_size') shoeSize;
    @field('shoe_type') shoeType;
    @field('breed') breed;
    @field('work_type') workType;
    @field('comments') comments;
    @field('hidden') hidden;
    @field('stable_name') stableName;
    @field('stable_address') stableAddress;
    @field('stable_phone') stablePhone;
    @field('stable_phone_prefix') stablePhonePrefix;
    @field('microchip_no') microchipNo;
    @field('sire_name') sireName;
    @field('dam_name') damName;

    @field('shoeing_cycle_weeks') shoeingCycle;
    @field('shoeing_cycle_notify') shoeingCycleNotify;
    @field('shoeing_cycle_badge') shoeingCycleBadge;
    @field('location_id') locationID;
    @field('user_id') userId;
    @field('organisation_id') organisationId;

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('locations', 'location_id') location;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @children('entries') entries;
}
