// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class EventContact extends Model {
    static table = 'event_contacts';

    static associations = {
        events: { type: 'belongs_to', key: 'event_id' },
        contacts: { type: 'belongs_to', key: 'contact_id' },
    };

    @field('event_id') eventId;
    @field('contact_id') contactId;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('sent_sms') sentSMS;
    @readonly @field('sent_sms_at') sentSMSAt;
    @field('sent_email') sentEmail;
    @field('status') status;
    @readonly @field('sent_email_at') sentEmailAt;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @field('sent_sms') sentSMS;
    @readonly @field('sent_sms_at') sentSMSAt;
    @field('sent_email') sentEmail;
    @readonly @field('sent_email_at') sentEmailAt;
}
