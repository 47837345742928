import React, { createContext, useCallback, useContext, useState, createRef } from 'react';

import { Context } from '@/types/context';

const ShoppingListFiltersContext = createContext<Context.ShoppingListFilters.Value>({
    clearContextState: () => null,
    searchState: '',
    setSearchState: () => null,
});

const shoppingListFiltersContextRef: React.MutableRefObject<Context.ShoppingListFilters.Value | null> = createRef();

const ShoppingListFiltersProvider = ({ children }: Context.ShoppingListFilters.Props) => {
    const [searchState, setSearchState] = useState('');

    const clearContextState = useCallback(() => {
        setSearchState('');
    }, []);

    const contextValue: Context.ShoppingListFilters.Value = {
        clearContextState,
        searchState,
        setSearchState,
    };

    shoppingListFiltersContextRef.current = contextValue;

    return (
        <ShoppingListFiltersContext.Provider value={contextValue}>
            {children}
        </ShoppingListFiltersContext.Provider>
    );
};

function useShoppingListFiltersContext() {
    const shoppingListContext = useContext(ShoppingListFiltersContext);
    return shoppingListContext;
}

function clearShoppingListFiltersContextState() {
    return shoppingListFiltersContextRef.current?.clearContextState();
}

export {
    ShoppingListFiltersContext,
    ShoppingListFiltersProvider,
    useShoppingListFiltersContext,
    clearShoppingListFiltersContextState,
};