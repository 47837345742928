export enum ENTRIES_FILTER {
    horse_assigned = 'horse_assigned',
    invoicing = 'invoicing',
    date_range = 'date_range',
    contacts = 'contacts',
    job_type = 'job_type',
}

export enum ENTRIES_SHOW_ENTRIES_FILTER {
    with_horse_assigned = 'with_horse_assigned',
    without_horse_assigned = 'without_horse_assigned',
}

export enum ENTRIES_INVOICING_FILTER {
    to_invoice = 'to_invoice',
    draft_invoice = 'draft_invoice',
    invoiced = 'invoiced',
}

export const ENTRIES_SHOW_ENTRIES_FILTERS_DICTIONARY: Record<
    ENTRIES_SHOW_ENTRIES_FILTER,
    string
> = {
    [ENTRIES_SHOW_ENTRIES_FILTER.with_horse_assigned]:
        'EntriesList:filters:show_entries:with_horse_assigned',
    [ENTRIES_SHOW_ENTRIES_FILTER.without_horse_assigned]:
        'EntriesList:filters:show_entries:without_horse_assigned',
};

export const ENTRIES_INVOICING_FILTERS_DICTIONARY: Record<
    ENTRIES_INVOICING_FILTER,
    string
> = {
    [ENTRIES_INVOICING_FILTER.to_invoice]:
        'EntriesList:filters:invoicing:to_invoice',
    [ENTRIES_INVOICING_FILTER.draft_invoice]:
        'EntriesList:filters:invoicing:draft_invoice',
    [ENTRIES_INVOICING_FILTER.invoiced]:
        'EntriesList:filters:invoicing:invoiced',
};
