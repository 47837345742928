import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function CartIcon({ color = COLOR.paleSky, height = 25, width = 25 }: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <g id="Shapes">
                <path
                    fill={color}
                    d="M11.2 34.7c-1 0-1.9.8-1.9 1.8s.8 1.8 1.9 1.8 1.9-.8 1.9-1.8-.8-1.8-1.9-1.8zM33.3 34.7c-1 0-1.9.8-1.9 1.8s.8 1.8 1.9 1.8 1.9-.8 1.9-1.8-.9-1.8-1.9-1.8zM34.3 31.8H11.9l3.8-5.7h19.9c.8 0 1.4-.6 1.4-1.4l2.2-12.8c0-.8-.6-1.4-1.4-1.4H10.7L9.6 5c-.1-.5-.5-.8-1-.8H4.4c-.5 0-1 .5-1 1s.4 1 1 1h3.4L10 17.5v.1l1.2 7c0 .8.6 1.4 1.4 1.4h.8l-4.1 6.2c-.2.3-.2.7 0 1 .2.3.5.5.9.5h24.2c.5 0 1-.5 1-1s-.5-1-1.1-.9z"
                />
            </g>
        </svg>
    );
}

export default memo(CartIcon);
