/* eslint-disable @typescript-eslint/quotes */
import {
    schemaMigrations,
    createTable,
    addColumns,
    //@ts-ignore
    unsafeExecuteSql,
} from '@nozbe/watermelondb/Schema/migrations';

export default schemaMigrations({
    migrations: [
        {
            toVersion: 171,
            steps: [
                addColumns({
                    table: 'organisation_accountings',
                    columns: [
                        {
                            name: 'user_email',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'user_provider_email',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'provider_archived_at',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'onboardings',
                    columns: [
                        {
                            name: 'overlay_archived_invoices',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 170,
            steps: [
                addColumns({
                    table: 'invoice_invoicing_products',
                    columns: [
                        { name: 'name', type: 'string', isOptional: true },
                        { name: 'price', type: 'string', isOptional: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 169,
            steps: [
                addColumns({
                    table: 'organisations',
                    columns: [
                        {
                            name: 'is_alternative_phone_enabled',
                            type: 'boolean',
                        },
                        {
                            name: 'is_alternative_email_enabled',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 168,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'last_synced_at',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 167,
            steps: [
                addColumns({
                    table: 'onboardings',
                    columns: [
                        {
                            name: 'are_procedures_onboarded',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'are_entries_onboarded',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'are_invoices_onboarded',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'are_business_details_onboarded',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 166,
            steps: [
                addColumns({
                    table: 'organisations',
                    columns: [
                        {
                            name: 'products_tax_rate',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'invoices',
                    columns: [
                        { name: 'tax_rate', type: 'string', isOptional: true },
                        {
                            name: 'products_tax_rate',
                            type: 'string',
                            isOptional: true,
                        },
                        { name: 'provider', type: 'string', isOptional: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 165,
            steps: [
                createTable({
                    name: 'invoicing_products',
                    columns: [
                        { name: 'name', type: 'string' },
                        { name: 'price', type: 'string' },
                        { name: 'available', type: 'boolean' },
                        { name: 'user_id', type: 'string' },
                        { name: 'organisation_id', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'invoice_invoicing_products',
                    columns: [
                        { name: 'user_id', type: 'string' },
                        { name: 'organisation_id', type: 'string' },
                        { name: 'invoice_id', type: 'string' },
                        { name: 'invoicing_product_id', type: 'string' },
                        { name: 'quantity', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 164,
            steps: [
                addColumns({
                    table: 'onboardings',
                    columns: [
                        {
                            name: 'getting_started_section_dismissed',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 163,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'invoice_date',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 162,
            steps: [
                addColumns({
                    table: 'organisations',
                    columns: [
                        {
                            name: 'payment_instructions',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'email',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'alternative_address',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'alternative_email',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'alternative_phone_number',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'alternative_phone_prefix',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'footer_message',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'tax_rate',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'business_number',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'footer_text',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 161,
            steps: [
                addColumns({
                    table: 'onboardings',
                    columns: [
                        {
                            name: 'created_at',
                            type: 'number',
                        },
                        {
                            name: 'updated_at',
                            type: 'number',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 160,
            steps: [
                createTable({
                    name: 'onboardings',
                    columns: [
                        {
                            name: 'overlay_rsvp_one',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'overlay_rsvp_two',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'overlay_rsvp_three',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'overlay_rsvp_four',
                            type: 'boolean',
                            isOptional: true,
                        },
                        {
                            name: 'user_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 159,
            steps: [
                addColumns({
                    table: 'event_contacts',
                    columns: [
                        {
                            name: 'status',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 158,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        { name: 'is_plus_button_onboarded', type: 'boolean' },
                    ],
                }),
            ],
        },
        {
            toVersion: 157,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'ignore',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 156,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'terms_accepted_at',
                            type: 'number',
                        },
                        {
                            name: 'terms_created_at',
                            type: 'number',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 155,
            steps: [
                addColumns({
                    table: 'events',
                    columns: [
                        { name: 'is_recurring', type: 'boolean' },
                        {
                            name: 'recurrence_pattern',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'recurrence_end_date',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'original_event_id',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 154,
            steps: [
                addColumns({
                    table: 'horses',
                    columns: [
                        {
                            name: 'microchip_no',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'sire_name',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'dam_name',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 153,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'is_entry_creation_from_event_enabled',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 152,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'is_appointment_contact_selection_onboarded',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 151,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [{ name: 'is_contacts_setup', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 150,
            steps: [
                addColumns({
                    table: 'event_contacts',
                    columns: [
                        {
                            name: 'sent_sms',
                            type: 'boolean',
                        },
                        {
                            name: 'sent_sms_at',
                            type: 'string',
                        },
                        {
                            name: 'sent_email',
                            type: 'boolean',
                        },
                        {
                            name: 'sent_email_at',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 149,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        { name: 'is_contacts_setup_set', type: 'string' },
                    ],
                }),
            ],
        },
        {
            toVersion: 148,
            steps: [
                addColumns({
                    table: 'events',
                    columns: [
                        {
                            name: 'send_invites_to_contacts_at',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 147,
            steps: [
                addColumns({
                    table: 'questions',
                    columns: [
                        {
                            name: 'mandatory',
                            type: 'boolean',
                        },
                        {
                            name: 'question_order',
                            type: 'number',
                        },
                        {
                            name: 'question_version',
                            type: 'number',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 146,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'is_appointment_onboarded',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 145,
            steps: [
                addColumns({
                    table: 'events',
                    columns: [
                        {
                            name: 'category',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 144,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'timezone',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'regions',
                    columns: [
                        {
                            name: 'default_timezone',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 143,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'is_procedures_account_setup_set',
                            type: 'boolean',
                        },
                        {
                            name: 'is_invoicing_account_setup_set',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 142,
            steps: [
                addColumns({
                    table: 'products',
                    columns: [
                        {
                            name: 'product_size',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 141,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'is_team_created',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 140,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'freshchat_restore_id',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 139,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'company_name',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 138,
            steps: [
                addColumns({
                    table: 'regions',
                    columns: [
                        { name: 'currency_name', type: 'string' },
                        { name: 'currency_symbol', type: 'string' },
                        { name: 'proposed_language', type: 'string' },
                    ],
                }),
            ],
        },
        {
            toVersion: 137,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'menu_order',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 136,
            steps: [
                unsafeExecuteSql(
                    `CREATE INDEX IF NOT EXISTS "organisation_users_organisation_user_color_id" ON "organisation_users" ("organisation_user_color_id");`,
                ),
            ],
        },
        {
            toVersion: 135,
            steps: [
                addColumns({
                    table: 'products',
                    columns: [
                        {
                            name: 'not_available',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 134,
            steps: [
                addColumns({
                    table: 'organisation_users',
                    columns: [
                        {
                            name: 'role',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 133,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [
                        {
                            name: 'phone_prefix',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'organisations',
                    columns: [
                        {
                            name: 'phone_prefix',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'phone_prefix',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'horses',
                    columns: [
                        {
                            name: 'stable_phone_prefix',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 132,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [
                        {
                            name: 'synced',
                            type: 'boolean',
                        },
                    ],
                }),
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'synced',
                            type: 'boolean',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 131,
            steps: [
                addColumns({
                    table: 'events',
                    columns: [
                        { name: 'timezone', type: 'string', isOptional: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 130,
            steps: [
                createTable({
                    name: 'organisation_accountings',
                    columns: [
                        {
                            name: 'sales_account_code',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'accounting_provider',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'accounting_disconnect_message',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'lock_accounting_provider',
                            type: 'boolean',
                        },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 129,
            steps: [
                createTable({
                    name: 'questions',
                    columns: [
                        { name: 'content', type: 'string' },
                        { name: 'description', type: 'string' },
                        { name: 'extra_action', type: 'string' },
                        { name: 'is_multiselect', type: 'boolean' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'answers',
                    columns: [
                        { name: 'content', type: 'string' },
                        { name: 'question_id', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'user_answers',
                    columns: [
                        { name: 'user_id', type: 'string' },
                        { name: 'answer_id', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 128,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'amount_check',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'amount_valid',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 127,
            steps: [
                createTable({
                    name: 'event_users',
                    columns: [
                        {
                            name: 'event_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'user_id', type: 'string', isIndexed: true },
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'client_created_at', type: 'number' },
                        { name: 'client_updated_at', type: 'number' },
                        { name: 'archived_at', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 126,
            steps: [
                createTable({
                    name: 'entry_users',
                    columns: [
                        {
                            name: 'entry_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'user_id', type: 'string', isIndexed: true },
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'client_created_at', type: 'number' },
                        { name: 'client_updated_at', type: 'number' },
                        { name: 'archived_at', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 125,
            steps: [
                createTable({
                    name: 'organisation_user_colors',
                    columns: [
                        { name: 'code', type: 'string' },
                        { name: 'position', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                addColumns({
                    table: 'organisation_users',
                    columns: [
                        {
                            name: 'organisation_user_color_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'active', type: 'boolean', isIndexed: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 124,
            steps: [
                addColumns({
                    table: 'inventory_changes',
                    columns: [{ name: 'process_at', type: 'number' }],
                }),
            ],
        },
        {
            toVersion: 123,
            steps: [
                createTable({
                    name: 'inventory_changes',
                    columns: [
                        {
                            name: 'inventory_product_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'user_id', type: 'string', isIndexed: true },
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'quantity_change', type: 'number' },
                        { name: 'client_created_at', type: 'number' },
                        { name: 'client_updated_at', type: 'number' },
                        { name: 'archived_at', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 122,
            steps: [
                createTable({
                    name: 'organisations',
                    columns: [
                        { name: 'name', type: 'string' },
                        { name: 'address', type: 'string', isOptional: true },
                        {
                            name: 'phone_number',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'location_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'organisation_users',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                addColumns({
                    table: 'entries',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'entry_products',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'events',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'event_horses',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'event_contacts',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'event_contact_reminders',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'horses',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'inventory_products',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'contacts',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'products',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'horse_contacts',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'shopping_lists',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'shopping_list_products',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'locations',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'procedures',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'entry_procedures',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'organisation_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 121,
            steps: [
                addColumns({
                    table: 'horses',
                    columns: [{ name: 'hidden', type: 'boolean' }],
                }),
            ],
        },
        {
            toVersion: 120,
            steps: [
                addColumns({
                    table: 'entry_procedures',
                    columns: [{ name: 'quantity', type: 'number' }],
                    // @ts-ignore
                    unsafeSql: (sql) =>
                        sql.replace(`"quantity" = 0`, `"quantity" = 1`),
                }),
            ],
        },
        {
            toVersion: 119,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [
                        {
                            name: 'business_name',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 118,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [{ name: 'sales_account_code', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 117,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        { name: 'lock_accounting_provider', type: 'boolean' },
                    ],
                }),
            ],
        },
        {
            toVersion: 116,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [{ name: 'public_url', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 115,
            steps: [
                createTable({
                    name: 'device_calendar_events',
                    columns: [
                        { name: 'device_id', type: 'string', isIndexed: true },
                        {
                            name: 'calendar_event_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'event_id', type: 'string', isIndexed: true },
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 114,
            steps: [
                addColumns({
                    table: 'shopping_list_products',
                    columns: [
                        {
                            name: 'product_sales_unit_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 113,
            steps: [
                createTable({
                    name: 'regions',
                    columns: [
                        { name: 'code', type: 'string' },
                        { name: 'name', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'product_sales_units',
                    columns: [
                        { name: 'box_unit_size', type: 'number' },
                        { name: 'sku_apac', type: 'string' },
                        { name: 'sku_emea', type: 'string' },
                        { name: 'product_id', type: 'string', isIndexed: true },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'product_sales_unit_regions',
                    columns: [
                        {
                            name: 'product_sales_unit_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'region_id', type: 'string', isIndexed: true },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 112,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        { name: 'trace_id', type: 'string', isOptional: true },
                        {
                            name: 'profiling',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 111,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [{ name: 'region', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 110,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'accounting_disconnect_message',
                            type: 'string',
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 109,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [
                        { name: 'sent_to_contact_time', type: 'string' },
                        { name: 'payment_date_time', type: 'string' },
                    ],
                }),
            ],
        },
        {
            toVersion: 108,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [{ name: 'authorised_time', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 107,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [{ name: 'attach_reports', type: 'boolean' }],
                }),
            ],
        },
        {
            toVersion: 106,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        { name: 'address', type: 'string', isOptional: true },
                        {
                            name: 'location_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 105,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [{ name: 'deep_link', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 104,
            steps: [
                addColumns({
                    table: 'entry_procedures',
                    columns: [{ name: 'name', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 103,
            steps: [],
        },
        {
            toVersion: 102,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [{ name: 'hidden', type: 'boolean' }],
                }),
            ],
        },
        {
            toVersion: 101,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [
                        {
                            name: 'apply_taxes',
                            type: 'boolean',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 100,
            steps: [
                addColumns({
                    table: 'invoices',
                    columns: [{ name: 'reference', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 99,
            steps: [
                createTable({
                    name: 'invoices',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'contact_id', type: 'string', isIndexed: true },
                        { name: 'external_id', type: 'string' },
                        { name: 'number', type: 'string' },
                        { name: 'status', type: 'string' },
                        { name: 'sub_total', type: 'string' },
                        { name: 'total_tax', type: 'string' },
                        { name: 'total', type: 'string' },
                        { name: 'total_discount', type: 'string' },
                        { name: 'sent_to_contact', type: 'boolean' },
                        { name: 'invoiced_time', type: 'string' },
                        { name: 'due_date_time', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                addColumns({
                    table: 'entries',
                    columns: [
                        { name: 'invoice_id', type: 'string', isIndexed: true },
                    ],
                }),
            ],
        },
        {
            toVersion: 98,
            steps: [
                addColumns({
                    table: 'users',
                    columns: [
                        {
                            name: 'accounting_provider',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 97,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [{ name: 'billable', type: 'boolean' }],
                }),
            ],
        },
        {
            toVersion: 96,
            steps: [],
        },
        {
            toVersion: 95,
            steps: [
                createTable({
                    name: 'procedures',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'category', type: 'string' },
                        { name: 'name', type: 'string' },
                        {
                            name: 'description',
                            type: 'string',
                            isOptional: true,
                        },
                        { name: 'price', type: 'string' },
                        { name: 'available', type: 'boolean' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'entry_procedures',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'entry_id', type: 'string', isIndexed: true },
                        {
                            name: 'procedure_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'price', type: 'string' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 94,
            steps: [
                addColumns({
                    table: 'user_settings',
                    columns: [{ name: 'topic', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 93,
            steps: [
                addColumns({
                    table: 'user_settings',
                    columns: [{ name: 'description', type: 'string' }],
                }),
            ],
        },
        {
            toVersion: 92,
            steps: [
                createTable({
                    name: 'user_settings',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'static_name', type: 'string' },
                        { name: 'enabled', type: 'boolean' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 91,
            steps: [
                addColumns({
                    table: 'shopping_lists',
                    columns: [
                        {
                            name: 'requested_sending_to_dealer_time',
                            type: 'string',
                            isOptional: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 90,
            steps: [
                addColumns({
                    table: 'products',
                    columns: [{ name: 'box_unit_size', type: 'number' }],
                }),
            ],
        },
        {
            toVersion: 89,
            steps: [
                createTable({
                    name: 'shopping_lists',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'name', type: 'string' },
                        {
                            name: 'added_to_inventory_time',
                            type: 'string',
                            isOptional: true,
                        },
                        {
                            name: 'sent_to_dealer_time',
                            type: 'string',
                            isOptional: true,
                        },
                        { name: 'dealer_id', type: 'string', isOptional: true },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                createTable({
                    name: 'shopping_list_products',
                    columns: [
                        { name: 'user_id', type: 'string', isIndexed: true },
                        {
                            name: 'shopping_list_id',
                            type: 'string',
                            isIndexed: true,
                        },
                        { name: 'product_id', type: 'string', isIndexed: true },
                        { name: 'unit_type', type: 'string' },
                        { name: 'quantity', type: 'number' },
                        { name: 'total_quantity', type: 'number' },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
            ],
        },
        {
            toVersion: 88,
            steps: [
                addColumns({
                    table: 'contacts',
                    columns: [
                        {
                            name: 'location_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'horses',
                    columns: [
                        {
                            name: 'location_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
                addColumns({
                    table: 'events',
                    columns: [
                        {
                            name: 'location_id',
                            type: 'string',
                            isIndexed: true,
                        },
                    ],
                }),
            ],
        },
        {
            toVersion: 87,
            steps: [
                createTable({
                    name: 'locations',
                    columns: [
                        { name: 'place_id', type: 'string' },
                        { name: 'name', type: 'string' },
                        { name: 'lat', type: 'number' },
                        { name: 'lng', type: 'number' },
                        { name: 'url', type: 'string' },
                        { name: 'utc_offset', type: 'number' },
                        { name: 'user_id', type: 'string', isIndexed: true },
                        { name: 'created_at', type: 'number' },
                        { name: 'updated_at', type: 'number' },
                    ],
                }),
                addColumns({
                    table: 'events',
                    columns: [
                        { name: 'address', type: 'string', isOptional: true },
                    ],
                }),
            ],
        },
    ],
});
