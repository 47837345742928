import { Avatar, Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { getInitials } from 'shared/utils/string';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

export function InitialsAvatar({
    avatarUrl,
    firstName,
    lastName,
    onClick,
    size = 72,
    sx = {},
    testID,
    children,
}: Props) {
    const initials = useMemo(() => {
        return getInitials(`${firstName.replace(/ /g, '')} ${lastName}`, 2);
    }, [firstName, lastName]);

    const handleAvatarClick = useCallback(() => {
        if (onClick && avatarUrl) {
            onClick(avatarUrl);
        }
    }, [avatarUrl, onClick]);

    const showCursorPointer = avatarUrl && onClick;

    return (
        <Box onClick={handleAvatarClick}>
            <Avatar
                data-test-id={testID}
                src={avatarUrl}
                sx={{
                    cursor: showCursorPointer ? 'pointer' : 'inherit',
                    width: size,
                    height: size,
                    backgroundColor: COLOR.alto,
                    color: COLOR.osloGray,
                    fontSize: 28,
                    fontWeight: 700,
                    ...sx,
                }}
                variant="circular"
            >
                {initials || children}
            </Avatar>
        </Box>
    );
}
