export const DB_TABLES_LISTENER = [
    'contacts',
    'horses',
    'events',
    'event_contacts',
    'inventory_products',
    'entries',
    'shopping_lists',
    'user_settings',
    'users',
    'procedures',
    'locations',
    'invoices',
    'device_calendar_events',
    'inventory_changes',
    'organisations',
    'organisation_users',
    'organisation_user_colors',
    'organisation_accountings',
    'shopping_list_products',
    'user_answers',
    'onboardings',
];
