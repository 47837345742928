import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Button,
    Skeleton,
    SxProps,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingListsAnalytics } from 'shared/analytics/shoppingLists';
import Database from 'shared/db/services/Database.web';
import ShoppingList from 'shared/db/services/ShoppingList';
import User from 'shared/db/services/User';
import { ContactModel } from 'shared/types/Contacts';
import { FirebaseShoppingList } from 'shared/types/firebase/types';
import { ShoppingProductParserParams } from 'shared/types/ShoppingList';
import { UserModel } from 'shared/types/User';
import moment from 'shared/utils/moment';
import {
    generateProductCSV,
    shoppingProductParser,
} from 'shared/utils/shoppingList';

import { InventoryIcon } from '@/assets/svg';
import { SelectContactsModal } from '@/components/SelectContactsSection/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { mapUsersWithColors } from '@/services/user';
import { UserWithColor } from '@/services/user/types';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import { ShareWithDealerAlert } from './components';
import { SHARE_SHOPPING_LIST_TYPE } from './components/ShareWithDealerAlert/types';
import { Props } from './types';

const defaultTextStyle: SxProps<Theme> = {
    fontSize: 13,
    fontWeight: 700,
};

const infoLabelsStyle = {
    display: 'flex',
    gap: 1,
    p: 1,
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 300,
    color: COLOR.paleSky,
};

const database = Database.getDatabase();

function HeaderSection({
    shoppingList,
    isLoading,
    products,
    showInventory,
    setShowInventory,
    onAddToInventoryClick,
}: Props) {
    const { t } = useTranslation();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation } = useOrganisationsContext();

    const [sentToDealerTime, setSentToDealerTime] = useState<
        string | null | undefined
    >(shoppingList?.sentToDealerTime);
    const [shoppingListCreator, setShoppingListCreator] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const [showShareAlert, setShowAlert] = useState<boolean>(false);
    const [showContactSelection, setShowContactSelection] =
        useState<boolean>(false);
    const [isNameEditActive, setNameEditActive] = useState(false);
    const [shoppingListName, setShoppingListName] = useState(
        shoppingList?.name,
    );
    const [usersWithColors, setUsersWithColors] = useState<UserWithColor[]>([]);

    const shoppingListService = useMemo(
        () =>
            new ShoppingList({
                database,
                logDBAction: Logger.logRecordActivity,
                imageService: ImagesService,
                logError: Logger.logError,
            }),
        [ImagesService],
    );

    const userService = useMemo(
        () =>
            new User({
                database,
                logDBAction: Logger.logRecordActivity,
                imageService: ImagesService,
            }),
        [ImagesService],
    );

    const numberOfItemsLabel = useMemo(() => {
        const suffix =
            products.length === 1
                ? t('SingleShoppingList:button:item')
                : t('SingleShoppingList:button:items');
        return `${products.length} ${suffix}`;
    }, [products.length, t]);

    const inventoryVisibility = useMemo(() => {
        return (
            <>
                {showInventory ? <VisibilityOffIcon /> : <VisibilityIcon />}
                <Typography sx={defaultTextStyle}>
                    {showInventory
                        ? t('SingleShoppingList:button:hide_inventory')
                        : t('SingleShoppingList:button:show_inventory')}
                </Typography>
            </>
        );
    }, [showInventory, t]);

    const addedToInventoryLabel = useMemo(() => {
        if (shoppingList?.addedToInventoryTime) {
            const date = formatDate(shoppingList?.addedToInventoryTime);
            return (
                <Typography
                    sx={{ ...infoLabelsStyle, justifySelf: 'flex-end' }}
                    data-test-id="ShoppingListPage-InventoryAddedTime"
                >{`${t(
                    'ShoppingList:added_to_inventory',
                )} ${date}`}</Typography>
            );
        }
    }, [shoppingList?.addedToInventoryTime, t]);

    const helperText = useMemo(() => {
        if (!shoppingListName?.length) {
            return t('SignUp:error:field_required', {
                field: t('ShoppingListsPage:fields:title'),
            });
        } else if (shoppingListName && shoppingListName?.length < 2) {
            return t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('ShoppingListsPage:fields:title'),
            });
        } else {
            return '';
        }
    }, [shoppingListName, t]);

    const updateUsersWithColors = useCallback(async (users: UserModel[]) => {
        setUsersWithColors(await mapUsersWithColors(users, database));
    }, []);

    const fetchShoppingListCreator = useCallback(async () => {
        if (shoppingList && shoppingList.userId !== shoppingListCreator?.id) {
            const [user] = await userService
                .getById(shoppingList.userId)
                .fetch();
            updateUsersWithColors([user]);
            setShoppingListCreator({
                id: user.id,
                name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
            });
        }
    }, [
        shoppingList,
        shoppingListCreator?.id,
        updateUsersWithColors,
        userService,
    ]);

    useEffect(() => {
        fetchShoppingListCreator();
    }, [fetchShoppingListCreator]);

    const handleInventoryVisibilityClick = useCallback(
        () => setShowInventory(),
        [setShowInventory],
    );

    const handleShareClick = useCallback(() => setShowAlert(true), []);

    const closeShareAlert = useCallback(() => setShowAlert(false), []);

    const closeContactSelection = useCallback(
        () => setShowContactSelection(false),
        [],
    );

    const openContactSelection = useCallback(
        () => setShowContactSelection(true),
        [],
    );

    const toggleNameEdit = useCallback(() => {
        setNameEditActive((prev) => !prev);
    }, []);

    const updateShoppingListName = useCallback(async () => {
        if (shoppingList && shoppingListName) {
            await shoppingListService.updateName(
                shoppingList.id,
                shoppingListName,
            );

            ShoppingListsAnalytics.logUserUpdatedShoppingListTitle(
                FirebaseAnalytics.logEvent,
                {
                    title: shoppingListName,
                    isOwner: userOrganisation?.owner ?? true,
                },
            );

            setNameEditActive(false);
        }
    }, [
        shoppingList,
        shoppingListName,
        shoppingListService,
        userOrganisation?.owner,
    ]);

    const shareViaClient = useCallback(async () => {
        try {
            if (!shoppingList || !userProfileData) {
                throw new Error();
            }

            const products = await shoppingList?.shoppingListProducts.fetch();

            if (!products.length) {
                throw new Error();
            }
            const params: ShoppingProductParserParams = {
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
                userId: userProfileData.id,
            };
            const parsedProducts = await shoppingProductParser(
                database,
                products,
                params,
            );

            await generateProductCSV(
                shoppingList.name,
                parsedProducts,
                database,
                params,
            );

            ShoppingListsAnalytics.logUserDownloadedShoppingList(
                FirebaseAnalytics.logEvent,
            );
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [ImagesService, shoppingList, t, userProfileData]);

    const shareViaBackend = useCallback(
        async (dealerId: string) => {
            try {
                if (!shoppingList) {
                    throw new Error();
                }

                await shoppingListService.sendToDealer(
                    shoppingList.id,
                    dealerId,
                );
                await FirebaseFirestoreAPI.addDocumentToCollection<FirebaseShoppingList>(
                    FIRESTORE_COLLECTION.shopping_lists,
                    shoppingList.id,
                    {
                        id: shoppingList.id,
                        sync: false,
                    },
                );
                setSentToDealerTime(moment().startOf('day').toISOString());

                ShoppingListsAnalytics.logUserSharedShoppingListWithDealer(
                    FirebaseAnalytics.logEvent,
                    { dealerId },
                );

                Snackbar.showToastNotification({
                    message: t('SingleShoppingList:alert:shopping_list_share'),
                });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [shoppingList, shoppingListService, t],
    );

    const onConfirmShareShoppingList = useCallback(
        (shareOption: SHARE_SHOPPING_LIST_TYPE) => {
            if (shareOption === SHARE_SHOPPING_LIST_TYPE.VIA_BACKEND) {
                openContactSelection();
            } else {
                shareViaClient();
            }
        },
        [openContactSelection, shareViaClient],
    );

    const handleOnSelectedContact = useCallback(
        (contacts: ContactModel[] | ContactModel) =>
            shareViaBackend((contacts as ContactModel).id),
        [shareViaBackend],
    );

    const contactValidation = useCallback(
        (contact: ContactModel) => !!contact.email,
        [],
    );

    const AddToInventoryButton = !shoppingList?.addedToInventoryTime ? (
        <TooltipSyncButton
            onClick={onAddToInventoryClick}
            disabled={isLoading || isSyncInProgress}
            showTooltip={isSyncInProgress}
            tooltip={t('AddEditModalHeader:button_tooltip', {
                defaultValue: 'Please wait for the synchronization to complete',
            })}
            sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
            }}
            testID="ShoppingListPage-AddToInventoryButton"
            color="inherit"
        >
            <InventoryIcon />
            <Typography sx={defaultTextStyle}>
                {t('ShoppingList:add_to_inventory')}
            </Typography>
        </TooltipSyncButton>
    ) : null;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                bgcolor: COLOR.transparentLightGrayBackground,
                borderRadius: '16px 16px 2px 2px',
                mb: 7.5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        flex: 0.5,
                    }}
                >
                    {isNameEditActive ? (
                        <>
                            <TextField
                                fullWidth
                                size={'small'}
                                disabled={isSyncInProgress}
                                data-test-id="ShoppingListPage-TitleInput"
                                id="shoppingListName"
                                label={t('ShoppingListsPage:fields:title')}
                                error={!!helperText.length}
                                helperText={helperText}
                                onChange={(e) => {
                                    setShoppingListName(e.target.value);
                                }}
                                name="shoppingListName"
                                value={shoppingListName}
                            />
                            <TooltipSyncButton
                                onClick={updateShoppingListName}
                                disabled={!!helperText || isSyncInProgress}
                                showTooltip={isSyncInProgress}
                                sx={{ minWidth: 0, ml: 2 }}
                                size="small"
                                testID="ShoppingListPage-Confirm"
                                tooltip={t(
                                    'AddEditModalHeader:button_tooltip',
                                    {
                                        defaultValue:
                                            'Please wait for the synchronization to complete',
                                    },
                                )}
                            >
                                {t('Actions:confirm')}
                            </TooltipSyncButton>
                            <Button
                                className="min-w-0 ml-2"
                                data-test-id="ShoppingListPage-CancelNameEditButton"
                                size="small"
                                onClick={toggleNameEdit}
                                color="error"
                            >
                                {t('Actions:cancel')}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography
                                sx={{
                                    fontSize: 24,
                                    fontWeight: 700,
                                    color: COLOR.paleSky,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                data-test-id="ShoppingListPage-Name"
                                onClick={toggleNameEdit}
                            >
                                {!isLoading ? (
                                    shoppingList?.name
                                ) : (
                                    <Skeleton width={250} variant="text" />
                                )}
                            </Typography>
                            <Button
                                className="min-w-0 ml-2"
                                data-test-id="ShoppingListPage-EditNameButton"
                                size="small"
                                onClick={toggleNameEdit}
                            >
                                {t('Actions:edit')}
                            </Button>
                        </>
                    )}
                </Box>

                <Typography
                    sx={{
                        p: 1,
                        fontSize: 13,
                        fontWeight: 300,
                        color: COLOR.ebonyClay,
                    }}
                    data-test-id="ShoppingListPage-ItemsNumber"
                >
                    {!isLoading ? (
                        numberOfItemsLabel
                    ) : (
                        <Skeleton width={40} variant="text" />
                    )}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    {shoppingListCreator ? (
                        <div className="flex flex-row items-center">
                            <Typography
                                sx={{ ...infoLabelsStyle, pl: 0.5 }}
                                data-test-id="ShoppingListPage-SentToDealerTime"
                            >
                                {t(
                                    'SingleShoppingList:button:shopping_list_created_at',
                                    {
                                        date: formatDate(
                                            shoppingList?.createdAt.toISOString() ??
                                                '',
                                        ),
                                    },
                                )}
                            </Typography>
                            {usersWithColors.length ? (
                                <Box
                                    className="ml-1 p-[0.3rem] rounded-full"
                                    sx={{
                                        backgroundColor:
                                            usersWithColors[0].userColor,
                                    }}
                                />
                            ) : null}

                            <Typography
                                sx={infoLabelsStyle}
                                data-test-id="ShoppingListPage-SentToDealerTime"
                            >
                                {shoppingListCreator.name}
                            </Typography>
                        </div>
                    ) : null}
                    {sentToDealerTime ? (
                        <Typography
                            sx={infoLabelsStyle}
                            data-test-id="ShoppingListPage-SentToDealerTime"
                        >
                            {t(
                                'SingleShoppingList:button:share_with_dealer_last_update',
                                {
                                    date: formatDate(sentToDealerTime),
                                },
                            )}
                        </Typography>
                    ) : null}
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Button
                            data-test-id="ShoppingListPage-ShareWithDealerButton"
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                            color="inherit"
                            size="small"
                            onClick={handleShareClick}
                            disabled={isLoading}
                        >
                            <EmailIcon />
                            <Typography sx={defaultTextStyle}>
                                {t(
                                    'SingleShoppingList:button:share_with_dealer',
                                )}
                            </Typography>
                        </Button>
                        {AddToInventoryButton}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }}
                >
                    {shoppingList?.addedToInventoryTime
                        ? addedToInventoryLabel
                        : null}
                    <Button
                        data-test-id="ShoppingListPage-HideInventory"
                        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                        color="inherit"
                        size="small"
                        disabled={isLoading}
                        onClick={handleInventoryVisibilityClick}
                    >
                        {inventoryVisibility}
                    </Button>
                </Box>
            </Box>
            <ShareWithDealerAlert
                isOpen={showShareAlert}
                onClose={closeShareAlert}
                shoppingListName={shoppingList?.name ?? ''}
                onConfirm={onConfirmShareShoppingList}
            />
            {showContactSelection ? (
                <SelectContactsModal
                    addSelectedItemsButtonText={t('Actions:send')}
                    close={closeContactSelection}
                    isOpen={showContactSelection}
                    multiSelect={false}
                    selectCondition={contactValidation}
                    conditionWarning={t('Contact:alert:no_email_provided')}
                    selectedContacts={[]}
                    onSelectContacts={handleOnSelectedContact}
                />
            ) : null}
        </Box>
    );
}

export default HeaderSection;
