import { InventoryChangeModel } from '../types/InventoryChange';
import {
    InventoryProduct,
    InventoryProductSize,
    ProductModel,
} from '../types/Products';
import { InventoryProductModel } from '../types/InventoryProduct';
import { RegionModel } from '../types/Region';
import {
    getProductMaterialShapeAndClips,
    getProductName,
    getProductSize,
} from './product';
import User from '../db/services/User';
import Product from '../db/services/Product';
import { Collection } from '@nozbe/watermelondb';

export function sumInventoryChanges(
    changes: Pick<InventoryChangeModel, 'processAt' | 'quantityChange'>[],
) {
    const sumOfChanges = changes
        .filter((c) => new Date(c.processAt) <= new Date())
        .map((c) => c.quantityChange)
        .reduce((q, acc) => q + acc, 0);

    return sumOfChanges;
}

export async function getInventoryProductSizes({
    productsForInventory,
    inventoryProducts,
    regions,
    userId,
    userService,
    productService,
}: {
    productsForInventory: ProductModel[];
    inventoryProducts: InventoryProductModel[];
    regions: Collection<RegionModel>;
    userId: string;
    userService: User;
    productService: Product;
}): Promise<InventoryProductSize[]> {
    const userRegion = await userService.getUserRegion(userId);

    const filteredProducts: InventoryProductSize[] = [];

    for (const productForInventory of productsForInventory) {
        const inventoryProduct = inventoryProducts.find(
            (invProd) => invProd.productId === productForInventory.id,
        );

        if (
            inventoryProduct ||
            (await productService.isProductFromUserRegion(
                productForInventory,
                regions,
                userRegion,
            ))
        ) {
            filteredProducts.push({
                productId: productForInventory.id,
                inventoryProductId: inventoryProduct?.id ?? '',
                quantity: inventoryProduct?.quantity ?? 0,
                size: getProductSize(productForInventory) ?? '',
                isInInventory: !!inventoryProduct,
                isHighlighted: false,
                notAvailable: productForInventory.notAvailable,
            });
        }
    }

    return filteredProducts;
}

export async function getInventoryProducts({
    groupedProducts,
    inventoryProducts,
    regions,
    userId,
    userService,
    productService,
}: {
    groupedProducts: ProductModel[][];
    inventoryProducts: InventoryProductModel[];
    regions: Collection<RegionModel>;
    userId: string;
    userService: User;
    productService: Product;
}): Promise<InventoryProduct[]> {
    const mappedInventoryProducts: InventoryProduct[] = [];

    for (const group of groupedProducts) {
        const product = group[0];
        const name = getProductName(product);

        const materialShapeAndClips = getProductMaterialShapeAndClips(product);

        const productSizes = await getInventoryProductSizes({
            productsForInventory: group,
            inventoryProducts,
            regions,
            userId,
            userService,
            productService,
        });

        mappedInventoryProducts.push({
            stockProductId:
                productSizes.find((prod) => prod.isInInventory)?.productId ??
                '',
            name,
            materialShapeAndClips,
            type: product.productType,
            dimensions: group.length > 1 ? product.dimensions ?? null : null,
            showAll: false,
            productSizes,
        });
    }

    return mappedInventoryProducts;
}
