import { datadogLogs } from '@datadog/browser-logs';

import { APP_CONFIG } from '@/constants/appConfig';

function init() {
    if (!process.env.__DEV__) {
        datadogLogs.init({
            clientToken: APP_CONFIG.datadogClientToken,
            site: 'datadoghq.eu',
            forwardErrorsToLogs: false,
            sampleRate: 100,
            env: `${APP_CONFIG.environmentName}-fe-web`,
            service: `${APP_CONFIG.environmentName}-fe-web`,
        });
    }
}

export default { init };
