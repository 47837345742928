import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function TeamIcon({
    color = COLOR.whiteDarker2,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg
            data-test-id="TeamIcon"
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 1024 1024"
        >
            <path d="M869.798 484.292c53.007 0 98.786-43.369 98.786-98.786s-43.369-96.376-98.786-96.376c-53.007 0-98.786 43.369-98.786 98.786s45.779 96.376 98.786 96.376z"></path>
            <path d="M252.988 387.915c0 54.558-44.228 98.786-98.786 98.786s-98.786-44.228-98.786-98.786c0-54.558 44.228-98.786 98.786-98.786s98.786 44.228 98.786 98.786z"></path>
            <path d="M154.202 513.205c-50.598 0-149.384 43.369-149.384 127.699v62.645h233.713c4.819-45.779 24.094-84.329 50.598-115.652-28.913-50.598-96.376-74.692-134.927-74.692v0z"></path>
            <path d="M869.798 513.205c-38.551 0-106.014 24.094-134.927 74.692 28.913 31.322 48.188 69.873 50.598 115.652h233.713v-62.645c2.409-86.739-98.786-130.108-149.384-127.699v0z"></path>
            <path d="M513.205 496.339c77.101 0 139.746-62.645 139.746-139.746s-62.645-139.746-139.746-139.746-139.746 62.645-139.746 139.746 60.235 139.746 139.746 139.746v0z"></path>
            <path d="M513.205 534.889c-72.282 0-216.847 62.645-216.847 183.115v91.558h431.285v-91.558c0-120.471-144.565-183.115-214.438-183.115z"></path>
        </svg>
    );
}

export default memo(TeamIcon);
