import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import bgImage from '@/assets/images/bg-image.webp';
import { AuthPageLayout } from '@/layouts';
import { ROUTE } from '@/router/routes';
import { COLOR } from '@/theme/colors';

import { ForgotPasswordForm } from './components';

function ForgotPasswordPage() {
    const content = (
        <>
            <Box
                sx={{
                    mt: '36px',
                    ml: { xs: 4, md: 10 },
                }}
            >
                <Link to={ROUTE.signIn}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ArrowBackIosIcon
                            sx={{ width: 12, height: 12 }}
                            htmlColor={COLOR.ebonyClay}
                        />
                        <Typography
                            fontSize={12}
                            fontWeight={700}
                            color={COLOR.ebonyClay}
                        >
                            {t('ForgotPassword:topbar:back')}
                        </Typography>
                    </Box>
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '60px',
                }}
            >
                <Box
                    sx={{
                        ml: { xs: 4, md: 10 },
                        mr: { xs: 4, md: 0 },
                    }}
                >
                    <Box
                        alt="Equinet Logo"
                        component="img"
                        src={equinetLogoImage}
                        sx={{
                            height: 88,
                            mb: 4,
                            width: 334,
                        }}
                    />
                    <Typography
                        component="h1"
                        sx={{ fontWeight: 700, mb: 1 }}
                        variant="h5"
                    >
                        {t('ForgotPassword:header:find_your_account')}
                    </Typography>
                    <Typography
                        component="p"
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                    >
                        {t('ForgotPassword:header:enter_email_phone_number')}
                    </Typography>
                </Box>
                <ForgotPasswordForm />
            </Box>
        </>
    );

    return (
        <AuthPageLayout
            backgroundImage={bgImage}
            rightColumnContent={content}
        />
    );
}

export default ForgotPasswordPage;
