import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { useImagesContext } from '@/context/ImagesContext';
import { Context } from '@/types/context';
import { UserProfileData } from '@/types/user';

const UserContext = createContext<Context.User.Value>({
    clearContextState: () => null,
    setUserProfileData: () => null,
    userAvatarUrl: '',
    userProfileData: null,
});

const userContextRef: React.MutableRefObject<Context.User.Value | null> =
    createRef();

const UserContextProvider = ({ children }: Context.User.Props) => {
    const { images, ImagesService } = useImagesContext();

    const [userAvatarUrl, setUserAvatarUrl] = useState('');
    const [userProfileData, setUserProfileData] =
        useState<UserProfileData | null>(null);

    const getUserAvatar = useCallback(() => {
        if (!userProfileData?.id || !images) {
            return;
        }

        const imageUrl = ImagesService.getSingleImageByEntityId(
            images,
            userProfileData.id,
        )?.imageURL;

        if (imageUrl) {
            setUserAvatarUrl(imageUrl);
        }
    }, [images, userProfileData, ImagesService]);

    useEffect(() => {
        getUserAvatar();
    }, [getUserAvatar]);

    const clearContextState = useCallback(() => {
        setUserAvatarUrl('');
        setUserProfileData(null);
    }, []);

    const contextValue: Context.User.Value = {
        clearContextState,
        setUserProfileData,
        userAvatarUrl,
        userProfileData,
    };

    userContextRef.current = contextValue;

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

function useUserContext() {
    const userContext = useContext(UserContext);

    return userContext;
}

function clearUserContextState() {
    return userContextRef.current?.clearContextState();
}

export {
    UserContext,
    UserContextProvider,
    useUserContext,
    clearUserContextState,
};
