import { Collection, Database, Model, Q } from '@nozbe/watermelondb';

import EventContactReminder from './EventContactReminder';
import Organisation from './Organisation';
import {
    EVENT_CONTACT_STATUS,
    EventContactModel,
    EventContactPayload,
} from '../../types/EventContact';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { getLocal } from 'shared/utils/date';

class EventContact {
    private database: Database;
    private collection: Collection<EventContactModel>;
    private table = 'event_contacts';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByEventId(id: string) {
        return this.collection.query(Q.where('event_id', id));
    }

    getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value));
    }

    getByContactAndStatus(contactId: string, status: EVENT_CONTACT_STATUS) {
        const yesterdayDate = getLocal().minus({ days: 1 });
        return this.collection.query(
            Q.and(Q.where('contact_id', contactId), Q.where('status', status)),
            Q.on('events', Q.where('ends_time', Q.gt(yesterdayDate.toISO()))),
        );
    }

    getByEventAndStatus(eventId: string, status: EVENT_CONTACT_STATUS) {
        const yesterdayDate = getLocal().minus({ days: 1 });
        return this.collection.query(
            Q.and(Q.where('event_id', eventId), Q.where('status', status)),
            Q.on('events', Q.where('ends_time', Q.gt(yesterdayDate.toISO()))),
        );
    }

    async fetchByParam(param: string, value: any) {
        return this.getByParam(param, value).fetch();
    }

    getByParams(params: { name: string; value: string }[]) {
        return this.collection.query(
            Q.and(...params.map((param) => Q.where(param.name, param.value))),
        );
    }

    fetchByParams(params: { name: string; value: string }[]) {
        return this.getByParams(params).fetch();
    }

    async fetchByEventIdAndContactId(eventId: string, contactId: string) {
        return this.collection
            .query(
                Q.and(
                    Q.where('event_id', eventId),
                    Q.where('contact_id', contactId),
                ),
            )
            .fetch();
    }

    async updateEventContactInvitations(
        eventId: string,
        contactId: string,
        payload: Partial<{ sendSMS: boolean; sendEmail: boolean }>,
    ) {
        const eventContact = await this.fetchByEventIdAndContactId(
            eventId,
            contactId,
        );

        if (eventContact.length && eventContact[0]) {
            await this.database.write(async () => {
                const updatedEventContact = await eventContact[0].update(
                    (eventContact) => {
                        eventContact.sentSMS =
                            payload.sendSMS || eventContact.sentSMS;
                        eventContact.sentEmail =
                            payload.sendEmail || eventContact.sentEmail;
                    },
                );

                this.options.logDBAction({
                    message: 'Update event contact invitations',
                    modelName: this.table,
                    payload: updatedEventContact,
                });

                return updatedEventContact;
            });
        }
    }

    async add(payload: EventContactPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdEventContact = await this.collection.create(
                (eventContact) => {
                    eventContact.eventId = payload.eventId;
                    eventContact.contactId = payload.contactId;
                    eventContact.userId = userId;
                    eventContact.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create event contact',
                modelName: this.table,
                payload: createdEventContact,
            });
        });
    }

    async deleteByID(id: string) {
        const eventContactElement = await this.getByID(id);

        await this.database.write(async () => {
            await eventContactElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete event contact',
                modelName: this.table,
                payload: eventContactElement,
            });

            const eventContactReminderService = new EventContactReminder({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const eventContactReminders =
                await eventContactReminderService.getByParam(
                    'event_contact_id',
                    id,
                );

            if (eventContactReminders.length > 0) {
                let eventContactRemindersToDelete: Model[] = [];

                await Promise.all(
                    eventContactReminders.map((eventContactReminder) => {
                        eventContactRemindersToDelete = [
                            ...eventContactRemindersToDelete,
                            eventContactReminder.prepareMarkAsDeleted(),
                        ];
                    }),
                );

                this.database.batch(...eventContactRemindersToDelete);

                this.options.logDBAction({
                    message:
                        'Delete event contact - delete event contact reminders',
                    modelName: this.table,
                    payload: eventContactRemindersToDelete,
                });
            }
        });
    }
}

export default EventContact;
