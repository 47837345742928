import { AppConfig } from '@/types/appConfig';

export const APP_CONFIG: AppConfig = {
    apiUrl: process.env.API_URL,
    environmentName: process.env.ENVIRONMENT_NAME,
    sentryDns: process.env.SENTRY_DNS,
    firebaseOptions: {
        apiKey: process.env.FIREBASE_API_KEY,
        appId: process.env.FIREBASE_APP_ID,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.FIREBASE_DATABASE_URL,
        measurementId: process.env.FIREBASE_MEASUREMENT_ID,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    },
    googlePlacesApiKey: process.env.GOOGLE_PLACES_API_KEY,
    // 12 hours. Firebase has a limit on the remote config requests
    // and therefore we have to set one as well
    remoteConfigCacheTimeInMillis: process.env.__DEV__ ? 0 : 30000,
    datadogClientToken: process.env.DATADOG_CLIENT_TOKEN || '',
    runFirebaseEmulator:
        process.env.RUN_FIREBASE_EMULATOR === 'true' ? true : false,
    firebaseEmulatorAuthPort: process.env.FIREBASE_EMULATOR_AUTH_PORT
        ? Number(process.env.FIREBASE_EMULATOR_AUTH_PORT)
        : undefined,
    firebaseEmulatorFirestorePort: process.env.FIREBASE_EMULATOR_FIRESTORE_PORT
        ? Number(process.env.FIREBASE_EMULATOR_FIRESTORE_PORT)
        : undefined,
    freshchatSrc: process.env.FRESHCHAT_SRC || undefined,
    firebaseDeepLinkPrefix: process.env.FIREBASE_DEEPLINK_PREFIX || '',
    firebaseHostDomain: process.env.FIREBASE_HOST_DOMAIN || '',
    mobileAppPackageIdentifier:
        process.env.MOBILE_APPLICATION_PACKAGE_IDENTIFIER || undefined,
    onboardingQuestionsVersion: process.env.ONBOARDING_QUESTIONS_VERSION
        ? Number(process.env.ONBOARDING_QUESTIONS_VERSION)
        : 2,
};
