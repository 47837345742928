import {
    Box,
    Button,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'shared/utils/moment';

import { formatDate } from '@/utils/date';
import { ShoppingListValidationSchema } from '@/validations/shoppingList';

import { Props } from './types';

const defaultTitle = `List ${formatDate(moment().toISOString(), 'date')}`;

function Footer({
    isShoppingListSelected,
    onAddToShoppingListButtonClick,
    onCancelButtonClick,
    onNewShoppingListSwitchChange,
}: Props) {
    const { t } = useTranslation();

    const form = useFormik<{ createNewShoppingList: boolean; title: string }>({
        initialValues: { createNewShoppingList: false, title: defaultTitle },
        // We use formik here just for validation purposes.
        // No submission via formik is being done
        onSubmit: () => {
            return;
        },
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema:
            ShoppingListValidationSchema.addShoppingListOptionalForm,
    });

    const handleNewShoppingListSwitchChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;

            form.setFieldValue('createNewShoppingList', checked);

            onNewShoppingListSwitchChange(checked);
        },
        [form, onNewShoppingListSwitchChange],
    );

    const handleAddToShoppingListButtonClick = useCallback(() => {
        onAddToShoppingListButtonClick(form.values.title);
    }, [onAddToShoppingListButtonClick, form.values.title]);

    const { createNewShoppingList, title } = form.values;

    const disableAddButton = createNewShoppingList
        ? !form.isValid
        : !isShoppingListSelected;

    return (
        <Box className="border-1 border-t border-t-gray-200 pt-6 px-8">
            <Box>
                <FormControlLabel
                    checked={createNewShoppingList}
                    className="mb-5 min-w-0"
                    control={
                        <Switch
                            data-test-id="AddProductToShoppingListModal-NewShoppingListSwitch"
                            id="createNewShoppingList"
                            name="createNewShoppingList"
                            onChange={handleNewShoppingListSwitchChange}
                            value={createNewShoppingList}
                        />
                    }
                    label={t('ShoppingListsPage:new_shopping_list')}
                />
                {createNewShoppingList ? (
                    <TextField
                        className="mb-10"
                        data-test-id="AddProductToShoppingListModal-NewShoppingListTitleInput"
                        error={!!form.errors.title && form.touched.title}
                        fullWidth
                        helperText={form.touched.title ? form.errors.title : ''}
                        id="title"
                        label={'Title'}
                        name="title"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={title}
                    />
                ) : null}
            </Box>
            <Box className="flex justify-end">
                <Box>
                    <Button
                        className="mr-3"
                        color="inherit"
                        data-test-id="AddProductToShoppingListModal-CancelButton"
                        onClick={onCancelButtonClick}
                    >
                        {t('Actions:cancel')}
                    </Button>
                    <Button
                        data-test-id="AddProductToShoppingListModal-AddToShoppingListButton"
                        disabled={disableAddButton}
                        onClick={handleAddToShoppingListButtonClick}
                        variant="contained"
                    >
                        {t('Inventory:button:add_to_shopping_list')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default memo(Footer);
