import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, SxProps, Typography, TextField, Button } from '@mui/material';
import React, {
    ChangeEvent,
    FocusEvent,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    calculateAvailableUnits,
    calculateUnitTypeDisplayName,
    parseAvailableUnits,
} from 'shared/utils/productUnit';

import { Select } from '@/components';
import { useAddProductsContext } from '@/context/AddProductsContext';
import { COLOR } from '@/theme/colors';

import ProductIcon from '../ProductIcon';

import { Props } from './types';

const flexStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
};

const yellowBoxStyle: SxProps = {
    backgroundColor: COLOR.vividYellow12,
    width: 64,
    height: 40,
    borderRadius: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    mr: 11,
};

function ProductSizeItem({
    size,
    icon,
    testID,
    product,
    showUnitTypes,
}: Props) {
    const {
        handleQuantityChange,
        productQuantities,
        incrementQuantity,
        decrementQuantity,
        boxUnitSizes,
        handleUnitTypeChange,
    } = useAddProductsContext();

    const { t } = useTranslation();

    const [isQuantityInputFocused, setIsQuantityInputFocused] = useState(false);

    const _onUnitTypeChange = useCallback(
        (value: string[]) => {
            handleUnitTypeChange(product.id, value[0]);
        },
        [handleUnitTypeChange, product.id],
    );

    const productBoxUnitSizes = useMemo(() => {
        return boxUnitSizes[product.id];
    }, [boxUnitSizes, product.id]);

    const shoppingListProductUnitOptions = useMemo(() => {
        const availableUnits: string[] = calculateAvailableUnits(
            productBoxUnitSizes,
            product.productType,
        );
        const parsedAvailableUnits = parseAvailableUnits(
            availableUnits,
            product.productType,
        );
        return parsedAvailableUnits;
    }, [product.productType, productBoxUnitSizes]);

    const quantity = useMemo(() => {
        const quantity = productQuantities[product.id]?.quantity;

        if (
            isQuantityInputFocused &&
            (quantity === '0' || quantity === undefined)
        ) {
            return '';
        }

        return quantity === undefined ? '0' : quantity;
    }, [isQuantityInputFocused, product.id, productQuantities]);

    const selected = useMemo(() => parseInt(quantity, 10) > 0, [quantity]);

    const onQuantityChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            handleQuantityChange(product.id, value);
        },
        [handleQuantityChange, product.id],
    );

    const onQuantityFieldFocus = useCallback(() => {
        setIsQuantityInputFocused(true);
    }, []);

    const onQuantityFieldBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const value = event.target.value;

            if (!value || Number(value) < 0) {
                handleQuantityChange(product.id, '0');
            }

            setIsQuantityInputFocused(false);
        },
        [handleQuantityChange, product.id],
    );

    const increment = useCallback(() => {
        incrementQuantity(product.id);
    }, [incrementQuantity, product.id]);

    const decrement = useCallback(() => {
        decrementQuantity(product.id);
    }, [decrementQuantity, product.id]);

    const unit = useMemo(() => {
        const unit = productQuantities[product.id]?.unitType;
        return unit === undefined
            ? shoppingListProductUnitOptions[0].id || ''
            : unit;
    }, [product.id, productQuantities, shoppingListProductUnitOptions]);

    const shoppingListProductSelectedUnit = useCallback(
        (value: string[]) => {
            const unitType = unit ?? value[0];
            const result = calculateUnitTypeDisplayName(
                unitType,
                product.productType,
            );
            return result;
        },
        [unit, product.productType],
    );

    const onIconClick = useCallback(() => {
        if (selected) {
            handleQuantityChange(product.id, '0');
        } else {
            handleQuantityChange(product.id, '1');
        }
    }, [handleQuantityChange, product.id, selected]);

    return (
        <Box
            sx={{
                ...flexStyle,
                justifyContent: 'space-between',
            }}
            data-test-id={testID}
        >
            <Box sx={flexStyle}>
                <Box sx={yellowBoxStyle}>
                    <Typography>{size}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Button
                        className="min-w-0 p-0"
                        size="small"
                        data-test-id={`${testID}-ProductSizeTableItem-RemoveButton`}
                        disabled={!!quantity && parseInt(quantity, 10) <= 0}
                        sx={{
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                        color="inherit"
                        onClick={decrement}
                    >
                        <RemoveIcon />
                    </Button>
                    <TextField
                        onBlur={onQuantityFieldBlur}
                        onFocus={onQuantityFieldFocus}
                        type="number"
                        value={quantity}
                        onChange={onQuantityChange}
                        data-test-id={`${testID}-ProductSizeTableItem-QuantityInput`}
                        sx={{
                            maxWidth: 100,
                            /* Chrome, Safari, Edge, Opera */
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            /* Firefox */
                            'input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        }}
                    />
                    <Button
                        className="min-w-0 p-0"
                        size="small"
                        data-test-id={`${testID}-ProductSizeTableItem-AddButton`}
                        sx={{ fontWeight: 700, fontSize: 16 }}
                        color="inherit"
                        onClick={increment}
                    >
                        <AddIcon />
                    </Button>
                    {showUnitTypes && selected ? (
                        <Select
                            styles={{ ml: 3 }}
                            label={t('ShoppingList:table:unit')}
                            onChange={_onUnitTypeChange}
                            options={shoppingListProductUnitOptions ?? []}
                            selectedValue={[unit ?? '']}
                            renderSelectedValue={
                                shoppingListProductSelectedUnit
                            }
                            testID={`${testID}-ProductSizeTableItem-UnitTypeSelect`}
                        />
                    ) : null}
                </Box>
            </Box>
            <ProductIcon
                testID={`${testID}-iconButton`}
                sx={{
                    justifySelf: 'flex-end',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
                onClick={onIconClick}
                defaultIcon={icon}
                selected={selected}
            />
        </Box>
    );
}

export default memo(ProductSizeItem);
