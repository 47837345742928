export enum CONTACTS_FILTER {
    invoicing = 'invoicing',
    role = 'role',
    show_hidden = 'show_hidden',
    show_ids = 'show_ids',
    include_business_name = 'include_business_name',
}

export enum CONTACTS_INVOICING_FILTER {
    billable = 'billable',
    open_invoices = 'open_invoices',
    open_entries = 'open_entries',
}

export const CONTACTS_INVOICING_FILTERS_DICTIONARY: Record<
    CONTACTS_INVOICING_FILTER,
    string
> = {
    [CONTACTS_INVOICING_FILTER.billable]:
        'ContactsList:filters:invoicing:billable',
    [CONTACTS_INVOICING_FILTER.open_invoices]:
        'ContactsList:filters:invoicing:open_invoices',
    [CONTACTS_INVOICING_FILTER.open_entries]:
        'ContactsList:filters:invoicing:open_entries',
};
