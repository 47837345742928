import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function HorseIcon({ color = COLOR.paleSky, height = 25, width = 25 }: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <path
                d="M9.9 39.1c.7-2.8 3-4.8 5.4-7 2.7-2.6 5.5-5.2 5.6-8.8-.5.9-1.2 1.4-1.9 1.8-1.3.6-2.7.5-4.2.5-.5 0-.9 0-1.2.1-.3.1-.5.3-.7.8-.2.5-.4.9-.6 1.2-.3.4-.6.7-.9.8-.8.3-1.4.4-2 .3-.9-.1-1.5-.5-2-1.1-.5-.5-.8-1.2-1-1.9-.4-.9-.5-1.8-.4-2.4v-.2l.1-.2c1.4-2 2.8-4.1 4.3-6.2 1.3-2 2.7-3.9 4.1-6-.1-1.2-.4-2.1-.7-3.1-.5-1.5-.7-2.6-.9-4.2 1.6.4 3.3.9 4.7 1.8.9.5 1.6 1.2 2.2 2.2.3-.5.7-.9 1.2-1.4l.3-.2.3.1c4.1 1.1 7.5 2.9 10 5.5s4.2 6 4.8 10.4c.4 2.5.2 5.4-.4 8.2-.6 2.7-1.7 5.2-3.1 7.4l-23 1.6z"
                style={{
                    fill: color,
                }}
            />
        </svg>
    );
}

export default memo(HorseIcon);
