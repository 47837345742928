// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class DeviceCalendarEvents extends Model {
    static table = 'device_calendar_events';

    static associations = {
        users: { type: 'belongs_to', key: 'user_id' },
        events: { type: 'belongs_to', key: 'event_id' },
    };

    @field('device_id') deviceID;
    @field('calendar_event_id') calendarEventID;
    @field('event_id') eventID;
    @field('user_id') userID;

    @relation('events', 'event_id') event;
    @relation('users', 'user_id') user;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
