import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Button, Checkbox, Radio, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SelectableListItem<T>({
    avatarUrl,
    avatarVariant,
    children,
    clickable,
    defaultAvatar,
    editable,
    hideAvatar,
    isSelected,
    item,
    onClick,
    onEditButtonClick,
    onRemove,
    removable,
    renderExtraInfo,
    selectable,
    showExtraInfo,
    avatarSize,
    testID,
    wrapperStyles,
    backgroundColor,
    itemHeight,
    multiSelect = true,
    selectCondition,
    conditionWarning,
    placeholder,
    avatarFitsContainer = true,
    renderExtraContentComponent,
}: Props<T>) {
    const isSelectable = useMemo(() => {
        if (selectCondition) {
            return selectCondition(item);
        } else {
            return selectable;
        }
    }, [item, selectCondition, selectable]);

    const handleItemClick = useCallback(() => {
        if ((isSelectable || clickable) && onClick) {
            onClick(item);
        }
    }, [clickable, isSelectable, item, onClick]);

    const handleItemRemove = useCallback(
        (event) => {
            event.stopPropagation();

            if (removable && onRemove) {
                onRemove(item);
            }
        },
        [item, onRemove, removable],
    );

    const handleEditButtonPress = useCallback(
        (event) => {
            event.stopPropagation();

            if (editable && onEditButtonClick) {
                onEditButtonClick(item);
            }
        },
        [editable, item, onEditButtonClick],
    );

    const isInteractive = clickable || isSelectable;

    const displayAvatar = useMemo(() => {
        if (hideAvatar) {
            return null;
        }
        if (
            typeof defaultAvatar === 'string' ||
            (!defaultAvatar && placeholder)
        ) {
            return (
                <Avatar
                    className={clsx('bg-gainsboro mr-4', {
                        'h-16 w-16': avatarSize === 'normal',
                        'h-[74px] w-[74px]': avatarSize !== 'normal',
                    })}
                    src={avatarUrl || defaultAvatar}
                    variant={avatarVariant}
                >
                    {placeholder}
                </Avatar>
            );
        } else {
            return defaultAvatar;
        }
    }, [
        hideAvatar,
        defaultAvatar,
        placeholder,
        avatarSize,
        avatarUrl,
        avatarVariant,
    ]);

    const notSelectableWarning = useMemo(() => {
        if (!isSelectable && selectCondition) {
            if (!conditionWarning) {
                return (
                    <WarningIcon
                        className="min-w-0 px-0 mx-2"
                        htmlColor={COLOR.vividYellow}
                    />
                );
            }

            let warningText = '';

            if (typeof conditionWarning === 'string') {
                warningText = conditionWarning;
            } else {
                warningText = conditionWarning(item);
            }

            return (
                <Tooltip title={warningText}>
                    <WarningIcon
                        className="min-w-0 px-0 mx-2"
                        htmlColor={COLOR.vividYellow}
                    />
                </Tooltip>
            );
        }
    }, [isSelectable, selectCondition, conditionWarning, item]);

    const backgroundColorClass = backgroundColor ? `bg-${backgroundColor}` : '';

    const itemSizeClass = itemHeight ? `h-${itemHeight}` : '';

    const borderClasses = clsx('rounded-lg', {
        'rounded-l-[32px]':
            avatarVariant === 'circular' && avatarSize === 'normal',
        'rounded-l-[37px]':
            avatarVariant === 'circular' && avatarSize !== 'normal',
        'rounded-bl-lg': !avatarFitsContainer,
    });

    return (
        <Box
            data-test-id={testID}
            className={twMerge(
                clsx('flex items-center', borderClasses),
                wrapperStyles,
            )}
        >
            {selectable && multiSelect ? (
                <Checkbox
                    checked={isSelected}
                    className="mr-2"
                    disabled={!isInteractive}
                    onClick={isInteractive ? handleItemClick : undefined}
                    size="small"
                    sx={{ ml: '-9px' }}
                />
            ) : null}
            {selectable && !multiSelect ? (
                <Radio
                    checked={isSelected}
                    className="mr-2"
                    disabled={!isInteractive}
                    onClick={isInteractive ? handleItemClick : undefined}
                    size="small"
                    sx={{ ml: '-9px' }}
                />
            ) : null}
            <Box
                className={clsx(
                    backgroundColorClass,
                    itemSizeClass,
                    borderClasses,
                    'flex flex-col h-full',
                    {
                        'w-[90%]': selectable,
                        'w-full': !selectable,
                        'hover:cursor-pointer hover:bg-aliceBlue':
                            isInteractive,
                        'bg-aliceBlue': isSelected,
                        'bg-athensGrayDarker': !isSelected,
                    },
                )}
                onClick={isInteractive ? handleItemClick : undefined}
            >
                <Box className="flex">
                    {displayAvatar}
                    <Box className="flex w-full min-w-0 items-center justify-between gap-2 py-1 pr-2">
                        <Box className="min-w-0" flex={6}>
                            {children}
                        </Box>
                        {showExtraInfo && renderExtraInfo
                            ? renderExtraInfo()
                            : null}
                        {editable ? (
                            <Button
                                className="min-w-0 px-0.5 mx-2"
                                onClick={handleEditButtonPress}
                                size="small"
                                data-test-id={`${testID}-EditButton`}
                            >
                                <EditRoundedIcon />
                            </Button>
                        ) : null}
                        {removable ? (
                            <Button
                                className="min-w-0 px-0"
                                onClick={handleItemRemove}
                                size="small"
                                data-test-id={`${testID}-RemoveButton`}
                            >
                                <DeleteOutlineRoundedIcon />
                            </Button>
                        ) : null}
                        {notSelectableWarning}
                    </Box>
                </Box>
                {renderExtraContentComponent ? (
                    <Box className="h-full flex flex-col justify-between">
                        <Box className="w-full pl-[90px] pr-3">
                            <Box className="h-[1px] bg-gainsboro w-full mb-[5px]" />
                        </Box>
                        <Box>{renderExtraContentComponent(item)}</Box>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default SelectableListItem;
