import { DocumentData, QueryDocumentSnapshot } from '@firebase/firestore';
import * as FirebaseFirestore from 'firebase/firestore';

import { APP_CONFIG } from '@/constants/appConfig';
import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { firebaseApp } from '@/services/firebase';

import Logger from '../logger';

const firestoreDB = FirebaseFirestore.initializeFirestore(firebaseApp, {
    experimentalForceLongPolling: APP_CONFIG.runFirebaseEmulator,
});

if (
    APP_CONFIG.runFirebaseEmulator &&
    APP_CONFIG.firebaseEmulatorFirestorePort
) {
    FirebaseFirestore.connectFirestoreEmulator(
        firestoreDB,
        'localhost',
        APP_CONFIG.firebaseEmulatorFirestorePort,
    );
}

function collectionConverter<T extends DocumentData>() {
    return {
        toFirestore: (data: T) => data,
        fromFirestore: (snap: FirebaseFirestore.QueryDocumentSnapshot) =>
            snap.data() as T,
    };
}

async function getCollectionDocumentById<T extends DocumentData>(
    collectionName: string,
    documentId: string,
) {
    const { doc, getDoc } = FirebaseFirestore;

    const docSnapshot = await getDoc(
        doc(firestoreDB, collectionName, documentId).withConverter(
            collectionConverter<T>(),
        ),
    );

    if (docSnapshot.exists()) {
        return docSnapshot.data();
    }

    return null;
}

async function getCollectionDocumentsByField<
    T extends FirebaseFirestore.WithFieldValue<DocumentData>,
>(collectionName: FIRESTORE_COLLECTION, fieldName: string, fieldValue: string) {
    const { collection, getDocs, query, where } = FirebaseFirestore;

    const dbQuery = query(
        collection(firestoreDB, collectionName).withConverter(
            collectionConverter<T>(),
        ),
        where(fieldName, '==', fieldValue),
    );

    const documentsData: QueryDocumentSnapshot<T>[] = [];
    const querySnapshot = await getDocs<T>(dbQuery);

    querySnapshot.forEach((doc) => {
        documentsData.push(doc);
    });

    return documentsData;
}

async function getCollectionDocuments<T extends DocumentData>(
    collectionName: FIRESTORE_COLLECTION,
) {
    const { collection, getDocs, query } = FirebaseFirestore;

    const dbQuery = query(
        collection(firestoreDB, collectionName).withConverter(
            collectionConverter<T>(),
        ),
    );

    const documentsData: T[] = [];
    const querySnapshot = await getDocs(dbQuery);

    querySnapshot.forEach((doc) => {
        documentsData.push(doc.data());
    });

    return documentsData;
}

function addDocumentToCollection<T extends { [x: string]: any }>(
    collectionName: FIRESTORE_COLLECTION,
    documentId: string,
    payload: T,
) {
    const { setDoc, doc } = FirebaseFirestore;

    return setDoc(doc(firestoreDB, collectionName, documentId), payload).catch(
        (error) => {
            Logger.logError(
                `(Firestore error) adding ${collectionName} collection failed \n payload: ${JSON.stringify(
                    payload,
                )} \n error: ${error}`,
            );
        },
    );
}

function updateDocumentInCollection<T extends { [x: string]: any }>(
    collectionName: FIRESTORE_COLLECTION,
    documentId: string,
    payload: T,
) {
    const { updateDoc, doc } = FirebaseFirestore;

    return updateDoc(doc(firestoreDB, collectionName, documentId), payload);
}

function removeDocumentInCollection(
    collectionName: string,
    documentId: string,
) {
    const { deleteDoc, doc } = FirebaseFirestore;

    return deleteDoc(doc(firestoreDB, collectionName, documentId));
}

function getCollectionRealtimeUpdates<T extends DocumentData>(
    collectionName: FIRESTORE_COLLECTION,
    organisationId: string,
    onUpdate: (documents: Array<T>) => void,
) {
    const { onSnapshot, collection, query, where } = FirebaseFirestore;

    const dbQuery = query(
        collection(firestoreDB, collectionName),
        where('organisationId', '==', organisationId),
    );

    const unsubscribe = onSnapshot(dbQuery, (snapshot) => {
        const updatedDocuments: Array<T> = [];

        snapshot.forEach((doc) => {
            updatedDocuments.push(doc.data() as T);
        });

        onUpdate(updatedDocuments);
    });

    return unsubscribe;
}

export const FirebaseFirestoreAPI = {
    firestoreDB,
    collectionConverter,
    getCollectionDocumentById,
    getCollectionDocumentsByField,
    getCollectionDocuments,
    addDocumentToCollection,
    updateDocumentInCollection,
    removeDocumentInCollection,
    getCollectionRealtimeUpdates,
};
