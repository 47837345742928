import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';

function storeItemInSessionStorage(
    key: WEB_STORAGE_KEYS,
    value: object | string | boolean,
) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

function getItemFromSessionStorage<T>(key: WEB_STORAGE_KEYS): T | null {
    const item = sessionStorage.getItem(key);

    if (item) {
        return JSON.parse(item) as T;
    } else {
        return null;
    }
}

function removeItemFromSessionStorage(key: WEB_STORAGE_KEYS) {
    sessionStorage.removeItem(key);
}

export {
    storeItemInSessionStorage,
    getItemFromSessionStorage,
    removeItemFromSessionStorage,
};
