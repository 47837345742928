import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRolesDictionary } from 'shared/utils/contactRoles';

import { ImagePreview, InitialsAvatar } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const HeaderSection = ({
    contact,
    avatarUrl,
    isLoading,
    isPhotoLoading,
}: Props) => {
    const { t } = useTranslation();

    const [imageToPreview, setImageToPreview] = useState<string | null>(null);

    const rolesDictionary = useMemo(() => {
        return getRolesDictionary(t);
    }, [t]);

    const openImagePreview = useCallback((url: string) => {
        setImageToPreview(url);
    }, []);

    const closeImagePreview = useCallback(() => {
        setImageToPreview(null);
    }, []);

    const contactPhoneNumber = useMemo(() => {
        if (!contact?.phone) return t('Contact:no_phone');

        if (contact?.phonePrefix) {
            return `${contact?.phonePrefix} ${contact?.phone}`;
        }

        return contact?.phone;
    }, [contact?.phone, contact?.phonePrefix, t]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    p: 2,
                    bgcolor: COLOR.transparentLightGrayBackground,
                    borderRadius: '16px 16px 2px 2px',
                    mb: 7.5,
                }}
            >
                {!isLoading && !isPhotoLoading ? (
                    <InitialsAvatar
                        avatarUrl={avatarUrl || ''}
                        firstName={contact?.firstName ?? ''}
                        lastName={contact?.lastName ?? ''}
                        onClick={openImagePreview}
                        size={130}
                        sx={{ fontWeight: 'normal', mr: 2.5 }}
                    />
                ) : (
                    <Skeleton
                        sx={{ mr: 3 }}
                        variant="circular"
                        width={130}
                        height={130}
                    />
                )}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography
                            data-test-id="ContactPage-UserNameText"
                            fontSize={24}
                            fontWeight={700}
                            color={COLOR.paleSky}
                        >
                            {!isLoading ? (
                                `${contact?.firstName ?? ''} ${
                                    contact?.lastName ?? ''
                                }`
                            ) : (
                                <Skeleton width={150} variant="text" />
                            )}
                        </Typography>
                        <Typography
                            data-test-id="ContactPage-UserRoleText"
                            fontSize={13}
                            fontWeight={300}
                            color={COLOR.black60}
                        >
                            {!isLoading ? (
                                contact?.role ? (
                                    rolesDictionary[contact.role]
                                ) : (
                                    ''
                                )
                            ) : (
                                <Skeleton width={75} variant="text" />
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            gap: 1,
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                            justifyItems: 'flex-start',
                            paddingBlock: 2,
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                gap: 1,
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    size="small"
                                    color="inherit"
                                    disabled={isLoading || !contact?.email}
                                    href={`mailto:${contact?.email}`}
                                    startIcon={<EmailIcon />}
                                    data-test-id="ContactPage-EmailButton"
                                >
                                    <Typography
                                        fontSize={13}
                                        data-test-id="ContactPage-EmailLabel"
                                        color={
                                            contact?.email
                                                ? COLOR.ebonyClay
                                                : COLOR.regentGray
                                        }
                                    >
                                        {!isLoading ? (
                                            contact?.email ? (
                                                contact?.email
                                            ) : (
                                                t('Contact:no_email')
                                            )
                                        ) : (
                                            <Skeleton
                                                width={120}
                                                variant="text"
                                            />
                                        )}
                                    </Typography>
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    size="small"
                                    color="inherit"
                                    disabled={isLoading || !contact?.phone}
                                    href={`tel:${contact?.phone}`}
                                    startIcon={<CallIcon />}
                                    data-test-id="ContactPage-CallButton"
                                >
                                    <Typography
                                        fontSize={13}
                                        data-test-id="ContactPage-PhoneLabel"
                                        color={
                                            contact?.phone
                                                ? COLOR.ebonyClay
                                                : COLOR.regentGray
                                        }
                                    >
                                        {!isLoading ? (
                                            contactPhoneNumber
                                        ) : (
                                            <Skeleton
                                                width={80}
                                                variant="text"
                                            />
                                        )}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 2 }}>
                            <Button
                                size="small"
                                color="inherit"
                                disabled={isLoading || !contact?.address}
                                startIcon={<LocationOnIcon />}
                                target="_blank"
                                href={`http://maps.google.com/?q=${contact?.address}`}
                                rel="noopener noreferrer"
                                data-test-id="ContactPage-LocationButton"
                            >
                                <Typography
                                    data-test-id="ContactPage-LocationLabel"
                                    fontSize={13}
                                    color={
                                        contact?.address
                                            ? COLOR.ebonyClay
                                            : COLOR.regentGray
                                    }
                                >
                                    {!isLoading ? (
                                        contact?.address ? (
                                            contact?.address
                                        ) : (
                                            t('Contact:no_address')
                                        )
                                    ) : (
                                        <Skeleton width={200} variant="text" />
                                    )}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {imageToPreview ? (
                <ImagePreview close={closeImagePreview} url={imageToPreview} />
            ) : null}
        </>
    );
};

export default HeaderSection;
