import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import clsx from 'clsx';
import { t } from 'i18next';
import moment from 'moment';
import React, { useMemo } from 'react';

import { CalendarEventIcon } from '@/assets/svg';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import { getAddToCalendarDateProps } from '../../getAddToCalendarProps';
import { INVITE_STATUS } from '../../useExternalAppointmentPage';
import AttendeesSection from '../AttendeesSection';
import HorsesSection from '../HorsesSection';
import NotesSection from '../NotesSection';

import { Props } from './types';

function MobileView({
    appointmentDetails,
    status,
    showCancellationModal,
    hideCancellationModal,
    isLoading,
    cancelAppointment,
    isAppointmentCancelled,
}: Props) {
    const isDeclinedOrCancelled =
        isAppointmentCancelled || status === INVITE_STATUS.REJECTED;
    const isExpired = status === INVITE_STATUS.EXPIRED;

    const lastUpdatedLabel = useMemo(() => {
        return t('ExternalAppointment:labels:last_update', {
            date: formatDate(
                moment(appointmentDetails?.updatedAt).toISOString(),
                'datetime',
            ),
        });
    }, [appointmentDetails?.updatedAt]);

    const cancellationContent = useMemo(() => {
        return (
            <div className="md:hidden h-full flex flex-col">
                <div className=" px-6 pt-6 pb-4 bg-gandalfGrey">
                    <div
                        className="flex flex-row items-center gap-1 py-1 border-b-[1px] border-borderGrey"
                        onClick={hideCancellationModal}
                    >
                        <ArrowBackIcon sx={{ color: COLOR.black }} />
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '24px',
                                color: COLOR.black,
                            }}
                        >
                            {t(
                                'ExternalAppointment:actions:cancel_appointment',
                            )}
                        </Typography>
                    </div>
                    <div
                        className={clsx('mt-5 w-full border-md', {
                            'bg-stateYellow': isDeclinedOrCancelled,
                        })}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: isDeclinedOrCancelled
                                    ? '700'
                                    : '300',
                                lineHeight: '26px',
                                color: COLOR.ebonyClay,
                                p: 1,
                            }}
                        >
                            {t(
                                isDeclinedOrCancelled
                                    ? 'ExternalAppointment:actions:cancelled_disclaimer'
                                    : 'ExternalAppointment:actions:cancel_disclaimer',
                            )}
                        </Typography>
                    </div>
                    <div className="flex flex-row w-full pt-5 pb-2">
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={cancelAppointment}
                            className="py-4 w-full"
                            disabled={isLoading || isDeclinedOrCancelled}
                            sx={{
                                background:
                                    status !== INVITE_STATUS.REJECTED
                                        ? `linear-gradient(${COLOR.cancelRed}, ${COLOR.cancelRedLight})`
                                        : `linear-gradient(${COLOR.cancelRed20}, ${COLOR.cancelRedLight20})`,
                            }}
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={10}
                                    sx={{ color: 'inherit' }}
                                />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '19.5px',
                                        color: COLOR.white,
                                    }}
                                >
                                    {t(
                                        isDeclinedOrCancelled
                                            ? 'ExternalAppointment:actions:cancelled_appointment'
                                            : 'ExternalAppointment:actions:cancel_appointment',
                                    )}
                                </Typography>
                            )}
                        </Button>
                    </div>
                </div>
                <Box
                    className="px-6 pt-6 pb-4 bg-white"
                    sx={{
                        background: `linear-gradient(180deg, ${COLOR.amazingWhiterGrey}, ${COLOR.white}, ${COLOR.amazingWhiterGrey})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: COLOR.gameboyGrey,
                        }}
                    >
                        {t('ExternalAppointment:labels:appointment_details')}
                    </Typography>
                    <div className="flex flex-row items-center gap-2 pt-2">
                        {appointmentDetails?.category === 'service' ? (
                            <HardwareIcon
                                sx={{
                                    color: isDeclinedOrCancelled
                                        ? COLOR.cancelledGrey
                                        : undefined,
                                }}
                            />
                        ) : (
                            <CalendarEventIcon
                                width={26}
                                height={28}
                                color={
                                    isDeclinedOrCancelled
                                        ? COLOR.cancelledGrey
                                        : undefined
                                }
                            />
                        )}

                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '700',
                                lineHeight: '21px',
                                color: isDeclinedOrCancelled
                                    ? COLOR.cancelledGrey
                                    : COLOR.desktopTitleBlack,
                            }}
                        >
                            {appointmentDetails?.title}
                        </Typography>
                    </div>
                    <div className="pt-4">
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: '700',
                                lineHeight: '15px',
                                color: COLOR.coolestGrey,
                                textDecorationLine: isDeclinedOrCancelled
                                    ? 'line-through'
                                    : undefined,
                            }}
                        >
                            {appointmentDetails?.date}
                        </Typography>
                    </div>
                    <div className="pt-2">
                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: '700',
                                lineHeight: '14px',
                                color: COLOR.coolestGrey,
                                textDecorationLine: isDeclinedOrCancelled
                                    ? 'line-through'
                                    : undefined,
                            }}
                        >
                            {appointmentDetails?.time}
                        </Typography>
                    </div>
                </Box>
            </div>
        );
    }, [
        appointmentDetails?.category,
        appointmentDetails?.date,
        appointmentDetails?.time,
        appointmentDetails?.title,
        cancelAppointment,
        hideCancellationModal,
        isDeclinedOrCancelled,
        isLoading,
        status,
    ]);

    const normalContent = useMemo(() => {
        return (
            <Box
                className="md:hidden h-full flex flex-col flex-1"
                sx={{
                    background: `linear-gradient(180deg, ${COLOR.amazingWhiterGrey}, ${COLOR.white}, ${COLOR.amazingWhiterGrey})`,
                }}
            >
                <Box
                    className={clsx('px-6 pt-6 pb-4', {
                        'opacity-50': isExpired,
                    })}
                >
                    <div className="flex flex-row items-center gap-2">
                        {appointmentDetails?.category === 'service' ? (
                            <HardwareIcon
                                sx={{
                                    color: isAppointmentCancelled
                                        ? COLOR.cancelledGrey
                                        : undefined,
                                }}
                            />
                        ) : (
                            <CalendarEventIcon
                                width={26}
                                height={28}
                                color={
                                    isAppointmentCancelled
                                        ? COLOR.cancelledGrey
                                        : undefined
                                }
                            />
                        )}
                        <Typography
                            sx={{
                                fontSize: '28px',
                                fontWeight: '700',
                                lineHeight: '33px',
                                color: isAppointmentCancelled
                                    ? COLOR.cancelledGrey
                                    : undefined,
                            }}
                        >
                            {appointmentDetails?.title}
                        </Typography>
                    </div>
                    <div className="pt-2.5">
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '700',
                                lineHeight: '19.5px',
                                color: COLOR.coolestGrey,
                                textDecorationLine: isAppointmentCancelled
                                    ? 'line-through'
                                    : undefined,
                            }}
                        >
                            {appointmentDetails?.date}
                        </Typography>
                    </div>
                    <div className="pt-2">
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '700',
                                lineHeight: '19.5px',
                                color: COLOR.coolestGrey,
                                textDecorationLine: isAppointmentCancelled
                                    ? 'line-through'
                                    : undefined,
                            }}
                        >
                            {appointmentDetails?.time}
                        </Typography>
                    </div>
                    {appointmentDetails?.address ? (
                        <div className="flex flex-row py-5">
                            <LocationOnIcon />
                            <Typography
                                sx={{
                                    maxWidth: '265px',
                                    fontSize: '14px',
                                    fontWeight: '300',
                                    lineHeight: '20px',
                                    color: COLOR.paleSky,
                                    textDecorationLine: isAppointmentCancelled
                                        ? 'line-through'
                                        : undefined,
                                }}
                            >
                                {appointmentDetails?.address}
                            </Typography>
                        </div>
                    ) : null}
                </Box>
                {status !== INVITE_STATUS.REJECTED && !isExpired ? (
                    <div className="flex flex-row justify-center items-center h-[72px] w-full">
                        <AddToCalendarButton
                            name={appointmentDetails?.title || ''}
                            description={appointmentDetails?.notes || ''}
                            {...getAddToCalendarDateProps(
                                appointmentDetails?.starts_at,
                                appointmentDetails?.ends_at,
                            )}
                            location={appointmentDetails?.address || ''}
                            organizer={`${appointmentDetails?.author.name}|${appointmentDetails?.author.email}`}
                            status="CONFIRMED"
                            options={[
                                'Apple',
                                'Google',
                                'iCal',
                                'Outlook.com',
                                'Yahoo',
                            ]}
                            buttonStyle="text"
                            trigger="click"
                            hideBackground
                            styleLight="--btn-border-top: #2f4377"
                        />
                    </div>
                ) : null}
                <div className="px-6 pt-6 pb-4 bg-gandalfGrey flex-1">
                    <div>
                        {isAppointmentCancelled ? (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '24px',
                                    color: COLOR.battleRed,
                                }}
                            >
                                {t(
                                    'ExternalAppointment:labels:cancelled_appointment',
                                )}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    lineHeight: '24px',
                                    color: COLOR.gameboyGrey,
                                }}
                            >
                                {appointmentDetails?.category === 'service'
                                    ? t(
                                          'ExternalAppointment:labels:service_appointment',
                                      )
                                    : t(
                                          'ExternalAppointment:labels:appointment',
                                      )}
                            </Typography>
                        )}
                        <div className="pt-3">
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '24px',
                                    color: COLOR.gameboyGrey,
                                }}
                            >
                                {appointmentDetails?.author.name}
                                {appointmentDetails?.author.business_name
                                    ? ` (${appointmentDetails.author.business_name})`
                                    : null}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    color: COLOR.gameboyGrey,
                                }}
                            >
                                {lastUpdatedLabel}
                            </Typography>
                        </div>
                    </div>
                    {appointmentDetails?.notes ? (
                        <NotesSection
                            notes={appointmentDetails.notes}
                            lineThrough={isAppointmentCancelled}
                        />
                    ) : null}
                    {appointmentDetails?.attendees ? (
                        <AttendeesSection
                            attendees={appointmentDetails.attendees}
                            isCancelled={isAppointmentCancelled}
                        />
                    ) : null}

                    {appointmentDetails?.horses ? (
                        <HorsesSection
                            horses={appointmentDetails.horses}
                            isCancelled={isAppointmentCancelled}
                        />
                    ) : null}
                </div>
            </Box>
        );
    }, [
        appointmentDetails?.address,
        appointmentDetails?.attendees,
        appointmentDetails?.author.business_name,
        appointmentDetails?.author.email,
        appointmentDetails?.author.name,
        appointmentDetails?.category,
        appointmentDetails?.date,
        appointmentDetails?.horses,
        appointmentDetails?.notes,
        appointmentDetails?.time,
        appointmentDetails?.title,
        appointmentDetails?.ends_at,
        appointmentDetails?.starts_at,
        isAppointmentCancelled,
        lastUpdatedLabel,
        status,
        isExpired,
    ]);

    return <>{showCancellationModal ? cancellationContent : normalContent}</>;
}

export default MobileView;
