import { Box, Typography } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useMemo } from 'react';
import { getNowISO } from 'shared/utils/date';

import { HorseAvatar } from '@/assets/svg';
import { SelectableListItem } from '@/components';
import { renderTableItemContactsText } from '@/helpers/contacts/data';
import { useFormatDate } from '@/hooks';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function HorsesListItem({
    avatarUrl,
    clickable,
    contacts,
    futureEntries,
    horse,
    isSelected,
    onClick,
    onRemove,
    pastEntries,
    removable,
    selectable,
    showContacts,
    showEntryDate,
    wrapperStyles,
    size,
    testID,
    multiSelect,
}: Props) {
    const formatDate = useFormatDate();

    const horseContactsTextElement = useMemo(() => {
        if (!contacts?.length) {
            return '';
        }

        return renderTableItemContactsText(contacts, t, {
            className: 'mt-0.5',
            fontSize: 14,
            fontWeight: 400,
            props: { color: COLOR.regentGray, flex: 1, noWrap: true },
            testId: `${testID}-ContactsText`,
        });
    }, [contacts, testID]);

    const entryDateText = useMemo(() => {
        if (!showEntryDate) {
            return '';
        }

        const [nearestEntry] = futureEntries || [];
        const [recentEntry] = pastEntries || [];

        if (nearestEntry) {
            return formatDate(nearestEntry.loggedTime, 'date');
        }

        if (recentEntry) {
            return formatDate(recentEntry.loggedTime, 'date');
        }
    }, [formatDate, futureEntries, pastEntries, showEntryDate]);

    const renderEntryDate = useCallback(
        () => (
            <Box className="min-w-0 text-right pr-4" flex={4}>
                <Typography color={COLOR.paleSky} variant="caption">
                    {entryDateText}
                </Typography>
            </Box>
        ),
        [entryDateText],
    );

    return (
        <SelectableListItem
            avatarUrl={avatarUrl}
            avatarVariant="circular"
            clickable={clickable}
            placeholder={<HorseAvatar color={COLOR.mischka} />}
            isSelected={isSelected}
            item={horse}
            onClick={onClick}
            onRemove={onRemove}
            removable={removable}
            renderExtraInfo={renderEntryDate}
            selectable={selectable}
            showExtraInfo={showEntryDate && !!entryDateText}
            avatarSize={size}
            testID={`${testID}-${horse.name}`}
            wrapperStyles={wrapperStyles}
            multiSelect={multiSelect}
        >
            <Typography
                flex={1}
                fontWeight={700}
                noWrap
                data-test-id={`${testID}-Name`}
            >
                {horse.name}
            </Typography>
            {showContacts && horseContactsTextElement
                ? horseContactsTextElement
                : null}
        </SelectableListItem>
    );
}

const enhance = withObservables<Props, unknown>(
    ['horse'],
    ({ horse }: Props) => {
        const now = getNowISO();

        return {
            contacts: horse.contacts.extend(Q.sortBy('last_name', 'asc')),
            pastEntries: horse.entries.extend(
                Q.where('logged_time', Q.lt(now)),
                Q.sortBy('logged_time', 'desc'),
            ),
            futureEntries: horse.entries.extend(
                Q.where('logged_time', Q.gt(now)),
                Q.sortBy('logged_time', 'asc'),
            ),
        };
    },
);

export default enhance(HorsesListItem);
