import { NavigateAction, View } from 'react-big-calendar';

export type Props = {
    onView: (view: View) => void;
    view: View;
    onNavigate: (navigate: NavigateAction, date?: Date) => void;
    date: Date;
    showAddEventModal: (flag: boolean) => void;
};

export enum CALENDAR_VIEW {
    agenda = 'agenda',
    day = 'day',
    week = 'week',
    month = 'month',
}
