import {
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateQuantityValue } from 'shared/utils/validation';

import { Props } from './types';

function QuantityEditingContent({
    closeModal,
    onCancelButtonClick,
    onSaveButtonClick,
    procedure,
}: Props) {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [procedureQuantity, setProcedureQuantity] = useState(
        procedure?.quantity.toString() || '1',
    );

    const handleProcedureQuantityChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setProcedureQuantity(event.target.value);
        },
        [],
    );

    const handleSaveButtonClick = useCallback(async () => {
        if (!procedure) {
            return;
        }

        try {
            await validateQuantityValue(procedureQuantity, t);

            onSaveButtonClick(procedure.procedureId, procedureQuantity);
            closeModal();
        } catch (error) {
            setError(error);
        }
    }, [closeModal, onSaveButtonClick, procedure, procedureQuantity, t]);

    return (
        <>
            <DialogTitle>
                {t('Procedure:update_quantity:title', {
                    procedure: procedure?.name || '',
                })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText data-test-id="AlertText">
                    {t('Procedure:update_quantity:explanation')}
                </DialogContentText>
                <TextField
                    data-test-id="EditProcedureDataModal-QuantityEditing-QuantityInput"
                    error={!!error}
                    fullWidth
                    helperText={error || ''}
                    id="quantity"
                    label={t('Procedure:label:quantity')}
                    onChange={handleProcedureQuantityChange}
                    name="quantity"
                    sx={{ mt: 3 }}
                    value={procedureQuantity}
                />
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button
                    onClick={onCancelButtonClick}
                    data-test-id="EditProcedureDataModal-QuantityEditing-CancelButton"
                >
                    {t('Actions:cancel')}
                </Button>
                <Button
                    color="primary"
                    data-test-id="EditProcedureDataModal-QuantityEditing-SaveButton"
                    onClick={handleSaveButtonClick}
                >
                    {t('Actions:save')}
                </Button>
            </DialogActions>
        </>
    );
}

export default QuantityEditingContent;
