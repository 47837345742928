import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function UpdateExistingEntriesAlert({
    isOpen,
    onConfirm,
    onClose,
    testID,
}: Props) {
    const { t } = useTranslation();

    const handleUpdateClick = useCallback(() => {
        onClose();
        onConfirm(true);
    }, [onClose, onConfirm]);

    const handleDontUpdateClick = useCallback(() => {
        onClose();
        onConfirm(false);
    }, [onClose, onConfirm]);

    return (
        <Dialog open={isOpen} data-test-id={testID}>
            <DialogTitle>{t('Actions:confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('Procedure:alert:update_existing_entries:description')}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Box>
                    <Button onClick={onClose}>{t('Actions:cancel')}</Button>
                </Box>
                <Box>
                    <Button
                        onClick={handleDontUpdateClick}
                        data-test-id="DontUpdateButton"
                    >
                        {t(
                            'Procedure:alert:update_existing_entries:button:dont_update',
                        )}
                    </Button>
                    <Button
                        onClick={handleUpdateClick}
                        color="primary"
                        autoFocus
                        data-test-id="UpdateButton"
                    >
                        {t(
                            'Procedure:alert:update_existing_entries:button:update',
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateExistingEntriesAlert;
