import { Box } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { PropsWithChildren } from 'react';
import { Transition } from 'react-transition-group';

const transitionStyles: Record<string, React.CSSProperties> = {
    entering: { opacity: 0, transform: 'translateY(50px)' },
    entered: { opacity: 1 },
    exiting: {
        opacity: 0,
        transform: 'translateY(-50px)',
        position: 'absolute',
    },
    exited: {
        opacity: 0,
        transform: 'translateY(-50px)',
    },
};

function IntroductionTransition({
    children,
    ...rest
}: PropsWithChildren<TransitionProps>) {
    return (
        <Transition {...rest} timeout={600} mountOnEnter>
            {(state) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                    style={{
                        transition: 'all 0.6s ease-out',
                        ...transitionStyles[state],
                    }}
                >
                    {children}
                </Box>
            )}
        </Transition>
    );
}

export default IntroductionTransition;
