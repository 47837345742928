import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
    APPLE_APP_STORE_APP_URL,
    GOOGLE_PLAY_STORE_APP_URL,
} from 'shared/constants/externalUrls';
import { getAppStoreAppUrl } from 'shared/utils/externalUrls';

import { OPERATING_SYSTEM } from '@/constants/device';
import { getMobileOperatingSystem } from '@/utils/device';

const MOBILE_OS = getMobileOperatingSystem();

function GetMobileAppCallbackPage() {
    const { t } = useTranslation();
    const [params] = useSearchParams();
    const userRegionCode = params.get('region');

    useEffect(() => {
        const appStoreUrl = userRegionCode
            ? getAppStoreAppUrl(userRegionCode)
            : APPLE_APP_STORE_APP_URL;
        const url =
            MOBILE_OS === OPERATING_SYSTEM.iOS
                ? appStoreUrl
                : GOOGLE_PLAY_STORE_APP_URL;

        window.location.replace(url);
    }, [userRegionCode]);

    return (
        <Typography>
            {t('GetMobileAppPage:text', {
                store:
                    MOBILE_OS === OPERATING_SYSTEM.iOS
                        ? 'App Store'
                        : 'Google Play',
            })}
        </Typography>
    );
}

export default GetMobileAppCallbackPage;
