import {
    GetInvitationDetailsParams,
    GetInvitationDetailsResponse,
} from '@/types/invitation';
import {
    DismissInvitationParams,
    OpenInvitationParams,
    OpenInvitationResponse,
    RemoveMemberParams,
    UpdateInvitationStatusParams,
} from '@/types/organisations';

import apiClient from './apiClient';

async function removeMember(params: RemoveMemberParams) {
    const { data } = await apiClient.post('/invitations/remove_member', params);

    return data;
}

async function openInvitation(params: OpenInvitationParams) {
    const { data } = await apiClient.post<OpenInvitationResponse>(
        '/invitations/open',
        params,
    );

    return data;
}

async function updateOwnerInvitationStatus(
    params: UpdateInvitationStatusParams,
) {
    const { data } = await apiClient.post(
        '/invitations/update_status_owner',
        params,
    );
    return data;
}

async function updateUserInvitationStatus(
    params: UpdateInvitationStatusParams,
) {
    const { data } = await apiClient.post(
        '/invitations/update_status_user',
        params,
    );
    return data;
}

async function getInvitationDetails(params: GetInvitationDetailsParams) {
    const { data } = await apiClient.get<GetInvitationDetailsResponse>(
        `/invitations/${params.invitationId}/organisation_details`,
    );

    return data;
}

async function dismissInvitation(params: DismissInvitationParams) {
    const { data } = await apiClient.post('/invitations/dismiss', params);

    return data;
}

export const OrganisationsInvitationsAPI = {
    removeMember,
    updateOwnerInvitationStatus,
    updateUserInvitationStatus,
    getInvitationDetails,
    dismissInvitation,
    openInvitation,
};
