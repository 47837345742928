import { useCallback, useEffect, useState } from 'react';
import { ContactModel } from 'shared/types/Contacts';
import { EVENT_CONTACT_STATUS } from 'shared/types/EventContact';
import { ContactRSVPStatus } from 'shared/types/Events';

import { ContactsToOverlay } from '@/types/appointments';

const INITIAL_STATE: ContactsToOverlay = {
    [EVENT_CONTACT_STATUS.AWAITING]: '',
    [EVENT_CONTACT_STATUS.REJECTED]: '',
};

function useRSVPOverlay(
    contacts: ContactModel[],
    rsvpStatus: ContactRSVPStatus,
) {
    const [contactsToOverlay, setContactsToOverlay] =
        useState<ContactsToOverlay>(INITIAL_STATE);

    const calculateContactsToOverlay = useCallback(() => {
        if (!contacts || !contacts.length) {
            return;
        }

        const visibleContacts =
            contacts.length > 5 ? [...contacts.slice(0, 5)] : [...contacts];

        const overlays: ContactsToOverlay = INITIAL_STATE;
        visibleContacts.forEach((contact) => {
            const contactStatus = rsvpStatus[contact.id];

            if (
                !contactStatus ||
                contactStatus === EVENT_CONTACT_STATUS.ACCEPTED
            ) {
                return;
            }

            if (!overlays[contactStatus]) {
                overlays[contactStatus] = contact.id;
            }
        });
        setContactsToOverlay(overlays);
    }, [contacts, rsvpStatus]);

    useEffect(() => {
        calculateContactsToOverlay();
    }, [calculateContactsToOverlay]);

    return { contactsToOverlay };
}

export default useRSVPOverlay;
