import Database from '@nozbe/watermelondb/Database';

import DBService from './DBService.web';

class DatabaseService {
    static instance: Database;

    static getDatabase() {
        if (!this.instance) {
            this.instance = DBService();
        }

        return this.instance;
    }
}

export default DatabaseService;
