// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class EventContactReminder extends Model {
    static table = 'event_contact_reminders';

    static associations = {
        events: { type: 'belongs_to', key: 'event_id' },
        event_contacts: { type: 'belongs_to', key: 'event_contact_id' },
    };

    @field('event_id') eventId;
    @field('time_before') timeBefore;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
    @field('event_contact_id') eventContactId;
}
