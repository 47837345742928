import { UserModel } from '../types/User';

export const decode = (encodedValue: string) => {
    return decodeURIComponent(encodedValue);
};

export const capitalizeFirstLetter = (str?: string | null) => {
    if (!str) {
        return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeWords = (sentence: string | null | undefined) => {
    if (!sentence) {
        return '';
    }

    try {
        const words = sentence.split(' ');

        return words
            .map((word) => {
                return word[0].toUpperCase() + word.substring(1).toLowerCase();
            })
            .join(' ');
    } catch {
        return sentence;
    }
};

export const toLowerCase = (str: string = ''): string => {
    try {
        return str.toLowerCase();
    } catch {
        return '';
    }
};

export const replaceAll = (
    str: string,
    searchValue: string,
    replaceWith: string,
) => {
    try {
        const regExp = new RegExp(searchValue, 'g');

        return str.replace(regExp, replaceWith);
    } catch {
        return str;
    }
};

export const stripSpecialChars = (
    str: string | null | undefined,
    replaceValue?: string,
): string => {
    try {
        if (!str) return '';

        return str.replace(/[^A-Z0-9]+/gi, replaceValue || '');
    } catch {
        return str || '';
    }
};

export const isEmail = (str: string) => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(str);
};

export const extractInitialsFromUser = (user: UserModel) => {
    const { firstName = '', lastName = '' } = user;

    const firstNameInitial = firstName?.charAt(0).toUpperCase();
    const lastNameInitial = lastName?.charAt(0).toUpperCase();

    return `${firstNameInitial}${lastNameInitial}`;
};

export function normalizeStringFromDB(
    string: string,
    capitalize: 'allWords' | 'firstWord',
) {
    try {
        let tempString = string;

        tempString = replaceAll(tempString, '_', ' ');

        const capitalizeCallbackFn =
            capitalize === 'allWords' ? capitalizeWords : capitalizeFirstLetter;

        tempString = capitalizeCallbackFn(tempString);

        return tempString;
    } catch {
        return string;
    }
}

export function getInitials(string: string, wordsLimit?: number): string {
    let wordsArray = string.split(' ');

    if (wordsLimit) {
        wordsArray = wordsArray.slice(0, wordsLimit);
    }

    const initials = wordsArray.map((word) => word[0]).join('');

    return initials.toUpperCase();
}
