import React, { memo } from 'react';

import { TopPartSection } from './components';
import { Props } from './types';

function SidebarCompactVersion({ visibleLinks, hiddenLinks }: Props) {
    return (
        <>
            <TopPartSection
                hiddenLinks={hiddenLinks}
                visibleLinks={visibleLinks}
            />
        </>
    );
}

export default memo(SidebarCompactVersion);
