import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@/components';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

function RSVPBannerOverlay() {
    const { t } = useTranslation();
    const { dismissOverlay } = useRSVPOverlayContext();

    return (
        <Modal isOpen styles="bg-transparent shadow-none items-center">
            <Box
                sx={{
                    px: 5.375,
                    pt: 5.375,
                    pb: 3.375,
                    mt: 8,
                    background: 'linear-gradient(#E9ECF0, #FFFFFF)',
                    borderRadius: '1rem',
                    boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    maxWidth: '500px',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 2,
                        height: 20,
                        backgroundColor: ENTITY_COLOR.schedule,
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: ENTITY_COLOR.schedule,
                        top: 20,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontWeight: '700',
                        color: ENTITY_COLOR.schedule,
                        lineHeight: '20px',
                    }}
                >
                    {t('Event:rsvp:overlay:banner:title')}
                </Typography>
                <Typography
                    sx={{
                        mt: 2,
                        wordWrap: 'break-word',
                        width: '350px',
                        fontSize: '14px',
                        fontWeight: '300',
                        textAlign: 'center',
                        lineHeight: '20px',
                        color: '#121212',
                    }}
                >
                    {t('Event:rsvp:overlay:banner:disclaimer')}
                </Typography>
                <Button
                    caption={t('Event:onboarding:continue')}
                    onClick={() => dismissOverlay('banner')}
                    variant="contained"
                    sx={{
                        mt: 3,
                        bgcolor: ENTITY_COLOR.schedule,
                        color: COLOR.white,
                        alignSelf: 'flex-end',
                        '&:hover': {
                            bgcolor: ENTITY_COLOR.schedule,
                            filter: 'brightness(1.1)',
                        },
                    }}
                />
            </Box>
        </Modal>
    );
}

export default memo(RSVPBannerOverlay);
