import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntryProcedureToAdd } from 'shared/types/Entries';

import { SelectionOutlinedBox } from '@/components';
import SelectableProceduresGroupedList from '@/components/SelectableProceduresGroupedList';
import { Snackbar } from '@/services/toastNotifications';
import { COLOR } from '@/theme/colors';

import { EditProcedureDataModal, SelectProceduresModal } from './components';
import { Props } from './types';

function SelectEntryProceduresSection({
    onRemoveProcedure,
    onSavePriceButtonClick,
    onSaveQuantityButtonClick,
    onSelectProcedures,
    selectedProcedures,
    testIdPrefix,
    allowToInteract = true,
    editable = true,
    showPrice,
}: Props) {
    const { t } = useTranslation();

    const [editedProcedure, setEditedProcedure] =
        useState<EntryProcedureToAdd | null>(null);
    const [isEditProcedureDataModalOpen, setIsEditProcedureDataModalOpen] =
        useState(false);
    const [isSelectProceduresModalOpen, setIsSelectProceduresModalOpen] =
        useState(false);

    const openEditProcedureDataModal = useCallback(() => {
        setIsEditProcedureDataModalOpen(true);
    }, []);

    const closeEditProcedureDataModal = useCallback(() => {
        setIsEditProcedureDataModalOpen(false);
    }, []);

    const openSelectProceduresModal = useCallback(() => {
        if (allowToInteract) {
            setIsSelectProceduresModalOpen(true);
        } else {
            Snackbar.showToastNotification({
                message: t('Entry:alert:add_procedure_when_invoiced'),
                options: {
                    variant: 'info',
                },
            });
        }
    }, [allowToInteract, t]);

    const closeSelectProceduresModal = useCallback(() => {
        setIsSelectProceduresModalOpen(false);
    }, []);

    const handleProcedureItemClick = useCallback(
        (procedure: EntryProcedureToAdd) => {
            if (allowToInteract) {
                setEditedProcedure(procedure);
                openEditProcedureDataModal();
            } else {
                Snackbar.showToastNotification({
                    message: t('Entry:alert:change_procedure_when_invoiced'),
                    options: {
                        variant: 'info',
                    },
                });
            }
        },
        [allowToInteract, openEditProcedureDataModal, t],
    );

    const handleRemoveProcedure = useCallback(
        (procedure: EntryProcedureToAdd) => {
            if (allowToInteract) {
                onRemoveProcedure(procedure);
            } else {
                Snackbar.showToastNotification({
                    message: t('Entry:alert:remove_procedure_when_invoiced'),
                    options: {
                        variant: 'info',
                    },
                });
            }
        },
        [allowToInteract, onRemoveProcedure, t],
    );

    return (
        <SelectionOutlinedBox
            title={t('ProceduresSelection:select')}
            onSelect={openSelectProceduresModal}
            selectLabel={t('ProceduresSelection:select_button')}
            SelectIcon={Add}
            buttonsPosition="bottom"
            selectButtonTestId={`${testIdPrefix}-SelectProceduresButton`}
            allowCreate={false}
        >
            {!selectedProcedures.length ? (
                <Typography
                    sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                    color={COLOR.regentGray}
                    variant="body2"
                    data-test-id={`${testIdPrefix}-SelectEntryProceduresSection-EmptyMessage`}
                >
                    {t('AddEntryForm:procedures_blankslate')}
                </Typography>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: '100%',
                    }}
                >
                    <SelectableProceduresGroupedList
                        editable={editable}
                        testIdPrefix={testIdPrefix}
                        items={selectedProcedures}
                        onEditButtonClick={handleProcedureItemClick}
                        onRemove={handleRemoveProcedure}
                        removable
                        itemWrapperStyles="px-4 rounded-lg"
                        backgroundColor="lightBlue"
                        itemHeight="14"
                        showTotalPrice
                        showPrice={showPrice}
                    />
                </Box>
            )}
            <EditProcedureDataModal
                close={closeEditProcedureDataModal}
                isOpen={isEditProcedureDataModalOpen}
                onSavePriceButtonClick={onSavePriceButtonClick}
                onSaveQuantityButtonClick={onSaveQuantityButtonClick}
                procedure={editedProcedure}
                allowToEditPrice={showPrice}
            />
            <SelectProceduresModal
                close={closeSelectProceduresModal}
                isOpen={isSelectProceduresModalOpen}
                onSelectProcedures={onSelectProcedures}
                selectedProcedures={selectedProcedures}
                showPrice={showPrice}
            />
        </SelectionOutlinedBox>
    );
}

export default SelectEntryProceduresSection;
