import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback } from 'react';

import DeleteIcon from '@/assets/svg/DeleteIcon/DeleteIcon';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function AccountSetupItem({
    item,
    onClick,
    wrapperStyle,
    onDeleteItemClick,
}: Props) {
    const { isSyncInProgress } = useDBSyncContext();

    const handleItemClick = useCallback(() => onClick(item), [item, onClick]);

    const handleDeleteClick = useCallback(
        () => onDeleteItemClick(item),
        [item, onDeleteItemClick],
    );

    return (
        <Box
            className={`flex flex-row gap-1 p-2 mb-3 rounded-md cursor-pointer ${wrapperStyle}`}
            sx={{ backgroundColor: COLOR.accountSetupBlue }}
            onClick={handleItemClick}
        >
            <Box
                sx={{
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {<item.icon color={item.iconPrimaryColor} />}
            </Box>

            <Box className="flex grow flex-col gap-1">
                <Typography
                    sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: COLOR.deepCerulean,
                    }}
                >
                    {t(item.title)}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        color: COLOR.accountSetupBlack,
                    }}
                >
                    {t(item.description)}
                </Typography>
            </Box>
            <Box
                sx={{
                    '&:hover': {
                        cursor: isSyncInProgress ? 'auto' : 'pointer',
                    },
                    opacity: isSyncInProgress ? 0.5 : 1,
                }}
                onClick={!isSyncInProgress ? handleDeleteClick : undefined}
            >
                <DeleteIcon width={20} height={20} />
            </Box>
        </Box>
    );
}

export default AccountSetupItem;
