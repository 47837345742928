import { Box } from '@mui/material';
import React, { memo } from 'react';

import {
    ContactsFilterSection,
    DatesFilterSection,
    GeneralFiltersSection,
} from './components';
import { Props } from './types';

function FiltersPanel({
    filtersState,
    onFiltersChange,
    setTempDateRangeFilterState,
    tempDateRangeFilterState,
}: Props) {
    return (
        <Box
            className="bg-gray-50 px-4 pt-8 pb-4"
            data-test-id="EntriesPage-FiltersPanel"
        >
            <GeneralFiltersSection
                filtersState={filtersState}
                onFiltersChange={onFiltersChange}
            />
            <DatesFilterSection
                filtersState={filtersState}
                onFiltersChange={onFiltersChange}
                setTempDateRangeFilterState={setTempDateRangeFilterState}
                tempDateRangeFilterState={tempDateRangeFilterState}
            />
            <ContactsFilterSection
                filtersState={filtersState}
                onFiltersChange={onFiltersChange}
            />
        </Box>
    );
}

export default memo(FiltersPanel);
