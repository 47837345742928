import { Box, Modal } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { COLOR } from '@/theme/colors';

import {
    IntroductionAnimation,
    Carousel,
    IntroductionQuestions,
} from './components';
import { ONBOARDING_STEP, Props } from './types';

function IntroductionModal({ close, firstName, isSyncInProgress }: Props) {
    const [animation, setAnimation] = useState<'first' | 'second' | undefined>(
        undefined,
    );
    const [introductionStep, setIntroductionStep] = useState<ONBOARDING_STEP>(
        ONBOARDING_STEP.ANIMATION,
    );

    useEffect(() => {
        setTimeout(() => setAnimation('first'), 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => setAnimation('second'), 3000);
    }, []);

    const handleContinueToCarousel = useCallback(() => {
        setIntroductionStep(ONBOARDING_STEP.CAROUSEL);
    }, []);

    const handleContinueToQuestions = useCallback(() => {
        setIntroductionStep(ONBOARDING_STEP.QUESTIONS);
    }, []);

    const currentDisplay = useMemo(() => {
        switch (introductionStep) {
            case ONBOARDING_STEP.ANIMATION:
                return (
                    <IntroductionAnimation
                        animation={animation}
                        firstName={firstName}
                        onContinue={handleContinueToCarousel}
                    />
                );
            case ONBOARDING_STEP.CAROUSEL:
                return <Carousel onContinue={handleContinueToQuestions} />;
            case ONBOARDING_STEP.QUESTIONS:
                return (
                    <IntroductionQuestions
                        close={close}
                        isSyncInProgress={isSyncInProgress}
                    />
                );
        }
    }, [
        animation,
        close,
        firstName,
        handleContinueToCarousel,
        handleContinueToQuestions,
        introductionStep,
        isSyncInProgress,
    ]);

    return (
        <Modal
            open
            sx={{
                overflowY: 'scroll',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    backgroundColor: COLOR.white,
                    borderRadius: '8px',
                    boxShadow: '3px 3px 20px #0000005D',
                    width: '308px',
                    height: '480px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {currentDisplay}
            </Box>
        </Modal>
    );
}

export default IntroductionModal;
