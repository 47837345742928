import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleContainer } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const emptyStateTextStyles = {
    color: COLOR.paleSky,
    fontStyle: 'italic',
    fontWeight: 300,
};

function NotesSection({
    comments,
    isLoading,
    containerTestId,
    textTestId,
}: Props) {
    const { t } = useTranslation();

    return (
        <CollapsibleContainer
            testID={containerTestId}
            title={t('Contact:section:notes')}
        >
            {isLoading ? (
                <Skeleton width="100%" height={76} />
            ) : (
                <Typography
                    data-test-id={textTestId}
                    sx={!comments ? emptyStateTextStyles : undefined}
                >
                    {comments ? comments : t('Contact:no_notes')}
                </Typography>
            )}
        </CollapsibleContainer>
    );
}

export default NotesSection;
