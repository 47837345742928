import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Database from 'shared/db/services/Database.web';
import Horse from 'shared/db/services/Horse';
import { HorseModel } from 'shared/types/Horses';
import { DBWriterLoggerFunction } from 'shared/types/logger';

import {
    AddEditContactModal,
    AddEditHorseModal,
    BackLinkButton,
    ErrorState,
} from '@/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { ROUTE } from '@/router/routes';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';

import { HorseDetails } from './components';

function HorsePage() {
    const { id } = useParams();

    const { isSyncInProgress } = useDBSyncContext();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();

    const navigate = useNavigate();

    const [horse, setHorse] = useState<HorseModel | null>(null);
    const [isEditHorseModalOpen, setIsEditHorseModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchHorse = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);

        try {
            const database = Database.getDatabase();

            const logDBActionMock: DBWriterLoggerFunction = () => {
                return;
            };

            const horseService = new Horse({
                database,
                imageService: ImagesService,
                logDBAction: logDBActionMock,
            });

            if (!id) {
                throw new Error();
            }

            const horse = await horseService.getByID(id || '');

            setHorse(horse);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [ImagesService, id]);

    const makeHorseHidden = useCallback(
        async (hide: boolean) => {
            try {
                const database = Database.getDatabase();

                const logDBActionMock: DBWriterLoggerFunction = () => {
                    return;
                };

                const horseService = new Horse({
                    database,
                    imageService: ImagesService,
                    logDBAction: logDBActionMock,
                });

                if (!horse?.id || !userProfileData?.id) {
                    throw new Error();
                }

                const updatedHorse = await horseService.update(
                    horse.id,
                    { hidden: hide },
                    userProfileData.id,
                );

                setHorse(updatedHorse);
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong_short'),
                    options: { variant: 'error' },
                });
            }
        },
        [ImagesService, horse?.id, userProfileData?.id],
    );

    const toggleHorseVisibility = useCallback(() => {
        if (!horse) {
            return;
        }

        makeHorseHidden(!horse.hidden);
    }, [horse, makeHorseHidden]);

    useEffect(() => {
        fetchHorse();
    }, [fetchHorse]);

    const openEditHorseModal = useCallback(() => {
        setIsEditHorseModalOpen(true);
    }, []);

    const closeEditHorseModal = useCallback(() => {
        setIsEditHorseModalOpen(false);
    }, []);

    const navigateToHorsesRoute = useCallback(
        () => navigate(ROUTE.horses, { replace: true }),
        [navigate],
    );

    const HideShowButtonIcon = horse?.hidden
        ? VisibilityIcon
        : VisibilityOffIcon;

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            {isEditHorseModalOpen ? (
                <AddEditHorseModal
                    close={closeEditHorseModal}
                    horse={horse}
                    isEditMode
                    CreateContactModal={AddEditContactModal}
                    onRemove={navigateToHorsesRoute}
                />
            ) : null}
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <BackLinkButton
                    text={t('HorseProfile:button:back')}
                    to={ROUTE.horses}
                    testID="HorsePage-BackButton"
                />
                {!isError ? (
                    <Box sx={{ display: 'flex', gap: 3.375 }}>
                        {horse ? (
                            <TooltipSyncButton
                                tooltip={t(
                                    'AddEditModalHeader:button_tooltip',
                                    {
                                        defaultValue:
                                            'Please wait for the synchronization to complete',
                                    },
                                )}
                                disabled={isSyncInProgress}
                                showTooltip={isSyncInProgress}
                                onClick={toggleHorseVisibility}
                                testID="HorsePage-HideShowButton"
                                sx={{ display: 'flex', gap: 1.5 }}
                                color="inherit"
                            >
                                <HideShowButtonIcon fontSize="small" />
                                <Typography fontSize="small" fontWeight={700}>
                                    {t(
                                        `HorseProfile:button:${
                                            horse?.hidden ? 'show' : 'hide'
                                        }`,
                                    )}
                                </Typography>
                            </TooltipSyncButton>
                        ) : null}
                        <Button
                            data-test-id="HorsePage-EditButton"
                            disabled={isLoading}
                            sx={{ display: 'flex', gap: 1.5 }}
                            color="inherit"
                            onClick={openEditHorseModal}
                            size="small"
                        >
                            <EditIcon />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('ShowHorse:buttons:edit_horse')}
                            </Typography>
                        </Button>
                    </Box>
                ) : null}
            </Box>
            {isError ? (
                <ErrorState
                    onRefreshClick={fetchHorse}
                    testID="HorsePage-ErrorState"
                />
            ) : (
                <HorseDetails horse={horse} isLoading={isLoading} />
            )}
        </Box>
    );
}

export default HorsePage;
