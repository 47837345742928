import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { monthLabel, weekDays } from 'shared/constants/date';
import moment from 'shared/utils/moment';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function WeekColumnHeader({ label, date }: Props) {
    const weekDate = useMemo(() => {
        const weeDayKey = label.split(' ')[1];
        return t(weekDays[weeDayKey]);
    }, [label]);

    const headerColor = useMemo(() => {
        const now = moment();
        const formattedDate = moment(date);
        const isToday = now.isSame(formattedDate, 'day');

        if (isToday) {
            return COLOR.deepCerulean;
        }

        const isFuture = formattedDate.diff(now);

        if (isFuture > 0) {
            return COLOR.black;
        } else {
            return COLOR.regentGray;
        }
    }, [date]);

    const dateNumber = useMemo(() => {
        const dayAsString = moment(date).format('D');

        const value = parseInt(dayAsString, 10);

        const month = moment(date).month();

        if (value === 1) {
            return `${value} ${t(monthLabel[month])}`;
        }
        return dayAsString;
    }, [date]);

    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: headerColor,
                    textAlign: 'start',
                }}
            >
                {weekDate}
            </Typography>
            <Typography
                sx={{
                    fontWeight: 700,
                    fontSize: 20,
                    color: headerColor,
                    textAlign: 'start',
                }}
            >
                {dateNumber}
            </Typography>
        </Box>
    );
}

export default WeekColumnHeader;
