import * as yup from 'yup';

import { EMAIL_REGEXP, PHONE_REGEXP_WITHOUT_PREFIX } from '@/constants/regex';
import { t } from '@/services/translations/config';

const addEditContactForm = yup.object().shape({
    firstName: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('SignUp:label:first_name'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:first_name'),
            }),
        ),
    email: yup
        .string()
        .matches(
            EMAIL_REGEXP,
            t('SignUp:error:email_must_be_valid_email_adresss'),
        ),

    phone: yup.object().shape({
        full: yup.string(),
        number: yup.string().when(['full', 'prefix'], {
            is: (full, prefix) => {
                return (
                    full?.length > 1 &&
                    (full?.length > prefix?.length || full !== prefix)
                );
            },
            then: (schema) => {
                return schema
                    .matches(
                        PHONE_REGEXP_WITHOUT_PREFIX,
                        t('ContactsList:error:phone_must_be_valid_phone'),
                    )
                    .required(
                        t('ContactsList:error:phone_must_be_valid_phone'),
                    );
            },
            otherwise: (schema) => {
                return schema.nullable(true);
            },
        }),
        prefix: yup.string(),
    }),
});

export const ContactValidationSchema = {
    addEditContactForm,
};
