import React, { memo } from 'react';

import { Props } from '@/assets/svg/CameraIcon/types';

function CameraIcon({ width = 40, height = 40, color }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 1024 1024">
            <path
                fill={color}
                d="M512 740.267q76.8 0 129.067-52.267t52.267-129.067q0-77.867-52.267-129.6t-129.067-51.733q-77.867 0-129.6 51.733t-51.733 129.6q0 76.8 51.733 129.067t129.6 52.267zM149.333 896q-25.6 0-44.8-19.2t-19.2-44.8v-547.2q0-24.533 19.2-44.267t44.8-19.733h156.8l77.867-92.8h256l77.867 92.8h156.8q24.533 0 44.267 19.733t19.733 44.267v547.2q0 25.6-19.733 44.8t-44.267 19.2h-725.333z"
            />
        </svg>
    );
}

export default memo(CameraIcon);
