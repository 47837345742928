import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HORSE_BREEDS_DICTIONARY } from 'shared/constants/horses/breeds';
import { HORSES_FILTER } from 'shared/constants/horses/filters';
import { HORSE_GENDERS_DICTIONARY } from 'shared/constants/horses/genders';

import { Select } from '@/components';
import {
    renderSelectedFilterValues,
    renderShoeingCycleFilterValues,
} from '@/helpers/filters';
import {
    getHorseBreedFilters,
    getHorseGenderFilters,
    getHorseShoeingCycleFilters,
} from '@/helpers/horses/filters';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function FiltersPanel({ filtersState, onFiltersChange }: Props) {
    const { t } = useTranslation();

    const breedFiltersOptions = useMemo(() => getHorseBreedFilters(), []);
    const genderFiltersOptions = useMemo(() => getHorseGenderFilters(), []);
    const shoeingCycleFiltersOptions = useMemo(
        () => getHorseShoeingCycleFilters(),
        [],
    );

    const isShowHiddenChecked = filtersState[HORSES_FILTER.show_hidden];

    const handleAgeInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            const setAgeFilterValue = onFiltersChange(HORSES_FILTER.age);

            setAgeFilterValue(value);
        },
        [onFiltersChange],
    );

    const handleShowHiddenSwitchChange = useCallback(() => {
        const previousValue = filtersState[HORSES_FILTER.show_hidden];

        const setShowHiddenFilterValue = onFiltersChange(
            HORSES_FILTER.show_hidden,
        );

        setShowHiddenFilterValue(!previousValue);
    }, [filtersState, onFiltersChange]);

    return (
        <Box
            data-test-id="HorsesPage-FiltersPanel"
            sx={{
                alignItems: 'center',
                backgroundColor: COLOR.athensGray,
                display: 'flex',
                flexWrap: 'wrap',
                px: 2,
                pt: 4,
                pb: 2,
            }}
        >
            <Box sx={{ mb: 2, mr: 3 }}>
                <Select
                    label={t('AddHorseForm:labels:breed')}
                    multiselect
                    onChange={onFiltersChange(HORSES_FILTER.breed)}
                    options={breedFiltersOptions}
                    renderSelectedValue={renderSelectedFilterValues(
                        HORSE_BREEDS_DICTIONARY,
                    )}
                    selectedValue={filtersState[HORSES_FILTER.breed]}
                    testID="HorsesPage-FiltersPanel-SelectBreed"
                />
            </Box>
            <Box sx={{ mb: 2, mr: 3 }}>
                <Select
                    label={t('AddHorseForm:labels:gender')}
                    multiselect
                    onChange={onFiltersChange(HORSES_FILTER.gender)}
                    options={genderFiltersOptions}
                    renderSelectedValue={renderSelectedFilterValues(
                        HORSE_GENDERS_DICTIONARY,
                    )}
                    selectedValue={filtersState[HORSES_FILTER.gender]}
                    testID="HorsesPage-FiltersPanel-SelectGender"
                />
            </Box>
            <Box sx={{ mb: 2, mr: 3 }}>
                <Select
                    label={t('AddHorseForm:labels:shoeingCycle')}
                    multiselect
                    onChange={onFiltersChange(HORSES_FILTER.shoeing_cycle)}
                    options={shoeingCycleFiltersOptions}
                    renderSelectedValue={renderShoeingCycleFilterValues}
                    selectedValue={filtersState[HORSES_FILTER.shoeing_cycle]}
                    testID="HorsesPage-FiltersPanel-SelectShoeingCycle"
                />
            </Box>
            <Box sx={{ mb: 2, mr: 3 }}>
                <TextField
                    data-test-id="HorsesPage-FiltersPanel-AgeInput"
                    label={t('AddHorseForm:labels:age')}
                    onChange={handleAgeInputChange}
                    size="small"
                    sx={{ width: 200 }}
                    type="number"
                    value={filtersState[HORSES_FILTER.age]}
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <FormControlLabel
                    checked={isShowHiddenChecked}
                    control={
                        <Switch
                            onChange={handleShowHiddenSwitchChange}
                            value={isShowHiddenChecked}
                        />
                    }
                    data-test-id="HorsesPage-FiltersPanel-ShowHidden"
                    label={t('ContactsList:filters:show_options:show_hidden')}
                />
            </Box>
        </Box>
    );
}

export default memo(FiltersPanel);
