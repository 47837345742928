import React, { memo, useCallback } from 'react';
import {
    getProductMaterialShapeAndClips,
    getProductName,
} from 'shared/utils/product';

import { ProductItem } from './components';
import { Props } from './types';

function ProductListRow({
    data,
    index,
    style,
    icon,
    onClick,
    isOpen,
    testIdPrefix,
    showUnitTypes,
}: Props) {
    const productGroup = data[index];
    const firstProduct = productGroup[0] || [];

    const title = getProductName(firstProduct);

    const subtitle = getProductMaterialShapeAndClips(firstProduct);

    const _onClick = useCallback(() => {
        onClick(index);
    }, [onClick, index]);

    return (
        <ProductItem
            style={style}
            key={productGroup.map((prod) => prod.id).join()}
            title={title}
            subtitle={subtitle}
            productGroup={productGroup}
            type={firstProduct.productType}
            icon={icon}
            isOpen={isOpen}
            onClick={_onClick}
            testIdPrefix={testIdPrefix}
            showUnitTypes={showUnitTypes}
        />
    );
}

export default memo(ProductListRow);
