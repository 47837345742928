import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { getSyncStatus } from 'shared/utils/sync';

import { SyncStatusBadge } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useNetwork } from '@/hooks';

import InitialsAvatar from '../InitialsAvatar';

import { Props } from './types';

function CurrentUserBox({ userAvatarUrl, userProfileData }: Props) {
    const isOnline = useNetwork();

    const { isSyncInProgress, hasSyncIssue, isUnsynced } = useDBSyncContext();

    const syncStatus = useMemo(
        () =>
            getSyncStatus({
                hasSyncIssue,
                isOnline,
                isSyncInProgress,
                isUnsynced,
            }),
        [hasSyncIssue, isUnsynced, isOnline, isSyncInProgress],
    );

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                flexDirection: 'row',
                height: '100%',
                width: '100%',
                pt: 2,
                px: '13px',
            }}
            data-test-id="OrganisationBox"
        >
            <InitialsAvatar
                testID="UserBox-Avatar-Sidebar"
                avatarUrl={userAvatarUrl}
                size={'54px'}
                sx={{
                    fontSize: 20,
                    fontWeight: 'normal',
                    mr: 2,
                }}
                firstName={userProfileData?.first_name ?? ''}
                lastName={userProfileData?.last_name ?? ''}
            />
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: 105,
                        gap: 1,
                    }}
                >
                    <Typography
                        fontSize={13}
                        fontWeight={700}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {userProfileData?.first_name}{' '}
                        {userProfileData?.last_name}
                    </Typography>
                </Box>
                <SyncStatusBadge status={syncStatus} />
            </Box>
        </Box>
    );
}

export default CurrentUserBox;
