import { Collection, Database, Q } from '@nozbe/watermelondb';
import {
    ShoppingListProductModel,
    ShoppingListProductPayload,
} from '../../types/ShoppingListProduct';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import {
    ParsedShoppingListProduct,
    SelectedUnitType,
    ShoppingListModel,
} from '../../types/ShoppingList';
import {
    SHOPPING_PRODUCT_UNIT,
    SHOPPING_PRODUCT_UNIT_VALUE,
} from '../../constants/shoppingProductUnit';
import { ProductType } from '../../types/Products';

class ShoppingListProduct {
    private database: Database;
    private collection: Collection<ShoppingListProductModel>;
    private table = 'shopping_list_products';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async prepareAdd(
        shoppingListProducts: ParsedShoppingListProduct[],
        shoppingListId: string,
        userId: string,
        organisationID: string,
    ) {
        const shoppingListProductsCollection =
            this.database.collections.get<ShoppingListProductModel>(
                'shopping_list_products',
            );

        let shoppingListProductsToAdd: ShoppingListProductModel[] = [];

        await Promise.all(
            shoppingListProducts.map((shoppingListProd) => {
                shoppingListProd.productSizes.map(async (prodSize) => {
                    if (prodSize.quantity !== 0) {
                        let totalQuantity = 0;
                        let productSelectedUnit = prodSize.selectedUnit;
                        let productSalesUnitId = '';

                        if (
                            shoppingListProd.productAttributes.productType !==
                            ProductType.custom
                        ) {
                            if (
                                Object.keys(SHOPPING_PRODUCT_UNIT).includes(
                                    prodSize.selectedUnit,
                                )
                            ) {
                                if (
                                    !!prodSize.boxUnitSizes &&
                                    Object.keys(prodSize.boxUnitSizes)
                                        .length !== 0
                                ) {
                                    productSalesUnitId = Object.keys(
                                        prodSize.boxUnitSizes,
                                    )[0];
                                    for (const [
                                        id,
                                        boxUnitSize,
                                    ] of Object.entries(
                                        prodSize.boxUnitSizes,
                                    )) {
                                        if (
                                            prodSize.boxUnitSizes[
                                                productSalesUnitId
                                            ] > boxUnitSize
                                        ) {
                                            productSalesUnitId = id;
                                        }
                                    }
                                }

                                if (prodSize.selectedUnit === 'box') {
                                    totalQuantity =
                                        prodSize.quantity *
                                        shoppingListProd.productAttributes
                                            .boxUnitSize;
                                } else {
                                    const unitValue =
                                        SHOPPING_PRODUCT_UNIT_VALUE[
                                            prodSize.selectedUnit
                                        ].toString();

                                    totalQuantity =
                                        prodSize.quantity *
                                        parseInt(unitValue, 10);
                                }
                            } else {
                                const boxUnitSizeAmount = parseInt(
                                    prodSize.selectedUnit.split(' ')[1],
                                    10,
                                );

                                totalQuantity =
                                    prodSize.quantity * boxUnitSizeAmount;
                                productSelectedUnit = 'box';

                                if (
                                    prodSize.boxUnitSizes &&
                                    Object.keys(prodSize.boxUnitSizes).length
                                ) {
                                    productSalesUnitId =
                                        Object.keys(prodSize.boxUnitSizes).find(
                                            (salesUnitId) =>
                                                prodSize.boxUnitSizes &&
                                                prodSize.boxUnitSizes[
                                                    salesUnitId
                                                ] === boxUnitSizeAmount,
                                        ) || '';
                                } else {
                                    productSalesUnitId = '';
                                }
                            }
                        }

                        const shoppingListProductToAdd =
                            await shoppingListProductsCollection.prepareCreate(
                                (shoppLstProd) => {
                                    shoppLstProd.userId = userId;
                                    shoppLstProd.organisationId =
                                        organisationID;
                                    shoppLstProd.shoppingListId =
                                        shoppingListId;
                                    shoppLstProd.productId = prodSize.productId;
                                    shoppLstProd.productSalesUnitId =
                                        productSalesUnitId;
                                    shoppLstProd.unitType = productSelectedUnit;
                                    shoppLstProd.quantity = prodSize.quantity;
                                    shoppLstProd.totalQuantity = totalQuantity;
                                },
                            );

                        shoppingListProductsToAdd = [
                            ...shoppingListProductsToAdd,
                            shoppingListProductToAdd,
                        ];
                    }
                });
            }),
        );
        return shoppingListProductsToAdd;
    }

    async prepareUpdate(
        shoppingListElement: ShoppingListModel,
        payloadProducts: ParsedShoppingListProduct[],
        userId: string,
        organisationID: string,
    ) {
        const initialShoppingListProducts =
            await shoppingListElement.shoppingListProducts.fetch();
        const shoppingListProductsCollection =
            this.database.collections.get<ShoppingListProductModel>(
                'shopping_list_products',
            );

        let shoppingListProductsToAdd: ShoppingListProductModel[] = [];
        let shoppingListProductsToUpdate: ShoppingListProductModel[] = [];
        let shoppingListProductsToDelete: ShoppingListProductModel[] = [];

        await Promise.all(
            payloadProducts.map((shoppLstProd) => {
                shoppLstProd.productSizes.map(async (shoppLstProdSize) => {
                    if (shoppLstProdSize.quantity !== 0) {
                        const initialShoppLstProd =
                            initialShoppingListProducts.find(
                                (initialShoppLstProd) =>
                                    initialShoppLstProd.productId ===
                                    shoppLstProdSize.productId,
                            );
                        let totalQuantity = 0;
                        let productSelectedUnit = shoppLstProdSize.selectedUnit;
                        let productSalesUnitId = '';

                        if (
                            Object.keys(SHOPPING_PRODUCT_UNIT).includes(
                                shoppLstProdSize.selectedUnit,
                            )
                        ) {
                            if (
                                !!shoppLstProdSize.boxUnitSizes &&
                                Object.keys(shoppLstProdSize.boxUnitSizes)
                                    .length !== 0
                            ) {
                                productSalesUnitId = Object.keys(
                                    shoppLstProdSize.boxUnitSizes,
                                )[0];
                                for (const [id, boxUnitSize] of Object.entries(
                                    shoppLstProdSize.boxUnitSizes,
                                )) {
                                    if (
                                        shoppLstProdSize.boxUnitSizes[
                                            productSalesUnitId
                                            // @ts-ignore
                                        ] > boxUnitSize
                                    ) {
                                        productSalesUnitId = id;
                                    }
                                }
                            }

                            if (shoppLstProdSize.selectedUnit === 'box') {
                                totalQuantity =
                                    shoppLstProdSize.quantity *
                                    shoppLstProd.productAttributes.boxUnitSize;
                            } else {
                                totalQuantity =
                                    shoppLstProdSize.quantity *
                                    parseInt(
                                        SHOPPING_PRODUCT_UNIT_VALUE[
                                            shoppLstProdSize.selectedUnit as SelectedUnitType
                                        ],
                                        10,
                                    );
                            }
                        } else {
                            const boxUnitSizeAmount = parseInt(
                                shoppLstProdSize.selectedUnit.split(' ')[1],
                                10,
                            );
                            totalQuantity =
                                shoppLstProdSize.quantity *
                                parseInt(
                                    shoppLstProdSize.selectedUnit.split(' ')[1],
                                    10,
                                );
                            productSelectedUnit = 'box';
                            if (!!shoppLstProdSize.boxUnitSizes) {
                                productSalesUnitId =
                                    Object.keys(
                                        shoppLstProdSize.boxUnitSizes,
                                    ).find(
                                        (salesUnitId) =>
                                            shoppLstProdSize.boxUnitSizes![
                                                salesUnitId
                                            ] === boxUnitSizeAmount,
                                    ) || '';
                            }
                        }
                        if (!initialShoppLstProd) {
                            const shoppingListProdToAdd =
                                await shoppingListProductsCollection.prepareCreate(
                                    (shoppingListProd) => {
                                        shoppingListProd.userId = userId;
                                        shoppingListProd.organisationId =
                                            organisationID;
                                        shoppingListProd.shoppingListId =
                                            shoppingListElement.id;
                                        shoppingListProd.productId =
                                            shoppLstProdSize.productId;
                                        shoppingListProd.productSalesUnitId =
                                            productSalesUnitId;
                                        shoppingListProd.unitType =
                                            productSelectedUnit;
                                        shoppingListProd.quantity =
                                            shoppLstProdSize.quantity;
                                        shoppingListProd.totalQuantity =
                                            totalQuantity;
                                    },
                                );
                            shoppingListProductsToAdd = [
                                ...shoppingListProductsToAdd,
                                shoppingListProdToAdd,
                            ];
                        } else {
                            if (
                                initialShoppLstProd.quantity !==
                                    shoppLstProdSize.quantity ||
                                initialShoppLstProd.unitType !==
                                    productSelectedUnit ||
                                initialShoppLstProd.totalQuantity !==
                                    totalQuantity
                            ) {
                                const shoppingListProdToUpdate =
                                    await initialShoppLstProd.prepareUpdate(
                                        (shoppingListProd) => {
                                            shoppingListProd.quantity =
                                                shoppLstProdSize.quantity;
                                            shoppingListProd.productSalesUnitId =
                                                productSalesUnitId;
                                            shoppingListProd.unitType =
                                                productSelectedUnit;
                                            shoppingListProd.totalQuantity =
                                                totalQuantity;
                                        },
                                    );
                                shoppingListProductsToUpdate = [
                                    ...shoppingListProductsToUpdate,
                                    shoppingListProdToUpdate,
                                ];
                            }
                        }
                    }
                });
            }),
        );

        await Promise.all(
            initialShoppingListProducts.map(async (initialShoppLstProd) => {
                let shouldBeDeleted = true;
                payloadProducts.map((shoppLstProd) => {
                    const existingShoppLstProd = shoppLstProd.productSizes.find(
                        (shoppLstProdSize) =>
                            shoppLstProdSize.productId ===
                            initialShoppLstProd.productId,
                    );
                    if (
                        existingShoppLstProd &&
                        existingShoppLstProd.quantity !== 0
                    ) {
                        return (shouldBeDeleted = false);
                    }
                });
                if (shouldBeDeleted) {
                    shoppingListProductsToDelete = [
                        ...shoppingListProductsToDelete,
                        initialShoppLstProd.prepareMarkAsDeleted(),
                    ];
                }
            }),
        );

        return {
            shoppingListProductsToAdd,
            shoppingListProductsToUpdate,
            shoppingListProductsToDelete,
        };
    }

    async prepareDelete(shoppingList: ShoppingListModel) {
        const shoppingListProducts =
            await shoppingList.shoppingListProducts.fetch();

        let shoppingListProductsToDelete: ShoppingListProductModel[] = [];

        await Promise.all(
            shoppingListProducts.map((shoppLstProd) => {
                shoppingListProductsToDelete = [
                    ...shoppingListProductsToDelete,
                    shoppLstProd.prepareMarkAsDeleted(),
                ];
            }),
        );
        return shoppingListProductsToDelete;
    }

    async getBy(
        shoppingListId: string,
        productId: string,
        userId: string,
        organisationID: string,
    ) {
        return await this.collection
            .query(
                Q.and(
                    Q.where('shopping_list_id', shoppingListId),
                    Q.where('product_id', productId),
                    Q.where('user_id', userId),
                    Q.where('organisation_id', organisationID),
                ),
            )
            .fetch();
    }

    async add(
        payload: ShoppingListProductPayload,
        userId: string,
        organisationID: string,
    ) {
        return await this.database.write(async () => {
            const createdProduct = await this.collection.create(
                (shoppingListProduct) => {
                    (shoppingListProduct.productId = payload.productId),
                        (shoppingListProduct.organisationId = organisationID),
                        (shoppingListProduct.userId = userId),
                        (shoppingListProduct.quantity = payload.quantity),
                        (shoppingListProduct.shoppingListId =
                            payload.shoppingListId),
                        (shoppingListProduct.totalQuantity =
                            payload.totalQuantity),
                        (shoppingListProduct.unitType = payload.unitType),
                        (shoppingListProduct.productSalesUnitId =
                            payload.productSalesUnitId);
                },
            );

            this.options.logDBAction({
                message: 'Add shopping list product',
                modelName: this.table,
                payload: createdProduct,
            });

            return createdProduct;
        });
    }

    async update(
        shoppingListId: string,
        payload: ShoppingListProductPayload,
        userId: string,
        organisationID: string,
    ) {
        const [productToEdit] = await this.getBy(
            shoppingListId,
            payload.productId,
            userId,
            organisationID,
        );

        return await this.database.write(async () => {
            if (productToEdit) {
                const updatedProduct = await productToEdit.update((product) => {
                    product.quantity = payload.quantity;
                    product.totalQuantity = payload.totalQuantity;
                    product.unitType = payload.unitType;
                    product.productSalesUnitId =
                        payload.productSalesUnitId ||
                        product.productSalesUnitId;
                });

                this.options.logDBAction({
                    message: 'Update shopping list product',
                    modelName: this.table,
                    payload: productToEdit,
                });

                return updatedProduct;
            }
        });
    }

    async deleteProductFromShoppingList(
        shoppingListId: string,
        productId: string,
        userId: string,
        organisationID: string,
    ) {
        const result = await this.getBy(
            shoppingListId,
            productId,
            userId,
            organisationID,
        );
        await this.database.write(async () => {
            if (result.length) {
                const shoppingListProductToRemove = result[0];
                await shoppingListProductToRemove.markAsDeleted();

                this.options.logDBAction({
                    message: 'Delete shopping list product',
                    modelName: this.table,
                    payload: shoppingListProductToRemove,
                });
            }
        });
    }
}

export default ShoppingListProduct;
