export enum HORSE_WORK_TYPE {
    all_around_pleasure_horse = 'all_around_pleasure_horse',
    breeding_studs = 'breeding_studs',
    campdrafting = 'campdrafting',
    dressage = 'dressage',
    endurance = 'endurance',
    eventing = 'eventing',
    jumping_hunters = 'jumping_hunters',
    other = 'other',
    polo_polo_crosse = 'polo_polo_crosse',
    racing_barrel_racing = 'racing_barrel_racing',
    racing_standardbred_trotting = 'racing_standardbred_trotting',
    racing_thoroughbred = 'racing_thoroughbred',
    retired_not_currently_working = 'retired_not_currently_working',
    stockhorse = 'stockhorse',
    western_cutting_roping = 'western_cutting_roping',
    western_other_styles = 'western_other_styles',
    western_pleasure = 'western_pleasure',
    western_reining = 'western_reining',
    work_horses_ranch_horses = 'work_horses_ranch_horses',
    trail = 'trail',
}

export const HORSE_WORK_TYPE_DICTIONARY: Record<HORSE_WORK_TYPE, string> = {
    [HORSE_WORK_TYPE.all_around_pleasure_horse]:
        'HorseProfile:picker:work_types:all_around_pleasure_horse',
    [HORSE_WORK_TYPE.breeding_studs]:
        'HorseProfile:picker:work_types:breeding_studs',
    [HORSE_WORK_TYPE.campdrafting]:
        'HorseProfile:picker:work_types:campdrafting',
    [HORSE_WORK_TYPE.dressage]: 'HorseProfile:picker:work_types:dressage',
    [HORSE_WORK_TYPE.endurance]: 'HorseProfile:picker:work_types:endurance',
    [HORSE_WORK_TYPE.eventing]: 'HorseProfile:picker:work_types:eventing',
    [HORSE_WORK_TYPE.jumping_hunters]:
        'HorseProfile:picker:work_types:jumping_hunters',
    [HORSE_WORK_TYPE.other]: 'HorseProfile:picker:work_types:other',
    [HORSE_WORK_TYPE.polo_polo_crosse]:
        'HorseProfile:picker:work_types:polo_polo_crosse',
    [HORSE_WORK_TYPE.racing_barrel_racing]:
        'HorseProfile:picker:work_types:racing_barrel_racing',
    [HORSE_WORK_TYPE.racing_standardbred_trotting]:
        'HorseProfile:picker:work_types:racing_standardbred_trotting',
    [HORSE_WORK_TYPE.racing_thoroughbred]:
        'HorseProfile:picker:work_types:racing_thoroughbred',
    [HORSE_WORK_TYPE.retired_not_currently_working]:
        'HorseProfile:picker:work_types:retired_not_currently_working',
    [HORSE_WORK_TYPE.stockhorse]: 'HorseProfile:picker:work_types:stockhorse',
    [HORSE_WORK_TYPE.trail]: 'HorseProfile:picker:work_types:trail',
    [HORSE_WORK_TYPE.western_cutting_roping]:
        'HorseProfile:picker:work_types:western_cutting_roping',
    [HORSE_WORK_TYPE.western_other_styles]:
        'HorseProfile:picker:work_types:western_other_styles',
    [HORSE_WORK_TYPE.western_pleasure]:
        'HorseProfile:picker:work_types:western_pleasure',
    [HORSE_WORK_TYPE.western_reining]:
        'HorseProfile:picker:work_types:western_reining',
    [HORSE_WORK_TYPE.work_horses_ranch_horses]:
        'HorseProfile:picker:work_types:work_horses_ranch_horses',
};
