import { Box } from '@mui/material';
import React from 'react';

import { COLOR, ENTITY_COLOR } from '@/theme/colors';

export type OverlayDotsProps = {
    numberOfDots: number;
    currentPosition: number;
};

type OverlayDotProps = {
    isActive: boolean;
};

function OverlayDot({ isActive }: OverlayDotProps) {
    return (
        <Box
            sx={{
                transition: 'width 0.4s ease-in-out, height 0.4s ease-in-out',
                width: isActive ? '15px' : '12px',
                height: isActive ? '15px' : '12px',
                borderRadius: '50%',
                background: isActive ? ENTITY_COLOR.schedule : COLOR.white,
                border: `2px solid ${ENTITY_COLOR.schedule}`,
            }}
        />
    );
}

function OverlayDots({ numberOfDots, currentPosition }: OverlayDotsProps) {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '27px',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
            }}
        >
            {Array(numberOfDots)
                .fill(1)
                .map((_, index) => (
                    <OverlayDot
                        key={index}
                        isActive={index === currentPosition}
                    />
                ))}
        </Box>
    );
}

export default OverlayDots;
