import { Q } from '@nozbe/watermelondb';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContactsService from 'shared/db/services/Contact';
import HorsesService from 'shared/db/services/Horse';
import { HorseModel } from 'shared/types/Horses';

import { HorsesList, SelectItemsModal } from '@/components';
import { Props as HorsesListProps } from '@/components/HorsesList/types';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';
import { t } from '@/services/translations/config';

import { Props } from './types';

function SelectHorsesModal({
    close,
    contactsToFilterHorsesBy,
    horsesToSelect,
    isOpen,
    multiSelect,
    onSelectHorses,
    selectedHorses,
}: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();

    const [showSuggestions, setShowSuggestions] = useState(
        !!contactsToFilterHorsesBy?.length || false,
    );

    useEffect(() => {
        setShowSuggestions(!!contactsToFilterHorsesBy?.length && isOpen);
    }, [contactsToFilterHorsesBy?.length, isOpen]);

    const database = useMemo(() => {
        return getDatabase();
    }, [getDatabase]);

    const fetchHorses = useCallback(
        async (query = '') => {
            const horsesService = new HorsesService({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const queries = [Q.where('hidden', false), Q.sortBy('name', 'asc')];

            if (query) {
                queries.push(
                    Q.where('name', Q.like(`${Q.sanitizeLikeString(query)}%`)),
                );
            }

            if (showSuggestions && contactsToFilterHorsesBy?.length) {
                const contactsService = new ContactsService({
                    database,
                    imageService: ImagesService,
                    logDBAction: () => null,
                });

                return contactsService.getContactsUniqueHorses(
                    contactsToFilterHorsesBy,
                    queries,
                );
            }

            if (horsesToSelect) {
                return horsesToSelect.extend(...queries).fetch();
            }

            return horsesService.getAll(...queries);
        },
        [
            contactsToFilterHorsesBy,
            database,
            horsesToSelect,
            ImagesService,
            showSuggestions,
        ],
    );

    const hideSuggestions = useCallback(() => {
        setShowSuggestions(false);
    }, []);

    return (
        <SelectItemsModal<HorseModel, HorsesListProps>
            isOpen={isOpen}
            ListComponent={HorsesList}
            selectedItems={selectedHorses}
            close={close}
            fetchItemsFn={fetchHorses}
            onSelectItems={onSelectHorses}
            testIdPrefix="SelectHorsesModal"
            emptyMessage={t(
                showSuggestions
                    ? 'HorsesSelection:horses_suggestions_blankslate'
                    : 'HorsesSelection:horses_blankslate',
            )}
            noItemsFoundMessage={t('HorseList:no_horse_found_blankslate')}
            title={t(
                multiSelect
                    ? 'HorsesSelection:select_multi'
                    : 'HorsesSelection:select',
            )}
            selectedItemsCountTextKey="Horses:selected_horses_number"
            addSelectedItemsButtonText={t(
                multiSelect
                    ? 'Horses:add_selected_horses'
                    : 'Horses:add_selected_horse',
            )}
            multiSelect={multiSelect}
            hideSuggestions={hideSuggestions}
            showSuggestions={showSuggestions}
            showAllButtonText={t('HorsesSelection:show_all')}
            listComponentProps={{
                itemWrapperStyle: 'mb-4',
                selectable: true,
                showContacts: true,
                showEntryDate: true,
                testIdPrefix: 'SelectHorsesModal',
            }}
        />
    );
}

export default SelectHorsesModal;
