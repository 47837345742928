import { LogEvent } from '../../types/analytics';
import { ProductModel, ProductType } from '../../types/Products';
import { ShoppingListModel } from '../../types/ShoppingList';
import {
    ShoppingListProductChange,
    ShoppingListProductModel,
} from '../../types/ShoppingListProduct';
import { getProductSize } from '../../utils/product';

import { OrganisationAnalytics } from '../organisation';
import { ShoppingListsEvents } from './events';

function getProductParams(
    product: ProductModel,
    quantity: number,
    productSalesUnitId?: string,
) {
    const eventParams = {
        brand: product.brand,
        shape: product.shape,
        type: product.productType,
        material: product.material,
        clip: product.clips,
        size: getProductSize(product),
        family: product.family,
        quantity,
    };

    return productSalesUnitId
        ? { ...eventParams, productSalesUnitId }
        : eventParams;
}

function logUserAddedCustomProductToShoppingList(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        quantity: number;
    },
) {
    logEvent(ShoppingListsEvents.userAddedCustomProductToShoppingList, {
        name: params.product.name,
        quantity: params.quantity,
    });
}

function logUserAddedRaspToShoppingList(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        quantity: number;
    },
) {
    const eventParams = getProductParams(params.product, params.quantity);

    logEvent(ShoppingListsEvents.userAddedRaspToShoppingList, {
        ...eventParams,
    });
}

function logUserAddedNailToShoppingList(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        productSalesUnitId: string;
        quantity: number;
    },
) {
    const eventParams = getProductParams(
        params.product,
        params.quantity,
        params.productSalesUnitId,
    );

    logEvent(ShoppingListsEvents.userAddedNailToShoppingList, {
        ...eventParams,
    });
}

function logUserAddedShoeToShoppingList(
    logEvent: LogEvent,
    params: {
        product: ProductModel;
        productSalesUnitId: string;
        quantity: number;
    },
) {
    const eventParams = getProductParams(
        params.product,
        params.quantity,
        params.productSalesUnitId,
    );

    logEvent(ShoppingListsEvents.userAddedShoeToShoppingList, {
        ...eventParams,
    });
}

async function logUserAddedProductToShoppingList(
    logEvent: LogEvent,
    params: {
        shoppingListProduct: ShoppingListProductModel;
    },
) {
    const product = await params.shoppingListProduct.database.collections
        .get<ProductModel>('products')
        .find(params.shoppingListProduct.productId);

    logEvent(ShoppingListsEvents.userAddedProductToShoppingList, {
        ...product._raw,
        quantity: params.shoppingListProduct.quantity,
        productSalesUnitId: params.shoppingListProduct.productSalesUnitId,
    });

    if (product.productType === ProductType.nails) {
        logUserAddedNailToShoppingList(logEvent, {
            product,
            quantity: params.shoppingListProduct.quantity,
            productSalesUnitId: params.shoppingListProduct.productSalesUnitId,
        });
    } else if (product.productType === ProductType.shoes) {
        logUserAddedShoeToShoppingList(logEvent, {
            product,
            quantity: params.shoppingListProduct.quantity,
            productSalesUnitId: params.shoppingListProduct.productSalesUnitId,
        });
    } else if (product.productType === ProductType.rasps) {
        logUserAddedRaspToShoppingList(logEvent, {
            product,
            quantity: params.shoppingListProduct.quantity,
        });
    } else if (product.productType === ProductType.custom) {
        logUserAddedCustomProductToShoppingList(logEvent, {
            product,
            quantity: params.shoppingListProduct.quantity,
        });
    }
}

function logUserChangedShoppingListTitle(
    logEvent: LogEvent,
    params: {
        title: string;
    },
) {
    logEvent(ShoppingListsEvents.userChangedShoppingListTitle, {
        title: params.title,
    });
}

async function logUserCreatedShoppingList(
    logEvent: LogEvent,
    params: {
        shoppingList: ShoppingListModel;
        userChangedTitle: boolean;
        isOwner: boolean | null;
    },
) {
    logEvent(ShoppingListsEvents.userCreatedShoppingList, {
        ...params.shoppingList._raw,
    });

    const shoppingListProducts =
        await params.shoppingList.shoppingListProducts.fetch();

    if (params.userChangedTitle) {
        logUserChangedShoppingListTitle(logEvent, {
            title: params.shoppingList.name,
        });
    }

    shoppingListProducts.forEach((shoppingListProduct) =>
        logUserAddedProductToShoppingList(logEvent, { shoppingListProduct }),
    );

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedShoppingList(logEvent);
    }
}

async function logUserChangedAmountOfProductInShoppingList(
    logEvent: LogEvent,
    params: {
        shoppingListProduct: ShoppingListProductModel;
        quantityChange: number;
    },
) {
    const product = await params.shoppingListProduct.database.collections
        .get<ProductModel>('products')
        .find(params.shoppingListProduct.productId);

    logEvent(ShoppingListsEvents.userChangedAmountOfProductInShoppingList, {
        ...product._raw,
        quantity: params.shoppingListProduct.quantity,
        quantityChange: params.quantityChange,
    });
}

function logUserUpdatedShoppingListProducts(
    logEvent: LogEvent,
    params: {
        changes: ShoppingListProductChange[];
        shoppingListProducts: ShoppingListProductModel[];
    },
) {
    params.changes.forEach(({ quantity, productSize }) => {
        const shoppingListProduct = params.shoppingListProducts.find(
            (shoppingListProduct) =>
                productSize.productId === shoppingListProduct.productId,
        );

        if (quantity > 0 && productSize.quantity === 0 && shoppingListProduct) {
            logUserAddedProductToShoppingList(logEvent, {
                shoppingListProduct: shoppingListProduct,
            });
        } else if (shoppingListProduct) {
            logUserChangedAmountOfProductInShoppingList(logEvent, {
                shoppingListProduct: shoppingListProduct,
                quantityChange:
                    shoppingListProduct.quantity - productSize.quantity,
            });
        }
    });
}

function logUserSharedShoppingListWithDealer(
    logEvent: LogEvent,
    params: { dealerId: string },
) {
    logEvent(ShoppingListsEvents.userSharedShoppingListWithDealer, {
        dealerId: params.dealerId,
    });
}

function logUserDownloadedShoppingList(logEvent: LogEvent) {
    logEvent(ShoppingListsEvents.userDownloadedShoppingList);
}

function logUserAddedShoppingListToInventory(logEvent: LogEvent) {
    logEvent(ShoppingListsEvents.userAddedShoppingListToInventory);
}

function logUserUpdatedShoppingListTitle(
    logEvent: LogEvent,
    params: { title: string; isOwner: boolean | null },
) {
    logEvent(ShoppingListsEvents.userUpdatedShoppingListTitle, {
        title: params.title,
    });

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberUpdatedShoppingListTitle(logEvent);
    }
}

function logUserDuplicatedShoppingList(logEvent: LogEvent) {
    logEvent(ShoppingListsEvents.userDuplicatedShoppingList);
}

function logUserClickedQuickSelectFromInventoryButton(logEvent: LogEvent) {
    logEvent(ShoppingListsEvents.userClickedQuickSelectFromInventoryButton);
}

export const ShoppingListsAnalytics = {
    logUserCreatedShoppingList,
    logUserSharedShoppingListWithDealer,
    logUserDownloadedShoppingList,
    logUserAddedShoppingListToInventory,
    logUserUpdatedShoppingListTitle,
    logUserDuplicatedShoppingList,
    logUserUpdatedShoppingListProducts,
    logUserClickedQuickSelectFromInventoryButton,
};
