import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HorseModel } from 'shared/types/Horses';

import { TruncatedListSection, HorsesList } from '@/components';
import { Props as HorsesListProps } from '@/components/HorsesList/types';
import { ROUTE } from '@/router/routes';

import { Props } from './types';

function HorsesSection({
    horses,
    isLoading,
    testIdPrefix,
    isSingleHorse = false,
    onAddButtonClick,
    showAddButton = false,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToHorse = useCallback(
        (horse: HorseModel) => {
            navigate(`${ROUTE.horses}/${horse.id}`);
        },
        [navigate],
    );

    return (
        <TruncatedListSection<Partial<HorsesListProps>>
            ListComponent={HorsesList}
            title={
                isSingleHorse
                    ? t('Entities:horse')
                    : t('Contact:section:horses_with_length', {
                          length: horses.length || 0,
                      })
            }
            buttonText={t('Contact:more_horses')}
            emptyMessage={t('Contact:horses_blankslate')}
            onAddButtonClick={onAddButtonClick}
            listComponentProps={{
                clickable: true,
                items: horses,
                itemWrapperStyle: 'border border-gray-200',
                testIdPrefix,
                onItemClick: navigateToHorse,
                size: 'large',
                showEntryDate: true,
                showContacts: true,
            }}
            entityName={t('Entities:horse').toLowerCase()}
            isLoading={isLoading}
            showAddButton={showAddButton}
        />
    );
}

export default HorsesSection;
