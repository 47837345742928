import CallIcon from '@mui/icons-material/Call';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, Typography } from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import clsx from 'clsx';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SchedulesAnalytics } from 'shared/analytics/schedules';

import equinetLogoImage from '@/assets/images/equinet-logo-large.png';
import equinetLogoImageDesktop from '@/assets/images/EQUINET_white_large.png';
import { BackdropLoader, Modal } from '@/components';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { COLOR } from '@/theme/colors';

import { DesktopView, Footer, HelpBanner, MobileView } from './components';
import { AppointmentCancelledModal } from './components/AppointmentCancelledModal';
import { getAddToCalendarDateProps } from './getAddToCalendarProps';
import {
    INVITE_STATUS,
    useExternalAppointmentPage,
} from './useExternalAppointmentPage';

function ExternalAppointmentPage() {
    const {
        appointmentDetails,
        status,
        isLoading,
        isPageLoading,
        acceptInvitation,
        rejectInvitation,
        clearActionQueryParam,
    } = useExternalAppointmentPage();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [showAppointmentCancelledModal, setShowAppointmentCancelledModal] =
        useState(false);
    const [acceptingProcessFinished, setAcceptingProcessFinished] =
        useState(false);
    const [showHelpBanner, setShowHelpBanner] = useState(false);

    const isAppointmentCancelled = appointmentDetails?.status === 'cancelled';

    const displayHelpBanner = useCallback(() => setShowHelpBanner(true), []);

    const hideHelpBanner = useCallback(() => setShowHelpBanner(false), []);

    const displayConfirmationModal = useCallback(
        () => setShowConfirmationModal(true),
        [],
    );

    const hideConfirmationModal = useCallback(() => {
        if (!acceptingProcessFinished) {
            setAcceptingProcessFinished(true);
        }
        clearActionQueryParam();

        setShowConfirmationModal(false);
    }, [acceptingProcessFinished, clearActionQueryParam]);

    const displayCancellationModal = useCallback(
        () => setShowCancellationModal(true),
        [],
    );

    const hideCancellationModal = useCallback(() => {
        clearActionQueryParam();
        setShowCancellationModal(false);
    }, [clearActionQueryParam]);

    const onConfirmationButtonClick = useCallback(async () => {
        if (
            status === INVITE_STATUS.AWAITING ||
            status === INVITE_STATUS.PROMPT_TO_CONFIRM
        ) {
            await acceptInvitation();
            SchedulesAnalytics.logUserAcceptedAppointment(
                FirebaseAnalytics.logEvent,
            );
        }

        displayConfirmationModal();
    }, [acceptInvitation, displayConfirmationModal, status]);

    const onCancelActionClick = useCallback(async () => {
        if (
            status === INVITE_STATUS.AWAITING ||
            status === INVITE_STATUS.CONFIRMED ||
            status === INVITE_STATUS.PROMPT_TO_REJECT
        ) {
            await rejectInvitation();
            SchedulesAnalytics.logUserDeclinedAppointment(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [rejectInvitation, status]);

    const equinetLogo = useMemo(
        () => <Box alt="Equinet Logo" component="img" src={equinetLogoImage} />,
        [],
    );

    const equinetDesktopLogo = useMemo(
        () => (
            <Box
                alt="Equinet Logo"
                component="img"
                src={equinetLogoImageDesktop}
            />
        ),
        [],
    );

    const confirmationModal = useMemo(() => {
        return (
            <Modal
                isOpen
                modalContentStyles="md:flex-1 md:flex md:justify-center md:items-center mt-10"
                disableRestoreFocus
                styles="w-[341px] px-6 py-5"
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        color: COLOR.ebonyClay,
                    }}
                >
                    {t(
                        !acceptingProcessFinished || status === 'awaiting'
                            ? 'ExternalAppointment:actions:confirm_title'
                            : 'ExternalAppointment:actions:already_confirmed_title',
                    )}
                </Typography>
                <div className="pt-5">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '300',
                            lineHeight: '26px',
                            color: COLOR.ebonyClay,
                        }}
                    >
                        {t(
                            !acceptingProcessFinished || status === 'awaiting'
                                ? 'ExternalAppointment:actions:confirm_disclaimer'
                                : 'ExternalAppointment:actions:already_confirmed_disclaimer',
                        )}
                    </Typography>
                </div>
                <div className="flex flex-row justify-between items-center pt-6">
                    <AddToCalendarButton
                        name={appointmentDetails?.title || ''}
                        description={appointmentDetails?.notes || ''}
                        {...getAddToCalendarDateProps(
                            appointmentDetails?.starts_at,
                            appointmentDetails?.ends_at,
                        )}
                        location={appointmentDetails?.address}
                        organizer={`${appointmentDetails?.author.name}|${appointmentDetails?.author.email}`}
                        status={
                            status === 'rejected' ? 'CANCELLED' : 'CONFIRMED'
                        }
                        options={[
                            'Apple',
                            'Google',
                            'iCal',
                            'Outlook.com',
                            'Yahoo',
                        ]}
                        buttonStyle="text"
                        trigger="click"
                        hideBackground
                        styleLight="--btn-border-top: #2f4377"
                    />

                    <Button
                        variant="contained"
                        disableElevation
                        className="py-2"
                        onClick={hideConfirmationModal}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '15px',
                                color: COLOR.white,
                            }}
                        >
                            {t('ExternalAppointment:actions:ok').toUpperCase()}
                        </Typography>
                    </Button>
                </div>
            </Modal>
        );
    }, [
        acceptingProcessFinished,
        appointmentDetails?.address,
        appointmentDetails?.author.email,
        appointmentDetails?.author.name,
        appointmentDetails?.notes,
        appointmentDetails?.title,
        appointmentDetails?.starts_at,
        appointmentDetails?.ends_at,
        hideConfirmationModal,
        status,
    ]);

    useEffect(() => {
        if (appointmentDetails?.status == null) {
            return;
        }

        if (isAppointmentCancelled) {
            setShowAppointmentCancelledModal(true);

            return;
        }

        if (status === INVITE_STATUS.PROMPT_TO_CONFIRM) {
            onConfirmationButtonClick();
        }

        if (status === INVITE_STATUS.PROMPT_TO_REJECT) {
            onCancelActionClick();
            displayCancellationModal();
        }
    }, [
        isAppointmentCancelled,
        onCancelActionClick,
        onConfirmationButtonClick,
        status,
        displayCancellationModal,
        appointmentDetails?.status,
    ]);

    if (isPageLoading) {
        return (
            <BackdropLoader
                sx={{ height: '100%' }}
                isLoading={isPageLoading}
                loadingText={t('ExternalAppointment:labels:loading')}
            />
        );
    }

    return (
        <div className="relative flex flex-col w-full h-full min-h-screen">
            <div className="w-full flex flex-row md:justify-between items-center px-6 py-3.5 h-16 bg-deepCerulean">
                <div className="md:hidden w-[35px] h-[36.5px]">
                    {equinetLogo}
                </div>
                <div className="hidden md:flex h-[36.5px]">
                    {equinetDesktopLogo}
                </div>
                <div className=" flex flex-row grow justify-between md:justify-end items-center gap-1">
                    {appointmentDetails?.author.phone ? (
                        <div className="md:hidden px-3">
                            <Button
                                variant="contained"
                                disableElevation
                                startIcon={<CallIcon />}
                                href={`tel:${appointmentDetails?.author.phone}`}
                                sx={{
                                    backgroundColor: COLOR.white15,
                                    '&:hover': {
                                        backgroundColor: COLOR.deepCerulean08,
                                    },
                                }}
                            >
                                {t(
                                    'ExternalAppointment:labels:contact_ferrier_simplier_version',
                                )}
                            </Button>
                        </div>
                    ) : null}
                    {appointmentDetails?.author.phone ? (
                        <div className="hidden md:flex px-3">
                            <Button
                                variant="contained"
                                disableElevation
                                startIcon={<CallIcon />}
                                href={`tel:${appointmentDetails?.author.phone}`}
                                sx={{
                                    backgroundColor: COLOR.white15,
                                    '&:hover': {
                                        backgroundColor: COLOR.deepCerulean08,
                                    },
                                }}
                            >
                                {t(
                                    'ExternalAppointment:labels:contact_ferrier',
                                    {
                                        number: appointmentDetails?.author
                                            .phone,
                                    },
                                )}
                            </Button>
                        </div>
                    ) : null}
                    <HelpIcon
                        onClick={displayHelpBanner}
                        sx={{ color: COLOR.white }}
                    />
                </div>
            </div>
            <div
                className={clsx(
                    'overflow-auto flex flex-col flex-1',
                    status === INVITE_STATUS.REJECTED ||
                        status === INVITE_STATUS.EXPIRED ||
                        isAppointmentCancelled
                        ? 'pb-[52px]'
                        : 'pb-28',
                )}
            >
                <DesktopView
                    appointmentDetails={appointmentDetails}
                    status={status}
                    isLoading={isLoading}
                    showCancellationModal={showCancellationModal}
                    hideCancellationModal={hideCancellationModal}
                    cancelAppointment={onCancelActionClick}
                    isAppointmentCancelled={isAppointmentCancelled}
                />
                <MobileView
                    appointmentDetails={appointmentDetails}
                    status={status}
                    isLoading={isLoading}
                    showCancellationModal={showCancellationModal}
                    hideCancellationModal={hideCancellationModal}
                    cancelAppointment={onCancelActionClick}
                    isAppointmentCancelled={isAppointmentCancelled}
                />
                {/* As this modal is shared in both view we leave it here */}
                {showConfirmationModal ? confirmationModal : null}
                {showAppointmentCancelledModal ? (
                    <AppointmentCancelledModal
                        onConfirm={() =>
                            setShowAppointmentCancelledModal(false)
                        }
                    />
                ) : null}
            </div>
            <Footer
                status={status}
                isAppointmentCancelled={isAppointmentCancelled}
                showCancellationModal={showCancellationModal}
                displayCancellationModal={displayCancellationModal}
                onConfirmationButtonClick={onConfirmationButtonClick}
            />
            <HelpBanner showBanner={showHelpBanner} close={hideHelpBanner} />
        </div>
    );
}

export default ExternalAppointmentPage;
