import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import React, { memo } from 'react';

import { Props } from './types';

function UnveilPasswordButton({ isPasswordVisible, onClick }: Props) {
    return (
        <InputAdornment position="end">
            <IconButton edge="end" onClick={onClick}>
                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    );
}

export default memo(UnveilPasswordButton);
