import en_AU from './en-AU/common.json';
import en_US from './en-US/common.json';
import nl_NL from './nl-NL/common.json';

export default {
    'en-US': {
        common: en_US,
    },
    'en-AU': {
        common: en_AU,
    },
    'nl-NL': {
        common: nl_NL,
    },
};
