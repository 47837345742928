import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppAnalytics } from 'shared/analytics/app';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';
import { COUNTRY, LOCALE } from 'shared/constants/localization/types';
import AppService from 'shared/db/services/App';

import { LocaleSelector, PhoneNumber } from '@/components';
import AcceptTermsCheckbox from '@/components/AcceptTermsCheckbox';
import { CUSTOM_ERROR } from '@/constants/customErrors';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { getUserDefaultOrganisation } from '@/helpers/organisations';
import { useDatabase } from '@/hooks';
import { ServerErrorAlert } from '@/pages/SignUpPage/components/SignUpForm/components';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import Logger from '@/services/logger';
import { ServerAuthAPI } from '@/services/networking/auth';
import { handleSignUpFlowError } from '@/utils/auth';
import { AuthValidationSchema } from '@/validations/auth';

import { Props, ProviderSignUpFormValues } from './types';

function ProviderAccountRegistrationDialog({
    providerData,
    cancelProviderSignUp,
    language,
    onSubmit,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { authenticateUser, allowUserToEnter, setIsUserAfterSignUp } =
        useAuthContext();
    const { setIsInitialSyncInProgress } = useDBSyncContext();

    const [providerErrorCode, setProviderErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [isAuthInProgress, setIsAuthInProgress] = useState(false);

    const initialValues: ProviderSignUpFormValues = useMemo(
        () => ({
            phone: '',
            prefix: '',
            rememberMe: true,
            emailSubscription: true,
            appSubscription: true,
            language,
            region: COUNTRY.AU,
            acceptTerms: false,
        }),
        [language],
    );

    const finishProviderSignUp = useCallback(
        async (values: ProviderSignUpFormValues) => {
            try {
                const database = getDatabase();
                const appService = new AppService({
                    database,
                    logInfo: Logger.logInfo,
                });

                setIsAuthInProgress(true);

                await ServerAuthAPI.signUpWithFirebase({
                    app_subscription: true,
                    company_name: null,
                    email_subscription: values.emailSubscription,
                    email: providerData.email ?? '',
                    first_name: providerData.displayName ?? '',
                    language: values.language,
                    last_name: '',
                    phone: values.phone,
                    phone_prefix: values.prefix,
                    region: values.region,
                });

                const firebaseToken = await FirebaseAuthAPI.getAuthToken();

                if (!firebaseToken) {
                    throw new Error(CUSTOM_ERROR.no_firebase_token_found);
                }

                await appService.clearDatabase();

                authenticateUser({
                    allowUserToEnter: false,
                    firebaseToken,
                    rememberUser: true,
                });

                const userDefaultOrganisation =
                    await getUserDefaultOrganisation();

                if (!userDefaultOrganisation?.id) {
                    throw new Error(CUSTOM_ERROR.no_user_default_org_found);
                }

                await FirebaseAuthAPI.setUserOrganisationId(
                    userDefaultOrganisation.id,
                );

                allowUserToEnter();

                setIsUserAfterSignUp(true);

                AppAnalytics.logUserRegistered(FirebaseAnalytics.logEvent);

                setIsInitialSyncInProgress(true);
            } catch (error) {
                handleSignUpFlowError({
                    error,
                    setAuthErrorCode: setProviderErrorCode,
                });
            } finally {
                setIsAuthInProgress(false);
                onSubmit();
            }
        },
        [
            getDatabase,
            providerData.email,
            providerData.displayName,
            authenticateUser,
            allowUserToEnter,
            setIsUserAfterSignUp,
            setIsInitialSyncInProgress,
            onSubmit,
        ],
    );

    const form = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: finishProviderSignUp,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.xeroSignUpForm,
    });

    const handleProviderSignUpSubmit = useCallback(
        () => form.handleSubmit(),
        [form],
    );

    const onPhoneNumberChange = useCallback(
        (
            phone: string,
            data: {
                name: string;
                dialCode: string;
                countryCode: string;
            },
        ) => {
            form.setFieldValue('phone', phone);
            form.setFieldValue('prefix', data.dialCode);
        },
        [form],
    );

    const handleEmailSubscriptionCheckboxClick = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            form.setFieldValue('emailSubscription', event.target.checked);
        },
        [form],
    );

    const onLanguageChange = useCallback(
        (language: LOCALE) => form.setFieldValue('language', language),
        [form],
    );

    const onRegionChange = useCallback(
        (country: COUNTRY) => form.setFieldValue('region', country),
        [form],
    );

    const setAcceptTermsTouched = useCallback(
        () =>
            setTimeout(() => {
                if (!form.touched.acceptTerms) {
                    form.setFieldTouched('acceptTerms', true, true);
                }
            }),
        [form],
    );

    const handleAcceptTermsCheckboxClick = useCallback(
        (checked: boolean) => {
            form.setFieldValue('acceptTerms', checked);
            setAcceptTermsTouched();
        },
        [form, setAcceptTermsTouched],
    );

    return (
        <Dialog open>
            <DialogTitle>{t('SignUp:provider:dialog:xero:title')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ whiteSpace: 'pre-wrap', mb: 4 }}>
                    {t('SignUp:provider:dialog:xero:description')}
                </DialogContentText>
                {providerErrorCode ? (
                    <ServerErrorAlert errorCode={providerErrorCode} />
                ) : null}
                <Box component="form" onSubmit={form.handleSubmit}>
                    <PhoneNumber
                        data-test-id="SignUpPage-PhoneInput"
                        error={!!form.errors.phone && !!form.touched.phone}
                        helperText={form.touched.phone ? form.errors.phone : ''}
                        fullWidth
                        id="phone"
                        InputLabelProps={{ shrink: true }}
                        label={t('SignUp:label:phone_number')}
                        name="phone"
                        defaultCountry={form.values.region?.toLowerCase()}
                        sx={{ mb: 3 }}
                        onChange={onPhoneNumberChange}
                    />
                    <LocaleSelector
                        onLanguageChange={onLanguageChange}
                        onRegionChange={onRegionChange}
                        languageValue={form.values.language}
                        regionValue={form.values.region}
                        testIdPrefix="ProviderAccountRegistrationDialog"
                    />
                    <AcceptTermsCheckbox
                        checked={form.values.acceptTerms}
                        onValueChange={handleAcceptTermsCheckboxClick}
                        hasError={
                            !!form.errors.acceptTerms &&
                            !!form.touched.acceptTerms
                        }
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormControlLabel
                            componentsProps={{
                                typography: {
                                    fontSize: 12,
                                },
                            }}
                            control={
                                <Checkbox
                                    checked={form.values.emailSubscription}
                                    onChange={
                                        handleEmailSubscriptionCheckboxClick
                                    }
                                />
                            }
                            label={t('SignUp:label:email_subscription')}
                            sx={{ mb: -0.5, mt: -0.5 }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelProviderSignUp}>
                    {t('Actions:cancel')}
                </Button>
                <Button
                    type="submit"
                    onClick={handleProviderSignUpSubmit}
                    disabled={isAuthInProgress}
                >
                    {isAuthInProgress ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        t('SignUp:provider:dialog:xero:confirm')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProviderAccountRegistrationDialog;
