import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

import { COLOR } from '@/theme/colors';

const BANNER_DEFAULT_HEIGHT = 52;

interface BannerProps extends Omit<AnimateHeightProps, 'height'> {
    height?: number;
}

export function Banner({
    children,
    height = BANNER_DEFAULT_HEIGHT,
    className,
    ...props
}: BannerProps) {
    return (
        <AnimateHeight
            duration={200}
            className={clsx(
                'bg-errorRed fixed bottom-0 left-0 right-0',
                className,
            )}
            height={height}
            {...props}
        >
            <div className="flex flex-row h-full justify-center items-center">
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '19.5px',
                        color: COLOR.white,
                    }}
                >
                    {children}
                </Typography>
            </div>
        </AnimateHeight>
    );
}
