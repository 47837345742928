import {
    HORSE_BREED,
    HORSE_BREEDS_DICTIONARY,
} from '../constants/horses/breeds';
import {
    HORSE_WORK_TYPE,
    HORSE_WORK_TYPE_DICTIONARY,
} from '../constants/horses/workTypes';
import {
    HORSE_GENDER,
    HORSE_GENDERS_DICTIONARY,
} from '../constants/horses/genders';

type TranslateFn = (translationKey: string) => string;
type HorseBreedItem = { name: string; value: HORSE_BREED };
type HorseGenderItem = { name: string; value: HORSE_GENDER };
type HorseShoeCycleItem = { name: string; value: string };
type HorseWorkTypeItem = { name: string; value: HORSE_WORK_TYPE };

export function getHorseBreeds(translateFn: TranslateFn): HorseBreedItem[] {
    const horseBreedsList = Object.keys(HORSE_BREEDS_DICTIONARY).reduce(
        (acc, key: HORSE_BREED) => {
            return [
                ...acc,
                {
                    name: translateFn(HORSE_BREEDS_DICTIONARY[key]),
                    value: key,
                },
            ];
        },
        [],
    );

    return horseBreedsList;
}

export function getHorseGenders(translateFn: TranslateFn): HorseGenderItem[] {
    const horseGendersList = Object.keys(HORSE_GENDERS_DICTIONARY).reduce(
        (acc, key: HORSE_GENDER) => {
            return [
                ...acc,
                {
                    name: translateFn(HORSE_GENDERS_DICTIONARY[key]),
                    value: key,
                },
            ];
        },
        [],
    );

    return horseGendersList;
}

export function getHorseShoeingCycleWeeks(
    translateFn: TranslateFn,
): HorseShoeCycleItem[] {
    const weeks: HorseShoeCycleItem[] = [];

    for (let i = 1; i <= 12; i++) {
        weeks.push({
            name: `${i} ${translateFn(
                i === 1 ? 'Generic:week' : 'Generic:weeks',
            )}`,
            value: i.toString(),
        });
    }

    return weeks;
}

export function getHorseWorkTypes(
    translateFn: TranslateFn,
): HorseWorkTypeItem[] {
    const horseWorkTypesList = Object.keys(HORSE_WORK_TYPE_DICTIONARY).reduce(
        (acc, key: HORSE_WORK_TYPE) => {
            return [
                ...acc,
                {
                    name: translateFn(HORSE_WORK_TYPE_DICTIONARY[key]),
                    value: key,
                },
            ];
        },
        [],
    );

    return horseWorkTypesList;
}
