import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { HorseModel } from 'shared/types/Horses';

import { HorseIcon } from '@/assets/svg';
import { HorsesList, SelectionOutlinedBox } from '@/components';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import DividerSelectSection from '../DividerSelectSection';

import { SelectHorsesModal } from './components';
import { Props } from './types';

function SelectHorsesSection({
    allowContactsSelection = false,
    allowToInteract = true,
    buttonsPosition = 'top',
    CreateHorseModal,
    contactsToFilterHorsesBy,
    CreateContactModal,
    horsesToSelect,
    multiSelect = true,
    onRemoveHorse,
    onSelectHorses,
    selectedHorses,
    testIdPrefix,
    variant = 'default',
}: Props) {
    const [isSelectHorsesModalOpen, setIsSelectHorsesModalOpen] =
        useState(false);

    const openSelectHorsesModal = useCallback(() => {
        if (allowToInteract) {
            setIsSelectHorsesModalOpen(true);
        } else {
            Snackbar.showToastNotification({
                message: t('Entry:alert:add_horse_when_invoiced'),
                options: {
                    variant: 'info',
                },
            });
        }
    }, [allowToInteract]);

    const closeSelectHorsesModal = useCallback(() => {
        setIsSelectHorsesModalOpen(false);
    }, []);

    const [isAddHorseModalOpen, setIsAddHorseModalOpen] =
        useState<boolean>(false);

    const openAddHorseModal = useCallback(() => {
        if (allowToInteract) {
            setIsAddHorseModalOpen(true);
        } else {
            Snackbar.showToastNotification({
                message: t('Entry:alert:add_horse_when_invoiced'),
                options: {
                    variant: 'info',
                },
            });
        }
    }, [allowToInteract]);

    const closeAddHorseModal = useCallback(
        () => setIsAddHorseModalOpen(false),
        [setIsAddHorseModalOpen],
    );

    const addCreatedHorseToContact = useCallback(
        (horse: HorseModel) =>
            onSelectHorses(multiSelect ? [...selectedHorses, horse] : horse),
        [multiSelect, onSelectHorses, selectedHorses],
    );

    const handleRemoveHorse = useCallback(
        (horse: HorseModel) => {
            if (allowToInteract) {
                onRemoveHorse(horse);
            } else {
                Snackbar.showToastNotification({
                    message: t('Entry:alert:remove_horse_when_invoiced'),
                    options: {
                        variant: 'info',
                    },
                });
            }
        },
        [allowToInteract, onRemoveHorse],
    );

    const renderOutlinedLayout = variant === 'outlined';

    const title = multiSelect ? t('Contact:horses') : t('Contact:horse');

    const selectLabel = t(
        multiSelect ? 'HorsesSelection:select_multi' : 'HorsesSelection:select',
    );

    const listContent = (
        <>
            {!selectedHorses.length ? (
                <Typography
                    sx={
                        renderOutlinedLayout
                            ? {
                                  display: 'flex',
                                  alignSelf: 'center',
                                  justifyContent: 'center',
                                  flex: 1,
                              }
                            : { mt: 1 }
                    }
                    color={COLOR.regentGray}
                    data-test-id={`${testIdPrefix}-SelectedHorsesEmptyStateText`}
                    variant="body2"
                >
                    {t(
                        multiSelect
                            ? 'Contact:horses_blankslate'
                            : 'Contact:horse_blankslate',
                    )}
                </Typography>
            ) : (
                <HorsesList
                    testIdPrefix={testIdPrefix}
                    items={selectedHorses}
                    onRemove={handleRemoveHorse}
                    removable
                    itemWrapperStyle={`border border-gray-200 ${
                        multiSelect ? 'w-[48%]' : 'w-full'
                    } ${!renderOutlinedLayout ? 'mb-4' : ''}`}
                />
            )}
            <SelectHorsesModal
                contactsToFilterHorsesBy={contactsToFilterHorsesBy}
                close={closeSelectHorsesModal}
                isOpen={isSelectHorsesModalOpen}
                onSelectHorses={onSelectHorses}
                selectedHorses={selectedHorses}
                multiSelect={multiSelect}
                horsesToSelect={horsesToSelect}
            />
            {isAddHorseModalOpen ? (
                <CreateHorseModal
                    allowContactsSelection={allowContactsSelection}
                    close={closeAddHorseModal}
                    closeOnSubmit
                    CreateContactModal={CreateContactModal}
                    forceContactsSelection={allowContactsSelection}
                    onSave={addCreatedHorseToContact}
                />
            ) : null}
        </>
    );

    const boxTestId = 'SelectHorsesSection';

    return !renderOutlinedLayout ? (
        <DividerSelectSection
            buttonsPosition={buttonsPosition}
            onAdd={openAddHorseModal}
            onSelect={openSelectHorsesModal}
            addLabel={t('HorsesSelection:create')}
            selectLabel={selectLabel}
            addButtonTestId={`${testIdPrefix}-CreateHorsesButton`}
            selectButtonTestId={`${testIdPrefix}-SelectHorsesButton`}
            testId={boxTestId}
            title={title}
        >
            {listContent}
        </DividerSelectSection>
    ) : (
        <SelectionOutlinedBox
            title={title}
            onAdd={openAddHorseModal}
            onSelect={openSelectHorsesModal}
            addLabel={t('HorsesSelection:create')}
            selectLabel={selectLabel}
            SelectIcon={HorseIcon}
            buttonsPosition={buttonsPosition}
            addButtonTestId={`${testIdPrefix}-CreateHorsesButton`}
            selectButtonTestId={`${testIdPrefix}-SelectHorsesButton`}
            testId={boxTestId}
        >
            {listContent}
        </SelectionOutlinedBox>
    );
}

export default SelectHorsesSection;
