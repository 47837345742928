import { renderToastNotification } from '@/components';
import { snackbarInstanceRef } from '@/context/SnackbarContext';
import { ShowToastNotificationParams } from '@/types/toastNotifications';

function showToastNotification({
    customContent,
    message,
    options,
}: ShowToastNotificationParams): void {
    const snackbarInstance = snackbarInstanceRef.current;

    snackbarInstance?.enqueueSnackbar(message, {
        content: (id: string, message: string) =>
            renderToastNotification({
                close: () => snackbarInstance?.closeSnackbar(id),
                customContent,
                id,
                message,
                showCloseButton: options?.persist ?? true,
                variant: options?.variant,
            }),
        ...options,
    });
}

export const Snackbar = {
    showToastNotification,
};
