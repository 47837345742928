import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTE } from '@/router/routes';

interface BannerProps {
    children: React.ReactNode;
    className: string;
    style: React.CSSProperties;
}

export function BannerLink({ children, className, style }: BannerProps) {
    return (
        <Link to={ROUTE.scheduleUpdates} className={className} style={style}>
            {children}
        </Link>
    );
}

export function BannerButton({ children, className, style }: BannerProps) {
    return (
        <Box className={className} style={style}>
            {children}
        </Box>
    );
}
