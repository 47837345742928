import { Box } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ENTRIES_FILTER,
    ENTRIES_INVOICING_FILTERS_DICTIONARY,
    ENTRIES_SHOW_ENTRIES_FILTERS_DICTIONARY,
} from 'shared/constants/entries/filters';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';

import { Select } from '@/components';
import {
    getEntriesJobTypeFilters,
    getInvoicingFilters,
    getShowEntriesFilters,
} from '@/helpers/entries/filters';
import { renderSelectedFilterValues } from '@/helpers/filters';

import { Props } from './types';

function GeneralFiltersSection({ filtersState, onFiltersChange }: Props) {
    const { t } = useTranslation();

    const jobTypes = useMemo(() => getEntriesJobTypeFilters(), []);

    const showEntriesFiltersOptions = useMemo(
        () => getShowEntriesFilters(),
        [],
    );
    const invoicingFiltersOptions = useMemo(() => getInvoicingFilters(), []);

    return (
        <Box className="border-b pb-7 mb-6 items-center flex flex-row">
            <Box className="mr-6">
                <Select
                    label={t('EntriesList:filters:job_type')}
                    multiselect
                    onChange={onFiltersChange(ENTRIES_FILTER.job_type)}
                    options={jobTypes}
                    renderSelectedValue={renderSelectedFilterValues(
                        ENTRIES_TITLES_DICTIONARY,
                    )}
                    selectedValue={filtersState[ENTRIES_FILTER.job_type]}
                    testID="EntriesPage-FiltersPanel-SelectJobType"
                />
            </Box>
            <Box className="mr-6">
                <Select
                    label={t('EntriesList:filters:show_entries:label')}
                    multiselect
                    onChange={onFiltersChange(ENTRIES_FILTER.horse_assigned)}
                    options={showEntriesFiltersOptions}
                    renderSelectedValue={renderSelectedFilterValues(
                        ENTRIES_SHOW_ENTRIES_FILTERS_DICTIONARY,
                    )}
                    selectedValue={filtersState[ENTRIES_FILTER.horse_assigned]}
                    testID="EntriesPage-FiltersPanel-ShowEntries"
                />
            </Box>
            <Box className="mr-6">
                <Select
                    label={t('EntriesList:filters:invoicing:label')}
                    multiselect
                    onChange={onFiltersChange(ENTRIES_FILTER.invoicing)}
                    options={invoicingFiltersOptions}
                    renderSelectedValue={renderSelectedFilterValues(
                        ENTRIES_INVOICING_FILTERS_DICTIONARY,
                    )}
                    selectedValue={filtersState[ENTRIES_FILTER.invoicing]}
                    testID="EntriesPage-FiltersPanel-Invoicing"
                />
            </Box>
        </Box>
    );
}

export default memo(GeneralFiltersSection);
