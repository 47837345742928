import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react';

import { Tooltip } from '@/components';

import { Props } from './types';

function TooltipSyncButton(props: Props) {
    const {
        endIcon,
        fullWidth,
        showTooltip,
        tooltip,
        sx,
        stylesAt,
        color,
        startIcon,
        onClick,
        testID,
        variant,
        type,
        disabled,
        children,
        tooltipPlacement,
        size,
    } = props;

    const isButtonStyle = useMemo(
        () => !stylesAt || stylesAt === 'button',
        [stylesAt],
    );

    const CustomButton = useMemo(
        () => (
            <Button
                sx={isButtonStyle ? sx : undefined}
                color={color}
                disabled={disabled}
                fullWidth={fullWidth}
                startIcon={startIcon}
                onClick={onClick}
                data-test-id={testID}
                variant={variant}
                type={type}
                size={size}
                endIcon={endIcon}
            >
                {children}
            </Button>
        ),
        [
            children,
            color,
            disabled,
            endIcon,
            fullWidth,
            isButtonStyle,
            onClick,
            size,
            startIcon,
            sx,
            testID,
            type,
            variant,
        ],
    );

    if (showTooltip) {
        return (
            <Tooltip title={tooltip ?? ''} placement={tooltipPlacement}>
                {disabled ? (
                    <Box
                        sx={
                            !isButtonStyle
                                ? { ...sx, cursor: 'default' }
                                : { cursor: 'default' }
                        }
                    >
                        {CustomButton}
                    </Box>
                ) : (
                    CustomButton
                )}
            </Tooltip>
        );
    } else {
        return CustomButton;
    }
}

export default TooltipSyncButton;
