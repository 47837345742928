import { Alert } from '@mui/material';
import React, { memo } from 'react';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';

import { t } from '@/services/translations/config';

import { Props } from './types';

function renderContent(errorCode: number | FIREBASE_AUTH_ERROR_CODE) {
    switch (errorCode) {
        case FIREBASE_AUTH_ERROR_CODE.invalid_email:
        case FIREBASE_AUTH_ERROR_CODE.user_not_found:
        case FIREBASE_AUTH_ERROR_CODE.wrong_password:
        case 401:
            return t('SignIn:error:email_and_password_do_not_match');

        case FIREBASE_AUTH_ERROR_CODE.expired_action_code:
        case FIREBASE_AUTH_ERROR_CODE.invalid_action_code:
            return t('NewPassword:error:invalid_link');
        case FIREBASE_AUTH_ERROR_CODE.email_already_in_use:
            return t('SignUp:error:email_is_already_used');
        default:
            return t('Generic:error:server_encountered_temporary_error');
    }
}

function SignInFlowServerErrorAlert({ error, testId }: Props) {
    const { errorCode, message } = error;
    return errorCode || message ? (
        <Alert data-test-id={testId} severity="error" sx={{ mb: 4 }}>
            <span
                dangerouslySetInnerHTML={{
                    __html: message
                        ? message
                        : errorCode
                        ? renderContent(errorCode)
                        : '',
                }}
            />
        </Alert>
    ) : null;
}

export default memo(SignInFlowServerErrorAlert);
