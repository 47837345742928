import React from 'react';

import { COLOR } from '@/theme/colors';
import { SVGIconProps } from '@/types/svg';

function DeleteIcon({
    width = 25,
    height = 25,
    color = COLOR.gray2,
}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            viewBox="0 96 960 960"
            width={width}
            fill={color}
        >
            <path d="M261 936q-24 0-42-18t-18-42V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm106-146h60V391h-60v399Zm166 0h60V391h-60v399Z" />
        </svg>
    );
}

export default DeleteIcon;
