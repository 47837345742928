import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';

import { globalStyles } from '@/theme/globalStyles';
import { overrideComponents } from '@/theme/overrides';
import { palette } from '@/theme/palette';
import { shape } from '@/theme/shape';
import { typography } from '@/theme/typography';
import { Context } from '@/types/context';

const MUIThemeContextProvider = ({ children }: Context.MUITheme.Props) => {
    const themeOptions: ThemeOptions = useMemo(() => {
        return {
            palette,
            shape,
            typography,
        };
    }, []);

    const theme = createTheme(themeOptions);

    theme.components = overrideComponents(theme);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={globalStyles} />
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export { MUIThemeContextProvider };
