export interface InvoiceTaxesInterface {
    value: 'included' | 'excluded';
    name: string;
}

export const getInvoiceTaxesList = (
    t: (translationKey: string) => string,
): InvoiceTaxesInterface[] => {
    return [
        {
            value: 'excluded',
            name: t('Invoice:taxes:excluded'),
        },
        {
            value: 'included',
            name: t('Invoice:taxes:included'),
        },
    ];
};
