import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';

function storeItemInLocalStorage(
    key: WEB_STORAGE_KEYS,
    value: object | string | boolean,
) {
    localStorage.setItem(key, JSON.stringify(value));
}

function getItemFromLocalStorage<T>(key: WEB_STORAGE_KEYS): T | null {
    const item = localStorage.getItem(key);

    if (item) {
        return JSON.parse(item) as T;
    } else {
        return null;
    }
}

function removeItemFromLocalStorage(key: WEB_STORAGE_KEYS) {
    localStorage.removeItem(key);
}

export {
    storeItemInLocalStorage,
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
};
