import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function CalendarEventIcon({
    width = 20,
    height = 20,
    color = COLOR.white,
    className = '',
}: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            viewBox="0 -960 960 960"
            width={width}
            fill={color}
            className={className}
        >
            <path d="M596.817-220Q556-220 528-248.183q-28-28.183-28-69T528.183-386q28.183-28 69-28T666-385.817q28 28.183 28 69T665.817-248q-28.183 28-69 28ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Z" />
        </svg>
    );
}

export default CalendarEventIcon;
