import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InventoryIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3432_150798)">
                <rect
                    x="4.44922"
                    y="5.59949"
                    width="15.125"
                    height="15.4005"
                    rx="1"
                    stroke={color}
                    strokeWidth="2"
                />
                <rect
                    x="4.4375"
                    y="10.733"
                    width="15.125"
                    height="5.13351"
                    rx="1"
                    stroke={color}
                    strokeWidth="2"
                />
                <mask
                    id="path-3-outside-1_3432_150798"
                    maskUnits="userSpaceOnUse"
                    x="5.5"
                    y="-1"
                    width="13"
                    height="13"
                    fill="black"
                >
                    <rect fill="white" x="5.5" y="-1" width="13" height="13" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V4.5H8.5C7.94772 4.5 7.5 4.94772 7.5 5.5C7.5 6.05228 7.94772 6.5 8.5 6.5H11V9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55229 13 9V6.5H15.5C16.0523 6.5 16.5 6.05228 16.5 5.5C16.5 4.94772 16.0523 4.5 15.5 4.5H13V2Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V4.5H8.5C7.94772 4.5 7.5 4.94772 7.5 5.5C7.5 6.05228 7.94772 6.5 8.5 6.5H11V9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55229 13 9V6.5H15.5C16.0523 6.5 16.5 6.05228 16.5 5.5C16.5 4.94772 16.0523 4.5 15.5 4.5H13V2Z"
                    fill={color}
                />
                <path
                    d="M11 4.5V6.5H13V4.5H11ZM11 6.5H13V4.5H11V6.5ZM13 6.5V4.5H11V6.5H13ZM13 4.5H11V6.5H13V4.5ZM12 3C11.4477 3 11 2.55228 11 2H15C15 0.343146 13.6569 -1 12 -1V3ZM13 2C13 2.55228 12.5523 3 12 3V-1C10.3431 -1 9 0.343146 9 2H13ZM13 4.5V2H9V4.5H13ZM8.5 6.5H11V2.5H8.5V6.5ZM9.5 5.5C9.5 6.05228 9.05228 6.5 8.5 6.5V2.5C6.84315 2.5 5.5 3.84315 5.5 5.5H9.5ZM8.5 4.5C9.05228 4.5 9.5 4.94772 9.5 5.5H5.5C5.5 7.15685 6.84315 8.5 8.5 8.5V4.5ZM11 4.5H8.5V8.5H11V4.5ZM13 9V6.5H9V9H13ZM12 8C12.5523 8 13 8.44772 13 9H9C9 10.6569 10.3431 12 12 12V8ZM11 9C11 8.44772 11.4477 8 12 8V12C13.6569 12 15 10.6569 15 9H11ZM11 6.5V9H15V6.5H11ZM15.5 4.5H13V8.5H15.5V4.5ZM14.5 5.5C14.5 4.94772 14.9477 4.5 15.5 4.5V8.5C17.1569 8.5 18.5 7.15685 18.5 5.5H14.5ZM15.5 6.5C14.9477 6.5 14.5 6.05228 14.5 5.5H18.5C18.5 3.84315 17.1569 2.5 15.5 2.5V6.5ZM13 6.5H15.5V2.5H13V6.5ZM11 2V4.5H15V2H11Z"
                    fill="white"
                    mask="url(#path-3-outside-1_3432_150798)"
                />
            </g>
            <defs>
                <clipPath id="clip0_3432_150798">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.0117188)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default memo(InventoryIcon);
