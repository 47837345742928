import React, { memo } from 'react';

import { Modal } from '@/components';
import AddProductsSection from '@/components/AddProductsSection/AddProductsSection';
import { AddProductsProvider } from '@/context/AddProductsContext';
import { useBrowserExitPrompt } from '@/hooks';

import { Props } from './types';

function AddProductsModal({
    disableMainActionButton,
    hideProductTypes,
    icon,
    mainActionButtonCaption,
    onCancel,
    onMainAction,
    preSelectedProducts,
    showOftenUsedFilter = false,
    showInventoryFilter = false,
    showUnitTypes = false,
    title,
}: Props) {
    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    return (
        <Modal
            center
            disableRestoreFocus
            styles={`py-0 ${showUnitTypes ? 'max-w-5xl' : 'max-w-3xl'}`}
            isOpen
            testID="AddProductsModal"
        >
            <AddProductsProvider>
                <AddProductsSection
                    disableMainActionButton={disableMainActionButton}
                    hideProductTypes={hideProductTypes}
                    icon={icon}
                    mainActionButtonCaption={mainActionButtonCaption}
                    onCancel={onCancel}
                    onMainAction={onMainAction}
                    preSelectedProducts={preSelectedProducts}
                    showOftenUsedFilter={showOftenUsedFilter}
                    showInventoryFilter={showInventoryFilter}
                    showUnitTypes={showUnitTypes}
                    title={title}
                    testIdPrefix="AddProductsModal"
                />
            </AddProductsProvider>
        </Modal>
    );
}

export default memo(AddProductsModal);
