import { Box, Button, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { COLOR } from '@/theme/colors';

import CurrentTeamEntityBox from '../CurrentTeamEntityBox';
import TooltipSyncButton from '../TooltipButton';

import { Props } from './types';

function AddEditModalHeader({
    title,
    cancel,
    save,
    testIdPrefix,
    avatarUrl,
    teamName,
    backgroundColor,
    icon,
    sx,
}: Props) {
    const { t } = useTranslation();

    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            save();
        },
        [save],
    );

    const { isSyncInProgress } = useDBSyncContext();

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'sticky',
                top: 0,
                zIndex: 12,
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                backgroundColor,
                overflow: 'hidden',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                minHeight: '60px',
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
            >
                {icon}
                <Typography
                    sx={{ color: COLOR.white, ml: '13px' }}
                    component="h6"
                    fontWeight={700}
                >
                    {title}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {teamName ? (
                    <CurrentTeamEntityBox
                        avatarUrl={avatarUrl || ''}
                        teamName={teamName}
                    />
                ) : undefined}

                <Button
                    sx={{
                        mr: 3,
                        ml: 3,
                        color: COLOR.white,
                    }}
                    color="inherit"
                    data-test-id={`${testIdPrefix}-CancelButton`}
                    onClick={cancel}
                >
                    {t('Actions:cancel')}
                </Button>

                <TooltipSyncButton
                    sx={{
                        color: COLOR.white,
                        borderColor: COLOR.white,
                        borderWidth: '2px',
                        '&:hover': {
                            borderWidth: '2px',
                            borderColor: COLOR.white,
                        },
                    }}
                    tooltip={t('AddEditModalHeader:button_tooltip', {
                        defaultValue:
                            'Please wait for the synchronization to complete',
                    })}
                    showTooltip={isSyncInProgress}
                    disabled={isSyncInProgress}
                    testID={`${testIdPrefix}-SaveButton`}
                    variant="outlined"
                    type="submit"
                    onClick={handleClick}
                >
                    {t('Actions:save')}
                </TooltipSyncButton>
            </Box>
        </Box>
    );
}

export default AddEditModalHeader;
