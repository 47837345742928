import { useCallback, useEffect, useState } from 'react';

import { createFirebaseDynamicLink } from '@/services/firebase/linking';
import { OrganisationsAPI } from '@/services/networking/organisations';

function useOrganisationInvitation() {
    const [invitationUrl, setInvitationUrl] = useState<string | null>(null);

    const fetchInvitationUrl = useCallback(async () => {
        const response = await OrganisationsAPI.getOrganisationInvitation();
        const { invitation_url } = response.payload;

        const dynamicLink = await createFirebaseDynamicLink(invitation_url);
        setInvitationUrl(dynamicLink || null);
    }, []);

    useEffect(() => {
        fetchInvitationUrl();
    }, [fetchInvitationUrl]);

    return { invitationUrl };
}

export { useOrganisationInvitation };
