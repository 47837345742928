import { Box, Modal as MaterialModal } from '@mui/material';
import React, { PropsWithChildren, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Props } from './types';

export function Modal({
    children,
    isOpen,
    modalContentStyles,
    styles,
    testID,
    center = false,
    closeAfterTransition = false,
    hideBackdrop = false,
    disableRestoreFocus = false,
}: PropsWithChildren<Props>) {
    const stopClickPropagation = useCallback(
        (event) => event.stopPropagation(),
        [],
    );

    return (
        <MaterialModal
            data-test-id={testID}
            open={isOpen}
            closeAfterTransition={closeAfterTransition}
            hideBackdrop={hideBackdrop}
            disableRestoreFocus={disableRestoreFocus}
            sx={{
                overflowY: 'scroll',
                ...(center
                    ? {
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                      }
                    : {}),
            }}
            onClick={stopClickPropagation}
            className={modalContentStyles}
        >
            <Box
                className={twMerge(
                    'flex flex-col bg-white outline-none mx-auto my-6 py-9 rounded-xl shadow-lg max-w-3xl',
                    styles,
                )}
            >
                {children}
            </Box>
        </MaterialModal>
    );
}
