import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

import { TeamIcon } from '@/assets/svg';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function CurrentTeamEntityBox({ avatarUrl, teamName }: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '220px',
                height: '60px',
                px: '22px',
                borderLeftWidth: '1px',
                borderRightWidth: '1px',
                borderColor: COLOR.white15,
            }}
        >
            <Avatar
                data-test-id="OrganisationBox-Avatar"
                src={avatarUrl}
                sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 0.65,
                    backgroundColor: COLOR.eclipse,
                    mr: '10px',
                }}
                variant="square"
            >
                <TeamIcon height={20} width={20} color={'grey'} />
            </Avatar>
            <Typography
                sx={{
                    fontSize: 11,
                    lineHeight: '14px',
                    color: COLOR.white,
                }}
            >
                {t('CurrentTeamEntityBox:publishing', {
                    defaultValue: 'Publishing in Team',
                })}
                <Typography
                    component="span"
                    sx={{
                        fontSize: 11,
                        lineHeight: '14px',
                        fontWeight: 'bold',
                        color: COLOR.white,
                    }}
                >
                    {' '}
                    {teamName}
                </Typography>
            </Typography>
        </Box>
    );
}

export default CurrentTeamEntityBox;
