export interface EntryTitlesInterface {
    value: string;
    name: string;
}

const getEntryTitlesList = (
    t: (translationKey: string) => string,
): EntryTitlesInterface[] => {
    return [
        {
            value: 'service',
            name: t('Entry:titles:service'),
        },
        {
            value: 'lost_shoe',
            name: t('Entry:titles:lost_shoe'),
        },
        {
            value: 'emergency',
            name: t('Entry:titles:emergency'),
        },
        {
            value: 'vet_consult',
            name: t('Entry:titles:vet_consult'),
        },
        {
            value: 'assess_horse',
            name: t('Entry:titles:assess_horse'),
        },
        {
            value: 'full_set',
            name: t('Entry:titles:full_set'),
        },
        {
            value: 'half_set_front',
            name: t('Entry:titles:half_set_front'),
        },
        {
            value: 'half_set_hinds',
            name: t('Entry:titles:half_set_hinds'),
        },
        {
            value: 'full_trim',
            name: t('Entry:titles:full_trim'),
        },
        {
            value: 'trim_front',
            name: t('Entry:titles:trim_front'),
        },
        {
            value: 'trim_hind',
            name: t('Entry:titles:trim_hind'),
        },
        {
            value: 'reset_remove',
            name: t('Entry:titles:reset_remove'),
        },
        {
            value: 'other',
            name: t('Entry:titles:other'),
        },
    ];
};

export { getEntryTitlesList };
