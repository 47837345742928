import { Box } from '@mui/material';
import React from 'react';

import { Props } from './types';

function Stepper({
    activeStep,
    nextButton,
    prevButton,
    steps,
    onStepClick,
}: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mx: '15px',
                mb: '15px',
            }}
        >
            {prevButton}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    alignItems: 'center',
                }}
            >
                {steps.map((step, i) => (
                    <Box
                        onClick={() => onStepClick(i)}
                        key={i}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            },
                            borderRadius: 10,
                            width: i === activeStep ? '14px' : '11px',
                            height: i === activeStep ? '14px' : '11px',
                            borderWidth: '2px',
                            borderColor: step.color,
                            backgroundColor:
                                i === activeStep ? step.color : undefined,
                        }}
                    />
                ))}
            </Box>
            {nextButton}
        </Box>
    );
}

export default Stepper;
