import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactModel } from 'shared/types/Contacts';

import { ContactsIcon } from '@/assets/svg';
import { ContactsList } from '@/components';
import { COLOR } from '@/theme/colors';

import SelectionOutlinedBox from '../SelectionOutlinedBox';

import { SelectContactsModal } from './components';
import { Props } from './types';

function SelectContactsSection({
    onRemoveContact,
    onSelectContacts,
    selectedContacts,
    CreateContactModal,
    CreateHorseModal,
    allowHorsesSelection = false,
    buttonsPosition = 'bottom',
    testIdPrefix,
    multiselect = true,
    disabled = false,
    displayContactInformation,
    renderExtraContentComponent,
    renderSelectContactModalItemContent,
}: Props) {
    const { t } = useTranslation();

    const [isSelectContactsModalOpen, setIsSelectContactsModalOpen] =
        useState(false);

    const openSelectContactsModal = useCallback(() => {
        setIsSelectContactsModalOpen(true);
    }, []);

    const closeSelectContactsModal = useCallback(() => {
        setIsSelectContactsModalOpen(false);
    }, []);

    const [isAddContactModalOpen, setIsAddContactModalOpen] =
        useState<boolean>(false);

    const openAddContactModal = useCallback(
        () => setIsAddContactModalOpen(true),
        [setIsAddContactModalOpen],
    );

    const closeAddContactModal = useCallback(
        () => setIsAddContactModalOpen(false),
        [setIsAddContactModalOpen],
    );

    const addCreatedContactToHorse = useCallback(
        (contact: ContactModel) =>
            onSelectContacts(
                multiselect ? [...selectedContacts, contact] : contact,
            ),
        [multiselect, onSelectContacts, selectedContacts],
    );

    const leftColumn = useMemo(
        () => selectedContacts.filter((_, index) => index % 2 === 0),
        [selectedContacts],
    );

    const rightColumn = useMemo(
        () => selectedContacts.filter((_, index) => index % 2 === 1),
        [selectedContacts],
    );

    return (
        <SelectionOutlinedBox
            disabled={disabled}
            onSelect={openSelectContactsModal}
            onAdd={openAddContactModal}
            title={t(
                multiselect ? 'HorseProfile:contacts' : 'HorseProfile:contact',
            )}
            addLabel={t('HorseProfile:create')}
            selectLabel={t(
                multiselect
                    ? 'HorseProfile:select'
                    : 'HorseProfile:single_select',
            )}
            SelectIcon={ContactsIcon}
            buttonsPosition={buttonsPosition}
            addButtonTestId={`${testIdPrefix}-CreateContactsButton`}
            selectButtonTestId={`${testIdPrefix}-SelectContactsButton`}
            testId="SelectContactsSection"
        >
            {!selectedContacts.length ? (
                <Typography
                    color={COLOR.regentGray}
                    variant="body2"
                    fontSize="small"
                    data-test-id={`${testIdPrefix}-SelectedContactsEmptyStateText`}
                    sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    {t(
                        multiselect
                            ? 'HorseProfile:contacts_blankslate'
                            : 'HorseProfile:contact_blankslate',
                    )}
                </Typography>
            ) : (
                <>
                    <Box
                        className={clsx('flex flex-col gap-[10px]', {
                            'w-[calc(50%-5px)]': multiselect,
                            'w-full': !multiselect,
                        })}
                    >
                        <ContactsList
                            testIdPrefix={testIdPrefix}
                            items={multiselect ? leftColumn : selectedContacts}
                            onRemove={onRemoveContact}
                            removable={!disabled}
                            itemWrapperStyle={clsx(
                                'border border-gray-200 min-h-[74px] w-full',
                            )}
                            size="large"
                            renderExtraContentComponent={
                                renderExtraContentComponent
                            }
                        />
                    </Box>
                    {multiselect ? (
                        <Box
                            className={clsx('flex flex-col gap-[10px]', {
                                'w-[calc(50%-5px)]': multiselect,
                                'w-full': !multiselect,
                            })}
                        >
                            <ContactsList
                                testIdPrefix={testIdPrefix}
                                items={rightColumn}
                                onRemove={onRemoveContact}
                                removable={!disabled}
                                itemWrapperStyle={clsx(
                                    'border border-gray-200 min-h-[74px] w-full',
                                )}
                                size="large"
                                renderExtraContentComponent={
                                    renderExtraContentComponent
                                }
                            />
                        </Box>
                    ) : null}
                </>
            )}
            <SelectContactsModal
                close={closeSelectContactsModal}
                isOpen={isSelectContactsModalOpen}
                onSelectContacts={onSelectContacts}
                selectedContacts={selectedContacts}
                multiSelect={multiselect}
                displayContactInformation={displayContactInformation}
                renderExtraContentComponent={
                    renderSelectContactModalItemContent
                }
            />
            {isAddContactModalOpen ? (
                <CreateContactModal
                    close={closeAddContactModal}
                    onSave={addCreatedContactToHorse}
                    CreateHorseModal={CreateHorseModal}
                    allowHorsesSelection={allowHorsesSelection}
                    isFromHorseCreation
                    closeOnSubmit
                />
            ) : null}
        </SelectionOutlinedBox>
    );
}

export default SelectContactsSection;
