import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    HorsesTableColumnDescription,
    HORSE_TABLE_COLUMN,
} from 'shared/constants/horses/sort';
import { SORT_TYPE } from 'shared/constants/sort';
import { EntryModel } from 'shared/types/Entries';
import { EventModel } from 'shared/types/Events';
import { HorseModel } from 'shared/types/Horses';
import { ColumnContent, SortObject } from 'shared/types/sort';

import {
    AddEditContactModal,
    AddEditEntryModal,
    AddEditHorseModal,
    AddEditScheduleModal,
    EmptyState,
} from '@/components';
import { useImagesContext } from '@/context/ImagesContext';
import { calculateSortValue } from '@/helpers/sort';
import { ROUTE } from '@/router/routes';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { HorsesTableItem } from './components';
import { Props } from './types';

const headerFontStyle: SxProps = {
    color: COLOR.paleSky,
    fontWeight: 700,
    fontSize: 13,
    marginBlock: 'auto',
};

const sortColumnStyle: SxProps = {
    display: 'flex',
};

function HorsesTable({
    emptyStateMessage,
    horses,
    refetchHorses,
    sortState,
    sortChange,
}: Props) {
    const { images, ImagesService } = useImagesContext();
    const navigate = useNavigate();

    const [horseToEdit, setHorseToEdit] = useState<HorseModel | null>(null);
    const [horseToAddEntry, setHorseToAddEntry] = useState<
        HorseModel | undefined
    >(undefined);
    const [horseToSchedule, setHorseToSchedule] = useState<
        HorseModel | undefined
    >(undefined);
    const [isAddEntryModalOpen, setIsAddEntryModalOpen] =
        useState<boolean>(false);
    const [isAddScheduleModalOpen, setIsAddScheduleModalOpen] =
        useState<boolean>(false);
    const [isEditHorseModalOpen, setIsEditHorseModalOpen] = useState(false);

    const openAddScheduleModal = useCallback(
        () => setIsAddScheduleModalOpen(true),
        [],
    );

    const closeAddScheduleModal = useCallback(
        () => setIsAddScheduleModalOpen(false),
        [],
    );

    const navigateToAppointment = useCallback(
        (appointment: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, {
                id: appointment.id,
            });
            navigate(route);
        },
        [navigate],
    );

    const openAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(true),
        [],
    );

    const closeAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(false),
        [],
    );

    const navigateToEntry = useCallback(
        (entry: EntryModel) => {
            const route = getRoutePath(ROUTE.entry, {
                id: entry.id,
            });
            navigate(route);
        },
        [navigate],
    );

    const openEditHorseModal = useCallback(() => {
        setIsEditHorseModalOpen(true);
    }, []);

    const closeEditHorseModal = useCallback(() => {
        setIsEditHorseModalOpen(false);
        setHorseToEdit(null);
    }, []);

    const handleHorseTableAddScheduleItemButtonPress = useCallback(
        (horse: HorseModel) => {
            setHorseToSchedule(horse);
            openAddScheduleModal();
        },
        [openAddScheduleModal],
    );

    const handleHorseTableAddEntryItemButtonPress = useCallback(
        (horse: HorseModel) => {
            setHorseToAddEntry(horse);
            openAddEntryModal();
        },
        [openAddEntryModal],
    );

    const handleHorseTableItemEditButtonPress = useCallback(
        (horse: HorseModel) => {
            setHorseToEdit(horse);
            openEditHorseModal();
        },
        [openEditHorseModal],
    );

    const handleSortIconPress = useCallback(
        (column: ColumnContent) => {
            let newSort: SortObject;

            if (column.columnId !== sortState?.columnId) {
                newSort = {
                    columnId: column.columnId,
                    value: SORT_TYPE.DESC,
                };
            } else {
                const newSortValue = calculateSortValue(sortState.value);
                newSort = {
                    columnId: column.columnId,
                    value: newSortValue,
                    query: column.query,
                };
            }

            if (column.unsafeQuery) {
                newSort.unsafeQuery = column.unsafeQuery;
            } else {
                newSort.query = column.query;
            }

            sortChange(newSort);
        },
        [sortState, sortChange],
    );

    const displaySortIcon = useCallback(
        (columnId: string): JSX.Element => {
            if (sortState.columnId !== columnId) {
                return <UnfoldMoreIcon fontSize="small" />;
            }

            return sortState.value === SORT_TYPE.DESC ? (
                <KeyboardArrowDownIcon fontSize="small" />
            ) : (
                <KeyboardArrowUpIcon fontSize="small" />
            );
        },
        [sortState],
    );

    return (
        <>
            {isEditHorseModalOpen ? (
                <AddEditHorseModal
                    close={closeEditHorseModal}
                    horse={horseToEdit}
                    isEditMode
                    onSave={refetchHorses}
                    onRemove={refetchHorses}
                    CreateContactModal={AddEditContactModal}
                />
            ) : null}
            {isAddScheduleModalOpen ? (
                <AddEditScheduleModal
                    horses={horseToSchedule ? [horseToSchedule] : []}
                    onClose={closeAddScheduleModal}
                    onSubmit={navigateToAppointment}
                />
            ) : null}
            {isAddEntryModalOpen ? (
                <AddEditEntryModal
                    close={closeAddEntryModal}
                    horse={horseToAddEntry}
                    onSave={navigateToEntry}
                />
            ) : null}
            <TableContainer>
                {!horses.length ? (
                    <EmptyState
                        testID="HorsesTable-EmptyStateMessage"
                        message={emptyStateMessage}
                    />
                ) : null}
                {horses.length ? (
                    <Table
                        sx={{
                            minWidth: 650,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    data-test-id="HorsesTable-HorseColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            HorsesTableColumnDescription[
                                                HORSE_TABLE_COLUMN.HORSE
                                            ],
                                        )
                                    }
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography sx={headerFontStyle}>
                                            {t(
                                                'HorsesList:table_columns:horse',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'HorsesList:table_columns:horse',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    data-test-id="HorsesTable-ContactsColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            HorsesTableColumnDescription[
                                                HORSE_TABLE_COLUMN.CONTACT
                                            ],
                                        )
                                    }
                                    align="left"
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'HorsesList:table_columns:contact',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'HorsesList:table_columns:contact',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    data-test-id="HorsesTable-LocationColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            HorsesTableColumnDescription[
                                                HORSE_TABLE_COLUMN.LOCATION
                                            ],
                                        )
                                    }
                                    align="left"
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'HorsesList:table_columns:location',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'HorsesList:table_columns:location',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    data-test-id="HorsesTable-EntryColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            HorsesTableColumnDescription[
                                                HORSE_TABLE_COLUMN.RECENT_ENTRY
                                            ],
                                        )
                                    }
                                    align="left"
                                    width="18%"
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'HorsesList:table_columns:recent_entry',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'HorsesList:table_columns:recent_entry',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    data-test-id="HorsesTable-AppointmentColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            HorsesTableColumnDescription[
                                                HORSE_TABLE_COLUMN.APPOINTMENT
                                            ],
                                        )
                                    }
                                    align="left"
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'HorsesList:table_columns:appointment',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'HorsesList:table_columns:appointment',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {horses.map((horse) => {
                                const avatarUrl =
                                    ImagesService.getSingleImageByEntityId(
                                        images,
                                        horse.id,
                                    )?.imageURL;

                                return (
                                    <HorsesTableItem
                                        avatarUrl={avatarUrl}
                                        key={horse.id}
                                        horse={horse}
                                        onAddScheduleButtonPress={
                                            handleHorseTableAddScheduleItemButtonPress
                                        }
                                        onAddEntryButtonPress={
                                            handleHorseTableAddEntryItemButtonPress
                                        }
                                        onEditButtonPress={
                                            handleHorseTableItemEditButtonPress
                                        }
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : null}
            </TableContainer>
        </>
    );
}

export default memo(HorsesTable);
