import { Alert } from '@mui/material';
import React, { memo } from 'react';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';

import { t } from '@/services/translations/config';

import { Props } from './types';

function renderContent(errorCode: number | FIREBASE_AUTH_ERROR_CODE) {
    switch (errorCode) {
        case FIREBASE_AUTH_ERROR_CODE.email_already_in_use:
            return t('SignUp:error:email_is_already_used');

        case 403:
            return t('SignUp:error:phone_number_has_been_taken');

        default:
            return t('Generic:error:server_encountered_temporary_error');
    }
}

function ServerErrorAlert({ errorCode }: Props) {
    return (
        <Alert
            data-test-id="SignUpPage-ErrorAlert"
            severity="error"
            sx={{ mb: 4 }}
        >
            {renderContent(errorCode)}
        </Alert>
    );
}

export default memo(ServerErrorAlert);
