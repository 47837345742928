import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    InputAdornment,
    TextField,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validatePriceValue } from 'shared/utils/validation';

import { useRegion } from '@/hooks/useRegion';

import { Props } from './types';

function PriceEditingContent({
    closeModal,
    onCancelButtonClick,
    onSaveButtonClick,
    procedure,
}: Props) {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [procedurePrice, setProcedurePrice] = useState(
        procedure?.price || '0',
    );

    const userRegion = useRegion();
    const currency = userRegion?.currencySymbol || '$';

    const handleProcedurePriceChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setProcedurePrice(event.target.value);
        },
        [],
    );

    const handleSaveButtonClick = useCallback(async () => {
        if (!procedure) {
            return;
        }

        try {
            await validatePriceValue(procedurePrice, t);

            onSaveButtonClick(
                procedure.procedureId,
                Number(procedurePrice).toFixed(2),
            );
            closeModal();
        } catch (error) {
            setError(error);
        }
    }, [closeModal, onSaveButtonClick, procedure, procedurePrice, t]);

    return (
        <>
            <DialogTitle>
                {t('Procedure:update_price:title', {
                    procedure: procedure?.name || '',
                })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText data-test-id="AlertText">
                    {t('Procedure:update_price:explanation')}
                </DialogContentText>
                <TextField
                    data-test-id="EditProcedureDataModal-PriceEditing-PriceInput"
                    error={!!error}
                    fullWidth
                    helperText={error || ''}
                    id="price"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {currency}
                            </InputAdornment>
                        ),
                    }}
                    label={t('Procedure:label:price')}
                    onChange={handleProcedurePriceChange}
                    name="price"
                    sx={{ mt: 3 }}
                    value={procedurePrice}
                />
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button
                    onClick={onCancelButtonClick}
                    data-test-id="EditProcedureDataModal-PriceEditing-CancelButton"
                >
                    {t('Actions:cancel')}
                </Button>
                <Button
                    color="primary"
                    data-test-id="EditProcedureDataModal-PriceEditing-SaveButton"
                    onClick={handleSaveButtonClick}
                >
                    {t('Actions:save')}
                </Button>
            </DialogActions>
        </>
    );
}

export default PriceEditingContent;
