import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Procedure from 'shared/db/services/Procedure';
import { EntryProcedureToAdd } from 'shared/types/Entries';

import { SelectItemsModal } from '@/components';
import SelectableProceduresGroupedList from '@/components/SelectableProceduresGroupedList';
import { Props as ProceduresListProps } from '@/components/SelectableProceduresGroupedList/types';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { Props } from './types';

function SelectProceduresModal({
    selectedProcedures,
    onSelectProcedures,
    close,
    isOpen,
    showPrice = true,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const [selectedItems, setSelectedItems] = useState<EntryProcedureToAdd[]>(
        [],
    );

    const database = useMemo(() => {
        return getDatabase();
    }, [getDatabase]);

    const fetchProcedures = useCallback(async () => {
        const proceduresService = new Procedure({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
        });

        const procedures = await proceduresService.getAll();

        const entryProcedures = procedures
            .filter((procedure) => procedure.available)
            .map((procedure) => ({
                name: procedure.name,
                procedureId: procedure.id,
                price: procedure.price,
                quantity: 1,
                category: procedure.category,
            }));

        const entrySelectedProcedures: EntryProcedureToAdd[] = [];

        selectedProcedures.forEach((selectedProcedure) => {
            const entrySelectedProcedure = entryProcedures.find(
                (entryProcedure) =>
                    entryProcedure.procedureId ===
                    selectedProcedure.procedureId,
            );

            if (entrySelectedProcedure) {
                entrySelectedProcedures.push(entrySelectedProcedure);
            }
        });

        if (selectedProcedures) {
            setSelectedItems(entrySelectedProcedures);
        }

        return entryProcedures;
    }, [ImagesService, database, selectedProcedures]);

    return (
        <SelectItemsModal<EntryProcedureToAdd, ProceduresListProps>
            isOpen={isOpen}
            ListComponent={SelectableProceduresGroupedList}
            selectedItems={selectedItems}
            testIdPrefix="SelectProceduresModal"
            emptyMessage={t('ProceduresSelection:procedures_blankslate')}
            addSelectedItemsButtonText={t(
                'ProceduresSelection:add_selected_procedures',
            )}
            onSelectItems={onSelectProcedures}
            title={t('ProceduresSelection:select')}
            fetchItemsFn={fetchProcedures}
            close={close}
            listComponentProps={{
                testIdPrefix: 'SelectProceduresModal',
                selectable: true,
                itemWrapperStyles: 'px-4 itemSizeClass rounded-lg mb-2',
                backgroundColor: 'lightBlue',
                itemHeight: '14',
                showQuantity: false,
                groupProcedures: true,
                showPrice,
            }}
            selectedItemsCountTextKey="ProceduresSelection:selected_procedures_number"
            searchable={false}
        />
    );
}

export default SelectProceduresModal;
