import { OrganisationsResponse } from 'shared/types/api/user';

import apiClient from '@/services/networking/apiClient';
import { GetUserProfileResponsePayload } from '@/types/user';

async function getUserProfile(): Promise<GetUserProfileResponsePayload> {
    const { data } = await apiClient.get<GetUserProfileResponsePayload>(
        '/user_profile',
    );

    return data;
}

async function getOrganisations(): Promise<OrganisationsResponse> {
    const { data } = await apiClient.get<OrganisationsResponse>(
        '/organisations',
    );
    return data;
}

export const UserAPI = {
    getUserProfile,
    getOrganisations,
};
