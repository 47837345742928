import { Close, Done } from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { Props } from '@/components/AddProductsSection/components/ProductsList/components/ProductListRow/components/ProductItem/components/ProductIcon/types';

const commonIconStyle: SxProps = {
    width: 40,
    height: 36,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

function ProductIcon({ defaultIcon, onClick, sx, selected, testID }: Props) {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);
    const onMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    const selectedIcon = useMemo(
        () => (
            <Box
                sx={{
                    ...commonIconStyle,
                    backgroundColor: '#AAF27F',
                }}
            >
                <Done sx={{ color: '#08660D' }} />
            </Box>
        ),
        [],
    );

    const deletedIcon = useMemo(
        () => (
            <Box
                sx={{
                    ...commonIconStyle,
                    backgroundColor: '#FFE7D9',
                }}
            >
                <Close sx={{ color: '#B72136' }} />
            </Box>
        ),
        [],
    );

    const productIcon = useMemo(() => {
        if (selected && isHovered) {
            return deletedIcon;
        }

        if (selected) {
            return selectedIcon;
        }

        return defaultIcon;
    }, [selected, isHovered, defaultIcon, deletedIcon, selectedIcon]);

    return (
        <Box
            data-test-id={testID}
            sx={sx}
            onClick={onClick}
            onMouseEnter={selected ? onMouseEnter : undefined}
            onMouseLeave={selected ? onMouseLeave : undefined}
        >
            {productIcon}
        </Box>
    );
}

export default ProductIcon;
