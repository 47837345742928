import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Product from 'shared/db/services/Product';
import { EntryProductToAdd } from 'shared/types/Entries';
import { AddProductParams, ProductType } from 'shared/types/Products';
import {
    getAddProductsDictionary,
    getProductMaterialShapeAndClips,
    getProductName,
    getProductSize,
} from 'shared/utils/product';

import { AddToInventoryIcon } from '@/assets/svg';
import AddProductsModal from '@/components/AddProductsModal';
import EntryProductsList from '@/components/EntryProductsList';
import SelectionOutlinedBox from '@/components/SelectionOutlinedBox';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';

import { EditProductDataModal } from './components';
import { Props } from './types';

function SelectEntryProductsSection({
    entryProducts,
    onSaveQuantityButtonClick,
    onSelect,
    testIdPrefix,
    onRemove,
}: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { t } = useTranslation();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const productService = useMemo(
        () =>
            new Product({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const [editedProduct, setEditedProduct] =
        useState<EntryProductToAdd | null>(null);
    const [isEditProductDataModalOpen, setIsEditProductDataModalOpen] =
        useState(false);
    const [isSelectProductsModalOpen, setIsSelectProductsModalOpen] =
        useState(false);

    const openSelectProductsModal = useCallback(() => {
        setIsSelectProductsModalOpen(true);
    }, []);

    const closeSelectProductsModal = useCallback(() => {
        setIsSelectProductsModalOpen(false);
    }, []);

    const openEditProductDataModal = useCallback(() => {
        setIsEditProductDataModalOpen(true);
    }, []);

    const closeEditProductDataModal = useCallback(() => {
        setIsEditProductDataModalOpen(false);
    }, []);

    const handleSelectProducts = useCallback(
        async (selectedProducts: AddProductParams[]) => {
            if (selectedProducts?.length) {
                const productDictionary =
                    getAddProductsDictionary(selectedProducts);
                const ids = Object.keys(productDictionary);
                const products = await productService.getByIDs(ids);

                onSelect(
                    products.map((product) => {
                        const name = getProductName(product);
                        const materialShapeAndClips =
                            getProductMaterialShapeAndClips(product);

                        const productSize = getProductSize(product);

                        return {
                            name,
                            materialShapeAndClips,
                            quantity: parseInt(
                                productDictionary[product.id].quantity,
                                10,
                            ),
                            productId: product.id,
                            productType: product.productType,
                            size: productSize,
                        };
                    }),
                );

                closeSelectProductsModal();
            }
        },
        [closeSelectProductsModal, onSelect, productService],
    );

    const handleEditProductButtonClick = useCallback(
        (product) => {
            setEditedProduct(product);
            openEditProductDataModal();
        },
        [openEditProductDataModal],
    );

    const preselectedProducts: AddProductParams[] | null = useMemo(
        () =>
            entryProducts?.map((product) => {
                return {
                    id: product.productId,
                    quantity: product.quantity
                        ? product.quantity.toString()
                        : '0',
                } as AddProductParams;
            }) ?? null,
        [entryProducts],
    );

    return (
        <SelectionOutlinedBox
            title={t('AddEntryForm:labels:used_products')}
            onSelect={openSelectProductsModal}
            SelectIcon={Add}
            selectLabel={t('ProductSelection:select')}
            buttonsPosition="bottom"
            selectButtonTestId={`${testIdPrefix}-SelectProductsButton`}
            allowCreate={false}
        >
            {entryProducts?.length ? (
                <EntryProductsList
                    items={entryProducts}
                    testIdPrefix={testIdPrefix}
                    onEditButtonClick={handleEditProductButtonClick}
                    onRemove={onRemove}
                />
            ) : (
                <Typography
                    sx={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                    color={COLOR.regentGray}
                    variant="body2"
                    data-test-id={`${testIdPrefix}-SelectEntryProductsSection-EmptyMessage`}
                >
                    {t('AddEntryForm:products_blankslate')}
                </Typography>
            )}
            <EditProductDataModal
                close={closeEditProductDataModal}
                isOpen={isEditProductDataModalOpen}
                onSaveButtonClick={onSaveQuantityButtonClick}
                product={editedProduct}
            />
            {isSelectProductsModalOpen ? (
                <AddProductsModal
                    hideProductTypes={[
                        ProductType.tools,
                        ProductType.rasps,
                        ProductType.other,
                    ]}
                    icon={
                        <Box
                            sx={{
                                width: 40,
                                height: 36,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AddToInventoryIcon color={COLOR.deepCerulean} />
                        </Box>
                    }
                    mainActionButtonCaption={t('ProductSelection:add_to_entry')}
                    onMainAction={handleSelectProducts}
                    onCancel={closeSelectProductsModal}
                    preSelectedProducts={preselectedProducts}
                    showOftenUsedFilter
                    showInventoryFilter
                    title={t('ProductSelection:add_to_entry_title')}
                />
            ) : null}
        </SelectionOutlinedBox>
    );
}

export default SelectEntryProductsSection;
