import {
    ENTRIES_INVOICING_FILTERS_DICTIONARY,
    ENTRIES_SHOW_ENTRIES_FILTERS_DICTIONARY,
} from 'shared/constants/entries/filters';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';
import { EntriesFiltersObject } from 'shared/types/entriesFilters';
import moment from 'shared/utils/moment';

import { generateSelectOptionsFromDictionary } from '@/helpers/filters';
import { SelectOption } from '@/types/select';

export function getShowEntriesFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(
        ENTRIES_SHOW_ENTRIES_FILTERS_DICTIONARY,
    );
}

export function getInvoicingFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(
        ENTRIES_INVOICING_FILTERS_DICTIONARY,
    );
}

export function getEntriesJobTypeFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(ENTRIES_TITLES_DICTIONARY);
}

export function getInitialDateRangeFilterValue(): EntriesFiltersObject['date_range'] {
    const todayDate = moment().endOf('day').toDate();
    const monthAgoDate = moment(todayDate)
        .subtract(1, 'months')
        .endOf('day')
        .toDate();

    return {
        isActive: false,
        from: moment(monthAgoDate),
        to: moment(todayDate),
    };
}
