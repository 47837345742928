import { Box } from '@mui/material';
import React from 'react';
import { APPLE_APP_STORE_APP_URL } from 'shared/constants/externalUrls';
import { getAppStoreAppUrl } from 'shared/utils/externalUrls';

import AppStoreBadgeImage from '@/assets/images/apple-app-store-badge.svg';
import { useRegion } from '@/hooks/useRegion';

import ExternalLink from '../ExternalLink';

function AppStoreBadgeLink() {
    const userRegion = useRegion();
    const appStoreUrl = userRegion?.code
        ? getAppStoreAppUrl(userRegion.code)
        : APPLE_APP_STORE_APP_URL;

    return (
        <Box className="transition-transform hover:scale-110">
            <ExternalLink href={appStoreUrl}>
                <img src={AppStoreBadgeImage} className="h-[45px] w-[135px]" />
            </ExternalLink>
        </Box>
    );
}

export default AppStoreBadgeLink;
