import { Box } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { EventModel } from 'shared/types/Events';
import { HorseModel } from 'shared/types/Horses';
import { InvoiceModel } from 'shared/types/Invoice';
import { ROLE } from 'shared/types/OrganisationUser';

import {
    AddEditContactModal,
    AddEditHorseModal,
    AddEditScheduleModal,
    EmptyState,
    NotesSection,
} from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import HorsesSection from '@/components/HorsesSection';
import SchedulesSection from '@/components/SchedulesSection';
import { useImagesContext } from '@/context/ImagesContext';
import { useInvoicingSystemContext } from '@/context/InvoicingSystemContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { ROUTE } from '@/router/routes';
import { FirestoreImagesDocument } from '@/types/firestore';
import { getRoutePath } from '@/utils/router';

import { HeaderSection, InvoicesSection } from './components';
import { Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

function ContactDetails({
    contact,
    contactEvents,
    contactHorses,
    contactInvoices,
    isLoading,
    isPhotoLoading,
    images,
}: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { ImagesService } = useImagesContext();
    const { orgUser } = useOrgUserContext();

    const [isAddHorseModalOpen, setIsHorseModalOpen] = useState(false);
    const [isAddInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
    const [isAddAppointmentModalOpen, setIsAddAppointmentModalOpen] =
        useState(false);

    const { isInvoicingAvailable } = useInvoicingSystemContext();

    const openAddHorseModal = useCallback(() => setIsHorseModalOpen(true), []);

    const closeAddHorseModal = useCallback(
        () => setIsHorseModalOpen(false),
        [],
    );

    const openAddInvoiceModal = useCallback(
        () => setIsInvoiceModalOpen(true),
        [],
    );

    const closeAddInvoiceModal = useCallback(
        () => setIsInvoiceModalOpen(false),
        [],
    );

    const openAddAppointmentModal = useCallback(
        () => setIsAddAppointmentModalOpen(true),
        [],
    );

    const closeAddAppointmentModal = useCallback(
        () => setIsAddAppointmentModalOpen(false),
        [],
    );

    const navigateToInvoicesFallback = useCallback(() => {
        navigate(ROUTE.invoices);
    }, [navigate]);

    const navigateToNewInvoicePage = useCallback(
        (invoice: InvoiceModel) => {
            const route = getRoutePath(ROUTE.invoice, { id: invoice.id });

            navigate(route);
        },
        [navigate],
    );

    const navigateToNewAppointmentPage = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, { id: event.id });

            navigate(route);
        },
        [navigate],
    );

    const navigateToNewHorsePage = useCallback(
        (horse: HorseModel) => {
            const route = getRoutePath(ROUTE.horse, { id: horse.id });

            navigate(route);
        },
        [navigate],
    );

    const handleAddNewInvoiceClick = useCallback(() => {
        isInvoicingAvailable
            ? openAddInvoiceModal()
            : navigateToInvoicesFallback();
    }, [isInvoicingAvailable, openAddInvoiceModal, navigateToInvoicesFallback]);

    const avatar = useMemo<FirestoreImagesDocument | null | undefined>(
        () =>
            contact
                ? ImagesService.getSingleImageByEntityId(images, contact.id)
                : null,
        [images, contact, ImagesService],
    );

    const avatarUrl = avatar?.imageURL ?? '';

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    const notesSection = (
        <Box
            data-test-id="ContactPage-NotesSection"
            sx={{ width: '100%', ...sectionStyles }}
        >
            <NotesSection
                containerTestId={'ContactNotesSection'}
                textTestId={'ContactPage-NotesText'}
                comments={contact?.comments || ''}
                isLoading={isLoading}
            />
        </Box>
    );

    return !isLoading && !contact ? (
        <EmptyState message={t('Contact:not_found')} />
    ) : (
        <div>
            <HeaderSection
                key={contact?.id}
                contact={contact}
                avatarUrl={avatarUrl}
                isLoading={isLoading}
                isPhotoLoading={isPhotoLoading}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="ContactPage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <SchedulesSection
                            events={contactEvents || []}
                            isLoading={isLoading}
                            onAddButtonClick={openAddAppointmentModal}
                            showAddButton
                            testIdPrefix={'ContactSchedulesSection'}
                        />
                    </Box>
                    <Box
                        data-test-id="ContactPage-InvoicesSection"
                        sx={sectionStyles}
                    >
                        {isOrgUserAdmin ? (
                            <InvoicesSection
                                invoices={contactInvoices || []}
                                isLoading={isLoading}
                                onAddButtonClick={handleAddNewInvoiceClick}
                                showAddButton
                            />
                        ) : null}
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="ContactPage-HorsesSection"
                        sx={sectionStyles}
                    >
                        <HorsesSection
                            horses={contactHorses || []}
                            isLoading={isLoading}
                            onAddButtonClick={openAddHorseModal}
                            showAddButton
                            testIdPrefix={'ContactHorsesSection'}
                        />
                    </Box>
                    {isOrgUserAdmin ? notesSection : null}
                </Box>
                {!isOrgUserAdmin ? notesSection : null}
            </Box>
            {isAddHorseModalOpen ? (
                <AddEditHorseModal
                    CreateContactModal={AddEditContactModal}
                    close={closeAddHorseModal}
                    contact={contact || undefined}
                    onSave={navigateToNewHorsePage}
                />
            ) : null}
            {isAddInvoiceModalOpen ? (
                <AddEditInvoiceModal
                    close={closeAddInvoiceModal}
                    contact={contact || undefined}
                    onSave={navigateToNewInvoicePage}
                />
            ) : null}
            {isAddAppointmentModalOpen ? (
                <AddEditScheduleModal
                    contacts={contact ? [contact] : []}
                    horses={contactHorses || []}
                    onClose={closeAddAppointmentModal}
                    onSubmit={navigateToNewAppointmentPage}
                />
            ) : null}
        </div>
    );
}

const enhance = withObservables<Props, unknown>(['contact'], ({ contact }) => {
    return {
        contactEvents: contact
            ? contact.events.extend(Q.sortBy('starts_time', Q.desc))
            : of([]),
        contactHorses: contact
            ? contact.horses.extend(
                  Q.where('hidden', false),
                  Q.sortBy('name', Q.asc),
              )
            : of([]),
        contactInvoices: contact
            ? contact.invoices.extend(Q.sortBy('created_at', Q.desc))
            : of([]),
    };
});

export default enhance(ContactDetails);
