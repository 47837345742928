import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function HamburgerIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill="none">
            <path
                d="M5 12c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z"
                fill={color}
            />
            <path
                d="M7.94 11h12.12c0.519 0 0.94 0.421 0.94 0.94v0.12c0 0.519-0.421 0.94-0.94 0.94h-12.12c-0.519 0-0.94-0.421-0.94-0.94v-0.12c0-0.519 0.421-0.94 0.94-0.94z"
                fill={color}
            />
            <path
                d="M3.94 16h16.12c0.519 0 0.94 0.421 0.94 0.94v0.12c0 0.519-0.421 0.94-0.94 0.94h-16.12c-0.519 0-0.94-0.421-0.94-0.94v-0.12c0-0.519 0.421-0.94 0.94-0.94z"
                fill={color}
            />
            <path
                d="M3.94 6h16.12c0.519 0 0.94 0.421 0.94 0.94v0.12c0 0.519-0.421 0.94-0.94 0.94h-16.12c-0.519 0-0.94-0.421-0.94-0.94v-0.12c0-0.519 0.421-0.94 0.94-0.94z"
                fill={color}
            />
        </svg>
    );
}

export default memo(HamburgerIcon);
