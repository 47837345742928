import React, { createContext, useContext, useState } from 'react';

import { Context } from '@/types/context';

const DBSyncContext = createContext<Context.DBSync.Value>({
    isInitialSyncInProgress: true,
    isSyncInProgress: true,
    isUnsynced: false,
    hasSyncIssue: false,
    isSyncModalVisible: false,
    setIsInitialSyncInProgress: () => null,
    setIsSyncInProgress: () => null,
    setIsUnsynced: () => null,
    setHasSyncIssue: () => null,
    setIsSyncModalVisible: () => null,
});

const DBSyncProvider = ({ children }: Context.DBSync.Props) => {
    const [isInitialSyncInProgress, setIsInitialSyncInProgress] =
        useState(true);
    const [isSyncInProgress, setIsSyncInProgress] = useState(true);
    const [hasSyncIssue, setHasSyncIssue] = useState(false);
    const [isUnsynced, setIsUnsynced] = useState(false);

    const [isSyncModalVisible, setIsSyncModalVisible] = useState(false);

    return (
        <DBSyncContext.Provider
            value={{
                isInitialSyncInProgress,
                isSyncInProgress,
                isUnsynced,
                hasSyncIssue,
                isSyncModalVisible,
                setIsInitialSyncInProgress,
                setIsSyncInProgress,
                setIsSyncModalVisible,
                setIsUnsynced,
                setHasSyncIssue,
            }}
        >
            {children}
        </DBSyncContext.Provider>
    );
};

function useDBSyncContext() {
    const dbSyncContext = useContext(DBSyncContext);

    return dbSyncContext;
}

export { DBSyncContext, DBSyncProvider, useDBSyncContext };
