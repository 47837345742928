import {
    CalculateEventStatusParams,
    CalculateEventStatusResult,
    InvitationStatus,
    RSVPEventStatus,
} from '../types/Events';
import { INVITATIONS_MAP, USER_REMINDER_VALUES_MAP } from '../constants/event';
import { UserModel } from '../types/User';
import EventContact from '../db/services/EventContact';
import { EVENT_CONTACT_STATUS, EventContactModel } from '../types/EventContact';
import { ContactModel } from '../types/Contacts';
import Contact from '../db/services/Contact';
import { LoggerFunction } from '../types/logger';

async function getEventCardColor(
    currentUserId: string,
    membersWithColors: { user: UserModel; userColor: string }[],
) {
    let eventCardColor: string = '';
    let members = membersWithColors;

    const currentUserEventMember = members.find(
        ({ user }) => user.id === currentUserId,
    );

    // Move current user event member to the beginning
    if (!!currentUserEventMember) {
        const eventMembersWithoutCurrentUser = members.filter(
            (eventMember) =>
                eventMember.user.id !== currentUserEventMember.user.id,
        );

        members = [currentUserEventMember, ...eventMembersWithoutCurrentUser];
    }

    if (members.length) {
        if (!!currentUserEventMember) {
            eventCardColor = currentUserEventMember.userColor;
        } else {
            eventCardColor = members[0].userColor;
        }
    }

    return eventCardColor;
}

function parseAlert(userReminder: string | undefined | null) {
    let alert = USER_REMINDER_VALUES_MAP.none;

    if (!userReminder) {
        return alert;
    }

    switch (userReminder) {
        case '1h':
            alert = USER_REMINDER_VALUES_MAP['1h'];
            break;
        case '24h':
            alert = USER_REMINDER_VALUES_MAP['24h'];
            break;
        case '1w':
            alert = USER_REMINDER_VALUES_MAP['1w'];
            break;
        default:
            break;
    }

    return alert;
}

function parseInvitations(ownersReminder: boolean, contactsReminder: boolean) {
    let invites = INVITATIONS_MAP.none;

    if (contactsReminder && !ownersReminder) {
        invites = INVITATIONS_MAP.contacts;
    } else if (ownersReminder && !contactsReminder) {
        invites = INVITATIONS_MAP.owners;
    } else if (ownersReminder && contactsReminder) {
        invites = INVITATIONS_MAP.contacts_and_owners;
    }

    return invites;
}

function calculateContactInvitationState(
    sentFlag: boolean,
    sentAt: string,
): InvitationStatus {
    if (!sentFlag && !sentAt) {
        return 'not_sent';
    }

    if (sentFlag && !sentAt) {
        return 'sending';
    }

    if (sentFlag && sentAt) {
        return 'sent';
    }
    return 'not_sent';
}

async function calculateStatus(
    invitations: EventContactModel[],
    totalLength: number,
    contactService: Contact,
    logError: LoggerFunction,
): Promise<RSVPEventStatus | null> {
    if (!invitations.length) {
        return null;
    }

    let contactToShow: ContactModel | null = null;

    if (invitations.length === 1) {
        try {
            contactToShow = await contactService.getByID(
                invitations[0].contactId,
            );
        } catch (e) {
            logError(`Error while fetching contact: ${e}`);
        }
    }

    return {
        contactsCount: totalLength,
        contactToShow,
        confirmedNumber: invitations.length,
    };
}

async function calculateEventStatus(
    params: CalculateEventStatusParams,
): Promise<CalculateEventStatusResult | null> {
    const eventContactService = new EventContact({
        database: params.database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
    });

    const contactService = new Contact({
        database: params.database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
    });

    const eventContacts = await eventContactService.fetchByParam(
        'event_id',
        params.eventId,
    );

    if (!eventContacts) {
        return null;
    }

    const awaitingResponseInvitations: EventContactModel[] = [];
    const confirmedInvitations: EventContactModel[] = [];
    const declinedInvitations: EventContactModel[] = [];

    eventContacts.forEach((eventContact) => {
        switch (eventContact.status) {
            case EVENT_CONTACT_STATUS.ACCEPTED:
                confirmedInvitations.push(eventContact);
                break;
            case EVENT_CONTACT_STATUS.REJECTED:
                declinedInvitations.push(eventContact);
                break;
            case EVENT_CONTACT_STATUS.AWAITING:
            default:
                awaitingResponseInvitations.push(eventContact);
        }
    });

    const allDeclined =
        !awaitingResponseInvitations.length &&
        !confirmedInvitations.length &&
        !!declinedInvitations.length;

    const allConfirmed =
        !awaitingResponseInvitations.length &&
        !declinedInvitations.length &&
        !!confirmedInvitations.length;

    const noneReply =
        !!awaitingResponseInvitations.length &&
        !declinedInvitations.length &&
        !confirmedInvitations.length;

    if (noneReply) {
        return null;
    }

    if (allConfirmed) {
        let contactToShow: ContactModel | null = null;

        if (confirmedInvitations.length === 1) {
            contactToShow = await contactService.getByID(
                confirmedInvitations[0].contactId,
            );
        }

        return {
            confirmed: {
                contactsCount: eventContacts.length,
                contactToShow: contactToShow,
            },
            declined: null,
            awaiting: 0,
        } as CalculateEventStatusResult;
    }

    if (allDeclined) {
        let contactToShow: ContactModel | null = null;

        if (declinedInvitations.length === 1) {
            contactToShow = await contactService.getByID(
                declinedInvitations[0].contactId,
            );
        }

        return {
            confirmed: null,
            awaiting: 0,
            declined: {
                contactsCount: eventContacts.length,
                contactToShow,
            },
        } as CalculateEventStatusResult;
    }

    const confirmedStatus = await calculateStatus(
        confirmedInvitations,
        eventContacts.length,
        contactService,
        params.logError,
    );
    const declinedStatus = await calculateStatus(
        declinedInvitations,
        eventContacts.length,
        contactService,
        params.logError,
    );

    return {
        awaiting: awaitingResponseInvitations.length,
        confirmed: confirmedStatus,
        declined: declinedStatus,
    };
}

export {
    getEventCardColor,
    parseAlert,
    parseInvitations,
    calculateContactInvitationState,
    calculateEventStatus,
};
