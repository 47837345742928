import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function EmptyState({ message, testID }: Props) {
    return (
        <Box data-test-id={testID} sx={{ mt: 10, width: '100%' }}>
            <Typography
                align={'center'}
                color={COLOR.paleSky}
                sx={{ fontSize: 12 }}
            >
                {message}
            </Typography>
        </Box>
    );
}

export default EmptyState;
