export enum HORSE_BREED {
    appaloosa = 'appaloosa',
    arabian = 'arabian',
    australian_brumby_waler = 'australian_brumby_waler',
    australian_riding_pony = 'australian_riding_pony',
    australian_stockhorse = 'australian_stockhorse',
    belgian_draft_horse = 'belgian_draft_horse',
    clydesdale_drafthorse = 'clydesdale_drafthorse',
    fresian = 'fresian',
    irish_sport_horse = 'irish_sport_horse',
    miniature = 'miniature',
    others = 'others',
    percheron = 'percheron',
    quarter_horse = 'quarter_horse',
    shetland = 'shetland',
    shire = 'shire',
    spanish_breed = 'spanish_breed',
    standardbred = 'standardbred',
    thoroughbred = 'thoroughbred',
    trakehner = 'trakehner',
    warmblood = 'warmblood',
    mule = 'mule',
    donkey = 'donkey',
    paint = 'paint',
    pinto = 'pinto',
    suffolk = 'suffolk',
    mustang = 'mustang',
    paso_fino = 'paso_fino',
    peruvian_paso = 'peruvian_paso',
    andalusian = 'andalusian',
    lipizzaner = 'lipizzaner',
    connemara = 'connemara',
    welsh = 'welsh',
    hackney = 'hackney',
    quarter_pony = 'quarter_pony',
    pony_of_the_americas = 'pony_of_the_americas',
    norwegian_fjord = 'norwegian_fjord',
    halflinger = 'halflinger',
    icelandic = 'icelandic',
}

export const HORSE_BREEDS_DICTIONARY: Record<HORSE_BREED, string> = {
    [HORSE_BREED.andalusian]: 'HorseProfile:picker:breeds:andalusian',
    [HORSE_BREED.appaloosa]: 'HorseProfile:picker:breeds:appaloosa',
    [HORSE_BREED.arabian]: 'HorseProfile:picker:breeds:arabian',
    [HORSE_BREED.australian_brumby_waler]:
        'HorseProfile:picker:breeds:australian_brumby_waler',
    [HORSE_BREED.australian_riding_pony]:
        'HorseProfile:picker:breeds:australian_riding_pony',
    [HORSE_BREED.australian_stockhorse]:
        'HorseProfile:picker:breeds:australian_stockhorse',
    [HORSE_BREED.belgian_draft_horse]:
        'HorseProfile:picker:breeds:belgian_draft_horse',
    [HORSE_BREED.clydesdale_drafthorse]:
        'HorseProfile:picker:breeds:clydesdale_drafthorse',
    [HORSE_BREED.connemara]: 'HorseProfile:picker:breeds:connemara',
    [HORSE_BREED.donkey]: 'HorseProfile:picker:breeds:donkey',
    [HORSE_BREED.fresian]: 'HorseProfile:picker:breeds:fresian',
    [HORSE_BREED.hackney]: 'HorseProfile:picker:breeds:hackney',
    [HORSE_BREED.halflinger]: 'HorseProfile:picker:breeds:halflinger',
    [HORSE_BREED.icelandic]: 'HorseProfile:picker:breeds:icelandic',
    [HORSE_BREED.irish_sport_horse]:
        'HorseProfile:picker:breeds:irish_sport_horse',
    [HORSE_BREED.lipizzaner]: 'HorseProfile:picker:breeds:lipizzaner',
    [HORSE_BREED.miniature]: 'HorseProfile:picker:breeds:miniature',
    [HORSE_BREED.mule]: 'HorseProfile:picker:breeds:mule',
    [HORSE_BREED.mustang]: 'HorseProfile:picker:breeds:mustang',
    [HORSE_BREED.norwegian_fjord]: 'HorseProfile:picker:breeds:norwegian_fjord',
    [HORSE_BREED.others]: 'HorseProfile:picker:breeds:others',
    [HORSE_BREED.paint]: 'HorseProfile:picker:breeds:paint',
    [HORSE_BREED.paso_fino]: 'HorseProfile:picker:breeds:paso_fino',
    [HORSE_BREED.percheron]: 'HorseProfile:picker:breeds:percheron',
    [HORSE_BREED.peruvian_paso]: 'HorseProfile:picker:breeds:peruvian_paso',
    [HORSE_BREED.pinto]: 'HorseProfile:picker:breeds:pinto',
    [HORSE_BREED.pony_of_the_americas]:
        'HorseProfile:picker:breeds:pony_of_the_americas',
    [HORSE_BREED.quarter_horse]: 'HorseProfile:picker:breeds:quarter_horse',
    [HORSE_BREED.quarter_pony]: 'HorseProfile:picker:breeds:quarter_pony',
    [HORSE_BREED.shetland]: 'HorseProfile:picker:breeds:shetland',
    [HORSE_BREED.shire]: 'HorseProfile:picker:breeds:shire',
    [HORSE_BREED.spanish_breed]: 'HorseProfile:picker:breeds:spanish_breed',
    [HORSE_BREED.standardbred]: 'HorseProfile:picker:breeds:standardbred',
    [HORSE_BREED.suffolk]: 'HorseProfile:picker:breeds:suffolk',
    [HORSE_BREED.thoroughbred]: 'HorseProfile:picker:breeds:thoroughbred',
    [HORSE_BREED.trakehner]: 'HorseProfile:picker:breeds:trakehner',
    [HORSE_BREED.warmblood]: 'HorseProfile:picker:breeds:warmblood',
    [HORSE_BREED.welsh]: 'HorseProfile:picker:breeds:welsh',
};
