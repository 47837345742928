import {
    Box,
    SxProps,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs/internal/observable/of';
import Database from 'shared/db/services/Database.web';
import User from 'shared/db/services/User';
import { ShoppingListModel } from 'shared/types/ShoppingList';

import { OrganisationMembersAvatarGroup, ShoppingAvatar } from '@/components';
import { ROUTE } from '@/router/routes';
import { FirebaseImagesService } from '@/services/firebase/images';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

const nameCellStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0.5,
};

const cellTextStyle: SxProps<Theme> = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
};

const database = Database.getDatabase();

const userService = new User({
    database,
    imageService: new FirebaseImagesService(),
    logDBAction: () => {
        return;
    },
});

function ShoppingListsItem({
    shoppingList,
    shoppingListProductsCount,
    shoppingListCreator,
}: Props) {
    const navigate = useNavigate();

    const navigateToShoppingList = useCallback(
        (shoppingList: ShoppingListModel) => {
            const path = getRoutePath(ROUTE.shoppingList, {
                id: shoppingList.id,
            });
            navigate(path);
        },
        [navigate],
    );

    const handleShoppingListClick = useCallback(
        (shoppingList: ShoppingListModel) => () =>
            navigateToShoppingList(shoppingList),
        [navigateToShoppingList],
    );

    const numberOfProducts = useMemo(() => {
        switch (shoppingListProductsCount) {
            case 0:
                return t('ShoppingListsPage:no_items');
            case 1:
                return `${shoppingListProductsCount} ${t(
                    'ShoppingListsPage:item',
                )}`;
            default:
                return `${shoppingListProductsCount} ${t(
                    'ShoppingListsPage:items',
                )}`;
        }
    }, [shoppingListProductsCount]);

    return (
        <TableRow
            className="hover:cursor-pointer hover:bg-blue-50/50 child:border-0 last-child:rounded-r-lg first-child:rounded-l-lg"
            data-test-id={`ShoppingLists-TableRow${shoppingList.name}`}
            onClick={handleShoppingListClick(shoppingList)}
        >
            <TableCell component="th" scope="row" width="5%">
                <ShoppingAvatar
                    isHighlighted={!!shoppingList.addedToInventoryTime}
                />
            </TableCell>
            <TableCell>
                <Box sx={nameCellStyles}>
                    <Typography
                        sx={{
                            ...cellTextStyle,
                            color: COLOR.transparentEbonyClay,
                        }}
                    >
                        {formatDate(
                            shoppingList.createdAt.toISOString(),
                            'month-year-short',
                        )}
                    </Typography>
                    <Typography
                        sx={{ fontWeight: 700, fontSize: 16 }}
                        data-test-id="ShoppingLists-TableRowName"
                    >
                        {shoppingList.name}
                    </Typography>
                    <Typography
                        sx={{
                            ...cellTextStyle,
                            color: COLOR.ebonyClay,
                        }}
                    >
                        {numberOfProducts}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography
                    sx={{
                        ...cellTextStyle,
                        color: COLOR.transparentEbonyClay,
                    }}
                >
                    {shoppingList.addedToInventoryTime
                        ? formatDate(
                              shoppingList.addedToInventoryTime,
                              'month-year-short',
                          )
                        : ''}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <div className="flex flex-row flex-1 justify-center items-center">
                    <OrganisationMembersAvatarGroup
                        database={database}
                        max={1}
                        users={shoppingListCreator}
                        size={26}
                        fontSize={12}
                        testID="ShoppingListItem-Creator"
                    />
                </div>
            </TableCell>
        </TableRow>
    );
}

const enhance = withObservables<Props, unknown>(
    ['shoppingList'],
    ({ shoppingList }) => {
        return {
            shoppingLists: shoppingList ? shoppingList.observe() : of([]),
            shoppingListProductsCount: shoppingList
                ? shoppingList.shoppingListProducts.observeCount()
                : of(0),
            shoppingListCreator: userService
                .getById(shoppingList.userId)
                .observe(),
        };
    },
);

export default enhance(ShoppingListsItem);
