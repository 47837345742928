import { Box, Checkbox, Typography } from '@mui/material';
import React, { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    EQUINET_PRIVACY_POLICY_PAGE,
    EQUINET_TERMS_AND_CONDITIONS_PAGE,
} from '@/constants/externalLinks';

import { Props } from './types';

function AcceptTermsCheckbox({ checked, onValueChange, hasError }: Props) {
    const acceptTermsRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const toggleAcceptTermsCheckbox = useCallback(() => {
        if (acceptTermsRef.current) {
            onValueChange(!acceptTermsRef.current.checked);
        }
    }, [onValueChange]);

    const handleCheckboxValueChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onValueChange(event.target.checked);
        },
        [onValueChange],
    );

    const getLink = useCallback(
        (label, link) =>
            `<a onclick="event.stopPropagation()" style="font-size: 12px; text-decoration: underline; color: black; font-weight: bold" href="${link}" target="_blank">${label}</a>`,
        [],
    );

    const termsLink = useMemo(
        () =>
            getLink(
                t('SignUp:link:terms_of_service'),
                EQUINET_TERMS_AND_CONDITIONS_PAGE,
            ),
        [getLink, t],
    );

    const privacyLink = useMemo(
        () =>
            getLink(
                t('SignUp:link:privacy_policy'),
                EQUINET_PRIVACY_POLICY_PAGE,
            ),
        [getLink, t],
    );

    return (
        <Box className="flex flex-col">
            <Box className="mt-[-4px] flex items-center ml-[-10px] cursor-pointer select-none">
                <Checkbox
                    checked={checked}
                    onChange={handleCheckboxValueChange}
                    inputRef={acceptTermsRef}
                />
                <Box
                    onClick={toggleAcceptTermsCheckbox}
                    className="flex items-center text-[12px] gap-1"
                >
                    <Typography
                        className="text-[12px]"
                        dangerouslySetInnerHTML={{
                            __html: t('SignUp:label:terms_agree', {
                                terms: termsLink,
                                privacy: privacyLink,
                            }),
                        }}
                    ></Typography>
                </Box>
            </Box>
            {hasError ? (
                <Typography className="ml-8 mt-[-8px] text-[0.75rem] text-formErrorRed">
                    {t('SignUp:error:accept_terms_required')}
                </Typography>
            ) : null}
        </Box>
    );
}
export default AcceptTermsCheckbox;
