import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import bgImage from '@/assets/images/bg-image.webp';
import { useAuthContext } from '@/context/AuthContext';
import { AuthPageLayout } from '@/layouts';
import { ROUTE } from '@/router/routes';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { COLOR } from '@/theme/colors';

import { NewPasswordForm } from './components';

const EMAIL_QUERY_PARAM_NAME = 'email';
const TOKEN_QUERY_PARAM_NAME = 'token';

function ForgotPasswordPage() {
    const { invalidateUserAuth } = useAuthContext();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const token = searchParams.get(TOKEN_QUERY_PARAM_NAME);

    const getEmail = useCallback(() => {
        // Handle case when email has a + sign, which in query string is interpreted as a space and needs to be encoded
        const paramsAsString = searchParams.toString().replace('+', '%2B');

        const params = new URLSearchParams(paramsAsString);

        return params.get(EMAIL_QUERY_PARAM_NAME);
    }, [searchParams]);

    const email = useMemo(getEmail, [getEmail]);

    const logOut = useCallback(async () => {
        await FirebaseAuthAPI.signOut();
        invalidateUserAuth();
    }, [invalidateUserAuth]);

    useEffect(() => {
        logOut();
    }, [logOut]);

    if (!email || !token) {
        return <Navigate replace to={ROUTE.signIn} />;
    }

    return (
        <AuthPageLayout
            backgroundImage={bgImage}
            rightColumnContent={
                <>
                    <Box
                        sx={{
                            mt: '35px',
                            ml: { xs: 4, md: 10 },
                        }}
                    >
                        <Link to={ROUTE.signIn}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <ArrowBackIosIcon
                                    sx={{ width: 12, height: 12 }}
                                    htmlColor={COLOR.ebonyClay}
                                />
                                <Typography
                                    fontSize={14}
                                    fontWeight={700}
                                    color={COLOR.ebonyClay}
                                >
                                    {t('ForgotPassword:topbar:back')}
                                </Typography>
                            </Box>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: '58px',
                        }}
                    >
                        <Box
                            sx={{
                                ml: { xs: 4, md: 10 },
                                mr: { xs: 4, md: 0 },
                            }}
                        >
                            <Box
                                alt="Equinet Logo"
                                component="img"
                                src={equinetLogoImage}
                                sx={{
                                    height: 88,
                                    mb: 4,
                                    width: 334,
                                }}
                            />
                            <Typography
                                component="h1"
                                sx={{ fontWeight: 700, mb: 1 }}
                                variant="h5"
                            >
                                {t('NewPassword:placeholder:new_password')}
                            </Typography>
                        </Box>
                        <NewPasswordForm email={email} token={token} />
                    </Box>
                </>
            }
        />
    );
}

export default ForgotPasswordPage;
