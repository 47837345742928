import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { INVITE_STATUS } from '../../useExternalAppointmentPage';

import { Banner } from './Banner';
import { Props } from './types';

function Footer({
    status,
    isAppointmentCancelled,
    showCancellationModal,
    displayCancellationModal,
    onConfirmationButtonClick,
}: Props) {
    if (status === INVITE_STATUS.REJECTED || isAppointmentCancelled) {
        return (
            <Banner
                height={showCancellationModal ? 0 : undefined}
                aria-controls={!showCancellationModal ? 'banner' : undefined}
                aria-haspopup={true}
                aria-expanded={!showCancellationModal ? true : undefined}
                onClick={
                    !isAppointmentCancelled
                        ? displayCancellationModal
                        : undefined
                }
                className={clsx('bg-errorRed', {
                    'cursor-pointer': !isAppointmentCancelled,
                })}
            >
                <div className="flex flex-row h-full justify-center items-center py-4">
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '19.5px',
                            color: COLOR.white,
                        }}
                    >
                        {t(
                            isAppointmentCancelled
                                ? 'ExternalAppointment:labels:cancelled'
                                : 'ExternalAppointment:labels:declined',
                        )}
                    </Typography>
                </div>
            </Banner>
        );
    }

    if (status === INVITE_STATUS.EXPIRED) {
        return (
            <Banner
                duration={200}
                className="bg-silver"
                aria-controls="banner"
                aria-expanded={true}
            >
                <div className="flex flex-row h-full justify-center items-center py-4">
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '19.5px',
                            color: COLOR.white,
                        }}
                    >
                        {t('ExternalAppointment:labels:expired')}
                    </Typography>
                </div>
            </Banner>
        );
    }

    if (!showCancellationModal) {
        const isStatusAwaiting = status === INVITE_STATUS.AWAITING;

        return (
            <Box
                sx={{
                    boxShadow: '0px -3px 6px #00000029',
                }}
                className="h-28 fixed bottom-0 left-0 right-0 bg-white"
            >
                <div className="flex flex-col md:flex-row h-full justify-center md:justify-start md:items-center gap-[8px] px-6 py-4 md:py-7 md:px-48">
                    <Button
                        className="py-3 md:py-3.5 md:px-[76px]"
                        variant="contained"
                        disableElevation
                        onClick={
                            !isAppointmentCancelled
                                ? onConfirmationButtonClick
                                : undefined
                        }
                        startIcon={
                            status === INVITE_STATUS.CONFIRMED ? (
                                <CheckCircleIcon
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        color: COLOR.confirmGreen,
                                    }}
                                />
                            ) : null
                        }
                        sx={{
                            cursor: !isAppointmentCancelled
                                ? 'pointer'
                                : 'default',
                            background: `linear-gradient(${
                                isStatusAwaiting
                                    ? COLOR.englishLightGreen
                                    : COLOR.englishLightGreen10
                            }, ${
                                isStatusAwaiting
                                    ? COLOR.englishVividGreen
                                    : COLOR.englishVividGreen10
                            })`,
                            '&:hover': {
                                background: `linear-gradient(${
                                    isStatusAwaiting
                                        ? COLOR.englishLightGreen
                                        : COLOR.englishLightGreen10
                                }, ${
                                    isStatusAwaiting
                                        ? COLOR.englishLightGreen
                                        : COLOR.englishLightGreen10
                                })`,
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: isStatusAwaiting ? '700' : '400',
                                lineHeight: '19.5px',
                                color: isStatusAwaiting
                                    ? COLOR.white
                                    : COLOR.confirmGreen,
                            }}
                        >
                            {isStatusAwaiting
                                ? t('ExternalAppointment:labels:confirm')
                                : t('ExternalAppointment:labels:confirmed')}
                        </Typography>
                    </Button>
                    <Button
                        className="md:pl-6"
                        variant="text"
                        disableElevation
                        onClick={
                            !isAppointmentCancelled
                                ? displayCancellationModal
                                : undefined
                        }
                        endIcon={
                            <ArrowForwardIcon sx={{ color: COLOR.cancelRed }} />
                        }
                        sx={{
                            cursor: !isAppointmentCancelled
                                ? 'pointer'
                                : 'default',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: COLOR.cancelRed,
                            }}
                        >
                            {t('ExternalAppointment:labels:reschedule_cancel')}
                        </Typography>
                    </Button>
                </div>
            </Box>
        );
    }

    return null;
}

export default Footer;
