import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Organisation from 'shared/db/services/Organisation';
import Product from 'shared/db/services/Product';
import ShoppingListProduct from 'shared/db/services/ShoppingListProduct';
import User from 'shared/db/services/User';
import { ParsedShoppingListProduct } from 'shared/types/ShoppingList';
import { getAllSizesGrouped } from 'shared/utils/product';
import { getShoppingListProducts } from 'shared/utils/shoppingList';

import {
    EmptyState,
    RemoveEntityAlert,
    SelectedProductsListSkeleton,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

import { HeaderSection, ProductsList } from './components';
import { Props } from './types';

function ShoppingListDetails({
    shoppingList,
    isLoading,
    products,
    refetchShoppingList,
    onAddToInventoryClick,
}: Props) {
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress, isInitialSyncInProgress } = useDBSyncContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const productService = useMemo(
        () =>
            new Product({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const shoppingListProductService = useMemo(
        () =>
            new ShoppingListProduct({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const [parsedProducts, setParsedProducts] = useState<
        ParsedShoppingListProduct[]
    >([]);

    const [productToRemove, setProductToRemove] =
        useState<ParsedShoppingListProduct | null>(null);

    const [showInventory, setShowInventory] = useState<boolean>(false);

    const fetchProducts = useCallback(async () => {
        const productSizesInShoppingList = await productService.getByIDs(
            products.map((shoppingListProd) => shoppingListProd.productId),
        );

        const allGroupedProductsFromShoppingList = await getAllSizesGrouped(
            productSizesInShoppingList,
            productService,
        );

        const shoppingListProducts = await getShoppingListProducts({
            groupedProductsForShoppingList: allGroupedProductsFromShoppingList,
            userId: userProfileData?.id ?? '',
            params: {
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
                userId: userProfileData?.id,
            },
            database,
            userService,
            productService,
            shoppingListProducts: products,
        });

        setParsedProducts(shoppingListProducts);
    }, [
        ImagesService,
        database,
        productService,
        products,
        userProfileData?.id,
        userService,
    ]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchProducts();
        }
    }, [fetchProducts, isInitialSyncInProgress]);

    const closeRemoveProductAlert = useCallback(
        () => setProductToRemove(null),
        [],
    );

    const handleOnRemoveProduct = useCallback(
        (name: string, materialShapeAndClips: string | null) => {
            const clickedShoppingListProduct = parsedProducts.find(
                (inventoryProduct) =>
                    inventoryProduct.productAttributes.name === name &&
                    materialShapeAndClips === materialShapeAndClips,
            );

            if (clickedShoppingListProduct) {
                setProductToRemove(clickedShoppingListProduct);
            }
        },
        [parsedProducts],
    );

    const removeProduct = useCallback(async () => {
        if (
            !shoppingList ||
            !productToRemove ||
            !userProfileData ||
            isSyncInProgress
        ) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
            return;
        }

        if (parsedProducts.length === 1) {
            Snackbar.showToastNotification({
                message: t('ShoppingList:products:remove_product_error'),
                options: {
                    variant: 'error',
                },
            });
            return;
        }

        const organisationService = new Organisation({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const filteredProducts = parsedProducts.filter(
            (product) =>
                product.chosenProductId !== productToRemove.chosenProductId,
        );

        setParsedProducts(filteredProducts);

        if (productToRemove.productSizes) {
            for (const size of productToRemove.productSizes) {
                if (size.productId) {
                    await shoppingListProductService.deleteProductFromShoppingList(
                        shoppingList.id,
                        size.productId,
                        userProfileData.id,
                        organisationID,
                    );
                }
            }

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:product'),
                }),
            });
        }
    }, [
        shoppingList,
        productToRemove,
        userProfileData,
        isSyncInProgress,
        parsedProducts,
        database,
        ImagesService,
        shoppingListProductService,
    ]);

    const handleChangeInventoryVisibility = useCallback(
        () => setShowInventory(!showInventory),
        [showInventory],
    );

    return !isLoading && !shoppingList ? (
        <EmptyState message={t('ShoppingList:not_found')} />
    ) : (
        <div>
            <HeaderSection
                key={shoppingList?.id}
                shoppingList={shoppingList}
                isLoading={isLoading}
                products={products}
                showInventory={showInventory}
                setShowInventory={handleChangeInventoryVisibility}
                onAddToInventoryClick={onAddToInventoryClick}
            />
            {isLoading ? (
                <SelectedProductsListSkeleton />
            ) : (
                <>
                    <ProductsList
                        onRemoveButtonClick={handleOnRemoveProduct}
                        shoppingListProducts={parsedProducts}
                        shoppingListId={shoppingList?.id ?? ''}
                        isLoading={isLoading}
                        showInventory={showInventory}
                        addedToInventory={!!shoppingList?.addedToInventoryTime}
                        refetchData={refetchShoppingList}
                    />
                    {productToRemove ? (
                        <RemoveEntityAlert
                            dialogText={t('Inventory:dialog:remove_inventory', {
                                name: productToRemove.productAttributes.name,
                            })}
                            isOpen
                            onClose={closeRemoveProductAlert}
                            onRemove={removeProduct}
                            testID="RemoveProductAlert"
                        />
                    ) : null}
                </>
            )}
        </div>
    );
}
export default ShoppingListDetails;
