export enum USER_REMINDER {
    NONE = 'none',
    BEFORE1HOUR = '1h',
    BEFORE24HOURS = '24h',
    BEFORE1WEEK = '1w',
}

export const USER_REMINDER_VALUES_MAP = {
    [USER_REMINDER.NONE]: 'ShowEvent:alert:no_alert',
    [USER_REMINDER.BEFORE1HOUR]: 'ShowEvent:alert:1h',
    [USER_REMINDER.BEFORE24HOURS]: 'ShowEvent:alert:24h',
    [USER_REMINDER.BEFORE1WEEK]: 'ShowEvent:alert:1w',
};

export const USER_REMINDER_LABELS = [
    USER_REMINDER.NONE,
    USER_REMINDER.BEFORE1HOUR,
    USER_REMINDER.BEFORE24HOURS,
    USER_REMINDER.BEFORE1WEEK,
];

export enum INVITATIONS {
    NONE = 'none',
    CONTACTS = 'contacts',
    OWNERS = 'owners',
    CONTACTS_AND_OWNERS = 'contacts_and_owners',
}

export const INVITATIONS_MAP = {
    [INVITATIONS.NONE]: 'ShowEvent:alert:do_not_notify',
    [INVITATIONS.CONTACTS]: 'ShowEvent:alert:contacts_notified',
    [INVITATIONS.OWNERS]: 'ShowEvent:alert:owners_notified',
    [INVITATIONS.CONTACTS_AND_OWNERS]:
        'ShowEvent:alert:contacts_owners_notified',
};
