import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InvoiceStatusBadge({
    status,
    color,
    testID,
    height,
    fontSize,
}: Props) {
    if (!status) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: height ? height : 28,
                borderRadius: 0.5,
                px: 1,
                flexShrink: 0,
                backgroundColor: color ? color : COLOR.black,
            }}
        >
            <Typography
                data-test-id={testID}
                sx={{
                    fontSize: fontSize ? fontSize : 14,
                    fontWeight: 700,
                    color: COLOR.white,
                }}
            >
                {status}
            </Typography>
        </Box>
    );
}

export default InvoiceStatusBadge;
