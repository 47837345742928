import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import Modal from '../Modal';

import type { Props } from './types';

export function ConnectProviderWarningModal({ onCancel, onProceed }: Props) {
    const { t } = useTranslation();

    return (
        <Modal center isOpen styles="flex items-center justify-center p-[22px]">
            <Box className="flex flex-col w-[440px]">
                <Typography className="mt-[3px] font-bold text-cranberry">
                    {t('Settings:invoices_admin:provider_warning_modal:title')}
                </Typography>
                <Typography className="mb-[44px] mt-[17px] text-base text-ebonyClay leading-relaxed font-light">
                    {t(
                        'Settings:invoices_admin:provider_warning_modal:description',
                    )}
                </Typography>
                <Box className="flex gap-[30px] self-end">
                    <Button caption={t('Actions:cancel')} onClick={onCancel} />
                    <Button
                        caption={t('Actions:proceed')}
                        onClick={onProceed}
                        variant="contained"
                        color="primary"
                    />
                </Box>
            </Box>
        </Modal>
    );
}
