import {
    TableRow,
    TableCell,
    Typography,
    Box,
    Button,
    Skeleton,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, MouseEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { ContactModel } from 'shared/types/Contacts';
import { getRolesDictionary } from 'shared/utils/contactRoles';

import { InitialsAvatar } from '@/components';
import { ROUTE } from '@/router/routes';
import { t } from '@/services/translations/config';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

export function ContactsTableItem({
    contact,
    contactAvatar,
    horsesCount,
    onEditButtonPress,
    isPhotoLoading,
}: Props) {
    const navigate = useNavigate();

    const contactAvatarUrl = contactAvatar?.imageURL;

    const navigateToContact = useCallback(
        (contact: ContactModel) => {
            const path = getRoutePath(ROUTE.contact, { id: contact.id });

            navigate(path);
        },
        [navigate],
    );

    const getOnClickHandler = useCallback(
        (contact: ContactModel) => () => navigateToContact(contact),
        [navigateToContact],
    );

    const handleEditButtonPress = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();

            onEditButtonPress(contact);
        },
        [contact, onEditButtonPress],
    );

    const rolesDictionary = useMemo(() => {
        return getRolesDictionary(t);
    }, []);

    return (
        <TableRow
            className="hover:cursor-pointer hover:bg-blue-50/50 child:border-0 last-child:rounded-r-lg first-child:rounded-l-lg"
            data-test-id={`ContactsPage-TableRow${contact.firstName}`}
            onClick={getOnClickHandler(contact)}
        >
            <TableCell component="th" scope="row" width="35%">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {!isPhotoLoading ? (
                        <InitialsAvatar
                            avatarUrl={contactAvatarUrl || ''}
                            firstName={contact.firstName}
                            lastName={contact.lastName || ''}
                            sx={{ fontWeight: 'normal', mr: 2 }}
                        />
                    ) : (
                        <Skeleton
                            height={72}
                            width={72}
                            variant="circular"
                            sx={{ mr: 3 }}
                        />
                    )}
                    <Box>
                        <Typography
                            data-test-id={'ContactsPage-TableRowName'}
                            variant={'subtitle1'}
                            color={'black'}
                            fontWeight={700}
                        >
                            {contact.firstName}
                            {contact.lastName ? ` ${contact.lastName}` : ''}
                        </Typography>
                        <Typography
                            data-test-id={'ContactsPage-TableRowRole'}
                            variant={'caption'}
                            color={'black'}
                            fontWeight={300}
                        >
                            {contact.role ? rolesDictionary[contact.role] : ''}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="right" width="10%">
                <Typography
                    fontSize={13}
                    fontWeight={300}
                    data-test-id={'ContactsPage-TableRowHorsesCount'}
                >
                    {horsesCount}
                </Typography>
            </TableCell>
            <TableCell align="left" width={'25%'} sx={{ pl: 8 }}>
                <Typography
                    data-test-id={'ContactsPage-TableRowBusiness'}
                    fontSize={13}
                    fontWeight={300}
                >
                    {contact.businessName || ''}
                </Typography>
            </TableCell>
            <TableCell align="left" width={'25%'}>
                <Typography fontSize={13} fontWeight={300}>
                    {contact.address || ''}
                </Typography>
            </TableCell>
            <TableCell
                sx={{
                    borderTopLeftRadius: '0.5 rem',
                    borderBottomLeftRadius: '0.5 rem',
                }}
                align="right"
            >
                <Button
                    className="min-w-0"
                    data-test-id="ContactsPage-TableRowEditButton"
                    onClick={handleEditButtonPress}
                    size="small"
                >
                    {t('Actions:edit')}
                </Button>
            </TableCell>
        </TableRow>
    );
}

const enhance = withObservables<Props, unknown>(['contact'], ({ contact }) => {
    return {
        contact,
        horsesCount: contact.horses.observeCount() || of(0),
    };
});

export default enhance(ContactsTableItem);
