import { t } from '@/services/translations/config';
import { ToastNotificationVariant } from '@/types/toastNotifications';

export const TOAST_NOTIFICATION_TITLE: Record<
    ToastNotificationVariant,
    string
> = {
    error: t('App:Titles:error'),
    info: t('App:Titles:info'),
    success: t('App:Titles:success'),
};
