import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function BackLinkButton({ testID, text, to }: Props) {
    return (
        <Link to={to}>
            <Box
                data-test-id={testID}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
                <ArrowBackIosIcon
                    sx={{ width: 12, height: 12 }}
                    htmlColor={COLOR.ebonyClay}
                />
                <Typography
                    fontSize={14}
                    fontWeight={700}
                    color={COLOR.ebonyClay}
                >
                    {text}
                </Typography>
            </Box>
        </Link>
    );
}

export default BackLinkButton;
