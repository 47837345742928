import {
    Avatar,
    TableRow,
    TableCell,
    Typography,
    Box,
    Button,
} from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { getNowISO } from 'shared/utils/date';
import { capitalizeWords, capitalizeFirstLetter } from 'shared/utils/string';

import { HorseAvatar } from '@/assets/svg';
import { renderTableItemContactsText } from '@/helpers/contacts/data';
import { useFormatDate } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

export function HorsesTableItem({
    avatarUrl,
    horse,
    horseContacts,
    horseFutureEntries,
    horseFutureEvents,
    horsePastEntries,
    horsePastEvents,
    onAddEntryButtonPress,
    onAddScheduleButtonPress,
    onEditButtonPress,
}: Props) {
    const navigate = useNavigate();
    const formatDate = useFormatDate();

    const navigateToHorse = useCallback(() => {
        const path = getRoutePath(ROUTE.horse, { id: horse.id });

        navigate(path);
    }, [horse.id, navigate]);

    const entryHorseContactsTextElement = useMemo(() => {
        if (!horseContacts?.length) {
            return '-';
        }

        return renderTableItemContactsText(horseContacts, t, {
            testId: 'HorsesPage-TableRowContacts',
        });
    }, [horseContacts]);

    const horseSubtitleText = useMemo(() => {
        const gender = horse.gender ? capitalizeFirstLetter(horse.gender) : '';
        const breed = horse.breed
            ? capitalizeWords(horse.breed.replace(/[_]/g, ' '))
            : '';

        return `${gender || ''}${gender && breed ? `, ${breed}` : breed}`;
    }, [horse.gender, horse.breed]);

    const entryDateText = useMemo(() => {
        const [nearestEntry] = horseFutureEntries || [];
        const [recentEntry] = horsePastEntries || [];

        if (nearestEntry) {
            return formatDate(nearestEntry.loggedTime, 'date');
        }

        if (recentEntry) {
            return formatDate(recentEntry.loggedTime, 'date');
        }

        return '';
    }, [formatDate, horseFutureEntries, horsePastEntries]);

    const handleEditButtonClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();

            onEditButtonPress(horse);
        },
        [horse, onEditButtonPress],
    );

    const handleAddEntryButtonClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onAddEntryButtonPress(horse);
        },
        [horse, onAddEntryButtonPress],
    );

    const handleAddEventButtonClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onAddScheduleButtonPress(horse);
        },
        [horse, onAddScheduleButtonPress],
    );

    const eventDateText = useMemo(() => {
        const [nearestEvent] = horseFutureEvents || [];
        const [recentEvent] = horsePastEvents || [];

        if (nearestEvent) {
            return formatDate(nearestEvent.startsTime, 'date');
        }

        if (recentEvent) {
            return formatDate(recentEvent.startsTime, 'date');
        }

        return '';
    }, [formatDate, horseFutureEvents, horsePastEvents]);

    return (
        <TableRow
            className="hover:cursor-pointer hover:bg-blue-50/50 child:border-0 last-child:rounded-r-lg first-child:rounded-l-lg"
            data-test-id={`HorsesPage-TableRow${horse.name}`}
            onClick={navigateToHorse}
        >
            <TableCell
                className="max-w-[18rem] flex-1"
                component="th"
                scope="row"
                width="30%"
            >
                <Box className="flex flex-1 flex-row items-center min-w-0">
                    <Avatar
                        className="bg-gray mr-4 h-[72px] w-[72px] bg-gainsboro"
                        src={avatarUrl}
                        variant="circular"
                    >
                        <HorseAvatar color={COLOR.mischka} />
                    </Avatar>
                    <Box className="flex flex-col min-w-0">
                        <Typography
                            data-test-id="HorsesPage-TableRowName"
                            flex={1}
                            fontWeight={700}
                            noWrap
                            variant="subtitle1"
                        >
                            {horse.name}
                        </Typography>
                        <Typography
                            data-test-id="HorsesPage-TableRowGenderBreed"
                            variant="caption"
                            fontWeight={300}
                        >
                            {horseSubtitleText}
                        </Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="left" width="18%">
                {entryHorseContactsTextElement}
            </TableCell>
            <TableCell align="left" width="17%">
                <Typography
                    data-test-id="HorsesPage-TableRowLocation"
                    fontSize={13}
                    fontWeight={300}
                >
                    {horse.stableAddress || '-'}
                </Typography>
            </TableCell>
            <TableCell align="left" className="min-w-[9rem]" width="15%">
                {entryDateText ? (
                    <Typography fontSize={13} fontWeight={300}>
                        {entryDateText}
                    </Typography>
                ) : (
                    <Button
                        className="min-w-0"
                        onClick={handleAddEntryButtonClick}
                        size="small"
                    >
                        {`+ ${t('Actions:add')}`}
                    </Button>
                )}
            </TableCell>
            <TableCell align="left" className="min-w-[9rem]" width="15%">
                {eventDateText ? (
                    <Typography fontSize={13} fontWeight={300}>
                        {eventDateText}
                    </Typography>
                ) : (
                    <Button
                        className="min-w-0"
                        onClick={handleAddEventButtonClick}
                        size="small"
                    >
                        {`+ ${t('Actions:add')}`}
                    </Button>
                )}
            </TableCell>
            <TableCell align="right" width="5%">
                <Button
                    className="min-w-0"
                    data-test-id="HorsesPage-TableRowEditButton"
                    onClick={handleEditButtonClick}
                    size="small"
                >
                    {t('Actions:edit')}
                </Button>
            </TableCell>
        </TableRow>
    );
}

const enhance = withObservables<Props, unknown>(['horse'], ({ horse }) => {
    const now = getNowISO();

    return {
        horseContacts: horse.contacts || of([]),
        horsePastEntries: horse.entries.extend(
            Q.where('logged_time', Q.lt(now)),
            Q.sortBy('logged_time', 'desc'),
        ),
        horseFutureEntries: horse.entries.extend(
            Q.where('logged_time', Q.gt(now)),
            Q.sortBy('logged_time', 'asc'),
        ),
        horsePastEvents: horse.events.extend(
            Q.where('starts_time', Q.lt(now)),
            Q.sortBy('starts_time', 'desc'),
        ),
        horseFutureEvents: horse.events.extend(
            Q.where('starts_time', Q.gt(now)),
            Q.sortBy('starts_time', 'asc'),
        ),
    };
});

export default enhance(HorsesTableItem);
