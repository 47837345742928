import { useCallback } from 'react';
import { OrganisationAnalytics } from 'shared/analytics/organisation';
import { InvitationData } from 'shared/types/api/user';
import { ROLE } from 'shared/types/OrganisationUser';

import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { OrganisationsInvitationsAPI } from '@/services/networking/invitations';
import { OrganisationsAPI } from '@/services/networking/organisations';

type UpdateMemberRoleParams = {
    memberId: string;
    organisationId: string;
    userRole: ROLE;
};

export function useOrganisationsLogic() {
    const {
        fetchUserOrganisationsData,
        setCurrentOrganisation,
        userOrganisations,
    } = useOrganisationsContext();

    const openInvitation = useCallback(
        async (
            invitation: Pick<
                InvitationData,
                'id' | 'user_id' | 'organisation_id'
            >,
        ) => {
            const data = await OrganisationsInvitationsAPI.openInvitation({
                invitation_id: invitation.id,
                user_id: invitation.user_id,
                organisation_id: invitation.organisation_id,
            });

            const { id } = data.payload.invitation;

            return id;
        },
        [],
    );

    const approveInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateOwnerInvitationStatus({
            user_invitation_id: invitationId,
            status: 'approved',
        });
        OrganisationAnalytics.logTeamInvitationApproved(
            FirebaseAnalytics.logEvent,
            { invitationId },
        );
    }, []);

    const rejectInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateOwnerInvitationStatus({
            user_invitation_id: invitationId,
            status: 'declined',
        });
    }, []);

    const acceptInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateUserInvitationStatus({
            user_invitation_id: invitationId,
            status: 'accepted',
        });
    }, []);

    const declineInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateUserInvitationStatus({
            user_invitation_id: invitationId,
            status: 'rejected',
        });
    }, []);

    const dismissInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.dismissInvitation({
            user_invitation_id: invitationId,
        });
    }, []);

    const updateMemberRole = useCallback(
        async ({
            memberId,
            organisationId,
            userRole,
        }: UpdateMemberRoleParams) => {
            await OrganisationsAPI.updateMemberRole({
                memberId,
                organisationId,
                userRole,
            });
        },
        [],
    );

    const removeMember = useCallback(
        async (memberId: string, organisationId: string) => {
            await OrganisationsInvitationsAPI.removeMember({
                member_id: memberId,
                organisation_id: organisationId,
            });
        },
        [],
    );

    const changeOrganisation = useCallback(
        (organisationId: string) => {
            const organisation = userOrganisations?.find(
                (org) => org.id === organisationId,
            );

            if (organisation) {
                setCurrentOrganisation(organisation);
            }
        },
        [setCurrentOrganisation, userOrganisations],
    );

    const joinOrganisation = useCallback(
        async (invitationId: string) => {
            await acceptInvitation(invitationId);

            await fetchUserOrganisationsData();
        },
        [acceptInvitation, fetchUserOrganisationsData],
    );

    return {
        acceptInvitation,
        approveInvitation,
        changeOrganisation,
        declineInvitation,
        dismissInvitation,
        joinOrganisation,
        openInvitation,
        rejectInvitation,
        removeMember,
        updateMemberRole,
    };
}
