import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { ENTRIES_FILTER } from 'shared/constants/entries/filters';
import { EntriesFiltersObject } from 'shared/types/entriesFilters';

import { getInitialDateRangeFilterValue } from '@/helpers/entries/filters';
import { checkIfAnyFilterIsActive } from '@/helpers/filters';
import { Context } from '@/types/context';
import { DateRange } from '@/types/dates';

const INITIAL_FILTERS_STATE: EntriesFiltersObject = {
    [ENTRIES_FILTER.horse_assigned]: [],
    [ENTRIES_FILTER.invoicing]: [],
    [ENTRIES_FILTER.date_range]: getInitialDateRangeFilterValue(),
    [ENTRIES_FILTER.contacts]: [],
    [ENTRIES_FILTER.job_type]: [],
};

const EntriesFiltersContext = createContext<Context.EntriesFilters.Value>({
    clearContextState: () => null,
    filtersState: INITIAL_FILTERS_STATE,
    searchState: '',
    setSearchState: () => null,
    isAnyFilterActive: false,
    isFiltersPanelVisible: false,
    resetFilters: () => null,
    setTempDateRangeFilterState: () => null,
    setFiltersState: () => null,
    setIsFiltersPanelVisible: () => null,
    tempDateRangeFilterState: { from: null, to: null },
});

const entriesFiltersContextRef: React.MutableRefObject<Context.EntriesFilters.Value | null> =
    createRef();

const EntriesFiltersProvider = ({ children }: Context.EntriesFilters.Props) => {
    // `tempDateRangeFilterState` is a separate state for date range filter to skip updating
    // main `filtersState` object when changing `from` and `to` values while `isActive` flag is false.
    // It helps to skip updating main `filtersState` object and avoid eg. resetting page state to `1`
    // after date change when `isActive` flag is set to `false` which means the date range filter is not applied.
    const [tempDateRangeFilterState, setTempDateRangeFilterState] = useState<
        DateRange<moment.Moment>
    >(getInitialDateRangeFilterValue);

    const [filtersState, setFiltersState] = useState(INITIAL_FILTERS_STATE);
    const [isFiltersPanelVisible, setIsFiltersPanelVisible] = useState(false);
    const [searchState, setSearchState] = useState('');

    const isAnyFilterActive = useMemo(() => {
        return checkIfAnyFilterIsActive(filtersState);
    }, [filtersState]);

    const resetFilters = useCallback(() => {
        setFiltersState(INITIAL_FILTERS_STATE);
        setSearchState('');
    }, []);

    const clearContextState = useCallback(() => {
        resetFilters();
    }, [resetFilters]);

    const contextValue: Context.EntriesFilters.Value = {
        clearContextState,
        filtersState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        searchState,
        setTempDateRangeFilterState,
        setFiltersState,
        setIsFiltersPanelVisible,
        setSearchState,
        tempDateRangeFilterState,
    };

    entriesFiltersContextRef.current = contextValue;

    return (
        <EntriesFiltersContext.Provider value={contextValue}>
            {children}
        </EntriesFiltersContext.Provider>
    );
};

function useEntriesFiltersContext() {
    const entriesFiltersContext = useContext(EntriesFiltersContext);

    return entriesFiltersContext;
}

function clearEntriesFiltersContextState() {
    return entriesFiltersContextRef.current?.clearContextState();
}

export {
    EntriesFiltersContext,
    EntriesFiltersProvider,
    useEntriesFiltersContext,
    clearEntriesFiltersContextState,
};
