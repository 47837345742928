export enum AppEvents {
    userLoggedIn = 'user_logged_in',
    userLoggedOut = 'user_logged_out',
    userLoggedOutSessionExpired = 'user_logged_out_session_expired',
    userRegistered = 'user_registered',
    userSetNewPassword = 'user_set_new_password',
    userSyncedCalendar = 'user_synced_calendar',
    userUsedCleanSync = 'user_used_clean_sync',
    userSentFeedback = 'user_sent_feedback',
}
