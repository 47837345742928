import { Avatar, Box, Skeleton, SxProps, Typography } from '@mui/material';
import React from 'react';

import { TeamFilledIcon } from '@/assets/svg';
import AddToTeamIcon from '@/assets/svg/AddToTeamIcon';
import TeamIcon from '@/assets/svg/TeamIcon';
import { Button } from '@/components';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

const buttonsSx: SxProps = {
    color: COLOR.white,
    backgroundColor: ENTITY_COLOR.teams,
    borderColor: COLOR.white,
    borderRadius: '3px',
    height: '28px',
    fontWeight: 'normal',
};

function Header({
    isTeamActive,
    teamPhotoUrl,
    teamName,
    onInviteNewMember,
    onEditTeam,
    isLoading,
    isYourTeam = false,
}: Props) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                background:
                    'transparent linear-gradient(0deg, #5D5288 0%, #554F6C 100%) 0% 0% no-repeat padding-box',
                display: 'flex',
                height: '180px',
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    mx: 'auto',
                    pl: '50px',
                    pr: '80px',
                    maxWidth: '1300px',
                    width: '100%',
                }}
            >
                {isLoading ? (
                    <Skeleton
                        data-test-id={'TeamsHeader-Skeleton'}
                        width="70%"
                        height={76}
                        variant="text"
                    />
                ) : (
                    <>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {!isTeamActive ? (
                                <>
                                    <TeamIcon height={60} width={86} />
                                    <Typography
                                        sx={{
                                            backgroundColor: COLOR.whiteDarker2,
                                            borderRadius: 30,
                                            textAlign: 'center',
                                            width: '135px',
                                            height: '21px',
                                            color: ENTITY_COLOR.teams,
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {t('TeamsPage:equinet_teams', {
                                            defaultValue: 'EQUINET Teams',
                                        })}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            width: '126px',
                                            height: '126px',
                                            display: 'flex',
                                            borderRadius: '10px',
                                            position: 'relative',
                                            overflow: 'visible',
                                            background: 'white',
                                            boxShadow: '0px 3px 6px #00000029',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mb: -12,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '120px',
                                                height: '120px',
                                                display: 'flex',
                                                borderRadius: '10px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                backgroundColor: 'white',
                                            }}
                                        >
                                            <Avatar
                                                src={teamPhotoUrl || undefined}
                                                sx={{
                                                    cursor: 'inherit',
                                                    width: '120px',
                                                    height: '120px',
                                                    backgroundColor:
                                                        COLOR.eclipse,
                                                }}
                                                variant="square"
                                            >
                                                <TeamIcon
                                                    height={80}
                                                    width={80}
                                                    color={'grey'}
                                                />
                                            </Avatar>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Box>
                        {!isTeamActive ? (
                            <Typography
                                sx={{
                                    width: '204px',
                                    textAlign: 'center',
                                    color: COLOR.whiteDarker2,
                                    ml: '21px',
                                }}
                            >
                                {t('TeamsPage:collaborate', {
                                    defaultValue:
                                        'Collaborate with members in your organisation',
                                })}
                            </Typography>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    ml: '15px',
                                    pt: 6,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: COLOR.white,
                                                fontSize: 20,
                                            }}
                                        >
                                            {teamName}
                                        </Typography>
                                        {isYourTeam ? (
                                            <Typography
                                                sx={{
                                                    color: COLOR.white,
                                                    opacity: '42%',
                                                    fontSize: 12,
                                                }}
                                            >
                                                {t('TeamsPage:your_team')}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TeamFilledIcon
                                            height={22}
                                            width={36}
                                            color={COLOR.white}
                                        />
                                        <Typography
                                            sx={{
                                                color: COLOR.white,
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                mt: '2px',
                                            }}
                                        >
                                            {t('Static:active')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        mb: 1,
                                        mt: 3,
                                        display: 'flex',
                                        gap: 2,
                                    }}
                                >
                                    {onInviteNewMember ? (
                                        <Button
                                            variant={'outlined'}
                                            sx={buttonsSx}
                                            startIcon={<AddToTeamIcon />}
                                            caption={t(
                                                'Organisation:invite_new_members',
                                            )}
                                            onClick={onInviteNewMember}
                                        />
                                    ) : null}
                                    {onEditTeam ? (
                                        <Button
                                            variant={'outlined'}
                                            sx={buttonsSx}
                                            caption={t('CreateTeam:edit_team')}
                                            onClick={onEditTeam}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}

export default Header;
