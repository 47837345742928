import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { InvitationData } from 'shared/types/api/user';

import { SectionHeader } from '@/components';
import UserTeamInvitationItem from '@/components/UserTeamInvitationItem';

import { Props } from './types';

function UserInvitationSection({
    invitations,
    onAccept,
    onDecline,
    onDismiss,
}: Props) {
    const [disabled, setDisabled] = useState(false);

    const handleAccept = useCallback(
        async (id: string) => {
            setDisabled(true);
            await onAccept(id);
            setDisabled(false);
        },
        [onAccept],
    );

    const handleDecline = useCallback(
        (invitation: InvitationData) => {
            setDisabled(true);
            onDecline(invitation);
            setDisabled(false);
        },
        [onDecline],
    );

    const handleDismiss = useCallback(
        async (id: string) => {
            setDisabled(true);
            await onDismiss(id);
            setDisabled(false);
        },
        [onDismiss],
    );

    return (
        <Box>
            <SectionHeader title={t('TeamsPage:team_invitations')} />
            <Box sx={{ px: '16px' }}>
                {invitations?.map((inv) => (
                    <UserTeamInvitationItem
                        disabled={disabled}
                        key={inv.id}
                        id={inv.organisation_id}
                        name={inv.organisation_name}
                        onAccept={() => handleAccept(inv.id)}
                        onDecline={() => handleDecline(inv)}
                        onDismiss={() => handleDismiss(inv.id)}
                        ownerName={inv.owner_name}
                        isDeclined={inv.status === 'declined'}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default UserInvitationSection;
