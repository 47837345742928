import {
    Skeleton,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleContainer } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function EntryNotesSection({
    testIdPrefix,
    isLoading,
    publicNotes,
    privateNotes,
}: Props) {
    const { t } = useTranslation();

    const [selectedNotes, setSelectedNotes] = useState<'public' | 'private'>(
        'public',
    );

    const handleChange = useCallback((_, value) => setSelectedNotes(value), []);

    const getEmptyMessageElement = useCallback(
        (text) => (
            <Typography
                color={COLOR.paleSky}
                data-test-id={`${testIdPrefix}-EmptyMessage`}
                fontStyle="italic"
                fontWeight={300}
            >
                {text}
            </Typography>
        ),
        [testIdPrefix],
    );

    return (
        <CollapsibleContainer
            testID={testIdPrefix}
            title={t('Entry:section:notes')}
        >
            <ToggleButtonGroup
                color="primary"
                value={selectedNotes}
                exclusive
                onChange={handleChange}
                sx={{ mb: 1.5, width: '100%', display: 'flex' }}
            >
                <ToggleButton
                    value="public"
                    sx={{ flex: 1 }}
                    data-test-id={`${testIdPrefix}-PublicButton`}
                >
                    {t('Entry:section:public')}
                </ToggleButton>
                <ToggleButton
                    value="private"
                    sx={{ flex: 1 }}
                    data-test-id={`${testIdPrefix}-PrivateButton`}
                >
                    {t('Entry:section:private')}
                </ToggleButton>
            </ToggleButtonGroup>
            {isLoading ? (
                <Skeleton width="100%" height={76} />
            ) : selectedNotes === 'public' && !publicNotes ? (
                getEmptyMessageElement(t('Entry:public_notes_blankslate'))
            ) : selectedNotes === 'public' && publicNotes ? (
                <Typography data-test-id={`${testIdPrefix}-PublicNotes`}>
                    {publicNotes}
                </Typography>
            ) : selectedNotes === 'private' && !privateNotes ? (
                getEmptyMessageElement(t('Entry:private_notes_blankslate'))
            ) : (
                <Typography data-test-id={`${testIdPrefix}-PrivateNotes`}>
                    {privateNotes}
                </Typography>
            )}
        </CollapsibleContainer>
    );
}

export default EntryNotesSection;
