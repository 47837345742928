import quickbooksLogo from '../../assets/images/invoicing/quickbooks.png';
import xeroLogo from '../../assets/images/invoicing/xero.png';
import { ExportInvoicesFormatItem } from '../../types/Invoicing';

export const EXPORT_INVOICES_FORMATS: ExportInvoicesFormatItem[] = [
    {
        translationKeys: {
            description: 'Invoicing:csv',
            subtitle: 'Invoicing:xero_company',
            title: 'Invoicing:xero_name',
        },
        image: xeroLogo,
        type: 'xero',
    },
    {
        translationKeys: {
            description: 'Invoicing:csv',
            subtitle: 'Invoicing:quickbooks_company',
            title: 'Invoicing:quickbooks_name',
        },
        image: quickbooksLogo,
        type: 'quickbooks',
    },
];
