export enum SHOPPING_PRODUCT_UNIT {
    box = 'Boxes',
    pair = 'Pair',
    set = 'Set',
    pack = 'PKT 250',
    piece = 'Pieces',
    rasp = 'Pieces',
}

export enum SHOPPING_PRODUCT_UNIT_VALUE {
    pair = 2,
    set = 4,
    pack = 250,
    piece = 1,
    rasp = 1,
}
