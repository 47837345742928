import { Add } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { of } from 'rxjs/internal/observable/of';
import Database from 'shared/db/services/Database.web';
import ShoppingList from 'shared/db/services/ShoppingList';
import { ShoppingListModel } from 'shared/types/ShoppingList';

import {
    AddShoppingListModal,
    Button,
    Pagination,
    SearchInput,
    TableSkeleton,
} from '@/components';
import { APP_CONTENT_ID } from '@/constants/documentIds';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useShoppingListFiltersContext } from '@/context/ShoppingListFiltersContext';
import { calculatePaginatedRows, PAGE_LIMIT } from '@/helpers/pagination';
import withSyncContext from '@/hoc/withInitialSyncContext/withSyncContext';
import {
    useDebouncedValue,
    useScrollToDocumentTopOnValueChange,
} from '@/hooks';
import Logger from '@/services/logger';

import { ShoppingListsTable } from './components';
import { Props } from './types';

const pageStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const tableStyles: SxProps<Theme> = {
    mr: 3,
    flexGrow: 1,
};

function ShoppingListsPage({ shoppingLists, totalRecords }: Props) {
    const { searchState, setSearchState } = useShoppingListFiltersContext();
    const { ImagesService } = useImagesContext();

    const { isInitialSyncInProgress } = useDBSyncContext();

    const [showAddShoppingListModal, setShowAddShoppingListModal] =
        useState<boolean>(false);
    const [isShoppingListsDataLoading, setIsShoppingListsDataLoading] =
        useState(false);
    const [filteredShoppingLists, setFilteredShoppingLists] = useState<
        ShoppingListModel[]
    >([]);

    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState<number>(
        totalRecords ? totalRecords : 0,
    );

    const handlePageChange = useCallback(
        (_, page: number) => setPage(page),
        [],
    );

    useScrollToDocumentTopOnValueChange(APP_CONTENT_ID, page);

    const openAddShoppingListModal = useCallback(
        () => setShowAddShoppingListModal(true),
        [],
    );

    const closeAddShoppingListModal = useCallback(
        () => setShowAddShoppingListModal(false),
        [],
    );

    const shoppingListService = useMemo(
        () =>
            new ShoppingList({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService],
    );

    const fetchShoppingLists = useCallback(
        async (search: string) => {
            setIsShoppingListsDataLoading(true);

            try {
                const filteredShoppingListsCount =
                    await shoppingListService.getFilteredShoppingListsCount(
                        search,
                    );

                setRowsCount(filteredShoppingListsCount);

                let paginatedShoppingLists: ShoppingListModel[] = [];
                if (filteredShoppingListsCount > 0) {
                    const shoppingLists =
                        await shoppingListService.getFilteredShoppingLists(
                            search,
                        );
                    paginatedShoppingLists = calculatePaginatedRows(
                        page,
                        shoppingLists,
                    );
                }
                setFilteredShoppingLists(paginatedShoppingLists);
            } finally {
                setIsShoppingListsDataLoading(false);
            }
        },
        [page, shoppingListService],
    );

    const handleSearchInputChange = useCallback(
        (value: string) => setSearchState(value),
        [setSearchState],
    );

    const clearSearchText = useCallback(
        () => setSearchState(''),
        [setSearchState],
    );

    const searchTextDebounced = useDebouncedValue(searchState);

    useEffect(() => setPage(1), [searchTextDebounced]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchShoppingLists(searchTextDebounced);
        }
    }, [fetchShoppingLists, isInitialSyncInProgress, searchTextDebounced]);

    const refetchShoppingLists = useCallback(() => {
        fetchShoppingLists(searchTextDebounced);
    }, [fetchShoppingLists, searchTextDebounced]);

    const emptyStateMessage =
        filteredShoppingLists.length === 0 &&
        shoppingLists &&
        shoppingLists.length > 0
            ? t('ShoppingListsPage:no_shopping_lists_found_blankslate')
            : t('ShoppingListsPage:no_shopping_lists_has_been_added');

    const showLoadingState =
        (!shoppingLists?.length && isShoppingListsDataLoading) ||
        isInitialSyncInProgress;

    return (
        <Box sx={pageStyles}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <SearchInput
                            onChange={handleSearchInputChange}
                            onClear={clearSearchText}
                            testId="ShoppingListsPage-SearchInput"
                            value={searchState}
                        />
                    </Box>
                    <Button
                        caption={t('ShoppingListsPage:buttons:add')}
                        onClick={openAddShoppingListModal}
                        startIcon={<Add />}
                        data-test-id="ShoppingListsPage-AddShoppingListButton"
                    />
                </Box>
            </Box>
            <Box sx={tableStyles}>
                {showLoadingState ? (
                    <TableSkeleton data-test-id="ShoppingListsPage-TableSkeleton" />
                ) : (
                    <ShoppingListsTable
                        refetchShoppingLists={refetchShoppingLists}
                        emptyStateMessage={emptyStateMessage}
                        rows={
                            searchTextDebounced || page > 1
                                ? filteredShoppingLists
                                : shoppingLists ?? []
                        }
                    />
                )}
            </Box>
            {rowsCount > 0 && (
                <Pagination
                    testID="ShoppingListsPage-Pagination"
                    page={page}
                    recordsCount={rowsCount}
                    onChange={handlePageChange}
                />
            )}
            {showAddShoppingListModal ? (
                <AddShoppingListModal close={closeAddShoppingListModal} />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        const shoppingListsRecords = database.collections
            .get<ShoppingListModel>('shopping_lists')
            .query()
            .observeCount();

        const shoppingLists = database.collections
            .get<ShoppingListModel>('shopping_lists')
            .query(
                Q.sortBy('created_at', 'desc'),
                Q.skip(0),
                Q.take(PAGE_LIMIT),
            );

        return {
            shoppingLists: isInitialSyncInProgress ? of() : shoppingLists,
            totalRecords: isInitialSyncInProgress
                ? of(0)
                : shoppingListsRecords,
        };
    },
);

export default withSyncContext<Props>(enhance(ShoppingListsPage));
