import { Moment } from 'moment';
import * as yup from 'yup';

import { t } from '@/services/translations/config';

const addEditScheduleForm = yup.object().shape({
    date: yup
        .mixed()
        .required(
            t('SignUp:error:field_required', {
                field: t('AddEventForm:labels:date'),
            }),
        )
        .test(
            'date',
            t('AddEventForm:error:date_validation'),
            (value: Moment | null) => !!value?.isValid(),
        ),
    startTime: yup
        .mixed()
        .required(
            t('SignUp:error:field_required', {
                field: t('AddEventForm:labels:start_time'),
            }),
        )
        .test(
            'startTime',
            t('AddEventForm:error:start_time_validation'),
            (value: Moment | null) => !!value?.isValid(),
        ),
    endTime: yup
        .mixed()
        .required(
            t('SignUp:error:field_required', {
                field: t('AddEventForm:labels:end_time'),
            }),
        )
        .test(
            'endTime',
            t('AddEventForm:error:end_time_validation'),
            (value: Moment | null) => !!value?.isValid(),
        ),
});

const titleField = yup
    .string()
    .min(
        2,
        t('SignUp:error:field_must_contain_at_least_2_characters', {
            field: t('AddEventForm:labels:title'),
        }),
    )
    .required(
        t('SignUp:error:field_required', {
            field: t('AddEventForm:labels:title'),
        }),
    )
    .max(50, t('Event:errors:event_title_length'));

export const ScheduleValidationSchema = { addEditScheduleForm, titleField };
