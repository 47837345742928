export enum HORSE_GENDER {
    mare = 'mare',
    filly = 'filly',
    gelding = 'gelding',
    colt = 'colt',
    stallion = 'stallion',
    speller = 'speller',
    weanling = 'weanling',
    yearling = 'yearling',
    nanny = 'nanny',
}

export const HORSE_GENDERS_DICTIONARY: Record<HORSE_GENDER, string> = {
    [HORSE_GENDER.mare]: 'HorseProfile:picker:genders:mare',
    [HORSE_GENDER.filly]: 'HorseProfile:picker:genders:filly',
    [HORSE_GENDER.gelding]: 'HorseProfile:picker:genders:gelding',
    [HORSE_GENDER.colt]: 'HorseProfile:picker:genders:colt',
    [HORSE_GENDER.stallion]: 'HorseProfile:picker:genders:stallion',
    [HORSE_GENDER.speller]: 'HorseProfile:picker:genders:speller',
    [HORSE_GENDER.weanling]: 'HorseProfile:picker:genders:weanling',
    [HORSE_GENDER.yearling]: 'HorseProfile:picker:genders:yearling',
    [HORSE_GENDER.nanny]: 'HorseProfile:picker:genders:nanny',
};
