import React, { memo, useEffect, useState } from 'react';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { getRoleAllowedRoutes } from '@/helpers/router';
import { ROUTE, routes } from '@/router/routes';

import { SidebarLinksListItem } from './components';
import { Props } from './types';

function SidebarLinksList({ currentPathname, links }: Props) {
    const { isInitialSyncInProgress } = useDBSyncContext();
    const { orgUser } = useOrgUserContext();
    const [roleAllowedRoutes, setRoleAllowedRoutes] = useState<ROUTE[] | null>(
        null,
    );

    useEffect(() => {
        if (!isInitialSyncInProgress && orgUser) {
            setRoleAllowedRoutes(getRoleAllowedRoutes(routes, orgUser.role));
        }
    }, [isInitialSyncInProgress, orgUser, orgUser?.role]);

    return (
        <>
            {links
                .filter(
                    (link) =>
                        roleAllowedRoutes &&
                        roleAllowedRoutes.includes(link.path as ROUTE),
                )
                .map((link) => (
                    <SidebarLinksListItem
                        isActive={currentPathname.includes(link.path)}
                        key={link.id}
                        linkData={link}
                    />
                ))}
        </>
    );
}

export default memo(SidebarLinksList);
