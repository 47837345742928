import { Collection, Database, Q } from '@nozbe/watermelondb';

import { QuestionModel } from '../../types/question';

class Question {
    private collection: Collection<QuestionModel>;
    private table = 'questions';

    constructor(database: Database) {
        this.collection = database.collections.get(this.table);
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByVersion(version: number) {
        return this.collection.query(
            Q.where('question_version', version),
            Q.sortBy('question_order', 'asc'),
        );
    }
}

export default Question;
