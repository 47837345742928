import React from 'react';

import { COLOR } from '@/theme/colors';
import { SVGIconProps } from '@/types/svg';

function TickIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.1 8.9"
            xmlSpace="preserve"
            width={width}
            height={height}
            fill={color}
        >
            <g id="Group_1802" transform="translate(0.001 0)">
                <path
                    id="Path_959"
                    d="M9.7,0L3.8,6.7L1,3.5L0,4.6l3.8,4.3l6.9-7.8L9.7,0z"
                />
            </g>
        </svg>
    );
}

export default TickIcon;
