import { Collection, Database, Q } from '@nozbe/watermelondb';

import User from './User';
import { UserAnswerModel, UserAnswerPayload } from '../../types/userAnswer';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class UserAnswer {
    private readonly database: Database;
    private collection: Collection<UserAnswerModel>;
    private table = 'user_answers';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByQuestionID(questionID: string, userID: string) {
        return this.collection
            .query(
                Q.experimentalJoinTables(['answers']),
                Q.and(
                    Q.where('user_id', userID),
                    Q.on('answers', Q.where('question_id', questionID)),
                ),
            )
            .fetch();
    }

    async add(payload: UserAnswerPayload) {
        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const user = await userService.get().fetch();
        const { id: userID } = user[0];

        return await this.database.write(async () => {
            const createdUserAnswer = await this.collection.create(
                (userAnswer) => {
                    userAnswer.userId = userID;
                    userAnswer.answerId = payload.answerId;
                },
            );

            this.options.logDBAction({
                message: 'Create user answer',
                modelName: this.table,
                payload: createdUserAnswer,
            });

            return createdUserAnswer;
        });
    }

    async batchAdd(batch: UserAnswerPayload[]) {
        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const user = await userService.get();
        const { id: userID } = user[0];

        return await this.database.write(async () => {
            const preparedAnswers = batch.map((answer) =>
                this.collection.prepareCreate((userAnswer) => {
                    userAnswer.userId = userID;
                    userAnswer.answerId = answer.answerId;
                }),
            );

            this.database.batch(...preparedAnswers);

            this.options.logDBAction({
                message: 'Create user answers',
                modelName: this.table,
                payload: preparedAnswers,
            });
        });
    }

    async deleteAll() {
        await this.database.write(async () => {
            const userAnswers = await this.collection.query().fetch();
            const toBeDeleted = userAnswers.map((answer) =>
                answer.prepareMarkAsDeleted(),
            );
            await this.database.batch(...toBeDeleted);
        });
    }

    async batchDelete(answers: UserAnswerPayload[]) {
        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const user = await userService.get().fetch();
        const { id: userID } = user[0];

        await this.database.write(async () => {
            const userAnswers = await this.collection
                .query(
                    Q.and(
                        Q.where('user_id', userID),
                        Q.or(
                            ...answers.map((answer) =>
                                Q.where('answer_id', answer.answerId),
                            ),
                        ),
                    ),
                )
                .fetch();

            const toBeDeleted = userAnswers.map((userAnswers) =>
                userAnswers.prepareMarkAsDeleted(),
            );

            await this.database.batch(...toBeDeleted);

            this.options.logDBAction({
                message: 'Delete user answers',
                modelName: this.table,
                payload: userAnswers,
            });
        });
    }
}

export default UserAnswer;
