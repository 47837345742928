import React, { useMemo } from 'react';
import { EventModel } from 'shared/types/Events';

import { SchedulesGroupedList } from '@/pages/SchedulesPage/components';

import { Props } from './types';
function AgendaComponent({ events }: Props) {
    const formattedEvents: EventModel[] = useMemo(
        () => events.map((event) => event.eventModel),
        [events],
    );
    return <SchedulesGroupedList futureEvents={formattedEvents} />;
}

AgendaComponent.navigate = (date: Date) => {
    return date;
};

AgendaComponent.title = () => {
    return '';
};

export default AgendaComponent;
