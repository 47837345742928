// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class ProductSalesUnit extends Model {
    static table = 'product_sales_units';

    static associations = {
        product_sales_unit_regions: {
            type: 'has_many',
            foreignKey: 'product_sales_unit_id',
        },
        products: { type: 'belongs_to', key: 'product_id' },
    };

    @lazy
    productSalesUnitRegions = this.collections
        .get('product_sales_unit_regions')
        .query(Q.where('product_sales_unit_id', this.id));

    @relation('products', 'product_id') product;
    @field('box_unit_size') boxUnitSize;
    @field('sku_apac') skuApac;
    @field('sku_emea') skuEmea;
    @field('product_id') productId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
