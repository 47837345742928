import { Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useMemo } from 'react';
import { getContactsText } from 'shared/utils/contacts';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function MonthEvent({ event, contacts }: Props) {
    const eventContactsText = useMemo(
        () => getContactsText(contacts ?? []),
        [contacts],
    );
    return (
        <Typography
            sx={{
                color: COLOR.white,
                fontSize: '13px',
                lineHeight: '22px',
                fontWeight: '400',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
            }}
        >
            {eventContactsText
                ? `${eventContactsText} (${event.eventModel.title})`
                : event.eventModel.title}
        </Typography>
    );
}

const enhance = withObservables(['event'], ({ event }) => {
    return { contacts: event.eventModel.contacts.observe() };
});

export default enhance(MonthEvent);
