import { Duration } from 'luxon';

import { diffBetweenDatesByRange, getFromISO, getLocal } from './date';

type ParseAgeOptions = {
    yearText: string;
    yearsText: string;
    monthText: string;
    monthsText: string;
};

export function parseAge(foalDate: string, options: ParseAgeOptions): string {
    if (!foalDate) {
        return '';
    }

    const foalAt = getFromISO(foalDate);
    const now = getLocal();
    const diff: Duration = now.diff(foalAt, ['years', 'months']);
    const years: number = Math.floor(diff.get('years'));
    const months: number = Math.floor(diff.get('months'));

    if (years <= 0 && months <= 0) {
        return `0 ${options.yearsText}`;
    }

    let parsedAge = '';

    if (years !== 0) {
        const suffix = years === 1 ? options.yearText : options.yearsText;
        parsedAge = parsedAge.concat(`${years} ${suffix}`);
    }

    if (months !== 0) {
        const suffix = months === 1 ? options.monthText : options.monthsText;
        parsedAge = parsedAge.concat(` ${months} ${suffix}`);
    }

    return parsedAge.trim();
}

export function calculateHorseAge(foalDate: string): string {
    return diffBetweenDatesByRange(getLocal(), foalDate, 'years').toString();
}
