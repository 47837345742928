import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { InvoiceModel } from 'shared/types/Invoice';
import { getInvoiceStatus } from 'shared/utils/invoicing';

import { COLOR, ENTITY_COLOR } from '@/theme/colors';

export function useInvoiceStatusLabel(
    invoice: InvoiceModel | null,
    hasAccountingProvider: boolean,
) {
    if (invoice === null) {
        return null;
    }

    const status = getInvoiceStatus({
        hasAccountingProvider,
        translateFn: t,
        sentToContact: invoice.sentToContact,
        sentToContactTime: invoice.sentToContactTime,
        status: invoice.status,
    });

    switch (status) {
        case 'Draft':
            return (
                <Typography
                    data-test-id="InvoicesPage-TableRow-Status"
                    sx={{
                        color: COLOR.darkYellow,
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: '24px',
                    }}
                >
                    {status.toUpperCase()}
                </Typography>
            );
        case 'Paid':
            return (
                <div className="flex flex-row shrink items-center gap-[4px] p-1 rounded-md bg-invoiceLightGreen">
                    <CheckCircleIcon
                        sx={{
                            color: ENTITY_COLOR.invoices,
                            width: '15px',
                            height: '15px',
                        }}
                    />
                    <Typography
                        data-test-id="InvoicesPage-TableRow-Status"
                        sx={{
                            fontSize: 13,
                            fontWeight: 600,
                            lineHeight: '15px',
                        }}
                    >
                        {status.toUpperCase()}
                    </Typography>
                </div>
            );
        default:
            return (
                <Typography
                    data-test-id="InvoicesPage-TableRow-Status"
                    sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: '24px',
                    }}
                >
                    {status.toUpperCase()}
                </Typography>
            );
    }
}
