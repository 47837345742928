import { Box, Grid } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

const NAVBAR_HEIGHT = '63px';

function AuthPageLayout({
    backgroundImage,
    navbarContent,
    rightColumnContent,
}: Props) {
    return (
        <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: COLOR.deepCerulean,
                    display: 'flex',
                    height: NAVBAR_HEIGHT,
                    justifyContent: 'flex-end',
                    width: '100%',
                }}
            >
                {navbarContent}
            </Box>
            <Grid
                item
                xs={0}
                md={4}
                sx={{
                    backgroundColor: 'gray',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            />
            <Grid
                item
                xs={12}
                md={8}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: `calc(100% - ${NAVBAR_HEIGHT})`,
                    overflowY: 'auto',
                }}
            >
                {rightColumnContent}
            </Grid>
        </Grid>
    );
}

export default AuthPageLayout;
