import {
    CONTACT_ROLES_DICTIONARY,
    CONTACT_ROLES_HUMANIZED_KEYS,
    CONTACT_ROLES_TRANSLATION_KEYS,
} from '../constants/contacts/roles';
import {
    RolesDictionaryObject,
    RolesHumanizedObject,
} from '../types/contactRoles';

type TranslateFn = (translationKey: string) => string;

export function getRoles(translateFn: TranslateFn): string[] {
    return CONTACT_ROLES_TRANSLATION_KEYS.map((key) => translateFn(key));
}

export function getRolesDictionary(
    translateFn: TranslateFn,
): RolesDictionaryObject {
    const roles = Object.keys(CONTACT_ROLES_DICTIONARY).reduce((acc, key) => {
        return { ...acc, [key]: translateFn(CONTACT_ROLES_DICTIONARY[key]) };
    }, {} as RolesDictionaryObject);

    return roles;
}

export function getRolesHumanized(
    translateFn: TranslateFn,
): RolesHumanizedObject {
    const rolesHumanized = Object.keys(CONTACT_ROLES_HUMANIZED_KEYS).reduce(
        (acc, key) => {
            return {
                ...acc,
                [translateFn(key)]: CONTACT_ROLES_HUMANIZED_KEYS[key],
            };
        },
        {} as RolesHumanizedObject,
    );

    return rolesHumanized;
}
