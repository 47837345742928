import { Avatar } from '@mui/material';
import React from 'react';

import { CartIcon } from '@/assets/svg';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const HIGHLIGHTED_COLORS = [COLOR.lightBlue, COLOR.deepCerulean];
const REGULAR_COLORS = [COLOR.shoppingListAvatar, COLOR.mischka];

function ShoppingAvatar({ isHighlighted, size = 72 }: Props) {
    const avatarColors = isHighlighted ? HIGHLIGHTED_COLORS : REGULAR_COLORS;

    const [backgroundColor, cartIconColor] = avatarColors;

    return (
        <Avatar
            sx={{
                backgroundColor,
                height: size,
                mr: 3,
                width: size,
            }}
            variant="rounded"
        >
            <CartIcon width={35} height={35} color={cartIconColor} />
        </Avatar>
    );
}

export default ShoppingAvatar;
