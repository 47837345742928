import { CancelRounded, Search } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useCallback, memo, useRef } from 'react';

import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SearchInput({
    disabled,
    inputStyles,
    onChange,
    onClear,
    testId,
    value,
    sx = {},
}: Props) {
    const inputRef = useRef<HTMLDivElement | null>(null);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            onChange(value);
        },
        [onChange],
    );

    const handleClear = useCallback(() => {
        onClear();

        inputRef.current?.focus();
    }, [onClear]);

    return (
        <TextField
            data-test-id={testId}
            disabled={disabled}
            size="small"
            placeholder={t('Actions:search')}
            inputRef={inputRef}
            inputProps={{
                'data-test-id': 'SearchInput-Input',
            }}
            InputProps={{
                startAdornment: (
                    <Search sx={{ color: COLOR.paleSky, marginRight: 1 }} />
                ),
                endAdornment: value ? (
                    <button
                        className="pl-2"
                        data-test-id="SearchInput-CrossButton"
                        onClick={handleClear}
                        type="button"
                    >
                        <CancelRounded
                            fontSize="small"
                            sx={{ color: COLOR.paleSky }}
                        />
                    </button>
                ) : null,
                sx: inputStyles,
            }}
            onChange={handleChange}
            sx={{ width: 250, ...sx }}
            value={value}
        />
    );
}

export default memo(SearchInput);
