import { useEffect } from 'react';

const promptBeforeUnload = (event: BeforeUnloadEvent) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
        e.returnValue = '';
    }
    return '';
};

export function useBrowserExitPrompt() {
    useEffect(() => {
        window.addEventListener('beforeunload', promptBeforeUnload);
        return () =>
            window.removeEventListener('beforeunload', promptBeforeUnload);
    }, []);
}
