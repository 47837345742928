import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import type { Props } from './types';

export function ProceduresFinancialItems({ procedures, currency }: Props) {
    return (
        <Box sx={{ dispaly: 'flex', marginLeft: '90px', marginRight: '12px' }}>
            {procedures.map((procedure, index) => (
                <>
                    <Box
                        key={procedure.id}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: COLOR.athensGrayDarker,
                            paddingY: '16px',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                maxWidth: '55%',
                            }}
                        >
                            <Typography fontSize={14} marginRight={'4px'}>
                                {`${procedure.quantity}x`}
                            </Typography>
                            <Typography
                                fontSize={14}
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {procedure.name}
                            </Typography>
                        </Box>
                        <Typography
                            fontWeight="bold"
                            fontSize={14}
                            maxWidth={'35%'}
                            sx={{ wordBreak: 'break-word' }}
                        >{`${currency}${procedure.price}`}</Typography>
                    </Box>
                    {index < procedures.length - 1 ? (
                        <Box
                            sx={{
                                borderWidth: 0.5,
                                borderColor: COLOR.alto,
                            }}
                        />
                    ) : null}
                </>
            ))}
        </Box>
    );
}
