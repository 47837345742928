import { ContactModel } from '../types/Contacts';

export function getContactsText(contacts: ContactModel[]) {
    return contacts.length
        ? contacts
              .map((contact) =>
                  `${contact.firstName} ${contact.lastName ?? ''}`.trim(),
              )
              .join(', ')
        : '';
}
