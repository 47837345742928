import axios from 'axios';
import React from 'react';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';

import { CUSTOM_ERROR } from '@/constants/customErrors';
import Logger from '@/services/logger';

type HandleAuthFlowErrorOptions = {
    error: any;
    setAuthErrorCode: (
        value: React.SetStateAction<number | FIREBASE_AUTH_ERROR_CODE | null>,
    ) => void;
    setAuthErrorMessage?: (value: React.SetStateAction<string | null>) => void;
};

export function handleSignUpFlowError({
    error,
    setAuthErrorCode,
}: Omit<HandleAuthFlowErrorOptions, 'setAuthErrorMessage'>) {
    if (error.message === CUSTOM_ERROR.no_user_default_org_found) {
        setAuthErrorCode(500);
        Logger.logError('(Firebase Sign Up) No user default org found');

        return;
    }

    if (error.message === CUSTOM_ERROR.no_firebase_token_found) {
        setAuthErrorCode(500);
        Logger.logError(
            '(Firebase Sign Up) No firebase token found when signing up',
        );

        return;
    }

    if (error.code === FIREBASE_AUTH_ERROR_CODE.email_already_in_use) {
        setAuthErrorCode(error.code);
        Logger.logSentryInfo(
            `(Firebase Sign Up) Failed sign up attempt: ${error.code}`,
        );

        return;
    }

    if (axios.isAxiosError(error)) {
        setAuthErrorCode(error.response?.status || 500);
        Logger.logSentryInfo(
            `(Sign Up) Failed sign up attempt (axios error): ${error.message}`,
        );

        return;
    }

    setAuthErrorCode(500);
    Logger.logError(`(Sign Up) Failed sign up attempt: ${error}`);
}

export function handleSignInFlowError({
    error,
    setAuthErrorCode,
    setAuthErrorMessage,
}: HandleAuthFlowErrorOptions) {
    if (
        error.message === CUSTOM_ERROR.no_reset_password_token_or_email_provided
    ) {
        setAuthErrorCode(FIREBASE_AUTH_ERROR_CODE.expired_action_code);

        return;
    }

    if (error.message === CUSTOM_ERROR.no_firebase_token_found) {
        setAuthErrorCode(500);
        Logger.logError(
            '(Firebase Auth) No firebase token found when signing in',
        );

        return;
    }

    if (
        [
            FIREBASE_AUTH_ERROR_CODE.invalid_email,
            FIREBASE_AUTH_ERROR_CODE.expired_action_code,
            FIREBASE_AUTH_ERROR_CODE.invalid_action_code,
            FIREBASE_AUTH_ERROR_CODE.user_not_found,
            FIREBASE_AUTH_ERROR_CODE.wrong_password,
            FIREBASE_AUTH_ERROR_CODE.email_already_in_use,
        ].includes(error.code)
    ) {
        setAuthErrorCode(error.code);

        return;
    }

    if (axios.isAxiosError(error)) {
        setAuthErrorCode(error.response?.status || 500);

        if (setAuthErrorMessage) {
            setAuthErrorMessage(error.response?.data.errors.message);
        }

        return;
    }

    Logger.logError(`(Auth) Failed sign in attempt: ${error}`);

    setAuthErrorCode(500);
}
