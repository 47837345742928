import {
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ROLE } from 'shared/types/OrganisationUser';

import { EmptyState } from '@/components';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { COLOR } from '@/theme/colors';

import { EntriesTableItem } from './components';
import { Props } from './types';

const headerFontStyle: SxProps = {
    color: COLOR.paleSky,
    fontWeight: 700,
    fontSize: 13,
};

function EntriesTable({
    emptyStateMessage,
    entries,
    loadingEntry,
    onEditItem,
    onAddInvoice,
}: Props) {
    const { t } = useTranslation();
    const { isImagesSyncInProgress } = useImagesContext();
    const { orgUser } = useOrgUserContext();

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    return (
        <>
            <TableContainer>
                {!entries.length ? (
                    <EmptyState
                        testID="EntriesTable-EmptyStateMessage"
                        message={emptyStateMessage}
                    />
                ) : null}
                {entries.length ? (
                    <Table
                        sx={{
                            minWidth: 650,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography sx={headerFontStyle}>
                                        {t(
                                            'EntriesList:table_columns:entry_and_procedures',
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t(
                                            'EntriesList:table_columns:created_by',
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t('EntriesList:table_columns:date')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t(
                                            'EntriesList:table_columns:entry_horse_contacts',
                                        )}
                                    </Typography>
                                </TableCell>
                                {isOrgUserAdmin ? (
                                    <TableCell align="left">
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'EntriesList:table_columns:invoice_status',
                                            )}
                                        </Typography>
                                    </TableCell>
                                ) : null}
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry) => {
                                return (
                                    <EntriesTableItem
                                        key={entry.id}
                                        entry={entry}
                                        isPhotosLoading={
                                            loadingEntry?.id === entry.id &&
                                            isImagesSyncInProgress
                                        }
                                        onEditItem={onEditItem}
                                        onAddInvoice={onAddInvoice}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : null}
            </TableContainer>
        </>
    );
}

export default memo(EntriesTable);
