import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '@/context/AuthContext';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { ServerAuthAPI } from '@/services/networking/auth';
import { Snackbar } from '@/services/toastNotifications';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const SECURITY_WORD = 'delete';

function DeleteAccountModal({ close }: Props) {
    const { invalidateUserAuth } = useAuthContext();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [securityWord, setSecurityWord] = useState('');

    const handleOnPhraseChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSecurityWord(event.target.value);
        },
        [],
    );

    const signOut = useCallback(async () => {
        await FirebaseAuthAPI.signOut();

        invalidateUserAuth();
    }, [invalidateUserAuth]);

    const deleteAccount = useCallback(async () => {
        await ServerAuthAPI.deleteAccount();

        await signOut();
        await FirebaseAuthAPI.removeAccount();
    }, [signOut]);

    const handleDeleteAccountButtonClick = useCallback(async () => {
        try {
            setIsLoading(true);

            await deleteAccount();
            close();

            Snackbar.showToastNotification({
                message: t('Options:alert:account_deleted'),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        } finally {
            setIsLoading(false);
        }
    }, [close, deleteAccount, t]);

    return (
        <Dialog data-test-id="DeleteAccountModal" open>
            <DialogTitle>{t('AccountDelete:modal:title')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    {t('AccountDelete:modal:information')}
                </DialogContentText>
                <DialogContentText sx={{ mb: 3 }}>
                    {t('AccountDelete:modal:input_info_first')}
                    <DialogContentText color={COLOR.errorRed} component="span">
                        {` ${SECURITY_WORD} `}
                    </DialogContentText>
                    {t('AccountDelete:modal:input_info_second')}
                </DialogContentText>
                <TextField
                    data-test-id="DeleteAccountModal-SecurityWordInput"
                    fullWidth
                    label={t('AccountDelete:modal:input_placeholder')}
                    onChange={handleOnPhraseChange}
                    value={securityWord}
                />
            </DialogContent>
            <DialogActions sx={{ display: 'flex', gap: 1 }}>
                <Button
                    color="error"
                    data-test-id="DeleteAccountModal-DeleteButton"
                    disabled={SECURITY_WORD !== securityWord || isLoading}
                    onClick={handleDeleteAccountButtonClick}
                    size="large"
                    sx={{ minWidth: 155 }}
                    variant="outlined"
                >
                    {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        t('AccountDelete:modal:delete_button')
                    )}
                </Button>
                <Button
                    data-test-id="DeleteAccountModal-CancelButton"
                    disabled={isLoading}
                    onClick={close}
                    size="large"
                    variant="text"
                >
                    {t('Actions:cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteAccountModal;
