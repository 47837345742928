import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { NavigationAnalytics } from 'shared/analytics/navigation';

import {
    CartIcon,
    ContactsIcon,
    EntriesIcon,
    HomeIcon,
    HorseIcon,
    InventoryIcon,
    InvoiceIcon,
    ScheduleIcon,
    SettingsIcon,
} from '@/assets/svg';
import TeamIcon from '@/assets/svg/TeamIcon/TeamIcon';
import { SIDEBAR_COMPACT_VERSION_WINDOW_HEIGHT_THRESHOLD } from '@/constants/sidebar';
import { useEventInvitationContext } from '@/context/EventInvitationContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useWindowSize } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { COLOR, ENTITY_COLOR, ENTITY_SECONDARY_COLOR } from '@/theme/colors';

import { SidebarCompactVersion, SidebarFullVersion } from './components';

function Sidebar() {
    const { height } = useWindowSize();

    const { pendingOwnerApprovalInvitations, userInvitationsToAccept } =
        useOrganisationsContext();

    const { attentionNeededEvents } = useEventInvitationContext();

    const teamsBadgeCount = useMemo(() => {
        const filteredInvitations = userInvitationsToAccept.filter(
            (inv) => inv.status !== 'declined',
        );
        return (
            (pendingOwnerApprovalInvitations?.length || 0) +
            filteredInvitations.length
        );
    }, [pendingOwnerApprovalInvitations?.length, userInvitationsToAccept]);

    const showCompactVersion = height
        ? height < SIDEBAR_COMPACT_VERSION_WINDOW_HEIGHT_THRESHOLD
        : true;

    const allLinks = useMemo(
        () => [
            {
                displayNameKey: 'Sidebar:dashboard',
                Icon: HomeIcon,
                color: ENTITY_COLOR.dashboard,
                secondaryColor: ENTITY_SECONDARY_COLOR.dashboard,
                id: '1',
                path: ROUTE.dashboard,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_dashboard'),
            },
            {
                displayNameKey: 'Sidebar:contacts',
                Icon: ContactsIcon,
                color: ENTITY_COLOR.contacts,
                secondaryColor: ENTITY_SECONDARY_COLOR.contacts,
                id: '2',
                path: ROUTE.contacts,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_contacts'),
            },
            {
                displayNameKey: 'Sidebar:horses',
                Icon: HorseIcon,
                color: ENTITY_COLOR.horses,
                secondaryColor: ENTITY_SECONDARY_COLOR.horses,
                id: '6',
                path: ROUTE.horses,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_horses'),
            },
            {
                displayNameKey: 'Sidebar:entries',
                Icon: EntriesIcon,
                color: ENTITY_COLOR.entries,
                secondaryColor: ENTITY_SECONDARY_COLOR.entries,
                id: '3',
                path: ROUTE.entries,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_entries'),
            },
            {
                displayNameKey: 'Sidebar:schedule',
                Icon: ScheduleIcon,
                color: ENTITY_COLOR.schedule,
                secondaryColor: ENTITY_SECONDARY_COLOR.schedule,
                id: '4',
                path: ROUTE.schedule,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_schedule'),
                notificationCount:
                    attentionNeededEvents.length > 0
                        ? attentionNeededEvents.length
                        : undefined,
            },
            {
                displayNameKey: 'Sidebar:invoices',
                hideForCompactVersion: true,
                Icon: InvoiceIcon,
                color: ENTITY_COLOR.invoices,
                secondaryColor: ENTITY_SECONDARY_COLOR.invoices,
                id: '7',
                path: ROUTE.invoices,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_invoices'),
            },
            {
                displayNameKey: 'Sidebar:inventory',
                Icon: InventoryIcon,
                hideForCompactVersion: true,
                color: ENTITY_COLOR.inventory,
                secondaryColor: ENTITY_SECONDARY_COLOR.inventory,
                id: '5',
                path: ROUTE.inventory,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_inventory'),
            },
            {
                displayNameKey: 'Sidebar:shopping_lists',
                hideForCompactVersion: true,
                Icon: CartIcon,
                color: ENTITY_COLOR.shoppingLists,
                secondaryColor: ENTITY_SECONDARY_COLOR.shoppingLists,
                id: '8',
                path: ROUTE.shoppingLists,
                onClick: () =>
                    FirebaseAnalytics.logEvent(
                        'user_switched_to_shopping_lists',
                    ),
            },
            {
                displayNameKey: 'Sidebar:teams',
                Icon: TeamIcon,
                color: ENTITY_COLOR.teams,
                secondaryColor: ENTITY_SECONDARY_COLOR.teams,
                id: '9',
                path: ROUTE.teams,
                onClick: () =>
                    NavigationAnalytics.logUserSwitchedToTeams(
                        FirebaseAnalytics.logEvent,
                    ),
                notificationCount: teamsBadgeCount || undefined,
            },
            {
                displayNameKey: 'Sidebar:settings',
                Icon: SettingsIcon,
                color: ENTITY_COLOR.settings,
                secondaryColor: ENTITY_SECONDARY_COLOR.settings,
                id: '10',
                path: ROUTE.settings,
                onClick: () =>
                    FirebaseAnalytics.logEvent('user_switched_to_settings'),
            },
        ],
        [teamsBadgeCount, attentionNeededEvents.length],
    );

    const visibleLinks = useMemo(
        () => allLinks.filter((link) => !link.hideForCompactVersion),
        [allLinks],
    );

    const hiddenLinks = useMemo(
        () => allLinks.filter((link) => link.hideForCompactVersion),
        [allLinks],
    );

    return (
        <Box
            component="nav"
            sx={{
                borderRight: `2px solid ${COLOR.geyser}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minWidth: 280,
                height: '100%',
                overflowY: 'auto',
                pb: 2,
                pt: 10,
                px: 2,
                width: 280,
                boxShadow: 3,
            }}
        >
            {showCompactVersion ? (
                <SidebarCompactVersion
                    visibleLinks={visibleLinks}
                    hiddenLinks={hiddenLinks}
                />
            ) : (
                <SidebarFullVersion links={allLinks} />
            )}
        </Box>
    );
}

export default Sidebar;
