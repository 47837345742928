import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { CollapsibleContainer, Modal } from '@/components';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props, ListComponentProps } from './types';

function TruncatedListSection<
    T extends ListComponentProps = ListComponentProps,
>(props: Props<T>) {
    const {
        badgeCount,
        ListComponent,
        listComponentProps,
        title,
        buttonText,
        emptyMessage,
        isLoading,
        onButtonClick,
        onAddButtonClick,
        truncateAt = 5,
        entityName,
        showAddButton = false,
        showButtonAlways,
    } = props;

    const { items, testIdPrefix } = listComponentProps;

    const [isListModalOpen, setIsListModalOpen] = useState<boolean>(false);

    const updatedProps = useMemo(() => {
        if (items && items.length > 0 && items.length > truncateAt) {
            return {
                ...listComponentProps,
                items: items.slice(0, truncateAt),
            };
        }

        return { ...listComponentProps };
    }, [items, listComponentProps, truncateAt]);

    const openListModal = useCallback(() => {
        setIsListModalOpen(true);
    }, []);

    const closeListModal = useCallback(() => {
        setIsListModalOpen(false);
    }, []);

    const handleShowMoreClick = useCallback(() => {
        if (onButtonClick) {
            onButtonClick();

            return;
        }

        openListModal();
    }, [onButtonClick, openListModal]);

    const baseTestId = 'TruncatedListSection';
    const testID = testIdPrefix ? `${testIdPrefix}-${baseTestId}` : baseTestId;

    const actionButtons = useMemo(() => {
        let addNewButton;
        let showMoreButton;

        if (showButtonAlways || (items && items.length > truncateAt)) {
            showMoreButton = (
                <Button
                    data-test-id={`${testID}-ShowMore`}
                    className="flex self-center gap-3"
                    onClick={handleShowMoreClick}
                >
                    <Typography fontSize="small" fontWeight={700}>
                        {buttonText}
                        <ArrowForwardIcon fontSize={'small'} />
                    </Typography>
                </Button>
            );
        }

        if (showAddButton) {
            addNewButton = (
                <Button
                    onClick={onAddButtonClick}
                    data-test-id={`${testID}-AddEntity`}
                >
                    <AddIcon fontSize={'small'} />
                    <Typography fontSize="small" fontWeight={700}>
                        {t('Entities:actions:add', { entity: entityName })}
                    </Typography>
                </Button>
            );
        }

        return (
            <Box
                className={`flex ${
                    !addNewButton ? 'justify-end' : 'justify-between'
                }`}
            >
                {addNewButton}
                {showMoreButton}
            </Box>
        );
    }, [
        buttonText,
        entityName,
        handleShowMoreClick,
        showAddButton,
        items,
        onAddButtonClick,
        showButtonAlways,
        testID,
        truncateAt,
    ]);

    return (
        <>
            <CollapsibleContainer
                badgeCount={badgeCount}
                testID={testID}
                title={title}
            >
                {!isLoading && items && items.length > 0 ? (
                    <Box className="flex flex-col gap-4">
                        <ListComponent {...updatedProps} />
                        {actionButtons}
                    </Box>
                ) : isLoading ? (
                    <Skeleton
                        data-test-id={`${testID}-Skeleton`}
                        width="100%"
                        height={76}
                    />
                ) : (
                    <Box className="flex flex-col gap-4">
                        <Typography
                            color={COLOR.paleSky}
                            data-test-id={`${testID}-EmptyMessage`}
                            fontStyle="italic"
                            fontWeight={300}
                        >
                            {emptyMessage}
                        </Typography>
                        {actionButtons}
                    </Box>
                )}
            </CollapsibleContainer>
            {isListModalOpen ? (
                <Modal
                    isOpen={isListModalOpen}
                    styles="max-h-[calc(100%_-_3rem)]"
                    testID={`${testID}-Modal`}
                >
                    <Typography
                        color={COLOR.paleSky}
                        fontSize="1.2rem"
                        fontWeight={700}
                        className="px-8"
                    >
                        {title}
                    </Typography>
                    <Box className="flex flex-col overflow-auto my-6 gap-4 last-child:mb-4 px-8">
                        <ListComponent {...listComponentProps} />
                    </Box>
                    <Box className="flex justify-end border-1 border-t border-t-gray-200 pt-6 mx-8">
                        <Button
                            onClick={closeListModal}
                            variant="contained"
                            data-test-id={`${testID}-Close`}
                        >
                            {t('Actions:close')}
                        </Button>
                    </Box>
                </Modal>
            ) : null}
        </>
    );
}

export default TruncatedListSection;
