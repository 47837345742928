import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Query } from '@nozbe/watermelondb';
import { useFormik } from 'formik';
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import { CountryCode } from 'shared/constants/countries';
import { getInvoiceTaxesList } from 'shared/constants/invoices/INVOICE_TAXES';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import Entry from 'shared/db/services/Entry';
import Invoice from 'shared/db/services/Invoice';
import regionStore from 'shared/stores/userRegion';
import { ContactModel } from 'shared/types/Contacts';
import { EntryModel } from 'shared/types/Entries';
import { HorseModel } from 'shared/types/Horses';
import { InvoicePayload } from 'shared/types/Invoice';
import { checkIfObjectHasEmptyField } from 'shared/utils/data';
import Moment from 'shared/utils/moment';

import { InvoiceIcon } from '@/assets/svg';
import { SelectContactsModal } from '@/components/SelectContactsSection/components';
import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useDatabase,
    useCancelEntityFormAlert,
    useEscapeButton,
    useAccountingProvider,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import AddEditContactModal from '../AddEditContactModal';
import AddEditEntryModal from '../AddEditEntryModal';
import AddEditHorseModal from '../AddEditHorseModal';
import AddEditModalHeader from '../AddEditModalHeader';
import { BackdropLoader } from '../Loaders';
import Modal from '../Modal';
import RemoveEntityAlert from '../RemoveEntityAlert';
import SelectContactsSection from '../SelectContactsSection';
import SelectEntriesSection from '../SelectEntriesSection';
import TooltipSyncButton from '../TooltipButton';

import RemoveContactAlert from './components';
import { AddEditInvoiceFormValues, Props } from './types';

function AddEditInvoiceModal({
    isEditMode,
    close,
    onSave,
    closeOnSubmit = false,
    invoice,
    onRemove,
    entry,
    contact,
}: Props) {
    const { t } = useTranslation();
    const { isSyncInProgress } = useDBSyncContext();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();
    const { getDatabase } = useDatabase();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();
    const accountingProvider = useAccountingProvider();

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const invoiceService = useMemo(
        () =>
            new Invoice({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const entriesService = useMemo(
        () =>
            new Entry({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [database, ImagesService],
    );

    const invoiceTaxesList = useMemo(() => getInvoiceTaxesList(t), [t]);

    const [isCreating, setIsCreating] = useState(false);
    const [isRemoveContactAlertOpen, setIsRemoveContactAlertOpen] =
        useState(false);
    const [isRemoveInvoiceAlertOpen, setIsRemoveInvoiceAlertOpen] =
        useState(false);
    const [isSelectEntryContactModalOpen, setIsSelectEntryContactModalOpen] =
        useState(false);
    const [preSelectedContact, setPreSelectedContact] =
        useState<ContactModel | null>(null);
    const [preSelectedEntryHorse, setPreSelectedEntryHorse] =
        useState<HorseModel | null>(null);

    const openRemoveContactAlert = useCallback(
        () => setIsRemoveContactAlertOpen(true),
        [],
    );

    const closeRemoveContactAlert = useCallback(
        () => setIsRemoveContactAlertOpen(false),
        [],
    );

    const openRemoveInvoiceAlert = useCallback(
        () => setIsRemoveInvoiceAlertOpen(true),
        [],
    );

    const closeRemoveInvoiceAlert = useCallback(
        () => setIsRemoveInvoiceAlertOpen(false),
        [],
    );

    const openSelectEntryContactModal = useCallback(
        () => setIsSelectEntryContactModalOpen(true),
        [],
    );

    const selectEntryContact = useCallback(async () => {
        const contactsCount =
            await preSelectedEntryHorse?.contacts.fetchCount();
        if (contactsCount && contactsCount > 1) {
            openSelectEntryContactModal();
        } else if (preSelectedEntryHorse) {
            const [contactToAssign] =
                await preSelectedEntryHorse.contacts.fetch();
            setPreSelectedContact(contactToAssign);
        }
    }, [openSelectEntryContactModal, preSelectedEntryHorse]);

    const closeSelectEntryContactModal = useCallback(
        () => setIsSelectEntryContactModalOpen(false),
        [],
    );

    const applyTaxByDefault = useMemo(() => {
        const applyTax = getItemFromLocalStorage<boolean>(
            WEB_STORAGE_KEYS.apply_tax_to_invoice,
        );

        return applyTax;
    }, []);

    const [initialValues, setInitialValues] =
        useState<AddEditInvoiceFormValues>(() => ({
            applyTax: applyTaxByDefault ? 'included' : 'excluded',
            contact: null,
            dueDate: Moment().add(1, 'M'),
            attachReport: false,
            entries: [],
        }));

    const [entriesToSelectQuery, setEntriesToSelectQuery] =
        useState<Query<EntryModel> | null>(null);

    const prepareInitialValues = useCallback(async () => {
        if (isEditMode && invoice) {
            try {
                const entries = await invoice.entries.fetch();
                const contact = (await invoice.contacts.fetch())[0];

                setInitialValues({
                    applyTax: invoice.applyTaxes ? 'included' : 'excluded',
                    contact,
                    entries,
                    attachReport: invoice.attachReports,
                    dueDate: Moment(invoice.dueDateTime),
                });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        } else if (entry && preSelectedContact) {
            setInitialValues((values) => ({
                ...values,
                entries: [entry],
                contact: preSelectedContact,
            }));
        } else if (contact) {
            setInitialValues((values) => ({
                ...values,
                contact,
            }));
        }
    }, [contact, entry, invoice, isEditMode, preSelectedContact, t]);

    const getPreSelectedEntryHorse = useCallback(async () => {
        if (!isEditMode && entry) {
            const horse = await entry.horse.fetch();

            if (horse) {
                setPreSelectedEntryHorse(horse);
            } else {
                openSelectEntryContactModal();
            }
        }
    }, [entry, isEditMode, openSelectEntryContactModal]);

    useEffect(() => {
        getPreSelectedEntryHorse();
    }, [getPreSelectedEntryHorse]);

    useEffect(() => {
        selectEntryContact();
    }, [selectEntryContact]);

    useEffect(() => {
        prepareInitialValues();
    }, [isEditMode, prepareInitialValues]);

    const addUpdateInvoice = useCallback(
        async (values: AddEditInvoiceFormValues) => {
            try {
                setIsCreating(true);

                if (!userProfileData) {
                    throw new Error();
                }

                const userId = userProfileData.id || '';

                const {
                    applyTax,
                    dueDate,
                    contact,
                    entries,
                    attachReport,
                    selectedProducts,
                    taxRate,
                } = values;

                const selectedEntriesIds = entries.map((entry) => ({
                    id: entry.id,
                }));

                const payload: InvoicePayload = {
                    applyTaxes: applyTax === 'included',
                    dueDateTime: dueDate.toISOString(),
                    contactId: contact?.id ?? '',
                    provider: accountingProvider || 'internal',
                    sentToContact: false,
                    status: INVOICE_STATUS.draft,
                    selectedEntriesIds,
                    selectedProducts: selectedProducts || [],
                    attachReports: attachReport,
                    taxRate,
                };

                let message;

                if (!isEditMode) {
                    const createdInvoice = await invoiceService.add(
                        payload,
                        userId,
                    );

                    InvoicesAnalytics.logUserCreatedInvoice(
                        FirebaseAnalytics.logEvent,
                        {
                            invoice: createdInvoice,
                            isOwner: userOrganisation?.owner ?? true,
                        },
                    );

                    if (onSave) {
                        onSave(createdInvoice);
                    }
                    message = 'Invoice:created';
                } else {
                    if (!invoice) {
                        throw new Error();
                    }

                    const previousAttachReports = invoice.attachReports;

                    const updatedInvoice = await invoiceService.update(
                        invoice.id,
                        payload,
                    );

                    InvoicesAnalytics.logUserUpdatedInvoice(
                        FirebaseAnalytics.logEvent,
                        {
                            invoice: updatedInvoice,
                            previousAttachReports,
                        },
                    );

                    message = 'Invoice:updated';
                }

                Snackbar.showToastNotification({
                    message: t(message),
                });

                if (isEditMode || closeOnSubmit) {
                    close();
                }
            } catch (error) {
                setIsCreating(false);
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            userProfileData,
            accountingProvider,
            isEditMode,
            t,
            closeOnSubmit,
            invoiceService,
            userOrganisation?.owner,
            onSave,
            invoice,
            close,
        ],
    );

    const handleSubmit = useCallback(
        async (values: AddEditInvoiceFormValues) => {
            const entries = values.entries;
            let hasProceduresAssigned = true;
            let hasHorseAssigned = true;

            const hasEmptyFields = checkIfObjectHasEmptyField(values, [
                'attachReport',
            ]);

            if (!isEditMode) {
                if (hasEmptyFields) {
                    Snackbar.showToastNotification({
                        message: t('Invoice:error:required_fields_missing'),
                        options: {
                            variant: 'error',
                        },
                    });
                    return;
                }

                for (const entry of entries) {
                    if (!entry.horseId) {
                        hasHorseAssigned = false;
                        break;
                    }

                    const procedures = await entry.entryProcedures.fetchCount();
                    if (!procedures) {
                        hasProceduresAssigned = false;
                        break;
                    }
                }
            }

            if (!hasHorseAssigned) {
                Snackbar.showToastNotification({
                    message: t('Invoice:alert:missing_horse_message'),
                    options: {
                        variant: 'info',
                    },
                });
                return;
            }

            if (!hasProceduresAssigned) {
                Snackbar.showToastNotification({
                    message: t('Invoice:alert:no_procedures_message'),
                    options: {
                        variant: 'info',
                    },
                });
                return;
            }
            addUpdateInvoice(values);
        },
        [addUpdateInvoice, isEditMode, t],
    );

    const form = useFormik<AddEditInvoiceFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: null,
    });

    const getEntriesToSelectQuery = useCallback(async () => {
        if (form.values.contact) {
            const horses = await form.values.contact.horses.fetch();
            setEntriesToSelectQuery(
                entriesService.getEntriesForHorsesQuery(horses),
            );
        }
    }, [form.values.contact, entriesService]);

    useEffect(() => {
        getEntriesToSelectQuery();
    }, [getEntriesToSelectQuery]);

    const handleRemoveInvoice = useCallback(async () => {
        try {
            if (!invoice) {
                throw new Error();
            }

            const invoiceId = invoice.id;

            await invoiceService.deleteByID(invoiceId);

            InvoicesAnalytics.logUserDeletedInvoice(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:invoice'),
                }),
            });

            close();

            if (onRemove) {
                onRemove();
            }
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [close, invoice, invoiceService, onRemove, t]);

    const onConfirmRemoveContact = useCallback(() => {
        form.setFieldValue('entries', []);
        form.setFieldValue('contact', null);
    }, [form]);

    const onRemoveContact = useCallback(() => {
        if (!form.values.entries.length) {
            form.setFieldValue('contact', null);
        } else {
            openRemoveContactAlert();
        }
    }, [form, openRemoveContactAlert]);

    const onSelectContact = useCallback(
        async (contact: ContactModel) => {
            if (form.values.contact?.id !== contact.id) {
                const entriesToKeep: EntryModel[] = [];

                for (const entry of form.values.entries) {
                    const entryHorse = await entry.horse.fetch();

                    if (!entryHorse) {
                        entriesToKeep.push(entry);
                    }
                }

                form.setFieldValue('entries', entriesToKeep);
            }

            form.setFieldValue('contact', contact);
        },
        [form],
    );

    const onDateChanged = useCallback(
        (value) => form.setFieldValue('dueDate', value),
        [form],
    );

    const handleApplyTaxFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            form.setFieldValue('applyTax', value);

            storeItemInLocalStorage(
                WEB_STORAGE_KEYS.apply_tax_to_invoice,
                value === 'included',
            );
        },
        [form],
    );

    const renderDateTimePickerInput = useCallback(
        (params) => (
            <TextField
                {...params}
                fullWidth
                error={!!form.errors['dueDate'] && !!form.touched['dueDate']}
                helperText={
                    form.touched['dueDate'] ? form.errors['dueDate'] : ''
                }
                onBlur={form.handleBlur}
                name={'dueDate'}
                id={'dueDate'}
                data-test-id={'AddEditInvoiceForm-DueDate'}
                aria-readonly={false}
            />
        ),
        [form.errors, form.handleBlur, form.touched],
    );

    const entryValidation = useCallback(
        (entry: EntryModel) => !entry.invoiceId,
        [],
    );

    const onRemoveEntry = useCallback(
        (entry: EntryModel) => {
            const previousEntries = form.values.entries;
            const newEntries = previousEntries.filter((e) => e.id !== entry.id);
            form.setFieldValue('entries', newEntries);
        },
        [form],
    );

    const onSelectEntries = useCallback(
        (entries: EntryModel[]) => {
            form.setFieldValue('entries', entries);
        },
        [form],
    );

    const handleSelectedEntryContact = useCallback(
        (contact: ContactModel) => setPreSelectedContact(contact),
        [],
    );

    const cancelEntryContactSelection = useCallback(() => {
        closeSelectEntryContactModal();
        close();
    }, [close, closeSelectEntryContactModal]);

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel: form.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    const isUSRegion = regionStore.getRegion() === CountryCode.unitedStates;

    const dateInputFormat = isUSRegion
        ? 'MM-DD-YYYY'
        : `dddd, MMMM DD [${t(
              'AddEntryForm:labels:date_input_placeholder',
          )}] YYYY`;

    return (
        <Modal
            isOpen
            center
            disableRestoreFocus
            testID="AddEditInvoiceModal"
            styles="relative py-0 pb-9 w-[768px]"
        >
            <BackdropLoader
                isLoading={isSyncInProgress && isCreating}
                sx={{
                    position: 'absolute',
                    borderRadius: '1rem',
                    backgroundColor: COLOR.white60,
                    color: COLOR.ebonyClay,
                }}
                loadingText={t(
                    isEditMode
                        ? 'Invoice:edit_loading_text'
                        : 'Invoice:add_loading_text',
                )}
            />
            <AddEditModalHeader
                backgroundColor={ENTITY_COLOR.invoices}
                cancel={handleFormCancelButtonClick}
                save={form.handleSubmit}
                testIdPrefix="AddEditInvoiceForm"
                title={t(
                    isEditMode ? 'Invoice:edit:view_title' : 'Invoice:new',
                )}
                teamName={
                    userOrganisations && userOrganisations?.length > 1
                        ? userOrganisation?.name || ''
                        : undefined
                }
                avatarUrl={
                    userOrganisations && userOrganisations?.length > 1
                        ? teamAvatar?.imageURL || ''
                        : undefined
                }
                icon={<InvoiceIcon color={COLOR.white} />}
            />
            <Box className="px-12" sx={{ pt: 7 }}>
                <Box className="flex flex-col gap-5">
                    <SelectContactsSection
                        disabled={isEditMode}
                        onRemoveContact={onRemoveContact}
                        onSelectContacts={onSelectContact}
                        selectedContacts={
                            form.values.contact ? [form.values.contact] : []
                        }
                        CreateContactModal={AddEditContactModal}
                        testIdPrefix="AddEditInvoiceForm"
                        multiselect={false}
                        allowHorsesSelection
                        CreateHorseModal={AddEditHorseModal}
                        buttonsPosition="bottom"
                    />
                    <Box className="flex gap-3">
                        <TextField
                            select
                            id="applyTax"
                            label={t('Invoice:labels:applyTax')}
                            error={
                                !!form.errors.applyTax &&
                                !!form.touched.applyTax
                            }
                            helperText={
                                form.touched.applyTax
                                    ? form.errors.applyTax
                                    : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={handleApplyTaxFieldChange}
                            name="applyTax"
                            value={form.values.applyTax}
                            data-test-id="AddEditInvoiceForm-ApplyTax"
                            sx={{ flex: 1 }}
                            disabled={isEditMode}
                        >
                            {invoiceTaxesList.map((invoiceTax) => (
                                <MenuItem
                                    key={invoiceTax.value}
                                    value={invoiceTax.value}
                                    data-test-id={`AddEditInvoiceForm-Tax${invoiceTax.name}`}
                                >
                                    {invoiceTax.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Box sx={{ flex: 1 }}>
                            <DesktopDatePicker
                                label={t('Invoice:labels:dueDate')}
                                value={form.values.dueDate}
                                onChange={onDateChanged}
                                inputFormat={dateInputFormat}
                                renderInput={renderDateTimePickerInput}
                                onClose={() => form.validateField('dueDate')}
                                disableMaskedInput
                            />
                        </Box>
                    </Box>
                    <FormControlLabel
                        checked={form.values.attachReport}
                        className="min-w-0 mb-5"
                        control={
                            <Switch
                                data-test-id="AddEditInvoiceForm-AttachReportInput"
                                id="attachReport"
                                name="attachReport"
                                value={form.values.attachReport}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        }
                        label={t('Invoice:labels:attachReport')}
                    />
                    <SelectEntriesSection
                        onRemoveEntry={onRemoveEntry}
                        onSelectEntries={onSelectEntries}
                        disabled={form.values.contact === null || !!isEditMode}
                        testIdPrefix="AddEditInvoiceForm"
                        CreateEntryModal={AddEditEntryModal}
                        selectedEntries={form.values.entries}
                        selectCondition={entryValidation}
                        conditionWarning={t('Entry:invoiced_entry_warning')}
                        entriesToSelect={entriesToSelectQuery || undefined}
                        entriesAllowedHorses={form.values.contact?.horses}
                    />
                </Box>
            </Box>
            <RemoveContactAlert
                isOpen={isRemoveContactAlertOpen}
                onClose={closeRemoveContactAlert}
                onConfirm={onConfirmRemoveContact}
            />
            {isEditMode && (
                <>
                    <TooltipSyncButton
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        showTooltip={isSyncInProgress}
                        onClick={openRemoveInvoiceAlert}
                        disabled={isSyncInProgress}
                        testID="AddInvoiceForm-RemoveButton"
                        sx={{ mt: 3, alignSelf: 'center' }}
                        stylesAt="container"
                        color="error"
                        startIcon={<DeleteOutlineRoundedIcon />}
                    >
                        {t('Invoice:button:remove')}
                    </TooltipSyncButton>
                    <RemoveEntityAlert
                        dialogText={t('Invoice:delete_invoice_confirmation')}
                        isOpen={isRemoveInvoiceAlertOpen}
                        onClose={closeRemoveInvoiceAlert}
                        onRemove={handleRemoveInvoice}
                        testID="RemoveInvoiceAlert"
                    />
                </>
            )}
            {isSelectEntryContactModalOpen ? (
                <SelectContactsModal
                    close={closeSelectEntryContactModal}
                    cancel={cancelEntryContactSelection}
                    isOpen={isSelectEntryContactModalOpen}
                    onSelectContacts={handleSelectedEntryContact}
                    selectedContacts={[]}
                    multiSelect={false}
                    contactsToSelect={preSelectedEntryHorse?.contacts}
                />
            ) : null}
            {showCancelAlert ? renderCancelAlert() : null}
        </Modal>
    );
}

export default AddEditInvoiceModal;
