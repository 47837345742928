export type Props = {
    close: VoidFunction;
    firstName: string;
    isSyncInProgress: boolean;
};

export enum ONBOARDING_STEP {
    ANIMATION,
    CAROUSEL,
    QUESTIONS,
}
