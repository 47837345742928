import { Box, Button, Typography } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { AuthErrorCodes, UserInfo } from 'firebase/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppAnalytics } from 'shared/analytics/app';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';
import { LOCALE } from 'shared/constants/localization/types';
import AppService from 'shared/db/services/App';

import bgImage from '@/assets/images/bg-image.webp';
import XeroAuthButtonIcon from '@/assets/svg/XeroAuthButtonIcon';
import { LinkProviderAccountDialog, TeamInvitationBadge } from '@/components';
import { CUSTOM_ERROR } from '@/constants/customErrors';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useOrganisationIncomingInvitationContext } from '@/context/OrganisationIncomingInvitationContext';
import { useDatabase } from '@/hooks';
import { AuthPageLayout } from '@/layouts';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { getLocaleFromLocalStorage } from '@/services/localization';
import Logger from '@/services/logger';
import { ServerAuthAPI } from '@/services/networking/auth';
import { Snackbar } from '@/services/toastNotifications';
import {
    getLastUserEmailFromLocalStorage,
    saveLastUserEmailInLocalStorage,
} from '@/services/webStorage/localStorage/user';
import { COLOR } from '@/theme/colors';
import { SignInFormValues } from '@/types/auth';
import { AuthProvider } from '@/types/firebase';

import ProviderAccountRegistrationDialog from '../../components/ProviderAccountRegistrationDialog';

import { SignInForm } from './components';

const APPLE_APP_STORE_URL = 'https://apps.apple.com/app/id1459492568';

function SignInPage() {
    const { t } = useTranslation();
    const { invitationDetails } = useOrganisationIncomingInvitationContext();

    const { getDatabase } = useDatabase();

    const { authenticateUser, invalidateUserAuth, setShowSessionExpiredModal } =
        useAuthContext();

    const { setIsInitialSyncInProgress } = useDBSyncContext();
    const { xeroAuthEnabled } = useFeatureFlagsContext();

    const [showProviderSignUpDialog, setShowProviderSignUpDialog] =
        useState(false);
    const [providerData, setProviderData] = useState<UserInfo | null>(null);
    const [showProviderLinkAlert, setShowProviderLinkAlert] = useState(false);
    const [language, setLanguage] = useState<LOCALE>(
        getLocaleFromLocalStorage() || LOCALE.en_AU,
    );
    const [emailToLink, setEmailToLink] = useState<string | undefined | null>(
        '',
    );
    const [providerErrorCode, setProviderErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [providerErrorMessage, setProviderErrorMessage] = useState<
        string | null
    >(null);

    const handleRemoveAccountOnUnload = useCallback(
        async () => await FirebaseAuthAPI.removeAccount(),
        [],
    );

    const handleProviderSignIn = useCallback(
        async (
            providerName: AuthProvider,
            values: SignInFormValues,
            catchError: (error) => boolean,
            skipProviderAuth = false,
        ) => {
            try {
                let { email } = values;
                const { language } = values;
                const database = getDatabase();
                const appService = new AppService({
                    database,
                    logInfo: Logger.logInfo,
                });

                if (!skipProviderAuth) {
                    const [providerData] = (
                        await FirebaseAuthAPI.signInWithProvider(
                            providerName,
                            email,
                        )
                    ).user.providerData;

                    if (providerData?.email) {
                        email = providerData.email;
                    }
                }

                await ServerAuthAPI.signInWithFirebase({
                    email,
                    language,
                });

                const firebaseToken = await FirebaseAuthAPI.getAuthToken();

                if (!firebaseToken) {
                    throw new Error(CUSTOM_ERROR.no_firebase_token_found);
                }

                const prevUserEmail = getLastUserEmailFromLocalStorage();

                if (prevUserEmail !== email) {
                    await appService.clearDatabase();
                }

                authenticateUser({
                    firebaseToken,
                    rememberUser: true,
                });

                saveLastUserEmailInLocalStorage(email);

                AppAnalytics.logUserLoggedIn(FirebaseAnalytics.logEvent);

                setIsInitialSyncInProgress(true);
            } catch (error) {
                const isErrorCaugth = catchError(error);

                if (
                    error?.response?.data?.errors?.message ===
                    CUSTOM_ERROR.invalid_credentials
                ) {
                    setProviderErrorCode(error.message);
                    await FirebaseAuthAPI.signOut();
                    invalidateUserAuth();
                    setShowSessionExpiredModal(false);
                } else if (!isErrorCaugth) {
                    await FirebaseAuthAPI.signOut();
                    invalidateUserAuth();
                    setShowSessionExpiredModal(false);
                }
            }
        },
        [
            authenticateUser,
            getDatabase,
            invalidateUserAuth,
            setIsInitialSyncInProgress,
            setShowSessionExpiredModal,
        ],
    );

    const catchAppleSignInError = useCallback(
        (error) => {
            if (
                error?.response?.data?.errors?.message ===
                CUSTOM_ERROR.invalid_credentials
            ) {
                setProviderErrorMessage(
                    t('SignIn:provider:error:unregistered_apple_web', {
                        app_store: APPLE_APP_STORE_URL,
                    }),
                );

                return true;
            } else if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
                setProviderErrorMessage(
                    t('SignIn:provider:error:account_exists_apple_web', {
                        app_store: APPLE_APP_STORE_URL,
                    }),
                );

                return true;
            } else if (
                error.code === AuthErrorCodes.EXPIRED_POPUP_REQUEST ||
                error.code === AuthErrorCodes.USER_CANCELLED ||
                error.code === AuthErrorCodes.REDIRECT_CANCELLED_BY_USER ||
                error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER
            ) {
                Snackbar.showToastNotification({
                    message: t('SignIn:provider:error:apple_cancelled'),
                    options: {
                        variant: 'info',
                    },
                });

                return true;
            } else {
                return false;
            }
        },
        [t],
    );

    const handleAppleSignIn = useCallback(
        async (values: SignInFormValues) => {
            handleProviderSignIn(
                AuthProvider.Apple,
                values,
                catchAppleSignInError,
            );
        },
        [catchAppleSignInError, handleProviderSignIn],
    );

    const catchXeroSignInError = useCallback(
        (error) => {
            if (
                error?.response?.data?.errors?.message ===
                CUSTOM_ERROR.invalid_credentials
            ) {
                setProviderErrorMessage(
                    t('SignIn:provider:error:unregistered_xero'),
                );

                return true;
            } else if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
                setEmailToLink(error?.customData?.email);
                setShowProviderLinkAlert(true);

                return true;
            } else if (
                error.code === AuthErrorCodes.EXPIRED_POPUP_REQUEST ||
                error.code === AuthErrorCodes.USER_CANCELLED ||
                error.code === AuthErrorCodes.REDIRECT_CANCELLED_BY_USER ||
                error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER
            ) {
                Snackbar.showToastNotification({
                    message: t('SignIn:provider:error:cancelled'),
                    options: {
                        variant: 'info',
                    },
                });

                return true;
            } else {
                return false;
            }
        },
        [t],
    );

    const handleXeroSignIn = useCallback(
        async (values: SignInFormValues, skipXeroAuth = false) => {
            handleProviderSignIn(
                AuthProvider.Xero,
                values,
                catchXeroSignInError,
                skipXeroAuth,
            );
        },
        [catchXeroSignInError, handleProviderSignIn],
    );

    const handleXeroSignUp = useCallback(async () => {
        try {
            const [providerData] = (
                await FirebaseAuthAPI.signInWithProvider(AuthProvider.Xero)
            ).user.providerData;
            setProviderData(providerData);
            const status = await ServerAuthAPI.checkExistingAccount();

            if (status === HttpStatusCode.Conflict) {
                setProviderErrorMessage(
                    t('SignUp:provider:xero:errors:account_exists'),
                );
            } else {
                setShowProviderSignUpDialog(true);
                window.addEventListener(
                    'beforeunload',
                    handleRemoveAccountOnUnload,
                );
            }
        } catch (error) {
            if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
                setEmailToLink(error?.customData?.email);
                setShowProviderLinkAlert(true);
            } else if (
                error.code === AuthErrorCodes.EXPIRED_POPUP_REQUEST ||
                error.code === AuthErrorCodes.USER_CANCELLED ||
                error.code === AuthErrorCodes.REDIRECT_CANCELLED_BY_USER ||
                error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER
            ) {
                Snackbar.showToastNotification({
                    message: t('SignIn:provider:error:cancelled'),
                    options: {
                        variant: 'info',
                    },
                });
            }
        }
    }, [t, handleRemoveAccountOnUnload]);

    const cleanRemoveAccountEvent = useCallback(
        () =>
            window.removeEventListener(
                'beforeunload',
                handleRemoveAccountOnUnload,
            ),
        [handleRemoveAccountOnUnload],
    );

    const cancelProviderSignUp = useCallback(() => {
        setShowProviderSignUpDialog(false);
        FirebaseAuthAPI.removeAccount();
        cleanRemoveAccountEvent();
    }, [cleanRemoveAccountEvent]);

    const cancelProviderLink = useCallback(
        () => setShowProviderLinkAlert(false),
        [],
    );

    useEffect(() => {
        if (!showProviderSignUpDialog) {
            cleanRemoveAccountEvent();
        }
    }, [cleanRemoveAccountEvent, showProviderSignUpDialog]);

    const equinetLogo = useMemo(
        () => (
            <Box
                alt="Equinet Logo"
                component="img"
                src={equinetLogoImage}
                sx={{
                    height: 88,
                    width: 334,
                }}
            />
        ),
        [],
    );

    return (
        <>
            <AuthPageLayout
                backgroundImage={bgImage}
                navbarContent={
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            mr: 4,
                            gap: 2,
                        }}
                    >
                        <Typography sx={{ color: COLOR.white, fontSize: 14 }}>
                            {t('SignIn:text:no_account_yet')}
                        </Typography>
                        <Link
                            data-test-id="SignInPage-CreateAccountLink"
                            to={ROUTE.signUp}
                        >
                            <Button
                                color="white"
                                sx={{
                                    borderColor: COLOR.white,
                                    fontWeight: '600',
                                }}
                                variant="outlined"
                            >
                                {t('SignIn:button:create_account')}
                            </Button>
                        </Link>
                        {xeroAuthEnabled ? (
                            <>
                                <Typography
                                    sx={{ color: COLOR.white, fontSize: 14 }}
                                >
                                    {t('SignIn:info:or')}
                                </Typography>
                                <Button
                                    color="white"
                                    sx={{
                                        borderColor: COLOR.white,
                                        fontWeight: '600',
                                    }}
                                    startIcon={
                                        <XeroAuthButtonIcon
                                            height={25}
                                            width={24}
                                        />
                                    }
                                    variant="outlined"
                                    onClick={handleXeroSignUp}
                                >
                                    {t('SignUp:button:xero')}
                                </Button>
                            </>
                        ) : null}
                    </Box>
                }
                rightColumnContent={
                    <Box
                        sx={{
                            mt: '114px',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                ml: { xs: 4, md: 10 },
                                mr: { xs: 4, md: 0 },
                            }}
                        >
                            {invitationDetails ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 3,
                                        alignItems: 'center',
                                    }}
                                >
                                    {equinetLogo}
                                    <TeamInvitationBadge
                                        name={invitationDetails.teamName}
                                        owner={invitationDetails.teamOwner}
                                        imageURL={
                                            invitationDetails.teamImageUrl
                                        }
                                    />
                                </Box>
                            ) : (
                                equinetLogo
                            )}

                            <Typography
                                component="h1"
                                sx={{ fontWeight: 700, mb: 1, mt: 4 }}
                                variant="h5"
                                data-test-id="SignInPage-SignIn-Header"
                            >
                                {t('SignIn:header:sign_in_to_equinet')}
                            </Typography>
                            <Typography
                                component="p"
                                sx={{ color: 'text.secondary', mb: 1 }}
                                variant="body2"
                            >
                                {t('SignIn:header:app_availability_text')}
                            </Typography>
                        </Box>
                        <SignInForm
                            handleXeroSignIn={handleXeroSignIn}
                            setLanguage={setLanguage}
                            providerErrorCode={providerErrorCode}
                            providerErrorMessage={providerErrorMessage}
                            handleAppleSignIn={handleAppleSignIn}
                        />
                    </Box>
                }
            />
            {showProviderSignUpDialog && providerData ? (
                <ProviderAccountRegistrationDialog
                    providerData={providerData}
                    cancelProviderSignUp={cancelProviderSignUp}
                    language={language}
                    onSubmit={cleanRemoveAccountEvent}
                />
            ) : null}
            {showProviderLinkAlert ? (
                <LinkProviderAccountDialog
                    handleXeroSignIn={handleXeroSignIn}
                    cancel={cancelProviderLink}
                    language={language}
                    email={emailToLink}
                />
            ) : null}
        </>
    );
}

export default SignInPage;
