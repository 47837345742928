import { Add, CancelRounded, TuneRounded } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { HorsesAnalytics } from 'shared/analytics/horses';
import { HORSES_FILTER } from 'shared/constants/horses/filters';
import { PAGE_LIMIT } from 'shared/constants/pagination';
import Database from 'shared/db/services/Database.web';
import HorsesService from 'shared/db/services/Horse';
import { HorseModel } from 'shared/types/Horses';
import { HorsesFiltersObject } from 'shared/types/horsesFilters';
import { SortObject } from 'shared/types/sort';

import {
    AddEditContactModal,
    AddEditHorseModal,
    Button,
    Pagination,
    SearchInput,
    TableSkeleton,
} from '@/components';
import { APP_CONTENT_ID } from '@/constants/documentIds';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useHorsesFiltersContext } from '@/context/HorsesFiltersContext';
import { useImagesContext } from '@/context/ImagesContext';
import { calculatePaginatedRows } from '@/helpers/pagination';
import { withSyncContext } from '@/hoc';
import {
    useDatabase,
    useDebouncedValue,
    useScrollToDocumentTopOnValueChange,
    useUnmount,
} from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { FiltersPanel, HorsesTable } from './components';
import { Props } from './types';

const pageStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const tableStyles: SxProps<Theme> = {
    mr: 3,
    flexGrow: 1,
};

function HorsesPage({ horses, totalRecords }: Props) {
    const { isInitialSyncInProgress } = useDBSyncContext();
    const {
        filtersState,
        searchState,
        setSearchState,
        sortState,
        setSortState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        setFiltersState,
        setIsFiltersPanelVisible,
    } = useHorsesFiltersContext();
    const { ImagesService } = useImagesContext();

    const { getDatabase } = useDatabase();

    const navigate = useNavigate();

    const [filteredHorses, setFilteredHorses] = useState<HorseModel[]>([]);
    const [isAddHorseModalOpen, setIsAddHorseModalOpen] = useState(false);
    const [isHorsesDataLoading, setIsHorsesDataLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState<number>(
        totalRecords ? totalRecords : 0,
    );

    const handlePageChange = useCallback(
        (_, page: number) => setPage(page),
        [],
    );

    useScrollToDocumentTopOnValueChange(APP_CONTENT_ID, page);

    const openAddHorseModal = useCallback(() => {
        setIsAddHorseModalOpen(true);
    }, []);

    const closeAddHorseModal = useCallback(() => {
        setIsAddHorseModalOpen(false);
    }, []);

    const toggleFiltersPanel = useCallback(() => {
        setIsFiltersPanelVisible((prev) => !prev);
    }, [setIsFiltersPanelVisible]);

    const database = useMemo(() => {
        return getDatabase();
    }, [getDatabase]);

    const handleSearchInputChange = useCallback(
        (value: string) => setSearchState(value),
        [setSearchState],
    );

    const clearSearchText = useCallback(
        () => setSearchState(''),
        [setSearchState],
    );

    const fetchHorses = useCallback(
        async (
            searchText: string,
            filters: HorsesFiltersObject,
            sort: SortObject | null,
        ) => {
            setIsHorsesDataLoading(true);

            try {
                const horsesService = new HorsesService({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const filteredHorsesCount =
                    await horsesService.getFilteredHorsesCount(
                        searchText,
                        filters,
                    );

                setRowsCount(filteredHorsesCount);

                let paginatedHorses: HorseModel[] = [];
                if (filteredHorsesCount > 0) {
                    const horses = await horsesService.getFilteredHorses(
                        searchText,
                        sort,
                        filters,
                    );
                    paginatedHorses = calculatePaginatedRows(page, horses);
                }
                setFilteredHorses(paginatedHorses);
            } finally {
                setIsHorsesDataLoading(false);
            }
        },
        [ImagesService, database, page],
    );

    const handleFiltersChange = useCallback(
        (filterType: HORSES_FILTER) => (value: string[]) => {
            setFiltersState((prev) => ({ ...prev, [filterType]: value }));
        },
        [setFiltersState],
    );

    const filtersStateDebounced = useDebouncedValue(filtersState, 300);
    const sortStateDebounced = useDebouncedValue(sortState, 300);
    const isAnyFilterActiveDebounced = useDebouncedValue(
        isAnyFilterActive,
        300,
    );
    const searchTextDebounced = useDebouncedValue(searchState);

    useEffect(() => {
        if (searchTextDebounced) {
            HorsesAnalytics.logUserUsedHorsesSearchBar(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [searchTextDebounced]);

    useEffect(() => setPage(1), [filtersStateDebounced, searchTextDebounced]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchHorses(
                searchTextDebounced,
                filtersStateDebounced,
                sortStateDebounced,
            );
        }
    }, [
        fetchHorses,
        filtersStateDebounced,
        isInitialSyncInProgress,
        searchTextDebounced,
        sortStateDebounced,
    ]);

    useUnmount(() => {
        if (!isAnyFilterActive && isFiltersPanelVisible) {
            setIsFiltersPanelVisible(false);
        }
    });

    const refetchHorses = useCallback(() => {
        fetchHorses(
            searchTextDebounced,
            filtersStateDebounced,
            sortStateDebounced,
        );
    }, [
        fetchHorses,
        filtersStateDebounced,
        searchTextDebounced,
        sortStateDebounced,
    ]);

    const navigateToCreatedHorse = useCallback(
        (horse: HorseModel) => {
            const route = getRoutePath(ROUTE.horse, { id: horse.id });
            navigate(route);
        },
        [navigate],
    );

    const emptyStateMessage =
        filteredHorses.length === 0 && horses && horses.length > 0
            ? t('HorsesList:no_horse_found')
            : t('HorsesList:no_horses_have_been_added');

    const showLoadingState =
        (!horses?.length && isHorsesDataLoading) || isInitialSyncInProgress;

    return (
        <Box sx={pageStyles}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <SearchInput
                            onChange={handleSearchInputChange}
                            onClear={clearSearchText}
                            testId="HorsesPage-SearchInput"
                            value={searchState}
                        />
                        <Button
                            caption={t('Actions:filter')}
                            color={isAnyFilterActive ? 'primary' : 'inherit'}
                            onClick={toggleFiltersPanel}
                            startIcon={<TuneRounded />}
                            sx={{
                                backgroundColor: isAnyFilterActive
                                    ? COLOR.deepCerulean08
                                    : undefined,
                                ml: 3,
                            }}
                            testID="HorsesPage-FilterButton"
                        />
                        {isAnyFilterActive ? (
                            <Button
                                caption={t('Actions:clearFilters')}
                                onClick={resetFilters}
                                startIcon={
                                    <CancelRounded htmlColor={COLOR.paleSky} />
                                }
                                sx={{
                                    color: COLOR.paleSky,
                                    fontWeight: 400,
                                    ml: 3,
                                }}
                                testID="HorsesPage-ClearFiltersButton"
                            />
                        ) : null}
                    </Box>
                    <Button
                        caption={t('HorsesList:button:add_horse')}
                        onClick={openAddHorseModal}
                        startIcon={<Add />}
                        testID="HorsesPage-AddHorseButton"
                    />
                </Box>
                {isFiltersPanelVisible ? (
                    <Box sx={{ mb: 4 }}>
                        <FiltersPanel
                            filtersState={filtersState}
                            onFiltersChange={handleFiltersChange}
                        />
                    </Box>
                ) : null}
            </Box>
            <Box sx={tableStyles}>
                <Box sx={{ mr: 3 }}>
                    {showLoadingState ? (
                        <TableSkeleton />
                    ) : (
                        <HorsesTable
                            emptyStateMessage={emptyStateMessage}
                            horses={
                                searchTextDebounced ||
                                isAnyFilterActiveDebounced ||
                                sortStateDebounced ||
                                page > 1
                                    ? filteredHorses
                                    : horses ?? []
                            }
                            refetchHorses={refetchHorses}
                            sortState={sortState}
                            sortChange={setSortState}
                        />
                    )}
                </Box>
            </Box>
            {rowsCount > 0 && (
                <Pagination
                    testID="HorsesPage-Pagination"
                    page={page}
                    recordsCount={rowsCount}
                    onChange={handlePageChange}
                />
            )}
            {isAddHorseModalOpen ? (
                <AddEditHorseModal
                    close={closeAddHorseModal}
                    CreateContactModal={AddEditContactModal}
                    onSave={navigateToCreatedHorse}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        const queries = [Q.where('hidden', false)];

        const horseRecords = database.collections
            .get<HorseModel>('horses')
            .query(...queries)
            .observeCount();

        const horses = database.collections
            .get('horses')
            .query(...queries, Q.skip(0), Q.take(PAGE_LIMIT));

        return {
            horses: isInitialSyncInProgress ? of() : horses,
            totalRecords: horseRecords,
        };
    },
);

export default withSyncContext<Props>(enhance(HorsesPage));
