import { EntriesIcon, InvoiceIcon } from '@/assets/svg';
import { ENTITY_COLOR, ENTITY_SECONDARY_COLOR } from '@/theme/colors';
import { AccountSetupItem, AccountSetupType } from '@/types/accountSetup';

const items: AccountSetupItem[] = [
    {
        type: AccountSetupType.PROCEDURES_AND_SERVICES,
        title: 'Dashboard:account_setups:procedures_and_services:title',
        description:
            'Dashboard:account_setups:procedures_and_services:description',
        positiveAction:
            'Dashboard:account_setups:procedures_and_services:positive_action',
        negativeAction:
            'Dashboard:account_setups:procedures_and_services:negative_action',
        icon: EntriesIcon,
        iconPrimaryColor: ENTITY_COLOR.entries,
        iconSecondaryColor: ENTITY_SECONDARY_COLOR.entries,
        navigationPath: '/settings/procedures',
    },
    {
        type: AccountSetupType.INVOICING,
        title: 'Dashboard:account_setups:invoicing:title',
        description: 'Dashboard:account_setups:invoicing:description',
        positiveAction: 'Dashboard:account_setups:invoicing:positive_action',
        negativeAction: 'Dashboard:account_setups:invoicing:negative_action',
        icon: InvoiceIcon,
        iconPrimaryColor: ENTITY_COLOR.invoices,
        iconSecondaryColor: ENTITY_SECONDARY_COLOR.invoices,
        navigationPath: '/settings/accounting-providers',
    },
];

function getSetupItems(keys: AccountSetupType[]): AccountSetupItem[] {
    const remainingSetupItems: AccountSetupItem[] = [];

    items.forEach((item) => {
        if (keys.includes(item.type)) {
            remainingSetupItems.push(item);
        }
    });
    return remainingSetupItems;
}

export default { getSetupItems };
