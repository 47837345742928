import { Box } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { of, switchMap } from 'rxjs';
import {
    ENTRIES_FILTER,
    ENTRIES_INVOICING_FILTER,
} from 'shared/constants/entries/filters';
import {
    INVOICES_FILTER,
    INVOICES_STATUS_FILTER,
} from 'shared/constants/invoices/filters';
import Database from 'shared/db/services/Database.web';
import Entry from 'shared/db/services/Entry';
import Event from 'shared/db/services/Event';
import Invoice from 'shared/db/services/Invoice';
import ShoppingList from 'shared/db/services/ShoppingList';
import User from 'shared/db/services/User';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';
import { ROLE } from 'shared/types/OrganisationUser';
import { UserPayload } from 'shared/types/User';
import { calculateInvoicesTotalSum } from 'shared/utils/invoicing';

import {
    AccountSetupModal,
    AccountSetupSection,
    EntriesSection,
    FinanceOverviewSection,
    SchedulesSection,
    ShoppingListsSection,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { useUserContext } from '@/context/UserContext';
import { withFeaureFlags, withSyncContext } from '@/hoc';
import { useRegion } from '@/hooks/useRegion';
import { ROUTE } from '@/router/routes';
import AccountSetupService from '@/services/accountSetup';
import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { AccountSetupItem, AccountSetupType } from '@/types/accountSetup';
import { FinanceOverviewItem } from '@/types/finances';

import AddNewButton from '../../components/AddNewButton';

import { InvoicesDataState, Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

function DashboardPage({
    awaitingPaymentInvoices,
    draftInvoices,
    entriesToInvoiceCount,
    overdueInvoices,
    paidInvoices,
    recentlyCreatedShoppingLists,
    recentlyUpdatedEntries,
    upcomingEvents,
}: Props) {
    const { isSyncInProgress } = useDBSyncContext();
    const { ImagesService } = useImagesContext();
    const { orgUser } = useOrgUserContext();
    const { userProfileData } = useUserContext();
    const { invoiceSystemEnabled } = useFeatureFlagsContext();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [paidInvoicesData, setPaidInvoicesData] = useState<InvoicesDataState>(
        { amount: null },
    );
    const [awaitingPaymentInvoicesData, setAwaitingPaymentInvoicesData] =
        useState<InvoicesDataState>({ amount: null, count: null });
    const [draftInvoicesData, setDraftInvoicesData] =
        useState<InvoicesDataState>({ amount: null, count: null });
    const [overdueInvoicesData, setOverdueInvoicesData] =
        useState<InvoicesDataState>({ amount: null, count: null });
    const [isFinanceOverviewDataLoading, setIsFinanceOverviewDataLoading] =
        useState(false);
    const [accountSetupItems, setAccountSetupItems] = useState<
        AccountSetupItem[]
    >([]);
    const [showAccountSetupModal, setShowAccountSetupModal] = useState<{
        show: boolean;
        item: AccountSetupItem | null;
    }>({ show: false, item: null });

    const userRegion = useRegion();
    const currency = userRegion?.currencySymbol || '$';

    const database = useMemo(() => {
        return Database.getDatabase();
    }, []);

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const invoicesFnOptions = useMemo(() => {
        return {
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
        };
    }, [database, ImagesService]);

    const openAccountSetupModal = useCallback(
        (item: AccountSetupItem) =>
            setShowAccountSetupModal({ show: true, item }),
        [],
    );

    const closeAccountSetupModal = useCallback(
        () => setShowAccountSetupModal({ show: false, item: null }),
        [],
    );

    const navigateToSchedulePage = useCallback(() => {
        navigate(ROUTE.schedule);
    }, [navigate]);

    const navigateToEntriesPage = useCallback(() => {
        navigate(ROUTE.entries);
    }, [navigate]);

    const navigateToShoppingListsPage = useCallback(() => {
        navigate(ROUTE.shoppingLists);
    }, [navigate]);

    const getAwaitingPaymentsInvoicesData = useCallback(async () => {
        try {
            const amount = await calculateInvoicesTotalSum({
                options: invoicesFnOptions,
                invoices: awaitingPaymentInvoices || [],
                invoiceSystemEnabled,
            });

            setAwaitingPaymentInvoicesData({
                amount: amount.toFixed(2),
                count: awaitingPaymentInvoices?.length || 0,
            });
        } catch {
            setAwaitingPaymentInvoicesData({
                amount: null,
                count: null,
            });
        }
    }, [awaitingPaymentInvoices, invoiceSystemEnabled, invoicesFnOptions]);

    const getDraftInvoicesData = useCallback(async () => {
        try {
            const amount = await calculateInvoicesTotalSum({
                options: invoicesFnOptions,
                invoices: draftInvoices || [],
                invoiceSystemEnabled,
            });

            setDraftInvoicesData({
                amount: amount.toFixed(2),
                count: draftInvoices?.length || 0,
            });
        } catch {
            setDraftInvoicesData({ amount: null, count: null });
        }
    }, [draftInvoices, invoiceSystemEnabled, invoicesFnOptions]);

    const getPaidInvoicesData = useCallback(async () => {
        try {
            const amount = await calculateInvoicesTotalSum({
                options: invoicesFnOptions,
                invoices: paidInvoices || [],
                invoiceSystemEnabled,
            });

            setPaidInvoicesData({ amount: amount.toFixed(2) });
        } catch {
            setPaidInvoicesData({ amount: null });
        }
    }, [invoicesFnOptions, paidInvoices, invoiceSystemEnabled]);

    const getOverdueInvoicesData = useCallback(async () => {
        try {
            const amount = await calculateInvoicesTotalSum({
                options: invoicesFnOptions,
                invoices: overdueInvoices || [],
                invoiceSystemEnabled,
            });

            setOverdueInvoicesData({
                amount: amount.toFixed(2),
                count: overdueInvoices?.length || 0,
            });
        } catch {
            setOverdueInvoicesData({ amount: null, count: null });
        }
    }, [invoicesFnOptions, overdueInvoices, invoiceSystemEnabled]);

    const fetchFinanceOverviewData = useCallback(async () => {
        setIsFinanceOverviewDataLoading(true);

        await getAwaitingPaymentsInvoicesData();
        await getPaidInvoicesData();
        await getOverdueInvoicesData();
        await getDraftInvoicesData();

        setIsFinanceOverviewDataLoading(false);
    }, [
        getAwaitingPaymentsInvoicesData,
        getPaidInvoicesData,
        getOverdueInvoicesData,
        getDraftInvoicesData,
    ]);

    useEffect(() => {
        fetchFinanceOverviewData();
    }, [fetchFinanceOverviewData]);

    const handleFinanceItemPress = useCallback(
        (item: FinanceOverviewItem) => {
            const {
                navigation: { filterToApply, targetRoute },
            } = item;

            navigate(targetRoute, {
                state: { filterToApply },
            });
        },
        [navigate],
    );

    const financeOverviewData = useMemo((): FinanceOverviewItem[] => {
        return [
            {
                amount: paidInvoicesData.amount || '',
                color: COLOR.successGreen,
                id: '1',
                name: t('Dashboard:finances_overview:paid'),
                navigation: {
                    filterToApply: {
                        key: INVOICES_FILTER.status,
                        value: INVOICES_STATUS_FILTER.paid,
                    },
                    targetRoute: ROUTE.invoices,
                },
            },
            {
                amount: awaitingPaymentInvoicesData.amount || '',
                color: COLOR.black,
                count: awaitingPaymentInvoicesData.count || 0,
                id: '2',
                name: t('Dashboard:finances_overview:awaiting_payment'),
                navigation: {
                    filterToApply: {
                        key: INVOICES_FILTER.status,
                        value: INVOICES_STATUS_FILTER.authorised,
                    },
                    targetRoute: ROUTE.invoices,
                },
            },
            {
                amount: overdueInvoicesData.amount || '',
                color: COLOR.errorRed,
                count: overdueInvoicesData.count || 0,
                id: '3',
                name: t('Dashboard:finances_overview:overdue'),
                navigation: {
                    filterToApply: {
                        key: INVOICES_FILTER.show_overdue,
                        value: true,
                    },
                    targetRoute: ROUTE.invoices,
                },
            },
            {
                amount: draftInvoicesData.amount || '',
                color: COLOR.regentGray,
                count: draftInvoicesData.count || 0,
                id: '4',
                name: t('Dashboard:finances_overview:draft'),
                navigation: {
                    filterToApply: {
                        key: INVOICES_FILTER.status,
                        value: INVOICES_STATUS_FILTER.draft,
                    },
                    targetRoute: ROUTE.invoices,
                },
            },
            {
                color: COLOR.bahamaBlue,
                count: entriesToInvoiceCount || 0,
                id: '5',
                name: t('Dashboard:finances_overview:entries_to_invoice'),
                navigation: {
                    filterToApply: {
                        key: ENTRIES_FILTER.invoicing,
                        value: ENTRIES_INVOICING_FILTER.to_invoice,
                    },
                    targetRoute: ROUTE.entries,
                },
            },
        ];
    }, [
        awaitingPaymentInvoicesData,
        draftInvoicesData,
        entriesToInvoiceCount,
        overdueInvoicesData,
        paidInvoicesData,
        t,
    ]);

    const handleAccountSetupItemClick = useCallback(
        (item: AccountSetupItem) => openAccountSetupModal(item),
        [openAccountSetupModal],
    );

    const handleDeleteAccountSetupItemClick = useCallback(
        async (itemToDelete: AccountSetupItem) => {
            let payload: UserPayload = {};

            if (itemToDelete.type === AccountSetupType.INVOICING) {
                payload = {
                    isInvoicingAccountSetupSet: true,
                };
            } else {
                payload = {
                    isProceduresAccountSetupSet: true,
                };
            }

            await userService.update(userProfileData?.id || '', payload);

            const updatedSetupItems = accountSetupItems.filter(
                (item) => item.type !== itemToDelete.type,
            );

            closeAccountSetupModal();
            setAccountSetupItems(updatedSetupItems);
        },
        [
            accountSetupItems,
            closeAccountSetupModal,
            userProfileData?.id,
            userService,
        ],
    );

    const fetchAccountSetupData = useCallback(async () => {
        if (!userProfileData) return;

        const currentUser = await userService
            .getById(userProfileData.id)
            .fetch();

        const isInvoicingSetup =
            currentUser?.[0]?.isInvoicingAccountSetupSet || false;
        const isProceduresSetup =
            currentUser?.[0]?.isProceduresAccountSetupSet || false;

        const remainingSetupKeys: AccountSetupType[] = [];

        if (!isInvoicingSetup) {
            remainingSetupKeys.push(AccountSetupType.INVOICING);
        }

        if (!isProceduresSetup) {
            remainingSetupKeys.push(AccountSetupType.PROCEDURES_AND_SERVICES);
        }

        const items = AccountSetupService.getSetupItems(remainingSetupKeys);

        setAccountSetupItems(items);
    }, [userProfileData, userService]);

    useEffect(() => {
        fetchAccountSetupData();
    }, [fetchAccountSetupData]);

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <AddNewButton />
            <Box
                sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mb: 3, mt: 3 }}
            >
                {accountSetupItems.length ? (
                    <Box sx={sectionsRowStyles}>
                        <Box
                            data-test-id="DashboardPage-AccountSetupSection"
                            sx={sectionStyles}
                        >
                            <AccountSetupSection
                                badgeCount={accountSetupItems.length}
                                data={accountSetupItems}
                                isLoading={isSyncInProgress}
                                onItemClick={handleAccountSetupItemClick}
                                onDeleteItemClick={
                                    handleDeleteAccountSetupItemClick
                                }
                            />
                        </Box>
                    </Box>
                ) : null}
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="DashboardPage-SchedulesSection"
                        sx={sectionStyles}
                    >
                        <SchedulesSection
                            buttonText={t('Dashboard:all_appointments')}
                            emptyMessage={t(
                                'Dashboard:empty_upcoming_appointments',
                            )}
                            events={upcomingEvents || []}
                            isLoading={isSyncInProgress}
                            onButtonClick={navigateToSchedulePage}
                            showButtonAlways
                            testIdPrefix="Dashboard-UpcomingAppointmentsSection"
                            title={t('Dashboard:upcoming_appointments')}
                        />
                    </Box>
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="DashboardPage-EntriesSection"
                        sx={sectionStyles}
                    >
                        <EntriesSection
                            buttonText={t('Dashboard:all_entries')}
                            emptyMessage={t(
                                'EntriesList:no_entries_have_been_added_yet',
                            )}
                            entries={recentlyUpdatedEntries || []}
                            isLoading={isSyncInProgress}
                            onButtonClick={navigateToEntriesPage}
                            showButtonAlways
                            title={t('Dashboard:recent_entries')}
                        />
                    </Box>
                </Box>
                {isOrgUserAdmin ? (
                    <Box sx={sectionsRowStyles}>
                        <Box
                            data-test-id="DashboardPage-FinanceOverviewSection"
                            sx={sectionStyles}
                        >
                            <FinanceOverviewSection
                                badgeCount={overdueInvoicesData.count}
                                currency={currency}
                                data={financeOverviewData}
                                isLoading={
                                    isSyncInProgress ||
                                    isFinanceOverviewDataLoading
                                }
                                onItemClick={handleFinanceItemPress}
                            />
                        </Box>
                    </Box>
                ) : null}
                <Box sx={sectionsRowStyles}>
                    <Box
                        data-test-id="DashboardPage-ShoppingListsSection"
                        sx={sectionStyles}
                    >
                        <ShoppingListsSection
                            buttonText={t('Dashboard:all_shopping_lists')}
                            emptyMessage={t(
                                'ShoppingListsPage:no_shopping_lists_has_been_added',
                            )}
                            shoppingLists={recentlyCreatedShoppingLists || []}
                            isLoading={isSyncInProgress}
                            onButtonClick={navigateToShoppingListsPage}
                            showButtonAlways
                            title={t('Dashboard:shopping_lists:title')}
                        />
                    </Box>
                </Box>
            </Box>
            {showAccountSetupModal.show && showAccountSetupModal.item ? (
                <AccountSetupModal
                    item={showAccountSetupModal.item}
                    onCancel={closeAccountSetupModal}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress', 'invoiceSystemEnabled'],
    ({ isInitialSyncInProgress, invoiceSystemEnabled }) => {
        const database = Database.getDatabase();
        const { collections } = database;
        const firebaseImagesService = new FirebaseImagesService();

        const entryService = new Entry({
            database,
            imageService: firebaseImagesService,
            logDBAction: Logger.logRecordActivity,
        });
        const invoiceService = new Invoice({
            database,
            imageService: firebaseImagesService,
            logDBAction: Logger.logRecordActivity,
        });
        const eventService = new Event({
            database,
            imageService: firebaseImagesService,
            logDBAction: Logger.logRecordActivity,
            cacheService: {
                clear: () => Promise.resolve(),
                get: (() => Promise.resolve()) as () => any,
            },
            notificationsService: {
                cancelNotification: () => null,
                scheduleEventLocalNotification: () => Promise.resolve(),
            },
        });
        const shoppingListService = new ShoppingList({
            database,
            imageService: firebaseImagesService,
            logDBAction: Logger.logRecordActivity,
            logError: Logger.logError,
        });

        const organisationAccountings = collections
            .get<OrganisationAccountingsModel>('organisation_accountings')
            .query();

        const draftInvoices = organisationAccountings
            .observeWithColumns(['accounting_provider'])
            .pipe(
                switchMap((accountingsData) => {
                    const provider = invoiceSystemEnabled
                        ? accountingsData?.[0]?.accountingProvider || 'internal'
                        : undefined;

                    return invoiceService.getDraftInvoices(provider).observe();
                }),
            );

        const awaitingPaymentInvoices = organisationAccountings
            .observeWithColumns(['accounting_provider'])
            .pipe(
                switchMap((accountingsData) => {
                    const provider = invoiceSystemEnabled
                        ? accountingsData?.[0]?.accountingProvider || 'internal'
                        : undefined;

                    return invoiceService
                        .getAwaitingPaymentInvoices(provider)
                        .observe();
                }),
            );

        const paidInvoices = organisationAccountings
            .observeWithColumns(['accounting_provider'])
            .pipe(
                switchMap((accountingsData) => {
                    const provider = invoiceSystemEnabled
                        ? accountingsData?.[0]?.accountingProvider || 'internal'
                        : undefined;

                    return invoiceService.getPaidInvoices(provider).observe();
                }),
            );

        const overdueInvoices = organisationAccountings
            .observeWithColumns(['accounting_provider'])
            .pipe(
                switchMap((accountingsData) => {
                    const provider = invoiceSystemEnabled
                        ? accountingsData?.[0]?.accountingProvider || 'internal'
                        : undefined;

                    return invoiceService
                        .getOverdueInvoices(provider)
                        .observe();
                }),
            );

        return {
            awaitingPaymentInvoices,
            draftInvoices,
            entriesToInvoiceCount: isInitialSyncInProgress
                ? of(null)
                : entryService.getEntriesToInvoiceCount(),
            overdueInvoices,
            paidInvoices,
            recentlyCreatedShoppingLists: isInitialSyncInProgress
                ? of([])
                : shoppingListService.getRecentlyCreatedShoppingLists(2),
            recentlyUpdatedEntries: isInitialSyncInProgress
                ? of([])
                : entryService.getRecentlyUpdatedEntries(2),
            upcomingEvents: isInitialSyncInProgress
                ? of([])
                : eventService.getTodayAndTomorrowEvents(2),
        };
    },
);

export default withFeaureFlags(withSyncContext<Props>(enhance(DashboardPage)));
