import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FinanceOverviewList, TruncatedListSection } from '@/components';
import { Props as FinanceOverviewListProps } from '@/components/FinanceOverviewList/types';
import { ROUTE } from '@/router/routes';

import { Props } from './types';

function FinanceOverviewSection({
    badgeCount,
    currency,
    data,
    isLoading,
    onItemClick,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAllInvoicesButtonClick = useCallback(() => {
        navigate(ROUTE.invoices);
    }, [navigate]);

    return (
        <TruncatedListSection<FinanceOverviewListProps>
            badgeCount={badgeCount}
            ListComponent={FinanceOverviewList}
            listComponentProps={{
                currency,
                items: data,
                onItemClick,
                testIdPrefix: 'FinanceOverviewSection',
            }}
            title={t('Dashboard:finance_overview')}
            buttonText={t('Dashboard:invoices:all')}
            emptyMessage=""
            isLoading={isLoading}
            onButtonClick={handleAllInvoicesButtonClick}
            showButtonAlways
        />
    );
}

export default FinanceOverviewSection;
