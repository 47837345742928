import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import React from 'react';

function PhoneNumber(
    props: Omit<MuiPhoneNumberProps, 'onChange'> & {
        onChange: (
            phone: string,
            data: { name: string; dialCode: string; countryCode: string },
        ) => void;
    },
) {
    return (
        <MuiPhoneNumber
            defaultCountry={props.defaultCountry || undefined}
            preferredCountries={['au', 'nz', 'nl']}
            variant="outlined"
            autoFormat={false}
            disableAreaCodes
            classes={{
                flagButton:
                    'rounded-md h-10 w-20 p-2 after:w-7 after:content-dropdown child:w-auto child:h-9',
            }}
            {...props}
        />
    );
}

export default PhoneNumber;
