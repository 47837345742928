import React from 'react';
import { Helmet } from 'react-helmet';

import { APP_CONFIG } from '@/constants/appConfig';

import { Props } from './types';

function FreshchatScriptWrapper(props: Props) {
    return (
        <Helmet>
            <script async src={APP_CONFIG.freshchatSrc} {...props} />
        </Helmet>
    );
}

export default FreshchatScriptWrapper;
