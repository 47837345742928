export enum ZIndex {
    index1 = 1,
    index2 = 2,
    index3 = 3,
    index4 = 4,
    index5 = 5,
    index6 = 6,
    index7 = 7,
    index8 = 8,
    index9 = 9,
    index10 = 10,
}
