import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import User from 'shared/db/services/User';
import { UserModel } from 'shared/types/User';

import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useDatabase } from '@/hooks';
import { Freshchat } from '@/services/freshchat';
import Logger from '@/services/logger';

import { useImagesContext } from '../ImagesContext';
import { useUserContext } from '../UserContext';

import { ContextValue, Props } from './types';

const FreshchatContext = createContext<ContextValue>({
    isFreshchatUserReady: false,
    enableFreshchatUnsecured: false,
});

const FreshchatContextProvider = ({ children }: Props) => {
    const { isUserAuthenticated } = useAuthContext();
    const { ImagesService } = useImagesContext();
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();

    const { isInitialSyncInProgress } = useDBSyncContext();

    const [intervalRef, setIntervalRef] = useState<any>(null);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [database, ImagesService],
    );

    const [isFreshchatUserReady, setIsFreshchatUserReady] = useState(false);
    const [enableFreshchatUnsecured, setEnableFreshchatUnsecured] = useState(
        !isUserAuthenticated,
    );
    const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
    const [dbUser, setDbUser] = useState<UserModel | null>(null);

    const getDbUser = useCallback(
        async (userId: string) => {
            const [user] = await userService.getById(userId).fetch();
            if (user) {
                setDbUser(user);
            }
        },
        [userService],
    );

    const setFreshchatUser = useCallback(
        async (userId: string) => {
            if (dbUser && !dbUser?.freshchatRestoreId) {
                Freshchat.onUserCreated((restoreId) => {
                    userService.update(userId, {
                        freshchatRestoreId: restoreId,
                    });
                });
                Freshchat.setUser(userId, {
                    firstName: dbUser.firstName,
                    lastName: dbUser.lastName,
                    phone: dbUser.phone,
                    phoneCountryCode: dbUser.phonePrefix,
                    email: dbUser.email,
                });
            }
        },
        [dbUser, userService],
    );

    const identifyUser = useCallback(() => {
        if (dbUser?.freshchatRestoreId) {
            Freshchat.identifyUser(dbUser.id, dbUser.freshchatRestoreId);
        }
    }, [dbUser?.freshchatRestoreId, dbUser?.id]);

    useEffect(() => {
        if (!isWidgetLoaded) {
            let isWidgetDestroyed = false;

            setIntervalRef(
                setInterval(() => {
                    if (isWidgetDestroyed && Freshchat.isInitialized()) {
                        setIsWidgetLoaded(true);
                    } else if (
                        !isWidgetDestroyed &&
                        !Freshchat.isInitialized()
                    ) {
                        isWidgetDestroyed = true;
                    }
                }, 100),
            );
        }
    }, [isWidgetLoaded]);

    useEffect(() => {
        if (isWidgetLoaded && intervalRef) {
            clearInterval(intervalRef);
            setIntervalRef(null);
        }
    }, [intervalRef, isWidgetLoaded]);

    const initUnsecuredChat = useCallback(async () => {
        if (isWidgetLoaded && !enableFreshchatUnsecured) {
            await Freshchat.clearUser();
            setDbUser(null);
            Freshchat.destroyWidget();
            setIsWidgetLoaded(false);
        }
        setIsFreshchatUserReady(false);
        setEnableFreshchatUnsecured(true);
    }, [enableFreshchatUnsecured, isWidgetLoaded]);

    useEffect(() => {
        if (isWidgetLoaded && enableFreshchatUnsecured) {
            Freshchat.clearUser();
        }
    }, [enableFreshchatUnsecured, isWidgetLoaded]);

    useEffect(() => {
        if (!userProfileData) {
            initUnsecuredChat();
        }
    }, [initUnsecuredChat, userProfileData]);

    useEffect(() => {
        if (
            !dbUser &&
            userProfileData?.id &&
            enableFreshchatUnsecured &&
            !isInitialSyncInProgress
        ) {
            if (isWidgetLoaded) {
                Freshchat.destroyWidget();
                setIsWidgetLoaded(false);
            }
            setEnableFreshchatUnsecured(false);
            getDbUser(userProfileData.id);
        }
    }, [
        dbUser,
        enableFreshchatUnsecured,
        getDbUser,
        isInitialSyncInProgress,
        isWidgetLoaded,
        userProfileData?.id,
    ]);

    useEffect(() => {
        if (userProfileData?.id && dbUser && !isFreshchatUserReady) {
            identifyUser();
            setIsFreshchatUserReady(true);
        }
    }, [dbUser, identifyUser, isFreshchatUserReady, userProfileData?.id]);

    useEffect(() => {
        if (
            userProfileData?.id &&
            dbUser &&
            isFreshchatUserReady &&
            isWidgetLoaded
        ) {
            setFreshchatUser(dbUser.id);
        }
    }, [
        setFreshchatUser,
        isWidgetLoaded,
        isFreshchatUserReady,
        enableFreshchatUnsecured,
        dbUser,
        identifyUser,
        userProfileData?.id,
    ]);

    return (
        <FreshchatContext.Provider
            value={{ isFreshchatUserReady, enableFreshchatUnsecured }}
        >
            {children}
        </FreshchatContext.Provider>
    );
};

function useFreshchatContext() {
    const freshchatContext = useContext(FreshchatContext);

    return freshchatContext;
}

export { FreshchatContext, FreshchatContextProvider, useFreshchatContext };
