import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { Button, NotchedOutlinedBox } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SelectionOutlinedBox({
    onSelect,
    onAdd,
    title,
    children,
    addLabel,
    selectLabel,
    SelectIcon,
    buttonsPosition = 'bottom',
    addButtonTestId,
    selectButtonTestId,
    allowCreate = true,
    disabled = false,
    testId,
}: Props) {
    const areButtonsOnBottom = buttonsPosition === 'bottom';

    return (
        <Box
            data-test-id={testId}
            sx={{
                display: 'flex',
                flexDirection: areButtonsOnBottom ? 'column' : 'column-reverse',
                gap: 1,
            }}
        >
            <NotchedOutlinedBox label={title}>
                <Box className="flex justify-between flex-wrap gap-[10px] max-h-80 overflow-auto min-h-[74px] px-[10px]">
                    {children}
                </Box>
            </NotchedOutlinedBox>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: areButtonsOnBottom
                        ? 'flex-start'
                        : 'flex-end',
                }}
            >
                {allowCreate ? (
                    <Button
                        data-test-id={addButtonTestId}
                        onClick={onAdd}
                        disabled={disabled}
                        size="small"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 700,
                            height: 'auto',
                            mb: 0.75,
                        }}
                        variant="text"
                        color="primary"
                        startIcon={
                            <Add
                                htmlColor={
                                    disabled
                                        ? COLOR.regentGray
                                        : COLOR.deepCerulean
                                }
                            />
                        }
                        caption={addLabel}
                    />
                ) : null}
                <Button
                    data-test-id={selectButtonTestId}
                    onClick={onSelect}
                    disabled={disabled}
                    size="small"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        height: 'auto',
                        mb: 0.75,
                    }}
                    variant="text"
                    color="primary"
                    startIcon={
                        <SelectIcon
                            color={
                                disabled ? COLOR.regentGray : COLOR.deepCerulean
                            }
                        />
                    }
                    caption={selectLabel}
                />
            </Box>
        </Box>
    );
}

export default SelectionOutlinedBox;
