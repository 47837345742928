export const EQUINET_SUPPORT_PAGE_URL = 'https://www.equinetapp.com/support';
export const EQUINET_ENTRIES_VIDEO_TUTORIAL_PAGE =
    'https://www.equinetapp.com/support/videotutorials#rec703544117';
export const EQUINET_SHOPPING_LISTS_VIDEO_TUTORIAL_PAGE =
    'https://www.equinetapp.com/support/videotutorials#rec703569071';
export const EQUINET_SCHEDULE_VIDEO_TUTORIAL_PAGE =
    'https://www.equinetapp.com/support/videotutorials#rec703567848';

export const APPLE_APP_STORE_APP_URL =
    'https://apps.apple.com/app/id1459492568';
export const GOOGLE_PLAY_STORE_APP_URL =
    'https://play.google.com/store/apps/details?id=com.mustad.equinetapp';
