import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    DialogActions,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function SessionExpiredModal({ onProceedButtonClick }: Props) {
    const { t } = useTranslation();

    return (
        <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
        >
            <DialogTitle>{t('SessionExpiredModal:title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('SessionExpiredModal:message')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onProceedButtonClick}>
                    {t('Actions:proceed')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SessionExpiredModal;
