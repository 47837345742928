import { ArrowForward } from '@mui/icons-material';
import { Box, Fade, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { SwitchTransition } from 'react-transition-group';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import Button from '@/components/Button';
import { t } from '@/services/translations/config';

import { IntroductionTransition } from './components';
import { Props } from './types';

function IntroductionAnimation({ animation, firstName, onContinue }: Props) {
    const animationSteps = useMemo(() => {
        switch (animation) {
            case 'first':
                return (
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            mt: '15px',
                        }}
                    >
                        {t('IntroductionModal:hi', {
                            defaultValue: 'Hi {{ firstName }}!',
                            firstName,
                        })}
                    </Typography>
                );

            case 'second':
                return (
                    <>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                mb: '10px',
                            }}
                        >
                            Welcome to
                        </Typography>
                        <Box
                            alt="Equinet Logo"
                            component="img"
                            src={equinetLogoImage}
                            sx={{
                                px: '33px',
                            }}
                        />
                    </>
                );

            default:
                <div />;
        }
    }, [animation, firstName]);

    return (
        <>
            <Box
                sx={{
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    mt: '65%',
                    height: '200px',
                }}
            >
                <SwitchTransition mode="in-out">
                    <IntroductionTransition key={animation} timeout={600}>
                        {animationSteps}
                    </IntroductionTransition>
                </SwitchTransition>
            </Box>

            {animation === 'second' ? (
                <Fade in timeout={600} style={{ transitionDelay: '2600ms' }}>
                    <Box
                        sx={{
                            mb: '12px',
                            width: '94%',
                        }}
                    >
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            caption={'Continue'}
                            endIcon={<ArrowForward />}
                            onClick={onContinue}
                        />
                    </Box>
                </Fade>
            ) : null}
        </>
    );
}

export default IntroductionAnimation;
