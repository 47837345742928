import { Box, Collapse, Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';

import { RotativeExpandMoreIcon } from '@/assets/svg';
import { useUserContext } from '@/context/UserContext';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import InvoiceHistoryItem from './components';
import { Props } from './types';

const p = {
    py: 1.5,
    px: 2,
};

function InvoiceHistory({ invoice }: Props) {
    const { userProfileData } = useUserContext();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const toggleCollapsible = useCallback(
        () => setIsCollapsed((collapsed) => !collapsed),
        [],
    );

    return (
        <div>
            <Box
                sx={{
                    ...p,
                    boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24);',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                onClick={toggleCollapsible}
            >
                <Typography
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    {t('SingleInvoice:history')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 3.5 }}>
                    <RotativeExpandMoreIcon isExpanded={!isCollapsed} />
                </Box>
            </Box>
            <Collapse in={!isCollapsed}>
                {invoice ? (
                    <Box sx={p}>
                        {invoice.amountValid === false && (
                            <InvoiceHistoryItem
                                text={`${t('Invoicing:invalid', {
                                    defaultValue: 'Invoice price is invalid!',
                                })}`}
                                textColor={COLOR.errorRed}
                            />
                        )}
                        {!!invoice.createdAt && (
                            <InvoiceHistoryItem
                                text={`${t('Invoice:log:created')}`}
                                description={`${t('SingleInvoice:on', {
                                    date: formatDate(
                                        invoice.createdAt.toISOString(),
                                        'date',
                                    ),
                                })} ${
                                    userProfileData
                                        ? `, ${t('SingleInvoice:by', {
                                              first_name:
                                                  userProfileData.first_name,
                                              last_name:
                                                  userProfileData.last_name,
                                          })}`
                                        : ''
                                }`}
                            />
                        )}
                        {!!invoice.authorisedTime && (
                            <InvoiceHistoryItem
                                text={`${t('Invoice:log:authorised')}`}
                                description={`${t('SingleInvoice:on', {
                                    date: formatDate(
                                        invoice.authorisedTime,
                                        'date',
                                    ),
                                })} ${
                                    userProfileData
                                        ? `, ${t('SingleInvoice:by', {
                                              first_name:
                                                  userProfileData.first_name,
                                              last_name:
                                                  userProfileData.last_name,
                                          })}`
                                        : ''
                                }`}
                            />
                        )}
                        {!!invoice.sentToContactTime && (
                            <InvoiceHistoryItem
                                text={`${t('Invoice:log:resent')}`}
                                description={`${t('SingleInvoice:on', {
                                    date: invoice.sentToContact
                                        ? formatDate(invoice.sentToContactTime)
                                        : t('Invoice:log:in_progress', {
                                              defaultValue: 'In progress',
                                          }),
                                })} ${
                                    userProfileData
                                        ? `, ${t('SingleInvoice:by', {
                                              first_name:
                                                  userProfileData.first_name,
                                              last_name:
                                                  userProfileData.last_name,
                                          })}`
                                        : ''
                                }`}
                            />
                        )}
                        {!!invoice.paymentDateTime && (
                            <InvoiceHistoryItem
                                text={`${t('Invoice:log:paid')}`}
                                description={`${t('SingleInvoice:on', {
                                    date: formatDate(invoice.paymentDateTime),
                                })} ${
                                    userProfileData
                                        ? `, ${t('SingleInvoice:by', {
                                              first_name:
                                                  userProfileData.first_name,
                                              last_name:
                                                  userProfileData.last_name,
                                          })}`
                                        : ''
                                }`}
                            />
                        )}
                    </Box>
                ) : (
                    <Skeleton
                        sx={p}
                        data-test-id="InvoiceHistory-Skeleton"
                        width="100%"
                        height={76}
                    />
                )}
            </Collapse>
        </div>
    );
}

export default InvoiceHistory;
