import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ContactsIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <g id="Shapes">
                <path
                    className="st0"
                    d="M15.1 34.9h12.3c.8 0 1.4.6 1.4 1.4v1.6c0 .8-.6 1.4-1.4 1.4H15.1c-.8 0-1.4-.6-1.4-1.4v-1.6c0-.8.6-1.4 1.4-1.4z"
                />
                <path
                    className="st0"
                    d="M33.9 3.2H8.6c-1 0-1.8.8-1.8 1.8v30.8c0 1 .8 1.8 1.8 1.8h3.6v-1.8c0-1.3 1-2.3 2.3-2.3H28c1.3 0 2.3 1 2.3 2.3v1.8h3.6c1 0 1.8-.8 1.8-1.8V5c0-1-.8-1.8-1.8-1.8zM21.3 14.5c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2 1.4-3.2 3.2-3.2zm4.8 12.6h-9.7v-1.9c0-2.5 3.2-3.7 4.9-3.7 1.6 0 4.9 1.2 4.9 3.7v1.9z"
                />
            </g>
        </svg>
    );
}

export default memo(ContactsIcon);
