export enum NavigationEvents {
    genericUserSwitchedTo = 'user_switched_to',
    userSwitchedToContacts = 'user_switched_to_contacts',
    userSwitchedToHorses = 'user_switched_to_horses',
    userSwitchedToEntries = 'user_switched_to_entries',
    userSwitchedToInvoices = 'user_switched_to_invoices',
    userSwitchedToSchedule = 'user_switched_to_schedule',
    userSwitchedToInventory = 'user_switched_to_inventory',
    userSwitchedToShoppingLists = 'user_switched_to_shopping_lists',
    userSwitchedToMessages = 'user_switched_to_messages',
    userSwitchedToTeams = 'user_switched_to_teams',
    userSwitchedToInvoicingAndAdministration = 'user_switched_to_invoicing_admin',
    userSwitchedToSettings = 'user_switched_to_settings',
    userSwitchedToAccount = 'user_switched_to_account',
    userSwitchedToCalendarSync = 'user_switched_to_calendar_sync',
    userSwitchedToSubscriptionsAndNotifications = 'user_switched_to_subscriptions_notifs',
    userSwitchedToPrivacySettings = 'user_switched_to_privacy_settings',
    userSwitchedToFAQ = 'user_switched_to_faq',
    userSwitchedToPrivacyPolicy = 'user_switched_to_privacy_policy',
    userSwitchedToTermsAndConditions = 'user_switched_to_terms_and_conditions',
    userSwitchedToWhatIsEquinet = 'user_switched_to_what_is_equinet',
    userOpenedContactUsModal = 'user_opened_contact_us_modal',
    userSelectedContactUsEmail = 'user_selected_contact_us_email',
    userSelectedContactUsPhoneCall = 'user_selected_contact_us_phone_call',
    userSwitchedToCredits = 'user_switched_to_credits',
    userClickedSendIssueReport = 'user_clicked_send_issue_report',
    userSentIssueReport = 'user_sent_issue_report',
    userClickedOnHelpButton = 'user_clicked_on_help_button',
    userClickedOnPlusButton = 'user_clicked_on_plus_button',
    userCustomisedMenuOrder = 'user_customised_menu_order',
    userOpenedTeamMembersArea = 'user_opened_team_members_area',
}
