import { Box, FormControlLabel, Switch } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import {
    CONTACTS_FILTER,
    CONTACTS_INVOICING_FILTERS_DICTIONARY,
} from 'shared/constants/contacts/filters';
import { CONTACT_ROLES_DICTIONARY } from 'shared/constants/contacts/roles';

import { Select } from '@/components';
import {
    getContactInvoicingFilters,
    getContactRoleFilters,
} from '@/helpers/contacts/filters';
import { renderSelectedFilterValues } from '@/helpers/filters';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function FiltersPanel({ filtersState, onFiltersChange }: Props) {
    const isShowHiddenChecked = filtersState[CONTACTS_FILTER.show_hidden];

    const handleShowHiddenSwitchChange = useCallback(() => {
        const previousValue = filtersState[CONTACTS_FILTER.show_hidden];

        const setShowHiddenFilterValue = onFiltersChange(
            CONTACTS_FILTER.show_hidden,
        );

        setShowHiddenFilterValue(!previousValue);
    }, [filtersState, onFiltersChange]);

    const contactRolesFilters = useMemo(() => getContactRoleFilters(), []);
    const contactInvoicingFilters = useMemo(
        () => getContactInvoicingFilters(),
        [],
    );

    return (
        <Box
            data-test-id="ContactsPage-FiltersPanel"
            sx={{
                alignItems: 'center',
                backgroundColor: COLOR.athensGray,
                display: 'flex',
                flexWrap: 'wrap',
                px: 2,
                pt: 4,
                pb: 2,
            }}
        >
            <Box sx={{ mb: 2, mr: 3 }}>
                <Select
                    label={t('ContactsList:filterNames:roles')}
                    multiselect
                    onChange={onFiltersChange(CONTACTS_FILTER.role)}
                    options={contactRolesFilters}
                    renderSelectedValue={renderSelectedFilterValues(
                        CONTACT_ROLES_DICTIONARY,
                    )}
                    selectedValue={filtersState[CONTACTS_FILTER.role]}
                    testID="ContactsPage-FiltersPanel-SelectRole"
                />
            </Box>
            <Box sx={{ mb: 2, mr: 3 }}>
                <Select
                    label={t('ContactsList:filterNames:invoicing')}
                    multiselect
                    onChange={onFiltersChange(CONTACTS_FILTER.invoicing)}
                    options={contactInvoicingFilters}
                    renderSelectedValue={renderSelectedFilterValues(
                        CONTACTS_INVOICING_FILTERS_DICTIONARY,
                    )}
                    selectedValue={filtersState[CONTACTS_FILTER.invoicing]}
                    testID="ContactsPage-FiltersPanel-SelectInvoicing"
                />
            </Box>
            <Box sx={{ mb: 2 }}>
                <FormControlLabel
                    checked={isShowHiddenChecked}
                    control={
                        <Switch
                            onChange={handleShowHiddenSwitchChange}
                            value={isShowHiddenChecked}
                        />
                    }
                    data-test-id="ContactsPage-FiltersPanel-ShowHidden"
                    label={t('ContactsList:filters:show_options:show_hidden')}
                />
            </Box>
        </Box>
    );
}

export default memo(FiltersPanel);
