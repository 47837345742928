import { Box, Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import AccountSetupList from '../AccountSetupList';
import Badge from '../Badge';

import { Props } from './types';

function AccountSetupSection({
    badgeCount,
    data,
    isLoading,
    onItemClick,
    onDeleteItemClick,
}: Props) {
    return (
        <div data-test-id="AccountSetupSection-TruncatedListSection">
            <Box
                sx={{
                    display: 'flex',
                    boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24);',
                    py: 1.5,
                    px: 2,
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography sx={{ fontWeight: 700 }}>
                        {t('Dashboard:account_setup')}
                    </Typography>
                    {badgeCount && badgeCount > 0 ? (
                        <Badge
                            count={badgeCount}
                            sx={{ width: 18, height: 18 }}
                            isCircle
                        />
                    ) : null}
                </Box>
            </Box>
            <Box sx={{ py: 1.5, px: 2 }}>
                {isLoading ? (
                    <Skeleton
                        data-test-id="AccountSetupSection-TruncatedListSection-Skeleton"
                        width="100%"
                        height={76}
                    />
                ) : (
                    <AccountSetupList
                        items={data}
                        onItemClick={onItemClick}
                        onDeleteItemClick={onDeleteItemClick}
                    />
                )}
            </Box>
        </div>
    );
}

export default AccountSetupSection;
