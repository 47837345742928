import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromSessionStorage,
    removeItemFromSessionStorage,
} from '@/services/webStorage/sessionStorage';

function removeAuthTokenFromSessionStorage(): void {
    removeItemFromSessionStorage(WEB_STORAGE_KEYS.firebase_token);
}

function checkAuthTokenInSessionStorage(): boolean {
    const firebaseToken = getItemFromSessionStorage<string>(
        WEB_STORAGE_KEYS.firebase_token,
    );

    return !!firebaseToken;
}

export { removeAuthTokenFromSessionStorage, checkAuthTokenInSessionStorage };
