import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ClockIcon({
    borderColor = COLOR.gray,
    height = 25,
    width = 25,
    ...props
}: Props) {
    return (
        <svg height={height} viewBox="0 0 52 32" width={width} {...props}>
            <path
                fill={borderColor}
                d="M34.615 1.743c7.844 0 14.319 6.35 14.319 14.319s-6.35 14.319-14.319 14.319h-18.303c-7.844 0-14.319-6.35-14.319-14.319s6.475-14.319 14.319-14.319h18.303zM34.615 0.498h-18.303c-8.591 0-15.564 6.973-15.564 15.44 0 8.591 6.973 15.564 15.564 15.564h18.303c8.591 0 15.564-6.973 15.564-15.564s-6.973-15.44-15.564-15.44v0z"
            />
            <path d="M25.401 7.097c-1.245 0-2.366 0.249-3.486 0.747-2.117 0.872-3.86 2.615-4.856 4.856s-0.996 4.732 0 6.973c0.872 2.117 2.615 3.86 4.856 4.856 1.121 0.498 2.241 0.747 3.486 0.747 0 0 0 0 0 0s0 0 0 0v0c0 0 0 0 0 0s0 0 0 0c1.245 0 2.366-0.249 3.486-0.747 2.117-0.872 3.86-2.615 4.856-4.856s0.996-4.732 0-6.973c-0.872-2.241-2.615-3.984-4.732-4.856-1.121-0.498-2.366-0.747-3.611-0.747v0zM25.401 23.907c-1.992 0-3.984-0.872-5.354-2.241-1.494-1.494-2.241-3.486-2.241-5.479 0-4.233 3.486-7.72 7.72-7.72 0 0 0 0 0 0 4.233 0 7.72 3.486 7.72 7.72s-3.486 7.72-7.72 7.72c0 0-0.125 0-0.125 0s0 0 0 0v0zM26.148 11.331h-1.37v5.105l3.984 4.109 0.996-0.996-3.611-3.735v-4.482z" />
        </svg>
    );
}

export default memo(ClockIcon);
