import { LogEvent } from '../../types/analytics';
import { InvoiceModel } from '../../types/Invoice';
import { ExportInvoicesFormatType } from '../../types/Invoicing';

import { InvoicesEvents } from './events';
import { OrganisationAnalytics } from '../organisation';

function logUserSelectedInvoiceTaxIncluded(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userSelectedInvoiceTaxIncluded);
}

function logUserSelectedInvoiceTaxExcluded(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userSelectedInvoiceTaxExcluded);
}

function logUserAttachedInvoiceReport(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userAttachedInvoiceReport);
}

function logUserCreatedInvoice(
    logEvent: LogEvent,
    params: { invoice: InvoiceModel; isOwner: boolean | null },
) {
    logEvent(InvoicesEvents.userCreatedInvoice, {
        ...params.invoice._raw,
    });

    if (params.invoice.applyTaxes) {
        logUserSelectedInvoiceTaxIncluded(logEvent);
    } else {
        logUserSelectedInvoiceTaxExcluded(logEvent);
    }

    if (params.invoice.attachReports) {
        logUserAttachedInvoiceReport(logEvent);
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedInvoice(logEvent);
    }
}

function logUserUpdatedInvoice(
    logEvent: LogEvent,
    params: {
        invoice: InvoiceModel;
        previousAttachReports: boolean;
    },
) {
    logEvent(InvoicesEvents.userUpdatedInvoice, {
        ...params.invoice._raw,
    });

    if (!params.previousAttachReports && params.invoice.attachReports) {
        logUserAttachedInvoiceReport(logEvent);
    }
}

function logUserDeletedInvoice(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userDeletedInvoice);
}

function logUserAuthorizedInvoice(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userAuthorizedInvoice);
}

function logUserClickedSendInvoiceButton(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userClickedSendInvoiceButton);
}

function logUserClickedViewInvoiceButton(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userClickedViewInvoiceButton);
}

function logUserClickedSMSButton(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userClickedSMSButton);
}

function logUserSetInvoiceToPaid(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userSetInvoiceToPaid);
}

function logUserExportedInvoices(
    logEvent: LogEvent,
    params: { format: ExportInvoicesFormatType },
) {
    logEvent(InvoicesEvents.userExportedInvoices, {
        format: params.format,
    });
}

function logUserFilteredInvoices(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userFilteredInvoices);
}

function logUserUsedInvoicesSearchBar(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userUsedInvoicesSearchBar);
}

function logUserConnectedWithAccountingProvider(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userConnectedWithAccountingProvider);
}

function logUserConnectedWithXero(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userConnectedWithXero);
}

function logUserConnectedWithQuickbooks(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userConnectedWithQuickbooks);
}

function logUserDisconnectedWithAccountingProvider(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userDisconnectedAccountingProvider);
}

function logUserDisconnectedWithXero(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userDisconnectedWithXero);
}

function logUserDisconnectedWithQuickbooks(logEvent: LogEvent) {
    logEvent(InvoicesEvents.userDisconnectedWithQuickbooks);
}

export const InvoicesAnalytics = {
    logUserCreatedInvoice,
    logUserUpdatedInvoice,
    logUserDeletedInvoice,
    logUserAuthorizedInvoice,
    logUserClickedSendInvoiceButton,
    logUserClickedViewInvoiceButton,
    logUserClickedSMSButton,
    logUserSetInvoiceToPaid,
    logUserExportedInvoices,
    logUserFilteredInvoices,
    logUserUsedInvoicesSearchBar,
    logUserConnectedWithAccountingProvider,
    logUserConnectedWithXero,
    logUserConnectedWithQuickbooks,
    logUserDisconnectedWithAccountingProvider,
    logUserDisconnectedWithXero,
    logUserDisconnectedWithQuickbooks,
};
