import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import {
    EntryProcedureModel,
    EntryProcedurePayload,
} from '../../types/EntryProcedure';

class EntryProcedure {
    private database: Database;
    private collection: Collection<EntryProcedureModel>;
    private table = 'entry_procedures';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getAllQuery() {
        return this.collection.query();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: EntryProcedurePayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdEntryProcedure = await this.collection.create(
                (entryProcedure) => {
                    entryProcedure.entryId = payload.entryId;
                    entryProcedure.procedureId = payload.procedureId;
                    entryProcedure.price = payload.price;
                    entryProcedure.quantity = payload.quantity;
                    entryProcedure.name = payload.name;
                    entryProcedure.userId = userId;
                    entryProcedure.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create entry procedure',
                modelName: this.table,
                payload: createdEntryProcedure,
            });
        });
    }

    async update(id: string, payload: EntryProcedurePayload) {
        const entryProcedureElement = await this.getByID(id);

        await this.database.write(async () => {
            await entryProcedureElement.update((entryProcedure) => {
                entryProcedure.entryId = payload.entryId;
                entryProcedure.procedureId = payload.procedureId;
                entryProcedure.price = payload.price;
                entryProcedure.quantity = payload.quantity;
                entryProcedure.name = payload.name;
                entryProcedure.userId = entryProcedureElement.userId;
                entryProcedure.organisationId =
                    entryProcedureElement.organisationId;
            });

            this.options.logDBAction({
                message: 'Update entry procedure',
                modelName: this.table,
                payload: entryProcedureElement,
            });
        });
    }

    async bump(ids: string[]) {
        await this.database.write(async () => {
            const procedureElements = await Promise.all(
                ids.map(async (id) => this.getByID(id)),
            );

            const updates = procedureElements.map((procedureElement) =>
                procedureElement.prepareUpdate((procedure) => {
                    procedure.name = procedureElement.name;
                }),
            );

            this.database.batch(...updates);

            this.options.logDBAction({
                message: 'Bump entry procedures',
                modelName: this.table,
                payload: updates,
            });
        });
    }

    async deleteByID(id: string) {
        const entryProcedureElement = await this.getByID(id);

        await this.database.write(async () => {
            await entryProcedureElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete entry procedure',
                modelName: this.table,
                payload: entryProcedureElement,
            });
        });
    }
}

export default EntryProcedure;
