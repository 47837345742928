import React, { memo } from 'react';

import { useImagesContext } from '@/context/ImagesContext';

import { ContactsListItem } from './components';
import { Props } from './types';

function ContactsList({
    clickable,
    multiSelect = true,
    selectCondition,
    conditionWarning,
    items,
    testIdPrefix,
    selectable,
    selectedItems,
    onItemClick,
    onRemove,
    removable,
    itemWrapperStyle,
    size = 'normal',
    displayContactInformation,
    renderExtraContentComponent,
}: Props) {
    const { images, ImagesService } = useImagesContext();

    return (
        <>
            {items.map((contact) => {
                const avatarUrl = ImagesService.getSingleImageByEntityId(
                    images,
                    contact.id,
                )?.imageURL;

                return (
                    <ContactsListItem
                        clickable={clickable}
                        testID={`${testIdPrefix}-ContactsListItem`}
                        key={contact.id}
                        contact={contact}
                        onRemove={onRemove}
                        isSelected={selectedItems && selectedItems.has(contact)}
                        onClick={onItemClick}
                        selectable={selectable}
                        removable={removable}
                        size={size}
                        wrapperStyles={itemWrapperStyle}
                        avatarUrl={avatarUrl}
                        multiSelect={multiSelect}
                        selectCondition={selectCondition}
                        conditionWarning={conditionWarning}
                        displayContactInformation={displayContactInformation}
                        renderExtraContentComponent={
                            renderExtraContentComponent
                        }
                    />
                );
            })}
        </>
    );
}

export default memo(ContactsList) as (props: Props) => JSX.Element;
