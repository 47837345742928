import { useMemo } from 'react';

import { useImagesContext } from '@/context/ImagesContext';

export function useEntityImage(id: string) {
    const { ImagesService, images } = useImagesContext();
    const image = useMemo(
        () => ImagesService.getSingleImageByEntityId(images, id),
        [images, id, ImagesService],
    );

    return image;
}
