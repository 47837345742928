import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGroupedProcedures, ProceduresGroup } from 'shared/utils/procedures';

import { CollapsibleContainer, EmptyState, TableSkeleton } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { COLOR } from '@/theme/colors';

import { ProceduresTable } from './components';
import { Props } from './types';

function ProceduresGroupedList({ procedures }: Props) {
    const { t } = useTranslation();
    const { isInitialSyncInProgress } = useDBSyncContext();

    const groupedProcedures = useMemo<ProceduresGroup[]>(
        () => getGroupedProcedures(procedures),
        [procedures],
    );

    const showLoadingState =
        (!procedures?.length && isInitialSyncInProgress) ||
        isInitialSyncInProgress;

    return (
        <Box>
            {groupedProcedures.map((proceduresGroup) => (
                <CollapsibleContainer
                    testID={`ProceduresPage-${proceduresGroup.title}`}
                    title={proceduresGroup.title}
                    key={proceduresGroup.title}
                    auxiliaryElement={
                        <Typography color={COLOR.paleSky}>
                            {(
                                proceduresGroup.procedures.length ?? 0
                            ).toString()}
                        </Typography>
                    }
                >
                    <Box>
                        {showLoadingState ? (
                            <TableSkeleton />
                        ) : proceduresGroup.procedures.length ? (
                            <ProceduresTable
                                procedures={proceduresGroup.procedures}
                            />
                        ) : (
                            <EmptyState
                                testID="ProceduresTable-EmptyStateMessage"
                                message={t(
                                    'Procedures:no_procedures_have_been_added',
                                )}
                            />
                        )}
                    </Box>
                </CollapsibleContainer>
            ))}
        </Box>
    );
}

export default ProceduresGroupedList;
