export type ProcedureCategory = 'shoe' | 'trim' | 'other';

export enum CATEGORY_HUMANIZED {
    shoe = 'Shoe',
    trim = 'Trim',
    other = 'Other',
}

export const CATEGORIES = [
    CATEGORY_HUMANIZED.shoe,
    CATEGORY_HUMANIZED.trim,
    CATEGORY_HUMANIZED.other,
];

export const CATEGORY_DICTIONARY: {
    [key: string]: ProcedureCategory;
} = {
    [CATEGORY_HUMANIZED.shoe]: 'shoe',
    [CATEGORY_HUMANIZED.trim]: 'trim',
    [CATEGORY_HUMANIZED.other]: 'other',
};
