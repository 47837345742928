import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

const CreateTeamIcon = ({
    color = COLOR.white,
    height = 20,
    width = 20,
}: Props) => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 47.6 34.3"
        width={width}
        height={height}
        fill={color}
    >
        <g id="Group_477">
            <g>
                <defs>
                    <rect
                        id="SVGID_1_"
                        x="0.7"
                        y="0.5"
                        width="46.3"
                        height="27.1"
                    />
                </defs>

                <g id="Group_475" transform="translate(0 0)">
                    <path
                        id="Path_383"
                        d="M40.1,12.8c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5C35.7,10.8,37.7,12.8,40.1,12.8
                    C40.1,12.8,40.1,12.8,40.1,12.8"
                    />
                    <path
                        id="Path_384"
                        d="M7.5,12.8c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5C5,3.8,3,5.8,3,8.3C3,10.8,5,12.8,7.5,12.8
                    C7.5,12.8,7.5,12.8,7.5,12.8"
                    />
                    <path
                        id="Path_385"
                        d="M7.5,14c-2.3,0-6.8,1.9-6.8,5.8v2.9h10.7c0.2-2,1-3.8,2.4-5.3C12.3,15.4,10,14.1,7.5,14"
                    />
                    <path
                        id="Path_386"
                        d="M40.1,14c-2.5,0.1-4.8,1.4-6.2,3.4c1.3,1.5,2.2,3.3,2.4,5.3H47v-2.9C47,15.9,42.4,14,40.1,14"
                    />
                    <path
                        id="Path_387"
                        d="M23.8,13.2c3.5,0,6.4-2.9,6.4-6.4s-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4l0,0C17.4,10.4,20.3,13.2,23.8,13.2
                    C23.8,13.2,23.8,13.2,23.8,13.2"
                    />
                    <path
                        d="M23.8,15L23.8,15c-3.3,0-9.8,2.8-9.8,8.3v4.2h2.5c-0.2-0.7-0.3-1.4-0.3-2.1c0-4.2,3.4-7.7,7.7-7.7c4.2,0,7.7,3.4,7.7,7.7
                    c0,0.7-0.1,1.5-0.3,2.1h2.4v-4.2C33.6,17.8,27.1,15,23.8,15z"
                    />
                </g>
            </g>
        </g>
        <path
            id="svgexport-17-2"
            d="M23.9,18.8c3.7,0,6.6,3,6.6,6.6c0,3.7-3,6.6-6.6,6.6s-6.6-3-6.6-6.6C17.3,21.7,20.2,18.8,23.9,18.8
         M23.9,17.5c-4.4,0-7.9,3.5-7.9,7.9s3.5,7.9,7.9,7.9s7.9-3.5,7.9-7.9C31.8,21,28.3,17.5,23.9,17.5L23.9,17.5z"
        />
        <path
            d="M27.1,26.2h-2.4v2.4c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8v-2.4h-2.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
        h2.4v-2.4c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v2.4h2.4c0.4,0,0.8,0.4,0.8,0.8C27.9,25.8,27.5,26.2,27.1,26.2L27.1,26.2z"
        />
    </svg>
);

export default CreateTeamIcon;
