import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ProceduresBadge({ procedures }: Props) {
    if (!procedures.length) {
        return (
            <div className="flex flex-row flex-1">
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: '19.5px',
                        color: COLOR.cranberry,
                    }}
                >
                    {t('EntriesList:no_procedures_added')}
                </Typography>
            </div>
        );
    }

    if (procedures.length > 3) {
        const slicedProcedures = procedures.slice(0, 3);
        const plus = procedures.length - slicedProcedures.length;

        return (
            <div className="flex flex-row flex-1">
                {slicedProcedures.map((parsedProcedure) => (
                    <div
                        key={parsedProcedure.id}
                        className="border-[1px] border-proceduresGrey rounded-md px-[6px] py-[4px] mr-[5px]"
                    >
                        <Typography
                            className="max-w-[35px] xl:max-w-[64px] 2xl:max-w-[80px] text-ellipsis overflow-hidden whitespace-nowrap"
                            sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: '19.5px',
                                color: COLOR.accountSetupBlack,
                            }}
                        >
                            {parsedProcedure.name}
                        </Typography>
                    </div>
                ))}
                <div
                    key={procedures[3].id}
                    className="border-[1px] border-proceduresGrey rounded-md px-[6px] py-[4px] mr-[5px]"
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            lineHeight: '19.5px',
                            color: COLOR.accountSetupBlack,
                        }}
                    >
                        {`+${plus}`}
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row flex-1">
            {procedures.map((parsedProcedure) => (
                <div
                    key={parsedProcedure.id}
                    className="border-[1px] border-proceduresGrey rounded-md px-[6px] py-[4px] mr-[5px]"
                >
                    <Typography
                        className="max-w-[45px] xl:max-w-[77px] 2xl:max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
                        sx={{
                            fontSize: 12,
                            fontWeight: 400,
                            lineHeight: '19.5px',
                            color: COLOR.accountSetupBlack,
                        }}
                    >
                        {parsedProcedure.name}
                    </Typography>
                </div>
            ))}
        </div>
    );
}

export default ProceduresBadge;
