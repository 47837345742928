import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import User from './User';
import { EntryProductModel } from '../../types/EntryProduct';
import { InventoryProductModel } from '../../types/InventoryProduct';
import {
    ProductGroup,
    ProductModel,
    ProductPayload,
    ProductType,
} from '../../types/Products';
import { RegionModel } from '../../types/Region';
import { ProductDBServiceOptions } from '../../types/dbService';
import { ProductsFilterOptions } from '../../types/productFilters';
import {
    applyProductsFiltersQueries,
    getProductsByGroupsQuery,
    getProductsSortQuery,
    getUnsafeProductsSearchQueries,
} from '../utils';
import { getDuplicatesFreeArrayByKey } from '../../utils/array';
import { FILTER_TYPE } from 'shared/types/filter';

class Product {
    private database: Database;
    private collection: Collection<ProductModel>;
    private table = 'products';
    private options: ProductDBServiceOptions;

    constructor(options: ProductDBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    countAll() {
        return this.collection.query().fetchCount();
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByIDs(ids: string[]) {
        if (!ids.length) {
            return new Promise<ProductModel[]>((resolve) => resolve([]));
        }

        return this.collection
            .query(Q.or(...ids.map((id) => Q.where('id', id))))
            .fetch();
    }

    async getAllBrands(type: ProductType) {
        const typeProducts = await this.collection
            .query(Q.where('product_type', type), Q.sortBy('brand'))
            .fetch();

        const brands = getDuplicatesFreeArrayByKey(typeProducts, 'brand');
        return brands.map((prod) => prod.brand || '');
    }

    getFilterProductsSearchLokiQuery(searchText: string) {
        const unsafeSearchQueries = getUnsafeProductsSearchQueries(searchText);

        // @ts-ignore
        return Q.unsafeLokiTransform((raws, loki) => {
            let result = raws;

            unsafeSearchQueries.forEach(
                (query) => (result = query(result, loki)),
            );

            return result;
        });
    }

    getFilterProductsSearchSQLQuery(searchText: string) {
        const parsedSearchText = searchText.toLowerCase().trim();

        const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);

        if (!sanitizedSearch.length) return;

        const SQLiteProductQuery = `brand || iif(family IS NULL, '', ' ') || ifnull(family, '') || iif(material IS NULL, '', ' ') || ifnull(material, '') || iif(shape IS NULL, '', ' ') || ifnull(shape, '') || iif(clips IS NULL, '', ' ') || ifnull(clips, '') LIKE`;

        const queries = [
            Q.unsafeSqlExpr(`${SQLiteProductQuery} '${sanitizedSearch}%'`),
            Q.unsafeSqlExpr(`${SQLiteProductQuery} '% ${sanitizedSearch}%'`),
            Q.where('name', Q.like(`${sanitizedSearch}%`)),
            Q.where('name', Q.like(`% ${sanitizedSearch}%`)),
        ];

        return Q.or(
            // @ts-ignore
            ...queries,
        );
    }

    async getFilteredProducts({
        searchText,
        filters,
        filterType,
        userId,
    }: ProductsFilterOptions) {
        const queries: Q.Clause[] = [];

        if (filters) {
            const filterQueries = await applyProductsFiltersQueries(
                filters,
                this.options,
            );
            filterQueries.forEach((filterQuery) => queries.push(filterQuery));
        }

        const searchQuery =
            filterType === FILTER_TYPE.LOKI
                ? this.getFilterProductsSearchLokiQuery(searchText)
                : this.getFilterProductsSearchSQLQuery(searchText);

        if (searchQuery) {
            queries.push(searchQuery);
        }

        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const userRegion = await userService.getUserRegion(userId);

        const productsInUserRegionQueries = [
            Q.experimentalJoinTables(['product_sales_units']),
            Q.experimentalNestedJoin(
                'product_sales_units',
                'product_sales_unit_regions',
            ),
            Q.on(
                'product_sales_units',
                Q.on(
                    'product_sales_unit_regions',
                    Q.where('region_id', Q.eq(userRegion.id)),
                ),
            ),
        ];

        const availableProductsQuery = [
            Q.or(
                Q.where('not_available', null),
                Q.where('not_available', false),
            ),
        ];

        const products = await this.collection
            .query(
                ...queries,
                ...productsInUserRegionQueries,
                ...availableProductsQuery,
                ...getProductsSortQuery(),
            )
            .fetch();

        return products;
    }

    getByGroups(groups: ProductGroup[]) {
        return this.collection
            .query(getProductsByGroupsQuery(groups), ...getProductsSortQuery())
            .fetch();
    }

    async isProductFromUserRegion(
        product: ProductModel,
        regions: Collection<RegionModel>,
        userRegion: RegionModel,
    ) {
        const productSalesUnits = await product?.productSalesUnits;

        if (productSalesUnits && productSalesUnits.length !== 0) {
            for (const productSalesUnit of productSalesUnits) {
                const unitRegions = await regions
                    .query(
                        Q.on(
                            'product_sales_unit_regions',
                            Q.where(
                                'product_sales_unit_id',
                                productSalesUnit.id,
                            ),
                        ),
                    )
                    .fetch();

                if (
                    unitRegions.length !== 0 &&
                    unitRegions.some(
                        (unitRegion) => unitRegion.id === userRegion.id,
                    )
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    async getProductsBasedOnUserRegionAndProductType(
        products:
            | EntryProductModel[]
            | InventoryProductModel[]
            | ProductModel[],
        type: string | null,
        userId: string,
    ): Promise<ProductModel[]> {
        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const userRegion = await userService.getUserRegion(userId);

        const regions = await this.database.collections.get<RegionModel>(
            'regions',
        );

        const availableProducts = await Promise.all(
            products?.map(async (prod) => {
                try {
                    let product: ProductModel;

                    if (prod.productId) {
                        product = await this.getByID(
                            prod?.productId || prod?.id,
                        );
                    } else {
                        product = prod;
                    }

                    if (!product || (type && product?.productType !== type))
                        return null;

                    if (
                        type === ProductType.custom ||
                        (await this.isProductFromUserRegion(
                            product,
                            regions,
                            userRegion,
                        ))
                    ) {
                        return product;
                    }
                    return null;
                } catch (err) {
                    this.options.logError(
                        `Error while parsing product: ${err}`,
                    );

                    return null;
                }
            }),
        );

        return availableProducts
            .filter((prod) => prod !== null)
            .map((prod) => prod!);
    }

    async add(payload: ProductPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const createdProduct = await this.collection.create((product) => {
                product.name = payload.name;
                product.productType = payload.productType;
                product.brand = payload.brand;
                product.family = payload.family;
                product.shape = payload.shape;
                product.dimensions = payload.dimensions;
                product.clips = payload.clips;
                product.shoeSize = payload.shoeSize;
                product.nailSize = payload.nailSize;
                product.skuApac = payload.skuApac;
                product.skuEmea = payload.skuEmea;
                product.uomDealers = payload.uomDealers;
                product.material = payload.material;
                product.shoeSizeSortNum = payload.shoeSizeSortNum;
                product.userId = userId;
                product.organisationId = organisationID;
                product.notAvailable = payload.notAvailable;
            });

            this.options.logDBAction({
                message: 'Create product',
                modelName: this.table,
                payload: createdProduct,
            });

            return createdProduct;
        });
    }
}

export default Product;
