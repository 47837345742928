import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';
import {
    checkAuthTokenInLocalStorage,
    removeAuthTokenFromLocalStorage,
} from '@/services/webStorage/localStorage/authTokens';
import {
    getItemFromSessionStorage,
    storeItemInSessionStorage,
} from '@/services/webStorage/sessionStorage';
import {
    removeAuthTokenFromSessionStorage,
    checkAuthTokenInSessionStorage,
} from '@/services/webStorage/sessionStorage/authTokens';
import { AuthTokensParams } from '@/types/auth';

function removeAuthToken(): void {
    removeAuthTokenFromSessionStorage();
    removeAuthTokenFromLocalStorage();
}

function saveAuthToken({
    firebaseToken,
    rememberUser,
}: AuthTokensParams): void {
    let storeItemInStorageCallback = storeItemInSessionStorage;

    if (rememberUser) {
        storeItemInStorageCallback = storeItemInLocalStorage;
    }

    storeItemInStorageCallback(WEB_STORAGE_KEYS.firebase_token, firebaseToken);
}

function checkAuthTokens(): boolean {
    const isTokenPresentInSessionStorage = checkAuthTokenInSessionStorage();
    const isTokenPresentInLocalStorage = checkAuthTokenInLocalStorage();

    return isTokenPresentInSessionStorage || isTokenPresentInLocalStorage;
}

function getAuthToken(): string | null {
    const firebaseTokenFromLocalStorage = getItemFromLocalStorage<string>(
        WEB_STORAGE_KEYS.firebase_token,
    );
    const firebaseTokenFromSessionStorage = getItemFromSessionStorage<string>(
        WEB_STORAGE_KEYS.firebase_token,
    );

    return firebaseTokenFromLocalStorage || firebaseTokenFromSessionStorage;
}

export { removeAuthToken, saveAuthToken, checkAuthTokens, getAuthToken };
