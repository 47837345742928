import { Box, Skeleton } from '@mui/material';
import React from 'react';

function InventoryListSkeletonItem() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Skeleton variant="rectangular" height={64} width={64} />
                <Box>
                    <Skeleton variant="text" height={28} width={150} />
                    <Skeleton variant="text" height={28} width={150} />
                </Box>
            </Box>
            <Skeleton variant="text" width="100%" />
        </Box>
    );
}

export default InventoryListSkeletonItem;
