import { Collection, Database, Q } from '@nozbe/watermelondb';
import { map } from 'rxjs/operators';

import { DBServiceOptionsWithImages } from '../../types/dbService';
import { EntryUserModel, EntryUserPayload } from '../../types/EntryUser';

import Organisation from './Organisation';

class EntryUser {
    private database: Database;
    private collection: Collection<EntryUserModel>;
    private table = 'entry_users';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByEntryId(entryId: string) {
        return this.collection.query(Q.where('entry_id', entryId));
    }

    getActiveEntryUsersIdsById(entryId: string) {
        return this.collection
            .query(
                Q.experimentalNestedJoin('entry_users', 'users'),
                Q.experimentalNestedJoin('users', 'organisation_users'),
                Q.and(
                    Q.where('entry_id', entryId),
                    Q.on(
                        'users',
                        Q.on('organisation_users', Q.where('active', true)),
                    ),
                ),
            )
            .observe()
            .pipe(
                map((entryUsers: EntryUserModel[]) =>
                    entryUsers.map((entryUser) => entryUser.userId),
                ),
            );
    }

    async deleteAllUsersByEntryId(entryId: string) {
        await this.collection
            .query(Q.where('entry_id', entryId))
            .markAllAsDeleted();
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: EntryUserPayload) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();

        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const createdEntryUser = await this.collection.create(
                (entryUser) => {
                    entryUser.entryId = payload.entryId;
                    entryUser.userId = payload.userId;
                    entryUser.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create entry user',
                modelName: this.table,
                payload: createdEntryUser,
            });

            return createdEntryUser;
        });
    }

    async prepareAddBatch(membersIds: string[], entryId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();

        const { id: organisationID } = organisation[0];

        return membersIds.map((userId) =>
            this.collection.prepareCreate((entryUser) => {
                entryUser.entryId = entryId;
                entryUser.userId = userId;
                entryUser.organisationId = organisationID;
            }),
        );
    }

    async prepareDeleteBatch(membersIds: string[]) {
        return Promise.all(
            membersIds.map(async (userId) => {
                const entryUser = await this.getByID(userId);
                return entryUser.prepareMarkAsDeleted();
            }),
        );
    }

    async deleteByID(id: string) {
        const entryUser = await this.getByID(id);

        await this.database.write(async () => {
            await entryUser.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete entry user',
                modelName: this.table,
                payload: entryUser,
            });
        });
    }
}

export default EntryUser;
