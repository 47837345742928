import apiClient from '@/services/networking/apiClient';
import {
    GetOrganisationMembersParams,
    GetOrganisationMembersPayload,
    GetOrganisationInvitationResponsePayload,
    GetOrganisationsResponsePayload,
    UpdateMemberRoleParams,
} from '@/types/organisations';

async function getOrganisations(): Promise<GetOrganisationsResponsePayload> {
    const { data } = await apiClient.get<GetOrganisationsResponsePayload>(
        '/organisations',
    );

    return data;
}

async function getMembers(params: GetOrganisationMembersParams) {
    const { data } = await apiClient.get<GetOrganisationMembersPayload>(
        `/organisations/${params.organisationId}`,
    );

    return data;
}

async function getOrganisationInvitation(): Promise<GetOrganisationInvitationResponsePayload> {
    const { data } =
        await apiClient.post<GetOrganisationInvitationResponsePayload>(
            '/invitations/create',
        );

    return data;
}

async function updateMemberRole(params: UpdateMemberRoleParams) {
    const { memberId, organisationId, userRole } = params;
    const { data } = await apiClient.put(
        `/organisations/${organisationId}/users/${memberId}`,
        { user_role: userRole },
    );

    return data;
}

export const OrganisationsAPI = {
    getOrganisations,
    getMembers,
    getOrganisationInvitation,
    updateMemberRole,
};
