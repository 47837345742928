import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import { TeamIcon } from '@/assets/svg';
import DeleteIcon from '@/assets/svg/DeleteIcon/DeleteIcon';
import { Badge } from '@/components';
import { useEntityImage } from '@/hooks/useEntityImage';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function UserTeamInvitationItem({
    id,
    name,
    ownerName,
    onAccept,
    onDecline,
    onDismiss,
    disabled,
    isDeclined,
}: Props) {
    const image = useEntityImage(id);
    const avatarURL = image?.imageURL || '';

    return (
        <Box
            sx={{
                flexDirection: 'row',
                backgroundColor: COLOR.backgroundGrey,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                justifyContent: 'space-between',
                mt: '20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <Avatar
                    sx={{
                        height: '64px',
                        width: '64px',
                        borderRadius: '8px',
                        backgroundColor: COLOR.eclipse,
                    }}
                    variant={'square'}
                    src={avatarURL}
                >
                    <TeamIcon height={40} width={40} color={'grey'} />
                </Avatar>
                <Box
                    sx={{
                        marginLeft: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {!isDeclined ? (
                            <Badge
                                sx={{ width: 10, height: 10 }}
                                isCircle
                                backgroundColor={COLOR.cranberry}
                            />
                        ) : null}

                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                marginBottom: '3px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '250px',
                            }}
                        >
                            {name}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 12,
                            color: isDeclined ? COLOR.black : '#212B36',
                            opacity: !isDeclined ? '40%' : undefined,
                        }}
                    >
                        {isDeclined
                            ? t('Organisations:labels:request_declined')
                            : t('TeamsPage:team_owned_by', {
                                  owner: ownerName,
                              })}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    pr: '16px',
                }}
            >
                {!isDeclined ? (
                    <>
                        <Button
                            disabled={disabled}
                            sx={{
                                color: COLOR.white,
                                backgroundColor: ENTITY_COLOR.teams,
                                borderRadius: '3px',
                                height: '28px',
                                fontWeight: 'normal',
                                mr: '16px',
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.teams,
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={onAccept}
                        >
                            {disabled ? (
                                <CircularProgress color="inherit" size={10} />
                            ) : (
                                t('Actions:Accept')
                            )}
                        </Button>
                        <Box
                            sx={{
                                '&:hover': {
                                    cursor: disabled ? 'auto' : 'pointer',
                                },
                                opacity: disabled ? 0.5 : 1,
                            }}
                            onClick={disabled ? undefined : onDecline}
                        >
                            <DeleteIcon />
                        </Box>
                    </>
                ) : (
                    <Box
                        sx={{
                            '&:hover': {
                                cursor: disabled ? 'auto' : 'pointer',
                            },
                            opacity: disabled ? 0.5 : 1,
                        }}
                        onClick={disabled ? undefined : onDismiss}
                    >
                        <DeleteIcon />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default UserTeamInvitationItem;
