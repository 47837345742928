import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

const AddToTeamIcon = ({
    color = COLOR.white,
    height = 20,
    width = 20,
}: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30.1 21.4"
        xmlSpace="preserve"
        width={width}
        height={height}
        fill={color}
    >
        <path d="M7.8 10.1c2.8 0 5.1-2.3 5.1-5.1s-2.4-5-5.1-5-5.1 2.3-5.1 5.1c0 2.7 2.3 5 5.1 5M7.8 11.5c-2.6 0-7.8 2.2-7.8 6.6v3.3h15.5v-3.3c0-4.4-5.2-6.6-7.7-6.6M23 4.4c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1c0-3.9-3.1-7.1-7.1-7.1zm0 13.1c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" />
        <path d="M25.4 10.9h-1.8V9.1c0-.3-.3-.6-.6-.6s-.6.3-.6.6v1.8h-1.8c-.3 0-.6.3-.6.6s.3.6.6.6h1.8v1.8c0 .3.3.6.6.6s.6-.3.6-.6v-1.8h1.8c.3 0 .6-.3.6-.6s-.3-.6-.6-.6z" />
    </svg>
);

export default AddToTeamIcon;
