// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model } from '@nozbe/watermelondb';
import { field, date, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Location extends Model {
    static table = 'locations';

    @field('place_id') placeID;
    @field('name') name;
    @field('lat') lat;
    @field('lng') lng;
    @field('url') url;
    @field('utc_offset') utcOffset;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
