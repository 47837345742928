import { Collection, Database, Q } from '@nozbe/watermelondb';

import User from './User';
import { ProductSalesUnitModel } from '../../types/ProductSalesUnit';
import { ProductSalesUnitDBServiceOptions } from '../../types/dbService';
import { ProductModel } from '../../types/Products';
import { BoxUnitSizesType } from '../../types/ShoppingList';

class ProductSalesUnit {
    private database: Database;
    private collection: Collection<ProductSalesUnitModel>;
    private table = 'product_sales_units';
    private options: ProductSalesUnitDBServiceOptions;

    constructor(options: ProductSalesUnitDBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByProductID(productId: string) {
        return this.collection.query(Q.where('product_id', productId)).fetch();
    }

    async createRegionQuery(userId: string) {
        const userService = new User({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const region = await userService.getUserRegion(userId);

        const query: Q.Clause[] = [];

        if (region) {
            query.push(
                Q.on(
                    'product_sales_unit_regions',
                    Q.where('region_id', region.id),
                ),
            );
            query.push(
                Q.on(
                    'products',
                    Q.or(
                        Q.where('not_available', null),
                        Q.where('not_available', false),
                    ),
                ),
            );
        }
        return query;
    }

    async getByUserRegion(userId: string) {
        const queries = await this.createRegionQuery(userId);

        if (!queries.length) {
            return [];
        }

        return await this.collection.query(...queries).fetch();
    }

    async getProductsByUserRegion(userId: string) {
        const productSalesUnit = await this.getByUserRegion(userId);
        const productSalesUnitIds = productSalesUnit.map((p) => p.productId);

        return await this.database.collections
            .get<ProductModel>('products')
            .query(Q.where('id', Q.oneOf([...productSalesUnitIds])))
            .fetch();
    }

    async getByUserRegionAndID(userId: string, salesUnitId: string) {
        const queries = await this.createRegionQuery(userId);

        if (!queries.length) {
            return [];
        }

        return await this.collection
            .query(...queries, Q.where('id', salesUnitId))
            .fetch();
    }

    async getByUserRegionAndProductID(userId: string, productId: string) {
        const queries = await this.createRegionQuery(userId);

        if (!queries.length) {
            return [];
        }

        return await this.collection
            .query(...queries, Q.where('product_id', productId))
            .fetch();
    }

    async getBoxUnitSizesByUserRegion(
        userId: string,
    ): Promise<Record<string, BoxUnitSizesType>> {
        const queries = await this.createRegionQuery(userId);

        if (!queries.length) {
            return {};
        }

        const salesUnits = await this.collection.query(...queries).fetch();

        return salesUnits.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.productId]: {
                    ...acc[curr.productId],
                    [curr.id]: curr.boxUnitSize,
                },
            };
        }, {});
    }
}

export default ProductSalesUnit;
