import * as yup from 'yup';

import { PHONE_REGEXP_WITHOUT_PREFIX } from '@/constants/regex';
import { t } from '@/services/translations/config';

const addEditHorseForm = yup.object().shape({
    foalDate: yup.date().typeError(t('AddHorseForm:error:date_validation')),
    name: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('AddHorseForm:labels:name'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('AddHorseForm:labels:name'),
            }),
        ),
    stablePhone: yup.object().shape({
        full: yup.string(),
        number: yup.string().when(['full', 'prefix'], {
            is: (full, prefix) => {
                return (
                    full?.length > 1 &&
                    (full?.length > prefix?.length || full !== prefix)
                );
            },
            then: (schema) => {
                return schema
                    .matches(
                        PHONE_REGEXP_WITHOUT_PREFIX,
                        t('ContactsList:error:phone_must_be_valid_phone'),
                    )
                    .required(
                        t('ContactsList:error:phone_must_be_valid_phone'),
                    );
            },
            otherwise: (schema) => {
                return schema.nullable(true);
            },
        }),
        prefix: yup.string(),
    }),
});

export const HorseValidationSchema = { addEditHorseForm };
