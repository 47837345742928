import { AddCircleOutline } from '@mui/icons-material';
import { Box, Typography, Button as MuiButton } from '@mui/material';
import React from 'react';

import CreateTeamIcon from '@/assets/svg/CreateTeamIcon';
import { Button } from '@/components';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function CreateTeamInfo({ onCreateTeam }: Props) {
    return (
        <Box
            sx={{
                borderRadius: '8px',
                backgroundColor: '#F5F6F7',
                display: 'flex',
                px: '15px',
                py: '15px',
                width: '460px',
                maxHeight: '314px',
                flex: 1,
            }}
        >
            <Box>
                <Box
                    sx={{
                        backgroundColor: ENTITY_COLOR.teams,
                        borderRadius: '8px',
                        width: '60px',
                        height: '60px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        pt: '5px',
                    }}
                >
                    <CreateTeamIcon width={50} height={50} />
                </Box>
            </Box>
            <Box
                sx={{
                    px: '18px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    mt: '-5px',
                }}
            >
                <Box
                    sx={{
                        flexDirection: 'column',
                        mb: '25px',
                    }}
                >
                    <Typography
                        sx={{ fontSize: 22, fontWeight: 'bold', mb: '12px' }}
                    >
                        {t('CreateTeam:better_together', {
                            defaultValue: 'Better together',
                        })}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'light',
                            mb: '18px',
                            lineHeight: '24px',
                        }}
                    >
                        {t('CreateTeam:info1')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'light',
                            lineHeight: '22px',
                        }}
                    >
                        {t('CreateTeam:info2')}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        color={'inherit'}
                        variant="contained"
                        caption={t('CreateTeam:create_a_team', {
                            defaultValue: 'Create a team',
                        })}
                        onClick={onCreateTeam}
                        sx={{
                            backgroundColor: ENTITY_COLOR.teams,
                            color: COLOR.whiteDarker2,
                            mr: 1,
                            '&:hover': {
                                backgroundColor: ENTITY_COLOR.teams,
                            },
                        }}
                        startIcon={
                            <AddCircleOutline
                                sx={{
                                    width: 15,
                                    height: 15,
                                    color: COLOR.whiteDarker2,
                                }}
                            />
                        }
                    />
                    <MuiButton
                        sx={{
                            color: ENTITY_COLOR.teams,
                            fontWeight: 700,
                            height: 37,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://www.equinetapp.com/aboutequinetteams'}
                    >
                        {t('CreateTeam:learn_more', {
                            defaultValue: 'Learn more',
                        })}
                    </MuiButton>
                </Box>
            </Box>
        </Box>
    );
}

export default CreateTeamInfo;
