import { AxiosResponse, HttpStatusCode } from 'axios';

import apiClient from '@/services/networking/apiClient';
import { Auth } from '@/types/auth';

async function signInWithFirebase(
    payload: Auth.ServerAPI.SignInWithFirebaseRequestPayload,
): Promise<Auth.ServerAPI.SignInWithFirebaseResponsePayload> {
    const { data } =
        await apiClient.post<Auth.ServerAPI.SignInWithFirebaseResponsePayload>(
            '/firebase/sign_in',
            payload,
        );

    return data;
}

async function signUpWithFirebase(
    payload: Auth.ServerAPI.SignUpRequestPayload,
): Promise<Auth.ServerAPI.SignUpResponsePayload> {
    const { data } = await apiClient.post<Auth.ServerAPI.SignUpResponsePayload>(
        '/firebase/sign_up?app_type=web',
        payload,
    );

    return data;
}

async function checkExistingAccount(): Promise<HttpStatusCode> {
    try {
        const { status } = await apiClient.get('/validate_account');
        return status;
    } catch (error) {
        if (error?.response?.status === HttpStatusCode.Conflict) {
            return error?.response?.status;
        }

        throw new Error(error);
    }
}

async function signOut() {
    return apiClient.post('/sign_out');
}

async function deleteAccount() {
    return await apiClient.post('/remove_account');
}

async function resetPassword(
    payload:
        | Auth.ServerAPI.ResetPasswordByEmailRequestPayload
        | Auth.ServerAPI.ResetPasswordByPhoneNumberRequestPayload,
): Promise<AxiosResponse<any>> {
    return apiClient.post('/passwords/send_reset_instructions', {
        auth_type: 'firebase',
        ...payload,
    });
}

export const ServerAuthAPI = {
    signInWithFirebase,
    signUpWithFirebase,
    resetPassword,
    deleteAccount,
    signOut,
    checkExistingAccount,
};
