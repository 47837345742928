import { Box, Pagination as MUIPagination } from '@mui/material';
import React, { useMemo } from 'react';

import { PAGE_LIMIT } from '@/helpers/pagination';

import { Props } from './types';

function Pagination({ page, onChange, recordsCount, testID }: Props) {

    const numberOfPages = useMemo(() => {
        if (!recordsCount) return -1;
        return Math.ceil(recordsCount / PAGE_LIMIT);
    }, [recordsCount]);

    return (
        <Box
            sx={{
                paddingBlock: 3,
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <MUIPagination
                data-test-id={testID}
                shape="rounded"
                page={page}
                count={numberOfPages}
                onChange={onChange}
            />
        </Box>
    );
}

export default Pagination;
