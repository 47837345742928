export const weekDays = {
    Mon: 'Calendar:labels:weeks:monday',
    Tue: 'Calendar:labels:weeks:tuesday',
    Wed: 'Calendar:labels:weeks:wednesday',
    Thu: 'Calendar:labels:weeks:thursday',
    Fri: 'Calendar:labels:weeks:friday',
    Sat: 'Calendar:labels:weeks:saturday',
    Sun: 'Calendar:labels:weeks:sunday',
};

export const monthLabel = {
    0: 'Calendar:labels:months:january',
    1: 'Calendar:labels:months:february',
    2: 'Calendar:labels:months:march',
    3: 'Calendar:labels:months:april',
    4: 'Calendar:labels:months:may',
    5: 'Calendar:labels:months:june',
    6: 'Calendar:labels:months:july',
    7: 'Calendar:labels:months:august',
    8: 'Calendar:labels:months:september',
    9: 'Calendar:labels:months:october',
    10: 'Calendar:labels:months:november',
    11: 'Calendar:labels:months:december',
};
