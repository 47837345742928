import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
    removeItemFromLocalStorage,
} from '@/services/webStorage/localStorage';

function removeLastUserEmailFromLocalStorage(): void {
    removeItemFromLocalStorage(WEB_STORAGE_KEYS.last_user_email);
}

function saveLastUserEmailInLocalStorage(email: string): void {
    storeItemInLocalStorage(WEB_STORAGE_KEYS.last_user_email, email);
}

function getLastUserEmailFromLocalStorage(): string | null {
    return getItemFromLocalStorage(WEB_STORAGE_KEYS.last_user_email);
}

export {
    removeLastUserEmailFromLocalStorage,
    saveLastUserEmailInLocalStorage,
    getLastUserEmailFromLocalStorage,
};
