import { ChevronRight } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function FinanceOverviewListItem({
    currency,
    item,
    onClick,
    wrapperStyle,
}: Props) {
    const handleItemClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    return (
        <Box
            className={`flex flex-row rounded-md items-center justify-between hover:cursor-pointer px-2 py-3 hover:bg-blue-50/50 ${wrapperStyle}`}
            onClick={handleItemClick}
        >
            <Box className="flex flex-row items-center">
                <DescriptionOutlinedIcon
                    className="mr-2"
                    style={{ width: 30, height: 30, color: item.color }}
                />
                <Typography className="mt-1">{`${item.name} ${
                    item.count !== undefined ? `: ${item.count}` : ''
                }`}</Typography>
            </Box>
            {item.amount?.length ? (
                <Box className="flex flex-row">
                    <Typography>{`${currency} ${item.amount}`}</Typography>
                    <ChevronRight style={{ color: COLOR.bahamaBlue }} />
                </Box>
            ) : null}
        </Box>
    );
}

export default FinanceOverviewListItem;
