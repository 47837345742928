import { Box } from '@mui/material';
import React from 'react';
import { GOOGLE_PLAY_STORE_APP_URL } from 'shared/constants/externalUrls';

import GooglePlayBadgeImage from '@/assets/images/google-play-badge.png';

import ExternalLink from '../ExternalLink';

function PlayStoreBadgeLink() {
    return (
        <Box className="transition-transform hover:scale-110">
            <ExternalLink href={GOOGLE_PLAY_STORE_APP_URL}>
                <img
                    src={GooglePlayBadgeImage}
                    className="h-[40px] w-[135px]"
                />
            </ExternalLink>
        </Box>
    );
}

export default PlayStoreBadgeLink;
