import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import OrganisationUser from 'shared/db/services/OrganisationUser';
import { OrganisationUserModel } from 'shared/types/OrganisationUser';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks';
import { Context } from '@/types/context';

const OrgUserContext = createContext<Context.OrgUser.Value>({
    clearContextState: () => null,
    orgUser: null,
});

const orgUserContextRef: React.MutableRefObject<Context.OrgUser.Value | null> =
    createRef();

const OrgUserContextProvider = ({ children }: Context.OrgUser.Props) => {
    const { userOrganisation } = useOrganisationsContext();
    const { userProfileData } = useUserContext();
    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();
    const [orgUser, setOrgUser] = useState<OrganisationUserModel | null>(null);

    const clearContextState = useCallback(() => setOrgUser(null), []);

    const { getDatabase } = useDatabase();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const orgUserService = useMemo(
        () => new OrganisationUser(database),
        [database],
    );

    const setCurrentOrgUser = useCallback(async () => {
        if (
            !isSyncInProgress &&
            isInitialSyncInProgress &&
            userOrganisation &&
            userProfileData
        ) {
            const [orgUserData] = await orgUserService
                .getById(userProfileData.id)
                .fetch();

            setOrgUser(orgUserData);
        } else if (isInitialSyncInProgress) {
            clearContextState();
        }
    }, [
        clearContextState,
        isInitialSyncInProgress,
        isSyncInProgress,
        orgUserService,
        userOrganisation,
        userProfileData,
    ]);

    useEffect(() => {
        setCurrentOrgUser();
    }, [setCurrentOrgUser]);

    const contextValue: Context.OrgUser.Value = {
        orgUser,
        clearContextState,
    };

    orgUserContextRef.current = contextValue;

    return (
        <OrgUserContext.Provider value={contextValue}>
            {children}
        </OrgUserContext.Provider>
    );
};

function useOrgUserContext() {
    const orgUserContext = useContext(OrgUserContext);

    return orgUserContext;
}

function clearOrgUserContextState() {
    return orgUserContextRef.current?.clearContextState();
}

export {
    OrgUserContext,
    OrgUserContextProvider,
    useOrgUserContext,
    clearOrgUserContextState,
};
