import { OPERATING_SYSTEM } from '@/constants/device';

export function getMobileOperatingSystem(): OPERATING_SYSTEM {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return OPERATING_SYSTEM.android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return OPERATING_SYSTEM.iOS;
    }

    return OPERATING_SYSTEM.unknown;
}
