import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ProvidersWrapper } from '@/components';
import { useBlockCookiesEffect } from '@/hooks';
import Router from '@/router/Router';
import Datadog from '@/services/datadog';
import Sentry from '@/services/sentry';

Sentry.init();
Datadog.init();

function App() {
    useBlockCookiesEffect(['_fw_crm_v']);

    return (
        <ProvidersWrapper>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </ProvidersWrapper>
    );
}

export default App;
