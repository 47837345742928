import {
    Box,
    Button,
    Checkbox,
    SxProps,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    MouseEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs/internal/observable/of';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import DatabaseService from 'shared/db/services/Database.web';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { DBWriterLoggerFunction } from 'shared/types/logger';
import {
    calculateInvoiceDetails,
    getInvoiceBusinessOrContactName,
    getInvoiceStatusColor,
} from 'shared/utils/invoicing';

import { PersonIcon } from '@/assets/svg';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useInvoiceStatusLabel } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';
import {
    calculateInvoicePriceSum,
    getOverdueWithColor,
} from '@/utils/invoicing';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

const contactCellStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

const cellTextStyle: SxProps<Theme> = {
    fontSize: 13,
    fontWeight: 300,
};

const database = DatabaseService.getDatabase();

function InvoicesTableItem({
    invoice,
    contacts,
    organisationAccountingsData,
    selectable,
    isSelected,
    onSelect,
    onEdit,
    onDelete,
    onSend,
    onSendAndAuthorise,
    onSetToPaid,
}: Props) {
    const navigate = useNavigate();

    const { isSyncInProgress } = useDBSyncContext();
    const { ImagesService } = useImagesContext();
    const { invoiceSystemEnabled } = useFeatureFlagsContext();

    const [sumTotal, setSumTotal] = useState('');

    const userRegion = useRegion();
    const currency = userRegion ? userRegion.currencySymbol : '$';

    const accountings = organisationAccountingsData
        ? organisationAccountingsData[0]
        : null;
    const contact = contacts ? contacts[0] : undefined;

    const invoiceStatusLabel = useInvoiceStatusLabel(
        invoice,
        !!(accountings && accountings.accountingProvider),
    );

    const navigateToInvoice = useCallback(() => {
        const path = getRoutePath(ROUTE.invoice, { id: invoice.id });

        navigate(path);
    }, [invoice.id, navigate]);

    const handleCheckboxClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onSelect();
        },
        [onSelect],
    );

    const handleInvoiceItemClick = useCallback(
        (event: MouseEvent) => {
            if (selectable) {
                handleCheckboxClick(event);
            } else {
                navigateToInvoice();
            }
        },
        [handleCheckboxClick, navigateToInvoice, selectable],
    );

    const handleEditClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onEdit(invoice);
        },
        [onEdit, invoice],
    );

    const handleDeleteClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onDelete(invoice);
        },
        [onDelete, invoice],
    );

    const handleSendAndAuthoriseClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onSendAndAuthorise(invoice);
        },
        [onSendAndAuthorise, invoice],
    );

    const handleSendClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onSend(invoice);
        },
        [onSend, invoice],
    );

    const handleSetToPaidClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onSetToPaid(invoice);
        },
        [onSetToPaid, invoice],
    );

    const getInvoiceSumTotal = useCallback(async () => {
        const { subtotalSum, totalSum } = await calculateInvoiceDetails(
            {
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            },
            invoice,
        );

        const priceSum = invoiceSystemEnabled ? totalSum : subtotalSum;

        const price = calculateInvoicePriceSum({ priceSum, invoice, currency });
        setSumTotal(price);
    }, [ImagesService, currency, invoice, invoiceSystemEnabled]);

    useEffect(() => {
        getInvoiceSumTotal();
    }, [getInvoiceSumTotal]);

    const BuildDraftButtons = useMemo(() => {
        const shouldAuthoriseAndSend =
            contact?.email && accountings?.accountingProvider;

        return (
            <>
                <TooltipSyncButton
                    tooltip={t('AddEditModalHeader:button_tooltip', {
                        defaultValue:
                            'Please wait for the synchronization to complete',
                    })}
                    onClick={handleDeleteClick}
                    showTooltip={isSyncInProgress}
                    disabled={isSyncInProgress}
                    testID="InvoicesPage-TableRow-DeleteButton"
                >
                    {t('InvoicesPage:buttons:delete')}
                </TooltipSyncButton>
                <Button
                    data-test-id="InvoicesPage-TableRow-EditButton"
                    onClick={handleEditClick}
                >
                    {t('InvoicesPage:buttons:edit')}
                </Button>
                <TooltipSyncButton
                    tooltip={t('AddEditModalHeader:button_tooltip', {
                        defaultValue:
                            'Please wait for the synchronization to complete',
                    })}
                    onClick={handleSendAndAuthoriseClick}
                    showTooltip={isSyncInProgress}
                    disabled={isSyncInProgress}
                    testID="InvoicesPage-TableRow-SendAndAuthoriseButton"
                >
                    {shouldAuthoriseAndSend
                        ? t('InvoicesPage:buttons:authorise_and_send')
                        : t('InvoicesPage:buttons:authorise')}
                </TooltipSyncButton>
            </>
        );
    }, [
        accountings?.accountingProvider,
        contact?.email,
        handleDeleteClick,
        handleEditClick,
        handleSendAndAuthoriseClick,
        isSyncInProgress,
    ]);

    const BuildAuthorisedButtons = useMemo(() => {
        if (!accountings?.accountingProvider) {
            return (
                <>
                    <TooltipSyncButton
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        onClick={handleDeleteClick}
                        showTooltip={isSyncInProgress}
                        disabled={isSyncInProgress}
                        testID="InvoicesPage-TableRow-DeleteButton"
                    >
                        {t('InvoicesPage:buttons:delete')}
                    </TooltipSyncButton>
                    <TooltipSyncButton
                        disabled={isSyncInProgress}
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        onClick={handleSetToPaidClick}
                        showTooltip={isSyncInProgress}
                        testID="InvoicesPage-TableRow-SetToPaidButton"
                    >
                        {t('InvoicesPage:buttons:set_to_paid')}
                    </TooltipSyncButton>
                </>
            );
        } else {
            return (
                <TooltipSyncButton
                    tooltip={t('AddEditModalHeader:button_tooltip', {
                        defaultValue:
                            'Please wait for the synchronization to complete',
                    })}
                    onClick={handleSendClick}
                    disabled={isSyncInProgress}
                    showTooltip={isSyncInProgress}
                    testID="InvoicesPage-TableRow-SendButton"
                >
                    {t(
                        invoice.sentToContactTime
                            ? 'InvoicesPage:buttons:resend'
                            : 'InvoicesPage:buttons:send',
                    )}
                </TooltipSyncButton>
            );
        }
    }, [
        accountings?.accountingProvider,
        handleDeleteClick,
        handleSendClick,
        handleSetToPaidClick,
        invoice.sentToContactTime,
        isSyncInProgress,
    ]);

    const BuildPaidButtons = useMemo(() => {
        if (!accountings?.accountingProvider) {
            return (
                <TooltipSyncButton
                    tooltip={t('AddEditModalHeader:button_tooltip', {
                        defaultValue:
                            'Please wait for the synchronization to complete',
                    })}
                    onClick={handleDeleteClick}
                    showTooltip={isSyncInProgress}
                    disabled={isSyncInProgress}
                    testID="InvoicesPage-TableRow-DeleteButton"
                >
                    {t('InvoicesPage:buttons:delete')}
                </TooltipSyncButton>
            );
        } else {
            return null;
        }
    }, [accountings?.accountingProvider, isSyncInProgress, handleDeleteClick]);

    const displayActionButtons = useMemo(() => {
        let buttons: JSX.Element | null;
        switch (invoice.status) {
            case INVOICE_STATUS.draft:
                buttons = BuildDraftButtons;
                break;
            case INVOICE_STATUS.authorised:
                buttons = BuildAuthorisedButtons;
                break;
            case INVOICE_STATUS.paid:
                buttons = BuildPaidButtons;
                break;
            case INVOICE_STATUS.approved:
            case INVOICE_STATUS.overdue:
            case INVOICE_STATUS.queued:
            case INVOICE_STATUS.sent:
            default:
                buttons = <Box></Box>;
        }
        return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                {buttons}
            </Box>
        );
    }, [
        BuildAuthorisedButtons,
        BuildDraftButtons,
        BuildPaidButtons,
        invoice.status,
    ]);

    const buildOverdueField = useMemo(() => {
        const { color, value } = getOverdueWithColor(
            invoice.dueDateTime,
            invoice.status,
        );

        return (
            <Typography
                data-test-id="InvoicesPage-TableRow-Overdue"
                sx={{
                    color,
                    fontSize: 13,
                    fontWeight: 400,
                }}
            >
                {value}
            </Typography>
        );
    }, [invoice.dueDateTime, invoice.status]);

    const invoiceStatusColor = useMemo(() => {
        return getInvoiceStatusColor(invoice.status, invoice.dueDateTime).color;
    }, [invoice.status, invoice.dueDateTime]);

    if (!invoice) return null;

    return (
        <TableRow
            className="hover:cursor-pointer hover:bg-blue-50/50 child:border-0 last-child:rounded-r-lg first-child:rounded-l-lg"
            data-test-id={`InvoicesPage-TableRow-${contact?.firstName}`}
            onClick={handleInvoiceItemClick}
        >
            <TableCell component="th" scope="row" width="28%">
                <Box sx={contactCellStyles}>
                    <div className="flex flex-row items-center">
                        {selectable ? (
                            <Checkbox
                                name={`${contact?.firstName}-checkbox`}
                                checked={isSelected}
                                onClick={handleCheckboxClick}
                                size="small"
                                sx={{ ml: '-9px' }}
                            />
                        ) : null}
                        <Typography
                            data-test-id="InvoicesPage-TableRow-ContactInfo"
                            sx={{
                                fontWeight: 700,
                                fontSize: 16,
                                lineHeight: '24px',
                                color:
                                    invoice.status === INVOICE_STATUS.draft
                                        ? COLOR.vividBlack
                                        : invoiceStatusColor,
                            }}
                        >
                            {invoice.reference}
                        </Typography>
                    </div>
                    {invoice.number ? (
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 400,
                                lineHeight: '24px',
                                color: COLOR.paleSky,
                            }}
                        >
                            {invoice.number}
                        </Typography>
                    ) : null}
                    <div className="flex flex-row gap-1 items-center">
                        <PersonIcon width={14} height={14} />
                        <Typography
                            className="text-xs"
                            sx={{
                                color: COLOR.vscBlack,
                                fontWeight: 400,
                                lineHeight: '19.5px',
                            }}
                        >
                            {getInvoiceBusinessOrContactName(contact)}
                        </Typography>
                    </div>
                </Box>
            </TableCell>
            <TableCell align="left" width="10%">
                <Typography
                    sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        lineHeight: '24px',
                        color: COLOR.vividBlack,
                    }}
                    data-test-id="InvoicesPage-TableRow-Total"
                >
                    {sumTotal ? `${sumTotal}` : '-'}
                </Typography>
            </TableCell>
            <TableCell align="left" width={'15%'}>
                {buildOverdueField}
            </TableCell>
            <TableCell align="left">
                <div className="flex flex-row items-center">
                    {invoiceStatusLabel}
                </div>
            </TableCell>
            <TableCell width="23%">
                <Typography
                    sx={cellTextStyle}
                    data-test-id="InvoicesPage-TableRow-CreatedAt"
                >
                    {formatDate(invoice.createdAt.toISOString())}
                </Typography>
            </TableCell>
            <TableCell width="23%">
                <Typography
                    sx={cellTextStyle}
                    data-test-id="InvoicesPage-TableRow-UpdatedAt"
                >
                    {formatDate(invoice.updatedAt.toISOString())}
                </Typography>
            </TableCell>
            <TableCell>
                <span>{displayActionButtons}</span>
            </TableCell>
        </TableRow>
    );
}

const enhance = withObservables<Props, unknown>(['invoice'], ({ invoice }) => {
    const database = DatabaseService.getDatabase();
    const logDBActionMock: DBWriterLoggerFunction = () => {
        return;
    };

    const accountingsService = new OrganisationAccountings({
        database,
        logDBAction: logDBActionMock,
    });

    return {
        invoice: invoice ? invoice.observe() : of([]),
        contacts: invoice ? invoice.contacts.observe() : of([]),
        organisationAccountingsData: accountingsService.get(),
    };
});

export default enhance(InvoicesTableItem);
