import React, { LegacyRef, forwardRef, memo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ProductModel } from 'shared/types/Products';

import { ProductListRow } from './components';
import { Props } from './types';

const LIST_HEIGHT = 650;

const ProductsList = forwardRef(
    (
        {
            filteredProducts,
            getItemSize,
            icon,
            itemSizes,
            marginTop,
            onProductClick,
            showUnitTypes = false,
        }: Props,
        ref,
    ) => {
        return (
            <List<ProductModel[][]>
                ref={ref as LegacyRef<List>}
                height={marginTop ? LIST_HEIGHT - marginTop : LIST_HEIGHT}
                width={showUnitTypes ? 720 : 480}
                itemSize={getItemSize}
                itemCount={filteredProducts.length}
                itemData={filteredProducts}
            >
                {(props) => (
                    <ProductListRow
                        {...props}
                        icon={icon}
                        isOpen={itemSizes[props.index]?.isOpen || false}
                        onClick={onProductClick}
                        testIdPrefix="AddProductsModal"
                        showUnitTypes={showUnitTypes}
                    />
                )}
            </List>
        );
    },
);

ProductsList.displayName = 'ProductsList';

export default memo(ProductsList);
