import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStatusMessage } from 'shared/utils/sync';

import { Tooltip } from '@/components';
import StatusIcon from '@/components/StatusIcon';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useMomentFromNow } from '@/hooks';
import { getLastPulledAt } from '@/services/webStorage/localStorage/sync';
import { COLOR } from '@/theme/colors';
import { getStatusColor } from '@/utils/sync';

import { Props } from './types';

function SyncStatusBadge({ status }: Props) {
    const { isSyncInProgress } = useDBSyncContext();
    const { t } = useTranslation();

    const statusMessage = useMemo(
        () => getStatusMessage(status, t),
        [status, t],
    );
    const currentColor = useMemo(() => {
        return getStatusColor(status);
    }, [status]);

    const [lastSyncedAt, setLastSyncedAt] = useState('');

    useEffect(() => {
        if (!isSyncInProgress) {
            setLastSyncedAt(getLastPulledAt() || Date.now().toString());
        }
    }, [isSyncInProgress]);

    const lastSyncedAtString = useMomentFromNow(+lastSyncedAt, 1000);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <StatusIcon status={status} size={18} />
                <Typography
                    color={currentColor}
                    ml={0.5}
                    fontSize={11}
                    fontWeight={700}
                    variant="body2"
                    lineHeight={2}
                >
                    {statusMessage}
                </Typography>
            </Box>
            {status === 'offline' ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        fontSize={11}
                        fontWeight={500}
                        color={COLOR.black23}
                        mr={1}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        maxWidth={140}
                        noWrap
                    >
                        {`${t('Generic:last_synced')} ${lastSyncedAtString}`}
                    </Typography>
                    <Tooltip
                        title={
                            <>
                                <Typography fontSize={14} fontWeight={700}>
                                    {t('SyncStatus:tooltip:title')}
                                </Typography>
                                <Typography fontSize={14}>
                                    {t('SyncStatus:tooltip:message')}
                                </Typography>
                            </>
                        }
                        placement={'right'}
                    >
                        <InfoOutlinedIcon
                            sx={{
                                display: 'flex',
                                alignSelf: 'center',
                                color: COLOR.bahamaBlue,
                                width: 18,
                                height: 18,
                            }}
                        />
                    </Tooltip>
                </Box>
            ) : status === 'error' ? (
                <Typography
                    fontSize={11}
                    fontWeight={500}
                    color={COLOR.black23}
                    mr={1}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    maxWidth={140}
                    noWrap
                >
                    {t('Generic:contact_support', {
                        defaultValue: 'Please contact Support',
                    })}
                </Typography>
            ) : null}
        </Box>
    );
}

export default SyncStatusBadge;
