import React from 'react';

import { useUserContext } from '@/context/UserContext';

import { Props } from './types';

function withUserProfileData<T extends Props>(
    Component: (props: T) => JSX.Element,
) {
    function ComponentWithUserProfileData(props: T) {
        const { userProfileData } = useUserContext();

        return <Component userProfileData={userProfileData} {...props} />;
    }

    ComponentWithUserProfileData.displayName = Component.name;

    return ComponentWithUserProfileData;
}

export default withUserProfileData;
