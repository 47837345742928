import { Box, SxProps, Theme, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useMemo } from 'react';
import { of } from 'rxjs';

import { ShoppingAvatar, SelectableListItem } from '@/components';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import { Props } from './types';

const smallTextStyle: SxProps<Theme> = {
    fontSize: 13,
    fontWeight: 300,
};

function ShoppingListsListItem({
    clickable,
    conditionWarning,
    highlightAvatar,
    isSelected,
    multiSelect,
    onClick,
    renderDateText,
    selectCondition,
    selectable,
    shoppingList,
    shoppingListProductsCount,
    testID,
    wrapperStyles,
}: Props) {
    const numberOfProductsText = useMemo(() => {
        switch (shoppingListProductsCount) {
            case 0:
                return t('ShoppingListsPage:no_items');
            case 1:
                return `${shoppingListProductsCount} ${t(
                    'ShoppingListsPage:item',
                )}`;
            default:
                return `${shoppingListProductsCount} ${t(
                    'ShoppingListsPage:items',
                )}`;
        }
    }, [shoppingListProductsCount]);

    const dateText = useMemo(() => {
        if (shoppingList.addedToInventoryTime) {
            return `${t('ShoppingList:added_to_inventory')} ${formatDate(
                shoppingList.addedToInventoryTime,
                'date',
            )}`;
        }

        return `${t('ShoppingList:generated')} ${formatDate(
            shoppingList.createdAt.toISOString(),
        )}`;
    }, [shoppingList]);

    const shouldRenderDateText = renderDateText
        ? renderDateText(shoppingList)
        : true;

    const shouldHighlightAvatar = useMemo(() => {
        if (highlightAvatar === undefined) {
            return !!shoppingList.addedToInventoryTime;
        }

        if (typeof highlightAvatar === 'boolean') {
            return highlightAvatar;
        }

        return highlightAvatar(shoppingList);
    }, [highlightAvatar, shoppingList]);

    return (
        <SelectableListItem
            avatarVariant="circular"
            clickable={clickable}
            conditionWarning={conditionWarning}
            defaultAvatar={
                <ShoppingAvatar isHighlighted={shouldHighlightAvatar} />
            }
            item={shoppingList}
            isSelected={isSelected}
            multiSelect={multiSelect}
            onClick={onClick}
            selectCondition={selectCondition}
            selectable={selectable}
            testID={testID}
            wrapperStyles={wrapperStyles}
        >
            <Box data-test-id={`${testID}-${shoppingList.name}`} sx={{ pr: 2 }}>
                <Typography
                    data-test-id="ShoppingListsListItem-TitleText"
                    flex={1}
                    fontSize={14}
                    fontWeight={700}
                    noWrap
                    sx={{ mb: 0.5 }}
                >
                    {shoppingList.name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                        data-test-id="ShoppingListsListItem-NumberOfProductsText"
                        sx={smallTextStyle}
                    >
                        {numberOfProductsText}
                    </Typography>
                    {shouldRenderDateText ? (
                        <Typography
                            color={COLOR.regentGray}
                            data-test-id="ShoppingListsListItem-DateText"
                            sx={smallTextStyle}
                        >
                            {dateText}
                        </Typography>
                    ) : null}
                </Box>
            </Box>
        </SelectableListItem>
    );
}

const enhance = withObservables<Props, unknown>(
    ['shoppingList'],
    ({ shoppingList }) => {
        return {
            shoppingListProductsCount: shoppingList
                ? shoppingList.shoppingListProducts.observeCount()
                : of(0),
        };
    },
);

export default enhance(ShoppingListsListItem) as typeof ShoppingListsListItem;
