import { Avatar, Box, Radio, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ExportFormatsListItem({ format, onClick, selectedFormat }: Props) {
    const { t } = useTranslation();

    const isSelected = format.type === selectedFormat;

    const handleOnFormatClick = useCallback(() => {
        if (isSelected) {
            return;
        }

        onClick(format.type);
    }, [format.type, isSelected, onClick]);

    return (
        <Box
            className="flex flex-row items-center mb-3"
            data-test-id={`ExportFormatsListsItem-${format.type}`}
            key={format.type}
        >
            <Radio
                checked={isSelected}
                onChange={handleOnFormatClick}
                sx={{ ml: '-9px', mr: 1 }}
            />
            <Box
                className={`flex flex-row items-center hover:cursor-pointer hover:bg-blue-50/50 w-full rounded-lg rounded-l-[37px] ${
                    isSelected ? 'bg-blue-50' : undefined
                }`}
                onClick={handleOnFormatClick}
            >
                <Avatar
                    src={format.image}
                    sx={{ height: 74, mr: 2, width: 74 }}
                />
                <Box>
                    <Typography
                        fontWeight="bold"
                        sx={{ mb: 1 }}
                        variant="body2"
                    >
                        {t(format.translationKeys.title)}
                        <Typography
                            component="span"
                            fontWeight="500"
                            variant="body2"
                        >
                            {` - ${t(format.translationKeys.subtitle)}`}
                        </Typography>
                    </Typography>
                    <Typography color={COLOR.regentGray} variant="body2">
                        {t(format.translationKeys.description)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default ExportFormatsListItem;
