import { Box, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';

import { COLOR } from '@/theme/colors';

import DividerSelectSection from '../DividerSelectSection';

import PhotoPlaceholder from './components/PhotoPlaceholder';
import { Props } from './types';

function DividerSelectPhotoSection({
    buttonsPosition,
    selectLabel,
    selectButtonTestId,
    title,
    onPhotosChange,
    selectedPhotosUrls,
    emptyStateMessage,
    emptyStateMessageTestId,
    onPhotoRemoved,
}: Props) {
    const photosInputRef = useRef<HTMLInputElement | null>(null);

    const handleAddPhotoClick = useCallback(
        () => photosInputRef?.current?.click(),
        [photosInputRef],
    );

    const handlePhotosChange = useCallback(
        (event) => {
            const images = event.target.files;
            if (!images) {
                return;
            }

            onPhotosChange(
                Object.values(images).map((image: Blob) =>
                    URL.createObjectURL(image),
                ),
            );

            event.target.value = null;
        },
        [onPhotosChange],
    );

    return (
        <DividerSelectSection
            buttonsPosition={buttonsPosition}
            onSelect={handleAddPhotoClick}
            selectLabel={selectLabel}
            selectButtonTestId={selectButtonTestId}
            title={title}
        >
            {selectedPhotosUrls?.length ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {selectedPhotosUrls.map((photoUrl) => (
                        <PhotoPlaceholder
                            key={photoUrl}
                            photoUrl={photoUrl}
                            onRemove={onPhotoRemoved}
                        />
                    ))}
                </Box>
            ) : (
                <Typography
                    sx={{ mt: 1 }}
                    color={COLOR.regentGray}
                    data-test-id={emptyStateMessageTestId}
                    variant="body2"
                >
                    {emptyStateMessage}
                </Typography>
            )}
            <input
                style={{ display: 'none' }}
                ref={photosInputRef}
                type="file"
                onChange={handlePhotosChange}
                accept="image/*"
                multiple
            />
        </DividerSelectSection>
    );
}

export default DividerSelectPhotoSection;
