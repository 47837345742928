import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Link, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';
import OrganisationAccountingsService from 'shared/db/services/OrganisationAccountings';
import { InvoiceModel } from 'shared/types/Invoice';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';
import {
    getInvoiceStatus,
    getInvoiceStatusColor,
} from 'shared/utils/invoicing';

import { OrganisationMembersAvatarGroup } from '@/components';
import InvoiceStatusBadge from '@/components/InvoiceStatusBox';
import { useDatabase, useFormatDate } from '@/hooks';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function HeaderSection({
    isLoading,
    entry,
    entryHorse,
    users,
    onCreateInvoice,
}: Props) {
    const { t } = useTranslation();
    const formatDate = useFormatDate();
    const { getDatabase } = useDatabase();
    const navigate = useNavigate();

    const [invoice, setInvoice] = useState<InvoiceModel | null>(null);
    const [organisationAccountingsData, setOrganisationAccountingsData] =
        useState<OrganisationAccountingsModel | null>(null);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const entryDate = useMemo(
        () =>
            entry?.loggedTime
                ? formatDate(entry.loggedTime, 'shortdatetime')
                : '',
        [entry?.loggedTime, formatDate],
    );

    const invoiceStatusStyles = useMemo(
        () =>
            invoice
                ? getInvoiceStatusColor(invoice.status, invoice.dueDateTime)
                : null,
        [invoice],
    );

    const composedInvoiceId = useMemo(() => {
        if (invoice) {
            const internalId = invoice.reference;
            const externalId = invoice.number ? `(${invoice.number})` : '';

            return `${internalId} ${externalId}`;
        }
    }, [invoice]);

    const invoiceStatus = useMemo(
        () =>
            invoice
                ? getInvoiceStatus({
                      hasAccountingProvider:
                          !!organisationAccountingsData?.accountingProvider,
                      translateFn: t,
                      sentToContact: invoice.sentToContact,
                      sentToContactTime: invoice.sentToContactTime,
                      status: invoice.status,
                  })
                : null,
        [invoice, organisationAccountingsData?.accountingProvider, t],
    );

    const navigateToInvoice = useCallback(() => {
        const route = getRoutePath(ROUTE.invoice, { id: invoice?.id });
        navigate(route);
    }, [invoice?.id, navigate]);

    const fetchAccountingsData = useCallback(async () => {
        const organisationAccountingsService =
            new OrganisationAccountingsService({
                database,
                logDBAction: Logger.logRecordActivity,
            });

        const [accountingsData] =
            (await organisationAccountingsService.get().fetch()) ?? [];

        setOrganisationAccountingsData(accountingsData);
    }, [database]);

    const fetchEntryInvoice = useCallback(async () => {
        if (entry?.invoiceId) {
            const invoice = await entry.invoice.fetch();
            setInvoice(invoice);
        }
    }, [entry?.invoice, entry?.invoiceId]);

    useEffect(() => {
        fetchAccountingsData();
    }, [fetchAccountingsData]);

    useEffect(() => {
        fetchEntryInvoice();
    }, [fetchEntryInvoice]);

    const entryTitleTranslated = entry?.title
        ? t(ENTRIES_TITLES_DICTIONARY[entry.title])
        : '';

    return (
        <Box
            sx={{
                display: 'flex',
                p: 2,
                bgcolor: COLOR.transparentLightGrayBackground,
                borderRadius: '16px 16px 2px 2px',
                mb: 7.5,
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography
                        data-test-id="EntryPage-EntryTitle"
                        fontSize={24}
                        fontWeight={700}
                        color={COLOR.paleSky}
                        sx={{ mb: 1.5 }}
                    >
                        {!isLoading ? (
                            entryHorse ? (
                                t('Entry:entry_horse_title', {
                                    horse: entryHorse.name,
                                    job_type: entryTitleTranslated,
                                })
                            ) : (
                                t('Entry:entry_title', {
                                    job_type: entryTitleTranslated,
                                })
                            )
                        ) : (
                            <Skeleton width={150} variant="text" />
                        )}
                    </Typography>
                    <Typography
                        sx={{ mb: 2 }}
                        fontSize={13}
                        fontWeight={300}
                        color={COLOR.black60}
                        data-test-id="EntryPage-EntryDate"
                    >
                        {!isLoading ? (
                            entryDate
                        ) : (
                            <Skeleton width={75} variant="text" />
                        )}
                    </Typography>
                    {!invoiceStatus ? (
                        <Button
                            data-test-id="EntryPage-AddInvoiceButton"
                            color="inherit"
                            size="small"
                            disabled={isLoading}
                            onClick={onCreateInvoice}
                            startIcon={<CheckCircleOutlineIcon />}
                        >
                            <Typography fontSize="small" fontWeight={700}>
                                Create invoice
                            </Typography>
                        </Button>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                data-test-id="EntryPage-InvoiceStatus"
                                fontSize={13}
                                fontWeight={300}
                                color={COLOR.black60}
                                onClick={navigateToInvoice}
                            >
                                {t('Entry:associated_invoice')}
                            </Typography>
                            <Link onClick={navigateToInvoice}>
                                <Typography
                                    data-test-id="EntryPage-InvoiceStatus"
                                    fontSize={13}
                                    fontWeight={300}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {composedInvoiceId}
                                </Typography>
                            </Link>
                            <InvoiceStatusBadge
                                status={invoiceStatus}
                                color={invoiceStatusStyles?.color}
                                fontSize={13}
                                height={19.5}
                                testID="EntryPage-Header-Status"
                            />
                        </Box>
                    )}
                </Box>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    {!!users && (
                        <OrganisationMembersAvatarGroup
                            database={database}
                            users={users}
                            testID="SchedulesListItem-Members"
                            max={3}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default HeaderSection;
