import { Box, Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleContainer } from '@/components';

import DeleteAccountModal from './components';

function AccountSection() {
    const { t } = useTranslation();

    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
        useState(false);

    const openDeleteAccountModal = useCallback(() => {
        setIsDeleteAccountModalOpen(true);
    }, []);

    const closeDeleteAccountModal = useCallback(() => {
        setIsDeleteAccountModalOpen(false);
    }, []);

    return (
        <CollapsibleContainer
            collapse
            title={t('Generic:account')}
            testID="SettingsPage-AccountSection"
        >
            {isDeleteAccountModalOpen ? (
                <DeleteAccountModal close={closeDeleteAccountModal} />
            ) : null}
            <Box sx={{ display: 'flex' }}>
                <Button
                    data-test-id="SettingsPage-DeleteAccountButton"
                    onClick={openDeleteAccountModal}
                    variant="outlined"
                >
                    {t('AccountDelete:modal:delete_button')}
                </Button>
            </Box>
        </CollapsibleContainer>
    );
}

export default AccountSection;
