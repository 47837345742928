import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function NotesSection({ notes, lineThrough, style }: Props) {
    return (
        <div className="pt-7">
            <div className={twMerge('border-b-[1px] border-borderGrey', style)}>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        color: COLOR.ebonyClay,
                    }}
                >
                    {t('ExternalAppointment:labels:description_notes')}
                </Typography>
            </div>
            <div className="md:pl-5 pt-4 md:pt-2">
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '300',
                        lineHeight: '26px',
                        color: COLOR.ebonyClay,
                        textDecorationLine: lineThrough
                            ? 'line-through'
                            : undefined,
                    }}
                >
                    {notes}
                </Typography>
            </div>
        </div>
    );
}

export default NotesSection;
