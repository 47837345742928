import {
    Box,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ENTRIES_FILTER } from 'shared/constants/entries/filters';
import { COUNTRY } from 'shared/constants/localization/types';

import { useRegion } from '@/hooks/useRegion';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function DatesFilterSection({
    filtersState,
    onFiltersChange,
    setTempDateRangeFilterState,
    tempDateRangeFilterState,
}: Props) {
    const { t } = useTranslation();

    const handleDateChange = useCallback(
        (type: 'from' | 'to') => (date: moment.Moment) => {
            if (!date) {
                return;
            }

            const prevFiltersState = filtersState[ENTRIES_FILTER.date_range];

            const newDate =
                type === 'from' ? date.startOf('day') : date.endOf('day');

            setTempDateRangeFilterState((prev) => ({
                ...prev,
                [type]: newDate,
            }));

            const setDateRangeFilterValue = onFiltersChange(
                ENTRIES_FILTER.date_range,
            );

            setDateRangeFilterValue({
                ...prevFiltersState,
                isActive: true,
                [type]: newDate,
            });
        },
        [filtersState, onFiltersChange, setTempDateRangeFilterState],
    );

    const handleIsDateRangeFilterActiveSwitchChange = useCallback(() => {
        const prevFiltersState = filtersState[ENTRIES_FILTER.date_range];

        const setDateRangeFilterValue = onFiltersChange(
            ENTRIES_FILTER.date_range,
        );

        const newIsActiveValue = !prevFiltersState.isActive;

        setDateRangeFilterValue({
            ...prevFiltersState,
            isActive: newIsActiveValue,
            ...(newIsActiveValue
                ? {
                      from: tempDateRangeFilterState.from,
                      to: tempDateRangeFilterState.to,
                  }
                : undefined),
        });
    }, [tempDateRangeFilterState, filtersState, onFiltersChange]);

    const userBrowserRegion = useRegion();

    const dateInputFormat =
        userBrowserRegion?.code === COUNTRY.US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

    return (
        <Box className="border-b mb-6 pb-7">
            <Typography className="mb-3" color={COLOR.paleSky} fontSize={13}>
                {t('EntriesList:filters:date_range:label')}
            </Typography>
            <Box className="flex flex-row">
                <DatePicker
                    inputFormat={dateInputFormat}
                    maxDate={tempDateRangeFilterState.to}
                    label={t('EntriesList:filters:date_range:from_label')}
                    value={tempDateRangeFilterState.from}
                    onChange={handleDateChange('from')}
                    renderInput={(params) => (
                        <TextField
                            className="mr-6"
                            data-test-id="EntriesPage-FiltersPanel-DateInputFrom"
                            size="small"
                            sx={{ width: 200 }}
                            {...params}
                        />
                    )}
                />
                <DatePicker
                    inputFormat={dateInputFormat}
                    minDate={tempDateRangeFilterState.from}
                    label={t('EntriesList:filters:date_range:to_label')}
                    value={tempDateRangeFilterState.to}
                    onChange={handleDateChange('to')}
                    renderInput={(params) => (
                        <TextField
                            className="mr-6"
                            data-test-id="EntriesPage-FiltersPanel-DateInputTo"
                            size="small"
                            sx={{ width: 200 }}
                            {...params}
                        />
                    )}
                />
                <FormControlLabel
                    checked={filtersState.date_range.isActive}
                    control={
                        <Switch
                            onChange={handleIsDateRangeFilterActiveSwitchChange}
                            value={filtersState.date_range.isActive}
                        />
                    }
                    data-test-id="EntriesPage-FiltersPanel-ApplySwitch"
                    label={t('Generic:apply')}
                />
            </Box>
        </Box>
    );
}

export default memo(DatesFilterSection);
