import { Box, capitalize } from '@mui/material';
import React from 'react';

import { MemberItem, SectionHeader } from '@/components';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { t } from '@/services/translations/config';

import { Props } from './types';

function MembersSection({ members, onDelete, onEdit }: Props) {
    const { userOrganisation } = useOrganisationsContext();

    return (
        <Box>
            <SectionHeader
                title={`${t('Members:field_text')} ${
                    members?.length ? `(${members.length})` : ''
                }`}
            />
            <Box sx={{ px: '16px' }}>
                {members?.map((member) => (
                    <MemberItem
                        key={member.id}
                        id={member.id}
                        name={member.name}
                        onDelete={
                            userOrganisation?.owner_id !== member.id && onDelete
                                ? () => onDelete(member)
                                : undefined
                        }
                        onEdit={
                            userOrganisation?.owner_id !== member.id && onEdit
                                ? () => onEdit(member)
                                : undefined
                        }
                        subtitle={capitalize(
                            userOrganisation?.owner_id === member.id
                                ? t('Generic:owner')
                                : member.role,
                        )}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default MembersSection;
