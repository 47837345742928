import React from 'react';
import { render } from 'react-dom';

import App from '@/App';

import '@/services/translations/config';
import './style.css';

declare global {
    interface Window {
        fcWidget: any;
        fcWidgetMessengerConfig: any;
        fcSettings: any;
        fwcrm: any;
    }
}

const appEl = document.getElementById('app');

render(<App />, appEl);
