import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Contact from 'shared/db/services/Contact';
import Database from 'shared/db/services/Database.web';
import { ContactModel, ContactPayload } from 'shared/types/Contacts';
import { DBWriterLoggerFunction } from 'shared/types/logger';

import {
    AddEditContactModal,
    AddEditHorseModal,
    BackLinkButton,
    ErrorState,
} from '@/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

import { ContactDetails } from './components';

function ContactPage() {
    const { images, ImagesService } = useImagesContext();

    const { id } = useParams();

    const { isSyncInProgress } = useDBSyncContext();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const database = useDatabase();

    const [contact, setContact] = useState<ContactModel | null>(null);
    const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState(false);

    const fetchContact = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);

        try {
            const database = Database.getDatabase();

            const contactService = new Contact({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            if (!id) {
                throw new Error();
            }

            const contact = await contactService.getByID(id);

            setContact(contact);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [id, ImagesService]);

    useEffect(() => {
        fetchContact();
    }, [fetchContact]);

    const openEditContactModal = useCallback(() => {
        setIsEditContactModalOpen(true);
    }, []);

    const closeEditContactModal = useCallback(() => {
        setIsEditContactModalOpen(false);
    }, []);

    const navigateToContactsRoute = useCallback(
        () => navigate(ROUTE.contacts, { replace: true }),
        [navigate],
    );

    const updateContactVisibility = useCallback(
        async (hide: boolean) => {
            try {
                if (contact == null || !contact.id || !contact.userId) {
                    throw new Error();
                }

                const logDBActionMock: DBWriterLoggerFunction = () => {
                    return;
                };

                const contactService = new Contact({
                    database: database.getDatabase(),
                    logDBAction: logDBActionMock,
                    imageService: ImagesService,
                });

                const payload: Partial<ContactPayload> = {
                    hidden: hide,
                };

                const updatedContact = await contactService.update(
                    contact.id,
                    payload,
                    contact.userId,
                );

                setContact(updatedContact);
            } catch {
                Snackbar.showToastNotification({
                    message: t('Contact:visibility_error'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [contact, database, t, ImagesService],
    );

    const handleContactVisibilityClick = useCallback(
        () => updateContactVisibility(!contact?.hidden),
        [contact, updateContactVisibility],
    );

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            {isEditContactModalOpen ? (
                <AddEditContactModal
                    contact={contact}
                    close={closeEditContactModal}
                    onRemove={navigateToContactsRoute}
                    isEditMode
                    CreateHorseModal={AddEditHorseModal}
                />
            ) : null}
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <BackLinkButton
                    to={ROUTE.contacts}
                    text={t('Contact:button:back')}
                    testID="ContactPage-BackButton"
                />
                {!isError ? (
                    <Box sx={{ display: 'flex', gap: 3.375 }}>
                        {contact ? (
                            <TooltipSyncButton
                                tooltip={t(
                                    'AddEditModalHeader:button_tooltip',
                                    {
                                        defaultValue:
                                            'Please wait for the synchronization to complete',
                                    },
                                )}
                                disabled={isSyncInProgress || isLoading}
                                showTooltip={isSyncInProgress}
                                color="inherit"
                                testID="ContactPage-VisibilityButton"
                                sx={{ display: 'flex', gap: 1.5 }}
                                onClick={handleContactVisibilityClick}
                            >
                                {contact?.hidden ? (
                                    <VisibilityIcon fontSize="small" />
                                ) : (
                                    <VisibilityOffIcon fontSize="small" />
                                )}
                                <Typography fontSize="small" fontWeight={700}>
                                    {t(
                                        `Contact:button:${
                                            contact?.hidden ? 'show' : 'hide'
                                        }`,
                                    )}
                                </Typography>
                            </TooltipSyncButton>
                        ) : null}
                        <Button
                            data-test-id="ContactPage-EditButton"
                            sx={{ display: 'flex', gap: 1.5 }}
                            color="inherit"
                            disabled={isLoading}
                            onClick={openEditContactModal}
                            size="small"
                        >
                            <EditIcon />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('Contact:button:edit')}
                            </Typography>
                        </Button>
                    </Box>
                ) : null}
            </Box>
            {isError ? (
                <ErrorState
                    onRefreshClick={fetchContact}
                    testID="ContactPage-ErrorState"
                />
            ) : (
                <ContactDetails
                    contact={contact}
                    isLoading={isLoading}
                    images={images}
                />
            )}
        </Box>
    );
}

export default ContactPage;
