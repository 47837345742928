import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InventoryIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <path
                d="M35.1 3.9H7.4c-1.1 0-2 .9-2 2v30.8c0 1.1.9 2 2 2h27.7c1.1 0 2-.9 2-2V5.8c-.1-1-.9-1.9-2-1.9zM7.5 33.7v-7.8h27.6v7.8H7.5zm0-17.6H35V24H7.5v-7.9zM35 5.8V14H7.5V5.9L35 5.8z"
                fill={color}
            />
        </svg>
    );
}

export default memo(InventoryIcon);
