import { Box } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { EventModel } from 'shared/types/Events';
import { getFromISO } from 'shared/utils/date';

import {
    AddEditScheduleModal,
    CollapsibleContainer,
    EmptyState,
    SchedulesList,
} from '@/components';
import { ROUTE } from '@/router/routes';
import { t } from '@/services/translations/config';
import { getRoutePath } from '@/utils/router';

import { EventGroup, Props } from './types';

export function SchedulesGroupedList({ futureEvents, componentType }: Props) {
    const navigate = useNavigate();
    const getGroupedEvents = useCallback<() => EventGroup[]>(() => {
        const groupedEvents: { [key: string]: EventModel[] } = {};
        const sortedEvents = futureEvents.sort((event1, event2) =>
            event1.startsTime.localeCompare(event2.startsTime),
        );

        sortedEvents.forEach((event) => {
            const eventGroup = getFromISO(event.startsTime).toFormat(
                'MMM yyyy',
            );

            const addedGroupEvents = groupedEvents[eventGroup] ?? [];

            groupedEvents[eventGroup] = [...addedGroupEvents, event];
        });

        return Object.keys(groupedEvents).map((groupKey) => ({
            title: groupKey,
            events: groupedEvents[groupKey],
        }));
    }, [futureEvents]);

    const groups = useMemo(getGroupedEvents, [getGroupedEvents]);

    const [eventToEdit, setEventToEdit] = useState<EventModel | null>(null);
    const [isEditEventModalOpen, setIsEditEventModalOpen] =
        useState<boolean>(false);

    const openEditEventModal = useCallback(
        () => setIsEditEventModalOpen(true),
        [],
    );
    const closeEditEventModal = useCallback(() => {
        setIsEditEventModalOpen(false);
        setEventToEdit(null);
    }, []);

    const handleEventItemEditButtonPress = useCallback(
        (event: EventModel) => {
            setEventToEdit(event);
            openEditEventModal();
        },
        [openEditEventModal],
    );

    const navigateToAppointment = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, { id: event.id });

            navigate(route);
        },
        [navigate],
    );

    return (
        <Box sx={{ minWidth: 650 }}>
            {groups.length > 0 ? (
                groups.map((group) => (
                    <CollapsibleContainer
                        testID="SchedulesPage-MonthList"
                        title={group.title}
                        key={group.title}
                    >
                        <Box className="flex flex-col gap-4">
                            <SchedulesList
                                testIdPrefix="SchedulesPage-MonthList"
                                items={group.events}
                                onEditItemClick={handleEventItemEditButtonPress}
                                onItemClick={navigateToAppointment}
                                type={componentType}
                            />
                        </Box>
                    </CollapsibleContainer>
                ))
            ) : (
                <EmptyState
                    message={t(
                        'EventsList:empty_state:no_events_have_been_added',
                    )}
                />
            )}
            {isEditEventModalOpen ? (
                <AddEditScheduleModal
                    onClose={closeEditEventModal}
                    onSubmit={closeEditEventModal}
                    event={eventToEdit}
                    mode="edit"
                />
            ) : null}
        </Box>
    );
}
