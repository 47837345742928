import { Box, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { NavigationAnalytics } from 'shared/analytics/navigation';

import { Props } from '@/components/MembersSelection/types';
import { FirebaseAnalytics } from '@/services/firebase/analytics';

function MembersSelection({
    label,
    error,
    helperText,
    onBlur,
    onChange,
    name,
    entity,
    id,
    members,
    selectedMembersIds,
    sx,
}: Props) {
    const renderSelectedMembers = useCallback(
        (selectedMembersIds: string[]) =>
            members
                ?.filter((member) =>
                    selectedMembersIds.find(
                        (selectedMemberId) =>
                            member.user.id === selectedMemberId,
                    ),
                )
                .map((member) => {
                    return `${member.user.firstName} ${
                        member.user.lastName ?? ''
                    }`;
                })
                .join(', '),
        [members],
    );

    useEffect(() => {
        NavigationAnalytics.logUserOpenedTeamMembersArea(
            FirebaseAnalytics.logEvent,
            { entity },
        );
    }, [entity]);

    return (
        <TextField
            data-test-id="AddEditScheduleForm-Members"
            select
            id={id}
            label={label}
            error={error}
            helperText={helperText}
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            value={selectedMembersIds}
            SelectProps={{
                multiple: true,
                renderValue: renderSelectedMembers,
            }}
            sx={sx}
        >
            {members?.map((member) => (
                <MenuItem
                    key={member.user.id}
                    value={member.user.id}
                    data-test-id={`AddEditScheduleForm-Members${member.user.firstName}`}
                >
                    <Checkbox
                        checked={
                            !!selectedMembersIds?.find(
                                (id) => member.user.id === id,
                            )
                        }
                        data-test-id="Members-OptionCheckbox"
                        sx={{
                            padding: 0,
                            py: 0.5,
                            ml: -0.5,
                            mr: 1,
                        }}
                    />
                    <Box
                        className="rounded-full w-4 h-4 mr-1.5"
                        bgcolor={member.userColor}
                    />
                    <Typography>{`${member.user.firstName} ${
                        member.user.lastName ?? ''
                    }`}</Typography>
                </MenuItem>
            ))}
        </TextField>
    );
}

export default MembersSelection;
