import { RefObject, useLayoutEffect, useMemo } from 'react';

export function useResizeObserver(
    ref: RefObject<HTMLElement>,
    callback: VoidFunction,
) {
    const resizeObserver = useMemo(
        () => new ResizeObserver(callback),
        [callback],
    );

    useLayoutEffect(() => {
        if (ref.current) {
            resizeObserver.observe(ref.current);

            const refCopy = ref.current;
            return () => resizeObserver.unobserve(refCopy);
        }
    }, [ref, resizeObserver]);
}
