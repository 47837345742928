import { Q } from '@nozbe/watermelondb';
import { HorseColumnDescription } from '../../types/sort';
import { isFuture } from '../../utils/date';

import { SORT_TYPE } from '../sort';

export enum HORSE_TABLE_COLUMN {
    HORSE = 'HORSE',
    CONTACT = 'CONTACT',
    LOCATION = 'LOCATION',
    RECENT_ENTRY = 'RECENT_ENTRY',
    APPOINTMENT = 'APPOINTMENT',
};
export const HorsesTableColumnDescription: HorseColumnDescription =  {
    [HORSE_TABLE_COLUMN.HORSE]: {
        columnId: 'HorsesList:table_columns:horse',
        query: (sort: SORT_TYPE) => Q.sortBy('name', sort),
    },
    [HORSE_TABLE_COLUMN.CONTACT]: {
        columnId: 'HorsesList:table_columns:contact',
        unsafeQuery: (filteredRaws: any, sort: SORT_TYPE, loki: any) => {
            const newRaws = filteredRaws.map((rawRecord) => {
                const horseContacts = loki
                    .getCollection('horse_contacts')
                    .find({
                        horse_id: { $eq: rawRecord.id },
                    });
                    
                const horseContactsNamesArray: string[] = [];
                for (const horseContact of horseContacts) {
                    const contact = loki
                        .getCollection('contacts')
                        .by('id', horseContact.contact_id);

                    horseContactsNamesArray.push(
                        `${contact.first_name?.toLowerCase() || ''} ${
                            contact.last_name?.toLowerCase() || ''
                        }`,
                    );
                }

                return {...rawRecord, contact_name: horseContactsNamesArray};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.contact_name < b.contact_name) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.contact_name > b.contact_name) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
    [HORSE_TABLE_COLUMN.LOCATION]: {
        columnId: 'HorsesList:table_columns:location',
        unsafeQuery(filteredRaws: any, sort: SORT_TYPE) {
            const newRaws = filteredRaws.map((rawRecord) => {
                const address: string = rawRecord.stable_address;
                return {...rawRecord, location: address ? address.toLowerCase() : ''};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.location < b.location) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.location > b.location) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
    [HORSE_TABLE_COLUMN.RECENT_ENTRY]: {
        columnId: 'HorsesList:table_columns:recent_entry',
        unsafeQuery(filteredRaws: any, sort: SORT_TYPE, loki: any) {
            const newRaws = filteredRaws.map((rawRecord) => {
                const horseEntries = loki
                    .getCollection('entries')
                    .find({
                        horse_id: { $eq: rawRecord.id },
                    });

                const futureEntries = horseEntries.filter((entry) => isFuture(entry.logged_time)).sort((a: any, b: any) => {
                    if (a.logged_time < b.logged_time) return -1;
                    if (a.logged_time > b.logged_time) return 1;
                    return 0;
                });

                if (futureEntries && futureEntries.length !== 0) {
                    const entryTimes: string[] = futureEntries.map(entry => entry.logged_time);
                    return {...rawRecord, entry_times: entryTimes[0]};
                }

                const pastEntries = horseEntries.filter((entry) => !isFuture(entry.logged_time)).sort((a: any, b: any) => {
                    if (a.logged_time < b.logged_time) return 1;
                    if (a.logged_time > b.logged_time) return -1;
                    return 0;
                });
                
                if (pastEntries && pastEntries.length !== 0) {
                    const entryTimes: string[] = pastEntries.map(entry => entry.logged_time);
                    return {...rawRecord, entry_times: entryTimes[0]};
                }
                return {...rawRecord, entry_times: []};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.entry_times < b.entry_times) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.entry_times > b.entry_times) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
    [HORSE_TABLE_COLUMN.APPOINTMENT]: {
        columnId: 'HorsesList:table_columns:appointment',
        unsafeQuery(filteredRaws: any, sort: SORT_TYPE, loki: any) {
            const newRaws = filteredRaws.map((rawRecord) => {
                const horseEvents = loki
                    .getCollection('event_horses')
                    .find({
                        horse_id: { $eq: rawRecord.id },
                    });

                const eventTimes: string[] = horseEvents.map(horseEvent => {
                    const event = loki
                    .getCollection('events')
                    .by('id', horseEvent.event_id);
                    return event.starts_time;
                });
                return {...rawRecord, event_times: eventTimes};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.event_times < b.event_times) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.event_times > b.event_times) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
};
