// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class EntryProcedure extends Model {
    static table = 'entry_procedures';

    static associations = {
        users: { type: 'belongs_to', key: 'user_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        entries: { type: 'belongs_to', key: 'entry_id' },
        procedures: { type: 'belongs_to', key: 'procedure_id' },
    };

    @lazy
    entries = this.collections
        .get('entries')
        .query(Q.where('id', this.entryId));

    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('entry_id') entryId;
    @field('procedure_id') procedureId;
    @field('price') price;
    @field('quantity') quantity;
    @field('name') name;

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('entries', 'entry_id') entry;
    @relation('procedures', 'procedure_id') procedure;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
