import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Collapse, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductImages } from 'shared/constants/products/images';
import { InventoryProductSize, ProductType } from 'shared/types/Products';
import {
    SelectedUnitType,
    ShoppingListProductSize,
} from 'shared/types/ShoppingList';

import { RotativeExpandMoreIcon } from '@/assets/svg';
import { Button, Tooltip } from '@/components';
import BackdropLoader from '@/components/Loaders/BackdropLoader';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { COLOR } from '@/theme/colors';

import TooltipSyncButton from '../TooltipButton';

import { SelectedProductSizesTable } from './components';
import { Props } from './types';

function SelectedProductsListItem({
    productSizes,
    type,
    name,
    materialShapeAndClips,
    onRemoveButtonClick,
    onQuantityChange,
    onUnitTypeChange,
    isExpandable = false,
    isLoading = false,
    renderCustomSizesTableColumn,
    testIdPrefix,
    isShoppingListProducts,
    showInventory = false,
    addedToInventory = false,
    boxUnitSize,
}: Props) {
    const { t } = useTranslation();
    const { isSyncInProgress } = useDBSyncContext();

    const [isExpanded, setIsExpanded] = useState(false);
    const [showAllSizes, setShowAllSizes] = useState(false);

    const addedProductSizes = useMemo(
        () => productSizes.filter((productSize) => productSize.quantity > 0),
        [productSizes],
    );

    const toggleAllSizes = useCallback(
        () => setShowAllSizes((value) => !value),
        [],
    );

    const toggleCollapsible = useCallback(() => {
        if (isExpandable) {
            setIsExpanded((collapsed) => !collapsed);
        }
    }, [isExpandable]);

    const getSizesString = useCallback(
        (productSizes: (InventoryProductSize | ShoppingListProductSize)[]) =>
            productSizes.map((productSize) => productSize.size).join(', ') ||
            '',
        [],
    );

    const sizesText = useMemo(
        () =>
            getSizesString(
                productSizes.filter((productSize) => productSize.quantity > 0),
            ),
        [getSizesString, productSizes],
    );

    const quantity = useMemo(
        () =>
            productSizes
                .filter((prod) => prod.quantity >= 0)
                .reduce(
                    (totalQuantity, productSize) =>
                        totalQuantity + productSize.quantity,
                    0,
                ),
        [productSizes],
    );

    const negativeQuantitySizes = useMemo(
        () => productSizes.filter((prod) => prod.quantity < 0),
        [productSizes],
    );

    const negativeQuantitiesWarnText = useMemo(() => {
        if (!negativeQuantitySizes.length) {
            return '';
        }

        if (type === ProductType.rasps) {
            return t('Inventory:alert:negative_quantity_rasps');
        } else if (type === ProductType.custom) {
            return t('Inventory:alert:negative_quantity_custom_products');
        }

        const negativeQuantitySizesText = getSizesString(negativeQuantitySizes);

        return t(
            negativeQuantitySizesText.split(',').length > 1
                ? 'Inventory:alert:negative_quantity_sizes'
                : 'Inventory:alert:negative_quantity_size',
            {
                sizes: negativeQuantitySizesText,
            },
        );
    }, [getSizesString, type, negativeQuantitySizes, t]);

    const handleRemoveButtonClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onRemoveButtonClick(name, materialShapeAndClips);
        },
        [materialShapeAndClips, name, onRemoveButtonClick],
    );

    const tooltipWarnElement = useMemo(
        () =>
            negativeQuantitiesWarnText ? (
                <Tooltip
                    title={
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: negativeQuantitiesWarnText,
                            }}
                            fontSize={14}
                        />
                    }
                >
                    <WarningIcon
                        sx={{
                            cursor: 'pointer',
                            mr: 2,
                            alignSelf: sizesText ? 'center' : 'flex-end',
                        }}
                        htmlColor={COLOR.vividYellow}
                    />
                </Tooltip>
            ) : null,
        [negativeQuantitiesWarnText, sizesText],
    );

    const quantityElement = useMemo(
        () => (
            <Typography
                data-test-id={`${testIdPrefix}-Product-Quantity`}
                sx={{
                    alignSelf: sizesText ? 'flex-start' : 'flex-end',
                    mr: 1.75,
                }}
            >
                {quantity}
            </Typography>
        ),
        [quantity, sizesText, testIdPrefix],
    );

    const handleUnitTypeChange = useCallback(
        (
            selectedUnitType: SelectedUnitType,
            productSize: ShoppingListProductSize,
            productSalesUnitId: string,
        ) => {
            if (onUnitTypeChange) {
                onUnitTypeChange(
                    selectedUnitType,
                    productSize,
                    productSalesUnitId,
                );
            }
        },
        [onUnitTypeChange],
    );

    const removeButton = useMemo(
        () => (
            <TooltipSyncButton
                tooltip={t('AddEditModalHeader:button_tooltip', {
                    defaultValue:
                        'Please wait for the synchronization to complete',
                })}
                showTooltip={isSyncInProgress}
                disabled={isSyncInProgress}
                onClick={handleRemoveButtonClick}
                testID={`${testIdPrefix}-Product-RemoveButton`}
                sx={{ display: 'flex', gap: 1.5 }}
                color="inherit"
            >
                <DeleteOutlineRoundedIcon />
                <Typography fontSize="small" fontWeight={700}>
                    {t('Actions:remove')}
                </Typography>
            </TooltipSyncButton>
        ),
        [handleRemoveButtonClick, isSyncInProgress, t, testIdPrefix],
    );

    return (
        <Box
            data-test-id={`${testIdPrefix}-Product${name}${
                materialShapeAndClips ?? ''
            }`.trim()}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 1.5,
                    flexDirection: sizesText ? 'column' : 'row',
                    justifyContent: 'space-between',
                    '&:hover': {
                        cursor: isExpandable ? 'pointer' : 'default',
                        backgroundColor: isExpandable
                            ? COLOR.transparentLightBlue
                            : 'inherit',
                    },
                }}
                onClick={toggleCollapsible}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <img className="w-20 h-20" src={ProductImages[type]} />
                        <Box sx={{ mt: 1 }}>
                            <Typography
                                fontWeight={700}
                                fontSize={16}
                                data-test-id={`${testIdPrefix}-Product-Name`}
                            >
                                {name}
                            </Typography>
                            <Typography
                                data-test-id={`${testIdPrefix}-Product-MaterialShapeAndClips`}
                            >
                                {materialShapeAndClips}
                            </Typography>
                        </Box>
                        {isExpandable ? (
                            <Box sx={{ mt: 1 }}>
                                <RotativeExpandMoreIcon
                                    isExpanded={isExpanded}
                                />
                            </Box>
                        ) : null}
                        <Box sx={{ mt: 1 }}>
                            <Typography
                                fontSize={13}
                                fontWeight={300}
                                color={COLOR.black60}
                            >{`(${t(
                                'ShoppingList:label:click_to_expand',
                            )})`}</Typography>
                        </Box>
                    </Box>
                    {sizesText || isExpanded || !isExpandable ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: 'black60',
                            }}
                        >
                            {removeButton}
                        </Box>
                    ) : null}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        ml: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        {sizesText && !isExpanded && isExpandable ? (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        px: 2,
                                        py: 1.25,
                                        bgcolor: COLOR.vividYellow12,
                                        width: 100,
                                    }}
                                >
                                    <Typography>
                                        {t('Inventory:sizes')}
                                    </Typography>
                                </Box>
                                <Typography
                                    data-test-id={`${testIdPrefix}-Product-Sizes`}
                                >
                                    {sizesText}
                                </Typography>
                            </>
                        ) : null}
                        {sizesText && !isExpanded && isExpandable
                            ? tooltipWarnElement
                            : null}
                    </Box>
                    {!isExpanded && isExpandable ? (
                        !sizesText ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    mr: 2,
                                }}
                            >
                                {removeButton}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {tooltipWarnElement}
                                    {quantityElement}
                                </Box>
                            </Box>
                        ) : (
                            quantityElement
                        )
                    ) : null}
                </Box>
            </Box>
            <Collapse in={isExpanded || !isExpandable}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <SelectedProductSizesTable
                            productSizes={
                                !showAllSizes ? addedProductSizes : productSizes
                            }
                            onQuantityChange={onQuantityChange}
                            onUnitTypeChange={handleUnitTypeChange}
                            type={type}
                            renderCustomColumn={renderCustomSizesTableColumn}
                            testIdPrefix={testIdPrefix}
                            isShoppingListProducts={isShoppingListProducts}
                            showInventory={showInventory}
                            addedToInventory={addedToInventory}
                            boxUnitSize={boxUnitSize}
                        />
                        <BackdropLoader
                            isLoading={isLoading}
                            sx={{
                                position: 'absolute',
                                borderRadius: '1rem',
                                backgroundColor: COLOR.white60,
                                color: COLOR.ebonyClay,
                            }}
                            loadingText={t(
                                'Inventory:product_sizes:loading_text',
                            )}
                            testID={`${testIdPrefix}-Product-Loader`}
                        />
                    </Box>
                    {addedProductSizes.length !== productSizes.length ? (
                        <Button
                            color="primary"
                            caption={
                                showAllSizes
                                    ? t('Inventory:button:hide_sizes')
                                    : t('Inventory:button:show_all_sizes')
                            }
                            onClick={toggleAllSizes}
                            startIcon={
                                <RotativeExpandMoreIcon
                                    isExpanded={showAllSizes}
                                />
                            }
                            sx={{ alignSelf: 'center' }}
                            data-test-id={`${testIdPrefix}-Product-ToggleSizesButton`}
                        />
                    ) : null}
                </Box>
            </Collapse>
        </Box>
    );
}

export default memo(SelectedProductsListItem);
