import React, { useEffect, useMemo, useState } from 'react';
import { CountryCode } from 'shared/constants/countries';

import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useLocalizationContext } from '@/context/LocalizationContext';
import EarlyAccessForm from '@/pages/SignUpPage/components/EarlyAccessForm';
import SignUpForm from '@/pages/SignUpPage/components/SignUpForm';

import { SignUpStepsSkeleton } from './components';

const SignUpSteps = () => {
    const { initialRegion } = useLocalizationContext();

    const [selectedRegionCode, setSelectedRegionCode] = useState<
        CountryCode | undefined
    >(undefined);
    const [showEarlyAccessForm, setShowEarlyAccessForm] = useState(false);
    const [validAccessCode, setValidAccessCode] = useState<string | undefined>(
        undefined,
    );
    const { requireUnitedStatesEarlyAccess } = useFeatureFlagsContext();

    const isUnitedStatesRegion =
        selectedRegionCode === CountryCode['unitedStates'];
    const requireAccessCode = useMemo(
        () =>
            !validAccessCode &&
            isUnitedStatesRegion &&
            requireUnitedStatesEarlyAccess === true,
        [isUnitedStatesRegion, requireUnitedStatesEarlyAccess, validAccessCode],
    );

    useEffect(() => {
        setSelectedRegionCode(initialRegion?.region?.code);
    }, [initialRegion?.region?.code]);

    useEffect(() => {
        setShowEarlyAccessForm(requireAccessCode);
    }, [requireAccessCode]);

    const skipAccessCodeStep = () => {
        setSelectedRegionCode(undefined);
        setShowEarlyAccessForm(false);
    };

    const onContinue = (accessCode: string) => {
        setValidAccessCode(accessCode);
    };

    const handleSelectCountry = (country: CountryCode) => {
        setSelectedRegionCode(country);
    };

    const resetAccessCode = () => {
        setValidAccessCode(undefined);
    };

    if (
        initialRegion.status !== 'initialized' ||
        requireUnitedStatesEarlyAccess === undefined
    ) {
        return <SignUpStepsSkeleton />;
    }

    if (showEarlyAccessForm) {
        return (
            <EarlyAccessForm
                onSkip={skipAccessCodeStep}
                onContinue={onContinue}
            />
        );
    }

    return (
        <SignUpForm
            validAccessCode={validAccessCode}
            handleSelectCountry={handleSelectCountry}
            resetAccessCode={resetAccessCode}
            selectedRegionCode={selectedRegionCode}
        />
    );
};

export default SignUpSteps;
