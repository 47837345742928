export enum LOCALE {
    en_AU = 'en-AU',
    en_US = 'en-US',
    nl_NL = 'nl-NL',
}

export enum COUNTRY {
    AU = 'AU',
    US = 'US',
    NL = 'NL',
}

export enum LANGUAGES_HUMANIZED {
    english_australia = 'English (Australia)',
    english_usa = 'English (USA)',
    dutch_netherlands = 'Dutch (Netherlands)',
}

export enum COUNTRIES_HUMANIZED {
    australia = 'Australia',
    usa = 'USA',
    netherlands = 'Netherlands',
}

export type LanguagesDictionary = {
    [key: string]: LOCALE;
};

export type LanguageCodesDictionary = {
    [key: string]: string;
};

export type CountriesDictionary = {
    [key: string]: COUNTRY;
};
