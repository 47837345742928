import { SHOPPING_PRODUCT_UNIT } from '../shoppingProductUnit';

export enum PRODUCT_UNIT_TYPES_MAP {
    shoes = 'CTN',
    nails = 'PKT',
    custom = 'Box',
}

export enum PRODUCT_UNIT_TO_ALIAS_MAP {
    CTN2 = SHOPPING_PRODUCT_UNIT.pair,
    CTN4 = SHOPPING_PRODUCT_UNIT.set,
}

// This threshold means that the the nails box unit size will change from PKT to CTN
export const NAILS_DESCRIPTION_CHANGE_TRESHOLD = {
    threshold: 1000,
    description: 'CTN',
};

export const UNIT_SIZES = {
    box250: 'box 250',
    box2: 'box 2',
    pair: 'pair',
    pack: 'pack',
};

export const PRODUCT_TYPE_RESTRICTIONS = {
    nails: {
        threshold: 250,
        propertyToRemove: UNIT_SIZES.pack,
    },
    shoes: {
        threshold: 4,
        propertyToRemove: UNIT_SIZES.pair,
    },
};
