import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import React, { useMemo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function RSVPBadge({ status, type }: Props) {
    const label = useMemo(() => {
        const isConfirmed = type === 'confirmed';

        if (status.contactToShow) {
            const name = `${status.contactToShow.firstName} ${
                status.contactToShow.lastName || ''
            }`;
            return t(
                isConfirmed
                    ? 'Event:status:confirmed_by'
                    : 'Event:status:declined_by',
                {
                    name,
                },
            );
        }

        if (status.confirmedNumber) {
            return t(
                isConfirmed
                    ? 'Event:status:partial_confirmed'
                    : 'Event:status:partial_declined',
                {
                    confirmedTotal: status.confirmedNumber,
                    total: status.contactsCount,
                },
            );
        }

        return t(
            isConfirmed ? 'Event:status:confirmed' : 'Event:status:declined',
        );
    }, [status, type]);

    return (
        <div
            className={clsx('w-fit mt-[5px] rounded-lg', {
                'bg-confirmGreen05': type === 'confirmed',
                'bg-eventErrorRed': type === 'declined',
            })}
        >
            <div className="flex flex-row gap-1 items-center py-[5px] px-2">
                {type === 'confirmed' ? (
                    <CheckCircleIcon sx={{ color: COLOR.confirmGreen }} />
                ) : (
                    <EventBusyIcon sx={{ color: COLOR.spanishRed }} />
                )}
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '15px',
                        color:
                            type === 'confirmed'
                                ? COLOR.badgeTextGrey
                                : COLOR.spanishRed,
                    }}
                >
                    {label}
                </Typography>
            </div>
        </div>
    );
}

export default RSVPBadge;
