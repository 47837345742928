import {
    RolesDictionaryObject,
    RolesHumanizedObject,
} from '../../types/contactRoles';
import { swapObjectKeysWithValues } from '../../utils/data';

export enum CONTACT_ROLE {
    dealer = 'dealer',
    owner = 'owner',
    farrier = 'farrier',
    vet = 'vet',
    trainer = 'trainer',
    trimmer = 'trimmer',
    rider = 'rider',
    stable_manager = 'stable_manager',
}

export const CONTACT_ROLES_DICTIONARY: RolesDictionaryObject = {
    [CONTACT_ROLE.dealer]: 'Contact:roles:dealer',
    [CONTACT_ROLE.owner]: 'Contact:roles:owner',
    [CONTACT_ROLE.farrier]: 'Contact:roles:farrier',
    [CONTACT_ROLE.vet]: 'Contact:roles:vet',
    [CONTACT_ROLE.trainer]: 'Contact:roles:trainer',
    [CONTACT_ROLE.trimmer]: 'Contact:roles:trimmer',
    [CONTACT_ROLE.rider]: 'Contact:roles:rider',
    [CONTACT_ROLE.stable_manager]: 'Contact:roles:stable_manager',
};

export const CONTACT_ROLES_TRANSLATION_KEYS: string[] = Object.values(
    CONTACT_ROLES_DICTIONARY,
);

export const CONTACT_ROLES_HUMANIZED_KEYS: RolesHumanizedObject =
    swapObjectKeysWithValues(CONTACT_ROLES_DICTIONARY);
