import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateQuantityValue } from 'shared/utils/validation';

import { Props } from './types';

function EditProcedureDataModal({
    close,
    isOpen,
    onSaveButtonClick,
    product,
}: Props) {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [productQuantity, setProductQuantity] = useState(
        product?.quantity.toString() || '0',
    );

    useEffect(() => {
        if (isOpen) {
            setError('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (product?.quantity) {
            setProductQuantity(product.quantity.toString() || '0');
        }
    }, [product]);

    const handleProductQuantityChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setProductQuantity(event.target.value);
        },
        [],
    );

    const handleSaveButtonClick = useCallback(async () => {
        if (!product) {
            return;
        }

        try {
            await validateQuantityValue(productQuantity, t);

            onSaveButtonClick(product.productId, productQuantity);
            close();
        } catch (error) {
            setError(error);
        }
    }, [close, onSaveButtonClick, product, productQuantity, t]);

    return (
        <Dialog data-test-id="EditProductDataModal" open={isOpen}>
            <DialogTitle>
                {t('Product:update_quantity:title', {
                    product: product?.name || '',
                })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText data-test-id="AlertText">
                    {t('Product:update_quantity:explanation')}
                </DialogContentText>
                <TextField
                    data-test-id="EditProductDataModal-QuantityInput"
                    error={!!error}
                    fullWidth
                    helperText={error || ''}
                    id="quantity"
                    label={t('Procedure:label:quantity')}
                    onChange={handleProductQuantityChange}
                    name="title"
                    sx={{ mt: 3 }}
                    value={productQuantity}
                />
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Button
                    onClick={close}
                    data-test-id="EditProductDataModal-CancelButton"
                >
                    {t('Actions:cancel')}
                </Button>
                <Button
                    color="primary"
                    data-test-id="EditProductDataModal-SaveButton"
                    onClick={handleSaveButtonClick}
                >
                    {t('Actions:save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditProcedureDataModal;
