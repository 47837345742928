import { PaletteOptions } from '@mui/material/styles';

import { COLOR } from '@/theme/colors';

export const palette: PaletteOptions = {
    primary: {
        main: COLOR.deepCerulean,
    },
    text: {
        disabled: COLOR.regentGray,
        primary: COLOR.ebonyClay,
        secondary: COLOR.gray,
    },
    white: {
        main: COLOR.white,
        contrastText: COLOR.white,
    },
};
