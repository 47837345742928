// We set here the default values of new features
// to safely init our app in case we do smth wrong in firebase

type FeatureFlagValue = {
    web?: string | number | boolean;
    mobile?: string | number | boolean;
};

type FeatureFlags = Record<string, FeatureFlagValue>;

const featureFlags: FeatureFlags = {
    // EG: test_feature: { web: false, mobile: false },
};

export const getFeatureFlagsInitValuesFor = (platform: 'web' | 'mobile') => {
    const keys = Object.keys(featureFlags);
    const defaultValues = keys.reduce(
        (prev, key) => ({ ...prev, [key]: featureFlags[key][platform] }),
        {},
    );
    return defaultValues;
};
