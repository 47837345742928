import { useCallback, useEffect, useState } from 'react';
import Database from 'shared/db/services/Database.web';
import { UserModel } from 'shared/types/User';
import { getEventCardColor } from 'shared/utils/events';

import { useUserContext } from '@/context/UserContext';
import { mapUsersWithColors } from '@/services/user';

export function useEntityColor(entityUsers: UserModel[]) {
    const { userProfileData } = useUserContext();

    const [dateColor, setDateColor] = useState('');

    const getDateColor = useCallback(async () => {
        const database = Database.getDatabase();

        const usersWithColors = await mapUsersWithColors(entityUsers, database);
        return await getEventCardColor(
            userProfileData?.id || '',
            usersWithColors,
        );
    }, [userProfileData?.id, entityUsers]);

    const fetchColor = useCallback(async () => {
        const color = await getDateColor();
        setDateColor(color);
    }, [getDateColor]);

    useEffect(() => {
        fetchColor();
    }, [fetchColor]);

    return dateColor;
}
