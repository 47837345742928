import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingListModel } from 'shared/types/ShoppingList';

import { ShoppingListsList, TruncatedListSection } from '@/components';
import { Props as ShoppingListsListProps } from '@/components/ShoppingListsList/types';
import { ROUTE } from '@/router/routes';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function ShoppingListsSection({
    buttonText,
    emptyMessage,
    shoppingLists,
    isLoading,
    onButtonClick,
    showButtonAlways,
    title,
}: Props) {
    const navigate = useNavigate();

    const navigateToShoppingList = useCallback(
        (shoppingList: ShoppingListModel) => {
            const route = getRoutePath(ROUTE.shoppingList, {
                id: shoppingList.id,
            });

            navigate(route);
        },
        [navigate],
    );

    return (
        <TruncatedListSection<ShoppingListsListProps>
            ListComponent={ShoppingListsList}
            listComponentProps={{
                clickable: true,
                items: shoppingLists,
                itemWrapperStyle: 'pb-0.5',
                onItemClick: navigateToShoppingList,
                testIdPrefix: 'ShoppingListsSection',
            }}
            title={title}
            emptyMessage={emptyMessage}
            buttonText={buttonText}
            isLoading={isLoading}
            onButtonClick={onButtonClick}
            showButtonAlways={showButtonAlways}
        />
    );
}

export default ShoppingListsSection;
