import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Button,
    Switch,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import Procedure from 'shared/db/services/Procedure';

import { AddEditProcedureModal, Tooltip } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function ProceduresTableItem({ procedure }: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const [isEditProcedureModalOpen, setIsEditProcedureModalOpen] =
        useState<boolean>(false);

    const openEditProcedureModal = useCallback(
        () => setIsEditProcedureModalOpen(true),
        [],
    );

    const closeAddProcedureModal = useCallback(
        () => setIsEditProcedureModalOpen(false),
        [],
    );

    const handleEditButtonClicked = useCallback(
        () => openEditProcedureModal(),
        [openEditProcedureModal],
    );

    const onProcedureAvailableChange = useCallback(
        (_, checked: boolean) => {
            try {
                const procedureService = new Procedure({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                procedureService.update(procedure.id, {
                    available: checked,
                });

                Snackbar.showToastNotification({
                    message: t('App:Messages:has_been_edited_successfully', {
                        entity: t('Entities:procedure'),
                    }),
                });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [ImagesService, database, procedure.id],
    );

    const userRegion = useRegion();
    const currency = userRegion?.currencySymbol || '$';
    const parsedPrice = useMemo(() => {
        let priceValue = Number(procedure.price);
        if (isNaN(priceValue)) {
            priceValue = 0;
        }

        return priceValue;
    }, [procedure.price]);

    const isPriceZero = parsedPrice === 0;
    const isProcedureAvailable = procedure.available;

    let AvailabilitySwitch: JSX.Element;
    if (isSyncInProgress) {
        AvailabilitySwitch = (
            <Tooltip
                title={t('AddEditModalHeader:button_tooltip', {
                    defaultValue:
                        'Please wait for the synchronization to complete',
                })}
            >
                <Box>
                    <Switch
                        disabled
                        data-test-id="ProceduresPage-TableRowAvailable"
                        checked={procedure.available}
                        onChange={onProcedureAvailableChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
            </Tooltip>
        );
    } else {
        AvailabilitySwitch = (
            <Box>
                <Switch
                    data-test-id="ProceduresPage-TableRowAvailable"
                    checked={procedure.available}
                    onChange={onProcedureAvailableChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Box>
        );
    }

    return (
        <>
            <TableRow
                data-test-id={`ProceduresPage-TableRow${procedure.name}`}
                sx={{
                    '& > td': {
                        padding: 0,
                        border: 0,
                    },
                    '& > td > div': {
                        bgcolor: !isProcedureAvailable
                            ? COLOR.lightGrayishBlue2
                            : isPriceZero
                            ? COLOR.lightWarningYellow
                            : COLOR.lightGrayishBlue,
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        height: 63,
                    },
                    '&:not(:last-child)': {
                        borderBottomWidth: 8,
                        borderBottomColor: 'white',
                    },
                }}
            >
                <TableCell scope="row" width="30%" sx={{ maxWidth: '8rem' }}>
                    <Box
                        sx={{
                            borderTopLeftRadius: '0.5rem',
                            borderBottomLeftRadius: '0.5rem',
                        }}
                    >
                        <Typography
                            fontWeight="bold"
                            data-test-id="ProceduresPage-TableRowName"
                            color={
                                !isProcedureAvailable
                                    ? COLOR.transparentEbonyClay
                                    : 'inherit'
                            }
                            title={procedure.name}
                            noWrap
                            sx={{ flex: 1 }}
                        >
                            {procedure.name}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell
                    scope="row"
                    width="40%"
                    sx={{
                        maxWidth: '12rem',
                    }}
                >
                    <Box>
                        <Typography
                            data-test-id="ProceduresPage-TableRowDescription"
                            color={
                                !isProcedureAvailable
                                    ? COLOR.transparentEbonyClay
                                    : 'inherit'
                            }
                            noWrap
                            title={procedure.description || '-'}
                        >
                            {procedure.description || '-'}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell scope="row" width="20%" sx={{ minWidth: 130 }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Typography
                            data-test-id="ProceduresPage-TableRowPrice"
                            color={
                                isPriceZero
                                    ? COLOR.warningYellow
                                    : !isProcedureAvailable
                                    ? COLOR.transparentEbonyClay
                                    : 'inherit'
                            }
                            fontWeight={isPriceZero ? 'bold' : 400}
                        >
                            {`${currency} ${parsedPrice.toFixed(2)}`}
                        </Typography>
                        {isPriceZero ? (
                            <WarningIcon htmlColor={COLOR.vividYellow} />
                        ) : null}
                    </Box>
                </TableCell>
                <TableCell scope="row" width="5%">
                    {AvailabilitySwitch}
                </TableCell>
                <TableCell scope="row" width="5%">
                    <Box
                        sx={{
                            borderTopRightRadius: '0.5rem',
                            borderBottomRightRadius: '0.5rem',
                        }}
                    >
                        <Button
                            className="min-w-0"
                            data-test-id="ProceduresPage-TableRowEditButton"
                            onClick={handleEditButtonClicked}
                            size="small"
                        >
                            {t('Actions:edit')}
                        </Button>
                    </Box>
                </TableCell>
            </TableRow>
            {isEditProcedureModalOpen ? (
                <AddEditProcedureModal
                    close={closeAddProcedureModal}
                    isEditMode={true}
                    procedure={procedure}
                />
            ) : null}
        </>
    );
}

export default ProceduresTableItem;
