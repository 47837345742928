import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { memo } from 'react';

import { AddToInventoryIcon } from '@/assets/svg';
import { AddProductsModal } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function AddInventoryProductModal({ onCancel, onMainAction }: Props) {
    const { isSyncInProgress } = useDBSyncContext();

    return (
        <AddProductsModal
            icon={
                <Box
                    sx={{
                        width: 40,
                        height: 36,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <AddToInventoryIcon color={COLOR.deepCerulean} />
                </Box>
            }
            mainActionButtonCaption={t('Inventory:modal:add_to_inventory', {
                defaultValue: 'Add to inventory',
            })}
            onMainAction={onMainAction}
            onCancel={onCancel}
            title={t('Inventory:modal:add_products_title', {
                defaultValue: 'Add products',
            })}
            showUnitTypes={false}
            disableMainActionButton={isSyncInProgress}
        />
    );
}

export default memo(AddInventoryProductModal);
