import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button as MUIButton } from '@mui/material';
import React, { useCallback, useRef } from 'react';

import { TeamIcon } from '@/assets/svg';
import { Button } from '@/components';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function TeamPhotoSelector({ currentPhotoUrl, onPhotoChanged }: Props) {
    const photoInputRef = useRef<HTMLInputElement | null>(null);

    const handlePhotoChange = useCallback(
        (event) => {
            const image = event.target.files && event.target.files[0];
            if (!image) {
                return;
            }

            onPhotoChanged(URL.createObjectURL(image));

            event.target.value = null;
        },
        [onPhotoChanged],
    );

    const handleAddPhotoClick = useCallback(
        () => photoInputRef?.current?.click(),
        [photoInputRef],
    );

    const handleRemovePhotoClick = useCallback(
        () => onPhotoChanged(null),
        [onPhotoChanged],
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 3,
            }}
        >
            <Box
                sx={{
                    width: '98px',
                    height: '100px',
                    display: 'flex',
                    borderRadius: '8px',
                    position: 'relative',
                    overflow: 'visible',
                    p: '2px',
                    backgroundColor: 'white',
                    boxShadow: '0px 3px 6px #00000029',
                }}
            >
                {currentPhotoUrl ? (
                    <MUIButton
                        data-test-id="AddContactForm-RemovePhotoButton"
                        onClick={handleRemovePhotoClick}
                        size="small"
                        sx={{
                            borderRadius: '9999px',
                            position: 'absolute',
                            zIndex: 9,
                            minWidth: 0,
                            bgcolor: COLOR.paleSky02,
                            mr: 1.25,
                            mt: 0.375,
                        }}
                        variant="text"
                        color="primary"
                    >
                        <CloseIcon
                            htmlColor={COLOR.ebonyClay}
                            sx={{ fontSize: 16 }}
                        />
                    </MUIButton>
                ) : null}
                <MUIButton
                    sx={{
                        width: '96px',
                        height: '96px',
                        display: 'flex',
                        borderRadius: '8px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                    onClick={handleAddPhotoClick}
                >
                    <Avatar
                        src={currentPhotoUrl || undefined}
                        sx={{
                            cursor: 'inherit',
                            width: '96px',
                            height: '96px',
                            backgroundColor: COLOR.eclipse,
                            fontSize: 28,
                            fontWeight: 700,
                        }}
                        variant="square"
                    >
                        <TeamIcon height={60} width={60} color={'grey'} />
                    </Avatar>
                </MUIButton>
            </Box>

            <Button
                onClick={handleAddPhotoClick}
                size="small"
                sx={{
                    fontSize: 12,
                    color: ENTITY_COLOR.teams,
                    fontWeight: 'normal',
                }}
                variant="text"
                color="primary"
                caption={
                    currentPhotoUrl
                        ? t('TeamPhotoSelector:edit_logo', {
                              defaultValue: 'Edit Team logo',
                          })
                        : t('TeamPhotoSelector:add_logo', {
                              defaultValue: 'Add team logo',
                          })
                }
            />
            <input
                style={{ display: 'none' }}
                ref={photoInputRef}
                type="file"
                onChange={handlePhotoChange}
                accept="image/*"
            />
        </Box>
    );
}

export default TeamPhotoSelector;
