import { useCallback, useEffect, useState } from 'react';
import User from 'shared/db/services/User';
import { RegionModel } from 'shared/types/Region';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import Logger from '@/services/logger';

import { useDatabase } from './useDatabase';

export function useRegion() {
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const [region, setRegion] = useState<RegionModel | undefined>();
    const { ImagesService } = useImagesContext();
    const { isInitialSyncInProgress } = useDBSyncContext();

    const initRegion = useCallback(async () => {
        const userService = new User({
            database: getDatabase(),
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
        });

        const region = await userService.getUserRegion(
            userProfileData?.id || '',
        );

        setRegion(region);
    }, [ImagesService, getDatabase, userProfileData?.id]);

    useEffect(() => {
        if (!isInitialSyncInProgress && userProfileData?.id) {
            initRegion();
        }
    }, [initRegion, isInitialSyncInProgress, userProfileData?.id]);

    return region;
}
