import { GlobalStylesProps } from '@mui/material';

import { COLOR } from '@/theme/colors';

export const globalStyles: GlobalStylesProps['styles'] = {
    a: {
        color: COLOR.deepCerulean,
        textDecoration: 'none',
    },
};
