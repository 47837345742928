const COMPRESS_LARGE_DIMENSION: number = 2048;
const COMPRESS_SMALL_DIMENSION: number = 512;
const COMPRESS_QUALITY: number = 80;
const COMPRESS_FORMAT: 'JPEG' | 'PNG' | 'WEBP' = 'JPEG';

const PhotoConfig = {
    COMPRESS_LARGE_DIMENSION,
    COMPRESS_SMALL_DIMENSION,
    COMPRESS_QUALITY,
    COMPRESS_FORMAT,
};

export const largeEntitiesPhotos = ['Entry', 'Horse'];

export default PhotoConfig;
