import { Box, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES_DICTIONARY } from 'shared/constants/localization';
import { LOCALE } from 'shared/constants/localization/types';

import { useLocalizationContext } from '@/context/LocalizationContext';
import { updateLocale } from '@/services/localization';

import { Props } from './types';

function LocaleSelector({
    onLanguageChange,
    onRegionChange,
    languageValue,
    regionValue,
    testIdPrefix,
    languageOnly,
}: Props) {
    const { t } = useTranslation();

    const { regions, languages, isLoading } = useLocalizationContext();

    const changeLanguage = useCallback(
        (language: LOCALE) => {
            onLanguageChange(language);
            updateLocale(language);
        },
        [onLanguageChange],
    );

    const onLanguageChangeEvent = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            changeLanguage(event.target.value as LOCALE),
        [changeLanguage],
    );

    const onRegionChangeEvent = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (!onRegionChange) {
                return;
            }

            const regionCode = event.target.value;
            onRegionChange(regionCode);

            const region =
                regions.find((region) => region.code === regionCode) || null;

            if (region?.proposed_language) {
                changeLanguage(region.proposed_language);
            }
        },
        [onRegionChange, regions, changeLanguage],
    );

    return (
        <Box
            sx={{
                display: 'flex',
                mb: 4,
                mr: { xs: 2, md: 0 },
                maxWidth: 512,
                flexWrap: 'wrap',
            }}
        >
            {!languageOnly ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        mr: 8.5,
                        mb: 2,
                    }}
                >
                    <InputLabel sx={{ fontSize: 14, color: 'black' }}>
                        {t('Login:country')}
                    </InputLabel>
                    {isLoading ? (
                        <Skeleton
                            height={70}
                            sx={{ borderRadius: '4px', mr: 2, my: 2 }}
                            variant="text"
                            width={200}
                        />
                    ) : (
                        <Select
                            sx={{ width: 200 }}
                            value={regionValue}
                            onChange={onRegionChangeEvent}
                            id="region"
                            size="small"
                            data-test-id={`${testIdPrefix}-LocaleSelector-region`}
                        >
                            {regions.map((region) => (
                                <MenuItem
                                    value={region.code}
                                    key={region.code}
                                    data-test-id={`${testIdPrefix}-LocaleSelector-region-${region.code}`}
                                >
                                    {region.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Box>
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <InputLabel sx={{ fontSize: 14, color: 'black' }}>
                    {t('Login:language')}
                </InputLabel>
                {isLoading ? (
                    <Skeleton
                        height={70}
                        sx={{ borderRadius: '4px', mr: 2, my: 2 }}
                        variant="text"
                        width={200}
                    />
                ) : (
                    <Select
                        sx={{ width: 200 }}
                        value={languageValue}
                        onChange={onLanguageChangeEvent}
                        id="language"
                        size="small"
                        data-test-id={`${testIdPrefix}-LocaleSelector-language`}
                    >
                        {languages.map((language) => (
                            <MenuItem
                                value={language}
                                key={language}
                                data-test-id={`${testIdPrefix}-LocaleSelector-language-${language}`}
                            >
                                {LANGUAGE_CODES_DICTIONARY[language]}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </Box>
        </Box>
    );
}

export default LocaleSelector;
