import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { MemberItem, SectionHeader } from '@/components';
import { t } from '@/services/translations/config';

import { Props } from './types';

function InvitationsSection({ invitations, onApprove, onDelete }: Props) {
    const [disabled, setDisabled] = useState(false);

    const handleApprove = useCallback(
        async (id: string) => {
            setDisabled(true);
            await onApprove(id);
            setDisabled(false);
        },
        [onApprove],
    );

    const handleDelete = useCallback(
        async (id: string) => {
            setDisabled(true);
            await onDelete(id);
            setDisabled(false);
        },
        [onDelete],
    );

    return (
        <Box>
            <SectionHeader
                title={t('TeamsPage:pending_approval', {
                    defaultValue: 'Pending approval',
                })}
            />
            <Box sx={{ px: '16px' }}>
                {invitations?.map((inv) => (
                    <MemberItem
                        disabled={disabled}
                        key={inv.id}
                        id={inv.user_id}
                        name={inv.user_name}
                        showBadge
                        onApprove={() => handleApprove(inv.id)}
                        onDelete={() => handleDelete(inv.id)}
                        subtitle={t('TeamsPage:accepted', {
                            defaultValue: 'Accepted your invitation',
                        })}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default InvitationsSection;
