import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function AddEntryAlert({
    isOpen,
    onConfirm,
    onClose,
    testID,
    isProceduresMissingAlert = false,
}: Props) {
    const { t } = useTranslation();

    const handleConfirmClick = useCallback(() => {
        onConfirm(false);
        onClose();
    }, [onClose, onConfirm]);

    const handleConfirmAndDontShowAgain = useCallback(() => {
        onConfirm(true);
        onClose();
    }, [onClose, onConfirm]);

    return (
        <Dialog open={isOpen} data-test-id={testID}>
            <DialogTitle>{t('Entry:onsave:title')}</DialogTitle>
            <DialogContent>
                <DialogContentText data-test-id="AlertText">
                    {t(
                        isProceduresMissingAlert
                            ? 'Entry:onsave:all_procedures_question'
                            : 'Entry:onsave:all_information_question',
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Box>
                    <Button onClick={onClose} data-test-id="CancelButton">
                        {t('Actions:no')}
                    </Button>
                </Box>
                <Box>
                    <Button
                        onClick={handleConfirmAndDontShowAgain}
                        data-test-id="ConfirmAndDontShowAgainButton"
                    >
                        {t('Actions:dont_show_again')}
                    </Button>
                    <Button
                        onClick={handleConfirmClick}
                        color="primary"
                        autoFocus
                        data-test-id="ConfirmButton"
                    >
                        {t('Actions:yes')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default AddEntryAlert;
