import { useEffect } from 'react';

export function useScrollToDocumentTopOnValueChange<T>(
    documentElementId: string,
    value: T,
): void {
    useEffect(
        () =>
            document
                .getElementById(documentElementId)
                ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
        [documentElementId, value],
    );
}
