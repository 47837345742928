import { CopyAll } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SchedulesAnalytics } from 'shared/analytics/schedules/schedules';
import Database from 'shared/db/services/Database.web';
import Event from 'shared/db/services/Event';
import EventContact from 'shared/db/services/EventContact';
import { ContactModel } from 'shared/types/Contacts';
import { EventContactModel } from 'shared/types/EventContact';
import {
    ContactInvitations,
    ContactRSVPStatus,
    EventModel,
} from 'shared/types/Events';
import { calculateContactInvitationState } from 'shared/utils/events';
import moment from 'shared/utils/moment';

import {
    AddEditContactModal,
    AddEditHorseModal,
    AddEditScheduleModal,
    AppointmentInvitationInPastModal,
    BackLinkButton,
    ErrorState,
    NoAppointmentContactDetailsModal,
    TooltipSyncButton,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { getRoutePath } from '@/utils/router';

import AppointmentDetails from './components/AppointmentDetails.tsx';

function AppointmentPage() {
    const { id } = useParams();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { getDatabase } = useDatabase();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const eventContactService = useMemo(
        () =>
            new EventContact({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const [event, setEvent] = useState<EventModel | null>(null);
    const [eventContacts, setEventContacts] = useState<EventContactModel[]>();
    const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);
    const [modalMode, setModalMode] = useState<'edit' | 'duplicate'>('edit');
    const [contactsInvitationState, setContactsInvitationState] =
        useState<ContactInvitations>({});
    const [contactsRSVPStatus, setContactsRSVPStatus] =
        useState<ContactRSVPStatus>({});
    const [showNoPhoneModal, setShowNoPhoneModal] = useState(false);
    const [showNoEmailModal, setShowNoEmailModal] = useState(false);
    const [contactToEdit, setContactToEdit] = useState<ContactModel | null>(
        null,
    );
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const [showInPastModal, setShowInPastModal] = useState(false);

    const isInPast = useMemo(
        () =>
            event?.startsTime
                ? moment(event.startsTime).isBefore(moment())
                : false,
        [event?.startsTime],
    );

    const checkContactsInvitationState = useCallback(
        () =>
            setContactsInvitationState(
                eventContacts?.reduce(
                    (previous, current) => ({
                        ...previous,
                        [current.contactId]: {
                            sms: current.sentSMS,
                            email: current.sentEmail,
                            smsAt: current.sentSMSAt || null,
                            emailAt: current.sentEmailAt || null,
                            smsStatus: calculateContactInvitationState(
                                current.sentSMS,
                                current.sentSMSAt,
                            ),
                            emailStatus: calculateContactInvitationState(
                                current.sentEmail,
                                current.sentEmailAt,
                            ),
                        },
                    }),
                    {},
                ) ?? {},
            ),
        [eventContacts],
    );

    const checkContactsRSVPStatus = useCallback(() => {
        setContactsRSVPStatus(
            eventContacts?.reduce(
                (previous, current) => ({
                    ...previous,
                    [current.contactId]: current.status,
                }),
                {},
            ) ?? {},
        );
    }, [eventContacts]);

    const duplicatedEvent = useMemo<Partial<EventModel>>(
        () => ({
            date: moment(event?.startsTime),
            id: event?.id,
            title: `${event?.title} copy`,
            startsTime: moment(event?.startsTime).toISOString(),
            endsTime: moment(event?.endsTime).toISOString(),
            address: event?.address,
            contacts: event?.contacts,
            ownersReminder: event?.ownersReminder,
            horses: event?.horses,
            location: event?.location,
            notes: event?.notes,
            userReminder: event?.userReminder,
            timezone: event?.timezone,
            category: event?.category,
        }),
        [
            event?.startsTime,
            event?.id,
            event?.title,
            event?.endsTime,
            event?.address,
            event?.contacts,
            event?.ownersReminder,
            event?.horses,
            event?.location,
            event?.notes,
            event?.userReminder,
            event?.timezone,
            event?.category,
        ],
    );

    const modalEvent = useMemo(() => {
        switch (modalMode) {
            case 'edit':
                return event;
            case 'duplicate':
                return duplicatedEvent;
            default:
                return undefined;
        }
    }, [event, duplicatedEvent, modalMode]);

    const fetchEventContacts = useCallback(async () => {
        setEventContacts([
            ...(await eventContactService
                .getByEventId(event?.id ?? '')
                .fetch()),
        ]);
    }, [event?.id, eventContactService]);

    const fetchEvent = useCallback(async () => {
        setIsLoading(true);
        setIsError(false);

        try {
            const database = Database.getDatabase();

            const eventService = new Event({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                cacheService: {
                    clear: () => Promise.resolve(),
                    get: (() => Promise.resolve()) as () => any,
                },
                notificationsService: {
                    cancelNotification: () => null,
                    scheduleEventLocalNotification: () => Promise.resolve(),
                },
            });

            if (!id) {
                throw new Error();
            }

            const event = await eventService.getByID(id);
            setEvent(event);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [ImagesService, id]);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    useEffect(() => {
        if (event && !isSyncInProgress) {
            fetchEventContacts();
        }
    }, [event, isSyncInProgress, fetchEventContacts]);

    useEffect(() => {
        checkContactsInvitationState();
    }, [checkContactsInvitationState]);

    useEffect(() => {
        checkContactsRSVPStatus();
    }, [checkContactsRSVPStatus]);

    const handleContactSmsClick = useCallback(
        async (contact: ContactModel) => {
            await eventContactService.updateEventContactInvitations(
                event?.id ?? '',
                contact.id,
                { sendSMS: true },
            );
            checkContactsInvitationState();
        },
        [checkContactsInvitationState, event?.id, eventContactService],
    );

    const handleContactEmailClick = useCallback(
        async (contact: ContactModel) => {
            await eventContactService.updateEventContactInvitations(
                event?.id ?? '',
                contact.id,
                { sendEmail: true },
            );
            checkContactsInvitationState();
        },
        [checkContactsInvitationState, event?.id, eventContactService],
    );

    const handleContactSmsDisabledClick = useCallback(
        (contact: ContactModel) => {
            if (isInPast) {
                setShowInPastModal(true);
            } else {
                setShowNoPhoneModal(true);
                setContactToEdit(contact);
            }
        },
        [isInPast],
    );

    const handleContactEmailDisabledClick = useCallback(
        (contact: ContactModel) => {
            if (isInPast) {
                setShowInPastModal(true);
            } else {
                setShowNoEmailModal(true);
                setContactToEdit(contact);
            }
        },
        [isInPast],
    );

    const handleEditContact = useCallback(() => {
        setShowEditContactModal(true);
    }, []);

    const handleCloseNoDetailsModal = useCallback(() => {
        setShowNoEmailModal(false);
        setShowNoPhoneModal(false);
        setContactToEdit(null);
    }, []);

    const closeEditContactModal = useCallback(() => {
        setShowEditContactModal(false);
        setContactToEdit(null);
        handleCloseNoDetailsModal();
    }, [handleCloseNoDetailsModal]);

    const handleSaveContact = useCallback(
        (contact: ContactModel) => {
            if (showNoPhoneModal && contact.phone) {
                handleContactSmsClick(contact);
            } else if (showNoEmailModal && contact.email) {
                handleContactEmailClick(contact);
            }
        },
        [
            handleContactEmailClick,
            handleContactSmsClick,
            showNoEmailModal,
            showNoPhoneModal,
        ],
    );

    const closeInPastModal = useCallback(() => setShowInPastModal(false), []);

    const openEditEventModal = useCallback(() => {
        setIsEditEventModalOpen(true);
    }, []);

    const handleAppointmentDuplicate = useCallback(() => {
        setModalMode('duplicate');
        setIsEditEventModalOpen(true);
    }, []);

    const navigateToScheduleList = useCallback(() => {
        const path = getRoutePath(ROUTE.schedule);

        navigate(path, { replace: true });
    }, [navigate]);

    const closeEditEventModal = useCallback(() => {
        setModalMode('edit');
        setIsEditEventModalOpen(false);
    }, []);

    const navigateToAppointmentDetails = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, {
                id: event.id,
            });
            navigate(route);
        },
        [navigate],
    );

    const submitEditEventModal = useCallback(
        (event: EventModel) => {
            setIsEditEventModalOpen(false);

            fetchEventContacts();

            if (modalMode === 'duplicate') {
                navigateToAppointmentDetails(event);
                SchedulesAnalytics.logUserDuplicatedEvent(
                    FirebaseAnalytics.logEvent,
                );
            }

            setModalMode('edit');
        },
        [fetchEventContacts, modalMode, navigateToAppointmentDetails],
    );

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            {isEditEventModalOpen ? (
                <AddEditScheduleModal
                    onClose={closeEditEventModal}
                    onSubmit={submitEditEventModal}
                    event={modalEvent}
                    mode={modalMode}
                    onRemove={navigateToScheduleList}
                    onUpdate={fetchEventContacts}
                />
            ) : null}
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <BackLinkButton
                    text={t('Appointment:button:back')}
                    to={ROUTE.schedule}
                    testID="AppointmentPage-BackButton"
                />
                {!isError ? (
                    <Box
                        sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            gap: 3.375,
                        }}
                    >
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            testID="AppointmentPage-DuplicateButton"
                            sx={{
                                display: 'flex',
                                gap: 1.5,
                                color: 'black',
                            }}
                            color="inherit"
                            showTooltip={isSyncInProgress}
                            onClick={handleAppointmentDuplicate}
                            disabled={isLoading || isSyncInProgress}
                        >
                            <CopyAll />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('Appointment:button:duplicate')}
                            </Typography>
                        </TooltipSyncButton>
                        <Button
                            data-test-id="AppointmentPage-EditButton"
                            sx={{
                                display: 'flex',
                                gap: 1.5,
                                color: 'black',
                            }}
                            color="inherit"
                            disabled={isLoading}
                            onClick={openEditEventModal}
                            size="small"
                        >
                            <EditIcon />
                            <Typography fontSize="small" fontWeight={700}>
                                {t('Appointment:button:edit')}
                            </Typography>
                        </Button>
                    </Box>
                ) : null}
            </Box>
            {isError ? (
                <ErrorState
                    onRefreshClick={fetchEvent}
                    testID="AppointmentPage-ErrorState"
                />
            ) : (
                <AppointmentDetails
                    event={event}
                    isLoading={isLoading}
                    onContactSmsClick={handleContactSmsClick}
                    onSmsDisabledClick={handleContactSmsDisabledClick}
                    onContactEmailClick={handleContactEmailClick}
                    onEmailDisabledClick={handleContactEmailDisabledClick}
                    contactsInvitationState={contactsInvitationState}
                    contactsRSVPStatus={contactsRSVPStatus}
                    onRemoveEvent={navigateToScheduleList}
                    onEditAppointmentPress={openEditEventModal}
                />
            )}
            {showNoPhoneModal || showNoEmailModal ? (
                <NoAppointmentContactDetailsModal
                    type={showNoPhoneModal ? 'sms' : 'email'}
                    onEdit={handleEditContact}
                    onCancel={handleCloseNoDetailsModal}
                />
            ) : null}
            {showEditContactModal ? (
                <AddEditContactModal
                    contact={contactToEdit}
                    close={closeEditContactModal}
                    onSave={handleSaveContact}
                    isEditMode
                    CreateHorseModal={AddEditHorseModal}
                    enableRemoveContact={false}
                />
            ) : null}
            {showInPastModal ? (
                <AppointmentInvitationInPastModal onClose={closeInPastModal} />
            ) : null}
        </Box>
    );
}

export default AppointmentPage;
