export enum ACTIVE_FIELD {
    NONE,
    EMAIL,
    PHONE,
}

export enum RESET_PASSWORD_STATUS {
    START,
    PENDING,
    FINISHED,
}

export const UNPROCESSABLE_ENTITY_STATUS = 422;
export const INTERNAL_SERVER_ERROR = 500;
