import { Box, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingListsAnalytics } from 'shared/analytics/shoppingLists';
import Product from 'shared/db/services/Product';
import ShoppingList from 'shared/db/services/ShoppingList';
import User from 'shared/db/services/User';
import { AddProductParams } from 'shared/types/Products';
import {
    ShoppingListPayload,
    ShoppingListProductSize,
} from 'shared/types/ShoppingList';
import Moment from 'shared/utils/moment';
import {
    getGroupedProducts,
    getAddProductsDictionary,
} from 'shared/utils/product';
import { getShoppingListProducts } from 'shared/utils/shoppingList';

import { CartIcon } from '@/assets/svg';
import { Modal } from '@/components';
import AddProductsSection from '@/components/AddProductsSection';
import { AddProductsProvider } from '@/context/AddProductsContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { useBrowserExitPrompt, useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';
import { getRoutePath } from '@/utils/router';
import { ShoppingListValidationSchema } from '@/validations/shoppingList';

import { AddShoppingListFormValues, Props } from './types';

function AddShoppingListModal({ close }: Props) {
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { getDatabase } = useDatabase();
    const navigate = useNavigate();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation } = useOrganisationsContext();

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const [selectedProducts, setSelectedProducts] = useState<
        AddProductParams[]
    >([]);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const shoppingListService = useMemo(
        () =>
            new ShoppingList({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const productService = useMemo(
        () =>
            new Product({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const defaultTitle = useMemo(
        () => `List ${formatDate(Moment().toISOString(), 'date')}`,
        [],
    );

    const handleSubmit = useCallback(
        async (values: AddShoppingListFormValues) => {
            try {
                if (!userProfileData) {
                    throw new Error();
                }

                const { title } = values;
                const selectedProductsIds = selectedProducts.map(
                    (prod) => prod.id,
                );

                const productsDictionary =
                    getAddProductsDictionary(selectedProducts);

                const productsFromShoppingList = await productService.getByIDs(
                    selectedProductsIds,
                );

                const allGroupedProductsFromShoppingList = getGroupedProducts(
                    productsFromShoppingList,
                );

                const shoppingListProducts = await getShoppingListProducts({
                    groupedProductsForShoppingList:
                        allGroupedProductsFromShoppingList,
                    userId: userProfileData?.id ?? '',
                    params: {
                        imageService: ImagesService,
                        logDBAction: Logger.logRecordActivity,
                        logError: Logger.logError,
                        userId: userProfileData?.id ?? '',
                    },
                    database,
                    userService,
                    productService,
                });

                const shoppingListProductsWithQuantities =
                    shoppingListProducts.map((shoppingListProduct) => ({
                        ...shoppingListProduct,
                        productSizes: shoppingListProduct.productSizes.map(
                            (productSize) => {
                                const product =
                                    productsDictionary[productSize.productId];
                                return {
                                    ...productSize,
                                    quantity: parseInt(product.quantity, 10),
                                    selectedUnit:
                                        product.unitType ||
                                        productSize.selectedUnit,
                                } as ShoppingListProductSize;
                            },
                        ),
                    }));

                const payload: ShoppingListPayload = {
                    name: title,
                    shoppingListProducts: shoppingListProductsWithQuantities,
                };

                const shoppingList = await shoppingListService.add(
                    payload,
                    userProfileData.id,
                );

                ShoppingListsAnalytics.logUserCreatedShoppingList(
                    FirebaseAnalytics.logEvent,
                    {
                        shoppingList,
                        userChangedTitle: defaultTitle !== title,
                        isOwner: userOrganisation?.owner ?? true,
                    },
                );

                close();

                Snackbar.showToastNotification({
                    message: t('App:Messages:has_been_created_successfully', {
                        entity: t('Entities:shopping_list'),
                    }),
                });

                const newShoppingListPath = getRoutePath(ROUTE.shoppingList, {
                    id: shoppingList.id,
                });

                navigate(newShoppingListPath);
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            ImagesService,
            close,
            database,
            defaultTitle,
            navigate,
            productService,
            selectedProducts,
            shoppingListService,
            userProfileData,
            userService,
            userOrganisation?.owner,
        ],
    );

    const form = useFormik<AddShoppingListFormValues>({
        enableReinitialize: true,
        initialValues: {
            title: defaultTitle,
        },
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: ShoppingListValidationSchema.addShoppingListForm,
    });

    /* If form.handleSubmit is used has handleSubmitClick dependency, eslint shows an error
     if form is used has dependency then AddProductsSection component will rerender every time the form changes
     this is a workaround for that **/
    const submitForm = form.handleSubmit;

    const handleSubmitClick = useCallback(
        (args: AddProductParams[]) => {
            setSelectedProducts(args);
            submitForm();
        },
        [submitForm],
    );

    const productsSection = useMemo(
        () => (
            <AddProductsSection
                icon={
                    <Box
                        sx={{
                            width: 40,
                            height: 36,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CartIcon color={COLOR.deepCerulean} />
                    </Box>
                }
                onCancel={close}
                title={t('Inventory:modal:add_products_title', {
                    defaultValue: 'Add products',
                })}
                onMainAction={handleSubmitClick}
                mainActionButtonCaption={'Add shopping list'}
                testIdPrefix="AddShoppingListModal"
                showOftenUsedFilter
                showInventoryFilter
                showUnitTypes
                disableMainActionButton={isSyncInProgress}
            />
        ),
        [close, handleSubmitClick, isSyncInProgress],
    );

    return (
        <Modal
            disableRestoreFocus
            styles="py-0 max-w-5xl"
            isOpen
            testID="AddShoppingListModal"
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    gap: 1,
                    px: 6,
                    py: 5,
                }}
            >
                <Box
                    sx={{
                        flexGrow: 2,
                        minHeight: 78,
                    }}
                >
                    <Typography
                        component="h5"
                        fontSize={20}
                        fontWeight={700}
                        color={COLOR.paleSky}
                    >
                        {t('ShoppingListsPage:new_shopping_list')}
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 3 }}>
                    <TextField
                        data-test-id="AddShoppingList-TitleField"
                        error={!!form.errors.title && !!form.touched.title}
                        fullWidth
                        helperText={form.touched.title ? form.errors.title : ''}
                        id="title"
                        label={t('ShoppingListsPage:fields:title')}
                        name="title"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.title}
                    />
                </Box>
            </Box>
            <AddProductsProvider>{productsSection}</AddProductsProvider>
        </Modal>
    );
}

export default memo(AddShoppingListModal);
