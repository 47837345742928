import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProceduresTableItem } from './components';
import { Props } from './types';

function ProceduresTable({ procedures }: Props) {
    const { t } = useTranslation();
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow sx={{ '> th': { borderBottomColor: 'white' } }}>
                        <TableCell>
                            <Typography>
                                {t('Procedures:table_columns:name')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {t('Procedures:table_columns:description')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {t('Procedures:table_columns:price')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {t('Procedures:table_columns:available')}
                            </Typography>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {procedures.map((procedure) => (
                        <ProceduresTableItem
                            key={procedure.id}
                            procedure={procedure}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default memo(ProceduresTable);
