import React from 'react';
import { useTranslation } from 'react-i18next';

import EntryProductsList from '@/components/EntryProductsList';
import { Props as EntryProductsListProps } from '@/components/EntryProductsList/types';
import TruncatedListSection from '@/components/TruncatedListSection';

import { Props } from './types';

function EntryProductsSection({ testIdPrefix, isLoading, products }: Props) {
    const { t } = useTranslation();

    return (
        <TruncatedListSection<EntryProductsListProps>
            ListComponent={EntryProductsList}
            title={t('Entry:section:products_used')}
            buttonText={t('Entry:button:more_products')}
            emptyMessage={t('Entry:products_blankslate')}
            listComponentProps={{
                items: products,
                testIdPrefix,
                isCompact: true,
            }}
            isLoading={isLoading}
        />
    );
}

export default EntryProductsSection;
