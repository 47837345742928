import { Box, Button, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React from 'react';
import { of } from 'rxjs/internal/observable/of';
import { v4 as uuidv4 } from 'uuid';

import { Props } from './types';

function Options({ options, selectedOptions, selectOption }: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
            }}
        >
            {options.map((option) => (
                <Box key={uuidv4()} sx={{ width: '262px' }}>
                    <Button
                        fullWidth
                        color="primary"
                        variant={
                            selectedOptions.includes(option)
                                ? 'contained'
                                : 'outlined'
                        }
                        onClick={() => selectOption(option)}
                    >
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: 400,
                                textAlign: 'start',
                            }}
                        >
                            {option.content}
                        </Typography>
                    </Button>
                </Box>
            ))}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['question'],
    ({ question }) => {
        return {
            options: question ? question.answers?.observe() : of([]),
        };
    },
);

export default enhance(Options);
