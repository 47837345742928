import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Badge } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function SidebarLinksListItem({ isActive, linkData }: Props) {
    const { t } = useTranslation();

    const displayName = t(linkData.displayNameKey);

    return (
        <Link
            className={`flex items-center h-12 mb-0.5 rounded-lg hover:cursor-pointer ${
                isActive ? 'bg-sidebarHoverGray' : 'hover:bg-sidebarHoverGray'
            }`}
            onClick={linkData.onClick}
            to={linkData.path}
            data-test-id={`SidebarLinksListItem-${displayName}`}
        >
            <Box
                sx={{
                    width: 30,
                    height: 30,
                    ml: 2,
                    background: `linear-gradient(to left top, ${linkData.color}, ${linkData.secondaryColor})`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 0.2,
                    position: 'relative',
                }}
            >
                {linkData.notificationCount ? (
                    <Box
                        sx={{
                            width: 13,
                            height: 13,
                            zIndex: 1,
                            top: -4,
                            right: -4,
                            position: 'absolute',
                        }}
                    >
                        <Badge
                            count={linkData.notificationCount}
                            backgroundColor={COLOR.cranberry}
                            fontSize={9}
                            isCircle
                        />
                    </Box>
                ) : null}
                {<linkData.Icon color={COLOR.white} width={20} height={20} />}
            </Box>
            <Typography
                sx={{ ml: 1.5 }}
                color={isActive ? COLOR.activeBlack : COLOR.paleSky}
                fontWeight={isActive ? 700 : 400}
                fontSize={14}
                variant="body2"
                data-test-id="SidebarLinksListItem-Text"
            >
                {displayName}
            </Typography>
        </Link>
    );
}

export default SidebarLinksListItem;
