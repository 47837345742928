import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_CONTACT_STATUS } from 'shared/types/EventContact';

import {
    RSVPDeclinedInvitationOverlay,
    RSVPInvitationOverlay,
    Spotlight,
    TooltipSyncButton,
} from '@/components';
import { usePageScrollableElementRef } from '@/context/PageScrollableElementRefContext';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const CONTACT_ITEM_HEIGHT = 160;
const NAV_HEIGHT = 64;

function ContactRSVPStatus({
    contact,
    contactsToOverlay,
    status,
    onEditAppointmentPress,
    hideEditButton = false,
}: Props) {
    const { t } = useTranslation();
    const { showRSVPInvitationOverlay, showRSVPDeclinedAppointmentOverlay } =
        useRSVPOverlayContext();

    const declinedRef = useRef<HTMLDivElement>(null);
    const awaitingRef = useRef<HTMLDivElement>(null);

    const { scrollableElementRef } = usePageScrollableElementRef();

    const isAccepted = status === EVENT_CONTACT_STATUS.ACCEPTED;
    const isRejected = status === EVENT_CONTACT_STATUS.REJECTED;

    const showAwaitingOverlay =
        contactsToOverlay &&
        contactsToOverlay[EVENT_CONTACT_STATUS.AWAITING] === contact.id &&
        showRSVPInvitationOverlay;

    const showDeclinedOverlay =
        contactsToOverlay &&
        contactsToOverlay[EVENT_CONTACT_STATUS.REJECTED] === contact.id &&
        !showRSVPInvitationOverlay &&
        showRSVPDeclinedAppointmentOverlay;

    const scrollToInvitationAwaiting = useCallback(() => {
        const desiredElementCoordinates =
            awaitingRef.current?.getBoundingClientRect();

        if (desiredElementCoordinates) {
            const top =
                desiredElementCoordinates.top -
                NAV_HEIGHT -
                CONTACT_ITEM_HEIGHT;
            scrollableElementRef?.scrollTo({
                behavior: 'smooth',
                top,
            });
        }
    }, [scrollableElementRef]);

    const scrollToInvitationDeclined = useCallback(() => {
        const desiredElementCoordinates =
            declinedRef.current?.getBoundingClientRect();

        if (desiredElementCoordinates) {
            const top =
                desiredElementCoordinates.top -
                NAV_HEIGHT -
                CONTACT_ITEM_HEIGHT;
            scrollableElementRef?.scrollTo({
                behavior: 'smooth',
                top,
            });
        }
    }, [scrollableElementRef]);

    const stopPropagationAndCallEditAppointment = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (onEditAppointmentPress) onEditAppointmentPress();
    };

    const declinedStatus = (
        <div
            ref={declinedRef}
            className={clsx('bg-tutu p-2 rounded relative', {
                'w-fit ml-[90px] mb-[5px] ': hideEditButton,
            })}
        >
            <div
                className={clsx('flex flex-row mb-[10px] items-center', {
                    'mb-0': hideEditButton,
                })}
            >
                <EventBusyIcon
                    width={20}
                    height={20}
                    htmlColor={COLOR.persianRed}
                    className="mr-[3px]"
                />
                <Typography className="text-persianRed text-xs">
                    {t('Event:rsvp:status:decline:title')}
                </Typography>
            </div>
            {!hideEditButton ? (
                <>
                    <Typography className="text-accountSetupBlack mb-[10px] text-xs">
                        {t('Event:rsvp:status:decline:description')}
                    </Typography>
                    <div className="flex flex-row gap-2 flex-wrap">
                        <TooltipSyncButton
                            showTooltip={false}
                            onClick={stopPropagationAndCallEditAppointment}
                            size="small"
                            variant="outlined"
                            color="error"
                            startIcon={
                                <EditIcon
                                    width={20}
                                    height={20}
                                    htmlColor={COLOR.black}
                                />
                            }
                            sx={{
                                color: COLOR.black,
                                flex: 1,
                                flexBasis: 212,
                            }}
                            tooltip=""
                        >
                            {t('Event:rsvp:status:decline:edit')}
                        </TooltipSyncButton>
                        {showDeclinedOverlay ? (
                            <Spotlight
                                width={'80%'}
                                height={'100%'}
                                sx={{
                                    px: 6,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 50,
                                }}
                            />
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    );

    const confirmedOrAwaitingStatus = (
        <div
            ref={awaitingRef}
            className={clsx(
                'flex flex-row items-center rounded w-fit ml-[90px] mb-[5px] relative',
                {
                    'p-2 bg-azure': isAccepted,
                    'pl-[6px]': !isAccepted,
                },
            )}
        >
            {isAccepted ? (
                <CheckCircleIcon
                    width={20}
                    height={20}
                    htmlColor={COLOR.persianGreen}
                    className="mr-[3px]"
                />
            ) : (
                <HelpIcon
                    className="mr-[3px]"
                    width={20}
                    height={20}
                    htmlColor={COLOR.suvaGrey}
                />
            )}
            <Typography
                className={clsx('text-xs', {
                    'font-bold': isAccepted,
                })}
            >
                {t(
                    isAccepted
                        ? 'Event:rsvp:status:confirmed'
                        : 'Event:rsvp:status:awaiting',
                )}
            </Typography>
            {showAwaitingOverlay ? (
                <Spotlight
                    width={'100%'}
                    height={'100%'}
                    sx={{
                        px: 6,
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 50,
                    }}
                />
            ) : null}
        </div>
    );

    return (
        <>
            {isRejected ? declinedStatus : confirmedOrAwaitingStatus}

            {showDeclinedOverlay && !showAwaitingOverlay ? (
                <RSVPDeclinedInvitationOverlay
                    afterShow={scrollToInvitationDeclined}
                />
            ) : null}
            {showAwaitingOverlay ? (
                <RSVPInvitationOverlay afterShow={scrollToInvitationAwaiting} />
            ) : null}
        </>
    );
}

ContactRSVPStatus.displayName = 'ContactRSVPStatus';

export default ContactRSVPStatus;
