import { CheckCircle, CloseRounded } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { InputMask, InputMaskProps, unformat } from '@react-input/mask';
import { useFormik } from 'formik';
import React, { FC, useMemo, useState, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AccessCodeAPI } from '@/services/networking/accessCode';
import { COLOR } from '@/theme/colors';

import { EarlyAccessFormValues, Props } from './types';

const TEXT_FIELD_HEIGHT = 48;
const US_SUPPORT_EMAIL = 'usa.equinetsupport@mustad.com';
const ACCESS_CODE_LENGTH = 8;

const accessCodeMaskConfig = {
    mask: '____-____',
    replacement: { _: /\d/ },
};

type ForwardedInputMaskProps = InputMaskProps &
    React.InputHTMLAttributes<HTMLInputElement>;

const ForwardedInputMask = forwardRef<
    HTMLInputElement,
    ForwardedInputMaskProps
>((props, forwardedRef) => {
    return (
        <InputMask ref={forwardedRef} {...accessCodeMaskConfig} {...props} />
    );
});

ForwardedInputMask.displayName = 'ForwardedInputMask';

const EarlyAccessForm: FC<Props> = ({ onContinue, onSkip }) => {
    const { t } = useTranslation();
    const [invalidAccessCodeMessage, setInvalidAccessCodeMessage] =
        useState('');
    const [codeValidationStatus, setCodeValidationStatus] = useState<
        'idle' | 'loading' | 'error' | 'success'
    >('idle');

    const isVerifyingAccessCode = useMemo(() => {
        return codeValidationStatus === 'loading';
    }, [codeValidationStatus]);

    const handleOnSubmit = ({ accessCode }: EarlyAccessFormValues) => {
        setInvalidAccessCodeMessage('');
        setCodeValidationStatus('idle');

        const unformattedInput = unformat(accessCode, accessCodeMaskConfig);

        onContinue(unformattedInput);
    };

    const validateAccessCode = async (accessCode: string) => {
        setInvalidAccessCodeMessage('');
        setCodeValidationStatus('loading');

        try {
            const { valid } = await AccessCodeAPI.verifyAccessCode({
                access_code: accessCode,
            });

            if (!valid) {
                setCodeValidationStatus('error');
                return;
            }

            setCodeValidationStatus('success');
        } catch (error) {
            setCodeValidationStatus('error');
        }
    };

    const form = useFormik<EarlyAccessFormValues>({
        initialValues: {
            accessCode: '',
        },
        onSubmit: handleOnSubmit,
        validationSchema: yup.object().shape({
            accessCode: yup
                .string()
                .required(t('EarlyAccessForm:validation:required'))
                .transform((value) => unformat(value, accessCodeMaskConfig))
                .length(
                    ACCESS_CODE_LENGTH,
                    t('EarlyAccessForm:validation:length', {
                        length: ACCESS_CODE_LENGTH,
                    }),
                ),
        }),
    });

    const accessCodeValue = form.values.accessCode;

    const handleContinue = () => {
        if (codeValidationStatus !== 'success') {
            setInvalidAccessCodeMessage(
                t('EarlyAccessForm:invalid_code:long_text'),
            );
            return;
        }
        setInvalidAccessCodeMessage('');
        setCodeValidationStatus('idle');
        form.submitForm();
    };

    const handleResetForm = useCallback(() => {
        setInvalidAccessCodeMessage('');
        setCodeValidationStatus('idle');
        form.resetForm();
    }, [form]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInvalidAccessCodeMessage('');

        setCodeValidationStatus('idle');

        const input = e.target.value;

        const unformattedInput = unformat(input, accessCodeMaskConfig);

        form.setFieldValue('accessCode', input);

        if (unformattedInput.length === ACCESS_CODE_LENGTH) {
            validateAccessCode(unformattedInput);
        }
    };

    const accessCodeFieldTrailingContent = useMemo(
        () => (
            <InputAdornment position="end">
                {accessCodeValue.length === ACCESS_CODE_LENGTH ? (
                    <IconButton sx={{ p: 0 }} onClick={handleResetForm}>
                        <CloseRounded sx={{ color: COLOR.codGray }} />
                    </IconButton>
                ) : null}
                {codeValidationStatus === 'error' ? (
                    <WarningIcon sx={{ color: COLOR.errorRed }} width={20} />
                ) : null}
                {codeValidationStatus === 'success' ? (
                    <CheckCircle
                        sx={{
                            color: COLOR.deepCerulean,
                        }}
                        width={20}
                    />
                ) : null}
                {isVerifyingAccessCode ? <CircularProgress size={20} /> : null}
            </InputAdornment>
        ),
        [
            accessCodeValue.length,
            handleResetForm,
            codeValidationStatus,
            isVerifyingAccessCode,
        ],
    );

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    mb: 14,
                    maxWidth: 520,
                    ml: { xs: 4, md: 10 },
                    mr: { xs: 4, md: 0 },
                }}
            >
                <Divider sx={{ mt: '10px', mb: '21px' }} />

                <Box
                    sx={{
                        mb: 1,
                    }}
                >
                    <Typography
                        component="h2"
                        variant="subtitle1"
                        fontWeight="bold"
                    >
                        {t('EarlyAccessForm:header:app_availability_text')}
                    </Typography>
                </Box>
                <Typography component="p" variant="body2">
                    {t('EarlyAccessForm:header:access_code_requirement_text')}
                </Typography>
                <Box sx={{ mt: '4px' }}>
                    <Typography component="p" variant="body2">
                        {t('EarlyAccessForm:header:enter_code_text')}
                    </Typography>

                    <TextField
                        error={
                            (!!form.errors.accessCode &&
                                !!form.touched.accessCode) ||
                            !!invalidAccessCodeMessage
                        }
                        fullWidth
                        helperText={
                            form.touched.accessCode
                                ? form.errors.accessCode
                                : ''
                        }
                        id="accessCode"
                        sx={{
                            mt: '22px',
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={
                            form.values.accessCode
                                ? t('EarlyAccessForm:label:access_code')
                                : ''
                        }
                        placeholder={t('EarlyAccessForm:label:access_code')}
                        name="accessCode"
                        onBlur={form.handleBlur}
                        onChange={handleChange}
                        value={form.values.accessCode}
                        InputProps={{
                            endAdornment: accessCodeFieldTrailingContent,
                            inputComponent: ForwardedInputMask,
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                        }}
                    />

                    {invalidAccessCodeMessage ? (
                        <Typography
                            component="p"
                            variant="body2"
                            color={COLOR.formError}
                            sx={{
                                fontSize: '10px',
                                mt: '12px',
                                pr: '16px',
                            }}
                        >
                            {invalidAccessCodeMessage}
                        </Typography>
                    ) : null}
                </Box>
                <Box
                    bgcolor={COLOR.settingsGray}
                    sx={{ py: '10px', px: '17px', mt: '21px' }}
                    borderRadius="8px"
                >
                    <Typography
                        component="p"
                        variant="body2"
                        fontSize={11}
                        fontWeight="bold"
                    >
                        {t('EarlyAccessForm:help:no_code_text')}
                    </Typography>
                    <Button
                        size="small"
                        sx={{ color: COLOR.avatarGrey }}
                        href={`mailto:${US_SUPPORT_EMAIL}`}
                        className="p-0 underline hover:text-black60 hover:bg-transparent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography fontSize={11} color={COLOR.nevada}>
                            {t('EarlyAccessForm:help:send_email')}
                        </Typography>
                    </Button>
                </Box>
                <Stack
                    sx={{
                        mt: {
                            xs: '20px',
                            md: '50px',
                        },
                    }}
                    direction={['column', 'row']}
                    justifyContent="space-between"
                >
                    <Button
                        size="small"
                        onClick={onSkip}
                        sx={{
                            mb: ['4px', 0],
                        }}
                    >
                        {t('EarlyAccessForm:footer:no_us_region')}
                    </Button>
                    <Button
                        onClick={handleContinue}
                        disabled={
                            isVerifyingAccessCode ||
                            !form.isValid ||
                            !form.dirty
                        }
                        variant="contained"
                        sx={{
                            minWidth: '120px',
                            ...(codeValidationStatus !== 'success' && {
                                background: COLOR.deepCerulean,
                                boxShadow: '0px 3px 6px #00000029',
                                color: COLOR.white,
                                opacity: '0.3',
                            }),
                            '&.Mui-disabled': {
                                background: COLOR.deepCerulean,
                                boxShadow: '0px 3px 6px #00000029',
                                color: COLOR.white,
                                opacity: '0.3',
                            },
                        }}
                    >
                        {t('EarlyAccessForm:footer:continue')}
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default EarlyAccessForm;
