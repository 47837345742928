import { t } from 'i18next';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';

export const getTitle = (key: string) => {
    try {
        if (!key) return '';

        const firstLetter = key[0];

        if (firstLetter === firstLetter.toUpperCase()) {
            return key;
        }

        return t(ENTRIES_TITLES_DICTIONARY[key.toLowerCase()]);
    } catch {
        return key;
    }
};
