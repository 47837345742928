import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton, Typography } from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import { capitalizeFirstLetter } from 'shared/utils/string';

import { TOAST_NOTIFICATION_TITLE } from '@/constants/toastNotifications';

import { Props, ToastNotificationProps } from './types';

const ToastNotification: React.FC<Props> = forwardRef<HTMLDivElement>(
    (
        {
            close,
            customContent,
            id,
            message,
            showCloseButton,
            variant = 'success',
            ...props
        }: Props,
        ref,
    ) => {
        const title = useMemo(() => {
            return capitalizeFirstLetter(TOAST_NOTIFICATION_TITLE[variant]);
        }, [variant]);

        return (
            <Alert
                action={
                    showCloseButton ? (
                        <IconButton
                            color="inherit"
                            data-test-id="ToastNotification-CloseButton"
                            size="small"
                            onClick={close}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    ) : null
                }
                className="shadow-lg"
                data-test-id="ToastNotification"
                id={id}
                ref={ref}
                severity={variant}
                sx={{ maxWidth: '880px', width: '80vw' }}
                {...props}
            >
                <AlertTitle data-test-id="ToastNotification-Title">
                    {title}
                </AlertTitle>
                {customContent || (
                    <Typography
                        data-test-id="ToastNotification-Message"
                        variant="body2"
                    >
                        {message}
                    </Typography>
                )}
            </Alert>
        );
    },
);

ToastNotification.displayName = 'ToastNotification';

function renderToastNotification(params: ToastNotificationProps) {
    return <ToastNotification {...params} />;
}

export default ToastNotification;
export { renderToastNotification };
