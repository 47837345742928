import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function AlertDialog({
    close,
    title,
    message,
    onConfirmButtonClick,
    showCancelButton = true,
    testID,
    fullWidth = false,
    confirmText,
}: Props) {
    const { t } = useTranslation();

    const handleConfirmButtonClick = useCallback(() => {
        close();

        if (onConfirmButtonClick) {
            onConfirmButtonClick();
        }
    }, [close, onConfirmButtonClick]);

    return (
        <Dialog
            open
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-test-id={testID}
            fullWidth={fullWidth}
        >
            <DialogTitle data-test-id={`${testID}-Title`}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{ whiteSpace: 'pre-wrap' }}
                    data-test-id={`${testID}-Message`}
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {showCancelButton ? (
                    <Button
                        onClick={close}
                        data-test-id={`${testID}-CancelButton`}
                    >
                        {t('Actions:cancel')}
                    </Button>
                ) : null}
                <Button
                    onClick={handleConfirmButtonClick}
                    data-test-id={`${testID}-OkButton`}
                >
                    {confirmText ? confirmText : t('Actions:ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;
