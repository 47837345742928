import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import ProductSalesUnit from 'shared/db/services/ProductSalesUnit';
import { AddProductParams, ProductQuantities } from 'shared/types/Products';
import { BoxUnitSizesType } from 'shared/types/ShoppingList';

import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';
import { Context } from '@/types/context';

import { useImagesContext } from './ImagesContext';
import { useUserContext } from './UserContext';

const AddProductsContext = createContext<Context.AddProducts.Value>({
    productQuantities: {},
    handleQuantityChange: () => null,
    incrementQuantity: () => null,
    decrementQuantity: () => null,
    boxUnitSizes: {},
    handleUnitTypeChange: () => null,
    initProductQuantities: () => null,
});

const AddProductsContextRef: React.MutableRefObject<Context.AddProducts.Value | null> =
    createRef();

const AddProductsProvider = ({ children }: Context.AddProducts.Props) => {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const [productQuantities, setProductQuantities] =
        useState<ProductQuantities>({});

    const [boxUnitSizes, setBoxUnitSizes] = useState<
        Record<string, BoxUnitSizesType>
    >({});

    const productSalesUnitRegionService = useMemo(
        () =>
            new ProductSalesUnit({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const getAllBoxUnitSizes = useCallback(async () => {
        const result =
            await productSalesUnitRegionService.getBoxUnitSizesByUserRegion(
                userProfileData?.id || '',
            );

        setBoxUnitSizes(result);
    }, [productSalesUnitRegionService, userProfileData?.id]);

    useEffect(() => {
        getAllBoxUnitSizes();
    }, [getAllBoxUnitSizes]);

    const handleQuantityChange = useCallback((id: string, quantity: string) => {
        setProductQuantities((prev) => {
            return {
                ...prev,
                [id]: {
                    ...prev[id],
                    quantity,
                },
            };
        });
    }, []);

    const incrementQuantity = useCallback((id: string) => {
        setProductQuantities((prev) => {
            return {
                ...prev,
                [id]: {
                    ...prev[id],
                    quantity: prev[id]?.quantity
                        ? `${parseInt(prev[id].quantity, 10) + 1}`
                        : '1',
                },
            };
        });
    }, []);

    const decrementQuantity = useCallback((id: string) => {
        setProductQuantities((prev) => {
            return {
                ...prev,
                [id]: {
                    ...prev[id],
                    quantity: prev[id]?.quantity
                        ? `${parseInt(prev[id].quantity, 10) - 1}`
                        : '0',
                },
            };
        });
    }, []);

    const handleUnitTypeChange = useCallback((id: string, unitType: string) => {
        setProductQuantities((prev) => {
            return {
                ...prev,
                [id]: {
                    ...prev[id],
                    unitType,
                },
            };
        });
    }, []);

    const initProductQuantities = useCallback(
        (products: AddProductParams[]) => {
            const quantities = products.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.id]: {
                        quantity: curr.quantity,
                        unitType: curr.unitType,
                    },
                };
            }, {} as ProductQuantities);
            setProductQuantities(quantities);
        },
        [],
    );

    const contextValue: Context.AddProducts.Value = {
        productQuantities,
        handleQuantityChange,
        incrementQuantity,
        decrementQuantity,
        boxUnitSizes,
        handleUnitTypeChange,
        initProductQuantities,
    };

    AddProductsContextRef.current = contextValue;

    return (
        <AddProductsContext.Provider value={contextValue}>
            {children}
        </AddProductsContext.Provider>
    );
};

function useAddProductsContext() {
    const addProductsContext = useContext(AddProductsContext);

    return addProductsContext;
}

export { AddProductsContext, AddProductsProvider, useAddProductsContext };
