import { Collection, Q } from '@nozbe/watermelondb';
import { DBServiceOptions } from '../../types/dbService';
import { InvoiceInvoicingProductModel } from '../../types/InvoiceInvoicingProduct';

class InvoiceInvoicingProduct {
    private collection: Collection<InvoiceInvoicingProductModel>;
    private table = 'invoice_invoicing_products';

    constructor(options: DBServiceOptions) {
        this.collection = options.database.collections.get(this.table);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }
}

export default InvoiceInvoicingProduct;
