import { Box } from '@mui/material';
import React from 'react';

import { AccountSetupItem } from './components';
import { Props } from './types';

function AccountSetupList({
    items,
    onItemClick,
    itemWrapperStyle,
    onDeleteItemClick,
}: Props) {
    return (
        <Box className="gap-3">
            {items.map((item) => {
                return (
                    <AccountSetupItem
                        key={item.type}
                        item={item}
                        onClick={onItemClick}
                        wrapperStyle={itemWrapperStyle || ''}
                        onDeleteItemClick={onDeleteItemClick}
                    />
                );
            })}
        </Box>
    );
}

export default AccountSetupList;
