import { LOCALE } from 'shared/constants/localization/types';

import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import i18next from '@/services/translations/config';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';

export function getLocaleFromLocalStorage(): LOCALE | null {
    return getItemFromLocalStorage<LOCALE>(WEB_STORAGE_KEYS.locale);
}

export function updateLocale(locale: LOCALE): void {
    storeItemInLocalStorage(WEB_STORAGE_KEYS.locale, locale);
    i18next.changeLanguage(locale);
}
