import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function PersonIcon({
    color = COLOR.iconGrey,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg viewBox="0 0 42.5 42.5" width={width} height={height} fill={color}>
            <path d="M21.3 20.2c5.2 0 9.4-4.2 9.4-9.4s-4.2-9.4-9.4-9.4-9.4 4.2-9.4 9.4 4.1 9.4 9.4 9.4zM21.3 22.8c-4.9 0-14.6 4.2-14.6 12.4v6.2h29.1v-6.2c0-8.2-9.8-12.4-14.5-12.4z" />
        </svg>
    );
}

export default PersonIcon;
