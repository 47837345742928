import { Database } from '@nozbe/watermelondb';
import { synchronize } from '@nozbe/watermelondb/sync';
import { AUTH_ERROR_CODES } from 'shared/constants/authErrors';
import { SYNC_ERROR } from 'shared/constants/synchronisation';

import { getUserDefaultOrganisation } from '@/helpers/organisations';
import { DBSyncAPI } from '@/services/networking/dbSync';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { SynchronizeDBOptions } from '@/types/dbSync';

import Logger from '../logger';
import { setLastPulledAt } from '../webStorage/localStorage/sync';

// TODO:
// * Add logging functions like for mobile
// * Some stuff has been removed just to deliver it quicker. Compare
//   it with mobile version and add what's necessary.

const synchronizeDB = async (
    database: Database,
    options: SynchronizeDBOptions,
): Promise<void | string> => {
    try {
        if (options?.setIsInProgress) {
            options.setIsInProgress(true);
        }

        await synchronize({
            database,
            pullChanges: async ({ lastPulledAt }) => {
                const data = await DBSyncAPI.pullChanges({
                    lastPulledAt: lastPulledAt ?? null,
                });

                return {
                    changes: data.payload.changes,
                    timestamp: data.payload.timestamp,
                };
            },

            pushChanges: async ({ changes, lastPulledAt }) => {
                await DBSyncAPI.pushChanges({
                    changes,
                    last_synced_at: lastPulledAt,
                });
            },
        });

        // 2nd sync needed to update timestamp properly - https://github.com/Nozbe/WatermelonDB/issues/649
        await synchronize({
            database,
            sendCreatedAsUpdated: true,

            pullChanges: async ({ lastPulledAt }) => {
                const data = await DBSyncAPI.pullChanges({
                    lastPulledAt: lastPulledAt ?? null,
                });

                const parsedChanges = {};

                if (data.payload.changes) {
                    // Ignore created/deleted changes on second pull, but allow updates -> https://github.com/Nozbe/WatermelonDB/issues/649
                    const changes = data.payload.changes;
                    Object.keys(changes).forEach((key) => {
                        parsedChanges[key] = {
                            updated: [
                                ...changes[key].updated,
                                ...changes[key].created,
                            ],
                            deleted: changes[key].deleted,
                            created: [],
                        };
                    });
                }

                setLastPulledAt(data.payload.timestamp.toString());

                return {
                    changes: parsedChanges,
                    timestamp: data.payload.timestamp,
                };
            },

            pushChanges: async () => {
                // Do nothing
            },
        });

        options?.setIsLastSyncFailed(false);

        if (options?.showSuccessToastNotification) {
            Snackbar.showToastNotification({
                message: t('Offline:notification:data_synced'),
                options: { variant: 'success' },
            });
        }
    } catch (err) {
        if (options?.showPossibleErrorToastNofification) {
            Snackbar.showToastNotification({
                message: t('Offline:notification:data_sync_failed:generic'),
                options: { variant: 'error' },
            });
        }

        if (AUTH_ERROR_CODES.includes(err?.response?.status)) {
            options?.setIsLastSyncFailed(true);
        }

        if (
            err?.response?.data?.errors?.type ===
            SYNC_ERROR.user_removed_from_organisation_type
        ) {
            const defaultOrganisation = await getUserDefaultOrganisation();

            if (!defaultOrganisation) {
                throw new Error('Default organisation not found');
            }

            return defaultOrganisation.id;
        }

        if (
            err?.message?.includes &&
            (err.message.includes(
                'Concurrent synchronization is not allowed',
            ) ||
                err.message.includes('timeout of 60000ms exceeded'))
        ) {
            Logger.logInfo(err);
        } else {
            Logger.logError(err);
        }
    } finally {
        if (options?.setIsInProgress) {
            options.setIsInProgress(false);
        }
    }
};

export { synchronizeDB };
