import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function TeamFilledIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg
            width={width}
            height={height}
            fill={color}
            viewBox="0 0 40.88 25.71"
        >
            <path d="M27.8.38H13.08C6.2.38.61 5.97.61 12.85c0 6.89 5.58 12.47 12.47 12.47H27.8c6.89 0 12.47-5.58 12.47-12.47C40.27 5.96 34.69.38 27.8.38Zm2.39 5.98c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7c-1.49 0-2.69-1.21-2.69-2.7 0-1.49 1.21-2.7 2.7-2.7Zm-9.86-2.04c2.13 0 3.86 1.73 3.86 3.86s-1.73 3.86-3.86 3.86-3.86-1.73-3.86-3.86 1.73-3.86 3.86-3.86ZM7.77 9.06c0-1.49 1.21-2.7 2.7-2.7s2.7 1.21 2.7 2.7-1.21 2.7-2.7 2.7a2.69 2.69 0 0 1-2.69-2.69Zm5 8.72H6.32v-1.76c0-2.34 2.76-3.52 4.14-3.52 1.5.07 2.87.83 3.73 2.06-.81.89-1.31 2.02-1.42 3.22Zm13.49 2.91H14.39v-2.52c0-3.35 3.95-5.04 5.94-5.04s5.94 1.69 5.94 5.04v2.52Zm8.07-2.91h-6.45c-.11-1.2-.61-2.33-1.43-3.22a4.832 4.832 0 0 1 3.73-2.06c1.38 0 4.14 1.18 4.14 3.52v1.76Z" />
        </svg>
    );
}

export default memo(TeamFilledIcon);
