import { useEffect, useRef } from 'react';

export function useUnmount(callbackFn: VoidFunction) {
    const callbackFnRef = useRef(callbackFn);

    callbackFnRef.current = callbackFn;

    useEffect(() => {
        return () => {
            callbackFnRef.current();
        };
    }, []);
}
