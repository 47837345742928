import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SignUpStepsSkeleton = () => {
    return (
        <Box
            sx={{
                mb: 14,
                maxWidth: 520,
                ml: { xs: 4, md: 10 },
                mr: { xs: 4, md: 0 },
            }}
        >
            <Box
                sx={{
                    mb: 6,
                }}
            >
                <Box
                    sx={{
                        mb: 3,
                    }}
                >
                    <Skeleton height={32} width="50%" variant="text" />
                    <Skeleton height={32} width="60%" variant="text" />
                </Box>
                <Skeleton height={28} width="60%" variant="text" />
                <Skeleton height={28} width="60%" variant="text" />
            </Box>
            <Skeleton height={28} width="80%" variant="text" />
        </Box>
    );
};

export default SignUpStepsSkeleton;
