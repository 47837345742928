import { Alert, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppAnalytics } from 'shared/analytics/app';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';
import { LOCALE } from 'shared/constants/localization/types';
import AppService from 'shared/db/services/App';

import { SignInFlowServerErrorAlert, UnveilPasswordButton } from '@/components';
import { CUSTOM_ERROR } from '@/constants/customErrors';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { getLocaleFromLocalStorage } from '@/services/localization';
import Logger from '@/services/logger';
import { ServerAuthAPI } from '@/services/networking/auth';
import {
    getLastUserEmailFromLocalStorage,
    saveLastUserEmailInLocalStorage,
} from '@/services/webStorage/localStorage/user';
import { NewPasswordFormValues } from '@/types/auth';
import { handleSignInFlowError } from '@/utils/auth';
import { AuthValidationSchema } from '@/validations/auth';

import { PasswordFieldsState, Props } from './types';

const initialValues: NewPasswordFormValues = {
    newPassword: '',
    newPasswordConfirmation: '',
};

const TEXT_FIELD_HEIGHT = 48;

function NewPasswordForm({ email, token }: Props) {
    const language = getLocaleFromLocalStorage() || LOCALE.en_AU;

    const { t } = useTranslation();

    const { authenticateUser } = useAuthContext();
    const { setIsInitialSyncInProgress } = useDBSyncContext();
    const { getDatabase } = useDatabase();
    const navigate = useNavigate();

    const [authErrorCode, setAuthErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [isAuthPending, setIsAuthPending] = useState(false);
    const [passwordFieldsState, setPasswordFieldsState] =
        useState<PasswordFieldsState>({
            unveilNewPasswordField: false,
            unveilNewPasswordConfirmationField: false,
        });

    const handleOnSubmit = useCallback(
        async (values: NewPasswordFormValues) => {
            setAuthErrorCode(null);
            setIsAuthPending(true);

            if (!token || !email) {
                throw new Error(
                    CUSTOM_ERROR.no_reset_password_token_or_email_provided,
                );
            }

            try {
                await FirebaseAuthAPI.confirmPasswordReset(
                    token,
                    values.newPassword,
                );

                AppAnalytics.logUserSetNewPassword(FirebaseAnalytics.logEvent);

                const database = getDatabase();
                const appService = new AppService({
                    database,
                    logInfo: Logger.logInfo,
                });

                await FirebaseAuthAPI.signIn({
                    email,
                    password: values.newPassword,
                });
                await ServerAuthAPI.signInWithFirebase({
                    email,
                    language,
                });

                const firebaseToken = await FirebaseAuthAPI.getAuthToken();

                if (!firebaseToken) {
                    throw new Error(CUSTOM_ERROR.no_firebase_token_found);
                }

                const prevUserEmail = getLastUserEmailFromLocalStorage();

                if (prevUserEmail !== email) {
                    await appService.clearDatabase();
                }

                authenticateUser({
                    firebaseToken,
                    rememberUser: true,
                });

                saveLastUserEmailInLocalStorage(email);

                AppAnalytics.logUserLoggedIn(FirebaseAnalytics.logEvent);

                setIsInitialSyncInProgress(true);

                navigate(ROUTE.dashboard);
            } catch (error) {
                handleSignInFlowError({ error, setAuthErrorCode });
            } finally {
                setIsAuthPending(false);
            }
        },
        [
            authenticateUser,
            email,
            getDatabase,
            language,
            setIsInitialSyncInProgress,
            token,
            navigate,
        ],
    );

    const form = useFormik<NewPasswordFormValues>({
        initialValues,
        onSubmit: handleOnSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.newPasswordForm,
    });

    const togglePasswordVisibility = useCallback(
        (field: keyof PasswordFieldsState) => () => {
            setPasswordFieldsState((prev) => ({
                ...prev,
                [field]: !prev[field],
            }));
        },
        [],
    );

    const handleSubmitButtonClick = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    return (
        <>
            <Box
                component="form"
                data-test-id="NewPasswordPage-Form"
                onSubmit={form.handleSubmit}
                sx={{
                    maxWidth: 480,
                    ml: { xs: 4, md: 10 },
                    mr: { xs: 4, md: 0 },
                    mt: 4,
                }}
            >
                {authErrorCode ? (
                    <SignInFlowServerErrorAlert
                        error={{ errorCode: authErrorCode }}
                    />
                ) : null}
                <Alert severity="info" sx={{ mb: 4 }}>
                    {t('SignIn:info:new_password_suggestion')}
                </Alert>
                <TextField
                    error={
                        !!form.errors.newPassword && !!form.touched.newPassword
                    }
                    fullWidth
                    helperText={
                        form.touched.newPassword ? form.errors.newPassword : ''
                    }
                    id="newPassword"
                    inputProps={{
                        'data-test-id': 'NewPasswordPage-NewPasswordInput',
                    }}
                    InputProps={{
                        endAdornment: (
                            <UnveilPasswordButton
                                isPasswordVisible={
                                    passwordFieldsState.unveilNewPasswordField
                                }
                                onClick={togglePasswordVisibility(
                                    'unveilNewPasswordField',
                                )}
                            />
                        ),
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('NewPassword:label:new_password')}
                    name="newPassword"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    sx={{ mb: 3 }}
                    type={
                        passwordFieldsState.unveilNewPasswordField
                            ? 'text'
                            : 'password'
                    }
                    value={form.values.newPassword}
                />
                <TextField
                    error={
                        !!form.errors.newPasswordConfirmation &&
                        !!form.touched.newPasswordConfirmation
                    }
                    fullWidth
                    helperText={
                        form.touched.newPasswordConfirmation
                            ? form.errors.newPasswordConfirmation
                            : ''
                    }
                    id="newPasswordConfirmation"
                    inputProps={{
                        'data-test-id':
                            'NewPasswordPage-NewPasswordConfirmationInput',
                    }}
                    InputProps={{
                        endAdornment: (
                            <UnveilPasswordButton
                                isPasswordVisible={
                                    passwordFieldsState.unveilNewPasswordConfirmationField
                                }
                                onClick={togglePasswordVisibility(
                                    'unveilNewPasswordConfirmationField',
                                )}
                            />
                        ),
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('NewPassword:label:confirm_new_password')}
                    name="newPasswordConfirmation"
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    sx={{ mb: 3 }}
                    type={
                        passwordFieldsState.unveilNewPasswordConfirmationField
                            ? 'text'
                            : 'password'
                    }
                    value={form.values.newPasswordConfirmation}
                />
                <Button
                    data-test-id="NewPasswordPage-SubmitButton"
                    disabled={isAuthPending}
                    fullWidth
                    onClick={handleSubmitButtonClick}
                    size="large"
                    sx={{ fontWeight: 700, height: 48, mb: 3 }}
                    type="submit"
                    variant="contained"
                >
                    {isAuthPending ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        t('NewPassword:button:submit')
                    )}
                </Button>
            </Box>
        </>
    );
}

export default NewPasswordForm;
