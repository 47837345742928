import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InvoiceIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <path
                d="M34 11.7 26.3 4c-.3-.3-.6-.4-1-.4H10.4c-.1 0-.2 0-.3.1-.1 0-.2.1-.3.1 0 .1-.1.2-.1.2s-.2.1-.3.1c-.1 0-.2.1-.2.2s-.2.1-.2.2c-.1.1-.2.1-.2.2-.1.1-.1.2-.2.2 0 .1-.1.2-.1.3-.1.1-.1.2-.2.3 0 .1-.1.2-.1.3 0 .1 0 .2-.1.3V36.6c0 .1 0 .2.1.3 0 .1.1.2.1.3 0 .1.1.2.1.3 0 .1.1.2.2.2 0 .1.1.2.2.2 0-.1.1 0 .2.1l.2.2.2.2c.1 0 .2.1.2.2.1.1.2.1.3.1.1 0 .2.1.3.1.1 0 .2 0 .3.1H32.2c.1 0 .2 0 .3-.1s.2-.1.3-.1c.1 0 .2-.1.3-.1.1 0 .2-.1.2-.2.1 0 .2-.1.2-.2l.2-.2.2-.2c.1-.1.1-.2.2-.2 0-.1.1-.2.2-.2 0-.1.1-.2.1-.3 0-.1.1-.2.1-.3 0-.1 0-.2.1-.3V12.8c-.2-.4-.3-.8-.6-1.1zm-9.5 14.5c-.6.6-1.4 1-2.3 1.1v.7c0 .3-.1.6-.3.8-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3-.2-.2-.3-.5-.3-.8v-.7c-1-.1-2-.4-2.9-1-.2-.1-.3-.2-.4-.4-.1-.2-.1-.4-.1-.6 0-.3.1-.6.3-.8.1-.2.4-.3.6-.3.1 0 .2 0 .4.1s.3.1.5.2c.4.2.8.4 1.2.5.4.1.8.1 1.2.1.4 0 .9-.1 1.2-.3.3-.2.4-.5.4-.8 0-.2-.1-.4-.2-.5-.2-.1-.4-.3-.6-.3-.2-.1-.6-.2-1.1-.3-.6-.1-1.3-.4-1.9-.6-.5-.2-.9-.5-1.3-.9-.4-.5-.6-1.1-.5-1.7 0-.8.3-1.5.9-2.1.6-.6 1.4-1 2.2-1.2v-.8c0-.3.1-.6.3-.8.4-.4 1.1-.4 1.5 0 .2.2.3.5.3.8v.7c.9.1 1.7.5 2.5 1 .2.1.3.2.4.4.1.2.1.4.1.5 0 .3-.1.6-.3.8-.1.2-.4.3-.6.3-.1 0-.2 0-.4-.1s-.3-.1-.5-.2l-.1-.1-.1.2-.1-.1c-.2-.1-.5-.2-.8-.3-.3-.1-.6-.1-.9-.1-.4 0-.8.1-1.1.3-.3.2-.4.5-.4.8s.2.6.5.7c.5.2 1 .4 1.5.5.6.1 1.2.3 1.9.5.5.2.9.5 1.3.9.4.5.6 1.1.5 1.7-.1.9-.4 1.7-.9 2.2zM25.7 14c-.8 0-1.5-.7-1.5-1.5V5l8.9 8.9c0 .1-7.4.1-7.4.1z"
                fill={color}
            />
        </svg>
    );
}

export default memo(InvoiceIcon);
