import {
    Box,
    FormControlLabel,
    InputProps,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { t } from 'i18next';
import React, { memo, useCallback } from 'react';
import { INVOICES_FILTER } from 'shared/constants/invoices/filters';
import { COUNTRY } from 'shared/constants/localization/types';

import { useRegion } from '@/hooks/useRegion';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function DatesFilterSection({ filtersState, onFiltersChange }: Props) {
    const handleDateChange = useCallback(
        (type: 'from' | 'to') => (date: moment.Moment) => {
            if (!date) {
                return;
            }

            const prevFiltersState = filtersState[INVOICES_FILTER.date_range];

            const setDateRangeFilterValue = onFiltersChange(
                INVOICES_FILTER.date_range,
            );

            setDateRangeFilterValue({
                ...prevFiltersState,
                isActive: true,
                [type]:
                    type === 'from' ? date.startOf('day') : date.endOf('day'),
            });
        },
        [filtersState, onFiltersChange],
    );

    const handleIsDateRangeFilterActiveSwitchChange = useCallback(() => {
        const prevFiltersState = filtersState[INVOICES_FILTER.date_range];

        const setDateRangeFilterValue = onFiltersChange(
            INVOICES_FILTER.date_range,
        );

        setDateRangeFilterValue({
            ...prevFiltersState,
            isActive: !prevFiltersState.isActive,
        });
    }, [filtersState, onFiltersChange]);

    const userBrowserRegion = useRegion();

    const dateInputFormat =
        userBrowserRegion?.code === COUNTRY.US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

    return (
        <Box className="border-b mb-6 pb-7">
            <Typography className="mb-3" color={COLOR.paleSky} fontSize={13}>
                {t('InvoicesPage:filters:date_range')}
            </Typography>
            <Box className="flex flex-row">
                <DatePicker
                    InputProps={
                        {
                            'data-test-id':
                                'InvoicesPage-FiltersPanel-DateFrom',
                        } as InputProps
                    }
                    inputFormat={dateInputFormat}
                    maxDate={filtersState.date_range.to}
                    label={t('InvoicesPage:filters:date_from')}
                    value={filtersState.date_range.from}
                    onChange={handleDateChange('from')}
                    renderInput={(params) => (
                        <TextField
                            className="mr-6"
                            size="small"
                            sx={{ width: 200 }}
                            {...params}
                        />
                    )}
                />
                <DatePicker
                    InputProps={
                        {
                            'data-test-id': 'InvoicesPage-FiltersPanel-DateTo',
                        } as InputProps
                    }
                    inputFormat={dateInputFormat}
                    minDate={filtersState.date_range.from}
                    label={t('InvoicesPage:filters:date_to')}
                    value={filtersState.date_range.to}
                    onChange={handleDateChange('to')}
                    renderInput={(params) => (
                        <TextField
                            className="mr-6"
                            size="small"
                            sx={{ width: 200 }}
                            {...params}
                        />
                    )}
                />
                <FormControlLabel
                    checked={filtersState.date_range.isActive}
                    control={
                        <Switch
                            onChange={handleIsDateRangeFilterActiveSwitchChange}
                            value={filtersState.date_range.isActive}
                        />
                    }
                    data-test-id="InvoicesPage-FiltersPanel-ApplySwitch"
                    label={t('InvoicesPage:filters:apply_date')}
                />
            </Box>
        </Box>
    );
}

export default memo(DatesFilterSection);
