import axios, { AxiosHeaders } from 'axios';
import { API_REQUEST_HEADER } from 'shared/constants/api';
import { AUTH_ERROR_CODES } from 'shared/constants/authErrors';

import { APP_CONFIG } from '@/constants/appConfig';
import { setShowSessionExpiredModal } from '@/context/AuthContext';
import { getUserOrganisationId } from '@/context/OrganisationsContext';
import { ROUTE } from '@/router/routes';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { saveAuthToken } from '@/services/webStorage/authTokens';
import { checkAuthTokenInLocalStorage } from '@/services/webStorage/localStorage/authTokens';

import { verifyAccessCodeEndpoint } from './accessCode';

const BASE_URL = APP_CONFIG.apiUrl;

const apiClient = axios.create({
    baseURL: BASE_URL,
});

let isAlreadyFetchingAccessToken = false;
let queue: { (access_token: string): void }[] = [];

const onAccessTokenFetched = (accessToken: string) => {
    queue.forEach((callback: (accessToken: string) => void) =>
        callback(accessToken),
    );
    queue = [];
};

const addToQueue = (callback: (accessToken: string) => void) => {
    queue.push(callback);
};

async function resetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error;

        const retryOriginalRequest = new Promise((resolve) => {
            addToQueue((accessToken: string) => {
                errorResponse.config.headers[
                    API_REQUEST_HEADER.firebase_token
                ] = accessToken;

                resolve(axios(errorResponse.config));
            });
        });

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            const firebaseToken = await FirebaseAuthAPI.getAuthToken(true);

            const isUserRemembered = checkAuthTokenInLocalStorage();

            saveAuthToken({
                firebaseToken: firebaseToken || '',
                rememberUser: isUserRemembered,
            });

            isAlreadyFetchingAccessToken = false;

            if (firebaseToken === undefined) {
                setShowSessionExpiredModal(true);

                return;
            }

            onAccessTokenFetched(firebaseToken);
        }

        return retryOriginalRequest;
    } catch (err) {
        queue = [];
        return Promise.reject(err);
    }
}

apiClient.interceptors.request.use(
    async (config) => {
        const headers = new AxiosHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        });

        const firebaseToken = await FirebaseAuthAPI.getAuthToken();
        const organisationId = getUserOrganisationId();

        if (firebaseToken) {
            headers[API_REQUEST_HEADER.firebase_token] = firebaseToken;
        }

        if (organisationId) {
            headers[API_REQUEST_HEADER.current_organisation_id] =
                organisationId;
        }

        return {
            ...config,
            headers,
        };
    },
    (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error?.response?.data?.errors?.message ===
                'App setting aws_migration is active and this action cannot be performed' &&
            !window.location.href.includes(ROUTE.maintenance)
        ) {
            window.location.href = ROUTE.maintenance;
            return Promise.reject(error);
        }

        const errorResponse = error.response;

        const errorMessage = errorResponse.data?.errors?.message || '';

        if (errorResponse?.config?.url?.includes(verifyAccessCodeEndpoint)) {
            return Promise.reject(error);
        }

        const firebaseToken = await FirebaseAuthAPI.getAuthToken();

        if (
            (!!firebaseToken && errorMessage === 'Invalid credentials') ||
            (!firebaseToken && errorMessage === 'Missing token')
        ) {
            setShowSessionExpiredModal(true);

            return Promise.reject(error);
        }

        if (AUTH_ERROR_CODES.includes(errorResponse && errorResponse.status)) {
            return resetTokenAndReattemptRequest(error);
        }

        return Promise.reject(error);
    },
);

export default apiClient;
