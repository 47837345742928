import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import RSVPBackgroundImage from '@/assets/images/RSVP-intro.png';
import { Button, Modal } from '@/components';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

function RSVPAppointmentOverlay() {
    const { t } = useTranslation();
    const { dismissOverlay } = useRSVPOverlayContext();

    return (
        <Modal isOpen center styles="bg-transparent shadow-none items-center">
            <Box
                sx={{
                    px: 5.375,
                    pt: 5.375,
                    pb: 3.375,
                    mt: 8,
                    background: 'linear-gradient(#E9ECF0, #FFFFFF)',
                    borderRadius: '1rem',
                    boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    maxWidth: '500px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '25px',
                        fontWeight: '500',
                        color: '#121212',
                        lineHeight: '30px',
                        textAlign: 'center',
                    }}
                >
                    {t('Event:rsvp:overlay:appointment:title')}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontWeight: '700',
                        color: ENTITY_COLOR.schedule,
                        lineHeight: '20px',
                        textAlign: 'center',
                        marginTop: 3,
                    }}
                >
                    {t('Event:rsvp:overlay:appointment:subtitle')}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#121212',
                        lineHeight: '20px',
                        textAlign: 'center',
                        marginTop: 1,
                    }}
                >
                    {t('Event:rsvp:overlay:appointment:disclaimer_1')}
                </Typography>
                <Box
                    sx={{
                        width: '60%',
                        height: '400px',
                        margin: '0 auto',
                        marginTop: 4,
                        backgroundImage: `url(${RSVPBackgroundImage})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '16px',
                        position: 'relative',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#121212',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '20px',
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: '100px',
                        }}
                    >
                        {t('Event:rsvp:overlay:appointment:disclaimer_2')}
                    </Typography>
                </Box>
                <Button
                    caption={t('Event:onboarding:continue')}
                    onClick={() => dismissOverlay('appointment')}
                    variant="contained"
                    sx={{
                        mt: 3,
                        bgcolor: ENTITY_COLOR.schedule,
                        color: COLOR.white,
                        width: '100%',
                        '&:hover': {
                            bgcolor: ENTITY_COLOR.schedule,
                            filter: 'brightness(1.1)',
                        },
                    }}
                    endIcon={<ArrowForwardIcon />}
                />
            </Box>
        </Modal>
    );
}

export default memo(RSVPAppointmentOverlay);
