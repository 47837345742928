import { clearAuthContextState } from '@/context/AuthContext';
import { clearContactsFiltersContextState } from '@/context/ContactsFiltersContext';
import { clearEntriesFiltersContextState } from '@/context/EntriesFiltersContext';
import { clearHorsesFiltersContextState } from '@/context/HorsesFiltersContext';
import { clearImagesContextState } from '@/context/ImagesContext';
import { clearInventoryFiltersContextState } from '@/context/InventoryFiltersContext';
import { clearOrganisationIncomingInvitationContextState } from '@/context/OrganisationIncomingInvitationContext';
import { clearOrganisationsContextState } from '@/context/OrganisationsContext';
import { clearPageScrollableElementRef } from '@/context/PageScrollableElementRefContext';
import { clearUserContextState } from '@/context/UserContext';

function clearAppContextsState() {
    clearContactsFiltersContextState();
    clearHorsesFiltersContextState();
    clearEntriesFiltersContextState();
    clearImagesContextState();
    clearOrganisationsContextState();
    clearUserContextState();
    clearInventoryFiltersContextState();
    clearPageScrollableElementRef();
    clearAuthContextState();
    clearOrganisationIncomingInvitationContextState();
}

export { clearAppContextsState };
