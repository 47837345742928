import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Box,
    Button,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useState,
    FocusEvent,
    ChangeEvent,
    memo,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QuantityThresholds } from 'shared/constants/products/quantityThresholds';
import {
    SHOPPING_PRODUCT_UNIT,
    SHOPPING_PRODUCT_UNIT_VALUE,
} from 'shared/constants/shoppingProductUnit';
import InventoryProduct from 'shared/db/services/InventoryProduct';
import { InventoryProductModel } from 'shared/types/InventoryProduct';
import { ProductType, InventoryProductSize } from 'shared/types/Products';
import { ShoppingListProductSize } from 'shared/types/ShoppingList';
import {
    calculateAvailableUnits,
    calculateUnitTypeDisplayName,
    parseAvailableUnits,
} from 'shared/utils/productUnit';

import { Select } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { COLOR } from '@/theme/colors';
import { SelectOption } from '@/types/select';

import { Props } from './types';

function SelectedProductSizesTableItem({
    productSize,
    onQuantityChange,
    onUnitTypeChange,
    type,
    renderCustomColumn,
    testIdPrefix,
    isShoppingListProducts,
    addedToInventory,
    boxUnitSize,
    showInventory,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const [isQuantityInputFocused, setIsQuantityInputFocused] = useState(false);
    const [unit, setUnit] = useState<string | null>(
        (productSize as ShoppingListProductSize).selectedUnit,
    );
    const [quantity, setQuantity] = useState<number | null>(null);
    const [inventoryQuantity, setInventoryQuantity] = useState<number | null>(
        null,
    );
    const [productSalesUnitsIdsMap, setProductSalesUnitsIdsMap] = useState<{
        [key: string]: string;
    }>({});

    const inventoryProductService = useMemo(
        () =>
            new InventoryProduct({
                database,
                imageService: ImagesService,
                logDBAction: () => null,
            }),
        [ImagesService, database],
    );

    useEffect(() => setQuantity(productSize.quantity), [productSize.quantity]);

    useEffect(() => {
        const productSalesUnitIdSizes: { [key: string]: string } = {};
        const size = productSize as ShoppingListProductSize;
        if (isShoppingListProducts && size?.boxUnitSizes) {
            Object.keys(size.boxUnitSizes).forEach(
                (boxUnitSizeId) =>
                    (productSalesUnitIdSizes[
                        `box ${size.boxUnitSizes?.[boxUnitSizeId] ?? ''}`
                    ] = boxUnitSizeId),
            );

            setProductSalesUnitsIdsMap(productSalesUnitIdSizes);
        }
    }, [isShoppingListProducts, productSize]);

    useEffect(() => {
        inventoryProductService
            .getByParam('product_id', productSize.productId)
            .then((inventoryProduct: InventoryProductModel[]) =>
                setInventoryQuantity(
                    inventoryProduct[0] ? inventoryProduct[0].quantity : 0,
                ),
            );
    }, [inventoryProductService, productSize.productId]);

    const shoppingListProductUnitOptions = useMemo(() => {
        if (!isShoppingListProducts) return;
        const availableUnits: string[] = calculateAvailableUnits(
            (productSize as ShoppingListProductSize).boxUnitSizes,
            type,
        );
        const parsedAvailableUnits: SelectOption[] = parseAvailableUnits(
            availableUnits,
            type,
            boxUnitSize,
        );
        return parsedAvailableUnits;
    }, [boxUnitSize, isShoppingListProducts, productSize, type]);

    const shoppingListProductSelectedUnit = useCallback(
        (value: string[]) => {
            const unitType = unit ?? value[0];
            const result = calculateUnitTypeDisplayName(
                unitType,
                type,
                boxUnitSize,
            );
            return result;
        },
        [unit, type, boxUnitSize],
    );

    const numberOfPieces = useMemo(() => {
        if (type === ProductType.custom) {
            return '-';
        }

        if (!quantity || !unit) {
            return 0;
        }

        if (Object.keys(SHOPPING_PRODUCT_UNIT).includes(unit)) {
            if (unit === 'box' && boxUnitSize) {
                return quantity * boxUnitSize;
            }

            return quantity * SHOPPING_PRODUCT_UNIT_VALUE[unit];
        } else {
            return quantity * parseInt(unit.split(' ')[1], 10);
        }
    }, [boxUnitSize, quantity, unit, type]);

    const sizeLabel =
        productSize.size || t('ShoppingList:products:one_size_label');

    const inventoryCell = useMemo(() => {
        if (!showInventory) return undefined;

        return (
            <TableCell width="10%">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        minWidth: 30,
                    }}
                >
                    <Typography sx={{ color: COLOR.errorRed }}>
                        {inventoryQuantity}
                    </Typography>
                </Box>
            </TableCell>
        );
    }, [inventoryQuantity, showInventory]);

    const handleQuantityBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const value = event.target.value;

            if (!value) {
                setQuantity(0);
            }

            const newQuantity = Number(value);

            setQuantity(newQuantity);

            onQuantityChange(newQuantity, productSize);
            setIsQuantityInputFocused(false);
        },
        [onQuantityChange, productSize],
    );

    const handleQuantityFocus = useCallback(() => {
        setIsQuantityInputFocused(true);
    }, []);

    const handleQuantityChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setQuantity(value ? Number(value) : null);
        },
        [],
    );

    const incrementQuantity = useCallback(
        () =>
            setQuantity((value) => {
                const newQuantity = (value ?? 0) + 1;
                onQuantityChange(newQuantity, productSize);
                return newQuantity;
            }),
        [onQuantityChange, productSize],
    );

    const decrementQuantity = useCallback(
        () =>
            setQuantity((value) => {
                const newQuantity = (value ?? 0) - 1;
                onQuantityChange(newQuantity, productSize);
                return newQuantity;
            }),
        [onQuantityChange, productSize],
    );

    const handleUnitTypeChange = useCallback(
        ([selectedUnit]: string[]) => {
            const isDifferent = !unit ? true : selectedUnit !== unit;
            if (onUnitTypeChange && isDifferent) {
                onUnitTypeChange(
                    selectedUnit,
                    productSize as ShoppingListProductSize,
                    productSalesUnitsIdsMap[selectedUnit] ?? '',
                );
                setUnit(selectedUnit);
            }
        },
        [onUnitTypeChange, productSalesUnitsIdsMap, productSize, unit],
    );

    const quantityToDisplay = useMemo(() => {
        if (isQuantityInputFocused && !quantity) {
            return '';
        }

        return quantity || 0;
    }, [isQuantityInputFocused, quantity]);

    const showLowInventoryWarning =
        (productSize as InventoryProductSize).isInInventory &&
        (type === ProductType.shoes || type === ProductType.nails) &&
        productSize.quantity < QuantityThresholds[type];

    return (
        <TableRow
            key={productSize.productId}
            sx={{
                '& > td': {
                    border: 0,
                },
            }}
            data-test-id={`${testIdPrefix}-ProductSizeTableItem-${
                productSize.size || 'One size'
            }`}
        >
            <TableCell width="14%">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        px: 2,
                        py: 1.25,
                        bgcolor: COLOR.vividYellow12,
                        width: 100,
                    }}
                >
                    <Typography
                        data-test-id={`${testIdPrefix}-ProductSizeTableItem-Size`}
                    >
                        {sizeLabel}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Button
                        className="min-w-0 p-0"
                        size="small"
                        data-test-id={`${testIdPrefix}-ProductSizeTableItem-RemoveButton`}
                        disabled={
                            (quantity && quantity <= 0) ||
                            addedToInventory ||
                            isSyncInProgress
                        }
                        sx={{
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                        color="inherit"
                        onClick={decrementQuantity}
                    >
                        <RemoveIcon />
                    </Button>
                    <TextField
                        type="number"
                        disabled={addedToInventory || isSyncInProgress}
                        value={quantityToDisplay}
                        onBlur={handleQuantityBlur}
                        onFocus={handleQuantityFocus}
                        onChange={handleQuantityChange}
                        data-test-id={`${testIdPrefix}-ProductSizeTableItem-QuantityInput`}
                        sx={{
                            maxWidth: 100,
                            /* Chrome, Safari, Edge, Opera */
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            /* Firefox */
                            'input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        }}
                    />
                    <Button
                        className="min-w-0 p-0"
                        size="small"
                        data-test-id={`${testIdPrefix}-ProductSizeTableItem-AddButton`}
                        sx={{ fontWeight: 700, fontSize: 16 }}
                        color="inherit"
                        disabled={addedToInventory || isSyncInProgress}
                        onClick={incrementQuantity}
                    >
                        <AddIcon />
                    </Button>
                    {showLowInventoryWarning ? (
                        <Tooltip title={t('Inventory:low_inventory')}>
                            <ErrorOutlineIcon
                                data-test-id={`${testIdPrefix}-ProductSizeTableItem-LowInventoryWarning`}
                                color="error"
                            />
                        </Tooltip>
                    ) : null}
                    {productSize.notAvailable ? (
                        <Box
                            sx={{
                                bgcolor: COLOR.lightGray,
                                padding: 1,
                                borderRadius: 1.5,
                            }}
                        >
                            <Typography
                                data-test-id={`${testIdPrefix}-ProductSizeTableItem-NotAvailableTag`}
                            >
                                {t('Generic:not_available')}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </TableCell>
            {isShoppingListProducts ? (
                <>
                    <TableCell
                        width={showInventory ? '15%' : '18%'}
                        align="center"
                    >
                        <Typography
                            sx={{
                                color: COLOR.regentGray,
                                textAlign: 'start',
                            }}
                        >
                            x
                        </Typography>
                    </TableCell>
                    <TableCell width="25%">
                        <Select
                            selectProps={{ disabled: isSyncInProgress }}
                            label={t('ShoppingList:table:unit')}
                            onChange={handleUnitTypeChange}
                            options={shoppingListProductUnitOptions ?? []}
                            selectedValue={[unit ?? '']}
                            renderSelectedValue={
                                shoppingListProductSelectedUnit
                            }
                            testID={`${testIdPrefix}-ProductSizeTableItem-UnitTypeSelect`}
                        />
                    </TableCell>
                    <TableCell width="10%">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                px: 2,
                                py: 1.25,
                                bgcolor: COLOR.lightBlue,
                                width: 70,
                            }}
                            data-test-id={`${testIdPrefix}-ProductSizeTableItem-Pieces`}
                        >
                            <Typography>{numberOfPieces.toString()}</Typography>
                        </Box>
                    </TableCell>
                    {inventoryCell}
                </>
            ) : null}
            {renderCustomColumn ? (
                <TableCell align="right" width={'18%'} sx={{ minWidth: 200 }}>
                    {renderCustomColumn(productSize)}
                </TableCell>
            ) : null}
        </TableRow>
    );
}

export default memo(SelectedProductSizesTableItem);
