import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContactModel } from 'shared/types/Contacts';

import { ContactsList, TruncatedListSection } from '@/components';
import { ROUTE } from '@/router/routes';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function ContactsSection({
    contacts,
    isLoading,
    testIdPrefix,
    showAddButton = false,
    onAddButtonClick,
    renderExtraContentComponent,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToContact = useCallback(
        (contact: ContactModel) => {
            const route = getRoutePath(ROUTE.contact, { id: contact.id });

            navigate(route);
        },
        [navigate],
    );

    return (
        <TruncatedListSection
            ListComponent={ContactsList}
            listComponentProps={{
                clickable: true,
                items: contacts,
                itemWrapperStyle: 'pb-0.5',
                onItemClick: navigateToContact,
                size: 'large',
                testIdPrefix,
                renderExtraContentComponent,
            }}
            title={t('ShowHorse:section:Contacts_with_length', {
                length: contacts.length || 0,
            })}
            emptyMessage={t('ShowHorse:empty:contacts')}
            buttonText={t('ShowEvent:section:more_contacts')}
            isLoading={isLoading}
            showAddButton={showAddButton}
            onAddButtonClick={onAddButtonClick}
            entityName={t('Entities:contact').toLowerCase()}
        />
    );
}

export default ContactsSection;
