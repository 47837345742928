import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import App from 'shared/db/services/App';
import { InvitationData } from 'shared/types/api/user';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { synchronizeDB } from '@/services/database/dbSync';
import Logger from '@/services/logger';
import { OrganisationsAPI } from '@/services/networking/organisations';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import {
    addOrganisationToLocalStorage,
    getOrganisationIdFromLocalStorage,
} from '@/services/webStorage/localStorage/organisation';
import { Context } from '@/types/context';
import {
    Organisation,
    OrganisationInvitation,
    OrganisationMember,
    OrganisationOtherTeamsListItem,
    UserOrganisationsAvatarsUrls,
} from '@/types/organisations';

const OrganisationsContext = createContext<Context.Organisations.Value>({
    clearContextState: () => null,
    setUserOrganisations: () => null,
    userOrganisations: [],
    userOrganisationsAvatarsUrls: null,
    userOrganisation: null,
    setCurrentOrganisation: () => Promise.resolve(),
    isOrganisationLocalStorageEmptyOnInit: false,
    organisationChanged: false,
    setOrganisationChanged: () => null,
    pendingOwnerApprovalInvitations: null,
    members: null,
    fetchMembers: () => Promise.resolve(),
    setUserInvitations: () => null,
    userInvitations: null,
    fetchUserOrganisationsData: () => Promise.resolve(),
    userInvitationsToAccept: [],
    userInvitationsToBeApprovedByOwners: [],
});

const organisationsContextRef: React.MutableRefObject<Context.Organisations.Value | null> =
    createRef();

const OrganisationsContextProvider = ({
    children,
}: Context.Organisations.Props) => {
    const { images, ImagesService } = useImagesContext();
    const { setHasSyncIssue } = useDBSyncContext();

    const { getDatabase } = useDatabase();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const { setIsInitialSyncInProgress, setIsSyncInProgress } =
        useDBSyncContext();

    const [members, setMembers] = useState<OrganisationMember[] | null>(null);
    const [organisationChanged, setOrganisationChanged] = useState(false);
    const [
        pendingOwnerApprovalInvitations,
        setPendingOwnerApprovalInvitations,
    ] = useState<OrganisationInvitation[] | null>(null);
    const [userOrganisations, setUserOrganisations] = useState<Organisation[]>(
        [],
    );
    const [userOrganisationsAvatarsUrls, setUserOrganisationAvatarsUrls] =
        useState<UserOrganisationsAvatarsUrls | null>(null);
    const [userOrganisation, setUserOrganisation] =
        useState<Organisation | null>(null);
    const [userInvitations, setUserInvitations] = useState<
        OrganisationInvitation[] | null
    >(null);

    const isOrganisationLocalStorageEmptyOnInit = useMemo(
        () => !getOrganisationIdFromLocalStorage(),
        [],
    );

    const getOwnerOrganisation = useCallback(
        () => userOrganisations.find((org) => org.owner),
        [userOrganisations],
    );

    const currentUserOrganisation = useMemo(() => {
        let organisation;

        const storageOrgId = getOrganisationIdFromLocalStorage();

        if (!storageOrgId) {
            organisation = getOwnerOrganisation();
        } else {
            organisation = userOrganisations.find(
                (org) => org.id === storageOrgId,
            );

            if (!organisation) {
                organisation = getOwnerOrganisation();
            }
        }

        if (!organisation) {
            return null;
        }

        return organisation;
    }, [getOwnerOrganisation, userOrganisations]);

    useEffect(
        () => setUserOrganisation(currentUserOrganisation),
        [currentUserOrganisation],
    );

    useEffect(() => {
        if (userOrganisation) {
            addOrganisationToLocalStorage(userOrganisation.id);
        }
    }, [userOrganisation]);

    const changeOrganisationOnDB = useCallback(async () => {
        try {
            const AppService = new App({
                database,
                logInfo: Logger.logInfo,
            });

            await AppService.clearDatabase();
            await synchronizeDB(database, {
                showPossibleErrorToastNofification: true,
                setIsLastSyncFailed: setHasSyncIssue,
            });
            setOrganisationChanged(true);
        } catch {
            Snackbar.showToastNotification({
                message: t(
                    'Offline:notification:organisation_data_sync_failed',
                ),
                options: { variant: 'error' },
            });
        } finally {
            setIsSyncInProgress(false);
        }
    }, [database, setHasSyncIssue, setIsSyncInProgress]);

    const setCurrentOrganisation = useCallback(
        async (organisation: Organisation) => {
            setIsSyncInProgress(true);
            setIsInitialSyncInProgress(true);

            setUserOrganisation(organisation);

            /** Without setTimeout database will be cleared before DBSyncContext is updated
             * and subscriptions to database will be active when it is clearing, causing an error */
            setTimeout(changeOrganisationOnDB);
        },
        [
            changeOrganisationOnDB,
            setIsInitialSyncInProgress,
            setIsSyncInProgress,
        ],
    );

    const fetchUserOrganisationsData = useCallback(async () => {
        try {
            const data = await OrganisationsAPI.getOrganisations();

            setUserOrganisations(data.payload.organisations || []);
            setUserInvitations(data.payload.invitations || []);
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, []);

    const getOrganisationsAvatars = useCallback(async () => {
        if (!userOrganisations.length || !images) {
            return;
        }

        const avatarsUrlsArray = userOrganisations.map((userOrganisation) => {
            const imageUrl = ImagesService.getSingleImageByEntityId(
                images,
                userOrganisation.id,
            )?.imageURL;

            return {
                avatarUrl: imageUrl || '',
                organisationId: userOrganisation.id,
            };
        });

        const avatarsUrlsObject = avatarsUrlsArray.reduce(
            (acc, item) => ({
                ...acc,
                [item.organisationId]: item.avatarUrl,
            }),
            {},
        );

        setUserOrganisationAvatarsUrls(avatarsUrlsObject);
    }, [ImagesService, images, userOrganisations]);

    const fetchMembers = useCallback(async () => {
        try {
            if (!userOrganisation?.id) {
                return;
            }

            const res = await OrganisationsAPI.getMembers({
                organisationId: userOrganisation.id,
            });

            const members = res.payload.members;
            const invitations = res.payload.invitations;

            if (members) {
                setMembers(members);
            }

            if (invitations) {
                setPendingOwnerApprovalInvitations(invitations);
            }
        } catch (err) {
            Logger.logError(`Organisations context - fetch members ${err}`);
        }
    }, [userOrganisation?.id]);

    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    useEffect(() => {
        getOrganisationsAvatars();
    }, [getOrganisationsAvatars]);

    const userInvitationsToAccept = useMemo((): InvitationData[] => {
        if (!userInvitations) {
            return [];
        }

        return userInvitations.reduce((acc: InvitationData[], invitation) => {
            if (
                !['accepted', 'approved', 'rejected'].includes(
                    invitation.status,
                )
            ) {
                acc.push({
                    id: invitation.id,
                    created_at: new Date(invitation.created_at),
                    organisation_id: invitation.organisation_id,
                    organisation_name: invitation.organisation_name,
                    owner_name: invitation.owner_name,
                    status: invitation.status as InvitationData['status'],
                    updated_at: new Date(invitation.updated_at),
                    user_id: invitation.user_id,
                    user_name: invitation.user_name,
                });

                return acc;
            } else {
                return acc;
            }
        }, []);
    }, [userInvitations]);

    const userInvitationsToBeApprovedByOwners =
        useMemo((): OrganisationOtherTeamsListItem[] => {
            if (!userInvitations) {
                return [];
            }

            return userInvitations.reduce(
                (acc: OrganisationOtherTeamsListItem[], invitation) => {
                    if (invitation.status === 'accepted') {
                        acc.push({
                            created_at: invitation.created_at,
                            hasPendingApproval: true,
                            id: invitation.organisation_id,
                            name: invitation.organisation_name,
                            owner: false,
                            owner_name: invitation.owner_name,
                            updated_at: invitation.updated_at,
                        });

                        return acc;
                    } else {
                        return acc;
                    }
                },
                [],
            );
        }, [userInvitations]);

    const clearContextState = useCallback(() => {
        setUserOrganisation(null);
        setUserOrganisations([]);
        setUserInvitations(null);
        setUserOrganisationAvatarsUrls(null);
        setMembers(null);
        setPendingOwnerApprovalInvitations(null);
    }, []);

    const contextValue: Context.Organisations.Value = {
        clearContextState,
        fetchUserOrganisationsData,
        setUserOrganisations,
        userOrganisations,
        userOrganisationsAvatarsUrls,
        setCurrentOrganisation,
        userOrganisation,
        isOrganisationLocalStorageEmptyOnInit,
        organisationChanged,
        setOrganisationChanged,
        members,
        pendingOwnerApprovalInvitations,
        fetchMembers,
        userInvitations,
        setUserInvitations,
        userInvitationsToAccept,
        userInvitationsToBeApprovedByOwners,
    };

    organisationsContextRef.current = contextValue;

    return (
        <OrganisationsContext.Provider value={contextValue}>
            {children}
        </OrganisationsContext.Provider>
    );
};

function useOrganisationsContext() {
    const organisationsContext = useContext(OrganisationsContext);

    return organisationsContext;
}

function getUserOrganisationId() {
    return organisationsContextRef.current?.userOrganisation?.id;
}

function clearOrganisationsContextState() {
    return organisationsContextRef.current?.clearContextState();
}

export {
    OrganisationsContext,
    OrganisationsContextProvider,
    organisationsContextRef,
    useOrganisationsContext,
    getUserOrganisationId,
    clearOrganisationsContextState,
};
