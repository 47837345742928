import { Box } from '@mui/material';
import React from 'react';
import { ROLE } from 'shared/types/OrganisationUser';

import { useOrgUserContext } from '@/context/OrgUserContext';

import {
    AccountSection,
    InvoicingAndAdministrationSection,
} from './components';
import SubscriptionsAndNotificationsSection from './components/SubscriptionsAndNotificationsSection';

function SettingsPage() {
    const { orgUser } = useOrgUserContext();

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    return (
        <Box sx={{ minWidth: 650 }}>
            {isOrgUserAdmin ? (
                <Box sx={{ mb: 3 }}>
                    <InvoicingAndAdministrationSection />
                </Box>
            ) : null}
            <Box sx={{ mb: 3 }}>
                <SubscriptionsAndNotificationsSection />
            </Box>
            <Box sx={{ mb: 3 }}>
                <AccountSection />
            </Box>
        </Box>
    );
}

export default SettingsPage;
