import { Database, RawRecord } from '@nozbe/watermelondb';
import { getStorage, ref, uploadBytes, UploadResult } from 'firebase/storage';

export type DumpType = Array<{ [collection: string]: Array<RawRecord> }>;

export type DumpServiceProps = {
    database: Database;
    userID: string;
};

export function convertDatabaseToBlob(data) {
    const filteredData = data.map((table) => {
        const key = Object.keys(table)[0];
        const tableData = table[key];

        return {
            [key]: tableData.map((model) => model._raw),
        };
    });

    const jsonData = JSON.stringify(filteredData, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });

    return blob;
}

export async function createDatabaseDump({
    database,
    userID,
}: DumpServiceProps): Promise<UploadResult> {
    const allTables = Object.keys(database.collections.map);

    const allData = await Promise.all(
        allTables.map(async (t) => {
            const data = await database.collections.get(t).query().fetch();
            return { [t]: data };
        }),
    );

    const blob = convertDatabaseToBlob(allData);

    const fileName = `${userID}_${Date.now()}.json`;
    const dumpRef = ref(getStorage(), `dumps/${userID}/web/${fileName}`);

    return await uploadBytes(dumpRef, blob, {
        customMetadata: {
            userID,
            deviceID: 'web',
        },
    });
}
