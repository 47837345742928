import { ArrowForward } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
    Box,
    Fade,
    LinearProgress,
    Typography,
    linearProgressClasses,
    styled,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { of } from 'rxjs/internal/observable/of';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';
import Database from 'shared/db/services/Database.web';
import QuestionService from 'shared/db/services/Question';

import { Button } from '@/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { APP_CONFIG } from '@/constants/appConfig';
import { withSyncContext } from '@/hoc';
import { COLOR } from '@/theme/colors';

import Options from './components';
import { Props } from './types';
import { useIntroductionQuestions } from './useIntroductionQuestions';

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLOR.deepCerulean,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: COLOR.white,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: COLOR.deepCerulean,
    },
}));

function IntroductionQuestions({ close, isSyncInProgress, questions }: Props) {
    const {
        showQuestions,
        currentQuestion,
        currentQuestionIndex,
        selectedOptions,
        showFinishAnimation,
        addUserAnswer,
        nextQuestion,
        selectOption,
        displayQuestions,
    } = useIntroductionQuestions({
        close,
        questions,
    });

    const handleContinueClick = useCallback(
        () => addUserAnswer(selectedOptions),
        [addUserAnswer, selectedOptions],
    );

    const handleSkipClick = useCallback(() => nextQuestion(), [nextQuestion]);

    const progress = useMemo(() => {
        const length = questions?.length ?? 0;

        return ((currentQuestionIndex + 1) / length) * 100;
    }, [currentQuestionIndex, questions?.length]);

    return (
        <Box
            sx={{
                maxWidth: 400,
                flexGrow: 1,
                gap: showQuestions ? 2 : 3,
                borderRadius: '8px',
                display: 'flex',
                justifyContent:
                    showFinishAnimation || !showQuestions
                        ? 'center'
                        : 'flex-start',
                alignItems: 'stretch',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            {showFinishAnimation ? (
                <Fade
                    in={showFinishAnimation}
                    style={{ transitionDelay: '300ms' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: 400,
                                color: COLOR.accountSetupBlack,
                            }}
                        >
                            {t('Onboarding:thank_you')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                backgroundColor: COLOR.deepCerulean,
                            }}
                        >
                            <CheckIcon
                                sx={{
                                    width: '25px',
                                    height: '25px',
                                    color: COLOR.white,
                                }}
                            />
                        </Box>
                    </Box>
                </Fade>
            ) : showQuestions ? (
                <>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                alignSelf: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                            }}
                        >
                            {t('Onboarding:question_of_questions', {
                                currentQuestion: currentQuestionIndex + 1,
                                totalQuestions: questions?.length ?? 0,
                            })}
                        </Typography>
                        <Box sx={{ px: 3 }}>
                            <BorderLinearProgress
                                variant="determinate"
                                value={progress}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                color: COLOR.accountSetupBlack,
                                textAlign: 'center',
                                px: 5,
                            }}
                        >
                            {currentQuestion?.content || ''}
                        </Typography>
                        {currentQuestion?.description ? (
                            <Typography
                                sx={{
                                    fontSize: '10px',
                                    fontWeight: 400,
                                    color: COLOR.accountSetupBlack,
                                    textAlign: 'center',
                                    px: 5,
                                }}
                            >
                                {currentQuestion?.description || ''}
                            </Typography>
                        ) : null}
                    </Box>
                    <Box
                        sx={{
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            height: '230px',
                        }}
                    >
                        <Options
                            question={currentQuestion}
                            selectedOptions={selectedOptions}
                            selectOption={selectOption}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            gap: 1,
                            mb: 2,
                            mx: 2,
                        }}
                    >
                        {!currentQuestion?.mandatory ? (
                            <Button
                                fullWidth
                                color="primary"
                                variant="outlined"
                                caption={t('Onboarding:skip')}
                                onClick={handleSkipClick}
                            />
                        ) : null}
                        <TooltipSyncButton
                            fullWidth
                            tooltip={t('AddEditModalHeader:button_tooltip')}
                            showTooltip={isSyncInProgress}
                            disabled={
                                !selectedOptions.length || isSyncInProgress
                            }
                            color="primary"
                            variant="contained"
                            sx={{
                                '&.Mui-disabled': {
                                    backgroundColor: COLOR.scrollGray,
                                    color: COLOR.white,
                                },
                            }}
                            endIcon={<ArrowForward />}
                            onClick={handleContinueClick}
                        >
                            {t('Onboarding:continue')}
                        </TooltipSyncButton>
                    </Box>
                </>
            ) : (
                <>
                    <Box
                        alt="Equinet Logo"
                        component="img"
                        src={equinetLogoImage}
                        sx={{ px: '40px' }}
                    />
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            px: '24px',
                        }}
                    >
                        {t('Onboarding:thank_you_for_choosing_equinet')}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 300,
                            textAlign: 'center',
                            px: '24px',
                        }}
                    >
                        {t('Onboarding:questions_description')}
                    </Typography>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            mb: 2,
                            mx: 2,
                        }}
                    >
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            caption={t('Onboarding:continue')}
                            endIcon={<ArrowForward />}
                            onClick={displayQuestions}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        const questionService = new QuestionService(database);

        const allQuestions = questionService
            .getByVersion(APP_CONFIG.onboardingQuestionsVersion || 2)
            .observe();

        return {
            questions: isInitialSyncInProgress ? of() : allQuestions,
        };
    },
);

export default withSyncContext<Props>(enhance(IntroductionQuestions));
