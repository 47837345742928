import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useState } from 'react';
import { of } from 'rxjs';
import { ProceduresAnalytics } from 'shared/analytics/procedures';
import Database from 'shared/db/services/Database.web';
import { ProcedureModel } from 'shared/types/Procedures';

import { Button, AddEditProcedureModal, BackLinkButton } from '@/components';
import { withSyncContext } from '@/hoc';
import withUserProfileData from '@/hoc/withUserProfileData';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { t } from '@/services/translations/config';

import ProceduresGroupedList from '../../components/ProceduresGroupedList';

import { Props } from './types';

function ProceduresPage({ procedures = [] }: Props) {
    const [isAddProcedureModalOpen, setIsAddProcedureModalOpen] =
        useState<boolean>(false);

    const openAddProcedureModal = useCallback(
        () => setIsAddProcedureModalOpen(true),
        [],
    );

    const closeAddProcedureModal = useCallback(() => {
        setIsAddProcedureModalOpen(false);
    }, []);

    const onSaveProcedure = useCallback((procedure: ProcedureModel) => {
        ProceduresAnalytics.logUserCreatedProcedureFromSettings(
            FirebaseAnalytics.logEvent,
            { procedure },
        );
    }, []);

    return (
        <Box sx={{ pb: 4 }}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 4,
                }}
            >
                <BackLinkButton
                    to={ROUTE.settings}
                    text="Settings"
                    testID="ProceduresPage-BackButton"
                />
                <Button
                    caption={t('Procedures:button:add_procedure')}
                    onClick={openAddProcedureModal}
                    startIcon={<Add />}
                    testID="ProceduresPage-AddProcedureButton"
                />
            </Box>
            <ProceduresGroupedList procedures={procedures} />
            {isAddProcedureModalOpen ? (
                <AddEditProcedureModal
                    close={closeAddProcedureModal}
                    onSave={onSaveProcedure}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, any>(
    ['isInitialSyncInProgress', 'isSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        return {
            procedures: isInitialSyncInProgress
                ? of([])
                : database.collections
                      .get<ProcedureModel>('procedures')
                      .query(),
        };
    },
);

export default withUserProfileData(
    withSyncContext<Props>(enhance(ProceduresPage)),
);
