import { Box, Typography } from '@mui/material';
import React, { forwardRef } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

const HorsesCount = forwardRef(({ horsesCount }: Props, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                width: 13,
                height: 13,
                minWidth: 13,
                minHeight: 13,
                bgcolor: COLOR.white,
                borderRadius: 9999,
                alignItems: 'center',
                justifyItems: 'center',
                display: 'flex',
            }}
        >
            <Typography
                fontSize={8}
                sx={{
                    color: COLOR.black,
                    flex: 1,
                    textAlign: 'center',
                }}
            >
                {horsesCount}
            </Typography>
        </Box>
    );
});

HorsesCount.displayName = 'HorsesCount';

export default HorsesCount;
