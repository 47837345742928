import { Collection, Database, Q } from '@nozbe/watermelondb';
import { map } from 'rxjs/operators';

import { DBServiceOptionsWithImages } from '../../types/dbService';
import { EventUserModel } from '../../types/EventUser';

import Organisation from './Organisation';

class EventUser {
    private database: Database;
    private collection: Collection<EventUserModel>;
    private table = 'event_users';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getEventUsersIdsById(eventID: string) {
        return this.collection
            .query(Q.where('event_id', eventID))
            .observe()
            .pipe(
                map((eventUsers: EventUserModel[]) =>
                    eventUsers.map((eventUser) => eventUser.userId),
                ),
            );
    }

    getEventUsersByEventId(eventID: string) {
        return this.collection.query(Q.where('event_id', eventID)).fetch();
    }

    getActiveEventUsersIdsById(eventId: string) {
        return this.collection
            .query(
                Q.experimentalNestedJoin('event_users', 'users'),
                Q.experimentalNestedJoin('users', 'organisation_users'),
                Q.and(
                    Q.where('event_id', eventId),
                    Q.on(
                        'users',
                        Q.on('organisation_users', Q.where('active', true)),
                    ),
                ),
            )
            .observe()
            .pipe(
                map((eventUsers) =>
                    eventUsers.map((eventUser) => eventUser.userId),
                ),
            );
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async prepareAdd(membersIds: string[], eventId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();

        const { id: organisationID } = organisation[0];

        return membersIds.map((userId) =>
            this.collection.prepareCreate((eventUser) => {
                eventUser.eventId = eventId;
                eventUser.userId = userId;
                eventUser.organisationId = organisationID;
            }),
        );
    }

    async addBatch(membersIds: string[], eventId: string) {
        return this.database.write(async () => {
            const eventUsersToCreate = await this.prepareAdd(
                membersIds,
                eventId,
            );
            await this.database.batch(...eventUsersToCreate);

            eventUsersToCreate.forEach((user) =>
                this.options.logDBAction({
                    message: 'Create event user',
                    modelName: this.table,
                    payload: user,
                }),
            );

            return eventUsersToCreate;
        });
    }

    async deleteBatch(eventUsers: EventUserModel[]) {
        await this.database.write(async () => {
            const eventUsersToDelete = eventUsers.map((user) =>
                user.prepareMarkAsDeleted(),
            );

            await this.database.batch(...eventUsersToDelete);

            eventUsersToDelete.forEach((user) =>
                this.options.logDBAction({
                    message: 'Delete event user',
                    modelName: this.table,
                    payload: user,
                }),
            );
        });
    }

    async prepareUpdateBatch(membersIds: string[], eventId: string) {
        const currentEventUsers = await this.getByParam('event_id', eventId);

        const usersToDelete = currentEventUsers
            .filter((currentEventUser) =>
                membersIds.every(
                    (memberId) => memberId !== currentEventUser.userId,
                ),
            )
            .map((user) => user.prepareMarkAsDeleted());

        const usersIdsToAdd = membersIds.filter((memberId) =>
            currentEventUsers.every(
                (currentEventUser) => currentEventUser.userId !== memberId,
            ),
        );

        const usersToAdd = await this.prepareAdd(usersIdsToAdd, eventId);

        return { usersToAdd, usersToDelete };
    }

    async updateEventUsers(membersIds: string[], eventId: string) {
        const currentEventUsers = await this.getByParam('event_id', eventId);

        const usersToDelete = currentEventUsers.filter((currentEventUser) =>
            membersIds.every(
                (memberId) => memberId !== currentEventUser.userId,
            ),
        );
        await this.deleteBatch(usersToDelete);

        const membersToAdd = membersIds.filter((memberId) =>
            currentEventUsers.every(
                (currentEventUser) => currentEventUser.userId !== memberId,
            ),
        );
        await this.addBatch(membersToAdd, eventId);
    }

    async deleteAllUsersByEventId(eventID: string) {
        const eventUsers = await this.collection
            .query(Q.where('event_id', eventID))
            .fetch();

        return this.deleteBatch(eventUsers);
    }
}

export default EventUser;
