import { useEffect } from 'react';

export function useBlockCookiesEffect(cookies: string[]) {
    useEffect(() => {
        const cookieDesc = Object.getOwnPropertyDescriptor(
            Document.prototype,
            'cookie',
        );

        if (cookieDesc) {
            Object.defineProperty(document, 'cookie', {
                get() {
                    return cookieDesc.get?.call(document);
                },
                set(cookieToSet) {
                    const [cookieToSetName] = cookieToSet.split('=');
                    if (cookies.every((cookie) => cookie !== cookieToSetName)) {
                        cookieDesc.set?.call(document, cookieToSet);
                    }
                },
            });
        }
    }, [cookies]);
}
