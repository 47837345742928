import { Add } from '@mui/icons-material';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { VariableSizeList as List } from 'react-window';
import { PRODUCTS_FILTER } from 'shared/constants/products/filters';
import EntryProduct from 'shared/db/services/EntryProduct';
import ProductService from 'shared/db/services/Product';
import { FILTER_TYPE } from 'shared/types/filter';
import { ProductFiltersObject } from 'shared/types/productFilters';
import { ProductModel, ProductType } from 'shared/types/Products';
import { getGroupedProducts } from 'shared/utils/product';

import {
    AlertDialog,
    Button,
    ErrorState,
    SearchInput,
    TableSkeleton,
    EmptyState,
} from '@/components';
import { useAddProductsContext } from '@/context/AddProductsContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import {
    useCancelEntityFormAlert,
    useDatabase,
    useDebouncedValue,
    useEscapeButton,
} from '@/hooks';
import Logger from '@/services/logger';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import TooltipSyncButton from '../TooltipButton';

import { FilterMenu, ProductsList } from './components';
import { Props } from './types';

type ProductGroupWithFrequency = {
    frequency: number;
    group: ProductModel[];
};

const INITIAL_FILTERS_STATE: ProductFiltersObject = {
    [PRODUCTS_FILTER.productType]: null,
    [PRODUCTS_FILTER.productFilter]: 'none',
    [PRODUCTS_FILTER.brand]: null,
    [PRODUCTS_FILTER.excludeProductTypes]: null,
};

const FIXED_ITEM_HEIGHT = 68;
const FIXED_SIZE_ITEM_HEIGHT = 70;
const FIXED_OPENED_ITEM_HEIGHT = 120;

const BUTTON_ROW_HEIGHT = 60;

function AddProductsSection({
    disableMainActionButton,
    hideProductTypes,
    icon,
    mainActionButtonCaption,
    onCancel,
    onMainAction,
    preSelectedProducts,
    showOftenUsedFilter = false,
    showInventoryFilter = false,
    showUnitTypes = false,
    testIdPrefix,
    title,
}: Props) {
    const { ImagesService } = useImagesContext();
    const { getDatabase } = useDatabase();
    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();
    const { userProfileData } = useUserContext();
    const { initProductQuantities } = useAddProductsContext();

    const [filtersState, setFiltersState] = useState<ProductFiltersObject>(
        () => ({
            ...INITIAL_FILTERS_STATE,
            excludeProductTypes: hideProductTypes || null,
        }),
    );
    const [filteredProducts, setFilteredProducts] = useState<ProductModel[][]>(
        [],
    );

    const [areAllSizesVisible, setAreAllSizesVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [itemSizes, setItemSizes] = useState<
        Record<number, { height: number; index: number; isOpen: boolean }>
    >([]);
    const [searchState, setSearchState] = useState('');
    const [showAlertAboutCustomProducts, setShowAlertAboutCustomProducts] =
        useState(false);

    const database = useMemo(() => getDatabase(), [getDatabase]);
    const listRef = useRef<List>();

    const { productQuantities } = useAddProductsContext();

    const selectedProductsIds = useMemo(() => {
        const keys = Object.keys(productQuantities);

        return keys.filter(
            (key) =>
                productQuantities[key]?.quantity &&
                parseInt(productQuantities[key].quantity, 10) > 0,
        );
    }, [productQuantities]);

    const selectedProductsWithQuantities = useMemo(
        () =>
            selectedProductsIds.map((id) => {
                return {
                    id,
                    quantity: productQuantities[id]?.quantity || '0',
                    unitType: productQuantities[id]?.unitType,
                };
            }),
        [productQuantities, selectedProductsIds],
    );

    useEffect(() => {
        if (preSelectedProducts?.length) {
            initProductQuantities(preSelectedProducts);
        }
    }, [preSelectedProducts, initProductQuantities]);

    const _onMainAction = useCallback(
        () => onMainAction(selectedProductsWithQuantities),
        [onMainAction, selectedProductsWithQuantities],
    );

    const handleSearchInputChange = useCallback(
        (value: string) => setSearchState(value),
        [setSearchState],
    );

    const clearSearchText = useCallback(
        () => setSearchState(''),
        [setSearchState],
    );

    const searchTextDebounced = useDebouncedValue(searchState);

    const getProductGroupMaxEntryFrequency = useCallback(
        async (products: ProductModel[]) => {
            const entryProductService = new EntryProduct({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const frequencies = await Promise.all(
                products.map((product) =>
                    entryProductService.getByParamCount(
                        'product_id',
                        product.id,
                    ),
                ),
            );

            return Math.max(...frequencies);
        },
        [ImagesService, database],
    );

    const getProductsByEntryFrequency = useCallback(
        (
            productsGroups: ProductModel[][],
        ): Promise<ProductGroupWithFrequency[]> =>
            Promise.all(
                productsGroups.map((productsGroup) =>
                    getProductGroupMaxEntryFrequency(productsGroup).then(
                        (maxFrequency) => ({
                            frequency: maxFrequency,
                            group: productsGroup,
                        }),
                    ),
                ),
            ),
        [getProductGroupMaxEntryFrequency],
    );

    const getSortedProductsByFrequency = useCallback(
        async (productsGroups: ProductModel[][]): Promise<ProductModel[][]> => {
            let productsGroupsByFrequency: ProductGroupWithFrequency[] = [];

            if (filtersState.productFilter === 'often-used') {
                productsGroupsByFrequency = await getProductsByEntryFrequency(
                    productsGroups,
                );
            } else {
                return productsGroups;
            }

            return productsGroupsByFrequency
                .sort((group1, group2) => group2.frequency - group1.frequency)
                .map((group) => group.group);
        },
        [filtersState.productFilter, getProductsByEntryFrequency],
    );

    const fetchProducts = useCallback(async () => {
        const productService = new ProductService({
            database,
            imageService: ImagesService,
            logDBAction: () => null,
            logError: Logger.logError,
        });

        setIsLoading(true);
        setIsError(false);

        try {
            const fetchedProducts = await productService.getFilteredProducts({
                searchText: searchTextDebounced,
                filters: filtersState,
                filterType: FILTER_TYPE.LOKI,
                userId: userProfileData?.id ?? '',
            });

            const groupedProducts = getGroupedProducts(fetchedProducts);

            const sortedGroupedProducts = await getSortedProductsByFrequency(
                groupedProducts,
            );

            setFilteredProducts(sortedGroupedProducts);
        } catch (e) {
            setIsError(true);
        }

        setIsLoading(false);
    }, [
        database,
        filtersState,
        searchTextDebounced,
        userProfileData?.id,
        getSortedProductsByFrequency,
        ImagesService,
    ]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchProducts();
        }
    }, [fetchProducts, isInitialSyncInProgress]);

    const setFilters = useCallback((filters: ProductFiltersObject) => {
        setFiltersState((prev) => ({ ...prev, ...filters }));
    }, []);

    useEffect(() => {
        setItemSizes(
            filteredProducts.map((productGroup, i) => {
                if (listRef.current) {
                    listRef.current.resetAfterIndex(i);
                }

                return {
                    height: areAllSizesVisible
                        ? FIXED_OPENED_ITEM_HEIGHT +
                              productGroup?.length * FIXED_SIZE_ITEM_HEIGHT ||
                          FIXED_ITEM_HEIGHT
                        : FIXED_ITEM_HEIGHT,
                    index: i,
                    isOpen: areAllSizesVisible,
                };
            }),
        );
    }, [filteredProducts, areAllSizesVisible]);

    const getItemSize = useCallback(
        (index: number) => itemSizes[index].height || FIXED_ITEM_HEIGHT,
        [itemSizes],
    );

    const setSizeToOpen = useCallback(
        (index: number) => {
            if (listRef.current) {
                listRef.current.resetAfterIndex(index);
            }
            setItemSizes((sizes) => ({
                ...sizes,
                [index]: {
                    ...sizes[index],
                    height:
                        FIXED_OPENED_ITEM_HEIGHT +
                            filteredProducts[index].length *
                                FIXED_SIZE_ITEM_HEIGHT || FIXED_ITEM_HEIGHT,
                    isOpen: true,
                },
            }));
        },
        [filteredProducts],
    );

    const setSizeToClose = useCallback((index: number) => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(index);
        }

        setItemSizes((sizes) => ({
            ...sizes,
            [index]: {
                ...sizes[index],
                height: FIXED_ITEM_HEIGHT,
                isOpen: false,
            },
        }));
    }, []);

    const toggleAreAllSizesVisible = useCallback(() => {
        setAreAllSizesVisible((prev) => !prev);
    }, []);

    const onProductClick = useCallback(
        (index: number) => {
            if (itemSizes[index]?.isOpen) {
                setSizeToClose(index);
            } else {
                setSizeToOpen(index);
            }
        },
        [itemSizes, setSizeToOpen, setSizeToClose],
    );

    const openCustomProductsAlert = useCallback(() => {
        setShowAlertAboutCustomProducts(true);
    }, []);

    const closeCustomProductsAlert = useCallback(() => {
        setShowAlertAboutCustomProducts(false);
    }, []);

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: onCancel,
            shouldShowAlertOnCancel: !!selectedProductsIds.length,
        });

    useEscapeButton(handleFormCancelButtonClick);

    const emptyStateMessage = useMemo(
        () =>
            t(
                searchTextDebounced
                    ? 'ProductSelection:search_blankslate'
                    : 'ProductSelection:blankslate',
            ),
        [searchTextDebounced],
    );

    const isCustomProductsFilterActive =
        filtersState.productType === ProductType.custom;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    minHeight: 650,
                    maxHeight: 650,
                }}
            >
                <Box
                    sx={{
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        borderTopLeftRadius: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        pl: 6,
                    }}
                >
                    <Typography
                        component="h5"
                        fontSize={20}
                        fontWeight={700}
                        color={COLOR.paleSky}
                        my={3}
                    >
                        {title}
                    </Typography>
                    <SearchInput
                        onChange={handleSearchInputChange}
                        onClear={clearSearchText}
                        testId={`${testIdPrefix}-SearchInput`}
                        value={searchState}
                        sx={{ width: '12rem', mr: 5 }}
                        inputStyles={{ mb: 4 }}
                    />
                    <Box sx={{ overflow: 'auto', width: '100%' }}>
                        <FilterMenu
                            brand={filtersState.brand}
                            filter={filtersState.productFilter}
                            hideProductTypes={hideProductTypes}
                            onFilterChange={setFilters}
                            productType={filtersState.productType}
                            showOftenUsedFilter={showOftenUsedFilter}
                            showInventoryFilter={showInventoryFilter}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        borderBottomWidth: 1,
                        borderTopWidth: 1,
                        borderTopRightRadius: 16,
                        overflowY: 'auto',
                    }}
                >
                    {isCustomProductsFilterActive ? (
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                height: BUTTON_ROW_HEIGHT,
                                px: 3,
                            }}
                        >
                            <Button
                                caption={t(
                                    'Inventory:button:create_custom_product',
                                )}
                                disabled={isLoading}
                                onClick={openCustomProductsAlert}
                                startIcon={<Add />}
                                variant="text"
                            />
                        </Box>
                    ) : null}
                    {isError ? (
                        <ErrorState
                            onRefreshClick={fetchProducts}
                            testID={`${testIdPrefix}-ErrorState`}
                        />
                    ) : isLoading ? (
                        <TableSkeleton />
                    ) : filteredProducts.length === 0 ? (
                        <EmptyState
                            message={emptyStateMessage}
                            testID={`${testIdPrefix}-EmptySearchResult`}
                        />
                    ) : (
                        <ProductsList
                            icon={icon}
                            filteredProducts={filteredProducts}
                            getItemSize={getItemSize}
                            itemSizes={itemSizes}
                            marginTop={
                                isCustomProductsFilterActive
                                    ? BUTTON_ROW_HEIGHT
                                    : undefined
                            }
                            onProductClick={onProductClick}
                            ref={listRef}
                            showUnitTypes={showUnitTypes}
                        />
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flex: 1,
                    py: 3,
                    px: 4,
                }}
            >
                <FormControlLabel
                    checked={areAllSizesVisible}
                    control={
                        <Switch
                            data-test-id={`${testIdPrefix}-AllSizesSwitch`}
                            onChange={toggleAreAllSizesVisible}
                            value={areAllSizesVisible}
                        />
                    }
                    label={t('Inventory:show_sizes', {
                        defaultValue: 'Show sizes',
                    })}
                />
                <Box>
                    <Button
                        testID={`${testIdPrefix}-cancel`}
                        sx={{ mr: 3 }}
                        caption={t('Actions:cancel')}
                        onClick={handleFormCancelButtonClick}
                    />
                    <TooltipSyncButton
                        tooltip={t('AddEditModalHeader:button_tooltip', {
                            defaultValue:
                                'Please wait for the synchronization to complete',
                        })}
                        onClick={_onMainAction}
                        showTooltip={isSyncInProgress}
                        disabled={disableMainActionButton || isSyncInProgress}
                        testID={`${testIdPrefix}-submit`}
                        color="primary"
                        variant="contained"
                    >
                        {mainActionButtonCaption}
                    </TooltipSyncButton>
                </Box>
            </Box>
            {showAlertAboutCustomProducts ? (
                <AlertDialog
                    close={closeCustomProductsAlert}
                    message={t('ProductSelection:custom_products_web_info')}
                    showCancelButton={false}
                    testID="AddProductsModal-CustomProductsAlertDialog"
                    title={t('Generic:info')}
                    onConfirmButtonClick={() => null}
                />
            ) : null}
            {showCancelAlert ? renderCancelAlert() : null}
        </>
    );
}

export default memo(AddProductsSection);
