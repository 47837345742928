import { Collection, Database, Q } from '@nozbe/watermelondb';

import { RegionModel } from '../../types/Region';

class Region {
    // @ts-ignore
    private database: Database;
    private collection: Collection<RegionModel>;
    private table = 'regions';

    constructor(database: Database) {
        this.database = database;
        this.collection = database.collections.get(this.table);
    }

    getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value));
    }

    getAll() {
        return this.collection.query();
    }
}

export default Region;
