import { Collection, Database, Q } from '@nozbe/watermelondb';

import EntryProcedure from './EntryProcedure';
import Organisation from './Organisation';
import { EntryProcedureModel } from '../../types/EntryProcedure';
import { ProcedureModel, ProcedurePayload } from '../../types/Procedures';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class Procedure {
    private database: Database;
    private collection: Collection<ProcedureModel>;
    private table = 'procedures';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value));
    }

    async add(payload: ProcedurePayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const payloadPrice = (payload?.price || '').replace(',', '.');
            const isPriceNumber = !isNaN(Number(payloadPrice));
            const createdProcedure = await this.collection.create(
                (procedure) => {
                    procedure.category = payload.category;
                    procedure.name = payload.name;
                    procedure.description = payload.description;
                    procedure.price = (
                        (isPriceNumber && payloadPrice) ||
                        '0'
                    ).replace(',', '.');
                    procedure.available = payload.available;
                    procedure.userId = userId;
                    procedure.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create procedure',
                modelName: this.table,
                payload: createdProcedure,
            });

            return createdProcedure;
        });
    }

    async update(
        id: string,
        payload: Partial<ProcedurePayload>,
        updateEntriesRelatedProceduresPrice = true,
    ) {
        const procedureElement = await this.getByID(id);
        const procedureEntries = procedureElement.entries
            ? await procedureElement.entries.fetch()
            : [];

        let entryProceduresToUpdate: EntryProcedureModel[] = [];

        return await this.database.write(async () => {
            const payloadPrice = (payload?.price || '').replace(',', '.');
            const isPriceNumber = !isNaN(Number(payloadPrice));
            const updatedProcedureElement = await procedureElement.update(
                (procedure) => {
                    procedure.category =
                        payload.category ?? procedureElement.category;
                    procedure.name = payload.name ?? procedureElement.name;
                    procedure.description =
                        payload.description ?? procedureElement.description;
                    procedure.price =
                        (isPriceNumber && payloadPrice) ||
                        procedureElement.price ||
                        '0';
                    procedure.available =
                        payload.available ?? procedureElement.available;
                    procedure.userId = procedureElement.userId;
                    procedure.organisationId = procedureElement.organisationId;
                },
            );

            this.options.logDBAction({
                message: 'Update procedure',
                modelName: this.table,
                payload: procedureElement,
            });

            // Update price and name of related entry_procedures from entries without invoice
            if (procedureEntries.length > 0) {
                const entryProcedureService = new EntryProcedure({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const entryProcedures = await entryProcedureService.getByParam(
                    'procedure_id',
                    id,
                );

                await Promise.all(
                    entryProcedures.map(async (entryProcedure) => {
                        const entries = await entryProcedure.entries.fetch();
                        if (entries.length !== 0 && !entries[0].invoiceId) {
                            const entryProcedureToUpdate =
                                await entryProcedure.prepareUpdate(
                                    (entryProc) => {
                                        entryProc.name =
                                            payload.name ??
                                            procedureElement.name;

                                        if (
                                            updateEntriesRelatedProceduresPrice
                                        ) {
                                            entryProc.price =
                                                payload.price ??
                                                (procedureElement.price || '0');
                                        }
                                    },
                                );

                            entryProceduresToUpdate = [
                                ...entryProceduresToUpdate,
                                entryProcedureToUpdate,
                            ];
                        }
                    }),
                );
            }

            this.database.batch(...entryProceduresToUpdate);

            this.options.logDBAction({
                message: 'Update procedure - update entry procedures',
                modelName: this.table,
                payload: entryProceduresToUpdate,
            });

            return updatedProcedureElement;
        });
    }

    async deleteByID(id: string) {
        const procedureElement = await this.getByID(id);
        const procedureEntries = procedureElement.entries
            ? await procedureElement.entries.fetch()
            : [];

        let entryProceduresToDelete: EntryProcedureModel[] = [];

        await this.database.write(async () => {
            await procedureElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete procedure',
                modelName: this.table,
                payload: procedureElement,
            });

            // Delete related entries to the procedure (entry_procedures)
            if (procedureEntries.length > 0) {
                const entryProcedureService = new EntryProcedure({
                    database: this.database,
                    imageService: this.options.imageService,
                    logDBAction: this.options.logDBAction,
                });

                const entryProcedures = await entryProcedureService.getByParam(
                    'procedure_id',
                    id,
                );

                await Promise.all(
                    entryProcedures.map((entryProcedure) => {
                        entryProceduresToDelete = [
                            ...entryProceduresToDelete,
                            entryProcedure.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            this.database.batch(...entryProceduresToDelete);

            this.options.logDBAction({
                message: 'Delete procedure - delete entry procedures',
                modelName: this.table,
                payload: entryProceduresToDelete,
            });
        });
    }
}

export default Procedure;
