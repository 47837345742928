import { Database } from '@nozbe/watermelondb';
import User from 'shared/db/services/User';
import { UserModel } from 'shared/types/User';

import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { UserWithColor } from '@/services/user/types';

export async function mapUsersWithColors(
    users: UserModel[],
    database: Database,
): Promise<UserWithColor[]> {
    const userService = new User({
        database,
        imageService: new FirebaseImagesService(),
        logDBAction: Logger.logRecordActivity,
    });

    return await Promise.all(
        users.map(async (user) => {
            const userColor = await userService.getColorById(user.id);
            return { user, userColor };
        }),
    );
}
