import { Backdrop, Box, Button, Typography } from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { BackdropLoader, Modal, SearchInput } from '@/components';
import { ZIndex } from '@/constants/zIndex';
import { useDebouncedValue } from '@/hooks';
import { COLOR } from '@/theme/colors';

import { ListState, Props } from './types';

function SelectItemsModal<T, E>({
    addSelectedItemsButtonText,
    backdropLoaderText,
    blockListItemsSelection,
    close,
    CustomFooterComponent,
    emptyMessage,
    fetchItemsFn,
    hideSuggestions,
    isLoading,
    isOpen,
    ListComponent,
    listComponentProps,
    multiSelect = true,
    noItemsFoundMessage,
    onCancelButtonClick,
    onSelectItems,
    onSelectedItemsChange,
    searchable = true,
    selectedItems,
    selectedItemsCountTextKey,
    showAllButtonText,
    showBackdropLoader,
    showSuggestions,
    testIdPrefix,
    title,
}: Props<T, E>) {
    const listWrapperRef = useRef<HTMLDivElement | null>(null);

    const { t } = useTranslation();

    // Items count info after initial db fetch
    const initialItemsCountRef = useRef({ checked: false, count: 0 });

    const [items, setItems] = useState<T[]>([]);
    const [searchText, setSearchText] = useState('');
    const [selectedItemsSet, setSelectedItemsSet] = useState<Set<T>>(
        new Set(selectedItems),
    );

    const fetchItems = useCallback(
        async (query = '') => {
            const items = await fetchItemsFn(query);

            setItems(items);

            if (!initialItemsCountRef.current.checked) {
                initialItemsCountRef.current = {
                    checked: true,
                    count: items.length,
                };
            }
        },
        [fetchItemsFn],
    );

    const resetModalState = useCallback(() => {
        setSearchText('');
        setSelectedItemsSet(new Set());
    }, []);

    const handleSearchInputChange = useCallback((value: string) => {
        setSearchText(value);
    }, []);

    const clearSearchText = useCallback(() => {
        setSearchText('');
    }, []);

    const debouncedSearchText = useDebouncedValue(searchText);

    useEffect(() => {
        if (!isOpen) {
            resetModalState();
        }
    }, [isOpen, resetModalState]);

    useEffect(() => {
        if (isOpen) {
            fetchItems(debouncedSearchText);
        }
    }, [debouncedSearchText, fetchItems, isOpen]);

    useEffect(() => {
        if (isOpen) {
            setSelectedItemsSet(new Set(selectedItems));
        }
    }, [isOpen, selectedItems]);

    const handleAddSelectedItems = useCallback(
        (itemsToSelect: T[] | T) => {
            if (onSelectItems) {
                onSelectItems(itemsToSelect);
            }

            close();
        },
        [close, onSelectItems],
    );

    const handleItemClick = useCallback(
        (item: T) => {
            const selectedItemsSetCopy = new Set(selectedItemsSet);

            if (!multiSelect) {
                selectedItemsSetCopy.clear();
            }

            if (selectedItemsSetCopy.has(item)) {
                selectedItemsSetCopy.delete(item);
            } else {
                selectedItemsSetCopy.add(item);
            }

            setSelectedItemsSet(selectedItemsSetCopy);

            if (onSelectedItemsChange) {
                onSelectedItemsChange([...selectedItemsSetCopy]);
            }
        },
        [multiSelect, onSelectedItemsChange, selectedItemsSet],
    );

    const handleAddSelectedItemsButtonClick = useCallback(() => {
        const selectedItemsArray = [...selectedItemsSet];

        handleAddSelectedItems(
            multiSelect ? selectedItemsArray : selectedItemsArray[0],
        );
    }, [handleAddSelectedItems, multiSelect, selectedItemsSet]);

    const emptyStateText = useMemo((): string => {
        if (items.length) {
            return '';
        }

        // Display 'There are no [items] created yet' text always when
        // initial [items] count is 0 regardless of search text value
        if (!debouncedSearchText || !initialItemsCountRef.current.count) {
            return emptyMessage;
        }

        return noItemsFoundMessage ?? '';
    }, [debouncedSearchText, emptyMessage, items.length, noItemsFoundMessage]);

    const listState = useMemo<ListState>(() => {
        if (showSuggestions) {
            if (!items.length) {
                return 'show_empty_suggestions';
            }

            return 'show_suggestions';
        }

        if (!items.length) {
            return 'empty_state';
        }

        return 'show_entire_list';
    }, [items.length, showSuggestions]);

    function renderListContent() {
        const listComponent = (
            <ListComponent
                testIdPrefix={testIdPrefix}
                items={items}
                selectedItems={selectedItemsSet}
                onItemClick={handleItemClick}
                multiSelect={multiSelect}
                {...listComponentProps}
            />
        );

        const emptyStateComponent = (
            <Typography
                className="mt-16 text-center"
                color={COLOR.regentGray}
                data-test-id={`${testIdPrefix}-ListEmptyStateText`}
                variant="body2"
            >
                {emptyStateText}
            </Typography>
        );

        switch (listState) {
            case 'empty_state': {
                return emptyStateComponent;
            }

            case 'show_entire_list': {
                return listComponent;
            }

            case 'show_suggestions': {
                return (
                    <Box>
                        <Box className="flex flex-row justify-between items-center mb-2">
                            <Typography
                                color={COLOR.regentGray}
                                data-test-id={`${testIdPrefix}-SuggestionsText`}
                                variant="body2"
                            >
                                {t('HorsesSelection:suggestions')}
                            </Typography>
                            <Button
                                data-test-id={`${testIdPrefix}-ShowAllButton`}
                                onClick={hideSuggestions}
                            >
                                {showAllButtonText}
                            </Button>
                        </Box>
                        {listComponent}
                    </Box>
                );
            }

            case 'show_empty_suggestions': {
                return (
                    <Box className="flex flex-col justify-center">
                        {emptyStateComponent}
                        <Button
                            className="self-center mt-4"
                            data-test-id={`${testIdPrefix}-ShowAllButton`}
                            onClick={hideSuggestions}
                        >
                            {showAllButtonText}
                        </Button>
                    </Box>
                );
            }
        }
    }

    function renderFooter() {
        if (CustomFooterComponent) {
            return CustomFooterComponent;
        }

        return (
            <Box className="flex items-center border-1 border-t border-t-gray-200 pt-6 px-6">
                {multiSelect ? (
                    <Typography
                        color={COLOR.regentGray}
                        data-test-id={`${testIdPrefix}-SelectedItemsCountText`}
                        variant="body2"
                    >
                        {t(selectedItemsCountTextKey, {
                            number: selectedItemsSet.size.toString(),
                        })}
                    </Typography>
                ) : null}
                <Box className="ml-auto">
                    <Button
                        className="mr-3"
                        color="inherit"
                        data-test-id={`${testIdPrefix}-CancelButton`}
                        onClick={onCancelButtonClick || close}
                    >
                        {t('Actions:cancel')}
                    </Button>
                    <Button
                        data-test-id={`${testIdPrefix}-AddSelectedItemsButton`}
                        onClick={handleAddSelectedItemsButtonClick}
                        variant="contained"
                    >
                        {addSelectedItemsButtonText}
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            styles="mt-[5vh] max-w-xl pt-8 pb-6 relative"
            testID={testIdPrefix}
        >
            {showBackdropLoader && isLoading ? (
                <BackdropLoader
                    isLoading={isLoading}
                    sx={{
                        position: 'absolute',
                        borderRadius: '1rem',
                        backgroundColor: COLOR.white60,
                        color: COLOR.ebonyClay,
                    }}
                    loadingText={backdropLoaderText}
                />
            ) : null}
            <Box className="flex items-center justify-between px-6">
                <Typography
                    color={COLOR.paleSky}
                    fontSize="1.2rem"
                    fontWeight={700}
                >
                    {title}
                </Typography>
                {searchable ? (
                    <SearchInput
                        inputStyles={{ height: 36 }}
                        onClear={clearSearchText}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        testId={`${testIdPrefix}-SearchInput`}
                    />
                ) : null}
            </Box>
            <Box
                className="h-[50vh] max-h-96 overflow-auto mt-6 px-6 relative"
                ref={listWrapperRef}
            >
                {blockListItemsSelection ? (
                    <Backdrop
                        data-test-id={`${testIdPrefix}-ListItemsBlockingOverlay`}
                        open
                        sx={{
                            borderRadius: '1rem',
                            backgroundColor: COLOR.white60,
                            height: listWrapperRef.current?.scrollHeight,
                            opacity: 1,
                            position: 'absolute',
                            zIndex: ZIndex.index10,
                        }}
                    />
                ) : null}
                {renderListContent()}
            </Box>
            {renderFooter()}
        </Modal>
    );
}

export default SelectItemsModal;
