import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import {
    EntryProductModel,
    EntryProductPayload,
} from '../../types/EntryProduct';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class EntryProduct {
    private database: Database;
    private collection: Collection<EntryProductModel>;
    private table = 'entry_products';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getNewerThan(date: number) {
        return this.collection.query(Q.where('created_at', Q.gt(date))).fetch();
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getByParamQuery(param: string, value: any) {
        return this.collection.query(Q.where(param, value));
    }

    async getByParam(param: string, value: any) {
        return this.getByParamQuery(param, value).fetch();
    }

    async getByParamCount(param: string, value: any) {
        return this.getByParamQuery(param, value).fetchCount();
    }

    async add(payload: EntryProductPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdEntryProduct = await this.collection.create(
                (entryProduct) => {
                    entryProduct.entryId = payload.entryId;
                    entryProduct.productId = payload.productId;
                    entryProduct.quantity = payload.quantity;
                    entryProduct.userId = userId;
                    entryProduct.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create entry product',
                modelName: this.table,
                payload: createdEntryProduct,
            });
        });
    }

    async update(id: string, payload: EntryProductPayload) {
        const entryProductElement = await this.getByID(id);

        await this.database.write(async () => {
            await entryProductElement.update((entryProduct) => {
                entryProduct.entryId = payload.entryId;
                entryProduct.productId = payload.productId;
                entryProduct.quantity = payload.quantity;
                entryProduct.userId = entryProductElement.userId;
                entryProduct.organisationId =
                    entryProductElement.organisationId;
            });

            this.options.logDBAction({
                message: 'Update entry product',
                modelName: this.table,
                payload: entryProductElement,
            });
        });
    }

    async deleteByID(id: string) {
        const entryProductElement = await this.getByID(id);

        await this.database.write(async () => {
            await entryProductElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete entry product',
                modelName: this.table,
                payload: entryProductElement,
            });
        });
    }
}

export default EntryProduct;
