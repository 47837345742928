import CustomIcon from '../../assets/icons/products/custom.png';
import ShoesIcon from '../../assets/icons/products/horseshoe.png';
import NailsIcon from '../../assets/icons/products/nail.png';
import OtherIcon from '../../assets/icons/products/other.png';
import RaspsIcon from '../../assets/icons/products/rasp.png';
import ToolsIcon from '../../assets/icons/products/tool.png';
import { ProductType } from '../../types/Products';

export const ProductImages = {
    [ProductType.shoes]: ShoesIcon,
    [ProductType.nails]: NailsIcon,
    [ProductType.rasps]: RaspsIcon,
    [ProductType.tools]: ToolsIcon,
    [ProductType.other]: OtherIcon,
    [ProductType.custom]: CustomIcon,
};
