import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { useFormik } from 'formik';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { of } from 'rxjs';
import { ContactsAnalytics } from 'shared/analytics/contacts';
import Contact from 'shared/db/services/Contact';
import { ContactModel, ContactPayload } from 'shared/types/Contacts';
import { HorseModel } from 'shared/types/Horses';
import { Location } from 'shared/types/Location';
import {
    getRoles,
    getRolesDictionary,
    getRolesHumanized,
} from 'shared/utils/contactRoles';

import { ContactsIcon, UserProfileIcon } from '@/assets/svg';
import {
    AddEditModalHeader,
    Modal,
    RemoveEntityAlert,
    GooglePlacesAutocomplete,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useDatabase,
    useCancelEntityFormAlert,
    useEscapeButton,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';
import { ContactValidationSchema } from '@/validations/contact';

import BackdropLoader from '../Loaders/BackdropLoader';
import PhoneNumber from '../PhoneNumber';
import PhotoSelector from '../PhotoSelector';
import SelectHorsesSection from '../SelectHorsesSection';
import TooltipSyncButton from '../TooltipButton';

import { AddEditContactFormValues, Props } from './types';

const INITIAL_VALUES: AddEditContactFormValues = {
    firstName: '',
    lastName: '',
    business: '',
    phone: {
        full: '',
        number: '',
        prefix: '',
    },
    address: '',
    billable: false,
    email: '',
    horses: [],
    notes: '',
    role: '',
    location: null,
};

function AddEditContactModal({
    allowHorsesSelection = true,
    close,
    CreateHorseModal,
    contact,
    horse,
    contactInvoicesCount = 0,
    isEditMode,
    onSave,
    closeOnSubmit = false,
    onRemove,
    enableRemoveContact = true,
}: Props) {
    const { userProfileData } = useUserContext();
    const { images, ImagesService, isImagesSyncInProgress } =
        useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const [initialValues, setInitialValues] =
        useState<AddEditContactFormValues>(INITIAL_VALUES);

    const [isRemoveContactAlertOpen, setShowRemoveContactAlert] =
        useState<boolean>(false);

    const [isCreating, setIsCreating] = useState(false);

    const [currentAvatarUrl, setCurrentAvatarUrl] = useState<
        string | null | undefined
    >(null);

    const previousHorsesRef = useRef<HorseModel[]>([]);

    const closeRemoveContactAlert = useCallback(
        () => setShowRemoveContactAlert(false),
        [],
    );

    const roles = useMemo(() => {
        return getRoles(t);
    }, []);

    const rolesHumanized = useMemo(() => {
        return getRolesHumanized(t);
    }, []);

    const rolesDictionary = useMemo(() => {
        return getRolesDictionary(t);
    }, []);

    const prepareInitialValues = useCallback(async () => {
        if (horse) {
            const initialValues = { ...INITIAL_VALUES };
            initialValues['horses'] = [horse];
            setInitialValues(initialValues);
            return;
        }

        if (!contact || !isEditMode) {
            return;
        }

        try {
            const contactHorses = await contact.horses.fetch();
            const contactLocation = await contact.location?.fetch();

            previousHorsesRef.current = contactHorses;

            const values: AddEditContactFormValues = {
                firstName: contact.firstName || '',
                lastName: contact.lastName || '',
                business: contact.businessName || '',
                phone: {
                    full:
                        contact.phonePrefix && contact.phone
                            ? `${contact.phonePrefix}${contact.phone}`
                            : '',
                    number: contact.phone || '',
                    prefix: contact.phonePrefix || '',
                },
                address: contact.address || '',
                billable: contact.billable || false,
                email: contact.email || '',
                horses: contactHorses || [],
                notes: contact.comments || '',
                role: contact.role ? rolesDictionary[contact.role] : '',
                location: contactLocation || null,
            };

            setInitialValues(values);
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [contact, horse, isEditMode, rolesDictionary]);

    useEffect(() => {
        if (isEditMode || horse) {
            prepareInitialValues();
        }
    }, [horse, isEditMode, prepareInitialValues]);

    const database = useDatabase();

    const contactAvatar = useMemo(() => {
        if (!contact) {
            return null;
        }
        return ImagesService.getSingleImageByEntityId(images, contact.id);
    }, [contact, images, ImagesService]);

    const contactAvatarUrl = contactAvatar?.imageURL ?? '';

    const handleOnSubmit = useCallback(
        async (values: AddEditContactFormValues) => {
            setIsCreating(true);
            try {
                const contactService = new Contact({
                    database: database.getDatabase(),
                    logDBAction: Logger.logRecordActivity,
                    imageService: ImagesService,
                });

                const {
                    firstName,
                    lastName,
                    address,
                    notes,
                    role,
                    business,
                    phone,
                    email,
                    billable,
                    horses,
                    location,
                } = values;

                const payload: ContactPayload = {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    address,
                    role: role ? rolesHumanized[role] : isEditMode ? null : '',
                    businessName: business,
                    phone: phone.number,
                    phonePrefix:
                        phone.prefix && phone.number ? phone.prefix : '',
                    email,
                    billable,
                    comments: notes,
                    horses,
                    location,
                    removeAvatar: isEditMode && !currentAvatarUrl,
                    image: currentAvatarUrl
                        ? {
                              uri: currentAvatarUrl,
                              documentID: contactAvatar?.documentID,
                          }
                        : undefined,
                };

                const userId = userProfileData?.id || '';

                let newUpdatedContact: ContactModel;

                if (isEditMode) {
                    if (!contact?.id) {
                        throw new Error();
                    }

                    const previousRole = contact.role;

                    newUpdatedContact = await contactService.update(
                        contact.id,
                        payload,
                        userId,
                    );

                    ContactsAnalytics.logUserUpdatedContact(
                        FirebaseAnalytics.logEvent,
                        {
                            contact: newUpdatedContact,
                            previousRole,
                            previousHorses: previousHorsesRef.current,
                        },
                    );
                } else {
                    newUpdatedContact = await contactService.add(
                        payload,
                        userId,
                    );

                    ContactsAnalytics.logUserCreatedContact(
                        FirebaseAnalytics.logEvent,
                        {
                            contact: newUpdatedContact,
                            isOwner: userOrganisation?.owner ?? true,
                        },
                    );
                }

                if (onSave) {
                    onSave(newUpdatedContact);
                }

                let toastNotificationMessage = t(
                    'App:Messages:has_been_created_successfully',
                    {
                        entity: t('Entities:contact'),
                    },
                );

                if (isEditMode) {
                    toastNotificationMessage = t(
                        'App:Messages:has_been_edited_successfully',
                        {
                            entity: t('Entities:contact'),
                        },
                    );
                }

                Snackbar.showToastNotification({
                    message: toastNotificationMessage,
                });

                if (closeOnSubmit || isEditMode) {
                    close();
                }
            } catch (error) {
                setIsCreating(false);

                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            database,
            ImagesService,
            rolesHumanized,
            isEditMode,
            currentAvatarUrl,
            contactAvatar?.documentID,
            userProfileData?.id,
            onSave,
            closeOnSubmit,
            contact?.id,
            contact?.role,
            userOrganisation?.owner,
            close,
        ],
    );

    const form = useFormik<AddEditContactFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleOnSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: ContactValidationSchema.addEditContactForm,
    });

    const handleSaveButtonClick = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    const onPhoneNumberChange = useCallback(
        (
            phone: string,
            data: {
                name: string;
                dialCode: string;
                countryCode: string;
            },
        ) => {
            form.setFieldValue('phone', {
                full: phone,
                number: data.dialCode
                    ? phone.substring(data.dialCode.length + 1)
                    : '',
                prefix: data.dialCode ? `+${data.dialCode}` : '',
            });
        },
        [form],
    );

    const handleSelectHorses = useCallback(
        (horses: HorseModel[]) => {
            form.setFieldValue('horses', horses);
        },
        [form],
    );

    const handleRemoveHorse = useCallback(
        (horse: HorseModel) => {
            const previousHorses = form.values.horses;
            const newHorses = previousHorses?.filter(
                (prevHorse) => prevHorse.id !== horse.id,
            );

            form.setFieldValue('horses', newHorses);
        },
        [form],
    );

    useEffect(() => {
        setCurrentAvatarUrl(contactAvatarUrl);
    }, [contactAvatarUrl]);

    const handlePhotoChange = useCallback(
        (url: string) => setCurrentAvatarUrl(url),
        [],
    );

    const handleRemoveContact = useCallback(async () => {
        try {
            const contactService = new Contact({
                database: database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            await contactService.deleteByID(contact?.id ?? '');

            ContactsAnalytics.logUserDeletedContact(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:contact'),
                }),
            });

            close();

            if (onRemove) {
                onRemove();
            }
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [database, ImagesService, contact?.id, close, onRemove]);

    const showRemoveContactAlert = useCallback(() => {
        if (contactInvoicesCount === 0) {
            setShowRemoveContactAlert(true);
        } else {
            Snackbar.showToastNotification({
                message: t('Contact:alert:contact_has_invoices'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [contactInvoicesCount]);

    const handlePlaceChange = useCallback(
        (location: Location) => {
            form.setFieldValue('location', location);
            form.setFieldValue('address', location?.name || '');
        },
        [form],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel: form.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    return (
        <Modal
            isOpen
            center
            testID="AddEditContactModal"
            styles="relative py-0 h-[85%]"
            disableRestoreFocus
        >
            <BackdropLoader
                isLoading={
                    (isImagesSyncInProgress || isSyncInProgress) && isCreating
                }
                sx={{
                    position: 'absolute',
                    borderRadius: '1rem',
                    backgroundColor: COLOR.white60,
                    color: COLOR.ebonyClay,
                }}
                loadingText={t(
                    isEditMode
                        ? 'AddContactForm:edit_loading_text'
                        : 'AddContactForm:add_loading_text',
                )}
            />
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <AddEditModalHeader
                    backgroundColor={ENTITY_COLOR.contacts}
                    cancel={handleFormCancelButtonClick}
                    save={handleSaveButtonClick}
                    testIdPrefix="AddEditContactForm"
                    title={t(
                        isEditMode
                            ? 'EditContact:view_title'
                            : 'NewContact:view_title',
                    )}
                    teamName={
                        userOrganisations && userOrganisations?.length > 1
                            ? userOrganisation?.name || ''
                            : undefined
                    }
                    avatarUrl={
                        userOrganisations && userOrganisations?.length > 1
                            ? teamAvatar?.imageURL || ''
                            : undefined
                    }
                    icon={<ContactsIcon color={COLOR.white} />}
                />
                <Box
                    className="px-12 pb-7"
                    sx={{
                        overflow: 'auto',
                        pt: 3,
                    }}
                >
                    <Box className="flex mb-10">
                        <Box className="flex-1 mr-3">
                            <TextField
                                className="mb-5"
                                data-test-id="AddEditContactForm-FirstNameInput"
                                error={
                                    !!form.errors.firstName &&
                                    !!form.touched.firstName
                                }
                                fullWidth
                                helperText={
                                    form.touched.firstName
                                        ? form.errors.firstName
                                        : ''
                                }
                                id="firstName"
                                label={t('AddContactForm:labels:firstName')}
                                name="firstName"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                value={form.values.firstName}
                            />
                            <TextField
                                data-test-id="AddEditContactForm-LastNameInput"
                                error={
                                    !!form.errors.lastName &&
                                    !!form.touched.lastName
                                }
                                fullWidth
                                helperText={
                                    form.touched.lastName
                                        ? form.errors.lastName
                                        : ''
                                }
                                id="lastName"
                                label={t('AddContactForm:labels:lastName')}
                                name="lastName"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                value={form.values.lastName}
                            />
                        </Box>
                        <PhotoSelector
                            firstName={contact?.firstName}
                            lastName={contact?.lastName}
                            currentPhotoUrl={currentAvatarUrl}
                            onPhotoChanged={handlePhotoChange}
                            placeholder={
                                <UserProfileIcon
                                    width={44}
                                    height={58}
                                    color={COLOR.mischka}
                                />
                            }
                        />
                    </Box>
                    <Box className="flex mb-10">
                        <TextField
                            className="flex-1"
                            select
                            data-test-id="AddEditContactForm-RoleSelect"
                            error={!!form.errors.role && !!form.touched.role}
                            fullWidth
                            helperText={
                                form.touched.role ? form.errors.role : ''
                            }
                            id="role"
                            label={t('AddContactForm:labels:role')}
                            name="role"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.role}
                        >
                            <MenuItem
                                data-test-id="AddEditContactForm-RoleItemNone"
                                key="None"
                                value=""
                            >
                                {t('Actions:none')}
                            </MenuItem>
                            {roles.map((role) => {
                                return (
                                    <MenuItem
                                        data-test-id={`AddEditContactForm-RoleItem${role}`}
                                        key={role}
                                        value={role}
                                    >
                                        {role}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <TextField
                            className="flex-1 ml-3"
                            data-test-id="AddEditContactForm-BusinessInput"
                            error={
                                !!form.errors.business &&
                                !!form.touched.business
                            }
                            fullWidth
                            helperText={
                                form.touched.business
                                    ? form.errors.business
                                    : ''
                            }
                            id="business"
                            label={t('AddContactForm:labels:business')}
                            name="business"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.business}
                        />
                    </Box>
                    <Box className="flex mb-4">
                        <PhoneNumber
                            inputClass="flex-1"
                            data-test-id="AddEditContactForm-PhoneInput"
                            error={
                                !!form.errors.phone?.number &&
                                !!form.touched.phone?.number
                            }
                            fullWidth
                            helperText={
                                form.touched.phone?.number
                                    ? form.errors.phone?.number
                                    : ''
                            }
                            id="phone"
                            label={t('AddContactForm:labels:phone')}
                            name="phone.number"
                            onBlur={form.handleBlur}
                            onChange={onPhoneNumberChange}
                            sx={{ mb: 3 }}
                            defaultCountry={
                                isEditMode
                                    ? undefined
                                    : userProfileData?.region?.toLowerCase()
                            }
                            value={form.values.phone.full}
                        />
                        <TextField
                            className="flex-1 ml-3"
                            data-test-id="AddEditContactForm-EmailInput"
                            error={!!form.errors.email && !!form.touched.email}
                            fullWidth
                            helperText={
                                form.touched.email ? form.errors.email : ''
                            }
                            id="email"
                            label={t('AddContactForm:labels:email')}
                            name="email"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.email}
                        />
                    </Box>
                    <GooglePlacesAutocomplete
                        className="mb-5"
                        label={t('AddContactForm:labels:address')}
                        onBlur={form.handleBlur}
                        onPlaceChange={handlePlaceChange}
                        testId="AddEditContactForm-AddressInput"
                        value={
                            form.values.location
                                ? {
                                      description: form.values.location.name,
                                      place_id: form.values.location.placeID,
                                  }
                                : form.values.address
                                ? {
                                      description: form.values.address,
                                      place_id: '',
                                  }
                                : null
                        }
                    />
                    <FormControlLabel
                        checked={form.values.billable}
                        className="mb-5"
                        control={
                            <Switch
                                data-test-id="AddEditContactForm-BillableInput"
                                id="billable"
                                name="billable"
                                value={form.values.billable}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        }
                        label={t('AddContactForm:labels:billable')}
                    />
                    <TextField
                        multiline
                        rows={3}
                        data-test-id="AddEditContactForm-NotesInput"
                        error={!!form.errors.notes && !!form.touched.notes}
                        fullWidth
                        helperText={form.touched.notes ? form.errors.notes : ''}
                        id="notes"
                        label={t('AddContactForm:labels:notes')}
                        name="notes"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.notes}
                    />
                    {CreateHorseModal && allowHorsesSelection ? (
                        <SelectHorsesSection
                            onRemoveHorse={handleRemoveHorse}
                            onSelectHorses={handleSelectHorses}
                            selectedHorses={form.values.horses}
                            CreateHorseModal={CreateHorseModal}
                            testIdPrefix="AddEditContactForm"
                        />
                    ) : null}
                </Box>
                {isEditMode && enableRemoveContact && (
                    <>
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            showTooltip={isSyncInProgress}
                            disabled={isSyncInProgress}
                            color="error"
                            onClick={showRemoveContactAlert}
                            testID="AddContactForm-RemoveButton"
                            startIcon={<DeleteOutlineRoundedIcon />}
                            sx={{ mt: 3, alignSelf: 'center' }}
                            stylesAt="container"
                        >
                            {t('Contact:button:remove')}
                        </TooltipSyncButton>

                        <RemoveEntityAlert
                            dialogText={t('Contact:alert:contact_remove', {
                                firstName: contact?.firstName ?? '',
                                lastName: contact?.lastName ?? '',
                            })}
                            isOpen={isRemoveContactAlertOpen}
                            onClose={closeRemoveContactAlert}
                            onRemove={handleRemoveContact}
                            testID="RemoveContactAlert"
                        />
                    </>
                )}
            </Box>
            {showCancelAlert ? renderCancelAlert() : null}
        </Modal>
    );
}

const enhance = withObservables<Props, unknown>(['contact'], ({ contact }) => {
    return {
        contactInvoicesCount: contact?.invoices.observeCount() || of(0),
    };
});

export default enhance(AddEditContactModal);
