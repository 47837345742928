import { Box, Skeleton } from '@mui/material';
import React from 'react';

function TableSkeletonItem() {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Skeleton
                    height={72}
                    sx={{ mr: 4 }}
                    variant="circular"
                    width={72}
                />
                <Skeleton height={28} width={150} variant="text" />
            </Box>
            <Skeleton height={28} width="10%" variant="text" />
            <Skeleton height={28} width="15%" variant="text" />
            <Skeleton height={28} width={60} variant="text" />
        </Box>
    );
}

export default TableSkeletonItem;
