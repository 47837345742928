import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function DuplicateEntityAlert({
    dialogText,
    isOpen,
    onClose,
    onDuplicate,
    testID,
}: Props) {
    const { t } = useTranslation();

    const duplicateAndCloseDialog = useCallback(() => {
        onDuplicate();
        onClose();
    }, [onClose, onDuplicate]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-test-id={testID}
        >
            <DialogTitle>{t('Actions:confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    dangerouslySetInnerHTML={{ __html: dialogText }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('Actions:cancel')}</Button>
                <Button
                    onClick={duplicateAndCloseDialog}
                    color="primary"
                    autoFocus
                    data-test-id={`${testID}-DuplicateButton`}
                >
                    {t('Actions:confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DuplicateEntityAlert;
