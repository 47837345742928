import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function RemoveContactAlert({ isOpen, onConfirm, onClose }: Props) {
    const { t } = useTranslation();

    const confirmRemove = useCallback(() => {
        onClose();
        onConfirm();
    }, [onClose, onConfirm]);

    return (
        <Dialog open={isOpen} data-test-id="ConfirmRemoveContactAlert">
            <DialogTitle>{t('Invoice:alert:remove_contact_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('Invoice:alert:remove_contact_description')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('Actions:cancel')}</Button>
                <Button
                    onClick={confirmRemove}
                    color="primary"
                    autoFocus
                    data-test-id="confirmRemoveButton"
                >
                    {t('Actions:remove')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RemoveContactAlert;
