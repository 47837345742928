import {
    SHOPPING_PRODUCT_UNIT,
    SHOPPING_PRODUCT_UNIT_VALUE,
} from '../constants/shoppingProductUnit';
import {
    ProductModel,
    ProductQuantities,
    ProductType,
} from '../types/Products';
import { PRODUCT_UNIT_TYPES_MAP } from '../constants/products/productUnitSizes';
import {
    ShoppingListProductChange,
    ShoppingListProductModel,
    ShoppingListProductPayload,
} from '../types/ShoppingListProduct';
import ProductSalesUnit from '../db/services/ProductSalesUnit';
import {
    ParsedShoppingListProduct,
    ShoppingListProductSize,
    ShoppingProductParserParams,
} from '../types/ShoppingList';
import Product from '../db/services/Product';
import User from '../db/services/User';
import { RegionModel } from '../types/Region';
import { Database } from '@nozbe/watermelondb';
import {
    getProductMaterialShapeAndClips,
    getProductName,
    getProductSize,
} from './product';
import { clone, sortBy } from 'lodash';
import { capitalizeFirstLetter, replaceAll } from './string';
import { ProductSalesUnitModel } from '../types/ProductSalesUnit';
import ShoppingListProduct from '../db/services/ShoppingListProduct';
import Organisation from '../db/services/Organisation';
import {
    calculateProductBoxUnitSize,
    calculateProductSelectedUnit,
    getDefaultUnitByProductType,
    getDefaultUnitTypeByProductType,
    mapBoxUnitTypes,
    mapDescriptionToCustomTresholds,
} from './productUnit';

const mergeSameShoppingListProducts = (
    parsedProduct: ParsedShoppingListProduct[],
) => {
    const mergedProducts: ParsedShoppingListProduct[] = [];

    parsedProduct.forEach((product) => {
        const existingProduct = mergedProducts.filter(
            (prod) =>
                `${prod.productAttributes.name} ${prod.productAttributes.materialShapeAndClips}` ===
                `${product.productAttributes.name} ${product.productAttributes.materialShapeAndClips}`,
        );
        if (existingProduct.length) {
            const existingProductIndex = mergedProducts.indexOf(
                existingProduct[0],
            );
            mergedProducts[existingProductIndex].productSizes = mergedProducts[
                existingProductIndex
            ].productSizes.concat(product.productSizes);
        } else {
            mergedProducts.push(product);
        }
    });

    return mergedProducts;
};

export const shoppingProductParser = async (
    database,
    shoppingListProducts,
    params: ShoppingProductParserParams,
) => {
    const productService = new Product({
        database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
        logError: params.logError,
    });
    const productSalesUnitRegionService = new ProductSalesUnit({
        database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
        logError: params.logError,
    });

    const parsedProducts: any[] = [];
    await Promise.all(
        shoppingListProducts.map(async (shoppingListProd) => {
            const product = await productService.getByID(
                shoppingListProd.productId,
            );
            let selectedUnit = shoppingListProd.unitType;
            if (
                !!shoppingListProd.productSalesUnitId &&
                selectedUnit === 'box'
            ) {
                const productSalesUnit =
                    await productSalesUnitRegionService.getByUserRegionAndID(
                        params.userId || '',
                        shoppingListProd.productSalesUnitId,
                    );
                if (!!productSalesUnit) {
                    /*
                TODO: We are always using custom because it has the box unit type and
                the CTN and PKT designations are just for showing purposes.
                When we actually add support to these units this should be changed to:
                box_desc: PRODUCT_UNIT_TYPES_MAP[product.productType]
                */
                    const box_desc = PRODUCT_UNIT_TYPES_MAP['custom'];
                    selectedUnit = `${box_desc} ${productSalesUnit[0].boxUnitSize.toString()}`;
                }
            }
            // TODO: create query to improve performance
            const salesUnits =
                await productSalesUnitRegionService.getByUserRegionAndProductID(
                    params.userId || '',
                    product.id,
                );
            // TODO: check if we can imporve this map
            const boxUnitSizes = {};
            salesUnits.forEach(
                (unit) => (boxUnitSizes[unit.id] = unit.boxUnitSize),
            );
            const materialShapeAndClips: string[] = [];
            if (!!product.material) {
                materialShapeAndClips.push(product.material);
            }
            if (!!product.shape) {
                materialShapeAndClips.push(product.shape);
            }
            if (!!product.clips) {
                materialShapeAndClips.push(product.clips);
            }

            let size = '';

            if (!!product.shoeSize) {
                size = product.shoeSize;
            }

            if (!!product.nailSize) {
                size = product.nailSize;
            }

            if (!!product.productSize) {
                size = product.productSize;
            }

            const parsedObject = {
                chosenProductId: product.id,
                productAttributes: {
                    name:
                        product.productType === ProductType.custom
                            ? product.name || ''
                            : `${product.brand} ${product.family}`,
                    materialShapeAndClips: materialShapeAndClips.join(' '),
                    productType: product.productType,
                    dimensions: product.dimensions,
                    boxUnitSize: product.boxUnitSize,
                    skuApac: product.skuApac,
                    skuEmea: product.skuEmea,
                },
                productSizes: [
                    {
                        productId: product.id,
                        quantity: shoppingListProd.quantity,
                        unitType: shoppingListProd.unitType,
                        size,
                        selectedUnit,
                        boxUnitSizes:
                            salesUnits.length !== 0 ? boxUnitSizes : null,
                    },
                ],
                showAll: false,
            };

            parsedProducts.push(parsedObject);
        }),
    );

    return mergeSameShoppingListProducts(parsedProducts);
};

export async function getShoppingListProducts(args: {
    groupedProductsForShoppingList: ProductModel[][];
    userId: string;
    params: ShoppingProductParserParams;
    database: Database;
    userService: User;
    productService: Product;
    shoppingListProducts?: ShoppingListProductModel[];
    productQuantities?: ProductQuantities;
}) {
    const {
        groupedProductsForShoppingList,
        userId,
        params,
        database,
        userService,
        productService,
        shoppingListProducts,
        productQuantities,
    } = args;

    const mappedIShoppingProducts: ParsedShoppingListProduct[] = [];

    for (const group of groupedProductsForShoppingList) {
        const product = group[0];

        const name = getProductName(product);

        const materialShapeAndClips = getProductMaterialShapeAndClips(product);

        const productSizes = await getShoppingListProductSizes({
            productsForShoppingList: group,
            userId,
            params,
            database,
            userService,
            productService,
            shoppingListProducts,
            productQuantities,
        });

        mappedIShoppingProducts.push({
            chosenProductId: product.id,
            productAttributes: {
                boxUnitSize: product.boxUnitSize,
                dimensions: product.dimensions,
                materialShapeAndClips,
                name,
                productType: product.productType,
                skuApac: product.skuApac,
                skuEmea: product.skuEmea,
            },
            productSizes,
            showAll: false,
        });
    }
    return mappedIShoppingProducts;
}

export async function getShoppingListProductSizes(args: {
    productsForShoppingList: ProductModel[];
    userId: string;
    params: ShoppingProductParserParams;
    database: Database;
    userService: User;
    productService: Product;
    shoppingListProducts?: ShoppingListProductModel[];
    productQuantities?: ProductQuantities;
}): Promise<ShoppingListProductSize[]> {
    const {
        productsForShoppingList,
        userId,
        params,
        database,
        userService,
        productService,
        shoppingListProducts,
        productQuantities,
    } = args;

    const userRegion = await userService.getUserRegion(userId);

    const regions = await database.collections.get<RegionModel>('regions');

    const filteredProducts: ShoppingListProductSize[] = [];

    for (const productForShoppingList of productsForShoppingList) {
        const shoppingListProduct = shoppingListProducts?.find(
            (slProduct) => slProduct.productId === productForShoppingList.id,
        );

        const isProductInUserRegion =
            await productService.isProductFromUserRegion(
                productForShoppingList,
                regions,
                userRegion,
            );

        if (
            shoppingListProduct ||
            isProductInUserRegion ||
            productForShoppingList.productType === ProductType.custom
        ) {
            const productWithQuantityAndUnit =
                productQuantities?.[productForShoppingList.id];
            let selectedUnit: string | undefined,
                selectedBoxUnitSize: number | undefined;

            // case when unit size came from add shopping list product modal
            if (productWithQuantityAndUnit?.unitType) {
                const values = productWithQuantityAndUnit.unitType?.split(' ');
                selectedUnit = values?.[0];
                selectedBoxUnitSize = values
                    ? parseInt(values?.[1], 10)
                    : undefined;
            } else {
                [selectedUnit, selectedBoxUnitSize] =
                    (await calculateProductSelectedUnit(
                        database,
                        params,
                        productForShoppingList,
                        shoppingListProduct,
                    )) ?? [''];
            }

            const boxUnitSizes = await calculateProductBoxUnitSize(
                database,
                params,
                productForShoppingList.id,
            );

            if (!selectedUnit) {
                [selectedUnit, selectedBoxUnitSize] =
                    getDefaultUnitByProductType(
                        productForShoppingList.productType,
                    ) ?? [''];
            }

            let productSalesUnitId: string | undefined;

            if (boxUnitSizes && selectedBoxUnitSize) {
                productSalesUnitId = Object.keys(boxUnitSizes).find(
                    (unitId) => boxUnitSizes[unitId] === selectedBoxUnitSize,
                );
            }

            filteredProducts.push({
                productId: productForShoppingList.id,
                quantity: shoppingListProduct?.quantity ?? 0,
                size: getProductSize(productForShoppingList) ?? '',
                selectedUnit,
                selectedBoxUnitSize,
                productSalesUnitId,
                unitType:
                    shoppingListProduct?.unitType ??
                    getDefaultUnitTypeByProductType(
                        productForShoppingList.productType,
                    ),
                boxUnitSizes: boxUnitSizes ?? null,
                notAvailable: productForShoppingList.notAvailable,
            });
        }
    }

    return filteredProducts;
}

export async function updateShoppingListProductSize(args: {
    changes: ShoppingListProductChange[];
    shoppingListId: string;
    userId: string;
    shoppingListProductService: ShoppingListProduct;
    organisationService: Organisation;
}) {
    const {
        changes,
        shoppingListId,
        userId,
        shoppingListProductService,
        organisationService,
    } = args;

    const organisation = await organisationService.get();
    const { id: organisationID } = organisation[0];

    const updatedShoppingListProducts: ShoppingListProductModel[] = [];

    for (const {
        quantity,
        productSize,
        productSalesUnitId = productSize.productSalesUnitId ?? '',
        selectedUnit = productSize.selectedUnit,
    } of changes) {
        const [
            unitType,
            multiplier = SHOPPING_PRODUCT_UNIT_VALUE[selectedUnit],
        ] = selectedUnit.split(' ');

        const totalQuantity = quantity * parseInt(multiplier, 10);
        const payload: ShoppingListProductPayload = {
            productId: productSize.productId,
            productSalesUnitId,
            quantity,
            totalQuantity,
            unitType,
            shoppingListId,
        };

        if (quantity === 0) {
            // REMOVE
            await shoppingListProductService.deleteProductFromShoppingList(
                shoppingListId,
                productSize.productId,
                userId,
                organisationID,
            );
        } else if (productSize.quantity === 0) {
            // ADD
            const shoppingListProduct = await shoppingListProductService.add(
                payload,
                userId,
                organisationID,
            );

            updatedShoppingListProducts.push(shoppingListProduct);
        } else {
            const updatedShoppingListProduct =
                await shoppingListProductService.update(
                    shoppingListId,
                    payload,
                    userId,
                    organisationID,
                );

            if (updatedShoppingListProduct) {
                updatedShoppingListProducts.push(updatedShoppingListProduct);
            }
        }
    }

    return updatedShoppingListProducts;
}

export const convertToAliasOnCSVExport = (
    boxUnitSize: string,
    productType: ProductType,
) => {
    if (
        (productType === ProductType.nails ||
            productType === ProductType.shoes) &&
        boxUnitSize.toLowerCase().includes('box')
    ) {
        const correctPrefix = mapDescriptionToCustomTresholds(
            productType,
            boxUnitSize,
        );
        const splittedProductUnit = boxUnitSize.split(' ');
        return mapBoxUnitTypes(
            productType,
            [correctPrefix, splittedProductUnit[1]].join(' '),
        );
    } else if (productType === ProductType.nails && boxUnitSize === 'pack') {
        return SHOPPING_PRODUCT_UNIT.pack;
    } else if (productType === ProductType.rasps) {
        return SHOPPING_PRODUCT_UNIT.rasp;
    } else if ([ProductType.tools, ProductType.other].includes(productType)) {
        return SHOPPING_PRODUCT_UNIT.piece;
    } else {
        return boxUnitSize;
    }
};

export const buildProductCSVInformation = async (
    products: ParsedShoppingListProduct[],
    database,
    params: ShoppingProductParserParams,
): Promise<string[][]> => {
    const productSalesUnitService = new ProductSalesUnit({
        database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
        logError: params.logError,
    });

    const userId = params.userId ?? '';

    const sortedProducts = sortBy(products, 'productAttributes.name');

    const csvData: string[][] = [];

    await Promise.all(
        sortedProducts
            .filter(
                (product: ParsedShoppingListProduct) =>
                    product.productSizes.length && product.productAttributes,
            )
            .map(async (product: ParsedShoppingListProduct) => {
                const { productAttributes, productSizes } = product;

                for (const productSize of productSizes) {
                    let productSalesUnitId: any = null;

                    if (productAttributes.productType !== ProductType.custom) {
                        if (
                            Object.keys(SHOPPING_PRODUCT_UNIT).includes(
                                productSize.selectedUnit,
                            )
                        ) {
                            if (
                                !!productSize.boxUnitSizes &&
                                Object.keys(productSize.boxUnitSizes).length !==
                                    0
                            ) {
                                productSalesUnitId = Object.keys(
                                    productSize.boxUnitSizes,
                                )[0];
                                for (const [id, boxUnitSize] of Object.entries(
                                    productSize.boxUnitSizes,
                                )) {
                                    if (
                                        productSize.boxUnitSizes[
                                            productSalesUnitId
                                        ] > boxUnitSize
                                    ) {
                                        productSalesUnitId = id;
                                    }
                                }
                            }
                        } else {
                            const boxUnitSizeAmount = parseInt(
                                productSize.selectedUnit.split(' ')[1],
                                10,
                            );

                            if (productSize.boxUnitSizes) {
                                productSalesUnitId = Object.keys(
                                    productSize.boxUnitSizes,
                                ).find((salesUnitId) =>
                                    productSize.boxUnitSizes
                                        ? productSize.boxUnitSizes[
                                              salesUnitId
                                          ] === boxUnitSizeAmount
                                        : false,
                                );
                            }
                        }
                    }

                    let productSalesUnit: ProductSalesUnitModel | null = null;

                    if (productSalesUnitId) {
                        productSalesUnit = (
                            await productSalesUnitService.getByUserRegionAndID(
                                userId,
                                productSalesUnitId,
                            )
                        )[0];
                    }

                    if (!productSalesUnit) {
                        const productSalesUnitsByProductId =
                            await productSalesUnitService.getByProductID(
                                productSize.productId,
                            );
                        productSalesUnit = productSalesUnitsByProductId[0];

                        if (productSalesUnit) {
                            productSalesUnit = {
                                ...clone(productSalesUnit),
                                boxUnitSize:
                                    product.productAttributes.boxUnitSize,
                            } as ProductSalesUnitModel;
                        }
                    }

                    // start adding the row values to the array

                    let name: string;
                    if (productAttributes.productType === ProductType.custom) {
                        name = productAttributes.name;
                    } else {
                        name = `${productAttributes.name} ${
                            productAttributes.materialShapeAndClips || ''
                        } ${productSize.size || ''}`;
                    }

                    let skuApac: string;
                    let skuEmea: string;
                    if (!!productSalesUnit) {
                        skuApac = productSalesUnit.skuApac || '';
                        skuEmea = productSalesUnit.skuEmea || '';
                    } else {
                        skuApac = productAttributes.skuApac || '';
                        skuEmea = productAttributes.skuEmea || '';
                    }

                    const quantity = productSize.quantity || '';

                    const selectedUnit = productSize.selectedUnit
                        ? capitalizeFirstLetter(
                              convertToAliasOnCSVExport(
                                  productSize.selectedUnit,
                                  productAttributes.productType as ProductType,
                              ),
                          )
                        : '';

                    const row = [
                        name,
                        skuApac,
                        skuEmea,
                        quantity.toString(),
                        selectedUnit,
                    ];
                    csvData.push(row);
                }
            }),
    );
    return csvData;
};

export const convertProductsToCSVString = async (
    database,
    products: ParsedShoppingListProduct[],
    params: ShoppingProductParserParams,
): Promise<string> => {
    const csvData = await buildProductCSVInformation(
        products,
        database,
        params,
    );

    const HEADER = 'Name,Local stock code,Global stock code,QTY,Unit\r\n';

    const csv = csvData.map((rowArray) => rowArray.join(',')).join('\r\n');
    return `${HEADER}${csv}`;
};

export const generateProductCSV = async (
    name: string,
    products: ParsedShoppingListProduct[],
    database,
    params: ShoppingProductParserParams,
) => {
    if (!name || !products.length) {
        throw new Error();
    }

    const csvString = await convertProductsToCSVString(
        database,
        products,
        params,
    );

    const myBlob = new Blob([encodeURIComponent(csvString)], {
        type: 'text/csv;charset=utf-8;',
    });
    const url = window.URL.createObjectURL(myBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${replaceAll(name, ' ', '_')}.csv`;
    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
};
