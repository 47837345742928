import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Button,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function InitialContent({
    onCancelButtonClick,
    onEditPriceButtonClick,
    onEditQuantityButtonClick,
    procedureName,
    allowToEditPrice = true,
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle>
                {t('Generic:edit_something', { name: procedureName || '' })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText data-test-id="AlertText">
                    {t(
                        allowToEditPrice
                            ? 'Procedure:edit_description'
                            : 'Procedure:edit_description_without_price',
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Box>
                    <Button
                        onClick={onCancelButtonClick}
                        data-test-id="EditProcedureDataModal-CancelButton"
                    >
                        {t('Actions:cancel')}
                    </Button>
                </Box>
                <Box>
                    <Button
                        data-test-id="EditProcedureDataModal-EditQuantityButton"
                        onClick={onEditQuantityButtonClick}
                    >
                        {t('Procedure:update_quantity:button')}
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        data-test-id="EditProcedureDataModal-EditPriceButton"
                        onClick={onEditPriceButtonClick}
                    >
                        {t('Procedure:update_price:button')}
                    </Button>
                </Box>
            </DialogActions>
        </>
    );
}

export default InitialContent;
