import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InvoiceModel } from 'shared/types/Invoice';

import { TruncatedListSection } from '@/components';
import InvoicesList from '@/components/InvoicesList/InvoicesList';
import { Props as InvoicesListProps } from '@/components/InvoicesList/types';
import { ROUTE } from '@/router/routes';

import { Props } from './types';

function InvoicesSection({
    invoices,
    isLoading,
    showAddButton,
    onAddButtonClick,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToInvoice = useCallback(
        (invoice: InvoiceModel) => navigate(`${ROUTE.invoices}/${invoice.id}`),
        [navigate],
    );

    return (
        <TruncatedListSection<Partial<InvoicesListProps>>
            ListComponent={InvoicesList}
            title={t('Contact:section:invoices_with_length', {
                length: invoices.length || 0,
            })}
            buttonText={t('Contact:more_invoices')}
            emptyMessage={t('Contact:invoices_blankslate')}
            listComponentProps={{
                clickable: true,
                items: invoices,
                testIdPrefix: 'ContactInvoicesSection',
                onItemClick: navigateToInvoice,
                itemWrapperStyle: 'pb-0.5',
                size: 'large',
                showInvoices: true,
            }}
            isLoading={isLoading}
            showAddButton={showAddButton}
            entityName={t('Entities:invoice').toLowerCase()}
            onAddButtonClick={onAddButtonClick}
        />
    );
}
export default InvoicesSection;
