import { Alert, AlertTitle, Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import bgImage from '@/assets/images/bg-image.webp';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { AuthPageLayout } from '@/layouts';
import { ROUTE } from '@/router/routes';

function AppMaintenancePage() {
    const {
        maintenanceConfig: { from, isInMaintenance, to },
        fetchConfig,
    } = useFeatureFlagsContext();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const equinetLogo = useMemo(
        () => (
            <Box
                alt="Equinet Logo"
                component="img"
                src={equinetLogoImage}
                sx={{
                    height: 88,
                    width: 334,
                }}
            />
        ),
        [],
    );

    useEffect(() => {
        const interval = setInterval(fetchConfig, 30000);

        return () => clearInterval(interval);
    }, [fetchConfig, navigate]);

    useEffect(() => {
        if (from && to && !isInMaintenance) {
            navigate(ROUTE.dashboard, { replace: true });
        }
    }, [from, isInMaintenance, navigate, to]);

    return (
        <AuthPageLayout
            backgroundImage={bgImage}
            rightColumnContent={
                <Box
                    sx={{
                        mt: '114px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            mx: { xs: 4, md: 10 },
                        }}
                    >
                        {equinetLogo}
                        <Alert severity="info" sx={{ mt: 5 }}>
                            <AlertTitle>
                                {t('App:Maintenance:Page:title')}
                            </AlertTitle>
                            {t('App:Maintenance:Page:message', { from, to })}
                        </Alert>
                    </Box>
                </Box>
            }
        />
    );
}

export default AppMaintenancePage;
