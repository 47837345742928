import { Q } from '@nozbe/watermelondb';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ContactsService from 'shared/db/services/Contact';
import { ContactModel } from 'shared/types/Contacts';
import { FILTER_TYPE } from 'shared/types/filter';

import { ContactsList, SelectItemsModal } from '@/components';
import { Props as ContactsListProps } from '@/components/ContactsList/types';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { Props } from './types';

function SelectContactsModal({
    addSelectedItemsButtonText,
    close,
    isOpen,
    onSelectContacts,
    selectedContacts,
    multiSelect,
    selectCondition,
    conditionWarning,
    contactsToSelect,
    cancel,
    displayContactInformation,
    renderExtraContentComponent,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const fetchContacts = useCallback(
        async (query = '') => {
            const contactsService = new ContactsService({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            if (contactsToSelect) {
                const [queries, sortQueries] =
                    contactsService.getFilterContactsQueries();

                const searchQuery =
                    contactsService.getFilterContactsSearchLokiQuery({
                        searchText: query,
                        includeBusinessName: true,
                    });

                return contactsToSelect
                    .extend(searchQuery)
                    .extend(...queries)
                    .extend(...sortQueries)
                    .fetch();
            }

            if (query) {
                return await contactsService.getFilteredContacts({
                    searchText: query,
                    filterType: FILTER_TYPE.LOKI,
                    includeBusinessName: true,
                });
            }

            return contactsService.getAll(
                Q.sortBy('first_name', 'asc'),
                Q.sortBy('last_name', 'asc'),
            );
        },
        [ImagesService, contactsToSelect, database],
    );

    return (
        <SelectItemsModal<ContactModel, ContactsListProps>
            fetchItemsFn={fetchContacts}
            selectedItems={selectedContacts}
            isOpen={isOpen}
            onSelectItems={onSelectContacts}
            close={close}
            emptyMessage={t('ContactsSelection:blankslate')}
            noItemsFoundMessage={t('ContactsList:no_contact_found_blankslate')}
            selectedItemsCountTextKey="Contacts:selected_contacts_number"
            addSelectedItemsButtonText={
                addSelectedItemsButtonText ||
                t(
                    multiSelect
                        ? 'Contacts:add_selected_contacts'
                        : 'Contacts:add_selected_contact',
                )
            }
            testIdPrefix="SelectContactsModal"
            title={t(
                `HorseProfile:${multiSelect ? 'select' : 'single_select'}`,
            )}
            ListComponent={ContactsList}
            listComponentProps={{
                testIdPrefix: 'SelectContactsModal',
                selectable: true,
                itemWrapperStyle: 'mb-4 min-h-[74px]',
                size: 'large',
                multiSelect,
                selectCondition,
                conditionWarning,
                displayContactInformation,
                renderExtraContentComponent,
            }}
            multiSelect={multiSelect}
            onCancelButtonClick={cancel}
        />
    );
}

export default SelectContactsModal;
