import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencySymbol } from 'shared/constants/currency';
import InvoiceInvoicingProduct from 'shared/db/models/InvoiceInvoicingProduct';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { ContactModel } from 'shared/types/Contacts';
import { EntryModel } from 'shared/types/Entries';
import { InvoiceFinancialDetails } from 'shared/types/Invoice';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';
import { calculateInvoiceDetailsFromItems } from 'shared/utils/invoicing';
import { getProceduresFromEntries } from 'shared/utils/procedures';

import { ContactsSection, EntriesSection } from '@/components';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useDatabase } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import Logger from '@/services/logger';

import { HeaderSection, InvoiceHistory } from './components';
import ProductsSection from './components/ProductsSection';
import { Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

const INITIAL_FINANCIAL_DETAILS = {
    subtotalSum: 0,
    totalSum: 0,
    proceduresSum: 0,
    proceduresTax: 0,
    productsSum: 0,
    productsTax: 0,
};

function InvoiceDetails({ invoice, isLoading, refetchInvoice }: Props) {
    const [contacts, setContacts] = useState<ContactModel[]>([]);
    const [entries, setEntries] = useState<EntryModel[]>([]);
    const [products, setProducts] = useState<InvoiceInvoicingProduct[]>([]);
    const [organisationAccountingsData, setOrganisationAccountingsData] =
        useState<OrganisationAccountingsModel[]>([]);
    const [invoiceFinancialDetails, setInvoiceFinancialDetails] =
        useState<InvoiceFinancialDetails>(INITIAL_FINANCIAL_DETAILS);

    const { invoiceSystemEnabled } = useFeatureFlagsContext();

    const userRegion = useRegion();
    const currency = userRegion
        ? userRegion.currencySymbol
        : CurrencySymbol.usd;

    const {
        subtotalSum,
        totalSum,
        proceduresSum,
        proceduresTax,
        productsSum,
        productsTax,
    } = invoiceFinancialDetails;

    const db = useDatabase();

    const database = useMemo(() => db.getDatabase(), [db]);

    const accountingsService = useMemo(
        () =>
            new OrganisationAccountings({
                database,
                logDBAction: Logger.logRecordActivity,
            }),
        [database],
    );

    const fetchData = useCallback(async () => {
        if (!invoice) {
            return;
        }

        const contacts = await invoice.contacts.fetch();
        const entries = await invoice.entries.fetch();
        const products = await invoice.products.fetch();

        const procedures = await getProceduresFromEntries(entries);

        const financialDetails = await calculateInvoiceDetailsFromItems({
            procedures,
            products,
            productsTaxRate: Number(invoice.productsTaxRate) || 0,
            taxRate: Number(invoice.taxRate) || 0,
        });

        setInvoiceFinancialDetails(financialDetails);

        const organisationAccountingsData = await accountingsService
            .get()
            .fetch();
        setContacts(contacts);
        setEntries(entries);
        setProducts(products);
        setOrganisationAccountingsData(organisationAccountingsData);
    }, [accountingsService, invoice]);

    useEffect(() => {
        fetchData();
    }, [fetchData, invoice]);

    return (
        <Box>
            <HeaderSection
                invoice={invoice}
                refetchInvoice={refetchInvoice}
                isLoading={isLoading}
                accountings={organisationAccountingsData[0]}
                contact={contacts ? contacts[0] : null}
                invoicePrice={
                    invoiceSystemEnabled && invoice?.applyTaxes
                        ? totalSum
                        : subtotalSum
                }
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <Box sx={sectionStyles}>
                        <EntriesSection
                            entries={entries || []}
                            isLoading={isLoading}
                            financialDetails={
                                invoiceSystemEnabled
                                    ? {
                                          subtotal: proceduresSum.toFixed(2),
                                          tax: proceduresTax.toFixed(2),
                                          taxRate: invoice?.taxRate || '0',
                                          taxIncluded: invoice?.applyTaxes,
                                          currency,
                                          subtotalText: t(
                                              'InvoiceSystem:invoice_summary:subtotal_procedures',
                                          ),
                                      }
                                    : undefined
                            }
                        />
                    </Box>
                    {products.length > 0 ? (
                        <Box sx={sectionStyles}>
                            <ProductsSection
                                currency={currency}
                                products={products}
                                isLoading={isLoading}
                                subtotal={productsSum.toFixed(2)}
                                tax={productsTax.toFixed(2)}
                                taxRate={invoice?.productsTaxRate || '0'}
                                taxIncluded={invoice?.applyTaxes}
                            />
                        </Box>
                    ) : null}
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box sx={sectionStyles}>
                        <ContactsSection
                            testIdPrefix={'InvoiceContactsSection'}
                            contacts={contacts || []}
                            isLoading={isLoading}
                        />
                    </Box>
                    <Box sx={sectionStyles}>
                        <InvoiceHistory invoice={invoice} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default InvoiceDetails;
