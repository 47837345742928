import { Box, Button, Fade, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import Modal from '../Modal';

import { Props } from './types';

function NoAppointmentContactDetailsModal({ onCancel, onEdit, type }: Props) {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            closeAfterTransition
            center
            styles="py-5 px-5 rounded-lg shadow-md max-w-xl"
        >
            <Fade
                in
                timeout={{
                    enter: 500,
                    exit: 300,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: COLOR.black,
                        }}
                    >
                        {t(
                            type === 'email'
                                ? 'Event:invite_contact:no_details_modal:email_title'
                                : 'Event:invite_contact:no_details_modal:sms_title',
                        )}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: COLOR.black,
                        }}
                    >
                        {t(
                            type === 'email'
                                ? 'Event:invite_contact:no_details_modal:email_content'
                                : 'Event:invite_contact:no_details_modal:sms_content',
                        )}
                    </Typography>
                    <Box sx={{ alignSelf: 'flex-end', mt: 2 }}>
                        <Button
                            onClick={onCancel}
                            className="px-8 py-2.5"
                            variant="text"
                            sx={{
                                color: ENTITY_COLOR.schedule,
                                '&:hover': {
                                    backgroundColor: COLOR.white,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: ENTITY_COLOR.schedule,
                                }}
                            >
                                {t(
                                    'Event:invite_contact:no_details_modal:not_now',
                                )}
                            </Typography>
                        </Button>
                        <Button
                            onClick={onEdit}
                            className="px-5 py-2.5"
                            variant="contained"
                            sx={{
                                backgroundColor: ENTITY_COLOR.schedule,
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.schedule,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: COLOR.white,
                                }}
                            >
                                {t(
                                    'Event:invite_contact:no_details_modal:edit_contact',
                                )}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default NoAppointmentContactDetailsModal;
