import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import type { Props } from './types';

export function ProductsListItem({ product, currency }: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: COLOR.athensGrayDarker,
                paddingX: '12px',
                paddingY: '16px',
                borderRadius: 0.5,
                flex: 1,
            }}
        >
            <Typography fontSize={14} width={'78px'}>
                {product.quantity}x
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    fontWeight="bold"
                    fontSize={16}
                    maxWidth={'55%'}
                    sx={{ wordBreak: 'break-word' }}
                >
                    {product.name}
                </Typography>
                <Typography
                    fontSize={14}
                    maxWidth={'35%'}
                    sx={{ wordBreak: 'break-word' }}
                >{`${currency}${product.price}`}</Typography>
            </Box>
        </Box>
    );
}
