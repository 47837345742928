import { Box, Collapse, SxProps } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ProductService from 'shared/db/services/Product';
import { ProductType } from 'shared/types/Products';

import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { MenuCategory, MenuItem } from './components';
import { Props } from './types';

const collapseStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 1.5,
    pl: 1,
    maxHeight: 300,
    overflowY: 'auto',
    minWidth: '100%',
};

function FilterMenu({
    brand,
    filter,
    hideProductTypes,
    onFilterChange,
    productType,
    showOftenUsedFilter = false,
    showInventoryFilter = false,
}: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();

    const [brands, setBrands] = useState({
        [ProductType.shoes]: [] as string[],
        [ProductType.nails]: [] as string[],
        [ProductType.rasps]: [] as string[],
        [ProductType.tools]: [] as string[],
        [ProductType.other]: [] as string[],
    });
    const [shoesBrandsVisible, setShoesBrandsVisible] = useState(false);
    const [nailsBrandsVisible, setNailsBrandsVisible] = useState(false);
    const [raspsBrandsVisible, setRaspsBrandsVisible] = useState(false);
    const [toolsBrandsVisible, setToolsBrandsVisible] = useState(false);
    const [otherBrandsVisible, setOtherBrandsVisible] = useState(false);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const hideToolsProducts = useMemo(() => {
        return hideProductTypes?.includes(ProductType.tools);
    }, [hideProductTypes]);

    const hideRaspsProducts = useMemo(() => {
        return hideProductTypes?.includes(ProductType.rasps);
    }, [hideProductTypes]);

    const hideOtherProducts = useMemo(() => {
        return hideProductTypes?.includes(ProductType.other);
    }, [hideProductTypes]);

    const setCategory = useCallback(
        (type: ProductType) => () => {
            if (productType === type) {
                onFilterChange({
                    brand,
                    productType: null,
                    productFilter: filter,
                });
            } else {
                onFilterChange({
                    brand: null,
                    productType: type,
                    productFilter: 'none',
                });
            }
        },
        [productType, onFilterChange, filter, brand],
    );

    const _onAllProducts = useCallback(
        (selectedProductType: ProductType | null) => () => {
            onFilterChange({
                brand: null,
                productType: selectedProductType,
                productFilter: 'none',
            });
        },
        [onFilterChange],
    );

    const _onOftenUsed = useCallback(
        (productType: ProductType | null) => () =>
            onFilterChange({
                brand: null,
                productType,
                productFilter: 'often-used',
            }),
        [onFilterChange],
    );

    const _onFrom = useCallback(
        (productType: ProductType | null) => () =>
            onFilterChange({
                brand: null,
                productType,
                productFilter: 'from',
            }),
        [onFilterChange],
    );

    const onBrandsClick = useCallback(
        (type: ProductType) => () => {
            switch (type) {
                case ProductType.shoes:
                    setShoesBrandsVisible((prev) => !prev);
                    break;

                case ProductType.nails:
                    setNailsBrandsVisible((prev) => !prev);
                    break;

                case ProductType.rasps:
                    setRaspsBrandsVisible((prev) => !prev);
                    break;

                case ProductType.tools:
                    setToolsBrandsVisible((prev) => !prev);
                    break;

                case ProductType.other:
                    setOtherBrandsVisible((prev) => !prev);
                    break;
            }
        },
        [],
    );

    const fetchBrands = useCallback(async () => {
        const productService = new ProductService({
            database,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
            logError: Logger.logError,
        });

        let raspsBrands: string[] = [];
        let toolsBrands: string[] = [];
        let otherBrands: string[] = [];

        const shoesBrands = await productService.getAllBrands(
            ProductType.shoes,
        );
        const nailsBrands = await productService.getAllBrands(
            ProductType.nails,
        );

        if (!hideOtherProducts) {
            otherBrands = await productService.getAllBrands(ProductType.other);
        }

        if (!hideToolsProducts) {
            toolsBrands = await productService.getAllBrands(ProductType.tools);
        }

        if (!hideRaspsProducts) {
            raspsBrands = await productService.getAllBrands(ProductType.rasps);
        }

        setBrands({
            shoes: shoesBrands || [],
            nails: nailsBrands || [],
            rasps: raspsBrands || [],
            tools: toolsBrands || [],
            other: otherBrands || [],
        });
    }, [
        database,
        hideOtherProducts,
        hideToolsProducts,
        hideRaspsProducts,
        ImagesService,
    ]);

    useEffect(() => {
        fetchBrands();
    }, [fetchBrands]);

    const onBrandSelect = useCallback(
        (brand: string) => () => {
            onFilterChange({
                brand,
                productType,
                productFilter: 'brands',
            });
        },
        [productType, onFilterChange],
    );

    const shoesBrands = useMemo(
        () =>
            brands.shoes.map((shoeBrand) => (
                <MenuItem
                    insetLeft
                    key={shoeBrand}
                    caption={shoeBrand}
                    onClick={onBrandSelect(shoeBrand)}
                    isActive={brand === shoeBrand}
                />
            )),
        [brand, brands.shoes, onBrandSelect],
    );

    const nailsBrands = useMemo(
        () =>
            brands.nails.map((nailBrand) => (
                <MenuItem
                    insetLeft
                    key={nailBrand}
                    caption={nailBrand}
                    onClick={onBrandSelect(nailBrand)}
                    isActive={brand === nailBrand}
                />
            )),
        [brand, brands.nails, onBrandSelect],
    );

    const raspsBrands = useMemo(() => {
        if (hideRaspsProducts) {
            return [];
        }

        return brands.rasps.map((raspBrand) => (
            <MenuItem
                insetLeft
                key={raspBrand}
                caption={raspBrand}
                onClick={onBrandSelect(raspBrand)}
                isActive={brand === raspBrand}
            />
        ));
    }, [brand, brands.rasps, hideRaspsProducts, onBrandSelect]);

    const toolsBrands = useMemo(() => {
        if (hideToolsProducts) {
            return [];
        }

        return brands.tools.map((toolBrand) => (
            <MenuItem
                insetLeft
                key={toolBrand}
                caption={toolBrand}
                onClick={onBrandSelect(toolBrand)}
                isActive={brand === toolBrand}
            />
        ));
    }, [brand, brands.tools, hideToolsProducts, onBrandSelect]);

    const otherBrands = useMemo(() => {
        if (hideOtherProducts) {
            return [];
        }

        return brands.other.map((otherBrand) => (
            <MenuItem
                insetLeft
                key={otherBrand}
                caption={otherBrand}
                onClick={onBrandSelect(otherBrand)}
                isActive={brand === otherBrand}
            />
        ));
    }, [brand, brands.other, hideOtherProducts, onBrandSelect]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
            }}
        >
            <Box sx={collapseStyles}>
                {showOftenUsedFilter ? (
                    <MenuItem
                        caption={t('ProductSelection:frequently_used')}
                        onClick={_onOftenUsed(null)}
                        isActive={
                            filter === 'often-used' && productType === null
                        }
                    />
                ) : null}
                {showInventoryFilter ? (
                    <MenuItem
                        caption={t('ProductSelection:from_inventory')}
                        onClick={_onFrom(null)}
                        isActive={filter === 'from' && productType === null}
                    />
                ) : null}
                <MenuItem
                    testID={'FilterMenu-AllProducts'}
                    caption={t('Inventory:filter:all_products', {
                        defaultValue: 'All products',
                    })}
                    onClick={_onAllProducts(null)}
                    isActive={filter === 'none' && productType === null}
                />
            </Box>
            <MenuCategory
                caption={t('Inventory:section:shoes')}
                onClick={setCategory(ProductType.shoes)}
                isActive={productType === ProductType.shoes}
                testID="AddProductsModal-FilterShoes"
            />
            <Collapse
                sx={{ minWidth: '12rem' }}
                in={productType === ProductType.shoes}
            >
                <Box sx={collapseStyles}>
                    {showOftenUsedFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:frequently_used')}
                            onClick={_onOftenUsed(ProductType.shoes)}
                            isActive={filter === 'often-used'}
                        />
                    ) : null}
                    {showInventoryFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:from_inventory')}
                            onClick={_onFrom(ProductType.shoes)}
                            isActive={filter === 'from'}
                        />
                    ) : null}
                    <MenuItem
                        testID={'FilterMenu-AllShoes'}
                        caption={t('Inventory:filter:all_shoes', {
                            defaultValue: 'All shoes',
                        })}
                        onClick={_onAllProducts(ProductType.shoes)}
                        isActive={filter === 'none'}
                    />
                    <MenuItem
                        caption={t('Inventory:filter:brands', {
                            defaultValue: 'Brands',
                        })}
                        onClick={onBrandsClick(ProductType.shoes)}
                        arrow={shoesBrandsVisible ? 'up' : 'down'}
                    />
                    {shoesBrandsVisible && shoesBrands.length > 0
                        ? shoesBrands
                        : null}
                </Box>
            </Collapse>
            <MenuCategory
                caption={t('Inventory:section:nails')}
                onClick={setCategory(ProductType.nails)}
                isActive={productType === ProductType.nails}
                testID="AddProductsModal-FilterNails"
            />
            <Collapse
                sx={{ minWidth: '12rem' }}
                in={productType === ProductType.nails}
            >
                <Box sx={collapseStyles}>
                    {showOftenUsedFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:frequently_used')}
                            onClick={_onOftenUsed(ProductType.nails)}
                            isActive={filter === 'often-used'}
                        />
                    ) : null}

                    {showInventoryFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:from_inventory')}
                            onClick={_onFrom(ProductType.nails)}
                            isActive={filter === 'from'}
                        />
                    ) : null}
                    <MenuItem
                        testID={'FilterMenu-AllNails'}
                        caption={t('Inventory:filter:all_nails', {
                            defaultValue: 'All nails',
                        })}
                        onClick={_onAllProducts(ProductType.nails)}
                        isActive={filter === 'none'}
                    />
                    <MenuItem
                        caption={t('Inventory:filter:brands', {
                            defaultValue: 'Brands',
                        })}
                        onClick={onBrandsClick(ProductType.nails)}
                        arrow={nailsBrandsVisible ? 'up' : 'down'}
                    />
                    {nailsBrandsVisible && nailsBrands.length > 0
                        ? nailsBrands
                        : null}
                </Box>
            </Collapse>
            {!hideRaspsProducts ? (
                <>
                    <MenuCategory
                        caption={t('Inventory:section:rasps')}
                        onClick={setCategory(ProductType.rasps)}
                        isActive={productType === ProductType.rasps}
                        testID="AddProductsModal-FilterRasps"
                    />
                    <Collapse
                        sx={{ minWidth: '12rem' }}
                        in={productType === ProductType.rasps}
                    >
                        <Box sx={collapseStyles}>
                            {showOftenUsedFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:frequently_used',
                                    )}
                                    onClick={_onOftenUsed(ProductType.rasps)}
                                    isActive={filter === 'often-used'}
                                />
                            ) : null}
                            {showInventoryFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:from_inventory',
                                    )}
                                    onClick={_onFrom(ProductType.rasps)}
                                    isActive={filter === 'from'}
                                />
                            ) : null}
                            <MenuItem
                                testID={'FilterMenu-AllRasps'}
                                caption={t('Inventory:filter:all_rasps', {
                                    defaultValue: 'All rasps',
                                })}
                                onClick={_onAllProducts(ProductType.rasps)}
                                isActive={filter === 'none'}
                            />
                            <MenuItem
                                caption={t('Inventory:filter:brands', {
                                    defaultValue: 'Brands',
                                })}
                                onClick={onBrandsClick(ProductType.rasps)}
                                arrow={raspsBrandsVisible ? 'up' : 'down'}
                            />
                            {raspsBrandsVisible && raspsBrands.length > 0
                                ? raspsBrands
                                : null}
                        </Box>
                    </Collapse>
                </>
            ) : null}
            {!hideToolsProducts ? (
                <>
                    <MenuCategory
                        caption={t('Inventory:section:tools')}
                        onClick={setCategory(ProductType.tools)}
                        isActive={productType === ProductType.tools}
                        testID="AddProductsModal-FilterTools"
                    />
                    <Collapse
                        sx={{ minWidth: '12rem' }}
                        in={productType === ProductType.tools}
                    >
                        <Box sx={collapseStyles}>
                            {showOftenUsedFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:frequently_used',
                                    )}
                                    onClick={_onOftenUsed(ProductType.tools)}
                                    isActive={filter === 'often-used'}
                                />
                            ) : null}
                            {showInventoryFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:from_inventory',
                                    )}
                                    onClick={_onFrom(ProductType.tools)}
                                    isActive={filter === 'from'}
                                />
                            ) : null}
                            <MenuItem
                                testID={'FilterMenu-AllTools'}
                                caption={t('Inventory:filter:all_tools', {
                                    defaultValue: 'All tools',
                                })}
                                onClick={_onAllProducts(ProductType.tools)}
                                isActive={filter === 'none'}
                            />
                            <MenuItem
                                caption={t('Inventory:filter:brands', {
                                    defaultValue: 'Brands',
                                })}
                                onClick={onBrandsClick(ProductType.tools)}
                                arrow={toolsBrandsVisible ? 'up' : 'down'}
                            />
                            {toolsBrandsVisible && toolsBrands.length > 0
                                ? toolsBrands
                                : null}
                        </Box>
                    </Collapse>
                </>
            ) : null}
            {!hideOtherProducts ? (
                <>
                    <MenuCategory
                        caption={t('Inventory:section:other')}
                        onClick={setCategory(ProductType.other)}
                        isActive={productType === ProductType.other}
                        testID="AddProductsModal-FilterOther"
                    />
                    <Collapse
                        sx={{ minWidth: '12rem' }}
                        in={productType === ProductType.other}
                    >
                        <Box sx={collapseStyles}>
                            {showOftenUsedFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:frequently_used',
                                    )}
                                    onClick={_onOftenUsed(ProductType.other)}
                                    isActive={filter === 'often-used'}
                                />
                            ) : null}
                            {showInventoryFilter ? (
                                <MenuItem
                                    caption={t(
                                        'ProductSelection:from_inventory',
                                    )}
                                    onClick={_onFrom(ProductType.other)}
                                    isActive={filter === 'from'}
                                />
                            ) : null}
                            <MenuItem
                                testID={'FilterMenu-AllOther'}
                                caption={t('Inventory:filter:all_other', {
                                    defaultValue: 'All other',
                                })}
                                onClick={_onAllProducts(ProductType.other)}
                                isActive={filter === 'none'}
                            />
                            <MenuItem
                                caption={t('Inventory:filter:brands', {
                                    defaultValue: 'Brands',
                                })}
                                onClick={onBrandsClick(ProductType.other)}
                                arrow={otherBrandsVisible ? 'up' : 'down'}
                            />
                            {otherBrandsVisible && otherBrands.length > 0
                                ? otherBrands
                                : null}
                        </Box>
                    </Collapse>
                </>
            ) : null}
            <MenuCategory
                caption={t('Inventory:section:custom')}
                onClick={setCategory(ProductType.custom)}
                isActive={productType === ProductType.custom}
                testID="AddProductsModal-FilterCustom"
            />
            <Collapse
                sx={{ minWidth: '12rem' }}
                in={productType === ProductType.custom}
            >
                <Box sx={collapseStyles}>
                    {showOftenUsedFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:frequently_used')}
                            onClick={_onOftenUsed(ProductType.custom)}
                            isActive={filter === 'often-used'}
                        />
                    ) : null}

                    {showInventoryFilter ? (
                        <MenuItem
                            caption={t('ProductSelection:from_inventory')}
                            onClick={_onFrom(ProductType.custom)}
                            isActive={filter === 'from'}
                        />
                    ) : null}
                    <MenuItem
                        testID={'FilterMenu-AllCustoms'}
                        caption={t('Inventory:filter:all_customs', {
                            defaultValue: 'All customs',
                        })}
                        onClick={_onAllProducts(ProductType.custom)}
                        isActive={filter === 'none'}
                    />
                </Box>
            </Collapse>
        </Box>
    );
}

export default FilterMenu;
