import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import {
    Box,
    Button,
    TextField,
    Typography,
    Fade,
    Slide,
    Skeleton,
} from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { PersonPlusIcon } from '@/assets/svg';
import { useOrganisationInvitation } from '@/hooks';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import Modal from '../Modal';

import { Props } from './types';

const TEXT_FIELD_HEIGHT = 48;

function TeamInvitationModal({ handleClose }: Props) {
    const { invitationUrl } = useOrganisationInvitation();

    const [linkCopied, setLinkCopied] = useState(false);
    const containerRef = useRef(null);

    const copyLink = useCallback(() => {
        navigator.clipboard.writeText(invitationUrl || '');
        setLinkCopied(true);
    }, [invitationUrl]);

    const closeModal = useCallback(() => {
        setLinkCopied(false);
        handleClose();
    }, [handleClose]);

    const companyNameFieldTrailingContent = useMemo(() => {
        return (
            <Button
                onClick={copyLink}
                variant="outlined"
                sx={{
                    width: 120,
                    height: 34,
                    color: COLOR.black,
                    borderColor: COLOR.black,
                    '&:hover': {
                        backgroundColor: COLOR.white,
                        borderColor: COLOR.black,
                    },
                    '& .MuiButton-startIcon': { marginRight: '4px' },
                }}
                startIcon={<ContentCopyIcon />}
            >
                <Typography sx={{ fontSize: 10, fontWeight: 700 }}>
                    {t('TeamInvitationModal:buttons:copy_link')}
                </Typography>
            </Button>
        );
    }, [copyLink]);

    return (
        <Modal
            isOpen
            closeAfterTransition
            center
            styles="px-11 my-6 pt-9 pb-8 rounded-lg shadow-md"
        >
            <Fade
                in
                timeout={{
                    enter: 500,
                    exit: 300,
                }}
            >
                <Box>
                    <Box className="flex gap-3">
                        <PersonPlusIcon
                            width={52}
                            height={37}
                            color={ENTITY_COLOR.teams}
                        />
                        <Typography className="text-2xl font-bold self-center">
                            {t('TeamInvitationModal:title')}
                        </Typography>
                    </Box>
                    <Box className="mt-6 flex gap-2" ref={containerRef}>
                        {invitationUrl ? (
                            <TextField
                                data-test-id="TeamInvitationModal-LinkInput"
                                id="invitationLink"
                                InputProps={{
                                    endAdornment:
                                        companyNameFieldTrailingContent,
                                    style: {
                                        height: TEXT_FIELD_HEIGHT,
                                        fontSize: 14,
                                        fontWeight: 300,
                                        color: '#212B36',
                                    },
                                    readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                name="invitationLink"
                                sx={{
                                    width: 518,
                                    borderColor: '#e5e7eb',
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#e5e7eb',
                                        },
                                    },
                                }}
                                focused={false}
                                value={invitationUrl || ''}
                            />
                        ) : (
                            <Skeleton
                                height={48}
                                sx={{ borderRadius: 1 }}
                                variant="rectangular"
                                width={518}
                            />
                        )}

                        <Slide
                            direction="up"
                            in={linkCopied}
                            container={containerRef.current}
                        >
                            <Button
                                variant="text"
                                className="self-center"
                                sx={{
                                    width: 150,
                                    height: 30,
                                    backgroundColor: COLOR.white,
                                    boxShadow: '0px 3px 6px #00000029',
                                    border: '1px solid #2E46AD33',
                                    opacity: 0.86,
                                    backdropFilter: 'blur(7px)',
                                    WebkitBackdropFilter: 'blur(7px)',
                                    '&:hover': {
                                        backgroundColor: COLOR.white,
                                    },
                                }}
                                disabled
                                startIcon={
                                    <DoneIcon
                                        fontSize="small"
                                        sx={{ color: COLOR.black }}
                                    />
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 400,
                                        color: COLOR.black,
                                    }}
                                >
                                    {t(
                                        'TeamInvitationModal:labels:copied_to_clickboard',
                                    )}
                                </Typography>
                            </Button>
                        </Slide>
                    </Box>
                    <Box className="mt-4">
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 300,
                                color: '#212B36',
                                maxWidth: 500,
                            }}
                        >
                            {t('TeamInvitationModal:description1')}
                        </Typography>
                    </Box>
                    <Box className="mt-5 flex justify-end">
                        <Button
                            onClick={closeModal}
                            className="px-8 py-2.5"
                            variant="contained"
                            sx={{
                                backgroundColor: ENTITY_COLOR.teams,
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.teams,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: COLOR.white,
                                }}
                            >
                                {t('TeamInvitationModal:buttons:done')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default TeamInvitationModal;
