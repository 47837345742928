import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import {
    ContactsTableColumnDescription,
    CONTACT_TABLE_COLUMN,
} from 'shared/constants/contacts/sort';
import { SORT_TYPE } from 'shared/constants/sort';
import { ContactModel } from 'shared/types/Contacts';
import { ColumnContent, SortObject } from 'shared/types/sort';

import {
    AddEditContactModal,
    AddEditHorseModal,
    EmptyState,
} from '@/components';
import { useImagesContext } from '@/context/ImagesContext';
import { calculateSortValue } from '@/helpers/sort';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { ContactsTableItem } from './components';
import { Props } from './types';

const headerFontStyle: SxProps = {
    color: COLOR.paleSky,
    fontWeight: 700,
    fontSize: 13,
};

const sortColumnStyle: SxProps = {
    display: 'flex',
};

function ContactsTable({
    emptyStateMessage,
    refetchContacts,
    rows,
    showEmptyStateHint,
    sortState,
    sortChange,
}: Props) {
    const { images, ImagesService } = useImagesContext();

    const [contactToEdit, setContactToEdit] = useState<ContactModel | null>(
        null,
    );
    const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);

    const openEditContactModal = useCallback(() => {
        setIsEditContactModalOpen(true);
    }, []);

    const closeEditContactModal = useCallback(() => {
        setIsEditContactModalOpen(false);
        setContactToEdit(null);
    }, []);

    const handleContactTableItemEditButtonPress = useCallback(
        (contact: ContactModel) => {
            setContactToEdit(contact);
            openEditContactModal();
        },
        [openEditContactModal],
    );

    const handleSortIconPress = useCallback(
        (column: ColumnContent) => {
            let newSort: SortObject;

            if (column.columnId !== sortState?.columnId) {
                newSort = {
                    columnId: column.columnId,
                    value: SORT_TYPE.DESC,
                };
            } else {
                const newSortValue = calculateSortValue(sortState.value);
                newSort = {
                    columnId: column.columnId,
                    value: newSortValue,
                };
            }

            if (column.unsafeQuery) {
                newSort.unsafeQuery = column.unsafeQuery;
            } else {
                newSort.query = column.query;
            }

            sortChange(newSort);
        },
        [sortState, sortChange],
    );

    const displaySortIcon = useCallback(
        (columnId: string): JSX.Element => {
            if (sortState.columnId !== columnId) {
                return <UnfoldMoreIcon fontSize="small" />;
            }

            return sortState.value === SORT_TYPE.DESC ? (
                <KeyboardArrowDownIcon fontSize="small" />
            ) : (
                <KeyboardArrowUpIcon fontSize="small" />
            );
        },
        [sortState],
    );

    return (
        <>
            {isEditContactModalOpen ? (
                <AddEditContactModal
                    contact={contactToEdit}
                    close={closeEditContactModal}
                    isEditMode
                    onSave={refetchContacts}
                    onRemove={refetchContacts}
                    CreateHorseModal={AddEditHorseModal}
                />
            ) : null}
            <TableContainer>
                {rows.length <= 0 && emptyStateMessage ? (
                    <>
                        <EmptyState
                            testID={'ContactsTable-EmptyStateMessage'}
                            message={emptyStateMessage}
                        />
                        {showEmptyStateHint ? (
                            <Typography
                                align="center"
                                sx={{
                                    fontSize: 12,
                                    fontWeight: '600',
                                    mx: 'auto',
                                    mt: 1.5,
                                    maxWidth: '480px',
                                }}
                            >
                                {t('ContactsList:empty_state_import_info')}
                            </Typography>
                        ) : null}
                    </>
                ) : null}
                {rows.length > 0 ? (
                    <Table
                        sx={{
                            minWidth: 650,
                        }}
                        aria-label="contacts table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    data-test-id="ContactsTable-ContactColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            ContactsTableColumnDescription[
                                                CONTACT_TABLE_COLUMN.CONTACT
                                            ],
                                        )
                                    }
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography sx={headerFontStyle}>
                                            {t(
                                                'ContactsList:table_columns:contact',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'ContactsList:table_columns:contact',
                                        )}
                                    </Box>
                                </TableCell>

                                <TableCell
                                    align="right"
                                    data-test-id="ContactsTable-HorsesColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            ContactsTableColumnDescription[
                                                CONTACT_TABLE_COLUMN
                                                    .HORSE_NUMBER
                                            ],
                                        )
                                    }
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'ContactsList:table_columns:horses',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'ContactsList:table_columns:horses',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    data-test-id="ContactsTable-BusinessColumnSort"
                                    sx={{ cursor: 'pointer', pl: 8 }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            ContactsTableColumnDescription[
                                                CONTACT_TABLE_COLUMN.BUSINESS
                                            ],
                                        )
                                    }
                                >
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'ContactsList:table_columns:business',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'ContactsList:table_columns:business',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    data-test-id="ContactsTable-LocationColumnSort"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        handleSortIconPress(
                                            ContactsTableColumnDescription[
                                                CONTACT_TABLE_COLUMN.LOCATION
                                            ],
                                        )
                                    }
                                >
                                    <Box sx={sortColumnStyle}>
                                        <Typography
                                            variant="caption"
                                            sx={headerFontStyle}
                                        >
                                            {t(
                                                'ContactsList:table_columns:location',
                                            )}
                                        </Typography>
                                        {displaySortIcon(
                                            'ContactsList:table_columns:location',
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                const contactFirebaseImage =
                                    ImagesService.getSingleImageByEntityId(
                                        images,
                                        row.id,
                                    );

                                return (
                                    <ContactsTableItem
                                        contact={row}
                                        contactAvatar={contactFirebaseImage}
                                        key={row.id}
                                        onEditButtonPress={
                                            handleContactTableItemEditButtonPress
                                        }
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : null}
            </TableContainer>
        </>
    );
}

export default memo(ContactsTable);
