import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useUserContext } from '@/context/UserContext';
import { calculateColorFromPathName } from '@/helpers/navbar';

import UserBox from '../UserBox';

function Navbar() {
    const { pathname: currentPathname } = useLocation();
    const { userAvatarUrl, userProfileData } = useUserContext();
    const { isInitialSyncInProgress } = useDBSyncContext();

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                backgroundColor: calculateColorFromPathName(currentPathname),
            }}
        >
            <UserBox
                isLoading={isInitialSyncInProgress}
                userAvatarUrl={userAvatarUrl}
                userProfileData={userProfileData}
            />
        </Box>
    );
}

export default Navbar;
