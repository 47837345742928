import { COLOR, ENTITY_COLOR } from '@/theme/colors';

function calculateColorFromPathName(pathname: string) {
    const result = pathname.match(/\/([a-zA-Z]*)/) || '';

    const entityName = result ? result[1] : '';

    const color: string = ENTITY_COLOR[entityName];

    if (color) {
        return color;
    } else if (entityName === 'shopping') {
        return ENTITY_COLOR.shoppingLists;
    } else {
        return COLOR.deepCerulean;
    }
}

export { calculateColorFromPathName };
