import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

import { UserProfileIcon } from '@/assets/svg';
import DeleteIcon from '@/assets/svg/DeleteIcon/DeleteIcon';
import { Badge } from '@/components';
import { useEntityImage } from '@/hooks/useEntityImage';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import Button from '../Button';

import { Props } from './types';

function MemberItem({
    id,
    name,
    subtitle,
    onApprove,
    onDelete,
    onEdit,
    disabled,
    showBadge,
}: Props) {
    const image = useEntityImage(id);
    const avatarURL = image?.imageURL || '';

    return (
        <Box
            sx={{
                backgroundColor: COLOR.backgroundGrey,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '8px',
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30,
                mt: '20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    sx={{
                        height: '64px',
                        width: '64px',
                        borderRadius: 30,
                        backgroundColor: COLOR.gainsboro,
                    }}
                    variant="rounded"
                    src={avatarURL}
                >
                    <UserProfileIcon color={COLOR.mischka} />
                </Avatar>
                <Box
                    sx={{
                        ml: '14px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            marginBottom: '3px',
                        }}
                    >
                        {showBadge ? (
                            <Badge
                                sx={{ width: 10, height: 10 }}
                                isCircle
                                backgroundColor={COLOR.cranberry}
                            />
                        ) : null}
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: 'bold',
                            }}
                        >
                            {name}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: 12,
                            color: '#212B36',
                            opacity: '40%',
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    pr: '16px',
                }}
            >
                {onApprove ? (
                    <Button
                        disabled={disabled}
                        sx={{
                            color: COLOR.white,
                            backgroundColor: ENTITY_COLOR.teams,
                            borderRadius: '3px',
                            height: '28px',
                            fontWeight: 'normal',
                            mr: '16px',
                            '&:hover': {
                                backgroundColor: ENTITY_COLOR.teams,
                                cursor: 'pointer',
                            },
                            opacity: disabled ? 0.5 : 1,
                        }}
                        caption={t('Actions:approve', {
                            defaultValue: 'Approve',
                        })}
                        onClick={onApprove}
                    />
                ) : null}
                {onEdit ? (
                    <Button
                        disabled={disabled}
                        variant="outlined"
                        sx={{
                            borderRadius: '3px',
                            fontWeight: 'normal',
                            height: '28px',
                            borderColor: '#41414148',
                            mr: '16px',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                            opacity: disabled ? 0.5 : 1,
                        }}
                        caption={t('Actions:edit')}
                        onClick={onEdit}
                    />
                ) : null}
                {onDelete ? (
                    <Box
                        sx={{
                            '&:hover': {
                                cursor: disabled ? 'auto' : 'pointer',
                            },
                            opacity: disabled ? 0.5 : 1,
                        }}
                        onClick={disabled ? undefined : onDelete}
                    >
                        <DeleteIcon />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default MemberItem;
