import { Q } from '@nozbe/watermelondb';
import { ContactColumnDescription } from '../../types/sort';
import { SORT_TYPE } from '../sort';

export enum CONTACT_TABLE_COLUMN {
    CONTACT = 'CONTACT',
    HORSE_NUMBER = 'HORSE_NUMBER',
    BUSINESS = 'BUSINESS',
    LOCATION = 'LOCATION',
}

export const ContactsTableColumnDescription: ContactColumnDescription = {
    [CONTACT_TABLE_COLUMN.CONTACT]: {
        columnId: 'ContactsList:table_columns:contact',
        query: (sort: SORT_TYPE) => Q.sortBy('first_name', sort),
    },
    [CONTACT_TABLE_COLUMN.HORSE_NUMBER]: {
        columnId: 'ContactsList:table_columns:horses',
        unsafeQuery: (filteredRaws: any, sort: SORT_TYPE, loki: any) => {
            const newRaws = filteredRaws.map((rawRecord) => {
                const contactHorses = loki.getCollection('horse_contacts')
                    .find({
                        contact_id: { $eq: rawRecord.id },
                    });
                return {...rawRecord, horseCount: contactHorses.length};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.horseCount < b.horseCount) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.horseCount > b.horseCount) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
    [CONTACT_TABLE_COLUMN.BUSINESS]: {
        columnId: 'ContactsList:table_columns:business',
        unsafeQuery: (filteredRaws: any, sort: SORT_TYPE) => {
            const newRaws = filteredRaws.map(rawRecord => {
                const lowerCaseBusinessName: string = rawRecord.business_name;

                return {...rawRecord, business_name: lowerCaseBusinessName ? lowerCaseBusinessName.toLowerCase() : ''};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.business_name < b.business_name) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.business_name > b.business_name) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
    [CONTACT_TABLE_COLUMN.LOCATION]: {
        columnId: 'ContactsList:table_columns:location',
        unsafeQuery: (filteredRaws: any, sort: SORT_TYPE) => {
            const newRaws = filteredRaws.map(rawRecord => {
                const lowerCaseLocation: string = rawRecord.address;

                return {...rawRecord, address: lowerCaseLocation ? lowerCaseLocation.toLowerCase() : ''};
            });
            return newRaws.sort((a: any, b: any) => {
                if (a.address < b.address) return sort === SORT_TYPE.ASC ? -1 : 1;
                if (a.address > b.address) return sort === SORT_TYPE.ASC ? 1 : -1;
                return 0;
            });
        },
    },
};