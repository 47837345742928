import {
    getAnalytics,
    logEvent as logFirebaseEvent,
    setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

import { firebaseApp } from '@/services/firebase';
import { Analytics } from '@/types/analytics';

const analytics = getAnalytics(firebaseApp);

setAnalyticsCollectionEnabled(analytics, !process.env.__DEV__);

function logEvent(
    eventName: string,
    params?: Analytics.Firebase.LogEventParams,
) {
    const paramsWithScreen = {
        ...params,
        screen: window.location.pathname,
    };

    logFirebaseEvent(analytics, eventName, paramsWithScreen);
}

export const FirebaseAnalytics = {
    logEvent,
};
