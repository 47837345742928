export enum EntriesEvents {
    userUpdatedEntry = 'user_updated_entry',
    userCreatedEntry = 'user_created_entry',
    userDeletedEntry = 'user_deleted_entry',
    userAddedEntryJobType = 'user_added_entry_job_type',
    userAddedEntryProcedure = 'user_added_entry_procedure',
    userExportedEntry = 'user_exported_entry',
    userAddedEntryPhotos = 'user_added_entry_photos',
    userUpdatedEntryPhotos = 'user_updated_entry_photos',
    userAddedEntryPrivateNotes = 'user_added_entry_private_notes',
    userUsedEntriesSearchBar = 'user_used_entries_search_bar',
    userAddedEntryShoe = 'user_added_entry_shoe',
    userAddedEntryNail = 'user_added_entry_nail',
    userAddedEntryCustomProduct = 'user_added_entry_custom_product',
    userDeletedEntryProduct = 'user_deleted_entry_product',
    userUsedPreviousProductsCombination = 'user_used_previous_products_combination',
}
