import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
    Autocomplete,
    Box,
    TextField,
    Typography,
    AutocompleteRenderInputParams,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from 'shared/types/Location';

import { APP_CONFIG } from '@/constants/appConfig';
import { useDebounce } from '@/hooks';
import { COLOR } from '@/theme/colors';

import { GooglePlaceOption, GooglePlaceDetails, Props } from './types';

const GOOGLE_MAPS_API_KEY = APP_CONFIG.googlePlacesApiKey;

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');

    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;

    position.appendChild(script);
}

function GooglePlacesAutocomplete({
    className,
    label,
    onBlur,
    onPlaceChange,
    testId,
    value,
}: Props) {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState(value?.description || '');
    const [options, setOptions] = useState<GooglePlaceOption[]>([]);
    const [selectedPlace, setSelectedPlace] =
        useState<GooglePlaceOption | null>(
            value
                ? {
                      description: value.description,
                      place_id: value.place_id,
                      structured_formatting: {
                          main_text: '',
                          secondary_text: '',
                      },
                  }
                : null,
        );

    const autocompleteServiceRef = useRef(null);
    const mapRef = useRef(null);
    const placesServiceRef = useRef(null);
    const isGoogleApiLoadedRef = useRef(false);

    if (typeof window !== 'undefined' && !isGoogleApiLoadedRef.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?lang=en&key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        isGoogleApiLoadedRef.current = true;
    }

    const initMap = useCallback(() => {
        const google = (window as any).google;

        if (!google || mapRef.current) {
            return;
        }

        const map = new google.maps.Map(document.getElementById('google-map'));

        mapRef.current = map;

        placesServiceRef.current = new google.maps.places.PlacesService(map);
    }, [mapRef]);

    useEffect(() => {
        setInputValue(value?.description || '');
        setSelectedPlace(value);
    }, [value]);

    useEffect(() => {
        initMap();
        (window as any).initMap = initMap;
    }, [initMap]);

    const fetchPlaces = useCallback(
        (
            request: { input: string },
            callback: (results?: GooglePlaceOption[]) => void,
        ) => {
            (autocompleteServiceRef.current as any).getPlacePredictions(
                request,
                callback,
            );
        },
        [],
    );

    const parsePlaceToLocationObject = useCallback(
        (
            placeData: GooglePlaceDetails,
            selectedPlaceDescription: string,
        ): Location => {
            return {
                lat: `${placeData.geometry.location.lat()}`,
                lng: `${placeData.geometry.location.lng()}`,
                name: selectedPlaceDescription || '',
                placeID: placeData.place_id,
                url: placeData.url,
                utcOffset: placeData.utc_offset_minutes,
            };
        },
        [],
    );

    const fetchPlaceDetails = useCallback(
        (
            placeId: string,
            onPlaceFound: (placeData: GooglePlaceDetails) => void,
        ) => {
            if (!placesServiceRef.current) {
                return;
            }

            (placesServiceRef.current as any).getDetails(
                { placeId },
                (placeData: GooglePlaceDetails) => {
                    if (placeData) {
                        onPlaceFound(placeData);
                    }
                },
            );
        },
        [],
    );

    const fetchPlacesDebounced = useDebounce(fetchPlaces);

    useEffect(() => {
        let isActive = true;

        const google = (window as any).google;

        if (google) {
            if (!autocompleteServiceRef.current) {
                autocompleteServiceRef.current =
                    new google.maps.places.AutocompleteService();
            }
        }

        if (!autocompleteServiceRef.current) {
            return;
        }

        if (inputValue === '') {
            setOptions(selectedPlace ? [selectedPlace] : []);

            return;
        }

        fetchPlacesDebounced(
            { input: inputValue },
            (results?: readonly GooglePlaceOption[]) => {
                if (isActive) {
                    let newOptions: GooglePlaceOption[] = [];

                    if (selectedPlace) {
                        newOptions = [selectedPlace];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            },
        );

        return () => {
            isActive = false;
        };
    }, [fetchPlacesDebounced, inputValue, selectedPlace]);

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return <TextField {...params} label={label} fullWidth />;
        },
        [label],
    );

    const getOptionLabel = useCallback((option: GooglePlaceOption) => {
        return typeof option === 'string' ? option : option.description;
    }, []);

    const handleInputChange = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, value: string) => {
            setInputValue(value);
        },
        [],
    );

    const handlePlaceChange = useCallback(
        (
            _event: React.SyntheticEvent<Element, Event>,
            value: GooglePlaceOption | null,
        ) => {
            setOptions(value ? [value, ...options] : options);
            setSelectedPlace(value);

            if (value?.place_id) {
                fetchPlaceDetails(value.place_id, (placeData) => {
                    const location = parsePlaceToLocationObject(
                        placeData,
                        value.description,
                    );

                    onPlaceChange(location);
                });
            } else {
                onPlaceChange(null);
            }
        },
        [fetchPlaceDetails, onPlaceChange, options, parsePlaceToLocationObject],
    );

    const renderOption = useCallback(
        (
            props: React.HTMLAttributes<HTMLElement>,
            option: GooglePlaceOption,
        ) => {
            return (
                <li
                    {...props}
                    data-test-id="GooglePlacesAutocomplete-Option"
                    key={option.place_id}
                >
                    <Box className="align-center flex flex-row">
                        <LocationOnIcon
                            className="self-center mr-3"
                            htmlColor={COLOR.paleSky}
                        />
                        <Box>
                            <Typography variant="body2">
                                {option.structured_formatting?.main_text}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {option.structured_formatting?.secondary_text}
                            </Typography>
                        </Box>
                    </Box>
                </li>
            );
        },
        [],
    );

    return (
        <>
            <div className="hidden" id="google-map" />
            <Autocomplete
                autoComplete
                className={className}
                data-test-id={testId}
                getOptionLabel={getOptionLabel}
                filterOptions={(x) => x}
                filterSelectedOptions
                isOptionEqualToValue={(option, value) =>
                    option.place_id === value.place_id
                }
                includeInputInList
                noOptionsText={t('LocationPicker:no_options_text')}
                onBlur={onBlur}
                onChange={handlePlaceChange}
                onInputChange={handleInputChange}
                options={options}
                renderInput={renderInput}
                renderOption={renderOption}
                value={selectedPlace}
            />
        </>
    );
}

export default GooglePlacesAutocomplete;
