import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OrganisationAnalytics } from 'shared/analytics/organisation';
import DatabaseService from 'shared/db/services/Database.web';
import Organisation from 'shared/db/services/Organisation';
import User from 'shared/db/services/User';
import { OrganisationPayload } from 'shared/types/Organisation';

import { Button, Modal, TeamPhotoSelector } from '@/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';
import { TeamValidationSchema } from '@/validations/team';

import { CreateTeamFormValues, Props } from './types';

function AddEditTeamModal({
    businessName,
    onSubmit,
    onCancel,
    isEditMode = false,
}: Props) {
    const { ImagesService, images } = useImagesContext();
    const { userOrganisation } = useOrganisationsContext();
    const { userProfileData } = useUserContext();
    const { isSyncInProgress } = useDBSyncContext();

    const [currentAvatarUrl, setCurrentAvatarUrl] = useState<
        string | null | undefined
    >(null);

    const userID = userProfileData?.id || '';

    const teamAvatar = useMemo(() => {
        if (userOrganisation?.id && isEditMode) {
            return ImagesService.getSingleImageByEntityId(
                images,
                userOrganisation.id,
            );
        }

        return null;
    }, [ImagesService, images, isEditMode, userOrganisation?.id]);

    const teamAvatarUrl = teamAvatar?.imageURL ?? '';

    useEffect(() => {
        setCurrentAvatarUrl(teamAvatarUrl);
    }, [teamAvatarUrl]);

    const _onSubmit = useCallback(
        async (values: CreateTeamFormValues) => {
            const database = DatabaseService.getDatabase();

            const organisationService = new Organisation({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const userService = new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const payload: OrganisationPayload = {
                image: currentAvatarUrl
                    ? {
                          uri: currentAvatarUrl,
                          documentID: teamAvatar?.documentID,
                      }
                    : undefined,
                name: values.businessName.trim(),
                avatar: currentAvatarUrl
                    ? {
                          uri: currentAvatarUrl,
                          documentID: teamAvatar?.documentID,
                      }
                    : undefined,
                removeAvatar: isEditMode && !currentAvatarUrl,
            };

            try {
                await organisationService.update(payload, userID);

                if (!isEditMode) {
                    await userService.update(userID, {
                        isTeamCreated: true,
                    });
                }

                OrganisationAnalytics.logUserCreateTeam(
                    FirebaseAnalytics.logEvent,
                    { teamName: payload.name || '' },
                );
            } catch (err) {
                Logger.logError(err);
            }

            onSubmit();
        },
        [
            ImagesService,
            currentAvatarUrl,
            isEditMode,
            onSubmit,
            teamAvatar?.documentID,
            userID,
        ],
    );

    const form = useFormik<CreateTeamFormValues>({
        initialValues: { businessName: businessName || '' },
        onSubmit: _onSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: TeamValidationSchema.addEditTeamForm,
    });

    const handleSubmit = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    const onPhotoChanged = useCallback((url: string | null) => {
        setCurrentAvatarUrl(url);
    }, []);

    return (
        <Modal
            center
            isOpen
            styles="flex max-w-2xl items-center justify-center "
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mx: '32px',
                    width: '578px',
                }}
            >
                <Typography
                    sx={{ fontSize: 24, fontWeight: 'bold', mb: '24px' }}
                >
                    {isEditMode
                        ? t('CreateTeam:edit_team')
                        : t('CreateTeam:create_team')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                    }}
                >
                    <TeamPhotoSelector
                        currentPhotoUrl={currentAvatarUrl}
                        onPhotoChanged={onPhotoChanged}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <TextField
                            sx={{
                                mr: '40px',
                            }}
                            data-test-id="AddEditTeamForm-BusinessNameInput"
                            error={
                                !!form.errors.businessName &&
                                !!form.touched.businessName
                            }
                            helperText={
                                form.touched.businessName
                                    ? form.errors.businessName
                                    : ''
                            }
                            id="businessName"
                            label={t('CreateTeam:labels:business_name')}
                            name="businessName"
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            value={form.values.businessName}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                mt: '24px',
                            }}
                        >
                            <InfoOutlinedIcon
                                sx={{
                                    display: 'flex',
                                    color: COLOR.bahamaBlue,
                                    mr: 1,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 'normal',
                                    fontSize: 12,
                                    maxWidth: '250px',
                                }}
                            >
                                {t('CreateTeam:beta')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: '50px',
                            }}
                        >
                            <Button
                                caption={t('Actions:cancel')}
                                onClick={onCancel}
                            />
                            <TooltipSyncButton
                                tooltip={t(
                                    'AddEditModalHeader:button_tooltip',
                                    {
                                        defaultValue:
                                            'Please wait for the synchronization to complete',
                                    },
                                )}
                                showTooltip={isSyncInProgress}
                                disabled={isSyncInProgress}
                                sx={{
                                    backgroundColor: ENTITY_COLOR.teams,
                                    color: COLOR.whiteDarker2,
                                    ml: 1,
                                    '&:hover': {
                                        backgroundColor: ENTITY_COLOR.teams,
                                    },
                                }}
                                variant={'contained'}
                                onClick={handleSubmit}
                            >
                                {isEditMode
                                    ? t('CreateTeam:edit_team')
                                    : t('CreateTeam:create_team')}
                            </TooltipSyncButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default AddEditTeamModal;
