import { HORSE_BREEDS_DICTIONARY } from 'shared/constants/horses/breeds';
import { HORSE_GENDERS_DICTIONARY } from 'shared/constants/horses/genders';
import { getHorseShoeingCycleWeeks } from 'shared/utils/horses';

import { generateSelectOptionsFromDictionary } from '@/helpers/filters';
import { t } from '@/services/translations/config';
import { SelectOption } from '@/types/select';

export function getHorseBreedFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(HORSE_BREEDS_DICTIONARY);
}

export function getHorseGenderFilters(): SelectOption[] {
    return generateSelectOptionsFromDictionary(HORSE_GENDERS_DICTIONARY);
}

export function getHorseShoeingCycleFilters(): SelectOption[] {
    const shoeingCycleWeeks = getHorseShoeingCycleWeeks(t);

    return shoeingCycleWeeks.map((week) => ({
        displayName: week.name,
        id: week.value,
        translate: false,
        value: week.value,
    }));
}
