import { LocationOn } from '@mui/icons-material';
import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { parseAge } from 'shared/utils/horse';
import { normalizeStringFromDB } from 'shared/utils/string';

import { HorseAvatar } from '@/assets/svg';
import { ImagePreview } from '@/components';
import { useImagesContext } from '@/context/ImagesContext';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { DataItem } from './components';
import { Props } from './types';

function Header({ horse, isLoading }: Props) {
    const { images, ImagesService } = useImagesContext();

    const [imageToPreview, setImageToPreview] = useState<string | null>(null);

    const openImagePreview = useCallback((url: string) => {
        setImageToPreview(url);
    }, []);

    const closeImagePreview = useCallback(() => {
        setImageToPreview(null);
    }, []);

    const avatarUrl = horse
        ? ImagesService.getSingleImageByEntityId(images, horse.id)?.imageURL
        : '';

    const handleOnAvatarClick = useCallback(() => {
        if (avatarUrl) {
            openImagePreview(avatarUrl);
        }
    }, [avatarUrl, openImagePreview]);

    const breedText = useMemo(() => {
        return horse?.breed
            ? normalizeStringFromDB(horse.breed, 'allWords')
            : '-';
    }, [horse?.breed]);

    const genderText = useMemo(() => {
        return horse?.gender
            ? normalizeStringFromDB(horse.gender, 'firstWord')
            : '-';
    }, [horse?.gender]);

    const workTypeText = useMemo(() => {
        return horse?.workType
            ? normalizeStringFromDB(horse.workType, 'firstWord')
            : '-';
    }, [horse?.workType]);

    const shoeingCycleText = horse?.shoeingCycle
        ? `${horse.shoeingCycle} ${t(
              horse.shoeingCycle === 1 ? 'Generic:week' : 'Generic:weeks',
          )}`
        : '-';

    const ageText = useMemo(() => {
        return horse?.foalDate
            ? parseAge(horse.foalDate, {
                  yearText: t('HorseProfile:picker:year'),
                  yearsText: t('HorseProfile:picker:years'),
                  monthText: t('HorseProfile:picker:month'),
                  monthsText: t('HorseProfile:picker:months'),
              }) || t('Generic:less_than_1_year')
            : '-';
    }, [horse?.foalDate]);

    const sireNameText = useMemo(
        () => horse?.sireName || '-',
        [horse?.sireName],
    );

    const damNameText = useMemo(() => horse?.damName || '-', [horse?.damName]);

    const microchipNoText = useMemo(
        () => horse?.microchipNo || '-',
        [horse?.microchipNo],
    );

    return (
        <>
            <Box className="flex pt-5 pl-5 pb-4 pr-28 bg-transparentGrayBackground mb-8 rounded-tl-lg rounded-tr-lg rounded-bl-sm rounded-br-sm">
                {isLoading ? (
                    <Skeleton
                        className="mr-5"
                        height={100}
                        variant="circular"
                        width={100}
                    />
                ) : (
                    <Box onClick={handleOnAvatarClick}>
                        <Avatar
                            className={`bg-gainsboro mr-5 h-[100px] w-[100px] ${
                                avatarUrl ? 'cursor-pointer' : ''
                            }`}
                            src={avatarUrl}
                            variant="circular"
                        >
                            <HorseAvatar
                                color={COLOR.mischka}
                                width={46}
                                height={53}
                            />
                        </Avatar>
                    </Box>
                )}
                <Box className="flex-1">
                    <Typography
                        className="mb-7"
                        color={COLOR.paleSky}
                        data-test-id="HorsePage-HorseNameText"
                        fontSize={24}
                        fontWeight={700}
                        lineHeight={1}
                        variant="h1"
                    >
                        {isLoading ? (
                            <Skeleton
                                className="-mb-2"
                                height={28}
                                width={150}
                                variant="text"
                            />
                        ) : (
                            `${horse?.name || ''}`
                        )}
                    </Typography>
                    <Box className="flex flex-wrap gap-3">
                        <Box className="flex flex-col flex-1 gap-3 min-w-[280px] mb-2 mr-2">
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : genderText ? (
                                <DataItem
                                    label={t('ShowHorse:label:gender')}
                                    testID="HorsePage-GenderText"
                                    value={genderText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : breedText ? (
                                <DataItem
                                    label={t('ShowHorse:label:breed')}
                                    testID="HorsePage-BreedText"
                                    value={breedText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : sireNameText ? (
                                <DataItem
                                    label={t('ShowHorse:label:sire_name')}
                                    testID="HorsePage-SireNameText"
                                    value={sireNameText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : damNameText ? (
                                <DataItem
                                    label={t('ShowHorse:label:dam_name')}
                                    testID="HorsePage-DamNameText"
                                    value={damNameText}
                                    underline
                                />
                            ) : null}
                        </Box>
                        <Box className="flex flex-col flex-1 gap-3 min-w-[280px] mb-2 mr-2">
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : ageText ? (
                                <DataItem
                                    label={t('ShowHorse:label:age')}
                                    testID="HorsePage-AgeText"
                                    value={ageText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : shoeingCycleText ? (
                                <DataItem
                                    label={t('ShowHorse:label:shoe_cycle')}
                                    testID="HorsePage-ShoeingCycleText"
                                    value={shoeingCycleText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : workTypeText ? (
                                <DataItem
                                    label={t('ShowHorse:label:work_type')}
                                    testID="HorsePage-workTypeText"
                                    value={workTypeText}
                                    underline
                                />
                            ) : null}
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={100}
                                    variant="text"
                                />
                            ) : microchipNoText ? (
                                <DataItem
                                    label={t('ShowHorse:label:microchip_no')}
                                    testID="HorsePage-MicrochipNoText"
                                    value={microchipNoText}
                                    underline
                                />
                            ) : null}
                        </Box>
                        <Box className="flex flex-col flex-1 gap-3 min-w-[280px] mb-2 mr-2">
                            {isLoading ? (
                                <Skeleton
                                    height={27}
                                    width={200}
                                    variant="text"
                                />
                            ) : horse?.stableAddress || horse?.stableName ? (
                                <Box className="flex">
                                    <LocationOn
                                        className="mb-0.5 mr-1"
                                        fontSize="small"
                                    />
                                    <Box>
                                        {horse.stableName ? (
                                            <Typography
                                                className="mb-1.5"
                                                data-test-id="HorsePage-StableNameText"
                                                fontWeight={300}
                                                variant="body2"
                                            >
                                                {horse.stableName}
                                            </Typography>
                                        ) : null}
                                        {horse.stableAddress ? (
                                            <Typography
                                                color={COLOR.paleSky}
                                                data-test-id="HorsePage-StableAddressText"
                                                fontWeight={300}
                                                variant="body2"
                                            >
                                                {horse.stableAddress}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {imageToPreview ? (
                <ImagePreview close={closeImagePreview} url={imageToPreview} />
            ) : null}
        </>
    );
}

export default Header;
