import { Box, Button, CircularProgress, Fade, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';

import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import Modal from '../Modal';

import { Props } from './types';

function UserInvitationDeclineModal({
    teamName,
    teamOwner,
    onCancel,
    onConfirm,
}: Props) {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleConfirmAction = useCallback(async () => {
        setIsDisabled(true);
        await onConfirm();
        setIsDisabled(false);
    }, [onConfirm]);

    return (
        <Modal
            isOpen
            closeAfterTransition
            center
            styles="px-5 my-6 pt-9 pb-8 rounded-lg shadow-md"
        >
            <Fade
                in
                timeout={{
                    enter: 500,
                    exit: 300,
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            color: COLOR.black,
                        }}
                    >
                        {t('TeamsPage:decline_invitation_title', {
                            organisationName: teamName,
                        })}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 300,
                            color: COLOR.black,
                        }}
                    >
                        {t('TeamsPage:decline_invitation_disclaimer', {
                            organisationOwner: teamOwner,
                        })}
                    </Typography>
                    <Box sx={{ alignSelf: 'flex-end', mt: 2 }}>
                        <Button
                            onClick={onCancel}
                            className="px-8 py-2.5"
                            variant="text"
                            sx={{
                                color: ENTITY_COLOR.teams,
                                '&:hover': {
                                    backgroundColor: COLOR.white,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: ENTITY_COLOR.teams,
                                }}
                            >
                                {t('Actions:cancel')}
                            </Typography>
                        </Button>
                        <Button
                            onClick={handleConfirmAction}
                            className="px-5 py-2.5"
                            variant="contained"
                            disabled={isDisabled}
                            sx={{
                                maxWidth: '80px',
                                minWidth: '80px',
                                backgroundColor: ENTITY_COLOR.teams,
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.teams,
                                },
                            }}
                        >
                            {isDisabled ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        color: COLOR.white,
                                    }}
                                >
                                    {t('Actions:remove')}
                                </Typography>
                            )}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default UserInvitationDeclineModal;
