import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function InputBadge({
    text,
    color = COLOR.badgeGrey,
    textColor = COLOR.white,
    testID,
    height = 28,
    fontSize = 14,
    opacity = 1,
}: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height,
                borderRadius: 0.5,
                px: 1,
                flexShrink: 0,
                backgroundColor: color,
                opacity,
            }}
        >
            <Typography
                data-test-id={testID}
                sx={{
                    fontSize,
                    color: textColor,
                }}
            >
                {text}
            </Typography>
        </Box>
    );
}

export default InputBadge;
