import { Box } from '@mui/material';
import React from 'react';

import { SectionHeader, TeamItem } from '@/components';
import { t } from '@/services/translations/config';

import { Props } from './types';

function OtherTeamsSection({ organisations, onTeamChange }: Props) {
    return (
        <Box>
            <SectionHeader
                title={t('TeamsPage:other_teams', {
                    defaultValue: 'Other Teams',
                })}
            />
            <Box sx={{ px: '16px' }}>
                {organisations?.map((org) => (
                    <TeamItem
                        key={org.id}
                        hasPendingApproval={org.hasPendingApproval}
                        id={org.id}
                        name={org.name}
                        onButtonPress={() => onTeamChange(org.id)}
                        ownerName={org.owner_name}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default OtherTeamsSection;
