import React, { memo } from 'react';

import { Props } from './types';

function UserProfileIcon({ color, width = 29, height = 35 }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 42.5 42.5">
            <path
                fill={color}
                d="M21.3,20.2c5.2,0,9.4-4.2,9.4-9.4s-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4S16,20.2,21.3,20.2L21.3,20.2z"
            />
            <path
                fill={color}
                d="M21.3,22.8c-4.9,0-14.6,4.2-14.6,12.4v6.2h29.1v-6.2C35.8,27,26,22.8,21.3,22.8z"
            />
        </svg>
    );
}

export default memo(UserProfileIcon);
