import { AxiosResponse } from 'axios';
import {
    VerifyAccessCodePayload,
    VerifyAccessCodeResponse,
} from 'shared/types/api/accessCode';

import apiClient from './apiClient';

export const verifyAccessCodeEndpoint = '/access_code/verify';

async function verifyAccessCode(payload: VerifyAccessCodePayload) {
    const { data } = await apiClient.post<
        VerifyAccessCodePayload,
        AxiosResponse<VerifyAccessCodeResponse>
    >(verifyAccessCodeEndpoint, payload);

    return data;
}

export const AccessCodeAPI = {
    verifyAccessCode,
};
