import { useCallback, useEffect, useState } from 'react';

import { useDebounce } from '@/hooks/useDebounce';
import { Dimensions } from '@/types/dimensions';

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState<Dimensions>({
        height: null,
        width: null,
    });

    const handleResize = useCallback(() => {
        setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }, []);

    const handleResizeDebounced = useDebounce(handleResize);

    useEffect(() => {
        // Call handler right away so state gets updated with initial window size
        handleResize();

        window.addEventListener('resize', handleResizeDebounced);

        return () =>
            window.removeEventListener('resize', handleResizeDebounced);
    }, [handleResize, handleResizeDebounced]);

    return windowSize;
}
