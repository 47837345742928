import { Box, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { CollapsibleContainer, ImagePreview } from '@/components';
import PhotoPlaceholder from '@/components/DividerSelectPhotoSection/components/PhotoPlaceholder';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function PhotosSection({
    photosUrls,
    emptyMessage,
    title,
    testIdPrefix,
    isLoading,
}: Props) {
    const [photoToZoomIn, setPhotoToZoomIn] = useState<string | null>(null);

    const zoomInPhoto = useCallback((url: string) => setPhotoToZoomIn(url), []);

    const zoomOutPhoto = useCallback(() => setPhotoToZoomIn(null), []);

    return (
        <>
            <CollapsibleContainer testID={testIdPrefix} title={title}>
                {photosUrls.length && !isLoading ? (
                    <Box className="flex flex-wrap gap-4">
                        {photosUrls.map((photoUrl) => (
                            <PhotoPlaceholder
                                key={photoUrl}
                                photoUrl={photoUrl}
                                clickable
                                onClick={zoomInPhoto}
                            />
                        ))}
                    </Box>
                ) : isLoading ? (
                    <Skeleton
                        data-test-id={`${testIdPrefix}-Skeleton`}
                        width={125}
                        height={125}
                    />
                ) : (
                    <Typography
                        color={COLOR.paleSky}
                        data-test-id={`${testIdPrefix}-EmptyMessage`}
                        fontStyle="italic"
                        fontWeight={300}
                    >
                        {emptyMessage}
                    </Typography>
                )}
            </CollapsibleContainer>
            {photoToZoomIn ? (
                <ImagePreview close={zoomOutPhoto} url={photoToZoomIn} />
            ) : null}
        </>
    );
}

export default PhotosSection;
