import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { EventHorseModel, EventHorsePayload } from '../../types/EventHorse';

class EventHorse {
    private database: Database;
    private collection: Collection<EventHorseModel>;
    private table = 'event_horses';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: EventHorsePayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdEventHorse = await this.collection.create(
                (entryProduct) => {
                    entryProduct.eventId = payload.eventId;
                    entryProduct.horseId = payload.horseId;
                    entryProduct.userId = userId;
                    entryProduct.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create event horse',
                modelName: this.table,
                payload: createdEventHorse,
            });
        });
    }

    async deleteByID(id: string) {
        const eventHorseElement = await this.getByID(id);

        await this.database.write(async () => {
            await eventHorseElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete event horse',
                modelName: this.table,
                payload: eventHorseElement,
            });
        });
    }
}

export default EventHorse;
