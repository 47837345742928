import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROLE } from 'shared/types/OrganisationUser';

import {
    AccountingProvidersPage,
    AppointmentPage,
    ContactPage,
    ContactsPage,
    DashboardPage,
    EntryPage,
    EntriesPage,
    ForgotPasswordPage,
    HorsePage,
    HorsesPage,
    InventoryPage,
    InvoicesPage,
    InvoicePage,
    NewPasswordPage,
    ProceduresPage,
    SchedulePage,
    SettingsPage,
    ShoppingListPage,
    ShoppingListsPage,
    SignInPage,
    SignUpPage,
    TeamsPage,
    ExternalAppointmentPage,
    GetMobileAppCallbackPage,
} from '@/pages';
import AppMaintenancePage from '@/pages/AppMaintenancePage';
import ScheduleUpdatesPage from '@/pages/ScheduleUpdatesPage';
import { RouteItem } from '@/types/router';

export enum ROUTE {
    root = '/',
    signIn = '/sign-in',
    signUp = '/sign-up',
    maintenance = '/maintenance',
    dashboard = '/dashboard',
    forgotPassword = '/forgot-password',
    newPassword = '/new-password',
    entries = '/entries',
    entry = '/entries/:id',
    contacts = '/contacts',
    contact = '/contacts/:id',
    schedule = '/schedule',
    appointment = '/schedule/:id',
    scheduleUpdates = '/schedule-updates',
    inventory = '/inventory',
    horse = '/horses/:id',
    horses = '/horses',
    invoices = '/invoices',
    invoice = '/invoices/:id',
    shoppingLists = '/shopping-lists',
    shoppingList = '/shopping-lists/:id',
    messages = '/messages',
    settings = '/settings',
    procedures = '/settings/procedures',
    accountingProviders = '/settings/accounting-providers',
    teams = '/teams',
    externalAppointment = '/external/appointment',
    help = '/help',
    getMobileApp = '/get-mobile-app',
    notFound = '*',
}

export const routes: RouteItem[] = [
    {
        component: <Navigate to={ROUTE.dashboard} />,
        path: ROUTE.root,
        secure: false,
    },
    {
        component: <AppMaintenancePage />,
        path: ROUTE.maintenance,
        secure: false,
    },
    {
        component: <SignInPage />,
        path: ROUTE.signIn,
        secure: false,
    },
    {
        component: <SignUpPage />,
        path: ROUTE.signUp,
        secure: false,
    },
    {
        component: <ForgotPasswordPage />,
        path: ROUTE.forgotPassword,
        secure: false,
    },
    {
        component: <NewPasswordPage />,
        path: ROUTE.newPassword,
        secure: false,
    },
    {
        component: <ExternalAppointmentPage />,
        path: ROUTE.externalAppointment,
        secure: false,
    },
    {
        component: <DashboardPage />,
        path: ROUTE.dashboard,
        secure: true,
    },
    {
        component: <ContactPage />,
        path: ROUTE.contact,
        secure: true,
        onInvalidRedirectTo: ROUTE.contacts,
    },
    {
        component: <ContactsPage />,
        path: ROUTE.contacts,
        secure: true,
    },
    {
        component: <EntryPage />,
        path: ROUTE.entry,
        secure: true,
        onInvalidRedirectTo: ROUTE.entries,
    },
    {
        component: <EntriesPage />,
        path: ROUTE.entries,
        secure: true,
    },
    {
        component: <SchedulePage />,
        path: ROUTE.schedule,
        secure: true,
    },
    {
        component: <AppointmentPage />,
        path: ROUTE.appointment,
        secure: true,
        onInvalidRedirectTo: ROUTE.schedule,
    },
    {
        component: <InventoryPage />,
        path: ROUTE.inventory,
        secure: true,
    },
    {
        component: <HorsePage />,
        path: ROUTE.horse,
        secure: true,
        onInvalidRedirectTo: ROUTE.horses,
    },
    {
        component: <HorsesPage />,
        path: ROUTE.horses,
        secure: true,
    },
    {
        component: <InvoicesPage />,
        path: ROUTE.invoices,
        secure: true,
        requiredRoles: [ROLE.admin],
    },
    {
        component: <InvoicePage />,
        path: ROUTE.invoice,
        secure: true,
        onInvalidRedirectTo: ROUTE.invoices,
        requiredRoles: [ROLE.admin],
    },
    {
        component: <ShoppingListsPage />,
        path: ROUTE.shoppingLists,
        secure: true,
    },
    {
        component: <ShoppingListPage />,
        path: ROUTE.shoppingList,
        secure: true,
        onInvalidRedirectTo: ROUTE.shoppingLists,
    },
    // INFO: hidden for MVP release
    // {
    //     component: <MessagesPage />,
    //     path: ROUTE.messages,
    //     secure: true,
    // },
    {
        component: <SettingsPage />,
        path: ROUTE.settings,
        secure: true,
    },
    {
        component: <ProceduresPage />,
        path: ROUTE.procedures,
        secure: true,
        requiredRoles: [ROLE.admin],
    },
    {
        component: <AccountingProvidersPage />,
        path: ROUTE.accountingProviders,
        secure: true,
        requiredRoles: [ROLE.admin],
    },
    // INFO: hidden for MVP release
    // {
    //     component: <HelpPage />,
    //     path: ROUTE.help,
    //     secure: true,
    // },
    { component: <TeamsPage />, path: ROUTE.teams, secure: true },
    {
        component: <ScheduleUpdatesPage />,
        path: ROUTE.scheduleUpdates,
        secure: true,
    },
    {
        component: <Navigate to={ROUTE.dashboard} />,
        path: ROUTE.notFound,
        secure: false,
    },
    {
        component: <GetMobileAppCallbackPage />,
        path: ROUTE.getMobileApp,
        secure: false,
    },
];
