import { useCallback, useEffect, useState } from 'react';
import Database from 'shared/db/services/Database.web';
import { CalculateEventStatusResult } from 'shared/types/Events';
import { calculateEventStatus } from 'shared/utils/events';

import { useImagesContext } from '@/context/ImagesContext';
import Logger from '@/services/logger';

const database = Database.getDatabase();

export function useEventStatus(eventId: string) {
    const { ImagesService } = useImagesContext();

    const [eventStatus, setEventStatus] =
        useState<CalculateEventStatusResult | null>(null);

    const fetchEventStatus = useCallback(async () => {
        const status = await calculateEventStatus({
            database,
            eventId,
            imageService: ImagesService,
            logDBAction: Logger.logRecordActivity,
            logError: Logger.logError,
        });
        setEventStatus(status);
    }, [ImagesService, eventId]);

    useEffect(() => {
        fetchEventStatus();
    }, [fetchEventStatus]);

    return { eventStatus };
}
