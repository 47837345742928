import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import {
    InventoryProductModel,
    InventoryProductPayload,
} from '../../types/InventoryProduct';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import { InventoryFiltersObject } from '../../types/inventoryFilters';
import { applyInventoryFiltersQueries } from '../utils';

class InventoryProduct {
    private database: Database;
    private collection: Collection<InventoryProductModel>;
    private table = 'inventory_products';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getFilteredProducts(filters: InventoryFiltersObject) {
        return this.collection
            .query(...applyInventoryFiltersQueries(filters))
            .fetch();
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: InventoryProductPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const createdInventoryProduct = await this.collection.create(
                (inventoryProduct) => {
                    inventoryProduct.productId = payload.productId;
                    inventoryProduct.quantity = payload.quantity;
                    inventoryProduct.favourite = payload.favourite;
                    inventoryProduct.userId = userId;
                    inventoryProduct.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create inventory product',
                modelName: this.table,
                payload: createdInventoryProduct,
            });

            return createdInventoryProduct;
        });
    }

    async update(id: string, payload: InventoryProductPayload, userId: string) {
        const inventoryProductElement = await this.getByID(id);

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            await inventoryProductElement.update((inventoryProduct) => {
                inventoryProduct.productId = payload.productId;
                inventoryProduct.userId = userId;
                inventoryProduct.organisationId = organisationID;
                inventoryProduct.quantity = payload.quantity;
            });

            this.options.logDBAction({
                message: 'Update inventory product',
                modelName: this.table,
                payload: inventoryProductElement,
            });
        });
    }

    async deleteByID(id: string) {
        const inventoryProductElement = await this.getByID(id);
        const relatedInventoryChanges =
            await inventoryProductElement.inventoryChanges.fetch();

        await this.database.write(async () => {
            await inventoryProductElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete inventory product',
                modelName: this.table,
                payload: inventoryProductElement,
            });

            if (relatedInventoryChanges.length > 0) {
                const inventoryChangesToDelete = relatedInventoryChanges.map(
                    (inventoryChange) => {
                        return inventoryChange.prepareMarkAsDeleted();
                    },
                );

                this.options.logDBAction({
                    message:
                        'Delete inventory changes related to inventory product',
                    modelName: this.table,
                    payload: inventoryChangesToDelete,
                });

                await this.database.batch(...inventoryChangesToDelete);
            }
        });
    }
}

export default InventoryProduct;
