import { Button as MUIButton } from '@mui/material';
import React from 'react';

import { Props } from './types';

function Button({ caption, onClick, sx, testID, ...props }: Props) {
    return (
        <MUIButton
            data-test-id={testID}
            color="inherit"
            onClick={onClick}
            sx={{ fontWeight: 700, height: 37, ...sx }}
            {...props}
        >
            {caption}
        </MUIButton>
    );
}

export default Button;
