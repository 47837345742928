import { UserInfo } from 'firebase/auth';
import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { FirebaseAuthAPI } from '@/services/firebase/auth';
import {
    checkAuthTokens,
    removeAuthToken,
    saveAuthToken,
} from '@/services/webStorage/authTokens';
import { removeOrganisationFromLocalStorage } from '@/services/webStorage/localStorage/organisation';
import { AuthenticateUserParams } from '@/types/auth';
import { Context } from '@/types/context';

const AuthContext = createContext<Context.Auth.Value>({
    allowUserToEnter: () => null,
    authenticateUser: () => null,
    clearContextState: () => null,
    firebaseUser: null,
    invalidateUserAuth: () => null,
    isUserAuthenticated: false,
    showSessionExpiredModal: false,
    setShowSessionExpiredModal: () => null,
    isUserAfterSignUp: false,
    setIsUserAfterSignUp: () => null,
});

const authContextRef: React.MutableRefObject<Context.Auth.Value | null> =
    createRef();

const AuthContextProvider = ({ children }: Context.Auth.Props) => {
    const [firebaseUser, setFirebaseUser] = useState<UserInfo | null>(null);
    const [isUserAuthenticated, setIsUserAuthenticated] =
        useState(checkAuthTokens);
    const [showSessionExpiredModal, setShowSessionExpiredModal] =
        useState(false);
    const [isUserAfterSignUp, setIsUserAfterSignUp] = useState(false);

    const authStateListener = useCallback((firebaseUser: UserInfo | null) => {
        setFirebaseUser(firebaseUser);
    }, []);

    useEffect(() => {
        const authSubscriber =
            FirebaseAuthAPI.auth.onAuthStateChanged(authStateListener);

        return authSubscriber;
    }, [authStateListener]);

    const authenticateUser = useCallback((params: AuthenticateUserParams) => {
        const { allowUserToEnter = true, ...restParams } = params;

        removeOrganisationFromLocalStorage();
        saveAuthToken(restParams);
        setIsUserAuthenticated(allowUserToEnter);
    }, []);

    const invalidateUserAuth = useCallback(() => {
        removeAuthToken();
        setIsUserAuthenticated(false);
    }, []);

    const allowUserToEnter = useCallback(() => {
        setIsUserAuthenticated(true);
    }, []);

    const clearContextState = useCallback(() => {
        setFirebaseUser(null);
        setIsUserAuthenticated(false);
        setShowSessionExpiredModal(false);
    }, []);

    const contextValue: Context.Auth.Value = {
        allowUserToEnter,
        authenticateUser,
        clearContextState,
        firebaseUser,
        invalidateUserAuth,
        isUserAuthenticated,
        showSessionExpiredModal,
        setShowSessionExpiredModal,
        isUserAfterSignUp,
        setIsUserAfterSignUp,
    };

    authContextRef.current = contextValue;

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuthContext() {
    const authContext = useContext(AuthContext);

    return authContext;
}

function setShowSessionExpiredModal(showModal: boolean) {
    authContextRef.current?.setShowSessionExpiredModal(showModal);
}

function clearAuthContextState() {
    return authContextRef.current?.clearContextState();
}

export {
    AuthContext,
    AuthContextProvider,
    useAuthContext,
    setShowSessionExpiredModal,
    clearAuthContextState,
};
