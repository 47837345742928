import React from 'react';

import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';

function withFeatureFlags<T>(Component: (props: T) => JSX.Element) {
    function ComponentWithFeatureFlags(props: T) {
        const flagsContext = useFeatureFlagsContext();

        return <Component {...flagsContext} {...props} />;
    }

    ComponentWithFeatureFlags.displayName = Component.name;

    return ComponentWithFeatureFlags;
}

export default withFeatureFlags;
