import { LogEvent } from '../../types/analytics';
import { ContactModel } from '../../types/Contacts';
import { HorseModel } from '../../types/Horses';

import { OrganisationAnalytics } from '../organisation';
import { HorsesEvents } from './events';

function logUserAddedHorseBreed(logEvent: LogEvent, params: { breed: string }) {
    logEvent(HorsesEvents.userAddedHorseBreed, {
        breed: params.breed,
    });
}

function logUserAddedHorseWorkType(
    logEvent: LogEvent,
    params: { workType: string },
) {
    logEvent(HorsesEvents.userAddedHorseWorkType, {
        workType: params.workType,
    });
}

function logUserAddedHorseGender(
    logEvent: LogEvent,
    params: { gender: string },
) {
    logEvent(HorsesEvents.userAddedHorseGender, {
        gender: params.gender,
    });
}

function logUserAddedHorseAge(logEvent: LogEvent, params: { age: string }) {
    logEvent(HorsesEvents.userAddedHorseAge, {
        age: params.age,
    });
}

function logUserAddedHorseShoeCycle(
    logEvent: LogEvent,
    params: { shoeCycle: number },
) {
    logEvent(HorsesEvents.userAddedHorseShoeCycle, {
        shoeCycle: params.shoeCycle,
    });
}

function logUserAddedContactToHorse(logEvent: LogEvent) {
    logEvent(HorsesEvents.userAddedContactToHorse);
}

async function logUserUpdatedHorse(
    logEvent: LogEvent,
    params: {
        horse: HorseModel;
        age: string | null;
        previousContacts: ContactModel[];
        previousGender?: string | null;
        previousBreed?: string | null;
        previousWorkType?: string | null;
        previousShoeingCycle?: number | null;
        previousAge?: string | null;
    },
) {
    const currentContacts = await params.horse.contacts.fetch();

    logEvent(HorsesEvents.userUpdatedHorse, {
        breed: params.horse.breed,
        created_at: params.horse.createdAt,
        foal_date: params.horse.foalDate,
        gender: params.horse.gender,
        hidden: params.horse.hidden,
        id: params.horse.id,
        organisation_id: params.horse.organisationId,
        shoe_size: params.horse.shoeSize,
        shoe_type: params.horse.shoeType,
        shoeing_cycle_badge: params.horse.shoeingCycleBadge,
        shoeing_cycle_notify: params.horse.shoeingCycleNotify,
        shoeing_cycle_weeks: params.horse.shoeingCycle,
        updated_at: params.horse.updatedAt,
        user_id: params.horse.userId,
        work_type: params.horse.workType,
    });

    const isNewContactAdded = currentContacts.some((currentContact) =>
        params.previousContacts.every(
            (previousContact) => currentContact.id !== previousContact.id,
        ),
    );

    if (isNewContactAdded) {
        logUserAddedContactToHorse(logEvent);
    }

    if (params.horse.gender && params.horse.gender !== params.previousGender) {
        logUserAddedHorseGender(logEvent, { gender: params.horse.gender });
    }

    if (params.horse.breed && params.horse.breed !== params.previousBreed) {
        logUserAddedHorseBreed(logEvent, { breed: params.horse.breed });
    }

    if (
        params.horse.workType &&
        params.horse.workType !== params.previousWorkType
    ) {
        logUserAddedHorseWorkType(logEvent, {
            workType: params.horse.workType,
        });
    }

    if (
        params.horse.shoeingCycle &&
        params.horse.shoeingCycle !== params.previousShoeingCycle
    ) {
        logUserAddedHorseShoeCycle(logEvent, {
            shoeCycle: params.horse.shoeingCycle,
        });
    }

    if (params.age && params.age !== params.previousAge) {
        logUserAddedHorseAge(logEvent, { age: params.age });
    }
}

async function logUserCreatedHorse(
    logEvent: LogEvent,
    params: {
        horse: HorseModel;
        age: string | null;
        isOwner: boolean | null;
    },
) {
    logEvent(HorsesEvents.userCreatedHorse, {
        breed: params.horse.breed,
        created_at: params.horse.createdAt,
        foal_date: params.horse.foalDate,
        gender: params.horse.gender,
        hidden: params.horse.hidden,
        id: params.horse.id,
        organisation_id: params.horse.organisationId,
        shoe_size: params.horse.shoeSize,
        shoe_type: params.horse.shoeType,
        shoeing_cycle_badge: params.horse.shoeingCycleBadge,
        shoeing_cycle_notify: params.horse.shoeingCycleNotify,
        shoeing_cycle_weeks: params.horse.shoeingCycle,
        updated_at: params.horse.updatedAt,
        user_id: params.horse.userId,
        work_type: params.horse.workType,
    });

    const contactsCount = await params.horse.contacts.fetchCount();

    if (contactsCount > 0) {
        logUserAddedContactToHorse(logEvent);
    }

    if (params.horse.gender) {
        logUserAddedHorseGender(logEvent, { gender: params.horse.gender });
    }

    if (params.horse.breed) {
        logUserAddedHorseBreed(logEvent, { breed: params.horse.breed });
    }

    if (params.horse.workType) {
        logUserAddedHorseWorkType(logEvent, {
            workType: params.horse.workType,
        });
    }

    if (params.horse.shoeingCycle) {
        logUserAddedHorseShoeCycle(logEvent, {
            shoeCycle: params.horse.shoeingCycle,
        });
    }

    if (params.age) {
        logUserAddedHorseAge(logEvent, { age: params.age });
    }

    if (params.isOwner === false) {
        OrganisationAnalytics.logTeamMemberCreatedHorse(logEvent);
    }
}

function logUserDeletedHorse(logEvent: LogEvent) {
    logEvent(HorsesEvents.userDeletedHorse);
}

function logUserUsedHorsesSearchBar(logEvent: LogEvent) {
    logEvent(HorsesEvents.userUsedHorsesSearchBar);
}

export const HorsesAnalytics = {
    logUserCreatedHorse,
    logUserDeletedHorse,
    logUserUsedHorsesSearchBar,
    logUserUpdatedHorse,
};
