import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ExternalAppointmentAPI } from '@/services/networking/appointments';
import { Snackbar } from '@/services/toastNotifications';
import { AppointmentDetailsInfo } from '@/types/appointments';

export enum ACTION_PARAM {
    ACCEPT = 'accept',
    REJECT = 'reject',
}

export enum INVITE_STATUS {
    AWAITING = 'awaiting',
    CONFIRMED = 'confirmed',
    REJECTED = 'rejected',
    PROMPT_TO_CONFIRM = 'prompt_to_confirm',
    PROMPT_TO_REJECT = 'prompt_to_reject',
    EXPIRED = 'expired',
}

export function useExternalAppointmentPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);

    const [appointmentDetails, setAppointmentDetails] =
        useState<AppointmentDetailsInfo | null>(null);
    const [status, setStatus] = useState<INVITE_STATUS>(INVITE_STATUS.AWAITING);
    const [token] = useState<string>(searchParams.get('token') || '');

    const clearActionQueryParam = useCallback(() => {
        searchParams.delete('action');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const handleAwaitingStatus = useCallback(() => {
        const action = searchParams.get('action') as ACTION_PARAM;

        if (action) {
            setStatus(
                action === ACTION_PARAM.ACCEPT
                    ? INVITE_STATUS.PROMPT_TO_CONFIRM
                    : INVITE_STATUS.PROMPT_TO_REJECT,
            );
        } else {
            setStatus(INVITE_STATUS.AWAITING);
        }
    }, [searchParams]);

    const setInitialStatus = useCallback(
        async (status: string, endTime: string) => {
            if (DateTime.fromISO(endTime) < DateTime.now()) {
                setStatus(INVITE_STATUS.EXPIRED);
                return;
            }

            switch (status) {
                case 'accepted':
                    setStatus(INVITE_STATUS.CONFIRMED);
                    return;
                case 'rejected':
                    setStatus(INVITE_STATUS.REJECTED);
                    return;
                case 'awaiting':
                default:
                    handleAwaitingStatus();
            }
        },
        [handleAwaitingStatus],
    );

    const fetchAppointmentDetails = useCallback(async () => {
        try {
            const result = await ExternalAppointmentAPI.getAppointmentDetails(
                token,
            );

            setInitialStatus(
                result.payload.current_user_status,
                result.payload.ends_at,
            );

            setAppointmentDetails(result.payload);
        } catch (e) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [setInitialStatus, token]);

    const rejectInvitation = useCallback(async () => {
        try {
            setIsLoading(true);

            await ExternalAppointmentAPI.replyInvitation({
                token,
                status: 'rejected',
            });

            await fetchAppointmentDetails();
        } catch (e) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        } finally {
            setStatus(INVITE_STATUS.REJECTED);
            setIsLoading(false);
        }
    }, [token, fetchAppointmentDetails]);

    const acceptInvitation = useCallback(async () => {
        try {
            setIsLoading(true);

            await ExternalAppointmentAPI.replyInvitation({
                token,
                status: 'accepted',
            });

            await fetchAppointmentDetails();
        } catch (e) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        } finally {
            setStatus(INVITE_STATUS.CONFIRMED);
            setIsLoading(false);
        }
    }, [token, fetchAppointmentDetails]);

    useEffect(() => {
        const loadInitialAppointmentDetails = async () => {
            setIsPageLoading(true);

            try {
                await fetchAppointmentDetails();
            } finally {
                setIsPageLoading(false);
            }
        };

        if (!appointmentDetails) loadInitialAppointmentDetails();
    }, [fetchAppointmentDetails, appointmentDetails]);

    return {
        appointmentDetails,
        status,
        isLoading,
        isPageLoading,
        acceptInvitation,
        rejectInvitation,
        clearActionQueryParam,
    };
}
