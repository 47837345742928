import { Box } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Components, SlotInfo } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { SchedulesAnalytics } from 'shared/analytics/schedules/schedules';
import Database from 'shared/db/services/Database.web';
import EventUser from 'shared/db/services/EventUser';
import User from 'shared/db/services/User';
import { CalendarEvent, EventModel } from 'shared/types/Events';
import { UserModel } from 'shared/types/User';
import { getLocal } from 'shared/utils/date';
import { getEventCardColor } from 'shared/utils/events';
import moment from 'shared/utils/moment';

import { AddEditScheduleModal } from '@/components';
import Calendar from '@/components/Calendar';
import {
    DayEvent,
    MonthColumnHeader,
    MonthDateCellHeader,
    MonthEvent,
    Toolbar,
    WeekColumnHeader,
} from '@/components/Calendar/components';
import WeekEvent from '@/components/Calendar/components/WeekEvent';
import { ViewType } from '@/components/Calendar/types';
import { useCalendarContext } from '@/context/CalendarContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { withSyncContext } from '@/hoc';
import { useDatabase } from '@/hooks';
import { useIsMounted } from '@/hooks/useIsMounted';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { mapUsersWithColors } from '@/services/user';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function SchedulesPage({ agendaEvents, calendarEvents }: Props) {
    const { view, setView } = useCalendarContext();

    const [date, setDate] = useState<Date>(new Date(moment.now()));
    const [agendaEventsToDisplay, setAgendaEventsToDisplay] = useState<
        CalendarEvent[]
    >([]);
    const [calendarEventsToDisplay, setCalendarEventsToDisplay] = useState<
        CalendarEvent[]
    >([]);
    const [selectedSlot, setSelectedSlot] = useState<SlotInfo | undefined>(
        undefined,
    );
    const [showAddEventModal, setShowAddEventModal] = useState(false);

    const isMounted = useIsMounted();
    const navigate = useNavigate();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { userProfileData } = useUserContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const isMonthView = view === 'month';

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const eventUsersService = useMemo(
        () =>
            new EventUser({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const closeAddAppointmentModal = useCallback(() => {
        if (isMounted) {
            setShowAddEventModal(false);
            setSelectedSlot(undefined);
        }
    }, [isMounted]);

    const openAddAppointmentModal = useCallback(
        () => setShowAddEventModal(true),
        [setShowAddEventModal],
    );

    const navigateToAppointmentDetails = useCallback(
        (event: EventModel) => {
            const route = getRoutePath(ROUTE.appointment, {
                id: event.id,
            });
            navigate(route);
        },
        [navigate],
    );

    const handleSubmitAppointment = useCallback(
        (event: EventModel) => {
            navigateToAppointmentDetails(event);

            if (selectedSlot) {
                SchedulesAnalytics.logUserCreatedEventFromCalendarView(
                    FirebaseAnalytics.logEvent,
                );
            }
        },
        [navigateToAppointmentDetails, selectedSlot],
    );

    const mapEventsToCalendarEvents = useCallback(
        async (events: EventModel[]) => {
            const result: CalendarEvent[] = [];

            for (const event of events) {
                const eventUsers =
                    await eventUsersService.getEventUsersByEventId(
                        event?.id ?? '',
                    );

                const activeUsers: UserModel[] = [];

                for (const eventUser of eventUsers) {
                    const user = (
                        await userService.getById(eventUser.userId).fetch()
                    )[0];
                    activeUsers.push(user);
                }

                const usersWithColors = await mapUsersWithColors(
                    activeUsers,
                    database,
                );
                const color = await getEventCardColor(
                    userProfileData?.id || '',
                    usersWithColors,
                );
                const item: CalendarEvent = {
                    start: new Date(event.startsTime),
                    end: new Date(event.endsTime),
                    color,
                    eventModel: event,
                };

                result.push(item);
            }

            return result;
        },
        [database, eventUsersService, userProfileData?.id, userService],
    );

    const setEventsToDisplay = useCallback(
        async (
            events: EventModel[],
            setEvents: React.Dispatch<React.SetStateAction<CalendarEvent[]>>,
        ) => {
            const eventsPrepared = await mapEventsToCalendarEvents(
                events || [],
            );

            setEvents(eventsPrepared);
        },
        [mapEventsToCalendarEvents],
    );

    useEffect(() => {
        if (isMounted) {
            setEventsToDisplay(agendaEvents || [], setAgendaEventsToDisplay);
        }
    }, [agendaEvents, isMounted, setEventsToDisplay]);

    useEffect(() => {
        if (isMounted) {
            setEventsToDisplay(
                calendarEvents || [],
                setCalendarEventsToDisplay,
            );
        }
    }, [calendarEvents, isMounted, setEventsToDisplay]);

    const handleViewChange = useCallback(
        (newView: ViewType) => {
            setView(newView);
            SchedulesAnalytics.logUserSwitchedEventsView(
                FirebaseAnalytics.logEvent,
                { view: newView },
            );
        },
        [setView],
    );

    const handleOnNavigateChange = useCallback(
        (newDate: Date) => setDate(newDate),
        [setDate],
    );

    const handleOnSlotClicked = useCallback(
        (slot: SlotInfo) => {
            setSelectedSlot(slot);
            openAddAppointmentModal();
        },
        [openAddAppointmentModal],
    );

    const handleOnEventClicked = useCallback(
        (event: CalendarEvent) => {
            const eventId = event.eventModel.id;
            navigate(`${ROUTE.schedule}/${eventId}`);
        },
        [navigate],
    );

    const customToolbar = useCallback(
        (props) => {
            return (
                <Toolbar {...props} showAddEventModal={setShowAddEventModal} />
            );
        },
        [setShowAddEventModal],
    );

    const customSlotPropGetter = useCallback(() => {
        return {
            style: {
                fontWeight: 400,
                fontSize: 14,
                color: COLOR.regentGray,
            },
        };
    }, []);

    const config: Components<CalendarEvent, object> = useMemo(
        () => ({
            toolbar: customToolbar,
            day: {
                event: DayEvent,
                header: WeekColumnHeader,
            },
            week: {
                header: WeekColumnHeader,
                event: WeekEvent,
            },
            month: {
                event: MonthEvent,
                header: MonthColumnHeader,
                dateHeader: MonthDateCellHeader,
            },
        }),
        [customToolbar],
    );
    return (
        <Box
            sx={{
                minWidth: 400,
                height: !isMonthView ? '85vh' : undefined,
                minHeight: isMonthView ? '85vh' : undefined,
                position: 'relative',
            }}
        >
            <Calendar
                date={date}
                defaultView={view}
                onNavigate={handleOnNavigateChange}
                onViewChange={handleViewChange}
                onEventClick={handleOnEventClicked}
                onSlotClick={handleOnSlotClicked}
                selectable
                events={
                    view === 'agenda'
                        ? agendaEventsToDisplay
                        : calendarEventsToDisplay
                }
                config={config}
                slotGroupGetter={customSlotPropGetter}
            />
            {showAddEventModal ? (
                <AddEditScheduleModal
                    onClose={closeAddAppointmentModal}
                    onSubmit={handleSubmitAppointment}
                    slotInfo={selectedSlot}
                    view={view}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();
        const yesterday = getLocal().minus({ days: 1 });

        const futureEventsQuery = Q.where('ends_time', Q.gt(yesterday.toISO()));

        return {
            agendaEvents: isInitialSyncInProgress
                ? of([])
                : database.collections
                      .get('events')
                      .query(futureEventsQuery, Q.sortBy('starts_time', 'asc')),
            calendarEvents: isInitialSyncInProgress
                ? of([])
                : database.collections.get('events').query(),
        };
    },
);

export default withSyncContext<Props>(enhance(SchedulesPage));
