import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
} from '@/services/webStorage/localStorage';

function removeAuthTokenFromLocalStorage(): void {
    removeItemFromLocalStorage(WEB_STORAGE_KEYS.firebase_token);
}

function checkAuthTokenInLocalStorage(): boolean {
    const firebaseToken = getItemFromLocalStorage<string>(
        WEB_STORAGE_KEYS.firebase_token,
    );

    return !!firebaseToken;
}

export { removeAuthTokenFromLocalStorage, checkAuthTokenInLocalStorage };
