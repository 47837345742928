// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, lazy, readonly } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class InventoryProduct extends Model {
    static table = 'inventory_products';

    static associations = {
        products: { type: 'belongs_to', key: 'product_id' },
        inventoryChanges: {
            type: 'has_many',
            foreignKey: 'inventory_product_id',
        },
    };

    @lazy
    inventoryChanges = this.collections
        .get('inventory_changes')
        .query(Q.where('inventory_product_id', this.id));

    @field('product_id') productId;
    @field('user_id') userId;
    @field('quantity') quantity;
    @field('favourite') favourite;
    @field('organisation_id') organisationId;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;
}
