import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { FirebaseImagesService } from '@/services/firebase/images';
import { Context } from '@/types/context';
import {
    FirestoreImagesDocument,
    FirestoreImagesDocumentsObject,
} from '@/types/firestore';

const ImagesContext = createContext<Context.Images.Value>({
    clearContextState: () => null,
    images: null,
    setImages: () => null,
    ImagesService: new FirebaseImagesService(),
    isImagesSyncInProgress: false,
    setIsImagesSyncInProgress: () => null,
});

const imagesContextRef: React.MutableRefObject<Context.Images.Value | null> =
    createRef();

const ImagesContextProvider = ({ children }: Context.Images.Props) => {
    const [isImagesSyncInProgress, setIsImagesSyncInProgress] = useState(false);

    const [images, setImages] = useState<FirestoreImagesDocumentsObject | null>(
        null,
    );

    const ImagesService = useMemo(
        () => new FirebaseImagesService(setIsImagesSyncInProgress),
        [setIsImagesSyncInProgress],
    );

    const parseAndSetImages = useCallback(
        (images: FirestoreImagesDocument[]) => {
            const imagesObject = images.reduce((acc, image) => {
                acc[image.entityID] = acc[image.entityID] || [];
                acc[image.entityID].push(image);

                return acc;
            }, {});

            setImages(imagesObject);
        },
        [],
    );

    const clearContextState = useCallback(() => {
        setImages(null);
    }, []);

    const contextValue: Context.Images.Value = {
        clearContextState,
        images,
        setImages: parseAndSetImages,
        isImagesSyncInProgress,
        setIsImagesSyncInProgress,
        ImagesService,
    };

    imagesContextRef.current = contextValue;

    return (
        <ImagesContext.Provider value={contextValue}>
            {children}
        </ImagesContext.Provider>
    );
};

function useImagesContext() {
    const imagesContext = useContext(ImagesContext);

    return imagesContext;
}

function clearImagesContextState() {
    return imagesContextRef.current?.clearContextState();
}

export {
    ImagesContext,
    ImagesContextProvider,
    useImagesContext,
    clearImagesContextState,
};
