export enum SchedulesEvents {
    userCreatedEvent = 'user_created_event',
    userUpdatedEvent = 'user_updated_event',
    userSetEventAlert = 'user_set_event_alert',
    userClickedEventNotifyContacts = 'user_clicked_event_notify_contacts',
    userClickedEventInviteContacts = 'user_clicked_event_invite_contacts',
    userClickedEventInviteHorseOwners = 'user_clicked_event_invite_horse_owners',
    userDeletedEvent = 'user_deleted_event',
    userSwitchedToEventsListView = 'user_switched_to_events_list_view',
    userSwitchedToEventsDayView = 'user_switched_to_events_day_view',
    userSwitchedToEventsWeekView = 'user_switched_to_events_week_view',
    userSwitchedToEventsMonthView = 'user_switched_to_events_month_view',
    userDuplicatedEvent = 'user_duplicated_event',
    userClickedEventNavigateButton = 'user_clicked_event_navigate_button',
    userClickedSMSButton = 'user_clicked_sms_button',
    userSentEventSMS = 'user_sent_event_sms',
    userCreatedEventFromCalendarView = 'user_created_event_from_calendar_view',
    userAcceptedAppointment = 'contact_accepted_appointment_RSVP',
    userDeclinedAppointment = 'contact_declined_appointment_RSVP',
    userNoResponseAppointment = 'no_response_RSVP',
}
