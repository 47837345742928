import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function HomeIcon({ color = COLOR.paleSky, height = 25, width = 25 }: Props) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 42.5 42.5">
            <path
                d="M35.7 15.7 23.2 5.1c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1 0-.2-.1c-.1 0-.1 0-.2-.1h-1c-.1 0-.2 0-.2.1-.1 0-.1.1-.2.1s-.1.1-.2.1-.1.1-.2.1-.1.1-.2.1L7.1 15.7c-.6.5-1 1.3-1 2.1v19.8c0 .1 0 .2.1.3 0 .1.1.2.1.2 0 .1.1.2.1.2 0 .1.1.2.1.2 0 .1.1.1.2.2l.2.2c0 .1.1.1.2.2l.2.2c.1 0 .1.1.2.1s.2.1.2.1c.1 0 .2.1.2.1s.2 0 .3.1H34.6c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.1.1 0 .2-.1.2-.1.1 0 .2-.1.2-.1.1 0 .1-.1.2-.2l.2-.2.2-.2.2-.2c0-.1.1-.1.1-.2s.1-.2.1-.2c0-.1.1-.2.1-.2 0-.1 0-.2.1-.3V17.7c-.1-.7-.4-1.5-1-2zM20.9 36.2c0 .8-.7 1.5-1.5 1.5H15c-.8 0-1.5-.7-1.5-1.5V24.4c0-.8.7-1.5 1.5-1.5h4.4c.8 0 1.5.7 1.5 1.5v11.8z"
                style={{
                    fill: color,
                }}
            />
        </svg>
    );
}

export default memo(HomeIcon);
