import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromLocalStorage,
    removeItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';

function getLastPulledAt(): string | null {
    return getItemFromLocalStorage(WEB_STORAGE_KEYS.last_pulled_at);
}

function setLastPulledAt(date: string) {
    storeItemInLocalStorage(WEB_STORAGE_KEYS.last_pulled_at, date);
}

function removeLastPulledAt() {
    removeItemFromLocalStorage(WEB_STORAGE_KEYS.last_pulled_at);
}

export { getLastPulledAt, setLastPulledAt, removeLastPulledAt };
