import HardwareIcon from '@mui/icons-material/Hardware';
import { Box, Typography } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import detectElementOverflow from 'detect-element-overflow';
import React, {
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { getUsersByEventId } from 'shared/db/utils';
import { EventCategory } from 'shared/types/Events';
import { getContactsText } from 'shared/utils/contacts';

import { CalendarEventIcon } from '@/assets/svg';
import { OrganisationMembersAvatarGroup } from '@/components';
import { useDatabase } from '@/hooks';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { FirebaseImagesService } from '@/services/firebase/images';
import { COLOR } from '@/theme/colors';

import HorsesCount from '../HorsesCount';

import { Props } from './types';

const TEAM_MEMBER_COLLAPSE_WIDTH = 180;
const INLINE_HORSES_TITLE_HIDDEN_WIDTH_WITH_MEMBERS = 95;
const TITLE_HIDDEN_WIDTH_WITH_MEMBERS = 72;
const TITLE_HIDDEN_WITH_HORSE_COUNT = 63;
const TITLE_HIDDEN = 45;
const TEAM_MEMBER_HIDDEN_WITH_HORSES = 63;
const TEAM_MEMBER_HIDDEN = 47;
const HORSE_COUNT_HIDDEN = 37;
const ICON_HIDDEN = 20;

function DayEvent({ event, contacts, horses, users }: Props) {
    const { getDatabase } = useDatabase();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const containerRef = useRef<HTMLElement>(null);
    const horsesRef = useRef<HTMLElement>(null);

    const [isHorsesOverflowing, setIsHorsesOverflowing] = useState(false);
    const [hideHorses, setHideHorses] = useState(false);
    const [collapseTeamMembers, setCollapseTeamMembers] = useState(false);
    const [hideTitle, setHideTitle] = useState(false);
    const [hideTeamMembers, setHideTeamMembers] = useState(false);
    const [hideHorseCount, setHideHorseCount] = useState(false);
    const [hideIcon, setHideIcon] = useState(false);

    const eventContactsText = useMemo(
        () => getContactsText(contacts ?? []),
        [contacts],
    );

    const eventIcon = useMemo(
        () =>
            event.eventModel.category === EventCategory.standard ? (
                <CalendarEventIcon
                    className="min-w-[19px]"
                    width={19}
                    height={21}
                    color={COLOR.white}
                />
            ) : (
                <HardwareIcon
                    sx={{
                        width: 19,
                        height: 19,
                    }}
                    htmlColor={COLOR.white}
                />
            ),
        [event.eventModel.category],
    );

    const horsesCountElement = useMemo(
        () =>
            horses?.length ? <HorsesCount horsesCount={horses.length} /> : null,
        [horses?.length],
    );

    const horsesTextElement = useMemo(() => {
        if (!horses?.length) {
            return null;
        }

        const horsesText = horses.map((horse) => horse.name).join(', ');

        return (
            <Box
                ref={horsesRef}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    ml: '3px',
                }}
            >
                {horsesCountElement}
                <Typography
                    sx={{
                        color: COLOR.white,
                        fontSize: 12,
                        fontWeight: '400',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textWrap: 'nowrap',
                    }}
                >
                    {horsesText}
                </Typography>
            </Box>
        );
    }, [horses, horsesCountElement]);

    useLayoutEffect(() => {
        if (horsesRef.current && containerRef.current) {
            setIsHorsesOverflowing(
                detectElementOverflow(horsesRef.current, containerRef.current)
                    .collidedBottom,
            );
        }
    }, []);

    const checkLayout = useCallback(() => {
        if (!containerRef.current) {
            return;
        }

        const containerWidth = containerRef.current.offsetWidth;

        if (containerWidth < TEAM_MEMBER_COLLAPSE_WIDTH) {
            setCollapseTeamMembers(true);
        } else {
            setCollapseTeamMembers(false);
        }

        if (
            containerWidth < INLINE_HORSES_TITLE_HIDDEN_WIDTH_WITH_MEMBERS &&
            isHorsesOverflowing &&
            users?.length
        ) {
            setHideTitle(true);
        } else if (
            containerWidth < TITLE_HIDDEN_WIDTH_WITH_MEMBERS &&
            users?.length
        ) {
            setHideTitle(true);
            setHideHorses(true);
        } else if (
            containerWidth < TITLE_HIDDEN_WITH_HORSE_COUNT &&
            horses?.length
        ) {
            setHideTitle(true);
        } else if (containerWidth < TITLE_HIDDEN) {
            setHideTitle(true);
        } else {
            setHideTitle(false);
            setHideHorses(false);
        }

        if (containerWidth < TEAM_MEMBER_HIDDEN_WITH_HORSES && horses?.length) {
            setHideTeamMembers(true);
        } else if (containerWidth < TEAM_MEMBER_HIDDEN) {
            setHideTeamMembers(true);
        } else {
            setHideTeamMembers(false);
        }

        if (containerWidth < HORSE_COUNT_HIDDEN) {
            setHideHorseCount(true);
        } else {
            setHideHorseCount(false);
        }

        if (containerWidth < ICON_HIDDEN) {
            setHideIcon(true);
        } else {
            setHideIcon(false);
        }
    }, [horses?.length, isHorsesOverflowing, users?.length]);

    useResizeObserver(containerRef, checkLayout);

    return (
        <Box
            ref={containerRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                py: 0.5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: 0.5,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        flex: 1,
                        minWidth: 0,
                    }}
                >
                    {!hideIcon ? eventIcon : null}
                    {(isHorsesOverflowing || hideHorses) && !hideHorseCount
                        ? horsesCountElement
                        : null}
                    {!hideTitle ? (
                        <Typography
                            sx={{
                                color: COLOR.white,
                                fontSize: '13px',
                                fontWeight: '400',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                textWrap: 'nowrap',
                            }}
                        >
                            {eventContactsText
                                ? `${eventContactsText} (${event.eventModel.title})`
                                : event.eventModel.title}
                        </Typography>
                    ) : null}
                </Box>
                {users?.length && !hideTeamMembers ? (
                    <OrganisationMembersAvatarGroup
                        database={database}
                        max={3}
                        users={collapseTeamMembers ? [users[0]] : users}
                        testID="SchedulesListItem-Members"
                        size={18}
                        fontSize={9}
                        borderSize={1}
                    />
                ) : null}
            </Box>
            {!isHorsesOverflowing && !hideHorses ? horsesTextElement : null}
        </Box>
    );
}

const enhance = withObservables(['event'], ({ event }: Props) => {
    return {
        contacts: event.eventModel.contacts.observe(),
        horses: event.eventModel.horses
            .extend(Q.where('hidden', false))
            .observe(),
        users: getUsersByEventId(
            event?.eventModel.id ?? '',
            new FirebaseImagesService(),
        ),
    };
});

export default enhance(DayEvent);
