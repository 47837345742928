import React from 'react';

import { useImagesContext } from '@/context/ImagesContext';

import InvoiceItemsFinancialSummary from '../InvoiceItemsFinancialSummary';

import EntriesListItem from './components/EntriesListItem';
import { Props } from './types';

function EntriesList({
    clickable,
    items,
    multiSelect = true,
    selectCondition,
    conditionWarning,
    testIdPrefix,
    selectable,
    selectedItems,
    onItemClick,
    onRemove,
    removable,
    itemWrapperStyle,
    size = 'normal',
    financialDetails,
}: Props) {
    const { images, ImagesService } = useImagesContext();

    return (
        <>
            {items.map((entry) => {
                const avatarUrl = ImagesService.getSingleImageByEntityId(
                    images,
                    entry.id,
                )?.imageURL;

                return (
                    <EntriesListItem
                        clickable={clickable}
                        currency={financialDetails?.currency}
                        testID={`${testIdPrefix}-EntriesListItem`}
                        key={entry.id}
                        entry={entry}
                        onRemove={onRemove}
                        isSelected={selectedItems && selectedItems.has(entry)}
                        onClick={onItemClick}
                        selectable={selectable}
                        removable={removable}
                        size={size}
                        wrapperStyles={itemWrapperStyle}
                        avatarUrl={avatarUrl}
                        multiSelect={multiSelect}
                        selectCondition={selectCondition}
                        conditionWarning={conditionWarning}
                        withFinancialDetails={Boolean(financialDetails)}
                    />
                );
            })}
            {financialDetails ? (
                <InvoiceItemsFinancialSummary {...financialDetails} />
            ) : null}
        </>
    );
}

export default EntriesList;
