/* eslint-disable @typescript-eslint/quotes */
import { appSchema, tableSchema } from '@nozbe/watermelondb';

export default appSchema({
    version: 171,
    tables: [
        tableSchema({
            name: 'invoicing_products',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'price', type: 'string' },
                { name: 'available', type: 'boolean' },
                { name: 'user_id', type: 'string' },
                { name: 'organisation_id', type: 'string' },
                { name: 'created_at', type: 'number', isIndexed: true },
                { name: 'updated_at', type: 'number', isIndexed: true },
            ],
        }),
        tableSchema({
            name: 'invoice_invoicing_products',
            columns: [
                { name: 'user_id', type: 'string' },
                { name: 'organisation_id', type: 'string' },
                { name: 'invoice_id', type: 'string' },
                { name: 'invoicing_product_id', type: 'string' },
                { name: 'quantity', type: 'number' },
                { name: 'name', type: 'string' },
                { name: 'price', type: 'string' },
                { name: 'created_at', type: 'number', isIndexed: true },
                { name: 'updated_at', type: 'number', isIndexed: true },
            ],
        }),
        tableSchema({
            name: 'onboardings',
            columns: [
                {
                    name: 'overlay_archived_invoices',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'overlay_rsvp_one',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'overlay_rsvp_two',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'overlay_rsvp_three',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'overlay_rsvp_four',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'getting_started_section_dismissed',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'are_procedures_onboarded',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'are_entries_onboarded',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'are_invoices_onboarded',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'are_business_details_onboarded',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'user_id',
                    type: 'string',
                    isIndexed: true,
                },
                {
                    name: 'created_at',
                    type: 'number',
                },
                {
                    name: 'updated_at',
                    type: 'number',
                },
            ],
        }),
        tableSchema({
            name: 'event_users',
            columns: [
                {
                    name: 'event_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'user_id', type: 'string', isIndexed: true },
                {
                    name: 'organisation_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'client_created_at', type: 'number' },
                { name: 'client_updated_at', type: 'number' },
                { name: 'archived_at', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'entry_users',
            columns: [
                {
                    name: 'entry_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'user_id', type: 'string', isIndexed: true },
                {
                    name: 'organisation_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'client_created_at', type: 'number' },
                { name: 'client_updated_at', type: 'number' },
                { name: 'archived_at', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'inventory_changes',
            columns: [
                {
                    name: 'inventory_product_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'user_id', type: 'string', isIndexed: true },
                {
                    name: 'organisation_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'quantity_change', type: 'number' },
                { name: 'client_created_at', type: 'number' },
                { name: 'client_updated_at', type: 'number' },
                { name: 'archived_at', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'process_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'entries',
            columns: [
                { name: 'logged_time', type: 'string' },
                { name: 'title', type: 'string', isOptional: true },
                { name: 'notes', type: 'string', isOptional: true },
                { name: 'horse_id', type: 'string', isOptional: true },
                { name: 'event_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'invoice_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'private_notes', type: 'string', isOptional: true },
            ],
        }),
        tableSchema({
            name: 'entry_products',
            columns: [
                { name: 'entry_id', type: 'string', isIndexed: true },
                { name: 'product_id', type: 'string', isIndexed: true },
                { name: 'quantity', type: 'number' },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'events',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'location_id', type: 'string', isIndexed: true },
                { name: 'title', type: 'string', isOptional: true },
                { name: 'address', type: 'string', isOptional: true },
                { name: 'starts_time', type: 'string' },
                { name: 'ends_time', type: 'string' },
                { name: 'notes', type: 'string', isOptional: true },
                { name: 'user_reminder', type: 'string', isOptional: true },
                { name: 'send_reminders_to_contacts', type: 'boolean' },
                { name: 'send_reminders_to_owners', type: 'boolean' },
                { name: 'send_invites_to_contacts', type: 'boolean' },
                { name: 'send_invites_to_contacts_at', type: 'string' },
                { name: 'send_invites_to_owners', type: 'boolean' },
                { name: 'timezone', type: 'string', isOptional: true },
                { name: 'category', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'is_recurring', type: 'boolean' },
                {
                    name: 'recurrence_pattern',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'recurrence_end_date',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'original_event_id',
                    type: 'string',
                    isOptional: true,
                },
            ],
        }),
        tableSchema({
            name: 'event_horses',
            columns: [
                { name: 'event_id', type: 'string', isIndexed: true },
                { name: 'horse_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'event_contacts',
            columns: [
                { name: 'event_id', type: 'string', isIndexed: true },
                { name: 'contact_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'sent_sms', type: 'boolean' },
                { name: 'sent_sms_at', type: 'string' },
                { name: 'sent_email', type: 'boolean' },
                { name: 'sent_email_at', type: 'string' },
                { name: 'status', type: 'string', isOptional: true },
            ],
        }),
        tableSchema({
            name: 'event_contact_reminders',
            columns: [
                { name: 'event_id', type: 'string', isIndexed: true },
                { name: 'time_before', type: 'string' },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'event_contact_id', type: 'string' },
            ],
        }),
        tableSchema({
            name: 'horses',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'foal_date', type: 'string', isOptional: true },
                { name: 'gender', type: 'string', isOptional: true },
                { name: 'shoe_size', type: 'string', isOptional: true },
                { name: 'shoe_type', type: 'string', isOptional: true },
                { name: 'breed', type: 'string', isOptional: true },
                { name: 'work_type', type: 'string', isOptional: true },
                { name: 'comments', type: 'string', isOptional: true },
                { name: 'stable_name', type: 'string', isOptional: true },
                { name: 'stable_address', type: 'string', isOptional: true },
                { name: 'stable_phone', type: 'string', isOptional: true },
                { name: 'microchip_no', type: 'string', isOptional: true },
                { name: 'sire_name', type: 'string', isOptional: true },
                { name: 'dam_name', type: 'string', isOptional: true },
                {
                    name: 'stable_phone_prefix',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'hidden', type: 'boolean' },
                {
                    name: 'shoeing_cycle_weeks',
                    type: 'number',
                    isOptional: true,
                },
                {
                    name: 'shoeing_cycle_notify',
                    type: 'boolean',
                    isOptional: true,
                },
                {
                    name: 'shoeing_cycle_badge',
                    type: 'boolean',
                    isOptional: true,
                },
                { name: 'location_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'inventory_products',
            columns: [
                { name: 'product_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'quantity', type: 'number' },
                { name: 'favourite', type: 'boolean' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'contacts',
            columns: [
                { name: 'first_name', type: 'string' },
                { name: 'last_name', type: 'string', isOptional: true },
                { name: 'description', type: 'string', isOptional: true },
                { name: 'role', type: 'string', isOptional: true },
                { name: 'phone', type: 'string', isOptional: true },
                { name: 'phone_prefix', type: 'string', isOptional: true },
                { name: 'email', type: 'string', isOptional: true },
                { name: 'business_name', type: 'string', isOptional: true },
                { name: 'address', type: 'string', isOptional: true },
                { name: 'comments', type: 'string', isOptional: true },
                { name: 'location_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'billable', type: 'boolean' },
                { name: 'hidden', type: 'boolean' },
                { name: 'synced', type: 'boolean' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'products',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'product_type', type: 'string' },
                { name: 'brand', type: 'string', isOptional: true },
                { name: 'family', type: 'string', isOptional: true },
                { name: 'shape', type: 'string', isOptional: true },
                { name: 'dimensions', type: 'string', isOptional: true },
                { name: 'clips', type: 'string', isOptional: true },
                { name: 'shoe_size', type: 'string', isOptional: true },
                { name: 'nail_size', type: 'string', isOptional: true },
                { name: 'product_size', type: 'string', isOptional: true },
                { name: 'sku_apac', type: 'string', isOptional: true },
                { name: 'sku_emea', type: 'string', isOptional: true },
                { name: 'uom_dealers', type: 'string', isOptional: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'material', type: 'string', isOptional: true },
                {
                    name: 'shoe_size_sort_num',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'box_unit_size', type: 'number' },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'not_available', type: 'boolean', isOptional: true },
                //   t.uuid "region_id"
            ],
        }),
        tableSchema({
            name: 'horse_contacts',
            columns: [
                { name: 'horse_id', type: 'string' },
                { name: 'contact_id', type: 'string' },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'shopping_lists',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'name', type: 'string' },
                {
                    name: 'added_to_inventory_time',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'sent_to_dealer_time',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'requested_sending_to_dealer_time',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'dealer_id', type: 'string', isOptional: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'shopping_list_products',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'shopping_list_id', type: 'string', isIndexed: true },
                { name: 'product_id', type: 'string', isIndexed: true },
                {
                    name: 'product_sales_unit_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'unit_type', type: 'string' },
                { name: 'quantity', type: 'number' },
                { name: 'total_quantity', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'users',
            columns: [
                { name: 'email', type: 'string' },
                { name: 'password_digest', type: 'string' },
                { name: 'reset_password_token', type: 'string' },
                { name: 'api_refresh_token', type: 'string' },
                { name: 'accounting_disconnect_message', type: 'string' },
                { name: 'region', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'first_name', type: 'string', isOptional: true },
                { name: 'last_name', type: 'string', isOptional: true },
                { name: 'phone', type: 'string', isOptional: true },
                { name: 'phone_prefix', type: 'string', isOptional: true },
                {
                    name: 'accounting_provider',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'address', type: 'string', isOptional: true },
                { name: 'location_id', type: 'string', isIndexed: true },
                { name: 'trace_id', type: 'string', isOptional: true },
                { name: 'profiling', type: 'boolean', isOptional: true },
                { name: 'sales_account_code', type: 'string' },
                { name: 'lock_accounting_provider', type: 'boolean' },
                { name: 'menu_order', type: 'string' },
                { name: 'company_name', type: 'string', isOptional: true },
                {
                    name: 'freshchat_restore_id',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'is_team_created', type: 'boolean' },
                { name: 'is_procedures_account_setup_set', type: 'boolean' },
                { name: 'is_invoicing_account_setup_set', type: 'boolean' },
                // duplicated flag. To be ignored
                { name: 'is_contacts_setup_set', type: 'boolean' },
                { name: 'is_contacts_setup', type: 'boolean' },
                { name: 'timezone', type: 'string', isOptional: true },
                { name: 'is_appointment_onboarded', type: 'boolean' },
                { name: 'ignore', type: 'boolean' },
                {
                    name: 'is_appointment_contact_selection_onboarded',
                    type: 'boolean',
                },
                {
                    name: 'is_entry_creation_from_event_enabled',
                    type: 'boolean',
                },
                {
                    // unix timestamp
                    name: 'terms_accepted_at',
                    type: 'number',
                },
                {
                    // unix timestamp
                    name: 'terms_created_at',
                    type: 'number',
                },
                { name: 'is_plus_button_onboarded', type: 'boolean' },
            ],
        }),
        tableSchema({
            name: 'locations',
            columns: [
                { name: 'place_id', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'lat', type: 'string' },
                { name: 'lng', type: 'string' },
                { name: 'url', type: 'string' },
                { name: 'utc_offset', type: 'number' },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'organisations',
            columns: [
                { name: 'name', type: 'string' },
                { name: 'address', type: 'string', isOptional: true },
                {
                    name: 'alternative_address',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'payment_instructions',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'phone_number', type: 'string', isOptional: true },
                { name: 'email', type: 'string', isOptional: true },
                { name: 'alternative_email', type: 'string', isOptional: true },
                {
                    name: 'alternative_phone_number',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'alternative_phone_prefix',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'footer_message', type: 'string', isOptional: true },
                { name: 'tax_rate', type: 'string', isOptional: true },
                { name: 'products_tax_rate', type: 'string', isOptional: true },
                { name: 'business_number', type: 'string', isOptional: true },
                { name: 'phone_prefix', type: 'string', isOptional: true },
                { name: 'location_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'is_alternative_phone_enabled', type: 'boolean' },
                { name: 'is_alternative_email_enabled', type: 'boolean' },
            ],
        }),
        tableSchema({
            name: 'organisation_accountings',
            columns: [
                {
                    name: 'sales_account_code',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'accounting_provider',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'accounting_disconnect_message',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'lock_accounting_provider',
                    type: 'boolean',
                },
                {
                    name: 'user_email',
                    type: 'string',
                    isOptional: true,
                },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'organisation_users',
            columns: [
                { name: 'organisation_id', type: 'string', isIndexed: true },
                {
                    name: 'organisation_user_color_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'active', type: 'boolean', isIndexed: true },
                { name: 'role', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'organisation_user_colors',
            columns: [
                { name: 'code', type: 'string' },
                { name: 'position', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'user_settings',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'topic', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'enabled', type: 'boolean' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'procedures',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'category', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'description', type: 'string', isOptional: true },
                { name: 'price', type: 'string' },
                { name: 'available', type: 'boolean' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'entry_procedures',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'entry_id', type: 'string', isIndexed: true },
                { name: 'procedure_id', type: 'string', isIndexed: true },
                { name: 'price', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'quantity', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
            // @ts-ignore
            unsafeSql: (sql) => sql.replace(`"quantity" = 0`, `"quantity" = 1`),
        }),
        tableSchema({
            name: 'invoices',
            columns: [
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'organisation_id', type: 'string', isIndexed: true },
                { name: 'contact_id', type: 'string', isIndexed: true },
                { name: 'deep_link', type: 'string' },
                { name: 'external_id', type: 'string' },
                { name: 'number', type: 'string' },
                { name: 'status', type: 'string' },
                { name: 'sub_total', type: 'string' },
                { name: 'total_tax', type: 'string' },
                { name: 'total', type: 'string' },
                { name: 'total_discount', type: 'string' },
                { name: 'sent_to_contact', type: 'boolean' },
                { name: 'authorised_time', type: 'string' },
                { name: 'sent_to_contact_time', type: 'string' },
                { name: 'payment_date_time', type: 'string' },
                { name: 'due_date_time', type: 'string' },
                { name: 'reference', type: 'string' },
                { name: 'apply_taxes', type: 'boolean', isOptional: true },
                { name: 'attach_reports', type: 'boolean' },
                { name: 'public_url', type: 'string' },
                { name: 'amount_check', type: 'string', isOptional: true },
                { name: 'footer_text', type: 'string', isOptional: true },
                { name: 'amount_valid', type: 'boolean', isOptional: true },
                { name: 'synced', type: 'boolean' },
                { name: 'invoice_date', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'last_synced_at', type: 'string', isOptional: true },
                { name: 'tax_rate', type: 'string', isOptional: true },
                { name: 'products_tax_rate', type: 'string', isOptional: true },
                { name: 'provider', type: 'string', isOptional: true },
                {
                    name: 'user_provider_email',
                    type: 'string',
                    isOptional: true,
                },
                {
                    name: 'provider_archived_at',
                    type: 'string',
                    isOptional: true,
                },
            ],
        }),
        tableSchema({
            name: 'regions',
            columns: [
                { name: 'code', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'currency_name', type: 'string' },
                { name: 'currency_symbol', type: 'string' },
                { name: 'proposed_language', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
                { name: 'default_timezone', type: 'string' },
            ],
        }),
        tableSchema({
            name: 'product_sales_units',
            columns: [
                { name: 'box_unit_size', type: 'number' },
                { name: 'sku_apac', type: 'string' },
                { name: 'sku_emea', type: 'string' },
                { name: 'product_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'product_sales_unit_regions',
            columns: [
                {
                    name: 'product_sales_unit_id',
                    type: 'string',
                    isIndexed: true,
                },
                { name: 'region_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'device_calendar_events',
            columns: [
                { name: 'device_id', type: 'string', isIndexed: true },
                { name: 'calendar_event_id', type: 'string', isIndexed: true },
                { name: 'event_id', type: 'string', isIndexed: true },
                { name: 'user_id', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'questions',
            columns: [
                { name: 'content', type: 'string' },
                { name: 'description', type: 'string', isOptional: true },
                { name: 'extra_action', type: 'string', isOptional: true },
                { name: 'is_multiselect', type: 'boolean', isOptional: true },
                { name: 'mandatory', type: 'boolean' },
                { name: 'question_version', type: 'number' },
                { name: 'question_order', type: 'number' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'answers',
            columns: [
                { name: 'content', type: 'string' },
                { name: 'question_id', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
        tableSchema({
            name: 'user_answers',
            columns: [
                { name: 'user_id', type: 'string' },
                { name: 'answer_id', type: 'string' },
                { name: 'created_at', type: 'number' },
                { name: 'updated_at', type: 'number' },
            ],
        }),
    ],
});
