import {
    Alert,
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CATEGORIES,
    CATEGORY_DICTIONARY,
    ProcedureCategory,
} from 'shared/constants/procedure/CATEGORIES';
import Procedure from 'shared/db/services/Procedure';
import User from 'shared/db/services/User';

import { AddEditModalHeader, Modal } from '@/components';
import { UpdateExistingEntriesAlert } from '@/components/AddEditProcedureModal/components';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useDatabase,
    useCancelEntityFormAlert,
    useEscapeButton,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { ENTITY_COLOR } from '@/theme/colors';
import { ProcedureValidationSchema } from '@/validations/procedure';

import { Props, AddEditProcedureFormValues } from './types';

const defaulInitialValues: AddEditProcedureFormValues = {
    category: '',
    name: '',
    available: true,
    description: '',
    price: '',
    updateExistingEntries: true,
};

function AddEditProcedureModal({
    close,
    procedure,
    isEditMode = false,
    onSave,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const [initialValues, setInitialValues] =
        useState<AddEditProcedureFormValues>(defaulInitialValues);
    const [
        isUpdateExistingEntriesAlertOpen,
        setIsUpdateExistingEntriesAlertOpen,
    ] = useState<boolean>(false);

    const openUpdateExistingEntriesAlert = useCallback(
        () => setIsUpdateExistingEntriesAlertOpen(true),
        [],
    );
    const closeUpdateExistingEntriesAlert = useCallback(
        () => setIsUpdateExistingEntriesAlertOpen(false),
        [],
    );

    const prepareInitialValues = useCallback(() => {
        if (!procedure) {
            return;
        }

        setInitialValues({
            category: procedure.category ?? '',
            name: procedure.name,
            description: procedure.description ?? '',
            price: procedure.price ?? '',
            available: procedure.available ?? true,
            updateExistingEntries: true,
        });
    }, [procedure]);

    useEffect(() => {
        if (isEditMode) {
            prepareInitialValues();
        }
    }, [prepareInitialValues, isEditMode]);

    const updateProcedure = useCallback(
        async (
            values: AddEditProcedureFormValues,
            updateExistingEntries = true,
        ) => {
            try {
                const procedureService = new Procedure({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const payload = {
                    ...values,
                    category: values.category as ProcedureCategory,
                };

                let createdUpdatedProcedure;

                if (isEditMode) {
                    if (!procedure?.id) {
                        throw new Error();
                    }

                    createdUpdatedProcedure = await procedureService.update(
                        procedure.id,
                        payload,
                        updateExistingEntries,
                    );
                } else {
                    createdUpdatedProcedure = await procedureService.add(
                        payload,
                        userProfileData?.id || '',
                    );
                }

                await userService.update(userProfileData?.id || '', {
                    isProceduresAccountSetupSet: true,
                });

                if (onSave) {
                    onSave(createdUpdatedProcedure);
                }

                close();

                Snackbar.showToastNotification({
                    message: t(
                        isEditMode
                            ? 'App:Messages:has_been_edited_successfully'
                            : 'App:Messages:has_been_created_successfully',
                        {
                            entity: t('Entities:procedure'),
                        },
                    ),
                });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            ImagesService,
            close,
            database,
            isEditMode,
            onSave,
            procedure?.id,
            t,
            userProfileData?.id,
            userService,
        ],
    );

    const handleSubmit = useCallback(
        async (values: AddEditProcedureFormValues) => {
            if (isEditMode && procedure?.price !== values.price) {
                openUpdateExistingEntriesAlert();
            } else {
                updateProcedure(values);
            }
        },
        [
            isEditMode,
            openUpdateExistingEntriesAlert,
            procedure?.price,
            updateProcedure,
        ],
    );

    const form = useFormik<AddEditProcedureFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: ProcedureValidationSchema.addEditProcedureForm,
    });

    const handleUpdateExistingEntriesConfirmationResult = useCallback(
        (updateExistingEntries: boolean) => {
            updateProcedure(form.values, updateExistingEntries);
        },
        [form.values, updateProcedure],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel: form.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    return (
        <Modal
            isOpen
            disableRestoreFocus
            center
            testID="AddEditProcedureModal"
            styles="py-0"
        >
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <AddEditModalHeader
                    backgroundColor={ENTITY_COLOR.entries}
                    cancel={handleFormCancelButtonClick}
                    save={form.handleSubmit}
                    testIdPrefix="AddEditProcedureForm"
                    title={t(
                        isEditMode
                            ? 'Procedure:modal:view_edit_title'
                            : 'Procedure:modal:view_create_title',
                    )}
                    teamName={
                        userOrganisations && userOrganisations?.length > 1
                            ? userOrganisation?.name || ''
                            : undefined
                    }
                    avatarUrl={
                        userOrganisations && userOrganisations?.length > 1
                            ? teamAvatar?.imageURL || ''
                            : undefined
                    }
                />
                <Box
                    className="px-12 pb-7 flex flex-col gap-10"
                    sx={{
                        overflow: 'auto',
                        pt: 5,
                    }}
                >
                    <TextField
                        select
                        id="category"
                        label={t('Procedure:label:category')}
                        error={
                            !!form.errors.category && !!form.touched.category
                        }
                        helperText={
                            form.touched.category ? form.errors.category : ''
                        }
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        name="category"
                        value={form.values.category}
                        data-test-id="AddEditProcedureForm-Category"
                        SelectProps={{ defaultValue: null }}
                    >
                        {CATEGORIES.map((category) => (
                            <MenuItem
                                key={category}
                                value={CATEGORY_DICTIONARY[category]}
                                data-test-id={`AddEditProcedureForm-Category${CATEGORY_DICTIONARY[category]}`}
                            >
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        data-test-id="AddEditProcedureForm-Name"
                        id="name"
                        label={t('Procedure:label:name')}
                        error={!!form.errors.name && !!form.touched.name}
                        helperText={form.touched.name ? form.errors.name : ''}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.name}
                        name="name"
                    />
                    <TextField
                        fullWidth
                        data-test-id="AddEditProcedureForm-Description"
                        id="description"
                        label={t('Procedure:label:description')}
                        error={
                            !!form.errors.description &&
                            !!form.touched.description
                        }
                        helperText={
                            form.touched.description
                                ? form.errors.description
                                : ''
                        }
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.description}
                        name="description"
                    />
                    <Alert severity="warning">
                        {t('Procedure:procedure_disclaimer')}
                    </Alert>
                    <TextField
                        data-test-id="AddEditProcedureForm-Price"
                        id="price"
                        type="text"
                        label={t('Procedure:label:price')}
                        error={!!form.errors.price && !!form.touched.price}
                        helperText={form.touched.price ? form.errors.price : ''}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        value={form.values.price}
                        name="price"
                        sx={{ width: '50%' }}
                    />
                    <FormControlLabel
                        checked={form.values.available}
                        className="mb-5"
                        control={
                            <Switch
                                data-test-id="AddEditProcedureForm-Available"
                                id="available"
                                name="available"
                                value={form.values.available}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        }
                        label={t('Procedure:label:available')}
                    />
                </Box>
            </Box>
            {isEditMode ? (
                <UpdateExistingEntriesAlert
                    isOpen={isUpdateExistingEntriesAlertOpen}
                    onConfirm={handleUpdateExistingEntriesConfirmationResult}
                    onClose={closeUpdateExistingEntriesAlert}
                    testID="AddEditProcedureForm-UpdateExistingEntriesAlert"
                />
            ) : null}
            {showCancelAlert ? renderCancelAlert() : null}
        </Modal>
    );
}

export default AddEditProcedureModal;
