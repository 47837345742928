export function isObjectEmpty<T extends object>(object: T): boolean {
    return !Object.keys(object).length;
}

/**
 * @param object - object to have keys and values swapped
 * @returns object with swapped keys and values
 *
 * NOTE: be aware that whenever an object passed to the function has
 * some identical values, only one of them (the last one) will be assigned
 * to the object returned by the function.
 *
 * Using this function only with static data is recommended.
 */
export function swapObjectKeysWithValues<T extends object, K>(object: T): K {
    const swapped = Object.entries(object).map(([key, value]) => ({
        [value]: key,
    }));

    return Object.assign({}, ...swapped);
}

export function isObject<T>(object: T) {
    return (
        typeof object === 'object' && !Array.isArray(object) && object !== null
    );
}

export function checkIfObjectHasEmptyField(
    values: Object,
    fieldsToDismiss?: string[],
) {
    return Object.keys(values)
        .filter((key) => !fieldsToDismiss || !fieldsToDismiss.includes(key))
        .some((key) =>
            Array.isArray(values[key]) ? !values[key]?.length : !values[key],
        );
}
