import React from 'react';

import { SchedulesListItem } from './components';
import type { Props } from './types';

function SchedulesList({
    items,
    testIdPrefix,
    onEditItemClick,
    onItemClick,
    type = 'regular',
}: Props) {
    return (
        <>
            {items.map((event) => {
                if (type === 'compact') {
                    return (
                        <SchedulesListItem
                            testID={`${testIdPrefix}-SchedulesListItem`}
                            event={event}
                            key={event.id}
                            onClick={onItemClick}
                            eventCategory={event.category}
                        />
                    );
                } else if (type === 'regular') {
                    return (
                        <SchedulesListItem
                            testID={`${testIdPrefix}-SchedulesListItem`}
                            event={event}
                            key={event.id}
                            onEditItemClick={onEditItemClick}
                            onClick={onItemClick}
                            eventCategory={event.category}
                            isEditable
                        />
                    );
                } else {
                    return (
                        <SchedulesListItem
                            testID={`${testIdPrefix}-SchedulesListItem`}
                            event={event}
                            key={event.id}
                            onClick={onItemClick}
                            eventCategory={event.category}
                        />
                    );
                }
            })}
        </>
    );
}

export default SchedulesList;
