import React, { memo } from 'react';

import { Props } from './types';

function HorseAvatar({ color, width = 40, height = 46 }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 1024 1024">
            <path
                fill={color}
                d="M238.532 942.080c16.866-67.464 72.282-115.652 130.108-168.659 65.054-62.645 132.518-125.289 134.927-212.028-12.047 21.685-28.913 33.732-45.779 43.369-31.322 14.456-65.054 12.047-101.195 12.047-12.047 0-21.685 0-28.913 2.409s-12.047 7.228-16.866 19.275c-4.819 12.047-9.638 21.685-14.456 28.913-7.228 9.638-14.456 16.866-21.685 19.275-19.275 7.228-33.732 9.638-48.188 7.228-21.685-2.409-36.141-12.047-48.188-26.504-12.047-12.047-19.275-28.913-24.094-45.779-9.638-21.685-12.047-43.369-9.638-57.826v-4.819l2.409-4.819c33.732-48.188 67.464-98.786 103.605-149.384 31.322-48.188 65.054-93.967 98.786-144.565-2.409-28.913-9.638-50.598-16.866-74.692-12.047-36.141-16.866-62.645-21.685-101.195 38.551 9.638 79.511 21.685 113.242 43.369 21.685 12.047 38.551 28.913 53.007 53.007v0c7.228-12.047 16.866-21.685 28.913-33.732l7.228-4.819 7.228 2.409c98.786 26.504 180.706 69.873 240.941 132.518s101.195 144.565 115.652 250.579c9.638 60.235 4.819 130.108-9.638 197.572-14.456 65.054-40.96 125.289-74.692 178.296l-554.165 38.551z"
            />
        </svg>
    );
}

export default memo(HorseAvatar);
