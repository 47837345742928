import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ContactModel } from 'shared/types/Contacts';
import { EventCategory } from 'shared/types/Events';

import { COLOR } from '@/theme/colors';

export function renderTableItemContactsText<T>(
    contacts: ContactModel[],
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        className?: string;
        color?: COLOR;
        fontSize?: number;
        fontWeight?: number;
        props?: T;
        testId?: string;
    },
): JSX.Element {
    const [contact, ...contactsRest] = contacts;

    const lastNameText = contact.lastName ? ` ${contact.lastName}` : '';

    const firstPart = `${contact.firstName}${lastNameText}`;
    const secondPart = contactsRest.length
        ? translateFn('EntriesList:contacts:more_count', {
              contacts_count: contactsRest.length,
          })
        : '';

    const contactsRestTooltipText = contactsRest
        .map(
            (contact) =>
                `${contact.firstName}${
                    contact.lastName ? ` ${contact.lastName}` : ''
                }`,
        )
        .join(', ');

    return (
        <Typography
            className={options?.className}
            data-test-id={options?.testId}
            fontSize={options?.fontSize || 13}
            fontWeight={options?.fontWeight || 300}
            {...options?.props}
        >
            {firstPart}
            {secondPart ? (
                <>
                    {` ${translateFn('Generic:and')} `}
                    <Tooltip
                        arrow
                        title={contactsRestTooltipText}
                        placement="top"
                    >
                        <span className="underline">{secondPart}</span>
                    </Tooltip>
                </>
            ) : (
                ''
            )}
        </Typography>
    );
}

export function renderEventContacts<T>(
    contacts: ContactModel[],
    eventCategory: EventCategory,
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        className?: string;
        color?: COLOR;
        fontSize?: number;
        fontWeight?: number;
        props?: T;
        testId?: string;
    },
): JSX.Element {
    const extendedLength = eventCategory === EventCategory.standard ? 3 : 2;

    if (contacts.length <= extendedLength) {
        const contactsLabel = contacts
            .map((contact) => `${contact.firstName} ${contact?.lastName || ''}`)
            .join(', ');
        return (
            <Typography
                className={options?.className}
                data-test-id={options?.testId}
                fontSize={options?.fontSize || 11}
                fontWeight={options?.fontWeight || 300}
                {...options?.props}
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {contactsLabel}
            </Typography>
        );
    } else {
        const [contact1, contact2, contact3, ...contactsRest] = contacts;

        const firstPart = `${contact1.firstName} ${
            contact1?.lastName || ''
        } ${`, ${contact2.firstName} ${contact2?.lastName || ''}`} ${
            eventCategory === EventCategory.standard
                ? `, ${contact3.firstName} ${contact3?.lastName || ''}`
                : ''
        }`;

        const rest =
            eventCategory === EventCategory.standard
                ? [...contactsRest]
                : [contact3, ...contactsRest];

        const secondPart = rest.length
            ? translateFn('EntriesList:contacts:plus_contacts', {
                  contacts_count: rest.length,
              })
            : '';

        const contactsRestTooltipText = rest
            .map(
                (contact) =>
                    `${contact?.firstName || ''} ${contact?.lastName || ''}`,
            )
            .join(', ');

        return (
            <>
                <Typography
                    className={options?.className}
                    data-test-id={options?.testId}
                    fontSize={options?.fontSize || 11}
                    fontWeight={options?.fontWeight || 300}
                    {...options?.props}
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {firstPart}
                </Typography>
                {secondPart ? (
                    <Tooltip
                        arrow
                        title={contactsRestTooltipText}
                        placement="top"
                    >
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 700,
                                color: COLOR.accountSetupBlack,
                            }}
                        >
                            {secondPart}
                        </Typography>
                    </Tooltip>
                ) : (
                    ''
                )}
            </>
        );
    }
}

export function renderDashboardEventContacts<T>(
    contacts: ContactModel[],
    translateFn: (key: string, params?: Record<string, number>) => string,
    options?: {
        className?: string;
        color?: COLOR;
        fontSize?: number;
        fontWeight?: number;
        props?: T;
        testId?: string;
    },
): JSX.Element {
    const label = translateFn('EventsList:event:plus_contacts', {
        contacts_count: contacts.length,
    });

    const contactsRestTooltipText = contacts
        .map(
            (contact) =>
                `${contact.firstName}${
                    contact.lastName ? ` ${contact.lastName}` : ''
                }`,
        )
        .join(', ');

    return (
        <Tooltip arrow title={contactsRestTooltipText} placement="top">
            <Typography
                className={options?.className}
                data-test-id={options?.testId}
                fontSize={options?.fontSize || 11}
                fontWeight={options?.fontWeight || 300}
                {...options?.props}
            >
                {label}
            </Typography>
        </Tooltip>
    );
}
