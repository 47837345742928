import { Collection, Database, Q } from '@nozbe/watermelondb';

import { OrganisationUserModel } from '../../types/OrganisationUser';
import OrganisationUserColor from '../models/OrganisationUserColor';

class OrganisationUser {
    private database: Database;
    private collection: Collection<OrganisationUserModel>;
    private table = 'organisation_users';

    constructor(database: Database) {
        this.database = database;
        this.collection = database.collections.get(this.table);
    }

    get() {
        return this.collection.query().fetch();
    }

    getById(user_id: string) {
        const organisationUser = this.collection.query(
            Q.where('user_id', user_id),
        );
        return organisationUser;
    }

    async getColorByUserId(user_id: string) {
        const organisationUser = (await this.getById(user_id).fetch())[0];
        return this.database.collections
            .get<OrganisationUserColor>('organisation_user_colors')
            .query(Q.where('id', organisationUser?.organisationUserColorId));
    }

    getActive() {
        return this.collection.query(Q.where('active', true));
    }
}

export default OrganisationUser;
