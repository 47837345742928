import { ENTITY_COLOR, ENTITY_SECONDARY_COLOR } from '@/theme/colors';

import { SVGIconProps } from './svg';

export enum AccountSetupType {
    PROCEDURES_AND_SERVICES,
    INVOICING,
}

export type AccountSetupItem = {
    type: AccountSetupType;
    title: string;
    description: string;
    positiveAction: string;
    negativeAction: string;
    icon: React.MemoExoticComponent<({ color }: SVGIconProps) => JSX.Element>;
    iconPrimaryColor: ENTITY_COLOR;
    iconSecondaryColor: ENTITY_SECONDARY_COLOR;
    navigationPath: string;
};
