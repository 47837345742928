import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthContext } from '@/context/AuthContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { ROUTE } from '@/router/routes';
import { RouterLocation } from '@/types/router';

import { Props } from './types';

const NonSecuredRoute = ({ children }: Props) => {
    const {
        maintenanceConfig: { isInMaintenance },
    } = useFeatureFlagsContext();

    const { isUserAuthenticated } = useAuthContext();

    const location = useLocation();

    const locationState = location.state as RouterLocation.AuthState;

    const isOnNewPasswordPage = location.pathname === ROUTE.newPassword;

    const isAppointmentExternalPAge =
        location.pathname === ROUTE.externalAppointment;

    const isOnMaintenancePage = location.pathname === ROUTE.maintenance;

    if (isInMaintenance && !isOnMaintenancePage) {
        return <Navigate replace to={ROUTE.maintenance} />;
    }

    if (
        !isUserAuthenticated ||
        isOnNewPasswordPage ||
        isOnMaintenancePage ||
        isAppointmentExternalPAge
    ) {
        return children;
    }

    return (
        <Navigate
            replace
            to={locationState?.from?.pathname || ROUTE.dashboard}
        />
    );
};

export default NonSecuredRoute;
