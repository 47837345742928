import { Box } from '@mui/material';
import React from 'react';

import { FinanceOverviewListItem } from './components';
import { Props } from './types';

function FinanceOverviewList({
    currency,
    items,
    onItemClick,
    itemWrapperStyle,
}: Props) {
    return (
        <Box className="gap-0">
            {items.map((item) => {
                return (
                    <FinanceOverviewListItem
                        currency={currency}
                        key={item.id}
                        item={item}
                        onClick={onItemClick}
                        wrapperStyle={itemWrapperStyle || ''}
                    />
                );
            })}
        </Box>
    );
}

export default FinanceOverviewList;
