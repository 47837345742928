import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
    removeItemFromLocalStorage,
} from '@/services/webStorage/localStorage';

function removeOrganisationFromLocalStorage(): void {
    removeItemFromLocalStorage(WEB_STORAGE_KEYS.organisation_id);
}

function addOrganisationToLocalStorage(organisationID: string): void {
    storeItemInLocalStorage(WEB_STORAGE_KEYS.organisation_id, organisationID);
}

function getOrganisationIdFromLocalStorage(): string | null {
    return getItemFromLocalStorage(WEB_STORAGE_KEYS.organisation_id);
}

export {
    removeOrganisationFromLocalStorage,
    addOrganisationToLocalStorage,
    getOrganisationIdFromLocalStorage,
};
