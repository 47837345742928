import { Avatar, Typography, Box } from '@mui/material';
import { t } from 'i18next';
import React, { memo, useMemo } from 'react';
import { getSyncStatus } from 'shared/utils/sync';

import { TeamIcon } from '@/assets/svg';
import { SyncStatusBadge } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useNetwork } from '@/hooks';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function CurrentTeamBox({ organisationName, avatarUrl }: Props) {
    const isOnline = useNetwork();

    const { isSyncInProgress, hasSyncIssue, isUnsynced } = useDBSyncContext();

    const syncStatus = useMemo(
        () =>
            getSyncStatus({
                hasSyncIssue,
                isOnline,
                isSyncInProgress,
                isUnsynced,
            }),
        [hasSyncIssue, isUnsynced, isOnline, isSyncInProgress],
    );

    return (
        <Box
            className="relative flex w-full h-full pt-3"
            data-test-id="OrganisationBox"
            id="organisations-menu-button"
        >
            <Box
                sx={{
                    fontSize: 28,
                    fontWeight: 700,
                    height: 60,
                    width: 60,
                    border: '2px solid',
                    borderColor: ENTITY_COLOR.teams,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className="rounded-lg mx-3"
            >
                <Avatar
                    data-test-id="OrganisationBox-Avatar"
                    src={avatarUrl}
                    sx={{
                        width: 52,
                        height: 52,
                        borderRadius: 0.65,
                        backgroundColor: COLOR.eclipse,
                    }}
                    variant="square"
                >
                    <TeamIcon height={40} width={40} color={'grey'} />
                </Avatar>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        maxWidth: 105,
                    }}
                >
                    <Typography
                        data-test-id="OrganisationBox-OrganisationNameText"
                        fontSize={13}
                        fontWeight={700}
                        variant="body2"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {organisationName}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontSize: 11,
                        fontWeight: 400,
                        color: COLOR.black89,
                    }}
                >
                    {t('Organisations:labels:your_team')}
                </Typography>
                <SyncStatusBadge status={syncStatus} />
            </Box>
        </Box>
    );
}

export default memo(CurrentTeamBox);
