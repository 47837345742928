import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Fade, Skeleton, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { memo, useCallback, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { of } from 'rxjs';
import DatabaseService from 'shared/db/services/Database.web';
import User from 'shared/db/services/User';

import { TeamFilledIcon } from '@/assets/svg';
import { CurrentTeamBox } from '@/components';
import {
    getUserOrganisationId,
    useOrganisationsContext,
} from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { withSyncContext, withUserProfileData } from '@/hoc';
import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import CurrentUserBox from '../CurrentUserBox/CurrentUserBox';

import { SwitchTeamItem } from './components';
import { Props } from './types';

function TeamSwitcher({
    isInitialSyncInProgress,
    isSyncInProgress,
    userProfile,
    organisation,
}: Props) {
    const {
        userOrganisation,
        setCurrentOrganisation,
        userOrganisations,
        userOrganisationsAvatarsUrls,
    } = useOrganisationsContext();

    const { userAvatarUrl, userProfileData } = useUserContext();

    const isTeamCreated = userProfile?.[0]?.isTeamCreated || false;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const hasMultipleTeams = useMemo(
        () => !!userOrganisations && userOrganisations.length > 1,
        [userOrganisations],
    );

    const onExpandTeamsClick = useCallback(
        () => setIsMenuOpen(!isMenuOpen),
        [isMenuOpen],
    );

    const changeOrganisation = useCallback(
        (organisationId: string) => {
            const organisation = userOrganisations?.find(
                (org) => org.id === organisationId,
            );

            if (organisation) {
                setCurrentOrganisation(organisation);
            }
        },
        [setCurrentOrganisation, userOrganisations],
    );

    const userTeams = useMemo(() => {
        if (!userOrganisations || !userOrganisation) {
            return null;
        }

        return userOrganisations.map((org, index) => {
            if (org.id !== userOrganisation.id) {
                return (
                    <SwitchTeamItem
                        key={org.id}
                        isFirstItem={index === 0}
                        organisationId={org.id}
                        organisationName={
                            org.owner
                                ? `${userProfileData?.first_name}${
                                      userProfileData?.last_name
                                          ? ` ${userProfileData.last_name}`
                                          : ''
                                  }`
                                : org.name
                        }
                        organisationImageUrl={
                            org.owner
                                ? userAvatarUrl
                                : userOrganisationsAvatarsUrls?.[org.id] || ''
                        }
                        onSwitchOrganisation={changeOrganisation}
                        isPersonalTeam={org.owner}
                    />
                );
            }
        });
    }, [
        changeOrganisation,
        userAvatarUrl,
        userOrganisation,
        userOrganisations,
        userOrganisationsAvatarsUrls,
        userProfileData?.first_name,
        userProfileData?.last_name,
    ]);

    if (
        (isInitialSyncInProgress && !userOrganisations && !userOrganisation) ||
        !userOrganisation
    ) {
        return (
            <div
                className="absolute top-4 left-4 w-62 h-22 shadow flex items-center bg-white rounded-lg"
                data-test-id="OrganisationBox-Skeletons"
            >
                <Skeleton
                    height={56}
                    sx={{
                        borderRadius: '8px',
                        mr: 2,
                        ml: 2,
                    }}
                    variant="circular"
                    width={56}
                />
                <Skeleton height={20} variant="text" width={130} />
            </div>
        );
    }

    const isDisabled =
        !hasMultipleTeams || isInitialSyncInProgress || isSyncInProgress;

    return (
        <AnimateHeight
            height={
                isMenuOpen
                    ? 'auto'
                    : isTeamCreated || userOrganisation.owner === false
                    ? 95
                    : 85
            }
            duration={200}
            className={`absolute top-4 left-4 w-62 shadow ${
                !isDisabled ? 'hover:cursor-pointer hover:shadow-md' : ''
            } bg-white rounded-lg z-10`}
            aria-controls={isMenuOpen ? 'organisations-menu' : undefined}
            aria-haspopup={true}
            aria-expanded={isMenuOpen ? true : undefined}
            onClick={!isDisabled ? onExpandTeamsClick : undefined}
        >
            <div className="relative">
                <div className="absolute right-1 top-1">
                    {!isDisabled ? (
                        <div className="flex items-center">
                            <TeamFilledIcon
                                width={22}
                                height={14}
                                color={ENTITY_COLOR.teams}
                            />
                            <ArrowDropDownIcon
                                sx={{
                                    transition: 'all 0.2s ease',
                                    transform: isMenuOpen
                                        ? 'rotateZ(180deg)'
                                        : 'rotateZ(0deg)',
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {isTeamCreated || userOrganisation.owner === false ? (
                    <CurrentTeamBox
                        organisationName={
                            organisation?.name || userOrganisation.name
                        }
                        avatarUrl={
                            userOrganisationsAvatarsUrls?.[
                                userOrganisation.id
                            ] ?? ''
                        }
                    />
                ) : (
                    <CurrentUserBox
                        userProfileData={userProfileData}
                        userAvatarUrl={userAvatarUrl}
                    />
                )}
                <Fade
                    timeout={300}
                    in={isMenuOpen}
                    className="mt-3 py-3 rounded-b-lg"
                >
                    <Box
                        sx={{
                            background: `linear-gradient(to right bottom, ${COLOR.whiteDarker}, ${COLOR.white})`,
                        }}
                    >
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 14,
                                fontWeight: 700,
                                color: COLOR.ebonyClay,
                            }}
                        >
                            {t('Organisations:labels:switch_teams')}
                        </Typography>
                        {userTeams}
                    </Box>
                </Fade>
            </div>
        </AnimateHeight>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress', 'userProfileData'],
    ({ isInitialSyncInProgress, userProfileData }) => {
        const database = DatabaseService.getDatabase();

        const userService = new User({
            database,
            imageService: new FirebaseImagesService(),
            logDBAction: Logger.logRecordActivity,
        });

        const organisationId = getUserOrganisationId();

        return {
            userProfile:
                isInitialSyncInProgress || !userProfileData?.id
                    ? of(undefined)
                    : userService.getById(userProfileData?.id),
            organisation:
                isInitialSyncInProgress || !organisationId
                    ? of(undefined)
                    : database.collections
                          .get('organisations')
                          .findAndObserve(organisationId),
        };
    },
);

export default memo(
    withSyncContext<Props>(withUserProfileData(enhance(TeamSwitcher))),
);
