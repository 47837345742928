import { RegionModel } from '../types/Region';

type Region = RegionModel['code'] | undefined;

class RegionStore {
    private static instance: RegionStore | null = null;
    private region: Region = undefined;

    private constructor() {}

    public static getInstance(): RegionStore {
        if (this.instance === null) {
            this.instance = new RegionStore();
        }

        return this.instance;
    }

    setRegion(region: Region): void {
        this.region = region;
    }

    getRegion(): Region {
        return this.region;
    }
}

const regionStore = RegionStore.getInstance();

export default regionStore;
