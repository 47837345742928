export enum InvoicesEvents {
    userCreatedInvoice = 'user_created_invoice',
    userSelectedInvoiceTaxIncluded = 'user_selected_invoice_tax_included',
    userSelectedInvoiceTaxExcluded = 'user_selected_invoice_tax_excluded',
    userAttachedInvoiceReport = 'user_attached_invoice_report',
    userUpdatedInvoice = 'user_updated_invoice',
    userDeletedInvoice = 'user_deleted_invoice',
    userAuthorizedInvoice = 'user_authorized_invoice',
    userClickedSendInvoiceButton = 'user_clicked_send_invoice_button',
    userClickedViewInvoiceButton = 'user_clicked_view_invoice_button',
    userClickedSMSButton = 'user_clicked_sms_button',
    userSetInvoiceToPaid = 'user_set_invoice_to_paid',
    userExportedInvoices = 'user_exported_invoices',
    userFilteredInvoices = 'user_filtered_invoices',
    userUsedInvoicesSearchBar = 'user_used_invoices_search_bar',
    userConnectedWithAccountingProvider = 'user_connected_with_accounting_provider',
    userConnectedWithXero = 'user_connected_with_xero',
    userConnectedWithQuickbooks = 'user_connected_with_quickbooks',
    userDisconnectedAccountingProvider = 'user_disconnected_accounting_provider',
    userDisconnectedWithXero = 'user_disconnected_with_xero',
    userDisconnectedWithQuickbooks = 'user_disconnected_with_quickbooks',
}
