import {
    fetchAndActivate,
    getRemoteConfig,
    getValue,
} from 'firebase/remote-config';
import moment from 'moment';

import { APP_CONFIG } from '@/constants/appConfig';
import { firebaseApp } from '@/services/firebase';

type MaintenanceConfigRaw = {
    isInMaintenance: boolean;
    showToast: boolean;
    from: number;
    to: number;
};

export type MaintenanceConfig = Omit<MaintenanceConfigRaw, 'to' | 'from'> & {
    from: string;
    to: string;
};

const REMOTE_CONFIG_FLAG_KEYS = {
    maintenance: 'maintenance',
    mobileAppVersion: 'mobile_app_version',
    xeroAuth: 'xero_auth',
    invoiceSystem: 'invoice_system',
    USEarlyAccess: 'us_early_access',
};

const remoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.settings.minimumFetchIntervalMillis =
    APP_CONFIG.remoteConfigCacheTimeInMillis;

function getMobileAppVersion() {
    return getValue(
        remoteConfig,
        REMOTE_CONFIG_FLAG_KEYS.mobileAppVersion,
    ).asString();
}

function getAppInMaintenanceConfig(): MaintenanceConfig | null {
    const stringConfig = getValue(
        remoteConfig,
        REMOTE_CONFIG_FLAG_KEYS.maintenance,
    ).asString();

    if (!stringConfig) {
        return null;
    }

    const maintenanceConfigRaw = JSON.parse(
        stringConfig,
    ) as MaintenanceConfigRaw;

    const dateFormat = 'h[:]mm A on MMM Do';

    return {
        ...maintenanceConfigRaw,
        from: moment.utc(maintenanceConfigRaw.from).local().format(dateFormat),
        to: moment.utc(maintenanceConfigRaw.to).local().format(dateFormat),
    };
}

function getXeroAuthConfigValue() {
    return getValue(remoteConfig, REMOTE_CONFIG_FLAG_KEYS.xeroAuth).asBoolean();
}

function getUSEarlyAccessConfigValue() {
    return getValue(
        remoteConfig,
        REMOTE_CONFIG_FLAG_KEYS.USEarlyAccess,
    ).asBoolean();
}

function getInvoiceSystemConfigValue() {
    return getValue(
        remoteConfig,
        REMOTE_CONFIG_FLAG_KEYS.invoiceSystem,
    ).asBoolean();
}

async function fetchAndActivateConfiguration() {
    fetchAndActivate(remoteConfig);
}

export const FirebaseRemoteConfigAPI = {
    remoteConfig,
    getMobileAppVersion,
    fetchAndActivateConfiguration,
    getAppInMaintenanceConfig,
    getXeroAuthConfigValue,
    getInvoiceSystemConfigValue,
    getUSEarlyAccessConfigValue,
};
