import { Avatar, Box, Divider, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { TeamIcon, TeamSwitcherIcon } from '@/assets/svg';
import InitialsAvatar from '@/components/InitialsAvatar';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

function SwitchTeamItem({
    organisationId,
    organisationName,
    organisationImageUrl,
    onSwitchOrganisation,
    isFirstItem,
    isPersonalTeam = false,
}: Props) {
    const organisationFirstName = organisationName.split(' ')[0];
    const organisationLastName = organisationName.split(' ')[1];

    const onSwitchOrganisationClick = useCallback(
        () => onSwitchOrganisation(organisationId),
        [onSwitchOrganisation, organisationId],
    );

    return (
        <div className="mt-2.5" onClick={onSwitchOrganisationClick}>
            {!isFirstItem ? <Divider variant="middle" /> : null}
            <div
                className={`relative flex justify-between items-center h-14 w-full ${
                    !isFirstItem ? 'mt-2.5' : ''
                }`}
            >
                <div className="flex">
                    <Box
                        sx={{
                            fontSize: 28,
                            fontWeight: 700,
                            height: 60,
                            ml: 1.6,
                            width: 60,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        className="rounded-lg"
                    >
                        {isPersonalTeam ? (
                            <InitialsAvatar
                                testID="UserBox-Avatar-Sidebar"
                                avatarUrl={organisationImageUrl ?? ''}
                                size={'54px'}
                                sx={{
                                    backgroundColor: null,
                                    fontSize: '1rem',
                                    fontWeight: 'normal',
                                }}
                                firstName={organisationFirstName}
                                lastName={organisationLastName}
                            />
                        ) : (
                            <Avatar
                                data-test-id="OrganisationBox-Avatar"
                                src={organisationImageUrl ?? ''}
                                sx={{
                                    backgroundColor: COLOR.eclipse,
                                    borderRadius: 0.65,
                                    width: 54,
                                    height: 54,
                                }}
                                variant="rounded"
                            >
                                <TeamIcon
                                    height={40}
                                    width={40}
                                    color={'grey'}
                                />
                            </Avatar>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            ml: 2,
                            maxWidth: 100,
                        }}
                    >
                        <Typography
                            data-test-id="OrganisationBox-OrganisationNameText"
                            fontSize={13}
                            fontWeight={700}
                            variant="body2"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {organisationName}
                        </Typography>
                    </Box>
                </div>
                <div className="flex items-center mr-4">
                    <TeamSwitcherIcon
                        width={30}
                        height={22}
                        color={ENTITY_COLOR.teams}
                    />
                </div>
            </div>
        </div>
    );
}

export default SwitchTeamItem;
