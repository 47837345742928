// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Event extends Model {
    static table = 'events';

    static associations = {
        event_horses: { type: 'has_many', foreignKey: 'event_id' },
        event_contacts: { type: 'has_many', foreignKey: 'event_id' },
        event_users: { type: 'has_many', foreignKey: 'event_id' },
        organisations: { type: 'belongs_to', key: 'organisation_id' },
        locations: { type: 'belongs_to', key: 'location_id' },
    };

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('locations', 'location_id') location;

    @field('title') title;
    @field('address') address;
    @field('starts_time') startsTime;
    @field('ends_time') endsTime;
    @field('notes') notes;
    @field('user_reminder') userReminder;
    @field('send_reminders_to_contacts') contactsReminder;
    @field('send_reminders_to_owners') ownersReminder;
    @field('send_invites_to_contacts') contactsInvite;
    @field('send_invites_to_contacts_at') sendInvitesToContactsAt;
    @field('location_id') locationID;
    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('timezone') timezone;
    @field('category') category;
    @field('is_recurring') isRecurring;
    @field('recurrence_pattern') recurrencePattern;
    @field('recurrence_end_date') recurrenceEndDate;
    @field('original_event_id') originalEventId;

    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    horses = this.collections
        .get('horses')
        .query(Q.on('event_horses', 'event_id', this.id));

    @lazy
    contacts = this.collections
        .get('contacts')
        .query(Q.on('event_contacts', 'event_id', this.id));

    @lazy
    entries = this.collections
        .get('entries')
        .query(Q.where('event_id', this.id));

    @lazy
    eventContactReminders = this.collections
        .get('event_contact_reminders')
        .query(Q.where('event_id', this.id));

    @lazy
    users = this.collections
        .get('users')
        .query(Q.on('event_users', 'event_id', this.id));
}
