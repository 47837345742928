import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ShoppingListsAnalytics } from 'shared/analytics/shoppingLists';
import Organisation from 'shared/db/services/Organisation';
import ShoppingListProduct from 'shared/db/services/ShoppingListProduct';
import { ShoppingListProductSize } from 'shared/types/ShoppingList';
import { ShoppingListProductChange } from 'shared/types/ShoppingListProduct';
import { updateShoppingListProductSize } from 'shared/utils/shoppingList';

import { EmptyState } from '@/components';
import SelectedProductsListItem from '@/components/SelectedProductsListItem';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useUserContext } from '@/context/UserContext';
import { useDebouncedValue } from '@/hooks';
import { useDatabase } from '@/hooks/useDatabase';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

import { Props } from './types';

function ProductsList({
    shoppingListId,
    shoppingListProducts,
    onRemoveButtonClick,
    isLoading,
    showInventory,
    addedToInventory,
    refetchData,
}: Props) {
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const loading = useMemo(
        () => isLoading && isSyncInProgress,
        [isLoading, isSyncInProgress],
    );

    const [shoppingListProductChanges, setShoppingListProductChanges] =
        useState<ShoppingListProductChange[]>([]);
    const [
        shoppingListProductChangesQueue,
        setShoppingListProductChangesQueue,
    ] = useState<ShoppingListProductChange[]>([]);
    const [isUpdatingQuantities, setIsUpdatingQuantities] = useState(false);

    const handleQuantityChange = useCallback(
        async (changesQueue: ShoppingListProductChange[]) => {
            try {
                if (!userProfileData?.id || loading) {
                    throw new Error();
                }

                const shoppingListProductService = new ShoppingListProduct({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const organisationService = new Organisation({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const updatedShoppingListProducts =
                    await updateShoppingListProductSize({
                        changes: changesQueue,
                        shoppingListId,
                        userId: userProfileData.id,
                        shoppingListProductService,
                        organisationService,
                    });

                ShoppingListsAnalytics.logUserUpdatedShoppingListProducts(
                    FirebaseAnalytics.logEvent,
                    {
                        changes: changesQueue,
                        shoppingListProducts: updatedShoppingListProducts,
                    },
                );

                Snackbar.showToastNotification({
                    message: t(
                        'SingleShoppingList:alert:shopping_list_product_edited',
                    ),
                });

                setShoppingListProductChangesQueue([]);
                refetchData();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('Inventory:errors:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
            setIsUpdatingQuantities(false);
        },
        [
            ImagesService,
            database,
            loading,
            refetchData,
            shoppingListId,
            userProfileData,
        ],
    );

    const debouncedShoppingListProductChangesQueue = useDebouncedValue(
        shoppingListProductChanges,
    );

    useEffect(() => {
        if (debouncedShoppingListProductChangesQueue.length) {
            setShoppingListProductChangesQueue((changes) => [
                ...changes,
                ...debouncedShoppingListProductChangesQueue,
            ]);
            setShoppingListProductChanges([]);
        }
    }, [debouncedShoppingListProductChangesQueue]);

    useEffect(() => {
        if (
            shoppingListProductChangesQueue.length &&
            !loading &&
            !isUpdatingQuantities
        ) {
            setIsUpdatingQuantities(true);
            handleQuantityChange(shoppingListProductChangesQueue);
        }
    }, [
        handleQuantityChange,
        loading,
        isUpdatingQuantities,
        shoppingListProductChangesQueue,
    ]);

    const handleOnProductQuantityChange = useCallback(
        (quantity: number, productSize: ShoppingListProductSize) =>
            setShoppingListProductChanges((changes) => [
                ...changes.filter(
                    (change) =>
                        change.productSize.productId !== productSize.productId,
                ),
                { quantity, productSize },
            ]),
        [],
    );

    const handleChangeUnitType = useCallback(
        async (
            selectedUnit: string,
            productSize: ShoppingListProductSize,
            productSalesUnitId: string,
        ) => {
            try {
                if (!userProfileData || loading) {
                    throw new Error();
                }

                const shoppingListProductService = new ShoppingListProduct({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const organisationService = new Organisation({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                await updateShoppingListProductSize({
                    changes: [
                        {
                            quantity: productSize.quantity,
                            productSize,
                            productSalesUnitId,
                            selectedUnit,
                        },
                    ],
                    shoppingListId,
                    userId: userProfileData.id ?? '',
                    shoppingListProductService,
                    organisationService,
                });

                Snackbar.showToastNotification({
                    message: t(
                        'SingleShoppingList:alert:shopping_list_product_edited',
                    ),
                });

                await refetchData();
            } catch (err) {
                Snackbar.showToastNotification({
                    message: t('Inventory:errors:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            ImagesService,
            database,
            loading,
            refetchData,
            shoppingListId,
            userProfileData,
        ],
    );

    return !shoppingListProducts.length ? (
        <EmptyState message={t('ShoppingList:products:no_products')} />
    ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {shoppingListProducts.map((structuredProduct) => (
                <SelectedProductsListItem
                    key={structuredProduct.chosenProductId}
                    onRemoveButtonClick={onRemoveButtonClick}
                    onQuantityChange={handleOnProductQuantityChange}
                    onUnitTypeChange={handleChangeUnitType}
                    isLoading={loading || isUpdatingQuantities}
                    testIdPrefix="ShoppingListPage"
                    isExpandable
                    isShoppingListProducts
                    showInventory={showInventory}
                    addedToInventory={addedToInventory}
                    boxUnitSize={
                        structuredProduct.productAttributes.boxUnitSize
                    }
                    productSizes={structuredProduct.productSizes}
                    materialShapeAndClips={
                        structuredProduct.productAttributes
                            .materialShapeAndClips
                    }
                    name={structuredProduct.productAttributes.name}
                    type={structuredProduct.productAttributes.productType}
                />
            ))}
        </Box>
    );
}

export default ProductsList;
