import { Box } from '@mui/material';
import React from 'react';

import { Props } from './types';

function Spotlight({ width, height, sx }: Props) {
    return (
        <Box
            sx={{
                position: 'absolute',
                zIndex: 9999,
                width,
                height: `calc(${height} + 6px)`,
                boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
                pointerEvents: 'none',
                filter: 'blur(15px)',
                boxSizing: 'content-box',
                ...sx,
            }}
        />
    );
}

export default Spotlight;
