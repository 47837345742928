import React, { memo } from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

function TeamSwitcherIcon({
    color = COLOR.paleSky,
    height = 25,
    width = 25,
}: Props) {
    return (
        <svg width={width} height={height} fill={color}>
            <g data-name="Group 520">
                <g
                    data-name="Rectangle 335"
                    transform="translate(1 1)"
                    fill="none"
                    stroke="#4d4d4d"
                >
                    <rect
                        width={23.478}
                        height={14.337}
                        rx={7.168}
                        stroke="none"
                    />
                    <rect
                        x={-0.5}
                        y={-0.5}
                        width={24.478}
                        height={15.337}
                        rx={7.668}
                    />
                </g>
                <path d="M9.593 12.032 6.66 9.704l2.933-2.33.638.507-1.839 1.461h4.8v.724h-4.8l1.839 1.461Zm6.292-3.067-.638-.507 1.839-1.461h-4.8v-.724h4.8l-1.839-1.461.638-.507 2.933 2.33Z" />
            </g>
        </svg>
    );
}

export default memo(TeamSwitcherIcon);
