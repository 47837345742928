import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { ZIndex } from '@/constants/zIndex';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function BackdropLoader({ isLoading, sx, loadingText, testID }: Props) {
    return (
        <Backdrop
            open={isLoading}
            sx={{
                backgroundColor: COLOR.black60,
                opacity: 1,
                zIndex: ZIndex.index10,
                color: COLOR.white,
                ...sx,
            }}
            data-test-id={testID}
        >
            <Box className="flex flex-col items-center justify-center">
                <CircularProgress sx={{ color: 'inherit' }} />
                {loadingText ? (
                    <Typography color="inherit" sx={{ mt: 4 }}>
                        {loadingText}
                    </Typography>
                ) : null}
            </Box>
        </Backdrop>
    );
}

export default BackdropLoader;
