import { Theme } from '@mui/material/styles';

function Input(theme: Theme) {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.disabled,
                },
            },
        },
    };
}

export default Input;
