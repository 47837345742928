// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import { field, date, readonly, lazy } from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Question extends Model {
    static table = 'questions';

    static associations = {
        answers: { type: 'has_many', foreignKey: 'question_id' },
    };

    @field('content') content;
    @field('description') description;
    @field('extra_action') extraAction;
    @field('is_multiselect') isMultiSelect;
    @field('mandatory') mandatory;
    @field('question_order') questionOrder;
    @field('question_version') questionVersion;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    answers = this.collections
        .get('answers')
        .query(Q.where('question_id', this.id));
}
