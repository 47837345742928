import { useCallback } from 'react';
import Database from 'shared/db/services/Database.web';

export function useDatabase() {
    const getDatabase = useCallback(() => {
        return Database.getDatabase();
    }, []);

    return { getDatabase };
}
