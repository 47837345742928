import { ErrorOutlineRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { t } from '@/services/translations/config';

import { Props } from './types';

function ErrorState({ onRefreshClick, testID }: Props) {
    return (
        <Box className="flex flex-col items-center mt-20 py-16">
            <Box className="flex items-center mb-3">
                <ErrorOutlineRounded className="mb-0.5 mr-2" />
                <Typography data-test-id={testID} variant="subtitle2">
                    {t('App:Messages:something_went_wrong_short')}
                </Typography>
            </Box>
            <Button onClick={onRefreshClick}>{t('Actions:refresh')}</Button>
        </Box>
    );
}

export default ErrorState;
