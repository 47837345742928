import { LogEvent } from '../../types/analytics';
import { AppEvents } from './events';

function logUserLoggedIn(logEvent: LogEvent) {
    logEvent(AppEvents.userLoggedIn);
}

function logUserLoggedOut(logEvent: LogEvent) {
    logEvent(AppEvents.userLoggedOut);
}

function logUserLoggedOutSessionExpired(logEvent: LogEvent) {
    logEvent(AppEvents.userLoggedOutSessionExpired);
}

function logUserRegistered(logEvent: LogEvent) {
    logEvent(AppEvents.userRegistered);
}

function logUserSetNewPassword(logEvent: LogEvent) {
    logEvent(AppEvents.userSetNewPassword);
}

function logUserSyncedCalendar(logEvent: LogEvent) {
    logEvent(AppEvents.userSyncedCalendar);
}

function logUserUsedCleanSync(logEvent: LogEvent) {
    logEvent(AppEvents.userUsedCleanSync);
}

function logUserSentFeedback(
    logEvent: LogEvent,
    params: { feedbackCategory: string },
) {
    logEvent(AppEvents.userSentFeedback, {
        feedbackCategory: params.feedbackCategory,
    });
}

export const AppAnalytics = {
    logUserLoggedIn,
    logUserLoggedOut,
    logUserLoggedOutSessionExpired,
    logUserRegistered,
    logUserSetNewPassword,
    logUserSyncedCalendar,
    logUserUsedCleanSync,
    logUserSentFeedback,
};
