import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

function RemoveContactAlert({
    dialogText,
    isOpen,
    onClose,
    onRemove,
    testID,
    cancelMessage,
    removeMessage,
}: Props) {
    const { t } = useTranslation();

    const cancelButtonMessage = cancelMessage || t('Actions:cancel');
    const removeButtonMessage = removeMessage || t('Actions:remove');

    const removeAndCloseDialog = useCallback(() => {
        onRemove();
        onClose();
    }, [onClose, onRemove]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-test-id={testID}
        >
            <DialogTitle>{t('Actions:confirm')}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    dangerouslySetInnerHTML={{ __html: dialogText }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{cancelButtonMessage}</Button>
                <Button
                    onClick={removeAndCloseDialog}
                    color="error"
                    autoFocus
                    data-test-id={`${testID}-RemoveButton`}
                >
                    {removeButtonMessage}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RemoveContactAlert;
