import { Box, Typography } from '@mui/material';
import React from 'react';

import { COLOR } from '@/theme/colors';

import { Props } from './types';

export function MenuCategory({ isActive, caption, onClick, testID }: Props) {
    return (
        <Box
            data-test-id={testID}
            className={`flex items-center justify-start h-12 mb-0.5 pl-4 rounded-lg hover:cursor-pointer shadow-lg w-48 ${
                isActive
                    ? 'bg-blue-50 hover:bg-blue-200/50'
                    : 'hover:bg-blue-50/50'
            }`}
            onClick={onClick}
        >
            <Typography
                color={isActive ? COLOR.deepCerulean : COLOR.paleSky}
                fontWeight={700}
                variant="body2"
            >
                {caption}
            </Typography>
        </Box>
    );
}
