export enum WEB_STORAGE_KEYS {
    firebase_token = '@equinet_app/firebase_token',
    apply_tax_to_invoice = '@equinet_app/apply_tax_to_invoice',
    locale = '@equinet_app/locale',
    organisation_id = '@equinet_app/organisation_id',
    entry_alert_dont_show = '@equinet_app/entry_alert_dont_show',
    procedures_alert_dont_show = '@equinet_app/entry_procedures_dont_show',
    lastBirthDate = '@equinet_app/last_birthdate',
    last_user_email = '@equinet_app/last_user_email',
    user_tried_to_connect_to_xero = '@equinet_app/user_tried_to_connect_to_xero',
    last_pulled_at = '@equinet_app/last_pulled_at',
}
