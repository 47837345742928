import { Box, Typography } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EntryProcedureToAdd } from 'shared/types/Entries';
import { getGroupedProcedures, ProceduresGroup } from 'shared/utils/procedures';

import { CollapsibleContainer } from '@/components';
import { COLOR } from '@/theme/colors';

import { SelectableProceduresListItem } from './components';
import { Props } from './types';

function SelectableProceduresGroupedList({
    clickable,
    editable,
    selectable,
    items,
    selectedItems,
    onItemClick,
    onEditButtonClick,
    onRemove,
    removable,
    itemWrapperStyles,
    testIdPrefix,
    backgroundColor,
    itemHeight,
    showQuantity = true,
    groupProcedures = false,
    showTotalPrice,
    showPrice = true,
}: Props) {
    const { t } = useTranslation();

    const groupedProcedures = useMemo<ProceduresGroup<EntryProcedureToAdd>[]>(
        () => getGroupedProcedures<EntryProcedureToAdd>(items),
        [items],
    );

    const renderProcedureItem = useCallback(
        (procedure: EntryProcedureToAdd) => (
            <SelectableProceduresListItem
                editable={editable}
                selectable={selectable}
                procedure={procedure}
                isSelected={selectedItems && selectedItems.has(procedure)}
                key={procedure.procedureId}
                testID={`${testIdPrefix}-ProceduresListItem`}
                onEditButtonClick={onEditButtonClick}
                onClick={onItemClick}
                onRemove={onRemove}
                wrapperStyles={itemWrapperStyles}
                removable={removable}
                clickable={clickable}
                backgroundColor={backgroundColor}
                itemHeight={itemHeight}
                showQuantity={showQuantity}
                showTotalPrice={showTotalPrice}
                showPrice={showPrice}
            />
        ),
        [
            backgroundColor,
            clickable,
            editable,
            itemHeight,
            itemWrapperStyles,
            onEditButtonClick,
            onItemClick,
            onRemove,
            removable,
            selectable,
            selectedItems,
            showPrice,
            showQuantity,
            showTotalPrice,
            testIdPrefix,
        ],
    );

    return groupProcedures ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {groupedProcedures.map((proceduresGroup) => (
                <CollapsibleContainer
                    testID={`${testIdPrefix}-${proceduresGroup.title}`}
                    title={proceduresGroup.title}
                    key={proceduresGroup.title}
                    auxiliaryElement={
                        <Typography color={COLOR.paleSky}>
                            {(
                                proceduresGroup.procedures.length ?? 0
                            ).toString()}
                        </Typography>
                    }
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {proceduresGroup.procedures.length ? (
                            <>
                                {proceduresGroup.procedures.map((procedure) =>
                                    renderProcedureItem(procedure),
                                )}
                            </>
                        ) : (
                            <Typography
                                data-test-id={`${testIdPrefix}-EmptyStateMessage`}
                                variant="body2"
                                color={COLOR.paleSky}
                                sx={{ mt: 2, alignSelf: 'center' }}
                            >
                                {t('Procedures:no_procedures_have_been_added')}
                            </Typography>
                        )}
                    </Box>
                </CollapsibleContainer>
            ))}
        </Box>
    ) : (
        <>{items.map((procedure) => renderProcedureItem(procedure))}</>
    );
}

export default memo(SelectableProceduresGroupedList) as (
    props: Props,
) => JSX.Element;
