import { Database } from '@nozbe/watermelondb';
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';

import Answer from '../models/Answer';
import Contact from '../models/Contact';
import DeviceCalendarEvent from '../models/DeviceCalendarEvent';
import Entry from '../models/Entry';
import EntryProcedure from '../models/EntryProcedure';
import EntryProduct from '../models/EntryProduct';
import EntryUser from '../models/EntryUser';
import Event from '../models/Event';
import EventContact from '../models/EventContact';
import EventContactReminder from '../models/EventContactReminder';
import EventHorse from '../models/EventHorse';
import EventUser from '../models/EventUser';
import Horse from '../models/Horse';
import HorseContacts from '../models/HorseContacts';
import InventoryChange from '../models/InventoryChange';
import InventoryProduct from '../models/InventoryProduct';
import Invoice from '../models/Invoice';
import Location from '../models/Location';
import Organisation from '../models/Organisation';
import OrganisationAccountings from '../models/OrganisationAccountings';
import OrganisationUser from '../models/OrganisationUser';
import OrganisationUserColor from '../models/OrganisationUserColor';
import Procedure from '../models/Procedure';
import Product from '../models/Product';
import ProductSalesUnit from '../models/ProductSalesUnit';
import ProductSalesUnitRegion from '../models/ProductSalesUnitRegion';
import Question from '../models/Question';
import Region from '../models/Region';
import ShoppingList from '../models/ShoppingList';
import ShoppingListProduct from '../models/ShoppingListProduct';
import User from '../models/User';
import UserAnswer from '../models/UserAnswer';
import UserSettings from '../models/UserSettings';
import migrations from './migrations';
import schema from './schema';
import RsvpOnboarding from '../models/RsvpOnboarding';
import InvoiceInvoicingProduct from '../models/InvoiceInvoicingProduct';

const DBservice = (): Database => {
    const adapter = new LokiJSAdapter({
        schema,
        migrations,
        useWebWorker: false,
        useIncrementalIndexedDB: true,
    });

    const database = new Database({
        adapter,
        modelClasses: [
            Entry,
            EntryProduct,
            Event,
            EventHorse,
            EventContact,
            EventContactReminder,
            Horse,
            InventoryProduct,
            InventoryChange,
            Product,
            ShoppingList,
            ShoppingListProduct,
            User,
            Contact,
            HorseContacts,
            Location,
            Organisation,
            OrganisationAccountings,
            OrganisationUser,
            OrganisationUserColor,
            UserSettings,
            Procedure,
            EntryProcedure,
            Invoice,
            InvoiceInvoicingProduct,
            Region,
            ProductSalesUnit,
            ProductSalesUnitRegion,
            DeviceCalendarEvent,
            EntryUser,
            EventUser,
            Answer,
            Question,
            UserAnswer,
            RsvpOnboarding,
        ],
    });

    return database;
};

export default DBservice;
