import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Button } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryAnalytics } from 'shared/analytics/inventory';
import InventoryChangeService from 'shared/db/services/InventoryChange';
import InventoryProductService from 'shared/db/services/InventoryProduct';
import Product from 'shared/db/services/Product';
import { InventoryChangeModel } from 'shared/types/InventoryChange';
import { InventoryProductSize } from 'shared/types/Products';
import { sumInventoryChanges } from 'shared/utils/inventory';

import {
    AddProductToShoppingListModal,
    SelectedProductsListItem,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase, useDebouncedValue } from '@/hooks';
import { InventoryChange } from '@/pages/InventoryPage/types';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';

import { Props } from './types';

function InventoryProductsList({
    inventoryProducts,
    onRemoveButtonClick,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation } = useOrganisationsContext();

    const [inventoryChanges, setInventoryChanges] = useState<InventoryChange[]>(
        [],
    );
    const [inventoryChangesQueue, setInventoryChangesQueue] = useState<
        InventoryChange[]
    >([]);
    const [isSelectShoppingListModalOpen, setIsSelectShoppingListModalOpen] =
        useState(false);
    const [isUpdatingQuantities, setIsUpdatingQuantities] = useState(false);
    const [
        productSizeToBeAddedToShoppingList,
        setProductSizeToBeAddedToShoppingList,
    ] = useState<InventoryProductSize | null>(null);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const handleQuantityChange = useCallback(
        async (changesQueue: InventoryChange[]) => {
            try {
                const productService = new Product({
                    database,
                    logDBAction: Logger.logRecordActivity,
                    logError: Logger.logError,
                    imageService: ImagesService,
                });

                const inventoryProductService = new InventoryProductService({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const inventoryChangesService = new InventoryChangeService({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                for (const { quantity, productSize } of changesQueue) {
                    const currentQuantityChanges = await database.collections
                        .get<InventoryChangeModel>('inventory_changes')
                        .query(
                            Q.where(
                                'inventory_product_id',
                                productSize?.inventoryProductId || '',
                            ),
                        )
                        .fetch();

                    const currentQuantityChangeValue = sumInventoryChanges(
                        currentQuantityChanges,
                    );

                    if (
                        productSize.quantity + currentQuantityChangeValue ===
                            quantity ||
                        !userProfileData?.id
                    ) {
                        continue;
                    }

                    if (productSize.inventoryProductId) {
                        const quantityChange =
                            quantity -
                            productSize.quantity -
                            currentQuantityChangeValue;
                        await inventoryChangesService.add(
                            {
                                inventoryProductId:
                                    productSize.inventoryProductId,
                                quantityChange,
                                processAt: new Date(),
                            },
                            userProfileData.id,
                        );

                        const inventoryProduct =
                            await inventoryProductService.getByID(
                                productSize.inventoryProductId,
                            );

                        InventoryAnalytics.logUserChangedAmountOfProductInInventory(
                            FirebaseAnalytics.logEvent,
                            {
                                inventoryProduct,
                                quantityChange,
                                productService,
                            },
                        );
                    } else {
                        const createdInventoryProduct =
                            await inventoryProductService.add(
                                {
                                    productId: productSize.productId,
                                    favourite: false,
                                    quantity: 0,
                                },
                                userProfileData?.id || '',
                            );

                        await inventoryChangesService.add(
                            {
                                inventoryProductId: createdInventoryProduct.id,
                                quantityChange: quantity,
                                processAt: new Date(),
                            },
                            userProfileData?.id || '',
                        );

                        InventoryAnalytics.logUserAddedProductToInventory(
                            FirebaseAnalytics.logEvent,
                            {
                                inventoryProduct: createdInventoryProduct,
                                productService,
                                isOwner: userOrganisation?.owner ?? true,
                            },
                        );
                    }
                }

                setInventoryChangesQueue([]);
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
            setIsUpdatingQuantities(false);
        },
        [
            database,
            ImagesService,
            userProfileData?.id,
            t,
            userOrganisation?.owner,
        ],
    );

    const debouncedInventoryChangesQueue = useDebouncedValue(inventoryChanges);

    useEffect(() => {
        if (debouncedInventoryChangesQueue.length) {
            setInventoryChangesQueue((changes) => [
                ...changes,
                ...debouncedInventoryChangesQueue,
            ]);

            setInventoryChanges([]);
        }
    }, [debouncedInventoryChangesQueue]);

    const onQuantityChange = useCallback(
        (quantity: number, productSize: InventoryProductSize) =>
            setInventoryChanges((changes) => [
                ...changes.filter(
                    (change) =>
                        change.productSize.productId !== productSize.productId,
                ),
                { quantity, productSize },
            ]),
        [],
    );

    useEffect(() => {
        if (
            inventoryChangesQueue.length &&
            !isSyncInProgress &&
            !isUpdatingQuantities
        ) {
            setIsUpdatingQuantities(true);
            handleQuantityChange(inventoryChangesQueue);
        }
    }, [
        handleQuantityChange,
        inventoryChangesQueue,
        isSyncInProgress,
        isUpdatingQuantities,
    ]);

    const openSelectShoppingListModal = useCallback(() => {
        setIsSelectShoppingListModalOpen(true);
    }, []);

    const closeSelectShoppingListModal = useCallback(() => {
        setProductSizeToBeAddedToShoppingList(null);
        setIsSelectShoppingListModalOpen(false);
    }, []);

    const handleAddToShoppingListButtonClick = useCallback(
        (productSize: InventoryProductSize) => () => {
            setProductSizeToBeAddedToShoppingList(productSize);
            openSelectShoppingListModal();
        },
        [openSelectShoppingListModal],
    );

    const renderCustomSizesTableColumn = useCallback(
        (productSize: InventoryProductSize) => {
            return (
                <Button
                    className="min-w-0"
                    data-test-id="InventoryPage-ProductSizeTableItem-AddToShoppingBasketButton"
                    onClick={handleAddToShoppingListButtonClick(productSize)}
                    size="small"
                    startIcon={<AddShoppingCartIcon />}
                    sx={{ fontWeight: 700 }}
                >
                    {t('Inventory:button:add_to_shopping_list')}
                </Button>
            );
        },
        [handleAddToShoppingListButtonClick, t],
    );

    return (
        <>
            {inventoryProducts.map((inventoryProduct) => (
                <SelectedProductsListItem
                    key={inventoryProduct.stockProductId}
                    onRemoveButtonClick={onRemoveButtonClick}
                    onQuantityChange={onQuantityChange}
                    isLoading={isUpdatingQuantities || isSyncInProgress}
                    testIdPrefix="InventoryPage"
                    renderCustomSizesTableColumn={renderCustomSizesTableColumn}
                    isExpandable
                    isShoppingListProducts={false}
                    type={inventoryProduct.type}
                    name={inventoryProduct.name}
                    materialShapeAndClips={
                        inventoryProduct.materialShapeAndClips
                    }
                    productSizes={inventoryProduct.productSizes}
                />
            ))}
            {isSelectShoppingListModalOpen &&
            productSizeToBeAddedToShoppingList ? (
                <AddProductToShoppingListModal
                    close={closeSelectShoppingListModal}
                    productSizeToAdd={productSizeToBeAddedToShoppingList}
                />
            ) : null}
        </>
    );
}

export default InventoryProductsList;
