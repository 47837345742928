import {
    Avatar,
    Box,
    capitalize,
    MenuItem,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { ROLE } from 'shared/types/OrganisationUser';

import { Button, Modal } from '@/components';
import TooltipSyncButton from '@/components/TooltipButton';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useEntityImage } from '@/hooks/useEntityImage';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Props } from './types';

const permissionsText: SxProps = {
    fontSize: 14,
    lineHeight: '26px',
    color: COLOR.ebonyClay,
    display: 'inline',
};

function EditMemberModal({ onCancel, member, onSubmit }: Props) {
    const { isSyncInProgress } = useDBSyncContext();

    const [role, setRole] = useState<ROLE>(member.role);

    const image = useEntityImage(member.id);
    const avatarUrl = image?.imageURL || '';

    const onRoleChange = useCallback<
        ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >((e) => {
        setRole(e.target.value as ROLE);
    }, []);

    const handleSubmit = useCallback(() => {
        onSubmit({
            ...member,
            role,
        });
    }, [member, onSubmit, role]);

    return (
        <Modal
            center
            isOpen
            styles="flex max-w-2xl items-center justify-center"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mx: '32px',
                    width: '578px',
                }}
            >
                <Typography
                    sx={{ fontSize: 16, fontWeight: 'bold', mb: '26px' }}
                >
                    {t('EditMemberModal:edit_member_role', {
                        defaultValue: 'Edit Member Role',
                    })}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            sx={{
                                height: '64px',
                                width: '64px',
                            }}
                            src={avatarUrl}
                        />
                        <Typography
                            sx={{
                                ml: '16px',
                                fontSize: 16,
                                fontWeight: 'bold',
                                lineHeight: '21px',
                            }}
                        >
                            {member.name}
                        </Typography>
                    </Box>

                    <TextField
                        size="small"
                        sx={{
                            maxWidth: '236px',
                            height: '44px',
                        }}
                        className="flex-1"
                        select
                        data-test-id="EditMemberModal-RoleSelect"
                        id="role"
                        name="role"
                        onChange={onRoleChange}
                        value={role}
                    >
                        <MenuItem
                            data-test-id={'AddEditContactForm-RoleItemMember'}
                            key={ROLE.member}
                            value={ROLE.member}
                        >
                            {capitalize(ROLE.member)}
                        </MenuItem>
                        <MenuItem
                            data-test-id={'AddEditContactForm-RoleItemAdmin'}
                            key={ROLE.admin}
                            value={ROLE.admin}
                        >
                            {capitalize(ROLE.admin)}
                        </MenuItem>
                    </TextField>
                </Box>

                <Box
                    sx={{
                        mt: '26px',
                        width: '100%',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'light',
                                fontSize: 14,
                                lineHeight: '26px',
                                mb: '24px',
                            }}
                        >
                            {t('EditMemberModal:assign_role')}
                        </Typography>

                        <Typography
                            sx={{
                                ...permissionsText,
                                fontWeight: 'bold',
                            }}
                        >
                            {t('EditMemberModal:admins')}
                            {': '}
                        </Typography>

                        <Typography
                            sx={{
                                ...permissionsText,
                                fontWeight: 'light',
                            }}
                        >
                            {t('EditMemberModal:admins_permissions')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                ...permissionsText,
                                fontWeight: 'bold',
                            }}
                        >
                            {t('EditMemberModal:members')}
                            {': '}
                        </Typography>
                        <Typography
                            sx={{
                                ...permissionsText,
                                fontWeight: 'light',
                            }}
                        >
                            {t('EditMemberModal:members_permissions')}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: '50px',
                        }}
                    >
                        <Button
                            caption={t('Actions:cancel')}
                            onClick={onCancel}
                        />
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            showTooltip={isSyncInProgress}
                            disabled={isSyncInProgress}
                            sx={{
                                backgroundColor: ENTITY_COLOR.teams,
                                color: COLOR.whiteDarker2,
                                ml: 1,
                                '&:hover': {
                                    backgroundColor: ENTITY_COLOR.teams,
                                },
                                width: '108px',
                            }}
                            variant={'contained'}
                            onClick={handleSubmit}
                        >
                            {t('Actions:update', { defaultValue: 'Update' })}
                        </TooltipSyncButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default EditMemberModal;
