import {
    Box,
    Button,
    CircularProgress,
    Divider,
    TextField,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { COUNTRY } from 'shared/constants/localization/types';

import { PhoneNumber } from '@/components';
import { ServerAuthAPI } from '@/services/networking/auth';
import { Snackbar } from '@/services/toastNotifications';
import { ResetPasswordFormValues } from '@/types/auth';
import { AuthValidationSchema } from '@/validations/auth';

import {
    ACTIVE_FIELD,
    INTERNAL_SERVER_ERROR,
    RESET_PASSWORD_STATUS,
    UNPROCESSABLE_ENTITY_STATUS,
} from './types';

const initialValues: ResetPasswordFormValues = {
    email: '',
    phone: '',
    prefix: '',
};

const TEXT_FIELD_HEIGHT = 48;

const ForgotPasswordForm = () => {
    const [status, setStatus] = useState<RESET_PASSWORD_STATUS>(
        RESET_PASSWORD_STATUS.START,
    );
    const [activeField, setActiveField] = useState<ACTIVE_FIELD>(
        ACTIVE_FIELD.NONE,
    );

    const handleErrorMessage = useCallback(
        (statusCode: number): string => {
            switch (statusCode) {
                case UNPROCESSABLE_ENTITY_STATUS:
                    return t(
                        `ForgotPassword:error:${
                            activeField === ACTIVE_FIELD.EMAIL
                                ? 'email_not_found'
                                : 'phone_not_found'
                        }`,
                    );
                case INTERNAL_SERVER_ERROR:
                default:
                    return t('ForgotPassword:error:generic');
            }
        },
        [activeField],
    );

    const handleOnSubmit = useCallback(
        (values: ResetPasswordFormValues) => {
            setStatus(RESET_PASSWORD_STATUS.PENDING);

            const { email, phone, prefix } = values;

            if (!values.phone && !values.email) {
                setStatus(RESET_PASSWORD_STATUS.START);
                return;
            }

            const params = email
                ? { email }
                : {
                      phone_prefix: '+'.concat(prefix),
                      phone: phone.substring(prefix.length + 1),
                  };

            ServerAuthAPI.resetPassword(params)
                .then(() => setStatus(RESET_PASSWORD_STATUS.FINISHED))
                .catch((error) => {
                    setStatus(RESET_PASSWORD_STATUS.START);
                    Snackbar.showToastNotification({
                        message: handleErrorMessage(error.response?.status),
                        options: {
                            variant: 'error',
                        },
                    });
                });
        },
        [handleErrorMessage],
    );

    const form = useFormik<ResetPasswordFormValues>({
        initialValues,
        onSubmit: handleOnSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.resetPasswordForm,
    });

    const onEmailChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            const activeField =
                e.target.value.length == 0
                    ? ACTIVE_FIELD.NONE
                    : ACTIVE_FIELD.EMAIL;
            setActiveField(activeField);
            form.handleChange(e);
        },
        [form],
    );

    const onPhoneNumberChange = useCallback(
        (
            phone: string,
            data: {
                name: string;
                dialCode: string;
                countryCode: string;
            },
        ) => {
            const prefixLength = 1 + data.dialCode?.length;
            const isPhoneEmpty = phone.length <= prefixLength;

            if (activeField === ACTIVE_FIELD.EMAIL) return;

            const active = isPhoneEmpty
                ? ACTIVE_FIELD.NONE
                : ACTIVE_FIELD.PHONE;
            setActiveField(active);

            form.setFieldValue('phone', isPhoneEmpty ? '' : phone);
            form.setFieldValue('prefix', data.dialCode);
        },
        [form, activeField],
    );

    const handleSignUpButtonClick = useCallback(() => {
        form.handleSubmit();
    }, [form]);

    const FormView = (
        <>
            <Box
                component="form"
                sx={{
                    maxWidth: 480,
                    ml: { xs: 4, md: 10 },
                    mr: { xs: 4, md: 0 },
                    mt: 4,
                }}
            >
                <TextField
                    error={!!form.errors.email && !!form.touched.email}
                    fullWidth
                    helperText={form.touched.email ? form.errors.email : ''}
                    id="email"
                    inputProps={{
                        'data-test-id': 'ResetPasswordPage-EmailInput',
                    }}
                    InputProps={{
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('SignUp:label:email_address')}
                    name="email"
                    onBlur={form.handleBlur}
                    onChange={onEmailChange}
                    sx={{ mb: 3 }}
                    value={form.values.email}
                    disabled={activeField === ACTIVE_FIELD.PHONE}
                />
                <Divider sx={{ fontSize: 14, mb: 3 }}>
                    {t('ForgotPassword:body:divider')}
                </Divider>
                <PhoneNumber
                    error={!!form.errors.phone && !!form.touched.phone}
                    fullWidth
                    helperText={form.touched.phone ? form.errors.phone : ''}
                    id="phone"
                    inputProps={{
                        'data-test-id': 'ResetPasswordPage-PhoneInput',
                    }}
                    InputProps={{
                        style: {
                            height: TEXT_FIELD_HEIGHT,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    label={t('SignUp:label:phone_number')}
                    name="phone"
                    onBlur={form.handleBlur}
                    onChange={onPhoneNumberChange}
                    disabled={activeField === ACTIVE_FIELD.EMAIL}
                    defaultCountry={COUNTRY.AU}
                    sx={{ mb: 3 }}
                />
                <Button
                    data-test-id="ResetPasswordPage-SubmitButton"
                    disabled={
                        status !== RESET_PASSWORD_STATUS.START ||
                        activeField == ACTIVE_FIELD.NONE
                    }
                    fullWidth
                    onClick={(e) => {
                        e.preventDefault();
                        handleSignUpButtonClick();
                    }}
                    size="large"
                    sx={{ fontWeight: 700, height: 48, mb: 3 }}
                    type="submit"
                    variant="contained"
                >
                    {status === RESET_PASSWORD_STATUS.PENDING ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        <Typography>
                            {t('ForgotPassword:button:send')}
                        </Typography>
                    )}
                </Button>
            </Box>
        </>
    );

    const SuccessMessageView = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                maxWidth: 480,
                ml: { xs: 4, md: 10 },
                mr: { xs: 4, md: 0 },
                mt: 4,
            }}
            data-test-id="ResetPasswordPage-SuccessView"
        >
            <Typography fontWeight={700}>
                {t('ForgotPassword:success:message1')}
            </Typography>
            <Typography>{t('ForgotPassword:success:message2')}</Typography>
        </Box>
    );

    const renderContent = () => {
        switch (status) {
            case RESET_PASSWORD_STATUS.FINISHED:
                return SuccessMessageView;
            case RESET_PASSWORD_STATUS.START:
            case RESET_PASSWORD_STATUS.PENDING:
            default:
                return FormView;
        }
    };

    return renderContent();
};

export default ForgotPasswordForm;
