import React, { createContext, createRef, useContext, useState } from 'react';

import { ViewType } from '@/components/Calendar/types';
import { Context } from '@/types/context';

const CalendarContext = createContext<Context.Calendar.Value>({
    view: 'agenda',
    setView: () => null,
});

const calendarContextRef: React.MutableRefObject<Context.Calendar.Value | null> =
    createRef();

const CalendarProvider = ({ children }: Context.Calendar.Props) => {
    const [view, setView] = useState<ViewType>('agenda');

    const contextValue: Context.Calendar.Value = {
        view,
        setView,
    };

    calendarContextRef.current = contextValue;

    return (
        <CalendarContext.Provider value={contextValue}>
            {children}
        </CalendarContext.Provider>
    );
};

function useCalendarContext() {
    const calendarContext = useContext(CalendarContext);
    return calendarContext;
}

export { CalendarContext, CalendarProvider, useCalendarContext };
