import { datadogLogs } from '@datadog/browser-logs';
import { SeverityLevel } from '@sentry/react';
import * as logLevel from 'loglevel';
import moment from 'moment';
import { DBWriterLoggerFunctionParams } from 'shared/types/logger';

import Sentry from '@/services/sentry';

const logTimestampFormat = 'YYYY-MM-DD HH:mm:ss';

const originalFactory = logLevel.methodFactory;
(logLevel.methodFactory as any) = function (methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);

    return function ({
        message,
        modelName,
        messageLevel,
        payload,
    }: {
        message: string;
        messageLevel: SeverityLevel | 'sentryInfo';
        modelName?: string;
        payload?: any;
    }) {
        const jsonPayload = payload ? JSON.stringify(payload, null, '\t') : '';
        const jsonPayloadSubstring = jsonPayload ? `\n${jsonPayload}` : '';

        const modelNameSubstring = modelName ? `${modelName} | ` : '';

        const timestamp = moment().format(logTimestampFormat);

        const logString = `${timestamp} | ${messageLevel.toUpperCase()} | ${modelNameSubstring}${message}${jsonPayloadSubstring}`;

        if (process.env.__DEV__) {
            rawMethod(logString);
        } else if (
            messageLevel === 'error' ||
            messageLevel === 'sentryInfo' ||
            messageLevel === 'warning'
        ) {
            Sentry.recordError(
                logString,
                messageLevel === 'sentryInfo' ? 'info' : messageLevel,
            );
        }
    };
};

logLevel.setLevel(
    process.env.__DEV__ ? logLevel.levels.DEBUG : logLevel.levels.INFO,
);

function logRecordActivity({
    message,
    modelName,
    payload,
}: DBWriterLoggerFunctionParams) {
    let _payload;

    if (typeof payload === 'string') {
        _payload = payload;
    } else if (Array.isArray(payload)) {
        _payload = payload.map((m) => m._raw);
    } else {
        _payload = payload._raw;
    }
    logLevel.info({
        message,
        modelName,
        payload: _payload,
        messageLevel: 'info',
    });
    datadogLogs.logger.info(message, {
        modelName,
        payload: _payload,
        messageLevel: 'info',
    });
}

function logInfo(message: string) {
    logLevel.info({ message, messageLevel: 'info' });
    datadogLogs.logger.info(message);
}

function logSentryInfo(message: string) {
    logLevel.info({ message, messageLevel: 'sentryInfo' });
    datadogLogs.logger.info(message);
}

function logError(message: string) {
    logLevel.error({ message, messageLevel: 'error' });
    datadogLogs.logger.error(message);
}

export default { logInfo, logError, logRecordActivity, logSentryInfo };
