import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import {
    EventContactReminderModel,
    EventContactReminderPayload,
} from '../../types/EventContactReminder';

class EventContactReminder {
    private database: Database;
    private collection: Collection<EventContactReminderModel>;
    private table = 'event_contact_reminders';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: EventContactReminderPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        await this.database.write(async () => {
            const createdEventContactReminder = await this.collection.create(
                (eventContactReminder) => {
                    eventContactReminder.eventId = payload.eventId;
                    eventContactReminder.timeBefore = payload.timeBefore;
                    eventContactReminder.eventContactId =
                        payload.eventContactId;
                    eventContactReminder.userId = userId;
                    eventContactReminder.organisationId = organisationID;
                },
            );

            this.options.logDBAction({
                message: 'Create event contact reminder',
                modelName: this.table,
                payload: createdEventContactReminder,
            });
        });
    }

    async deleteByID(id: string) {
        const eventContactReminderElement = await this.getByID(id);

        await this.database.write(async () => {
            await eventContactReminderElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete event contact reminder',
                modelName: this.table,
                payload: eventContactReminderElement,
            });
        });
    }
}

export default EventContactReminder;
