import { t } from 'i18next';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import { VAT_DESCRIPTION } from 'shared/constants/invoiceVatDescription';
import { InvoiceModel } from 'shared/types/Invoice';

import { COLOR } from '@/theme/colors';

import { isFutureDate, overdueDaysCalculation } from './date';

export const calculateInvoiceVATLabel = (invoice: InvoiceModel): string => {
    const _vatDescription = {
        [VAT_DESCRIPTION.WITH_VAT]: t('Invoicing:includingGST', {
            defaultValue: 'Including GST',
        }),
        [VAT_DESCRIPTION.WITHOUT_VAT]: t('Invoicing:excludingGST', {
            defaultValue: 'Excluding GST',
        }),
    };

    let vatText;
    let invoiceLabel;

    if (invoice.applyTaxes) {
        invoiceLabel = `${t('Invoicing:total')}`;
        vatText = VAT_DESCRIPTION.WITH_VAT;
    } else {
        invoiceLabel = `${t('Invoicing:subTotal')}`;
        vatText = VAT_DESCRIPTION.WITHOUT_VAT;
    }

    return `${invoiceLabel} ${_vatDescription[vatText]}`;
};

export const calculateInvoicePriceSum = ({
    priceSum,
    invoice,
    currency,
    taxIncluded,
}: {
    priceSum: number;
    invoice: InvoiceModel;
    currency: string;
    taxIncluded?: boolean;
}): string => {
    let price = '';

    const invoiceTotal = taxIncluded ? invoice.total : invoice.subTotal;
    const invoiceTotalNumber = invoiceTotal && Number(invoiceTotal);

    if (typeof invoiceTotalNumber === 'number' && !isNaN(invoiceTotalNumber)) {
        price = `${currency ? currency : '$'} ${invoiceTotalNumber.toFixed(2)}`;
    } else {
        price = `${currency ? currency : '$'} ${priceSum.toFixed(2)}`;
    }
    return price;
};

export const calculateOverdue = (
    overdueDate: string,
    isDateInTheFuture: boolean,
): string => {
    const overdueDays = overdueDaysCalculation(overdueDate);

    if (overdueDays === -1) {
        return '-';
    } else {
        return `${overdueDays.toFixed(0)} ${t('InvoicesPage:days')} ${
            !isDateInTheFuture ? t('InvoicesPage:ago') : ''
        }`;
    }
};

export const getOverdueColor = (dueDateTime: string) => {
    const isDateInTheFuture = isFutureDate(dueDateTime);

    return isDateInTheFuture ? COLOR.warningYellow : COLOR.errorRed;
};

export const getOverdueWithColor = (
    dueDateTime: string,
    status: string,
): { value: string; color: string } => {
    if (status === INVOICE_STATUS.paid) {
        return { value: '-', color: COLOR.white };
    }

    return {
        value: calculateOverdue(dueDateTime, isFutureDate(dueDateTime)),
        color: getOverdueColor(dueDateTime),
    };
};
