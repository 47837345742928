import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { InitialsAvatar } from '@/components';
import { COLOR } from '@/theme/colors';

import { AttendeeItemProps, Props } from './types';

function AttendeesSectionItem({ attendee, isCancelled }: AttendeeItemProps) {
    const [firstName, lastName] = useMemo(() => {
        const splittedArray = attendee.name.split(' ');
        return [splittedArray[0], splittedArray[splittedArray.length - 1]];
    }, [attendee.name]);

    const { status, statusIcon, statusTextColor } = useMemo(() => {
        switch (attendee.status) {
            case 'awaiting':
            default:
                return {
                    status: t(
                        'ExternalAppointment:labels:attendee_status_awaiting',
                    ),
                    statusIcon: (
                        <HelpIcon
                            sx={{
                                width: '20px',
                                height: '20px',
                                color: COLOR.avatarGrey,
                            }}
                        />
                    ),
                    statusTextColor: COLOR.paleSky,
                };
            case 'accepted':
                return {
                    status: t(
                        'ExternalAppointment:labels:attendee_status_accepted',
                    ),
                    statusIcon: (
                        <CheckCircleIcon
                            sx={{
                                width: '20px',
                                height: '20px',
                                color: COLOR.confirmGreen,
                            }}
                        />
                    ),
                    statusTextColor: COLOR.black,
                };
            case 'rejected':
                return {
                    status: t(
                        'ExternalAppointment:labels:attendee_status_rejected',
                    ),
                    statusIcon: (
                        <EventBusyIcon
                            sx={{
                                width: '20px',
                                height: '20px',
                                color: COLOR.errorRed,
                            }}
                        />
                    ),
                    statusTextColor: COLOR.errorRed,
                };
        }
    }, [attendee.status]);

    return (
        <div className="flex flex-row items-center rounded-tl-[28px] rounded-tr mt-3 rounded-b-md bg-backgroundGrey">
            <div className="self-start">
                <InitialsAvatar
                    avatarUrl={''}
                    firstName={firstName}
                    lastName={lastName}
                    size={74}
                    sx={{
                        fontWeight: 'light',
                        fontSize: '20px',
                        mr: 2,
                        backgroundColor: COLOR.avatarGrey,
                        borderWidth: '1px',
                        borderColor: COLOR.white,
                        opacity: isCancelled ? 0.2 : 1,
                        color: COLOR.white,
                    }}
                />
            </div>
            <div className="py-3 pr-2">
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        opacity: isCancelled ? 0.2 : 1,
                    }}
                >
                    {attendee.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '26px',
                        opacity: isCancelled ? 0.2 : 1,
                    }}
                >
                    {`${attendee.phone}/${attendee.email}`}
                </Typography>
                {!isCancelled ? (
                    <div className="flex flex-row items-center gap-1 rounded-md">
                        {statusIcon}
                        <Typography
                            sx={{
                                background:
                                    status === 'Confirmed'
                                        ? `linear-gradient(${COLOR.englishLightGreen10}, ${COLOR.englishVividGreen10})`
                                        : null,
                                fontSize: '12px',
                                fontWeight: '400',
                                lineHeight: '26px',
                                px: 1,
                                color: statusTextColor,
                            }}
                        >
                            {status}
                        </Typography>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

function AttendeesSection({ attendees, isCancelled, style }: Props) {
    if (!attendees) {
        return null;
    }

    return (
        <div className="pt-7">
            <div className={twMerge('border-b-[1px] border-borderGrey', style)}>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        color: COLOR.ebonyClay,
                    }}
                >
                    {t('ExternalAppointment:labels:attendees_title', {
                        length: attendees.length,
                    })}
                </Typography>
            </div>
            <div className="pt-4">
                {attendees.map((attendee, index) => (
                    <AttendeesSectionItem
                        key={index}
                        attendee={attendee}
                        isCancelled={isCancelled}
                    />
                ))}
            </div>
        </div>
    );
}

export default AttendeesSection;
