import { ProductModel, ProductType } from '../types/Products';
import { sortBy } from 'lodash';
import {
    BoxUnitSizesType,
    ParsedShoppingListProduct,
    SelectedUnitType,
    ShoppingProductParserParams,
} from '../types/ShoppingList';
import {
    NAILS_DESCRIPTION_CHANGE_TRESHOLD,
    PRODUCT_TYPE_RESTRICTIONS,
    PRODUCT_UNIT_TO_ALIAS_MAP,
    PRODUCT_UNIT_TYPES_MAP,
} from '../constants/products/productUnitSizes';
import { SHOPPING_PRODUCT_UNIT } from '../constants/shoppingProductUnit';
import ProductSalesUnit from '../db/services/ProductSalesUnit';
import { ShoppingListProductModel } from '../types/ShoppingListProduct';

export const _availableUnitsByProductType = (productType: ProductType) => {
    switch (productType) {
        case ProductType.shoes:
            return ['pair'];
        case ProductType.nails:
            return ['pack'];
        case ProductType.rasps:
            return ['rasp'];
        case ProductType.tools:
        case ProductType.other:
        case ProductType.custom:
            return ['piece'];
        default:
            return ['box', 'piece'];
    }
};

export const mapBoxUnitTypes = (productType: ProductType, str: string) => {
    if (productType === ProductType.rasps) {
        return SHOPPING_PRODUCT_UNIT.rasp;
    } else if ([ProductType.tools, ProductType.other].includes(productType)) {
        return SHOPPING_PRODUCT_UNIT.piece;
    } else {
        const unitNoWhitespace = str.replace(/\s/g, '');
        if (PRODUCT_UNIT_TO_ALIAS_MAP[unitNoWhitespace]) {
            return PRODUCT_UNIT_TO_ALIAS_MAP[unitNoWhitespace];
        } else {
            return str;
        }
    }
};

export const mapDescriptionToCustomTresholds = (
    productType: ProductType,
    boxUnitSize: string,
) => {
    const splittedBoxUnitSize = boxUnitSize.split(' ');
    const boxUnit = splittedBoxUnitSize[1] || '';
    const boxUnitValue = Number(boxUnit);
    if (
        productType === ProductType.nails &&
        boxUnitValue &&
        boxUnitValue > NAILS_DESCRIPTION_CHANGE_TRESHOLD.threshold
    ) {
        return `${NAILS_DESCRIPTION_CHANGE_TRESHOLD.description}`;
    }

    return `${PRODUCT_UNIT_TYPES_MAP[productType]}`;
};

export const calculateUnitTypeDisplayName = (
    unit: string,
    type: ProductType,
    boxUnitSize?: number,
): string => {
    let unitValue = unit;
    if (unitValue === 'box' && boxUnitSize) {
        unitValue = `${unitValue} ${boxUnitSize}`;
    }
    if (Object.keys(SHOPPING_PRODUCT_UNIT).includes(unitValue)) {
        return SHOPPING_PRODUCT_UNIT[unitValue];
    }
    return mapBoxUnitTypes(
        type,
        `${mapDescriptionToCustomTresholds(type, unitValue)} ${
            unitValue.split(' ')[1]
        }`,
    );
};

/*
    This method checks for the sent treshold so we know when to remove unit sizes.
    Examples:
     -remove pairs if shoe has a unit size <= 4
     -remove packs if nails has a unit size <= 250
 */

export const _shouldRemoveDefaultUnitSizeByThreshold = (
    productType: ProductType,
    boxUnitSizes: BoxUnitSizesType | null,
    sortedUnits: Array<{ name: string; order: number }>,
) => {
    if (
        [
            ProductType.custom,
            ProductType.rasps,
            ProductType.other,
            ProductType.tools,
        ].includes(productType)
    ) {
        return sortedUnits;
    }

    const { threshold, propertyToRemove } =
        PRODUCT_TYPE_RESTRICTIONS[productType];

    if (boxUnitSizes) {
        const shouldRemoveDefaultBoxUnitSize = Object.values(boxUnitSizes).some(
            (boxUnitSizeValue) => boxUnitSizeValue <= threshold,
        );

        return shouldRemoveDefaultBoxUnitSize
            ? sortedUnits.filter((el) => el.name !== propertyToRemove)
            : sortedUnits;
    }
    return sortedUnits;
};

export async function calculateProductBoxUnitSize(
    database,
    params: ShoppingProductParserParams,
    productId: string,
) {
    const productSalesUnitRegionService = new ProductSalesUnit({
        database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
        logError: params.logError,
    });

    const salesUnits =
        await productSalesUnitRegionService.getByUserRegionAndProductID(
            params.userId || '',
            productId,
        );

    if (!salesUnits.length) {
        return undefined;
    } else {
        const boxUnitSizes = {};
        salesUnits.forEach(
            (unit) => (boxUnitSizes[unit.id] = unit.boxUnitSize),
        );
        return boxUnitSizes;
    }
}

export async function calculateProductSelectedUnit(
    database,
    params: ShoppingProductParserParams,
    product: ProductModel,
    shoppingListProduct?: ShoppingListProductModel,
): Promise<[string | undefined, number] | [string | undefined]> {
    const productSalesUnitRegionService = new ProductSalesUnit({
        database,
        imageService: params.imageService,
        logDBAction: params.logDBAction,
        logError: params.logError,
    });

    const unitType =
        shoppingListProduct?.unitType ??
        getDefaultUnitTypeByProductType(product.productType);

    if (
        unitType === 'box' &&
        (product.productType === ProductType.shoes ||
            product.productType === ProductType.nails)
    ) {
        let salesUnit;
        if (shoppingListProduct?.productSalesUnitId) {
            [salesUnit] =
                await productSalesUnitRegionService.getByUserRegionAndID(
                    params.userId || '',
                    shoppingListProduct.productSalesUnitId,
                );
        } else {
            [salesUnit] =
                await productSalesUnitRegionService.getByUserRegionAndProductID(
                    params.userId || '',
                    product.id,
                );
        }

        if (salesUnit) {
            return [
                `${unitType} ${salesUnit.boxUnitSize}`,
                salesUnit.boxUnitSize,
            ];
        }
    }

    return [unitType];
}

export function calculateAvailableUnits(
    boxUnitSizes: BoxUnitSizesType | null,
    type: ProductType,
) {
    const availableUnits: string[] = [];

    if (
        ![
            ProductType.custom,
            ProductType.rasps,
            ProductType.other,
            ProductType.tools,
        ].includes(type) &&
        boxUnitSizes
    ) {
        Object.values(boxUnitSizes!).forEach((productBoxUnitSize) => {
            availableUnits.push(`box ${productBoxUnitSize}`);
        });
    } else {
        availableUnits.push(..._availableUnitsByProductType(type));
    }

    let sortedUnits = sortBy(
        availableUnits.map((el) => {
            return {
                name: el.toLocaleLowerCase(),
                order: Number(el.replace(/^\D+/g, '')),
            };
        }),
        'order',
    );

    sortedUnits = _shouldRemoveDefaultUnitSizeByThreshold(
        type,
        boxUnitSizes,
        sortedUnits,
    );

    const resultSortedUnits = sortedUnits.reduce(
        (acc, curr) => [...acc, curr.name],
        [],
    );
    return resultSortedUnits;
}

export const _sortUnits = (
    chosenProductId: string,
    productSizeId: string,
    productType: ProductType,
    productsPayload: ParsedShoppingListProduct[],
) => {
    const availableUnits = _availableUnitsByProductType(productType);
    const products = [...productsPayload];
    const productIndex = products.findIndex(
        (prod) => prod.chosenProductId === chosenProductId,
    );
    const productSizeIndex = products[productIndex].productSizes.findIndex(
        (prodSize) => prodSize.productId === productSizeId,
    );
    const editedProduct = products[productIndex];
    const productSizes = [...editedProduct.productSizes];

    if (
        ![
            ProductType.custom,
            ProductType.rasps,
            ProductType.other,
            ProductType.tools,
        ].includes(productType)
    ) {
        if (!!productSizes[productSizeIndex].boxUnitSizes) {
            Object.values(productSizes[productSizeIndex].boxUnitSizes!).map(
                (productBoxUnitSize) => {
                    availableUnits.push(`box ${productBoxUnitSize}`);
                },
            );
        } else {
            availableUnits.push('box');
        }
    }

    let sortedUnits = sortBy(
        availableUnits.map((el) => {
            return {
                name: el.toLocaleLowerCase(),
                order: Number(el.replace(/^\D+/g, '')),
            };
        }),
        'order',
    );

    sortedUnits = _shouldRemoveDefaultUnitSizeByThreshold(
        productType,
        productSizes[productSizeIndex].boxUnitSizes,
        sortedUnits,
    );

    const resultSortedUnits = sortedUnits.reduce(
        (acc, curr) => [...acc, curr.name],
        [],
    );

    return {
        products,
        sortedUnits: resultSortedUnits,
        productIndex,
        productSizeIndex,
        productSizes,
    };
};

export function getDefaultUnitByProductType(
    productType: ProductType,
): [string, number] | [string] {
    switch (productType) {
        case ProductType.shoes:
            return ['box 2', 2];
        case ProductType.nails:
            return ['box 250', 250];
        case ProductType.rasps:
            return ['rasp'];
        case ProductType.tools:
        case ProductType.other:
        case ProductType.custom:
            return ['piece'];
        default:
            return ['box'];
    }
}

export function getDefaultUnitTypeByProductType(
    productType: ProductType,
): string | undefined {
    switch (productType) {
        case ProductType.shoes:
            return 'box' as SelectedUnitType;
        case ProductType.nails:
            return 'box' as SelectedUnitType;
        case ProductType.rasps:
            return 'rasp' as SelectedUnitType;
        case ProductType.tools:
        case ProductType.other:
        case ProductType.custom:
            return 'piece' as SelectedUnitType;
        default:
            return undefined;
    }
}

export function parseAvailableUnits(
    availableUnits: string[],
    productType: ProductType,
    boxUnitSize?: number,
) {
    return availableUnits
        .map((unit) => {
            const value = calculateUnitTypeDisplayName(
                unit,
                productType,
                boxUnitSize,
            );
            const option = {
                id: unit,
                displayName: value,
                translate: false as false,
                value: unit,
            };
            return option;
        })
        .reduce(
            (unitsArray, unit) => {
                const exist = unitsArray.find((u) => u.id === unit.id);
                if (!exist) {
                    unitsArray.push(unit);
                }
                return unitsArray;
            },
            [] as {
                id: string;
                displayName: string;
                translate: false;
                value: string;
            }[],
        );
}
