import { Add, CancelRounded, TuneRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { ContactsAnalytics } from 'shared/analytics/contacts';
import { CONTACTS_FILTER } from 'shared/constants/contacts/filters';
import Contact from 'shared/db/services/Contact';
import Database from 'shared/db/services/Database.web';
import { ContactModel } from 'shared/types/Contacts';
import { ContactsFiltersObject } from 'shared/types/contactsFilters';
import { FILTER_TYPE } from 'shared/types/filter';
import { SortObject } from 'shared/types/sort';

import {
    AddEditContactModal,
    AddEditHorseModal,
    Button,
    Pagination,
    SearchInput,
    TableSkeleton,
} from '@/components';
import { APP_CONTENT_ID } from '@/constants/documentIds';
import { useContactsFiltersContext } from '@/context/ContactsFiltersContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { calculatePaginatedRows, PAGE_LIMIT } from '@/helpers/pagination';
import { withSyncContext } from '@/hoc';
import {
    useDebouncedValue,
    useScrollToDocumentTopOnValueChange,
    useUnmount,
} from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { ContactsTable, FiltersPanel } from './components';
import { Props } from './types';

const pageStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const tableStyles: SxProps<Theme> = {
    mr: 3,
    flexGrow: 1,
};

function ContactsPage({ totalRecords, contacts }: Props) {
    const {
        filtersState,
        searchState,
        setSearchState,
        sortState,
        setSortState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        setFiltersState,
        setIsFiltersPanelVisible,
    } = useContactsFiltersContext();
    const { isInitialSyncInProgress } = useDBSyncContext();
    const { ImagesService } = useImagesContext();
    const navigate = useNavigate();

    const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
    const [isContactsDataLoading, setIsContactsDataLoading] = useState(false);
    const [filteredContacts, setFilteredContacts] = useState<ContactModel[]>(
        [],
    );

    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState<number>(
        totalRecords ? totalRecords : 0,
    );

    const handlePageChange = useCallback(
        (_, page: number) => setPage(page),
        [],
    );

    useScrollToDocumentTopOnValueChange(APP_CONTENT_ID, page);

    const contactService = useMemo(
        () =>
            new Contact({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService],
    );

    const openAddContactModal = useCallback(() => {
        setIsAddContactModalOpen(true);
    }, []);

    const closeAddContactModal = useCallback(() => {
        setIsAddContactModalOpen(false);
    }, []);

    const toggleFiltersPanel = useCallback(() => {
        setIsFiltersPanelVisible((prev) => !prev);
    }, [setIsFiltersPanelVisible]);

    const fetchContacts = useCallback(
        async (
            searchText: string,
            filters: ContactsFiltersObject,
            sort: SortObject | null,
        ) => {
            setIsContactsDataLoading(true);

            try {
                const filteredContactsLength =
                    await contactService.getFilteredContactsCount({
                        searchText,
                        filters,
                        filterType: FILTER_TYPE.LOKI,
                    });

                setRowsCount(filteredContactsLength);

                let paginatedContacts: ContactModel[] = [];
                if (filteredContactsLength > 0) {
                    const contacts = await contactService.getFilteredContacts({
                        filterType: FILTER_TYPE.LOKI,
                        searchText,
                        sort,
                        filters,
                    });
                    paginatedContacts = calculatePaginatedRows(page, contacts);
                }
                setFilteredContacts(paginatedContacts);
            } finally {
                setIsContactsDataLoading(false);
            }
        },
        [contactService, page],
    );

    const handleFiltersChange = useCallback(
        (filterType: CONTACTS_FILTER) => (value: string[]) => {
            setFiltersState((prev) => ({ ...prev, [filterType]: value }));
        },
        [setFiltersState],
    );

    const handleSearchInputChange = useCallback(
        (value: string) => setSearchState(value),
        [setSearchState],
    );

    const clearSearchText = useCallback(
        () => setSearchState(''),
        [setSearchState],
    );

    const filtersStateDebounced = useDebouncedValue(filtersState, 300);
    const sortStateDebounced = useDebouncedValue(sortState, 300);
    const isAnyFilterActiveDebounced = useDebouncedValue(
        isAnyFilterActive,
        300,
    );
    const searchTextDebounced = useDebouncedValue(searchState);

    useEffect(() => setPage(1), [filtersStateDebounced, searchTextDebounced]);

    useEffect(() => {
        if (searchTextDebounced) {
            ContactsAnalytics.logUserUsedContactsSearchBar(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [searchTextDebounced]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchContacts(
                searchTextDebounced,
                filtersStateDebounced,
                sortStateDebounced,
            );
        }
    }, [
        fetchContacts,
        filtersStateDebounced,
        isInitialSyncInProgress,
        searchTextDebounced,
        sortStateDebounced,
    ]);

    useUnmount(() => {
        if (!isAnyFilterActive && isFiltersPanelVisible) {
            setIsFiltersPanelVisible(false);
        }
    });

    const refetchContacts = useCallback(() => {
        fetchContacts(
            searchTextDebounced,
            filtersStateDebounced,
            sortStateDebounced,
        );
    }, [
        fetchContacts,
        filtersStateDebounced,
        searchTextDebounced,
        sortStateDebounced,
    ]);

    const navigateToCreatedContact = useCallback(
        (contact: ContactModel) => {
            const route = getRoutePath(ROUTE.contact, { id: contact.id });
            navigate(route);
        },
        [navigate],
    );

    const showSearchEmptyState =
        filteredContacts.length === 0 && contacts && contacts.length > 0;
    const showRegularEmptyState =
        filteredContacts.length === 0 && contacts?.length === 0;

    const emptyStateMessage = showSearchEmptyState
        ? t('ContactsList:no_contact_found_blankslate')
        : t('ContactsList:no_contacts_has_been_added');

    const showLoadingState =
        (!contacts?.length && isContactsDataLoading) || isInitialSyncInProgress;

    return (
        <Box sx={pageStyles}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <SearchInput
                            onChange={handleSearchInputChange}
                            onClear={clearSearchText}
                            testId="ContactsPage-SearchInput"
                            value={searchState}
                        />
                        <Button
                            caption={t('Actions:filter')}
                            color={isAnyFilterActive ? 'primary' : 'inherit'}
                            onClick={toggleFiltersPanel}
                            startIcon={<TuneRounded />}
                            sx={{
                                backgroundColor: isAnyFilterActive
                                    ? COLOR.deepCerulean08
                                    : undefined,
                                ml: 3,
                            }}
                            testID="ContactsPage-FilterButton"
                        />
                        {isAnyFilterActive ? (
                            <Button
                                caption={t('Actions:clearFilters')}
                                onClick={resetFilters}
                                startIcon={
                                    <CancelRounded htmlColor={COLOR.paleSky} />
                                }
                                sx={{
                                    color: COLOR.paleSky,
                                    fontWeight: 400,
                                    ml: 3,
                                }}
                                testID="ContactsPage-ClearFiltersButton"
                            />
                        ) : null}
                    </Box>
                    <Button
                        caption={t('ContactsList:button:add_contact')}
                        onClick={openAddContactModal}
                        startIcon={<Add />}
                        testID="ContactsPage-AddContactButton"
                    />
                </Box>
                {isFiltersPanelVisible ? (
                    <Box sx={{ mb: 4 }}>
                        <FiltersPanel
                            filtersState={filtersState}
                            onFiltersChange={handleFiltersChange}
                        />
                    </Box>
                ) : null}
            </Box>

            <Box sx={tableStyles}>
                {showLoadingState ? (
                    <TableSkeleton data-test-id="ContactsPage-TableSkeleton" />
                ) : (
                    <ContactsTable
                        emptyStateMessage={emptyStateMessage}
                        refetchContacts={refetchContacts}
                        showEmptyStateHint={showRegularEmptyState}
                        sortState={sortState}
                        sortChange={setSortState}
                        rows={
                            searchTextDebounced ||
                            isAnyFilterActiveDebounced ||
                            sortStateDebounced ||
                            page > 1
                                ? filteredContacts
                                : contacts ?? []
                        }
                    />
                )}
            </Box>
            {rowsCount > 0 && (
                <Pagination
                    testID="ContactsPage-Pagination"
                    page={page}
                    recordsCount={rowsCount}
                    onChange={handlePageChange}
                />
            )}
            {isAddContactModalOpen ? (
                <AddEditContactModal
                    close={closeAddContactModal}
                    onSave={navigateToCreatedContact}
                    CreateHorseModal={AddEditHorseModal}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        const queries = [Q.where('hidden', false)];

        const contactRecords = database.collections
            .get('contacts')
            .query(...queries)
            .observeCount();

        const contacts = database.collections
            .get('contacts')
            .query(...queries, Q.skip(0), Q.take(PAGE_LIMIT));

        return {
            totalRecords: contactRecords,
            contacts: isInitialSyncInProgress ? of() : contacts,
        };
    },
);

export default withSyncContext<Props>(enhance(ContactsPage));
