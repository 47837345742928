import { Collection, Database, Q } from '@nozbe/watermelondb';

import Organisation from './Organisation';
import {
    InventoryChangeModel,
    InventoryChangePayload,
} from '../../types/InventoryChange';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class InventoryChange {
    private database: Database;
    private collection: Collection<InventoryChangeModel>;
    private table = 'inventory_changes';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async add(payload: InventoryChangePayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const createdInventoryChange = await this.collection.create(
                (inventoryChange) => {
                    inventoryChange.inventoryProductId =
                        payload.inventoryProductId;
                    inventoryChange.quantityChange = payload.quantityChange;
                    inventoryChange.userId = userId;
                    inventoryChange.organisationId = organisationID;
                    inventoryChange.processAt = payload.processAt;
                },
            );

            this.options.logDBAction({
                message: 'Create inventory change',
                modelName: this.table,
                payload: createdInventoryChange,
            });

            return createdInventoryChange;
        });
    }

    async prepareAdd(payload: InventoryChangePayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.collection.prepareCreate((inventoryChange) => {
            inventoryChange.inventoryProductId = payload.inventoryProductId;
            inventoryChange.quantityChange = payload.quantityChange;
            inventoryChange.userId = userId;
            inventoryChange.organisationId = organisationID;
            inventoryChange.processAt = payload.processAt;
        });
    }
}

export default InventoryChange;
