import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIREBASE_AUTH_ERROR_CODE } from 'shared/constants/firebase/errors';

import { SignInFlowServerErrorAlert, UnveilPasswordButton } from '@/components';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { SignInFormValues } from '@/types/auth';
import { AuthProvider } from '@/types/firebase';
import { handleSignInFlowError } from '@/utils/auth';
import { AuthValidationSchema } from '@/validations/auth';

import { Props } from './types';

const TEXT_FIELD_HEIGHT = 48;

function LinkProviderAccountDialog({
    language,
    handleXeroSignIn,
    cancel,
    email,
}: Props) {
    const [showPassword, setShowPassword] = useState(false);
    const [authErrorCode, setAuthErrorCode] = useState<
        number | FIREBASE_AUTH_ERROR_CODE | null
    >(null);
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(
        null,
    );
    const [isAuthInProgress, setIsAuthInProgress] = useState(false);

    const initialValues: SignInFormValues = useMemo(
        () => ({
            email: email ?? '',
            rememberMe: true,
            language,
            password: '',
        }),
        [email, language],
    );

    const { t } = useTranslation();

    const handleShowPasswordIconClick = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    const linkToXero = useCallback(
        async (values: SignInFormValues) => {
            const { email, password } = values;

            try {
                setIsAuthInProgress(true);
                await FirebaseAuthAPI.signIn({ email, password });
                await FirebaseAuthAPI.linkProvider(AuthProvider.Xero);
                handleXeroSignIn(values, true);
            } catch (error) {
                handleSignInFlowError({
                    error,
                    setAuthErrorCode,
                    setAuthErrorMessage,
                });
            } finally {
                setIsAuthInProgress(false);
            }
        },
        [handleXeroSignIn],
    );

    const form = useFormik<SignInFormValues>({
        initialValues,
        onSubmit: linkToXero,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: AuthValidationSchema.signInForm,
    });

    const handleSubmit = useCallback(() => form.handleSubmit(), [form]);

    return (
        <Dialog open>
            <DialogTitle>{t('SignIn:provider:link:title')}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ whiteSpace: 'pre-wrap', mb: 4 }}>
                    {t('SignIn:provider:link:description')}
                </DialogContentText>
                <Box component="form" onSubmit={form.handleSubmit}>
                    {authErrorCode || authErrorMessage ? (
                        <SignInFlowServerErrorAlert
                            error={{
                                errorCode: authErrorCode,
                                message: authErrorMessage
                                    ? authErrorMessage
                                    : undefined,
                            }}
                            testId="SignInPage-ErrorAlert"
                        />
                    ) : null}
                    <TextField
                        data-test-id="SignInPage-EmailInput"
                        error={!!form.errors.email && !!form.touched.email}
                        fullWidth
                        helperText={form.touched.email ? form.errors.email : ''}
                        id="linkEmail"
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        label={t('SignIn:label:email_address')}
                        name="email"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ mb: 3.5 }}
                        value={form.values.email}
                    />
                    <TextField
                        data-test-id="SignInPage-PasswordInput"
                        error={
                            !!form.errors.password && !!form.touched.password
                        }
                        fullWidth
                        helperText={
                            form.touched.password ? form.errors.password : ''
                        }
                        id="linkPassword"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: {
                                height: TEXT_FIELD_HEIGHT,
                            },
                            endAdornment: (
                                <UnveilPasswordButton
                                    isPasswordVisible={showPassword}
                                    onClick={handleShowPasswordIconClick}
                                />
                            ),
                        }}
                        label={t('SignIn:label:password')}
                        name="password"
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        sx={{ mb: 1.5 }}
                        type={showPassword ? 'text' : 'password'}
                        value={form.values.password}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}>{t('Actions:cancel')}</Button>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isAuthInProgress}
                >
                    {isAuthInProgress ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        t('SignIn:provider:link:confirm')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LinkProviderAccountDialog;
