import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import { EXPORT_INVOICES_FORMATS } from 'shared/constants/invoices/invoices';
import { ExportInvoicesFormatType } from 'shared/types/Invoicing';

import { Modal } from '@/components';
import { useUserContext } from '@/context/UserContext';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { InvoicingAPI } from '@/services/networking/invoicing';
import { Snackbar } from '@/services/toastNotifications';
import { COLOR } from '@/theme/colors';

import { ExportFormatsList } from './components';
import { Props } from './types';

function ExportInvoicesModal({
    close,
    invoicesIds,
    showInfoAboutFilter,
}: Props) {
    const { userProfileData } = useUserContext();
    const { t } = useTranslation();

    const [isInvoicesExportLoading, setIsInvoicesExportLoading] =
        useState(false);
    const [selectedFormat, setSelectedFormat] =
        useState<ExportInvoicesFormatType>(
            userProfileData?.accounting_provider === 'quick_books'
                ? 'quickbooks'
                : 'xero',
        );

    const handleFormatChange = useCallback((type: ExportInvoicesFormatType) => {
        setSelectedFormat(type);
    }, []);

    const exportInvoices = useCallback(async () => {
        setIsInvoicesExportLoading(true);

        try {
            await InvoicingAPI.exportInvoices({
                format: selectedFormat,
                invoicesIds,
            });

            InvoicesAnalytics.logUserExportedInvoices(
                FirebaseAnalytics.logEvent,
                { format: selectedFormat },
            );

            setIsInvoicesExportLoading(false);
            close();

            Snackbar.showToastNotification({
                message: t('Invoicing:export_info'),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
            setIsInvoicesExportLoading(false);
        }
    }, [close, invoicesIds, selectedFormat, setIsInvoicesExportLoading, t]);

    return (
        <Modal
            styles="mt-[5vh] max-w-xl pt-8 pb-5"
            isOpen
            testID="ExportInvoicesModal"
        >
            <Box className="px-4">
                <Typography
                    color={COLOR.paleSky}
                    fontSize="1.2rem"
                    fontWeight={700}
                >
                    {t('Invoicing:modal:export')}
                </Typography>
            </Box>
            <Box className="mt-7 px-4">
                {showInfoAboutFilter ? (
                    <Alert
                        className="mb-5"
                        data-test-id="ExportInvoicesModal-FiltersInfoAlert"
                        severity="info"
                    >
                        {t('Invoicing:modal:filters_info')}
                    </Alert>
                ) : null}
                <Typography className="mb-3" fontWeight={700} variant="body2">
                    {t('Invoicing:modal:available_formats')}
                </Typography>
                <ExportFormatsList
                    formats={EXPORT_INVOICES_FORMATS}
                    onFormatItemClick={handleFormatChange}
                    selectedFormat={selectedFormat}
                />
                {userProfileData?.email ? (
                    <Typography
                        className="mt-16 mb-2"
                        color={COLOR.regentGray}
                        variant="body2"
                    >
                        {t('Invoicing:modal:export_generated')}
                        <Box
                            component="span"
                            data-test-id="ExportInvoicesModal-UserEmailText"
                            fontWeight={700}
                        >
                            {userProfileData.email}
                        </Box>
                    </Typography>
                ) : null}
            </Box>
            <Box className="flex items-center justify-end border-1 border-t border-t-gray-200 pt-6 px-4">
                <Button
                    className="mr-3"
                    color="inherit"
                    data-test-id="ExportInvoicesModal-CancelButton"
                    disabled={isInvoicesExportLoading}
                    onClick={close}
                >
                    {t('Actions:cancel')}
                </Button>
                <Button
                    className="min-w-[100px]"
                    data-test-id="ExportInvoicesModal-ExportButton"
                    disabled={isInvoicesExportLoading}
                    sx={{ height: 37 }}
                    onClick={exportInvoices}
                    variant="contained"
                >
                    {isInvoicesExportLoading ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        t('Invoicing:export')
                    )}
                </Button>
            </Box>
        </Modal>
    );
}

export default ExportInvoicesModal;
