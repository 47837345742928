import { Database } from '@nozbe/watermelondb';

import { AppDBServiceOptions } from '../../types/dbService';

class App {
    private database: Database;
    private options: AppDBServiceOptions;

    constructor(options: AppDBServiceOptions) {
        this.database = options.database;
        this.options = options;
    }

    async clearDatabase() {
        await this.database.write(async () => {
            await this.database.unsafeResetDatabase();

            this.options.logInfo('(DB Clean) local DB has been cleared');
        });
    }
}

export default App;
