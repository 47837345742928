import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { CountryCode } from 'shared/constants/countries';
import { LOCALE } from 'shared/constants/localization/types';
import regionStore from 'shared/stores/userRegion';

import { getLocaleFromLocalStorage } from '@/services/localization';
export function useFormatDate() {
    const regionCode = regionStore.getRegion();

    const isUSRegion = regionCode === CountryCode.unitedStates;
    const locale = getLocaleFromLocalStorage() || LOCALE.en_AU;

    const formatDate = useCallback(
        (
            date: string,
            format: 'date' | 'datetime' | 'time' | 'shortdatetime',
        ) => {
            switch (format) {
                case 'date':
                    if (isUSRegion) {
                        return DateTime.fromISO(date).toFormat('MM-dd-yyyy');
                    } else {
                        return DateTime.fromISO(date)
                            .setLocale(locale)
                            .toLocaleString({
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            });
                    }

                case 'datetime':
                    if (isUSRegion) {
                        return DateTime.fromISO(date).toFormat(
                            'MM-dd-yyyy, hh:mm a',
                        );
                    } else {
                        return DateTime.fromISO(date)
                            .setLocale(locale)
                            .toLocaleString({
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            });
                    }
                case 'time':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: locale !== LOCALE.nl_NL,
                        });
                case 'shortdatetime':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: locale !== LOCALE.nl_NL,
                        });
                default:
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString();
            }
        },
        [locale, isUSRegion],
    );

    return formatDate;
}
