import HardwareIcon from '@mui/icons-material/Hardware';
import { Box, Typography } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import detectElementOverflow from 'detect-element-overflow';
import React, {
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { EventCategory } from 'shared/types/Events';
import { getContactsText } from 'shared/utils/contacts';

import { CalendarEventIcon } from '@/assets/svg';
import HorsesCount from '@/components/Calendar/components/HorsesCount';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const TITLE_HIDDEN_WITH_COUNT = 61;
const TITLE_HIDDEN_WITH_ICON = 50;
const TITLE_HIDDEN = 28;

const HORSE_HIDDEN_WITH_COUNT = 40;
const HORSE_HIDDEN = 28;

const HORSES_COUNT_HIDDEN_INLINE = 32;
const HORSES_COUNT_HIDDEN = 22;
const ICON_HIDDEN = 22;

function WeekEvent({ event, contacts, horses }: Props) {
    const containerRef = useRef<HTMLElement>(null);
    const titleRef = useRef<HTMLElement>(null);
    const horsesRef = useRef<HTMLElement>(null);
    const horsesCountRef = useRef<HTMLElement>(null);

    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    const [isHorsesTextOverflowing, setIsHorsesTextOverflowing] =
        useState(false);
    const [isHorsesCountOverflowing, setIsHorsesCountOverflowing] =
        useState(false);
    const [hideTitle, setHideTitle] = useState(false);
    const [hideIcon, setHideIcon] = useState(false);
    const [hideCount, setHideCount] = useState(false);
    const [hideHorses, setHideHorses] = useState(false);

    const eventContactsText = useMemo(
        () => getContactsText(contacts ?? []),
        [contacts],
    );

    const eventIcon = useMemo(
        () =>
            event.eventModel.category === EventCategory.standard ? (
                <CalendarEventIcon width={19} height={21} color={COLOR.white} />
            ) : (
                <HardwareIcon
                    sx={{
                        width: 19,
                        height: 19,
                    }}
                    htmlColor={COLOR.white}
                />
            ),
        [event.eventModel.category],
    );

    const horsesCountElement = useMemo(
        () =>
            horses?.length && !hideCount ? (
                <HorsesCount ref={horsesCountRef} horsesCount={horses.length} />
            ) : null,
        [hideCount, horses?.length],
    );

    const horsesTextElement = useMemo(() => {
        if (!horses?.length) {
            return null;
        }

        const horsesText = horses.map((horse) => horse.name).join(', ');

        return (
            <Box
                ref={horsesRef}
                sx={{
                    display: 'flex',
                    alignItems: isHorsesTextOverflowing
                        ? 'center'
                        : 'flex-start',
                    gap: 0.5,
                    ml: '3px',
                    width: '100%',
                    flexDirection: isHorsesTextOverflowing ? 'row' : 'column',
                }}
            >
                {horsesCountElement}
                {!hideHorses ? (
                    <Typography
                        sx={{
                            color: COLOR.white,
                            fontSize: 12,
                            fontWeight: '400',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            width: '100%',
                        }}
                    >
                        {horsesText}
                    </Typography>
                ) : null}
            </Box>
        );
    }, [hideHorses, horses, horsesCountElement, isHorsesTextOverflowing]);

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }

        if (titleRef.current) {
            setIsTitleOverflowing(
                detectElementOverflow(titleRef.current, containerRef.current)
                    .collidedBottom,
            );
        }

        if (horsesRef.current) {
            setIsHorsesTextOverflowing(
                detectElementOverflow(horsesRef.current, containerRef.current)
                    .collidedBottom,
            );
        }

        if (horsesCountRef.current) {
            setIsHorsesCountOverflowing(
                detectElementOverflow(
                    horsesCountRef.current,
                    containerRef.current,
                ).collidedBottom,
            );
        }
    }, []);

    const checkLayout = useCallback(() => {
        if (!containerRef.current) {
            return;
        }

        const containerWidth = containerRef.current.offsetWidth;

        if (
            containerWidth < TITLE_HIDDEN_WITH_COUNT &&
            isTitleOverflowing &&
            isHorsesCountOverflowing
        ) {
            setHideTitle(true);
        } else if (
            containerWidth < TITLE_HIDDEN_WITH_ICON &&
            isTitleOverflowing
        ) {
            setHideTitle(true);
        } else if (containerWidth < TITLE_HIDDEN) {
            setHideTitle(true);
        } else {
            setHideTitle(false);
        }

        if (
            containerWidth < HORSE_HIDDEN_WITH_COUNT &&
            isHorsesTextOverflowing
        ) {
            setHideHorses(true);
        } else if (containerWidth < HORSE_HIDDEN) {
            setHideHorses(true);
        } else {
            setHideHorses(false);
        }

        if (
            containerWidth < HORSES_COUNT_HIDDEN_INLINE &&
            isHorsesCountOverflowing
        ) {
            setHideCount(true);
        } else if (containerWidth < HORSES_COUNT_HIDDEN) {
            setHideCount(true);
        } else {
            setHideCount(false);
        }

        if (containerWidth < ICON_HIDDEN) {
            setHideIcon(true);
        } else {
            setHideIcon(false);
        }
    }, [isHorsesCountOverflowing, isHorsesTextOverflowing, isTitleOverflowing]);

    useResizeObserver(containerRef, checkLayout);

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 0.5,
                flexDirection: isTitleOverflowing ? 'row' : 'column',
                alignItems: 'flex-start',
                py: 0.5,
                height: '100%',
            }}
            ref={containerRef}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!hideIcon ? eventIcon : null}
                {isHorsesCountOverflowing ? horsesCountElement : null}
            </Box>
            <Box ref={titleRef} sx={{ minWidth: 0, width: '100%' }}>
                {!hideTitle ? (
                    <Typography
                        sx={{
                            color: COLOR.white,
                            fontSize: '13px',
                            fontWeight: '400',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            ml: '3px',
                        }}
                    >
                        {eventContactsText
                            ? `${eventContactsText} (${event.eventModel.title})`
                            : event.eventModel.title}
                    </Typography>
                ) : null}
            </Box>
            {!isHorsesCountOverflowing ? horsesTextElement : null}
        </Box>
    );
}

const enhance = withObservables(['event'], ({ event }: Props) => {
    return {
        contacts: event.eventModel.contacts.observe(),
        horses: event.eventModel.horses
            .extend(Q.where('hidden', false))
            .observe(),
    };
});

export default enhance(WeekEvent);
