import React, { memo } from 'react';

import { ShoppingListsListItem } from './components';
import { Props } from './types';

function ShoppingListsList({
    clickable,
    conditionWarning,
    highlightAvatar,
    items,
    itemWrapperStyle,
    multiSelect,
    onItemClick,
    renderDateText,
    selectCondition,
    selectable,
    selectedItems,
    testIdPrefix,
}: Props) {
    return (
        <>
            {items.map((shoppingList) => {
                return (
                    <ShoppingListsListItem
                        clickable={clickable}
                        conditionWarning={conditionWarning}
                        highlightAvatar={highlightAvatar}
                        isSelected={
                            selectedItems && selectedItems.has(shoppingList)
                        }
                        key={shoppingList.id}
                        multiSelect={multiSelect}
                        onClick={onItemClick}
                        renderDateText={renderDateText}
                        selectCondition={selectCondition}
                        shoppingList={shoppingList}
                        selectable={selectable}
                        testID={`${testIdPrefix}-ShoppingListsListItem`}
                        wrapperStyles={itemWrapperStyle}
                    />
                );
            })}
        </>
    );
}

export default memo(ShoppingListsList) as (props: Props) => JSX.Element;
