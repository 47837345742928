import Add from '@mui/icons-material/Add';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { monthLabel } from 'shared/constants/date';
import moment from 'shared/utils/moment';

import Button from '@/components/Button';
import { COLOR } from '@/theme/colors';

import { CALENDAR_VIEW, Props } from './types';

const viewStyles = {
    px: 3,
    py: 1,
    fontWeight: 700,
    fontSize: 12,
    borderTop: 1,
    borderBottom: 1,
    borderColor: COLOR.lightGray,
    cursor: 'pointer',
};

const leftViewStyle = {
    borderLeft: 1,
    borderRight: 1,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderLeftColor: COLOR.lightGray,
    borderRightColor: COLOR.lightGray,
};

const rightViewStyle = {
    borderLeft: 1,
    borderRight: 1,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    borderLeftColor: COLOR.lightGray,
    borderRightColor: COLOR.lightGray,
};

function Toolbar({ view, onView, onNavigate, date, showAddEventModal }: Props) {
    const goToAgendaView = () => {
        onView(CALENDAR_VIEW.agenda);
    };

    const goToDayView = () => {
        onView(CALENDAR_VIEW.day);
    };

    const goToWeekView = () => {
        onView(CALENDAR_VIEW.week);
    };

    const goToMonthView = () => {
        onView(CALENDAR_VIEW.month);
    };

    const goToPrev = () => onNavigate('PREV', date);

    const goToNext = () => onNavigate('NEXT', date);

    const goToToday = () => onNavigate('TODAY');

    const navigationLabel: JSX.Element | null = useMemo(() => {
        switch (view) {
            case 'day':
                return (
                    <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                        {moment(date).format('dddd, MMMM Do, YYYY')}
                    </Typography>
                );
            case 'week':
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                            {`${t(monthLabel[moment(date).month()])} ${moment(
                                date,
                            ).year()}`}
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: 1,
                                p: 1,
                                backgroundColor: COLOR.lightGray,
                            }}
                        >
                            <Typography sx={{ fontWeight: 700, fontSize: 12 }}>
                                {`${t('Calendar:labels:week')} ${moment(
                                    date,
                                ).isoWeek()}`}
                            </Typography>
                        </Box>
                    </Box>
                );
            case 'month':
                return (
                    <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                        {`${t(monthLabel[moment(date).month()])} ${moment(
                            date,
                        ).year()}`}
                    </Typography>
                );
            case 'agenda':
            default:
                return null;
        }
    }, [date, view]);

    const handleAddEventClick = useCallback(
        () => showAddEventModal(true),
        [showAddEventModal],
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    onClick={handleAddEventClick}
                    caption={t('Calendar:buttons:add_appointment')}
                    startIcon={<Add />}
                    testID="CalendarComponent-AddAppointmentButton"
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    height: '80px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 2,
                        ml: 2,
                    }}
                >
                    {view !== CALENDAR_VIEW.agenda ? (
                        <>
                            <Box
                                sx={{
                                    paddingInline: 2,
                                    paddingBlock: 1,
                                    border: 1,
                                    borderColor: COLOR.lightGray,
                                    borderRadius: 1,
                                }}
                                onClick={goToToday}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {t('Calendar:views:today')}
                                </Typography>
                            </Box>
                            <Box
                                marginLeft="12px"
                                padding="5px"
                                sx={{ cursor: 'pointer' }}
                                onClick={goToPrev}
                            >
                                <KeyboardArrowLeftIcon sx={{ fontSize: 30 }} />
                            </Box>
                            <Box
                                padding="5px"
                                sx={{ cursor: 'pointer' }}
                                onClick={goToNext}
                            >
                                <KeyboardArrowRightIcon sx={{ fontSize: 30 }} />
                            </Box>
                            <Box paddingLeft="10px">{navigationLabel}</Box>
                        </>
                    ) : null}
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        gap: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            px: 3,
                            py: 1,
                            fontWeight: 700,
                            fontSize: 12,
                            border: 1,
                            borderColor: COLOR.lightGray,
                            cursor: 'pointer',
                            borderRadius: '8px',
                            backgroundColor:
                                view === CALENDAR_VIEW.agenda
                                    ? COLOR.lightGray
                                    : undefined,
                        }}
                        onClick={goToAgendaView}
                    >
                        {t('Calendar:views:list')}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                ...viewStyles,
                                ...leftViewStyle,
                                backgroundColor:
                                    view === CALENDAR_VIEW.day
                                        ? COLOR.lightGray
                                        : undefined,
                            }}
                            onClick={goToDayView}
                        >
                            {t('Calendar:views:day')}
                        </Box>
                        <Box
                            sx={{
                                ...viewStyles,
                                backgroundColor:
                                    view === CALENDAR_VIEW.week
                                        ? COLOR.lightGray
                                        : undefined,
                            }}
                            onClick={goToWeekView}
                        >
                            {t('Calendar:views:week')}
                        </Box>
                        <Box
                            sx={{
                                ...viewStyles,
                                ...rightViewStyle,
                                backgroundColor:
                                    view === CALENDAR_VIEW.month
                                        ? COLOR.lightGray
                                        : undefined,
                            }}
                            onClick={goToMonthView}
                        >
                            {t('Calendar:views:month')}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Toolbar;
