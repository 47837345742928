import DirectionsIcon from '@mui/icons-material/Directions';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography, Button } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { of } from 'rxjs';
import Database from 'shared/db/services/Database.web';
import { getUsersByEventId } from 'shared/db/utils';
import { EventCategory } from 'shared/types/Events';
import { getContactsText } from 'shared/utils/contacts';

import { CalendarEventIcon, HorseIcon } from '@/assets/svg';
import {
    DateBox,
    EventRSVPStatus,
    OrganisationMembersAvatarGroup,
} from '@/components';
import { renderEventHorses } from '@/helpers/horses/data';
import { useEntityColor } from '@/hooks/useEntityColor';
import { FirebaseImagesService } from '@/services/firebase/images';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { formatDate } from '@/utils/date';

import { Props } from './types';

function SchedulesListItem({
    event,
    contacts,
    horses,
    users,
    testID,
    onEditItemClick,
    onClick,
    eventCategory,
    isEditable = false,
}: Props) {
    const eventContactsText = useMemo(
        () => getContactsText(contacts ?? []),
        [contacts],
    );

    const eventHorsesTextElement = useMemo(() => {
        if (!horses?.length) {
            return '';
        }

        return renderEventHorses(horses, t, {
            testId: 'ScheduleListItem-Horses',
        });
    }, [horses]);

    const database = Database.getDatabase();

    const handleEditButtonClicked: MouseEventHandler<HTMLButtonElement> =
        useCallback(
            (e) => {
                if (onEditItemClick && event) {
                    e.stopPropagation();
                    onEditItemClick(event);
                }
            },
            [event, onEditItemClick],
        );

    const handleOnClick = useCallback(() => {
        if (onClick && event) {
            onClick(event);
        }
    }, [event, onClick]);

    const dateColor = useEntityColor(users);

    const startTime = useMemo(
        () => formatDate(event?.startsTime || '', 'time'),
        [event?.startsTime],
    );

    const endsTime = useMemo(
        () => formatDate(event?.endsTime || '', 'time'),
        [event?.endsTime],
    );

    const eventIcon = useMemo(
        () =>
            eventCategory === EventCategory.standard ? (
                <CalendarEventIcon
                    width={eventContactsText ? 19 : 24}
                    height={eventContactsText ? 21 : 26}
                    color={eventContactsText ? COLOR.paleSky : COLOR.black}
                />
            ) : (
                <HardwareIcon
                    sx={{
                        width: eventContactsText ? 19 : 24,
                        height: eventContactsText ? 19 : 24,
                    }}
                    htmlColor={eventContactsText ? COLOR.paleSky : COLOR.black}
                />
            ),
        [eventCategory, eventContactsText],
    );

    return (
        <Box
            onClick={handleOnClick}
            data-test-id={`${testID}-${event?.title}`}
            sx={{
                display: 'flex',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: COLOR.transparentLightBlue,
                },
                flex: 1,
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flex: 1,
                    minWidth: 0,
                }}
            >
                <DateBox
                    textColor={COLOR.white}
                    backgroundColor={dateColor || COLOR.paleSky02}
                    date={event?.startsTime ?? ''}
                    lineHeight="32px"
                    minHeight={84}
                />
                <Box
                    sx={{
                        py: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        gap: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            minWidth: 0,
                        }}
                    >
                        {eventContactsText ? (
                            <PersonIcon
                                fontSize="medium"
                                sx={{
                                    color: COLOR.black,
                                }}
                            />
                        ) : (
                            eventIcon
                        )}
                        <Typography
                            className="min-w-0 flex-1 text-ellipsis overflow-hidden text-base font-bold text-accountSetupBlack whitespace-nowrap"
                            data-test-id={
                                eventContactsText
                                    ? 'ScheduleListItem-Contacts'
                                    : 'ScheduleListItem-Title'
                            }
                        >
                            {eventContactsText
                                ? eventContactsText
                                : event?.title ?? ''}
                        </Typography>
                    </Box>
                    {eventContactsText ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                minWidth: 0,
                            }}
                        >
                            {eventIcon}
                            <Typography
                                color={COLOR.accountSetupBlack}
                                fontSize={13}
                                fontWeight={300}
                                className={
                                    'text-ellipsis overflow-hidden min-w-0 whitespace-nowrap flex-1'
                                }
                            >
                                {event?.title}
                            </Typography>
                        </Box>
                    ) : null}
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: COLOR.accountSetupBlack,
                            marginLeft: 0.5,
                        }}
                    >{`${startTime} - ${endsTime}`}</Typography>
                    {eventCategory !== EventCategory.standard &&
                    horses?.length ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                minWidth: 0,
                                ml: 0.25,
                            }}
                        >
                            <Box>
                                <HorseIcon width={19} height={19} />
                            </Box>
                            {eventHorsesTextElement}
                        </Box>
                    ) : null}
                    {event?.address ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '4px',
                                alignItems: 'center',
                            }}
                        >
                            <LocationOnIcon
                                fontSize="small"
                                htmlColor={COLOR.paleSky}
                                sx={{ alignSelf: 'flex-start', mt: 0.5 }}
                            />
                            <Typography
                                flex={1}
                                fontSize={11}
                                fontWeight={300}
                                lineHeight="22px"
                                color={COLOR.paleSky}
                                data-test-id="ScheduleListItem-Address"
                                className="text-ellipsis overflow-hidden min-w-0 whitespace-nowrap"
                            >
                                {event?.address}
                            </Typography>
                            <DirectionsIcon />
                        </Box>
                    ) : null}
                    <EventRSVPStatus eventId={event?.id || ''} />
                </Box>
            </Box>
            {!!users && (
                <Box
                    sx={{
                        py: 1,
                        display: 'flex',
                    }}
                >
                    <OrganisationMembersAvatarGroup
                        database={database}
                        max={3}
                        users={users}
                        testID="SchedulesListItem-Members"
                    />
                </Box>
            )}
            {isEditable ? (
                <Box
                    sx={{
                        py: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start',
                        minWidth: 100,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            lineHeight: 1.2,
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            className="min-w-0"
                            size="small"
                            onClick={handleEditButtonClicked}
                            data-test-id="SchedulesListItem-EditButton"
                            sx={{ fontWeight: 700 }}
                        >
                            {t('EventsList:button:edit')}
                        </Button>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['event'],
    ({ event }: Props) => {
        const users = getUsersByEventId(
            event?.id ?? '',
            new FirebaseImagesService(),
        );

        return {
            contacts: event?.contacts.observe(),
            horses: event?.horses.extend(Q.where('hidden', false)).observe(),
            location: event ? event?.location.observe() : of(),
            users,
        };
    },
);

export default enhance(SchedulesListItem);
