import { getTimeZones } from '@vvo/tzdb';
import { getLocal, getFromISO } from './date';
import Moment from 'shared/utils/moment';
import { Moment as MomentInterface } from 'moment';
import { RegionModel } from '../types/Region';

export const getTimeZone = (abbreviation = false, date?: string) => {
    try {
        if (!date) return getLocal().zoneName;

        const ISODate = new Date(date).toISOString();
        const zoneName = getFromISO(ISODate).zoneName;

        if (abbreviation) {
            const timeZones = getTimeZones();
            const timezone = timeZones.find(
                (timezone) => timezone.name === zoneName,
            );

            return timezone?.abbreviation || '';
        }

        return getFromISO(ISODate).zoneName;
    } catch {
        return '';
    }
};

export const getTimeZonesForRegion = (
    region: RegionModel,
    date?: MomentInterface,
) => {
    return Moment.tz.zonesForCountry(region.code).map((timezone) => {
        const dateToConsider = date
            ? date.tz(timezone, true)
            : Moment.tz(timezone);

        // Get the timezone offset in minutes
        const offsetMinutes = dateToConsider.utcOffset();

        // Convert the offset to hours and minutes
        const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
        const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);

        // Format the offset in the GMT format
        const offsetString = `GMT${offsetMinutes >= 0 ? '+' : '-'}${offsetHours
            .toString()
            .padStart(2, '0')}:${offsetMinutesRemainder
            .toString()
            .padStart(2, '0')}`;

        return {
            offset: offsetString,
            timezone,
            displayName: timezone,
        };
    });
};

export const getTimeZonesForRegions = (
    regions: RegionModel[],
    date?: MomentInterface,
) =>
    regions
        .flatMap((region) => getTimeZonesForRegion(region, date))
        .sort((timezone1, timezone2) =>
            timezone1.offset.localeCompare(timezone2.offset),
        );
