import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import HardwareIcon from '@mui/icons-material/Hardware';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField,
} from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import moment, { Moment as MomentInterface } from 'moment';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { firstValueFrom } from 'rxjs';
import { SchedulesAnalytics } from 'shared/analytics/schedules';
import { CountryCode } from 'shared/constants/countries';
import {
    USER_REMINDER,
    USER_REMINDER_LABELS,
    USER_REMINDER_VALUES_MAP,
} from 'shared/constants/event';
import Event from 'shared/db/services/Event';
import EventContact from 'shared/db/services/EventContact';
import EventUser from 'shared/db/services/EventUser';
import OrganisationUser from 'shared/db/services/OrganisationUser';
import Region from 'shared/db/services/Region';
import User from 'shared/db/services/User';
import regionStore from 'shared/stores/userRegion';
import { ContactModel } from 'shared/types/Contacts';
import {
    EventPayload,
    EventCategory,
    ContactInvitations,
    ContactInvitation,
    ContactRSVPStatus as ContactRSVPStatusType,
} from 'shared/types/Events';
import { HorseModel } from 'shared/types/Horses';
import { Location } from 'shared/types/Location';
import { EventTimezone } from 'shared/types/timezone';
import { UserModel, UserPayload } from 'shared/types/User';
import { calculateContactInvitationState } from 'shared/utils/events';
import Moment from 'shared/utils/moment';
import { getTimeZonesForRegions } from 'shared/utils/timezone';

import { CalendarEventIcon, ScheduleIcon } from '@/assets/svg';
import {
    AddEditContactModal,
    AddEditHorseModal,
    AddEditModalHeader,
    AppointmentInviteContactSection,
    GooglePlacesAutocomplete,
    MembersSelection,
    Modal,
    RemoveEntityAlert,
    SelectContactsSection,
    SelectHorsesSection,
    Tabs,
    Tooltip,
    NoAppointmentContactDetailsModal,
    AppointmentInvitationInPastModal,
    RSVPAppointmentOverlay,
    ContactRSVPStatus,
} from '@/components';
import { TabItem, TabLayout } from '@/components/Tabs/types';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useCancelEntityFormAlert,
    useDatabase,
    useEscapeButton,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { mapUsersWithColors } from '@/services/user';
import { UserWithColor } from '@/services/user/types';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';
import { ScheduleValidationSchema } from '@/validations/schedule';

import Spotlight from '../Spotlight';
import TooltipSyncButton from '../TooltipButton';

import { AppointmentOnboardingModal } from './components';
import { Props, TitleState, AddEditScheduleFormValues } from './types';

function AddEditScheduleModal({
    contacts,
    mode = 'create',
    onClose,
    event,
    slotInfo,
    view,
    horses,
    onRemove,
    onSubmit,
    onUpdate,
    closeOnSubmit = false,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();
    const { showRSVPAppointmentOverlay } = useRSVPOverlayContext();

    const [contactsInvitationState, setContactsInvitationState] =
        useState<ContactInvitations>({});
    const [contactsRSVPStatus, setContactsRSVPStatus] =
        useState<ContactRSVPStatusType>({});
    const [tabsLayout, setTabsLayout] = useState<TabLayout>({
        width: 0,
        height: 0,
    });
    const [showNoPhoneModal, setShowNoPhoneModal] = useState(false);
    const [showNoEmailModal, setShowNoEmailModal] = useState(false);
    const [contactToEdit, setContactToEdit] = useState<ContactModel | null>(
        null,
    );
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const [showInPastModal, setShowInPastModal] = useState(false);
    const [initialNumberOfInvitedContacts, setInitialNumberOfInvitedContacts] =
        useState(0);
    const [isInPast, setIsInPast] = useState(false);
    const scrollContainerRef = useRef<HTMLElement>();

    const getNumberOfInvitedContacts = useCallback(
        (contactsState: ContactInvitations) =>
            Object.values(contactsState).filter(
                (state) => state.email || state.sms,
                [],
            ).length,
        [],
    );

    const numberOfInvitedContacts = useMemo(
        () => getNumberOfInvitedContacts(contactsInvitationState),
        [contactsInvitationState, getNumberOfInvitedContacts],
    );

    const defaultStandardTitle = t('Event:default_titles:standard_appointment');
    const defaultServiceTitle = t('Event:default_titles:service_appointment');

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    const tabItems: TabItem[] = useMemo(
        () => [
            {
                label: t('Event:categories:service'),
                value: EventCategory.service,
                icon: (
                    <HardwareIcon
                        className="mr-0"
                        width={21}
                        height={23}
                        htmlColor={ENTITY_COLOR.schedule}
                    />
                ),
            },
            {
                label: t('Event:categories:standard'),
                value: EventCategory.standard,
                icon: (
                    <CalendarEventIcon
                        width={24}
                        height={26}
                        color={ENTITY_COLOR.schedule}
                    />
                ),
            },
        ],
        [t],
    );

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const eventUserService = useMemo(
        () =>
            new EventUser({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const regionService = useMemo(() => new Region(database), [database]);

    const getInitialValues: (
        horses?: HorseModel[],
    ) => AddEditScheduleFormValues = useCallback(
        (horses?: HorseModel[]) => ({
            contacts: [],
            horses: horses || [],
            address: '',
            contactsReminder: false,
            startTime: Moment(),
            date: Moment(),
            endTime: Moment().add(1, 'h'),
            membersIds: [],
            notes: '',
            userReminder: USER_REMINDER.NONE,
            timezone: '',
            createEntries: false,
            contactsInvitationState: {},
        }),
        [],
    );

    const defaultInitialValues = useMemo(
        () => getInitialValues(horses),
        [getInitialValues, horses],
    );

    const [initialValues, setInitialValues] =
        useState<AddEditScheduleFormValues>(defaultInitialValues);

    const [isRemoveEventAlertVisible, setIsRemoveEventAlertVisible] =
        useState<boolean>(false);

    const [organisationUsers, setOrganisationUsers] = useState<
        UserWithColor[] | null
    >(null);

    const [eventUsersIds, setEventUsersIds] = useState<string[] | null>(null);

    const [selectableTimezones, setSelectableTimezones] = useState<
        EventTimezone[]
    >([]);

    const [appointmentCategory, setAppointmentCategory] =
        useState<EventCategory>(event?.category || EventCategory.service);

    const isServiceType = appointmentCategory === EventCategory.service;

    const initialTitleValue = useMemo(() => {
        if ((mode === 'duplicate' || mode === 'edit') && event) {
            return event?.title ?? '';
        }

        return isServiceType ? defaultServiceTitle : defaultStandardTitle;
    }, [event, mode, defaultServiceTitle, defaultStandardTitle, isServiceType]);

    const [title, setTitle] = useState<TitleState>({
        dirty: false,
        touched: false,
        error: null,
        value: initialTitleValue,
    });

    const updateTitleState = useCallback(
        (newState: Partial<TitleState>) => {
            setTitle((prev) => ({
                ...prev,
                ...newState,
                dirty: newState.value !== initialTitleValue,
            }));
        },
        [initialTitleValue],
    );

    useEffect(() => {
        setTitle((prev) => ({
            ...prev,
            dirty: prev.value !== initialTitleValue,
        }));
    }, [initialTitleValue]);

    const validateTitle = useCallback(async (value: string) => {
        try {
            await ScheduleValidationSchema.titleField.validate(value);
        } catch (e) {
            if (e.name === 'ValidationError') {
                return e.message;
            }
        }

        return null;
    }, []);

    const [userModel, setUserModel] = useState<UserModel | null>(null);

    const fetchUserModel = useCallback(async () => {
        const [model] = await userService
            .getById(userProfileData?.id ?? '')
            .fetch();
        if (model) {
            setUserModel(model);
        }
    }, [userProfileData?.id, userService]);

    useEffect(() => {
        fetchUserModel();
    }, [fetchUserModel]);

    const dismissAppointmentOnboarding = useCallback(async () => {
        const model = await userService.update(userProfileData?.id ?? '', {
            isAppointmentOnboarded: true,
        });

        setUserModel(model);
    }, [userProfileData?.id, userService]);

    const fetchOrganisationUsers = useCallback(async () => {
        const organisationUserService = new OrganisationUser(database);

        const users = await userService.get().fetch();

        const activeUsers: UserModel[] = [];

        for (const user of users) {
            const [organisationUser] = await organisationUserService
                .getById(user.id)
                .fetch();

            if (organisationUser?.active) {
                activeUsers.push(user);
            }
        }

        const usersWithColors = await mapUsersWithColors(activeUsers, database);

        setOrganisationUsers(usersWithColors);
    }, [database, userService]);

    const fetchEventUsersIds = useCallback(async () => {
        if (event?.id) {
            const eventUsers = await firstValueFrom(
                eventUserService.getEventUsersIdsById(event.id),
            );

            setEventUsersIds(eventUsers);
        }
    }, [event?.id, eventUserService]);

    useEffect(() => {
        fetchOrganisationUsers();

        if (mode === 'duplicate' || mode === 'edit') {
            fetchEventUsersIds();
        }
    }, [fetchEventUsersIds, fetchOrganisationUsers, mode]);

    const getAcceptedTimezone = useCallback(
        (timezones: EventTimezone[], timezoneToSearch = '') =>
            timezones.find((timezone) => timezone.timezone === timezoneToSearch)
                ?.timezone || '',
        [],
    );

    const getTimezones = useCallback(
        async (date?: MomentInterface) => {
            const regions = await regionService.getAll().fetch();

            return getTimeZonesForRegions(regions, date);
        },
        [regionService],
    );

    const prepareInitialValues = useCallback(async () => {
        const deviceTimezone = Moment.tz.guess();

        const userRegionDefaultTimezone = (
            await regionService
                .getByParam('code', userProfileData?.region ?? '')
                .fetch()
        )[0]?.defaultTimezone;

        const userTimezone = userModel?.timezone;

        const timezones = await getTimezones(
            event ? Moment(event.startsTime) : undefined,
        );

        setSelectableTimezones(timezones);

        const initialTimezone =
            getAcceptedTimezone(timezones, userTimezone) ||
            getAcceptedTimezone(timezones, deviceTimezone) ||
            getAcceptedTimezone(timezones, userRegionDefaultTimezone) ||
            timezones[0]?.timezone ||
            '';

        if ((mode === 'duplicate' || mode === 'edit') && event) {
            try {
                const eventContactService = new EventContact({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const eventContacts = await event.contacts?.fetch();
                const eventHorses = await event.horses?.fetch();
                const eventLocation = await event.location?.fetch();
                const eventContactsInvitations = await eventContactService
                    .getByEventId(event.id ?? '')
                    .fetch();

                const invitationState: ContactInvitations = eventContacts
                    ? eventContactsInvitations.reduce(
                          (previous, current) => ({
                              ...previous,
                              [current.contactId]: {
                                  sms: current.sentSMS,
                                  email: current.sentEmail,
                                  smsAt: current.sentSMSAt || null,
                                  emailAt: current.sentEmailAt || null,
                                  smsStatus:
                                      mode === 'duplicate'
                                          ? 'not_sent'
                                          : calculateContactInvitationState(
                                                current.sentSMS,
                                                current.sentSMSAt,
                                            ),
                                  emailStatus:
                                      mode === 'duplicate'
                                          ? 'not_sent'
                                          : calculateContactInvitationState(
                                                current.sentEmail,
                                                current.sentEmailAt,
                                            ),
                              },
                          }),
                          {},
                      )
                    : {};

                setInitialNumberOfInvitedContacts(
                    getNumberOfInvitedContacts(invitationState),
                );

                setContactsInvitationState(invitationState);

                setContactsRSVPStatus(
                    eventContactsInvitations?.reduce(
                        (previous, current) => ({
                            ...previous,
                            [current.contactId]: current.status,
                        }),
                        {},
                    ) ?? {},
                );

                const selectedTimezone =
                    getAcceptedTimezone(timezones, event?.timezone) ||
                    initialTimezone;

                const values: AddEditScheduleFormValues = {
                    endTime: Moment(event.endsTime).tz(selectedTimezone, false),
                    startTime: Moment(event.startsTime).tz(
                        selectedTimezone,
                        false,
                    ),
                    date: Moment(event.startsTime).tz(selectedTimezone, false),
                    address: event.address ?? '',
                    notes: event.notes ?? '',
                    userReminder: event.userReminder ?? USER_REMINDER.NONE,
                    contactsReminder: event.contactsReminder ?? false,
                    horses: eventHorses ?? [],
                    contacts: eventContacts ?? [],
                    membersIds: eventUsersIds ?? [],
                    location: eventLocation || null,
                    timezone: selectedTimezone,
                    createEntries:
                        mode === 'duplicate'
                            ? !!userModel?.isEntryCreationFromEventEnabled
                            : undefined,
                };

                updateTitleState({
                    value: event.title ?? '',
                });

                setInitialValues(values);
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        } else if (mode === 'create') {
            let startTime = Moment().tz(initialTimezone, false);
            let endTime = Moment().add(1, 'h').tz(initialTimezone, false);

            let date = startTime;

            if (slotInfo) {
                const start = Moment(slotInfo.start).tz(initialTimezone, false);
                const end = Moment(slotInfo.end).tz(initialTimezone, false);

                date = startTime;

                if (view !== 'month') {
                    startTime = start;
                    endTime = end;
                }
            }

            setInitialValues((values) => ({
                ...values,
                date,
                startTime,
                endTime,
                contacts: contacts || [],
                membersIds:
                    organisationUsers
                        ?.map((member) => member.user.id)
                        .filter((id) => id === userProfileData?.id) || [],
                timezone: initialTimezone,
                createEntries: !!userModel?.isEntryCreationFromEventEnabled,
            }));
        }
    }, [
        slotInfo,
        view,
        regionService,
        userProfileData?.region,
        userProfileData?.id,
        userModel?.timezone,
        userModel?.isEntryCreationFromEventEnabled,
        getTimezones,
        event,
        getAcceptedTimezone,
        mode,
        database,
        ImagesService,
        getNumberOfInvitedContacts,
        eventUsersIds,
        updateTitleState,
        t,
        contacts,
        organisationUsers,
    ]);

    useEffect(() => {
        prepareInitialValues();
    }, [prepareInitialValues]);

    const shouldNotifyContacts = useCallback(
        (values: AddEditScheduleFormValues) => {
            // We don't want to move on deeper if this scenario passes
            // Because we will only show the modal if the checkbox was and continue to be ticked
            if (!numberOfInvitedContacts) {
                return false;
            }

            if (
                !values.date.isSame(initialValues.date) ||
                !values.startTime.isSame(initialValues.startTime) ||
                !values.endTime.isSame(initialValues.endTime) ||
                values.timezone !== initialValues.timezone ||
                !isEqual(values.horses, initialValues.horses) ||
                values.address !== initialValues.address ||
                values.notes !== initialValues.notes
            ) {
                return true;
            }
        },
        [
            initialValues.address,
            initialValues.date,
            initialValues.endTime,
            initialValues.horses,
            initialValues.notes,
            initialValues.startTime,
            initialValues.timezone,
            numberOfInvitedContacts,
        ],
    );

    const handleSubmit = useCallback(
        async (values: AddEditScheduleFormValues) => {
            try {
                const titleError = await validateTitle(title.value);
                const notifyContacts = shouldNotifyContacts(values);

                if (titleError) {
                    updateTitleState({
                        error: titleError,
                    });

                    return;
                }

                const eventService = new Event({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                    notificationsService: {
                        cancelNotification: () => null,
                        scheduleEventLocalNotification: () => Promise.resolve(),
                    },
                    cacheService: {
                        clear: () => Promise.resolve(),
                        get: (() => Promise.resolve()) as () => any,
                    },
                    firestoreService: {
                        addDocumentToCollection:
                            FirebaseFirestoreAPI.addDocumentToCollection,
                    },
                });

                const {
                    endTime,
                    startTime,
                    date,
                    contacts,
                    contactsReminder,
                    userReminder,
                    horses,
                    membersIds,
                    address,
                    notes,
                    location,
                    createEntries,
                    timezone,
                } = values;

                const payload: EventPayload = {
                    startsTime: date
                        .hour(startTime.hour())
                        .minute(startTime.minute())
                        .tz(timezone, true)
                        .toISOString(),
                    endsTime: date
                        .hour(endTime.hour())
                        .minute(endTime.minute())
                        .tz(timezone, true)
                        .toISOString(),
                    title: title.value,
                    address,
                    notes,
                    userReminder:
                        userReminder && userReminder !== USER_REMINDER.NONE
                            ? userReminder
                            : null,
                    contactsReminder,
                    horses: isServiceType ? horses : [],
                    contacts,
                    ownersReminder: false,
                    location,
                    membersIds,
                    createEntries:
                        isServiceType && horses?.length ? createEntries : false,
                    timezone,
                    category: appointmentCategory,
                    invitations: contactsInvitationState,
                };

                if (mode === 'edit') {
                    if (!event?.id) {
                        throw new Error();
                    }

                    const updatedEvent = await eventService.update({
                        id: event.id,
                        notifyContacts,
                        payload,
                        userId: userProfileData?.id || '',
                    });

                    SchedulesAnalytics.logUserUpdatedEvent(
                        FirebaseAnalytics.logEvent,
                        {
                            event: updatedEvent,
                            previousContactsReminder: event.contactsReminder,
                            previousUserReminder: event.userReminder,
                            numberOfInvitedContacts,
                            previousNumberOfInvitedContacts:
                                initialNumberOfInvitedContacts,
                            membersIds: payload.membersIds ?? [],
                        },
                    );

                    onUpdate && onUpdate(updatedEvent);
                } else {
                    const createdEvent = await eventService.add(
                        payload,
                        userProfileData?.id || '',
                    );

                    SchedulesAnalytics.logUserCreatedEvent(
                        FirebaseAnalytics.logEvent,
                        {
                            event: createdEvent,
                            isOwner: userOrganisation?.owner ?? true,
                            numberOfInvitedContacts,
                            membersIds: payload.membersIds ?? [],
                        },
                    );

                    onSubmit(createdEvent);
                }

                const userPayload: UserPayload = { timezone };

                if (mode !== 'edit') {
                    userPayload.isEntryCreationFromEventEnabled = createEntries;
                }

                await userService.update(
                    userProfileData?.id ?? '',
                    userPayload,
                );

                Snackbar.showToastNotification({
                    message: t(
                        mode === 'edit'
                            ? 'App:Messages:has_been_edited_successfully'
                            : 'App:Messages:has_been_created_successfully',
                        {
                            entity: t('Entities:event'),
                        },
                    ),
                });

                if (mode === 'edit' || closeOnSubmit) {
                    onClose();
                }
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            validateTitle,
            title.value,
            database,
            ImagesService,
            isServiceType,
            appointmentCategory,
            contactsInvitationState,
            mode,
            userService,
            userProfileData?.id,
            t,
            closeOnSubmit,
            updateTitleState,
            event?.id,
            event?.contactsReminder,
            event?.userReminder,
            numberOfInvitedContacts,
            initialNumberOfInvitedContacts,
            onUpdate,
            userOrganisation?.owner,
            onSubmit,
            onClose,
            shouldNotifyContacts,
        ],
    );

    const form = useFormik<AddEditScheduleFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: ScheduleValidationSchema.addEditScheduleForm,
    });

    const getInvitationStateBasedOnIsInPastState = useCallback(
        (
            isInPastState: boolean,
            currentState: ContactInvitation,
            contact: ContactModel,
        ): ContactInvitation => ({
            ...currentState,
            email:
                currentState.emailStatus === 'not_sent' && contact.phone
                    ? !isInPastState
                    : currentState.email,
            sms:
                currentState.smsStatus === 'not_sent' && contact.email
                    ? !isInPastState
                    : currentState.sms,
        }),
        [],
    );

    const updateInPastState = useCallback(() => {
        const { date, timezone, startTime, contacts } = form.values;

        if (date && timezone && startTime && contacts) {
            setIsInPast((isInPastState) => {
                const currentIsInPastState = date
                    .hour(startTime.hour())
                    .minute(startTime.minute())
                    .tz(timezone, true)
                    .isBefore(moment().tz(timezone, false));

                if (isInPastState === currentIsInPastState) {
                    return isInPastState;
                }

                setContactsInvitationState((state) =>
                    contacts.reduce(
                        (previous, current) => ({
                            ...previous,
                            [current.id]:
                                getInvitationStateBasedOnIsInPastState(
                                    currentIsInPastState,
                                    state[current.id] ?? {
                                        sms: false,
                                        email: false,
                                        smsAt: '',
                                        emailAt: '',
                                        smsStatus: 'not_sent',
                                        emailStatus: 'not_sent',
                                    },
                                    current,
                                ),
                        }),
                        {},
                    ),
                );

                return currentIsInPastState;
            });
        }
    }, [form.values, getInvitationStateBasedOnIsInPastState]);

    useEffect(() => {
        updateInPastState();
    }, [updateInPastState]);

    const onStartTimeChanged = useCallback(
        (value) => form.setFieldValue('startTime', value),
        [form],
    );

    const onEndTimeChanged = useCallback(
        (value) => form.setFieldValue('endTime', value),
        [form],
    );

    const onDateChanged = useCallback(
        async (value) => {
            form.setFieldValue('date', value);
            const timezones = await getTimezones(value);
            setSelectableTimezones(timezones);
        },
        [form, getTimezones],
    );

    const renderDateTimePickerInput = useCallback(
        (params, field: string, testID: string) => (
            <TextField
                {...params}
                fullWidth
                error={!!form.errors[field] && !!form.touched[field]}
                helperText={form.touched[field] ? form.errors[field] : ''}
                onBlur={form.handleBlur}
                name={field}
                id={field}
                data-test-id={testID}
                aria-readonly={false}
            />
        ),
        [form.errors, form.handleBlur, form.touched],
    );

    const renderDateInput = useCallback(
        (params) =>
            renderDateTimePickerInput(
                params,
                'date',
                'AddEditScheduleForm-Date',
            ),
        [renderDateTimePickerInput],
    );
    const renderStartTimeInput = useCallback(
        (params) =>
            renderDateTimePickerInput(
                params,
                'startTime',
                'AddEditScheduleForm-StartTime',
            ),
        [renderDateTimePickerInput],
    );
    const renderEndTimeInput = useCallback(
        (params) =>
            renderDateTimePickerInput(
                params,
                'endTime',
                'AddEditScheduleForm-EndTime',
            ),
        [renderDateTimePickerInput],
    );

    const handleSelectContacts = useCallback(
        (contacts: ContactModel[]) => {
            setContactsInvitationState((state) =>
                contacts.reduce(
                    (previous, current) => ({
                        ...previous,
                        [current.id]: {
                            sms:
                                state[current.id]?.sms ??
                                (!isInPast && !!current.phone),
                            email:
                                state[current.id]?.email ??
                                (!isInPast && !!current.email),
                            smsAt: state[current.id]?.smsAt ?? null,
                            emailAt: state[current.id]?.emailAt ?? null,
                            smsStatus:
                                state[current.id]?.smsStatus ?? 'not_sent',
                            emailStatus:
                                state[current.id]?.emailStatus ?? 'not_sent',
                        },
                    }),
                    {},
                ),
            );
            form.setFieldValue('contacts', contacts);
        },
        [form, isInPast],
    );

    const handleRemoveContact = useCallback(
        (contact: ContactModel) => {
            const previousContacts = form.values.contacts;
            const newContacts = previousContacts?.filter(
                (prevContact) => prevContact.id !== contact.id,
            );

            form.setFieldValue('contacts', newContacts);
        },
        [form],
    );

    const handleSelectHorses = useCallback(
        (horses: HorseModel[]) => form.setFieldValue('horses', horses),
        [form],
    );

    const handleRemoveHorse = useCallback(
        (horse: HorseModel) => {
            const previousHorses = form.values.horses;
            const newHorses = previousHorses?.filter(
                (prevHorse) => prevHorse.id !== horse.id,
            );

            form.setFieldValue('horses', newHorses);
        },
        [form],
    );

    const getAlertMinTime = useCallback(() => {
        const userReminder = form.values.userReminder;
        const now = Moment();

        if (userReminder === USER_REMINDER.BEFORE1HOUR) {
            return now.add(1, 'hour');
        } else if (userReminder === USER_REMINDER.BEFORE24HOURS) {
            return now.add(24, 'hours');
        } else if (userReminder === USER_REMINDER.BEFORE1WEEK) {
            return now.add(7, 'days');
        }

        return null;
    }, [form.values.userReminder]);

    const willSendAlert = useMemo(() => {
        const { date, startTime } = form.values;
        if (!date?.isValid() || !startTime?.isValid()) {
            return true;
        }

        const startDate = date
            .hour(startTime.hour())
            .minute(startTime.minute());
        const alertMinTime = getAlertMinTime()?.tz(form.values.timezone, true);

        return (
            !alertMinTime ||
            startDate.tz(form.values.timezone, true).isAfter(alertMinTime)
        );
    }, [form.values, getAlertMinTime]);

    const showRemoveEventAlert = useCallback(() => {
        setIsRemoveEventAlertVisible(true);
    }, []);

    const closeRemoveEventAlert = useCallback(
        () => setIsRemoveEventAlertVisible(false),
        [],
    );

    const handleRemoveEvent = useCallback(async () => {
        try {
            const eventService = new Event({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                notificationsService: {
                    cancelNotification: () => null,
                    scheduleEventLocalNotification: () => Promise.resolve(),
                },
                cacheService: {
                    clear: () => Promise.resolve(),
                    get: (() => Promise.resolve()) as () => any,
                },
            });

            await eventService.deleteByID(
                event?.id ?? '',
                userProfileData?.id ?? '',
            );

            SchedulesAnalytics.logUserDeletedEvent(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:event'),
                }),
            });

            onClose();

            if (onRemove) {
                onRemove();
            }
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [
        ImagesService,
        onClose,
        database,
        event?.id,
        t,
        userProfileData?.id,
        onRemove,
    ]);

    const handlePlaceChange = useCallback(
        (location: Location) => {
            form.setFieldValue('location', location);
            form.setFieldValue('address', location?.name || '');
        },
        [form],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: onClose,
            shouldShowAlertOnCancel: form.dirty || title.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    const renderSelectedTimezone = useCallback(
        (timezoneValue) => {
            const selectedTimezone = selectableTimezones.find(
                ({ timezone }) => timezone === timezoneValue,
            );
            return `(${selectedTimezone?.offset}) ${selectedTimezone?.displayName}`;
        },
        [selectableTimezones],
    );

    const setDefaultTitle = useCallback(
        (type: EventCategory) => {
            if (mode === 'edit' || mode === 'duplicate') {
                return;
            }

            if (type === EventCategory.standard) {
                if (!title.dirty) {
                    updateTitleState({
                        value: defaultStandardTitle,
                    });
                }
            }

            if (type === EventCategory.service) {
                if (!title.dirty) {
                    updateTitleState({
                        value: defaultServiceTitle,
                    });
                }
            }
        },
        [
            mode,
            title.dirty,
            defaultServiceTitle,
            defaultStandardTitle,
            updateTitleState,
        ],
    );

    const handleTabChange = useCallback(
        (category: EventCategory) => {
            setAppointmentCategory(category);
            setDefaultTitle(category);
        },
        [setDefaultTitle],
    );

    const handleTabsLayout = useCallback(
        (layout: TabLayout) => setTabsLayout(layout),
        [],
    );

    const handleTitleBlur = useCallback(() => {
        setTitle((prev) => ({ ...prev, touched: true }));
    }, []);

    const handleTitleChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;

            const error = await validateTitle(newValue);

            updateTitleState({
                error,
                value: newValue,
                touched: true,
            });
        },
        [validateTitle, updateTitleState],
    );

    const handleContactSmsClick = useCallback(
        (contact: ContactModel) =>
            setContactsInvitationState((state) => ({
                ...state,
                [contact.id]: {
                    ...state[contact.id],
                    sms:
                        state[contact.id]?.sms != null
                            ? !state[contact.id].sms
                            : false,
                },
            })),
        [],
    );

    const handleContactEmailClick = useCallback(
        (contact: ContactModel) =>
            setContactsInvitationState((state) => ({
                ...state,
                [contact.id]: {
                    ...state[contact.id],
                    email:
                        state[contact.id]?.email != null
                            ? !state[contact.id].email
                            : false,
                },
            })),
        [],
    );

    const handleContactSmsDisabledClick = useCallback(
        (contact: ContactModel) => {
            if (isInPast) {
                setShowInPastModal(true);
            } else {
                setShowNoPhoneModal(true);
                setContactToEdit(contact);
            }
        },
        [isInPast],
    );

    const handleContactEmailDisabledClick = useCallback(
        (contact: ContactModel) => {
            if (isInPast) {
                setShowInPastModal(true);
            } else {
                setShowNoEmailModal(true);
                setContactToEdit(contact);
            }
        },
        [isInPast],
    );

    const handleEditContact = useCallback(() => {
        setShowEditContactModal(true);
    }, []);

    const handleCloseNoDetailsModal = useCallback(() => {
        setShowNoEmailModal(false);
        setShowNoPhoneModal(false);
        setContactToEdit(null);
    }, []);

    const closeEditContactModal = useCallback(() => {
        setShowEditContactModal(false);
        setContactToEdit(null);
        handleCloseNoDetailsModal();
    }, [handleCloseNoDetailsModal]);

    const handleSaveContact = useCallback(
        (contact: ContactModel) => {
            if (showNoPhoneModal && contact.phone) {
                setContactsInvitationState((state) => ({
                    ...state,
                    [contact.id]: {
                        ...state[contact.id],
                        sms: true,
                    },
                }));
            } else if (showNoEmailModal && contact.email) {
                setContactsInvitationState((state) => ({
                    ...state,
                    [contact.id]: {
                        ...state[contact.id],
                        email: true,
                    },
                }));
            }
        },
        [showNoEmailModal, showNoPhoneModal],
    );

    const handleUserReminderChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (
            event.target.value === USER_REMINDER.NONE &&
            form.values.contactsReminder
        ) {
            form.setFieldValue('contactsReminder', false);
        }

        form.handleChange(event);
    };

    const closeInPastModal = useCallback(() => setShowInPastModal(false), []);

    const renderRSVPStatus = useCallback(
        (item: ContactModel) => {
            const contactRSVPStatus = contactsRSVPStatus[item.id];

            if (!contactRSVPStatus) return <></>;

            return (
                <ContactRSVPStatus
                    contact={item}
                    status={contactsRSVPStatus[item.id]}
                    hideEditButton
                />
            );
        },
        [contactsRSVPStatus],
    );

    const renderExtraContentComponent = useCallback(
        (item: ContactModel) => {
            const contactInvitation = contactsInvitationState[item.id];

            return (
                <>
                    <AppointmentInviteContactSection
                        state={contactsInvitationState}
                        onSmsClick={handleContactSmsClick}
                        onEmailClick={handleContactEmailClick}
                        onSmsDisabledClick={handleContactSmsDisabledClick}
                        onEmailDisabledClick={handleContactEmailDisabledClick}
                        disable={isInPast}
                        item={item}
                    />

                    {contactInvitation?.sms || contactInvitation?.email
                        ? renderRSVPStatus(item)
                        : null}
                </>
            );
        },
        [
            contactsInvitationState,
            handleContactEmailClick,
            handleContactEmailDisabledClick,
            handleContactSmsClick,
            handleContactSmsDisabledClick,
            isInPast,
            renderRSVPStatus,
        ],
    );

    const showRSVPOverlay =
        showRSVPAppointmentOverlay && !!userModel?.isAppointmentOnboarded;

    const isUSRegion = regionStore.getRegion() === CountryCode.unitedStates;
    const dateInputFormat = isUSRegion
        ? 'MM-DD-YYYY'
        : `dddd, MMMM DD [${t(
              'AddEntryForm:labels:date_input_placeholder',
          )}] YYYY`;
    return (
        <Modal
            isOpen
            center
            disableRestoreFocus
            testID="AddEditScheduleModal"
            styles="py-0 relative  h-[85%]"
        >
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box className="sticky top-0 z-10">
                    <AddEditModalHeader
                        backgroundColor={ENTITY_COLOR.schedule}
                        cancel={handleFormCancelButtonClick}
                        save={form.handleSubmit}
                        testIdPrefix="AddEditScheduleForm"
                        title={t(
                            mode === 'edit'
                                ? 'EditEvent:view_title'
                                : 'NewEvent:view_title',
                        )}
                        teamName={
                            userOrganisations && userOrganisations?.length > 1
                                ? userOrganisation?.name || ''
                                : undefined
                        }
                        avatarUrl={
                            userOrganisations && userOrganisations?.length > 1
                                ? teamAvatar?.imageURL || ''
                                : undefined
                        }
                        icon={<ScheduleIcon color={COLOR.white} />}
                        sx={{ mb: 0 }}
                    />
                    <Box>
                        <Box className="relative">
                            {!userModel?.isAppointmentOnboarded ? (
                                <Spotlight
                                    width={tabsLayout.width}
                                    height="100%"
                                    sx={{ px: 6 }}
                                />
                            ) : null}
                            <Tabs
                                tabs={tabItems}
                                initialTabValue={event?.category}
                                activeColor={ENTITY_COLOR.schedule}
                                onTabChange={handleTabChange}
                                sx={{ pl: 6, backgroundColor: COLOR.white }}
                                onTabsLayout={handleTabsLayout}
                            />
                        </Box>
                        {mode !== 'edit' && isServiceType ? (
                            <Box className="flex gap-3 bg-whiteSmoke pl-[61px] py-[6px]">
                                <FormControlLabel
                                    sx={{ mr: 0 }}
                                    checked={form.values.createEntries}
                                    control={
                                        <Checkbox
                                            id="createEntries"
                                            name="createEntries"
                                            data-test-id="AddEditScheduleForm-CreateEntries"
                                            onBlur={form.handleBlur}
                                            onChange={form.handleChange}
                                            checked={
                                                form.values.horses?.length
                                                    ? form.values.createEntries
                                                    : false
                                            }
                                            disabled={
                                                !form.values.horses?.length
                                            }
                                        />
                                    }
                                    label={t('Event:button:create_entries')}
                                />
                                {!form.values.horses.length ? (
                                    <Tooltip
                                        title={t(
                                            'Event:label:create_entries_disabled_description',
                                        )}
                                    >
                                        <InfoOutlinedIcon
                                            sx={{
                                                display: 'flex',
                                                alignSelf: 'center',
                                                color: COLOR.bahamaBlue,
                                            }}
                                        />
                                    </Tooltip>
                                ) : null}
                            </Box>
                        ) : null}
                    </Box>
                </Box>

                <Box
                    ref={scrollContainerRef}
                    className={`px-12 ${
                        userModel?.isAppointmentOnboarded ? 'pb-7' : ''
                    } flex flex-col gap-10`}
                    sx={{
                        overflow: 'auto',
                        py: 3,
                    }}
                >
                    <TextField
                        fullWidth
                        data-test-id="AddEditScheduleForm-Title"
                        id="title"
                        label={t('Event:label:title')}
                        error={!!title.error && !!title.touched}
                        helperText={title.touched ? title.error : ''}
                        onBlur={handleTitleBlur}
                        onChange={handleTitleChange}
                        value={title.value}
                        name="title"
                    />
                    <Box className="flex flex-col gap-5">
                        <Box className="flex gap-3">
                            {organisationUsers?.length ? (
                                <MembersSelection
                                    label={t('AddEventForm:labels:members')}
                                    members={organisationUsers ?? []}
                                    selectedMembersIds={form.values.membersIds}
                                    id="membersIds"
                                    error={
                                        !!form.errors.membersIds &&
                                        !!form.touched.membersIds
                                    }
                                    helperText={
                                        form.touched.membersIds
                                            ? form.errors.membersIds
                                            : ''
                                    }
                                    onBlur={form.handleBlur}
                                    onChange={form.handleChange}
                                    name="membersIds"
                                    entity="events"
                                    sx={{ flex: 2 }}
                                />
                            ) : null}
                        </Box>
                    </Box>
                    <Box className="flex gap-3">
                        <Box className="flex-1">
                            <DesktopDatePicker
                                label={t('AddEventForm:labels:date')}
                                value={form.values.date}
                                onChange={onDateChanged}
                                inputFormat={dateInputFormat}
                                renderInput={renderDateInput}
                                onClose={() => form.validateField('date')}
                                disableMaskedInput
                            />
                        </Box>
                        <Box className="flex flex-1 gap-3">
                            <TimePicker
                                label={t('AddEventForm:labels:start_time')}
                                value={form.values.startTime}
                                onChange={onStartTimeChanged}
                                inputFormat="hh:mm a"
                                renderInput={renderStartTimeInput}
                                maxTime={form.values.endTime}
                            />
                            <TimePicker
                                label={t('AddEventForm:labels:end_time')}
                                value={form.values.endTime}
                                onChange={onEndTimeChanged}
                                inputFormat="hh:mm a"
                                renderInput={renderEndTimeInput}
                                minTime={form.values.startTime}
                            />
                        </Box>
                    </Box>
                    <Box className="flex flex-col gap-3">
                        <Box className="flex gap-3">
                            <TextField
                                select
                                id="userReminder"
                                label={t('Event:label:alert')}
                                error={!willSendAlert}
                                helperText={
                                    !willSendAlert
                                        ? t('Event:errors:user_reminder')
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={handleUserReminderChange}
                                name="userReminder"
                                value={form.values.userReminder}
                                data-test-id="AddEditScheduleForm-Alert"
                                sx={{ flex: 1 }}
                            >
                                {USER_REMINDER_LABELS.map((label) => (
                                    <MenuItem
                                        key={label}
                                        value={label}
                                        data-test-id={`AddEditScheduleForm-Alert${label}`}
                                    >
                                        {t(USER_REMINDER_VALUES_MAP[label])}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                id="timezone"
                                label={t('Event:label:timezone')}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="timezone"
                                value={form.values.timezone}
                                data-test-id="AddEditScheduleForm-Timezone"
                                className="flex-1"
                                SelectProps={{
                                    renderValue: renderSelectedTimezone,
                                }}
                            >
                                {selectableTimezones.map(
                                    ({ timezone, offset, displayName }) => {
                                        const currentTime =
                                            Moment().tz(timezone);

                                        return (
                                            <MenuItem
                                                key={timezone}
                                                value={timezone}
                                                data-test-id={`AddEditScheduleForm-Timezone${timezone}`}
                                                className="flex-col items-start gap-1"
                                            >
                                                <Box>{displayName}</Box>
                                                <Box>{`${currentTime
                                                    .format('LT')
                                                    .toLowerCase()} - ${offset}`}</Box>
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </TextField>
                        </Box>
                        <Box className="flex gap-3">
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                checked={form.values.contactsReminder}
                                control={
                                    <Checkbox
                                        id="contactsReminder"
                                        name="contactsReminder"
                                        data-test-id="AddEditScheduleForm-Notify"
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        checked={form.values.contactsReminder}
                                        disabled={
                                            !form.values.contacts?.length ||
                                            form.values.userReminder ===
                                                USER_REMINDER.NONE
                                        }
                                        sx={{ py: 0 }}
                                    />
                                }
                                label={t('Event:button:notify_contacts')}
                            />
                            <Tooltip title={t('Event:tooltip:notify_contact')}>
                                <InfoOutlinedIcon
                                    sx={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                        color: COLOR.bahamaBlue,
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        id="selectContactsContainer"
                        className="relative flex flex-col gap-3"
                    >
                        <SelectContactsSection
                            onSelectContacts={handleSelectContacts}
                            onRemoveContact={handleRemoveContact}
                            selectedContacts={form.values.contacts}
                            CreateContactModal={AddEditContactModal}
                            CreateHorseModal={AddEditHorseModal}
                            buttonsPosition="top"
                            testIdPrefix="AddEditScheduleForm"
                            allowHorsesSelection
                            displayContactInformation
                            renderExtraContentComponent={
                                renderExtraContentComponent
                            }
                            renderSelectContactModalItemContent={
                                renderRSVPStatus
                            }
                        />
                    </Box>
                    {isServiceType ? (
                        <SelectHorsesSection
                            contactsToFilterHorsesBy={form.values.contacts}
                            onRemoveHorse={handleRemoveHorse}
                            onSelectHorses={handleSelectHorses}
                            selectedHorses={form.values.horses}
                            CreateHorseModal={AddEditHorseModal}
                            CreateContactModal={AddEditContactModal}
                            variant="outlined"
                            testIdPrefix="AddEditScheduleForm"
                            allowContactsSelection
                        />
                    ) : null}
                    <GooglePlacesAutocomplete
                        label={t('AddContactForm:labels:address')}
                        onBlur={form.handleBlur}
                        onPlaceChange={handlePlaceChange}
                        testId="AddEditScheduleForm-Location"
                        value={
                            form.values.location
                                ? {
                                      description: form.values.location.name,
                                      place_id: form.values.location.placeID,
                                  }
                                : form.values.address
                                ? {
                                      description: form.values.address,
                                      place_id: '',
                                  }
                                : null
                        }
                    />
                    <TextField
                        multiline
                        rows={3}
                        fullWidth
                        id="notes"
                        data-test-id="AddEditScheduleForm-Notes"
                        label={t('AddHorseForm:labels:notes')}
                        error={!!form.errors.notes && !!form.touched.notes}
                        helperText={form.touched.notes ? form.errors.notes : ''}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        name="notes"
                        value={form.values.notes}
                    />
                </Box>
                {mode === 'edit' ? (
                    <>
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            onClick={showRemoveEventAlert}
                            showTooltip={isSyncInProgress}
                            disabled={isSyncInProgress}
                            testID="AddEditScheduleForm-RemoveButton"
                            sx={{ mt: 3, alignSelf: 'center' }}
                            stylesAt="container"
                            color="error"
                            startIcon={<DeleteOutlineRoundedIcon />}
                        >
                            {t('Event:button:remove')}
                        </TooltipSyncButton>
                        <RemoveEntityAlert
                            removeMessage={t(
                                'Actions:cancel_with_confirmation',
                            )}
                            cancelMessage={t('Actions:go_back')}
                            dialogText={t('Event:alert:remove_event')}
                            isOpen={isRemoveEventAlertVisible}
                            onClose={closeRemoveEventAlert}
                            onRemove={handleRemoveEvent}
                            testID="RemoveScheduleAlert"
                        />
                    </>
                ) : null}
            </Box>
            {showCancelAlert ? renderCancelAlert() : null}
            {!userModel?.isAppointmentOnboarded ? (
                <AppointmentOnboardingModal
                    onContinue={dismissAppointmentOnboarding}
                />
            ) : null}
            {showRSVPOverlay ? <RSVPAppointmentOverlay /> : null}
            {showNoPhoneModal || showNoEmailModal ? (
                <NoAppointmentContactDetailsModal
                    type={showNoPhoneModal ? 'sms' : 'email'}
                    onEdit={handleEditContact}
                    onCancel={handleCloseNoDetailsModal}
                />
            ) : null}
            {showEditContactModal ? (
                <AddEditContactModal
                    contact={contactToEdit}
                    close={closeEditContactModal}
                    onSave={handleSaveContact}
                    isEditMode
                    CreateHorseModal={AddEditHorseModal}
                    enableRemoveContact={false}
                />
            ) : null}
            {showInPastModal ? (
                <AppointmentInvitationInPastModal onClose={closeInPastModal} />
            ) : null}
        </Modal>
    );
}

export default AddEditScheduleModal;
