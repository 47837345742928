import FirebaseAuth from 'firebase/auth';

export enum AuthProvider {
    Apple = 'apple',
    Xero = 'xero',
}

export type AuthProviders = {
    [key in AuthProvider]: FirebaseAuth.OAuthProvider;
};

export const AuthProviderScopes = {
    [AuthProvider.Apple]: ['email', 'name'],
    [AuthProvider.Xero]: ['openid', 'email', 'profile'],
};
