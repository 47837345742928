import { ArrowBack, ArrowForward, Close, Done } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useCallback, useState } from 'react';

import EntriesImage from '@/assets/images/Entries.webp';
import InventoryImage from '@/assets/images/Inventory.webp';
import InvoicesImage from '@/assets/images/Invoices.webp';
import ScheduleImage from '@/assets/images/Schedule.webp';
import ShoppingListsImage from '@/assets/images/Shopping_Lists.webp';
import TeamsImage from '@/assets/images/Teams.webp';
import {
    CartIcon,
    EntriesIcon,
    InventoryIcon,
    InvoiceIcon,
    ScheduleIcon,
    TeamIcon,
} from '@/assets/svg';
import { t } from '@/services/translations/config';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';

import { Stepper } from './components';
import { Props, StepDefinition } from './types';

const steps: StepDefinition[] = [
    {
        label: t('WebOnboarding:entries', { defaultValue: 'Entries' }),
        imgPath: EntriesImage,
        icon: <EntriesIcon color={COLOR.white} />,
        title: t('WebOnboarding:entries_title', {
            defaultValue: 'Document your work with photos',
        }),
        description: t('WebOnboarding:entries_description', {
            defaultValue:
                'Keep track of your work by creating entries with notes, pictures and an overview of the products used. You can also email reports to your clients.',
        }),
        color: ENTITY_COLOR.entries,
    },
    {
        label: t('WebOnboarding:schedule', { defaultValue: 'Schedule' }),
        imgPath: ScheduleImage,
        icon: <ScheduleIcon color={COLOR.white} />,
        title: t('WebOnboarding:schedule_title', {
            defaultValue: 'Create appointments based on shoeing cycles',
        }),
        description: t('WebOnboarding:schedule_description', {
            defaultValue:
                'Easily send invites and reminders to your contacts via SMS or email. The feature also gives a complete overview of your schedule.',
        }),
        color: ENTITY_COLOR.schedule,
    },
    {
        label: t('WebOnboarding:shopping_lists', {
            defaultValue: 'Shopping Lists',
        }),
        imgPath: ShoppingListsImage,
        icon: <CartIcon color={COLOR.white} />,
        title: t('WebOnboarding:shopping_lists_title', {
            defaultValue: 'Share shopping list directly with dealers',
        }),
        description: t('WebOnboarding:shopping_lists_description', {
            defaultValue:
                'Generate shopping lists based on your stock levels and keep your inventory up to date.',
        }),
        color: ENTITY_COLOR.shoppingLists,
    },
    {
        label: t('WebOnboarding:inventory', { defaultValue: 'Inventory' }),
        imgPath: InventoryImage,
        icon: <InventoryIcon color={COLOR.white} />,
        title: t('WebOnboarding:inventory_title', {
            defaultValue: 'Keep track of your inventory',
        }),
        description: t('WebOnboarding:inventory_description', {
            defaultValue:
                'Stay on top of your inventory by having an overview of products in stock with quantities. This allows you to track when more stock needs to be ordered. ',
        }),
        color: ENTITY_COLOR.inventory,
    },
    {
        label: t('WebOnboarding:invoices', { defaultValue: 'Invoices' }),
        imgPath: InvoicesImage,
        icon: <InvoiceIcon color={COLOR.white} />,
        title: t('WebOnboarding:invoices_title', {
            defaultValue: 'Create and send invoices to your clients',
        }),
        description: t('WebOnboarding:invoices_description', {
            defaultValue:
                'Connect EQUINET with your accounting providers (like Xero) and stay on top of all your payments.',
        }),
        color: ENTITY_COLOR.invoices,
    },
    {
        label: t('WebOnboarding:teams', { defaultValue: 'EQUINET Teams' }),
        imgPath: TeamsImage,
        icon: <TeamIcon color={COLOR.white} />,
        title: t('WebOnboarding:teams_title', {
            defaultValue: 'Invite your whole team',
        }),
        description: t('WebOnboarding:teams_description', {
            defaultValue:
                'The Teams feature ensures all work and scheduling by members are held in one secure place, making your business run more efficiently.',
        }),
        color: ENTITY_COLOR.teams,
    },
];

function Carousel({ onContinue }: Props) {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = steps.length;

    const handleNext = useCallback(() => {
        if (activeStep === maxSteps - 1) {
            onContinue();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [activeStep, maxSteps, onContinue]);

    const handlePrev = useCallback(() => {
        setActiveStep((prev) => prev - 1);
    }, []);

    const handleClose = useCallback(() => {
        onContinue();
    }, [onContinue]);

    return (
        <Box
            sx={{
                maxWidth: 400,
                flexGrow: 1,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    height: 214,
                    display: 'block',
                    position: 'relative',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: '100%',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
            >
                <Box
                    sx={{
                        height: 214,
                        overflow: 'hidden',
                    }}
                    component="img"
                    src={steps[activeStep].imgPath}
                    alt={steps[activeStep].label}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        backgroundColor: `${steps[activeStep].color}E1`,
                        width: '100%',
                        height: '46px',
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backdropFilter: 'blur(8px)',
                        WebkitBackdropFilter: 'blur(8px)',
                    }}
                >
                    {steps[activeStep].icon}
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: COLOR.white,
                            textAlign: 'center',
                            ml: '10px',
                        }}
                    >
                        {steps[activeStep].label}
                    </Typography>
                </Box>
            </Box>

            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    flexDirection: 'column',
                    mb: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        lineHeight: '24px',
                        textAlign: 'center',
                        mb: '20px',
                        px: '20px',
                    }}
                >
                    {steps[activeStep].title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 'light',
                        lineHeight: '26px',
                        textAlign: 'center',
                        px: '24px',
                    }}
                >
                    {steps[activeStep].description}
                </Typography>
            </Paper>
            <Stepper
                activeStep={activeStep}
                steps={steps}
                onStepClick={setActiveStep}
                nextButton={
                    <Button
                        sx={{
                            backgroundColor:
                                activeStep === maxSteps - 1
                                    ? COLOR.deepCerulean
                                    : '#3E3E3E',
                            borderRadius: 30,
                            width: '34px',
                            height: '34px',
                            minWidth: '34px',
                        }}
                        variant="contained"
                        size="small"
                        onClick={handleNext}
                    >
                        {activeStep === maxSteps - 1 ? (
                            <Done />
                        ) : (
                            <ArrowForward />
                        )}
                    </Button>
                }
                prevButton={
                    <Button
                        sx={{
                            backgroundColor: '#3E3E3E',
                            borderRadius: 30,
                            width: '34px',
                            height: '34px',
                            minWidth: '34px',
                        }}
                        variant="contained"
                        size="small"
                        onClick={activeStep === 0 ? handleClose : handlePrev}
                    >
                        {activeStep === 0 ? <Close /> : <ArrowBack />}
                    </Button>
                }
            />
        </Box>
    );
}

export default Carousel;
