import { Q } from '@nozbe/watermelondb';
import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { HORSES_FILTER } from 'shared/constants/horses/filters';
import { SORT_TYPE } from 'shared/constants/sort';
import { HorsesFiltersObject } from 'shared/types/horsesFilters';
import { SortObject } from 'shared/types/sort';

import { checkIfAnyFilterIsActive } from '@/helpers/filters';
import { Context } from '@/types/context';

const INITIAL_FILTERS_STATE: HorsesFiltersObject = {
    [HORSES_FILTER.age]: '',
    [HORSES_FILTER.breed]: [],
    [HORSES_FILTER.gender]: [],
    [HORSES_FILTER.shoeing_cycle]: [],
    [HORSES_FILTER.show_hidden]: false,
};

const INITIAL_SORT_STATE: SortObject = {
    columnId: 'HorsesList:table_columns:horse',
    value: SORT_TYPE.ASC,
    query: (sort: SORT_TYPE) => Q.sortBy('name', sort),
};

const HorsesFiltersContext = createContext<Context.HorsesFilters.Value>({
    clearContextState: () => null,
    filtersState: INITIAL_FILTERS_STATE,
    searchState: '',
    setSearchState: () => null,
    sortState: INITIAL_SORT_STATE,
    setSortState: () => null,
    isAnyFilterActive: false,
    isFiltersPanelVisible: false,
    resetFilters: () => null,
    setFiltersState: () => null,
    setIsFiltersPanelVisible: () => null,
});

const horsesFiltersContextRef: React.MutableRefObject<Context.HorsesFilters.Value | null> =
    createRef();

const HorsesFiltersProvider = ({ children }: Context.HorsesFilters.Props) => {
    const [filtersState, setFiltersState] = useState(INITIAL_FILTERS_STATE);
    const [isFiltersPanelVisible, setIsFiltersPanelVisible] = useState(false);
    const [searchState, setSearchState] = useState('');

    const [sortState, setSortState] = useState<SortObject>(INITIAL_SORT_STATE);

    const isAnyFilterActive = useMemo(() => {
        return checkIfAnyFilterIsActive(filtersState);
    }, [filtersState]);

    const resetFilters = useCallback(() => {
        setFiltersState(INITIAL_FILTERS_STATE);
        setSearchState('');
        setSortState(INITIAL_SORT_STATE);
    }, []);

    const clearContextState = useCallback(() => {
        resetFilters();
    }, [resetFilters]);

    const contextValue: Context.HorsesFilters.Value = {
        clearContextState,
        filtersState,
        sortState,
        setSortState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        searchState,
        setFiltersState,
        setIsFiltersPanelVisible,
        setSearchState,
    };

    horsesFiltersContextRef.current = contextValue;

    return (
        <HorsesFiltersContext.Provider value={contextValue}>
            {children}
        </HorsesFiltersContext.Provider>
    );
};

function useHorsesFiltersContext() {
    const horsesFiltersContext = useContext(HorsesFiltersContext);

    return horsesFiltersContext;
}

function clearHorsesFiltersContextState() {
    return horsesFiltersContextRef.current?.clearContextState();
}

export {
    HorsesFiltersContext,
    HorsesFiltersProvider,
    useHorsesFiltersContext,
    clearHorsesFiltersContextState,
};
