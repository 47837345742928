import { Avatar, Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { HorseAvatar } from '@/assets/svg';
import { COLOR } from '@/theme/colors';

import { HorseSectionItemProps, Props } from './types';

function HorseSectionItem({ name, image, isCancelled }: HorseSectionItemProps) {
    return (
        <div className="mt-3 bg-backgroundGrey flex items-center rounded-r-lg rounded-l-[32px]">
            <Box className="flex">
                <Avatar
                    className="bg-gainsboro mr-4 h-[74px] w-[74px]"
                    src={image || ''}
                    variant="circular"
                    sx={{
                        opacity: isCancelled ? 0.2 : 1,
                    }}
                >
                    <HorseAvatar color={COLOR.mischka} />
                </Avatar>
                <Box className="flex w-full min-w-0 items-center justify-between gap-2 py-1 pr-2">
                    <Box className="min-w-0" flex={6}>
                        <Typography
                            flex={1}
                            fontWeight={700}
                            noWrap
                            sx={{
                                opacity: isCancelled ? 0.2 : 1,
                                color: COLOR.ebonyClay,
                            }}
                        >
                            {name}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

function HorsesSection({ horses, isCancelled, style }: Props) {
    if (!horses) {
        return null;
    }

    return (
        <div className="pt-7">
            <div className={twMerge('border-b-[1px] border-borderGrey', style)}>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '24px',
                    }}
                >
                    {t('ExternalAppointment:labels:horses_title', {
                        length: horses?.length || 0,
                    })}
                </Typography>
            </div>
            <div className="pt-4">
                {horses.map((horse, index) => (
                    <HorseSectionItem
                        key={index}
                        name={horse.name}
                        image={horse.image}
                        isCancelled={isCancelled}
                    />
                ))}
            </div>
        </div>
    );
}

export default HorsesSection;
