import * as yup from 'yup';

import { t } from '@/services/translations/config';

const addEditTeamForm = yup.object().shape({
    businessName: yup
        .string()
        .min(
            2,
            t('TeamsValidation:field_must_contain_at_least_2_characters', {
                field: t('CreateTeam:labels:business_name'),
            }),
        )
        .max(
            50,
            t('TeamsValidation:field_must_contain_at_max_50_characters', {
                field: t('CreateTeam:labels:business_name'),
            }),
        )
        .required(
            t('TeamsValidation:required', {
                field: t('CreateTeam:labels:business_name'),
            }),
        ),
});

export const TeamValidationSchema = {
    addEditTeamForm,
};
