import {
    LOCALE,
    LANGUAGES_HUMANIZED,
    LanguageCodesDictionary,
    LanguagesDictionary,
} from './types';

export const LANGUAGE_CODES_DICTIONARY: LanguageCodesDictionary = {
    [LOCALE.en_AU]: LANGUAGES_HUMANIZED.english_australia,
    [LOCALE.en_US]: LANGUAGES_HUMANIZED.english_usa,
    [LOCALE.nl_NL]: LANGUAGES_HUMANIZED.dutch_netherlands,
};

export const LANGUAGES_DICTIONARY: LanguagesDictionary = {
    [LANGUAGES_HUMANIZED.english_australia]: LOCALE.en_AU,
    [LANGUAGES_HUMANIZED.english_usa]: LOCALE.en_US,
    [LANGUAGES_HUMANIZED.dutch_netherlands]: LOCALE.nl_NL,
};
