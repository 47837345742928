import { Box, FormControlLabel, Switch } from '@mui/material';
import { t } from 'i18next';
import React, { memo, useCallback, useMemo } from 'react';
import { INVOICES_FILTER } from 'shared/constants/invoices/filters';
import { INVOICES_STATUSES_DICTIONARY } from 'shared/constants/invoices/statuses';

import { Select } from '@/components';
import { renderSelectedFilterValues } from '@/helpers/filters';
import { getInvoiceStatusFilters } from '@/helpers/invoice/filters';

import { ContactsFilterSection, DatesFilterSection } from './components';
import { Props } from './types';

function FiltersPanel({ filtersState, onFiltersChange }: Props) {
    const isShowOverdueChecked = filtersState[INVOICES_FILTER.show_overdue];

    const handleShowOverdueSwitchChange = useCallback(() => {
        const previousValue = filtersState[INVOICES_FILTER.show_overdue];

        const setShowOverdueFilterValue = onFiltersChange(
            INVOICES_FILTER.show_overdue,
        );

        setShowOverdueFilterValue(!previousValue);
    }, [filtersState, onFiltersChange]);

    const invoiceStatusFilters = useMemo(() => getInvoiceStatusFilters(), []);

    return (
        <Box
            data-test-id="InvoicesPage-FiltersPanel"
            className="bg-gray-50 px-4 pt-8 pb-4"
        >
            <DatesFilterSection
                filtersState={filtersState}
                onFiltersChange={onFiltersChange}
            />
            <Box className="border-b pb-7 mb-6 items-center flex flex-row">
                <Box className="mr-6">
                    <Select
                        label={t('InvoicesPage:filters:status')}
                        multiselect
                        onChange={onFiltersChange(INVOICES_FILTER.status)}
                        options={invoiceStatusFilters}
                        renderSelectedValue={renderSelectedFilterValues(
                            INVOICES_STATUSES_DICTIONARY,
                        )}
                        selectedValue={filtersState[INVOICES_FILTER.status]}
                        testID="InvoicesPage-FiltersPanel-SelectStatus"
                    />
                </Box>
                <Box className="mr-6">
                    <FormControlLabel
                        checked={isShowOverdueChecked}
                        control={
                            <Switch
                                onChange={handleShowOverdueSwitchChange}
                                value={isShowOverdueChecked}
                            />
                        }
                        data-test-id="InvoicesPage-FiltersPanel-ShowOverdue"
                        label={t('InvoicesPage:filters:show_overdue')}
                    />
                </Box>
            </Box>
            <ContactsFilterSection
                filtersState={filtersState}
                onFiltersChange={onFiltersChange}
            />
        </Box>
    );
}

export default memo(FiltersPanel);
