import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLOR } from '@/theme/colors';

import type { InvoiceItemsFinancialSummaryProps } from './types';

export function InvoiceItemsFinancialSummary({
    currency,
    subtotal,
    tax,
    taxRate,
    taxIncluded,
    subtotalText,
}: InvoiceItemsFinancialSummaryProps) {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                marginLeft: '90px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '12px',
                    flexDirection: 'column',
                    flex: 1,
                    marginRight: '12px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography fontWeight="bold" fontSize={14}>
                        {subtotalText}
                    </Typography>
                    <Typography
                        fontWeight="bold"
                        fontSize={14}
                    >{`${currency}${subtotal}`}</Typography>
                </Box>
                {taxIncluded ? (
                    <>
                        <Box
                            sx={{
                                borderWidth: 0.5,
                                borderColor: COLOR.athensGrayDarker,
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography fontWeight="bold" fontSize={14}>{`${t(
                                'InvoiceSystem:sales_tax',
                            )} ${taxRate}%`}</Typography>
                            <Typography
                                fontWeight="bold"
                                fontSize={14}
                            >{`${currency}${tax}`}</Typography>
                        </Box>
                    </>
                ) : null}
            </Box>
        </Box>
    );
}
