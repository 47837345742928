import { Box, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getRolesDictionary } from 'shared/utils/contactRoles';

import { HorseIcon, UserProfileIcon } from '@/assets/svg';
import { SelectableListItem } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const NUMBER_OF_HORSES_TO_LIST = 3;

function ContactsListItem({
    avatarUrl,
    clickable,
    contact,
    horses,
    isSelected,
    onClick,
    onRemove,
    removable,
    selectable,
    size,
    testID,
    wrapperStyles,
    multiSelect = true,
    selectCondition,
    conditionWarning,
    displayContactInformation,
    renderExtraContentComponent,
}: Props) {
    const { t } = useTranslation();

    const rolesDictionary = useMemo(() => getRolesDictionary(t), [t]);

    const contactRole = useMemo(
        () => (contact.role ? rolesDictionary[contact.role] : ''),
        [contact.role, rolesDictionary],
    );

    const horsesText = useMemo(() => {
        if (horses?.length) {
            const horsesToDisplay = horses.slice(0, NUMBER_OF_HORSES_TO_LIST);

            return horsesToDisplay.map((horse) => horse.name).join(', ');
        }

        return '';
    }, [horses]);

    const remainingHorsesCount = horses?.length
        ? horses.length - NUMBER_OF_HORSES_TO_LIST
        : 0;

    const hasPhoneNumber = !!contact.phonePrefix && !!contact.phone;

    const hasEmail = !!contact.email;

    const phoneNumberText =
        contact.phonePrefix && contact.phone
            ? `${contact.phonePrefix} ${contact.phone}`
            : t('ContactSelection:contacts:no_phone');

    const emailText: string =
        contact.email ?? t('ContactSelection:contacts:no_email');

    const renderContactInfoText = useCallback(
        (hasInfo: boolean, text: string, noWrap: boolean) => (
            <Typography
                variant="body2"
                noWrap={noWrap}
                className={clsx({
                    'opacity-70': hasInfo,
                    'opacity-30': !hasInfo,
                })}
            >
                {text}
            </Typography>
        ),
        [],
    );

    return (
        <SelectableListItem
            clickable={clickable}
            testID={`${testID}-${contact.firstName}`}
            avatarSize={size}
            item={contact}
            onRemove={onRemove}
            wrapperStyles={wrapperStyles}
            selectable={selectable}
            onClick={onClick}
            avatarUrl={avatarUrl}
            isSelected={isSelected}
            avatarVariant="circular"
            removable={removable}
            placeholder={<UserProfileIcon color={COLOR.mischka} />}
            multiSelect={multiSelect}
            selectCondition={selectCondition}
            conditionWarning={conditionWarning}
            avatarFitsContainer={
                (!displayContactInformation ||
                    !contactRole ||
                    !horses?.length) &&
                !renderExtraContentComponent
            }
            renderExtraContentComponent={renderExtraContentComponent}
        >
            <Typography
                flex={1}
                fontWeight={700}
                noWrap
                data-test-id={`${testID}-Name`}
            >
                {`${contact.firstName} ${contact.lastName ?? ''}`}
            </Typography>
            {horsesText ? (
                <Box className="flex gap-1 items-center">
                    <HorseIcon width={15} height={15} color="black" />
                    <Typography
                        className="mt-[1px] max-w-min"
                        flex={1}
                        noWrap
                        variant="body2"
                        data-test-id={`${testID}-HorsesCount`}
                    >
                        {horsesText}
                    </Typography>
                    {remainingHorsesCount > 0 ? (
                        <Typography noWrap variant="body2">
                            {`(+${remainingHorsesCount})`}
                        </Typography>
                    ) : null}
                </Box>
            ) : null}
            {contactRole ? (
                <Typography
                    className="mt-[1px] opacity-70"
                    flex={1}
                    noWrap
                    variant="body2"
                    data-test-id={`${testID}-Address`}
                >
                    {contactRole}
                </Typography>
            ) : null}
            {displayContactInformation ? (
                <Box className="flex items-center gap-2 mt-[1px]">
                    {hasPhoneNumber ? (
                        <>
                            {renderContactInfoText(
                                hasPhoneNumber,
                                phoneNumberText,
                                true,
                            )}
                            <Typography variant="body2">/</Typography>
                            {renderContactInfoText(hasEmail, emailText, true)}
                        </>
                    ) : hasEmail ? (
                        <>
                            {renderContactInfoText(hasEmail, emailText, true)}
                            <Typography variant="body2">/</Typography>
                            {renderContactInfoText(
                                hasPhoneNumber,
                                phoneNumberText,
                                true,
                            )}
                        </>
                    ) : (
                        <Typography variant="body2" className="text-mexicanRed">
                            {t('ContactSelection:contacts:no_contact_details')}
                        </Typography>
                    )}
                </Box>
            ) : null}
        </SelectableListItem>
    );
}

const enhance = withObservables<Props, unknown>(
    ['contact'],
    ({ contact }: Props) => {
        return {
            horses: contact.horses,
        };
    },
);

export default enhance(ContactsListItem);
