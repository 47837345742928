import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box, Button as MUIButton, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, InitialsAvatar } from '@/components';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

const roundedBoxStyles = {
    width: 130,
    height: 130,
    display: 'flex',
    borderRadius: '9999px',
    position: 'relative',
};

function PhotoSelector({
    firstName,
    lastName,
    currentPhotoUrl,
    defaultPhotoUrl,
    onPhotoChanged,
    sx = {},
    placeholder,
}: Props) {
    const { t } = useTranslation();

    const photoInputRef = useRef<HTMLInputElement | null>(null);

    const handlePhotoChange = useCallback(
        (event) => {
            const image = event.target.files && event.target.files[0];
            if (!image) {
                return;
            }

            onPhotoChanged(URL.createObjectURL(image));

            event.target.value = null;
        },
        [onPhotoChanged],
    );

    const handleAddPhotoClick = useCallback(
        () => photoInputRef?.current?.click(),
        [photoInputRef],
    );

    const handleRemovePhotoClick = useCallback(
        () => onPhotoChanged(null),
        [onPhotoChanged],
    );

    return (
        <Box className="flex flex-1" sx={sx}>
            <Box
                sx={{
                    ...roundedBoxStyles,
                    mr: 3,
                    overflow: 'visible',
                    justifyContent: 'flex-end',
                }}
            >
                {currentPhotoUrl ? (
                    <MUIButton
                        data-test-id="AddContactForm-RemovePhotoButton"
                        onClick={handleRemovePhotoClick}
                        size="small"
                        sx={{
                            borderRadius: '9999px',
                            position: 'absolute',
                            zIndex: 9,
                            minWidth: 0,
                            bgcolor: COLOR.paleSky02,
                            mr: 1.25,
                            mt: 0.375,
                        }}
                        variant="text"
                        color="primary"
                    >
                        <CloseIcon
                            htmlColor={COLOR.ebonyClay}
                            sx={{ fontSize: 16 }}
                        />
                    </MUIButton>
                ) : null}
                <Box
                    sx={{
                        ...roundedBoxStyles,
                        overflow: 'hidden',
                    }}
                >
                    <InitialsAvatar
                        avatarUrl={currentPhotoUrl ?? defaultPhotoUrl ?? ''}
                        sx={{ mr: 0 }}
                        firstName={firstName || ''}
                        lastName={lastName || ''}
                        size={130}
                    >
                        {placeholder}
                    </InitialsAvatar>
                    {currentPhotoUrl ? (
                        <MUIButton
                            onClick={handleAddPhotoClick}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: COLOR.paleSky02,
                                position: 'absolute',
                                alignSelf: 'flex-end',
                                px: 3,
                                pb: 1.75,
                                pt: 1,
                                borderRadius: 0,
                            }}
                        >
                            <PhotoCameraIcon
                                htmlColor={COLOR.ebonyClay}
                                sx={{ fontSize: 16 }}
                            />
                            <Typography color={COLOR.ebonyClay} fontSize={12}>
                                {t('EditContact:button:change_photo')}
                            </Typography>
                        </MUIButton>
                    ) : null}
                </Box>
            </Box>
            {!currentPhotoUrl ? (
                <Button
                    data-test-id="AddContactForm-AddPhotoButton"
                    onClick={handleAddPhotoClick}
                    size="small"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        alignSelf: 'flex-end',
                    }}
                    variant="text"
                    color="primary"
                    startIcon={
                        <AddPhotoAlternateIcon htmlColor={COLOR.deepCerulean} />
                    }
                    caption={t('EditContact:button:add_photo')}
                />
            ) : null}
            <input
                style={{ display: 'none' }}
                ref={photoInputRef}
                type="file"
                onChange={handlePhotoChange}
                accept="image/*"
            />
        </Box>
    );
}

export default PhotoSelector;
