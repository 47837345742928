import { ROLE } from '../../types/OrganisationUser';
import { LogEvent } from '../../types/analytics';
import { OrganisationEvents } from './events';

function logTeamMemberCreatedContact(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedContact);
}

function logTeamMemberCreatedHorse(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedHorse);
}

function logTeamMemberCreatedEntry(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedEntry);
}

function logTeamMemberCreatedEvent(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedEvent);
}

function logTeamMemberAddedPhotoToEntry(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberAddedPhotoToEntry);
}

function logTeamMemberCreatedInvoice(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedInvoice);
}

function logTeamMemberCreatedShoppingList(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberCreatedShoppingList);
}

function logTeamMemberUpdatedShoppingListTitle(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberUpdatedShoppingListTitle);
}

function logTeamMemberAddedProductToInventory(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamMemberAddedProductToInventory);
}

function logTeamAdminDeletedMemberFromTeam(logEvent: LogEvent) {
    logEvent(OrganisationEvents.teamAdminDeletedMemberFromTeam);
}

function logTeamGeneratedInvitation(
    logEvent: LogEvent,
    params: { organisationId: string; role: ROLE | null },
) {
    const event =
        params.role === ROLE.admin
            ? OrganisationEvents.teamAdminGeneratedInvitation
            : OrganisationEvents.teamMemberGeneratedInvitation;

    logEvent(event, { organisationId: params.organisationId });
}

function logTeamSentInvitationViaEmail(
    logEvent: LogEvent,
    params: { organisationId: string; role: ROLE | null; emails: string[] },
) {
    const event =
        params.role === ROLE.admin
            ? OrganisationEvents.teamAdminGeneratedInvitationViaEmail
            : OrganisationEvents.teamMemberGeneratedInvitationViaEmail;

    logEvent(event, {
        organisation_id: params.organisationId,
        emails_invited_count: params.emails.length,
    });
}

function logUserCreateTeam(logEvent: LogEvent, params: { teamName: string }) {
    logEvent(OrganisationEvents.userCreateTeam, {
        teamName: params.teamName,
    });
}

function logTeamInvitationApproved(
    logEvent: LogEvent,
    params: { invitationId },
) {
    logEvent(OrganisationEvents.teamInvitationApproved, {
        invitationId: params.invitationId,
    });
}

export const OrganisationAnalytics = {
    logTeamMemberCreatedContact,
    logTeamMemberCreatedHorse,
    logTeamMemberCreatedEntry,
    logTeamMemberCreatedEvent,
    logTeamMemberAddedPhotoToEntry,
    logTeamMemberCreatedInvoice,
    logTeamMemberCreatedShoppingList,
    logTeamMemberUpdatedShoppingListTitle,
    logTeamMemberAddedProductToInventory,
    logTeamAdminDeletedMemberFromTeam,
    logTeamGeneratedInvitation,
    logTeamSentInvitationViaEmail,
    logUserCreateTeam,
    logTeamInvitationApproved,
};
