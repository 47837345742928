import { useCallback, useEffect } from 'react';
import { InvitationData } from 'shared/types/api/user';
import { ROLE } from 'shared/types/OrganisationUser';

import { useDBSyncContext } from '@/context/DBSyncContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { OrganisationsInvitationsAPI } from '@/services/networking/invitations';
import { OrganisationsAPI } from '@/services/networking/organisations';

type UpdateMemberRoleParams = {
    memberId: string;
    organisationId: string;
    userRole: ROLE;
};

export function useTeamsPage() {
    const { isInitialSyncInProgress } = useDBSyncContext();
    const {
        fetchUserOrganisationsData,
        setCurrentOrganisation,
        userOrganisations,
    } = useOrganisationsContext();

    const openInvitation = useCallback(async (invitation: InvitationData) => {
        const data = await OrganisationsInvitationsAPI.openInvitation({
            invitation_id: invitation.id,
            user_id: invitation.user_id,
            organisation_id: invitation.organisation_id,
        });

        const { id } = data.payload.invitation;
        return id;
    }, []);

    const approveInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateOwnerInvitationStatus({
            user_invitation_id: invitationId,
            status: 'approved',
        });
    }, []);

    const rejectInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateOwnerInvitationStatus({
            user_invitation_id: invitationId,
            status: 'declined',
        });
    }, []);

    const acceptInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateUserInvitationStatus({
            user_invitation_id: invitationId,
            status: 'accepted',
        });
    }, []);

    const declineInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.updateUserInvitationStatus({
            user_invitation_id: invitationId,
            status: 'rejected',
        });
    }, []);

    const dismissInvitation = useCallback(async (invitationId: string) => {
        await OrganisationsInvitationsAPI.dismissInvitation({
            user_invitation_id: invitationId,
        });
    }, []);

    const updateMemberRole = useCallback(
        async ({
            memberId,
            organisationId,
            userRole,
        }: UpdateMemberRoleParams) => {
            await OrganisationsAPI.updateMemberRole({
                memberId,
                organisationId,
                userRole,
            });
        },
        [],
    );

    const removeMember = useCallback(
        async (memberId: string, organisationId: string) => {
            await OrganisationsInvitationsAPI.removeMember({
                member_id: memberId,
                organisation_id: organisationId,
            });
        },
        [],
    );

    const changeTeam = useCallback(
        (organisationId: string) => {
            const organisation = userOrganisations?.find(
                (org) => org.id === organisationId,
            );

            if (organisation) {
                setCurrentOrganisation(organisation);
            }
        },
        [setCurrentOrganisation, userOrganisations],
    );

    const joinTeam = useCallback(
        async (invitationId: string) => {
            await acceptInvitation(invitationId);

            await fetchUserOrganisationsData();
        },
        [acceptInvitation, fetchUserOrganisationsData],
    );

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchUserOrganisationsData();
        }
    }, [fetchUserOrganisationsData, isInitialSyncInProgress]);

    return {
        acceptInvitation,
        approveInvitation,
        changeTeam,
        declineInvitation,
        dismissInvitation,
        joinTeam,
        openInvitation,
        rejectInvitation,
        removeMember,
        updateMemberRole,
    };
}
