import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Box, MenuItem, TextField } from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EntriesAnalytics } from 'shared/analytics/entries';
import { CountryCode } from 'shared/constants/countries';
import { getEntryTitlesList } from 'shared/constants/entries/ENTRY_TITLES';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';
import Entry from 'shared/db/services/Entry';
import EntryUser from 'shared/db/services/EntryUser';
import OrganisationUser from 'shared/db/services/OrganisationUser';
import User from 'shared/db/services/User';
import regionStore from 'shared/stores/userRegion';
import {
    EntryPayload,
    EntryProcedureToAdd,
    EntryProductToAdd,
} from 'shared/types/Entries';
import { EntryProcedureModel } from 'shared/types/EntryProcedure';
import { EntryProductModel } from 'shared/types/EntryProduct';
import { HorseModel } from 'shared/types/Horses';
import { ROLE } from 'shared/types/OrganisationUser';
import { Photo } from 'shared/types/Photo';
import { UserModel } from 'shared/types/User';
import { checkIfObjectHasEmptyField } from 'shared/utils/data';
import Moment from 'shared/utils/moment';
import { mapWatermelonDBProceduresObjectsToRawObjects } from 'shared/utils/procedures';
import { mapEntryProducts } from 'shared/utils/product';

import { EntriesIcon } from '@/assets/svg';
import {
    AddEditHorseModal,
    AddEditModalHeader,
    MembersSelection,
    Modal,
    RemoveEntityAlert,
} from '@/components';
import AddEditContactModal from '@/components/AddEditContactModal';
import DividerSelectPhotoSection from '@/components/DividerSelectPhotoSection';
import BackdropLoader from '@/components/Loaders/BackdropLoader';
import SelectHorsesSection from '@/components/SelectHorsesSection';
import SelectEntryProductsSection from '@/components/SelectProductsSection';
import { WEB_STORAGE_KEYS } from '@/constants/webStorageKeys';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { useUserContext } from '@/context/UserContext';
import {
    useBrowserExitPrompt,
    useDatabase,
    useCancelEntityFormAlert,
    useEscapeButton,
} from '@/hooks';
import { useEntityImage } from '@/hooks/useEntityImage';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { mapUsersWithColors } from '@/services/user';
import { UserWithColor } from '@/services/user/types';
import {
    getItemFromLocalStorage,
    storeItemInLocalStorage,
} from '@/services/webStorage/localStorage';
import { COLOR, ENTITY_COLOR } from '@/theme/colors';
import { EntryValidationSchema } from '@/validations/entry';

import SelectEntryProceduresSection from '../SelectEntryProceduresSection';
import TooltipSyncButton from '../TooltipButton';

import AddEntryAlert from './components/AddEntryAlert';
import { AddEditEntryFormValues, Props } from './types';

export function AddEditEntryModal({
    isEditMode,
    close,
    horse,
    onSave,
    closeOnSubmit = false,
    entry,
    onRemove,
    horsesToSelect,
}: Props) {
    const { t } = useTranslation();
    const { isImagesSyncInProgress, ImagesService, images } =
        useImagesContext();
    const { isSyncInProgress } = useDBSyncContext();
    const { getDatabase } = useDatabase();
    const { userProfileData } = useUserContext();
    const { orgUser } = useOrgUserContext();
    const { userOrganisation, userOrganisations } = useOrganisationsContext();

    const teamAvatar = useEntityImage(userOrganisation?.id || '');

    // INFO: this hook open browser prompt when we refresh or leave the browser
    // so the user dont lose form information without confirmation
    useBrowserExitPrompt();

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const entryTitlesList = useMemo(() => getEntryTitlesList(t), [t]);

    const [initialValues, setInitialValues] = useState<AddEditEntryFormValues>({
        title: entryTitlesList[0].value,
        entryProcedures: [],
        horse: horse || null,
        date: Moment(),
        time: Moment(),
        membersIds: [],
        notes: '',
        privateNotes: '',
        entryProducts: [],
    });
    const [imagesToAdd, setImagesToAdd] = useState<Photo[]>([]);
    const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isEntryAlertOpen, setIsEntryAlertOpen] = useState(false);
    const [isProcedureAlertOpen, setIsProcedureAlertOpen] = useState(false);
    const [isRemoveEntryAlertOpen, setIsRemoveEntryAlertOpen] = useState(false);
    const [organisationUsers, setOrganisationUsers] = useState<UserWithColor[]>(
        [],
    );
    const [selectedPhotosUrls, setSelectedPhotosUrls] = useState<string[]>([]);

    const previousEntryProceduresRef = useRef<EntryProcedureModel[]>([]);
    const previousEntryProductsRef = useRef<EntryProductModel[]>([]);

    const organisationUserService = useMemo(
        () => new OrganisationUser(database),
        [database],
    );

    const userService = useMemo(
        () =>
            new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const entryUserService = useMemo(
        () =>
            new EntryUser({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [database, ImagesService],
    );

    const entryService = useMemo(
        () =>
            new Entry({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const openEntryAlert = useCallback(() => setIsEntryAlertOpen(true), []);
    const closeEntryAlert = useCallback(() => setIsEntryAlertOpen(false), []);

    const openProcedureAlert = useCallback(
        () => setIsProcedureAlertOpen(true),
        [],
    );
    const closeProcedureAlert = useCallback(
        () => setIsProcedureAlertOpen(false),
        [],
    );

    const fetchOrganisationUsers = useCallback(async () => {
        const users = await userService.get().fetch();

        const activeUsers: UserModel[] = [];

        for (const user of users) {
            const [organisationUser] = await organisationUserService
                .getById(user.id)
                .fetch();

            if (organisationUser?.active) {
                activeUsers.push(user);
            }
        }

        const usersWithColors = await mapUsersWithColors(activeUsers, database);

        setOrganisationUsers(usersWithColors);
    }, [database, organisationUserService, userService]);

    useEffect(() => {
        fetchOrganisationUsers();
    }, [fetchOrganisationUsers]);

    const entryImages = useMemo(() => {
        if (entry && images) {
            return ImagesService.getImagesByEntityId(images, entry.id);
        }
        return null;
    }, [ImagesService, images, entry]);

    const showRemoveEntryAlert = useCallback(() => {
        if (entry?.invoiceId) {
            Snackbar.showToastNotification({
                message: t('Entry:alert:has_invoice'),
                options: {
                    variant: 'error',
                },
            });
        } else {
            setIsRemoveEntryAlertOpen(true);
        }
    }, [entry?.invoiceId, t]);

    const closeRemoveEntryAlert = useCallback(
        () => setIsRemoveEntryAlertOpen(false),
        [],
    );

    const handleRemoveEntry = useCallback(async () => {
        try {
            const entryId = entry?.id ?? '';

            await entryService.deleteByID(entryId, userProfileData?.id ?? '');

            EntriesAnalytics.logUserDeletedEntry(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:entry'),
                }),
            });

            close();

            if (onRemove) {
                onRemove();
            }
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [close, entry?.id, entryService, onRemove, t, userProfileData?.id]);

    const prepareInitialValues = useCallback(async () => {
        if (isEditMode && entry) {
            try {
                const entryProcedures = await entry.entryProcedures.fetch();
                const horse = await entry.horse.fetch();
                const entryProducts = await entry.entryProducts.fetch();

                previousEntryProceduresRef.current = entryProcedures;
                previousEntryProductsRef.current = entryProducts;

                const entryProceduresToAdd = await entryProcedures.map(
                    mapWatermelonDBProceduresObjectsToRawObjects,
                );
                const entryProductsToAdd = await mapEntryProducts(
                    entryProducts,
                );

                const entryMembers = await entryUserService
                    .getByEntryId(entry.id)
                    .fetch();

                const membersIds: string[] = [];

                for (const { userId } of entryMembers) {
                    const [organisationUser] = await organisationUserService
                        .getById(userId)
                        .fetch();

                    if (organisationUser?.active) {
                        membersIds.push(userId);
                    }
                }

                setInitialValues({
                    title: entry.title ?? entryTitlesList[0].value,
                    entryProcedures: entryProceduresToAdd,
                    horse: horse ?? null,
                    entryProducts: entryProductsToAdd,
                    notes: entry.notes ?? '',
                    privateNotes: entry.privateNotes ?? '',
                    date: Moment(entry.loggedTime),
                    time: Moment(entry.loggedTime),
                    membersIds,
                });

                if (entryImages) {
                    setSelectedPhotosUrls(
                        entryImages.map((image) => image.imageURL),
                    );
                }
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        } else if (!isEditMode && organisationUsers) {
            setInitialValues((values) => ({
                ...values,
                membersIds: organisationUsers
                    .map((organisationUser) => organisationUser.user.id)
                    .filter((id) => id === userProfileData?.id),
            }));
        }
    }, [
        entry,
        entryImages,
        entryTitlesList,
        entryUserService,
        isEditMode,
        organisationUserService,
        organisationUsers,
        t,
        userProfileData?.id,
    ]);

    useEffect(() => {
        prepareInitialValues();
    }, [isEditMode, prepareInitialValues]);

    const addUpdateEntry = useCallback(
        async (values: AddEditEntryFormValues) => {
            try {
                setIsCreating(true);

                if (!userProfileData) {
                    throw new Error();
                }

                const {
                    entryProcedures,
                    entryProducts,
                    notes,
                    membersIds,
                    date,
                    time,
                    title,
                    horse,
                    privateNotes,
                } = values;
                const payload: EntryPayload = {
                    horse,
                    loggedTime: date
                        .hour(time.hour())
                        .minute(time.minute())
                        .toISOString(),
                    title,
                    notes,
                    privateNotes,
                    entryProcedures,
                    entryProducts,
                    images: imagesToAdd,
                    imagesToAdd,
                    imagesToDelete,
                    membersIds,
                };

                if (!isEditMode) {
                    const createdEntry = await entryService.add(
                        payload,
                        userProfileData.id,
                    );

                    if (!createdEntry) {
                        throw new Error();
                    }

                    EntriesAnalytics.logUserCreatedEntry(
                        FirebaseAnalytics.logEvent,
                        {
                            entry: createdEntry,
                            logCreatedPhotosEvent: !!imagesToAdd.length,
                            isOwner: userOrganisation?.owner ?? true,
                            membersIds: payload.membersIds ?? [],
                        },
                    );

                    if (onSave) {
                        onSave(createdEntry);
                    }
                } else {
                    if (!entry) {
                        throw new Error();
                    }

                    const previousTitle = entry.title;
                    const previousPrivateNotes = entry.privateNotes;

                    const updatedEntry = await entryService.update(
                        entry.id,
                        payload,
                        userProfileData.id,
                    );

                    EntriesAnalytics.logUserUpdatedEntry(
                        FirebaseAnalytics.logEvent,
                        {
                            entry: updatedEntry,
                            logUpdatedPhotosEvent:
                                !!imagesToAdd.length || !!imagesToDelete.length,
                            previousTitle,
                            previousEntryProcedures:
                                previousEntryProceduresRef.current,
                            previousEntryProducts:
                                previousEntryProductsRef.current,
                            previousPrivateNotes,
                            membersIds: payload.membersIds ?? [],
                        },
                    );

                    if (onSave) {
                        onSave();
                    }
                }

                let toastNotificationMessage = t(
                    'App:Messages:has_been_created_successfully',
                    {
                        entity: t('Entities:entry'),
                    },
                );

                if (isEditMode) {
                    toastNotificationMessage = t(
                        'App:Messages:has_been_edited_successfully',
                        {
                            entity: t('Entities:entry'),
                        },
                    );
                }

                Snackbar.showToastNotification({
                    message: toastNotificationMessage,
                });

                if (isEditMode || closeOnSubmit) {
                    close();
                }
            } catch {
                setIsCreating(false);

                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [
            userProfileData,
            imagesToAdd,
            imagesToDelete,
            isEditMode,
            t,
            closeOnSubmit,
            entryService,
            userOrganisation?.owner,
            onSave,
            entry,
            close,
        ],
    );

    const handleSubmit = useCallback(
        (values: AddEditEntryFormValues) => {
            const isShowEntryAlertDisabled = getItemFromLocalStorage(
                WEB_STORAGE_KEYS.entry_alert_dont_show,
            );
            const isShowProcedureAlertDisabled = getItemFromLocalStorage(
                WEB_STORAGE_KEYS.procedures_alert_dont_show,
            );
            const hasEmptyFields =
                checkIfObjectHasEmptyField(
                    values,
                    !organisationUsers.length ? ['membersIds'] : undefined,
                ) || !selectedPhotosUrls.length;

            if (!isEditMode) {
                if (!isShowEntryAlertDisabled && hasEmptyFields) {
                    openEntryAlert();
                } else if (
                    !isShowProcedureAlertDisabled &&
                    !values.entryProcedures.length
                ) {
                    openProcedureAlert();
                } else {
                    addUpdateEntry(values);
                }
            } else {
                addUpdateEntry(values);
            }
        },
        [
            addUpdateEntry,
            isEditMode,
            organisationUsers.length,
            openEntryAlert,
            openProcedureAlert,
            selectedPhotosUrls.length,
        ],
    );

    const form = useFormik<AddEditEntryFormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        validationSchema: EntryValidationSchema.addEditEntryForm,
    });

    const handleEntryAlertConfirm = useCallback(
        (dontShowAgain: boolean) => {
            if (dontShowAgain) {
                storeItemInLocalStorage(
                    WEB_STORAGE_KEYS.entry_alert_dont_show,
                    true,
                );
            }

            addUpdateEntry(form.values);
        },
        [addUpdateEntry, form.values],
    );

    const handleProcedureAlertConfirm = useCallback(
        (dontShowAgain: boolean) => {
            if (dontShowAgain) {
                storeItemInLocalStorage(
                    WEB_STORAGE_KEYS.procedures_alert_dont_show,
                    true,
                );
            }

            addUpdateEntry(form.values);
        },
        [addUpdateEntry, form.values],
    );

    const onDateChanged = useCallback(
        (value) => form.setFieldValue('date', value),
        [form],
    );

    const onTimeChanged = useCallback(
        (value) => form.setFieldValue('time', value),
        [form],
    );

    const renderDateTimePickerInput = useCallback(
        (params, field: string, testID: string) => (
            <TextField
                {...params}
                fullWidth
                error={!!form.errors[field] && !!form.touched[field]}
                helperText={form.touched[field] ? form.errors[field] : ''}
                onBlur={form.handleBlur}
                name={field}
                id={field}
                data-test-id={testID}
                aria-readonly={false}
            />
        ),
        [form.errors, form.handleBlur, form.touched],
    );

    const renderDateInput = useCallback(
        (params) =>
            renderDateTimePickerInput(params, 'date', 'AddEditEntryForm-Date'),
        [renderDateTimePickerInput],
    );

    const renderTimeInput = useCallback(
        (params) =>
            renderDateTimePickerInput(params, 'time', 'AddEditEntryForm-Time'),
        [renderDateTimePickerInput],
    );

    const handleSelectProcedures = useCallback(
        (procedures: EntryProcedureToAdd[]) => {
            const newProceduresArrayCopy = [...procedures];

            const newProcedures = newProceduresArrayCopy.map((procedure) => {
                const procedureAlreadySelected =
                    form.values.entryProcedures.find(
                        (entryProcedure) =>
                            procedure.procedureId ===
                            entryProcedure.procedureId,
                    );

                if (procedureAlreadySelected) {
                    return procedureAlreadySelected;
                }

                return procedure;
            });

            form.setFieldValue('entryProcedures', newProcedures);
        },
        [form],
    );

    const handleRemoveProcedures = useCallback(
        (procedure: EntryProcedureToAdd) => {
            const previousProcedures = form.values.entryProcedures;
            const newProcedures = previousProcedures?.filter(
                (prevProcedure) =>
                    prevProcedure.procedureId !== procedure.procedureId,
            );

            form.setFieldValue('entryProcedures', newProcedures);
        },
        [form],
    );

    const handleSaveProcedurePriceButtonClick = useCallback(
        (procedureId: string, procedurePrice: string) => {
            const entryProceduresArrayCopy = [...form.values.entryProcedures];
            const editedProcedureIndex = entryProceduresArrayCopy.findIndex(
                (procedure) => procedure.procedureId === procedureId,
            );

            if (editedProcedureIndex === -1) {
                return;
            }

            const newPrice = Number(procedurePrice);

            if (isNaN(newPrice)) {
                return;
            }

            entryProceduresArrayCopy[editedProcedureIndex] = {
                ...entryProceduresArrayCopy[editedProcedureIndex],
                price: newPrice ? newPrice.toString() : '0',
            };

            form.setFieldValue('entryProcedures', entryProceduresArrayCopy);
        },
        [form],
    );

    const handleSaveProcedureQuantityButtonClick = useCallback(
        (procedureId: string, procedureQuantity: string) => {
            const entryProceduresArrayCopy = [...form.values.entryProcedures];
            const editedProcedureIndex = entryProceduresArrayCopy.findIndex(
                (procedure) => procedure.procedureId === procedureId,
            );

            if (editedProcedureIndex === -1) {
                return;
            }

            const newQuantity = Number(procedureQuantity);

            if (isNaN(newQuantity)) {
                return;
            }

            entryProceduresArrayCopy[editedProcedureIndex] = {
                ...entryProceduresArrayCopy[editedProcedureIndex],
                quantity: newQuantity,
            };

            form.setFieldValue('entryProcedures', entryProceduresArrayCopy);
        },
        [form],
    );

    const handleSaveProductQuantityButtonClick = useCallback(
        (productId: string, procedureQuantity: string) => {
            const entryProductsArrayCopy = [...form.values.entryProducts];
            const editedProductIndex = entryProductsArrayCopy.findIndex(
                (product) => product.productId === productId,
            );

            if (editedProductIndex === -1) {
                return;
            }

            const newQuantity = Number(procedureQuantity);

            if (isNaN(newQuantity)) {
                return;
            }

            entryProductsArrayCopy[editedProductIndex] = {
                ...entryProductsArrayCopy[editedProductIndex],
                quantity: newQuantity,
            };

            form.setFieldValue('entryProducts', entryProductsArrayCopy);
        },
        [form],
    );

    const handleSelectHorses = useCallback(
        (horse: HorseModel[]) => {
            form.setFieldValue('horse', horse);
        },
        [form],
    );

    const handleRemoveHorse = useCallback(() => {
        form.setFieldValue('horse', null);
    }, [form]);

    const handleSelectProducts = useCallback(
        (products) => {
            form.setFieldValue('entryProducts', [...products]);
        },
        [form],
    );

    const handleRemoveProduct = useCallback(
        (productToRemove: EntryProductToAdd) => {
            form.setFieldValue(
                'entryProducts',
                form.values.entryProducts.filter(
                    (product) =>
                        product.productId !== productToRemove.productId,
                ),
            );
        },
        [form],
    );

    const handlePhotosChanged = useCallback((urls: string[]) => {
        setSelectedPhotosUrls((selectedUrls) => [
            ...(selectedUrls ?? []),
            ...urls,
        ]);
        setImagesToAdd((imgs) => [
            ...imgs,
            ...urls.map((url) => ({
                uri: url,
            })),
        ]);
    }, []);

    const handlePhotoRemoved = useCallback(
        (urlToRemove: string) => {
            setSelectedPhotosUrls(
                (photoUrls) =>
                    photoUrls?.filter((photoUrl) => urlToRemove !== photoUrl) ??
                    null,
            );

            const imageToRemove = entryImages?.find(
                (entryImage) => entryImage.imageURL === urlToRemove,
            );

            if (imageToRemove) {
                setImagesToDelete((imgs) => [
                    ...imgs,
                    imageToRemove.documentID,
                ]);
            }
        },
        [entryImages],
    );

    const { handleFormCancelButtonClick, renderCancelAlert, showCancelAlert } =
        useCancelEntityFormAlert({
            closeEntityForm: close,
            shouldShowAlertOnCancel: form.dirty,
        });

    useEscapeButton(handleFormCancelButtonClick);

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    const isUSRegion = regionStore.getRegion() === CountryCode.unitedStates;
    const dateInputFormat = isUSRegion
        ? 'MM-DD-YYYY'
        : `dddd, MMMM DD [${t(
              'AddEntryForm:labels:date_input_placeholder',
          )}] YYYY`;

    return (
        <Modal
            isOpen
            center
            disableRestoreFocus
            testID="AddEditEntryModal"
            styles="relative py-0 w-[768px] h-[85%]"
        >
            <BackdropLoader
                isLoading={
                    (isImagesSyncInProgress || isSyncInProgress) && isCreating
                }
                sx={{
                    position: 'absolute',
                    borderRadius: '1rem',
                    backgroundColor: COLOR.white60,
                    color: COLOR.ebonyClay,
                }}
                loadingText={t(
                    isEditMode
                        ? 'AddEntryForm:edit_loading_text'
                        : 'AddEntryForm:add_loading_text',
                )}
            />
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <AddEditModalHeader
                    backgroundColor={ENTITY_COLOR.entries}
                    cancel={handleFormCancelButtonClick}
                    save={form.handleSubmit}
                    testIdPrefix="AddEditEntryForm"
                    title={t(
                        isEditMode
                            ? 'EditEntry:view_title'
                            : 'NewEntry:view_title',
                    )}
                    teamName={
                        userOrganisations && userOrganisations?.length > 1
                            ? userOrganisation?.name || ''
                            : undefined
                    }
                    avatarUrl={
                        userOrganisations && userOrganisations?.length > 1
                            ? teamAvatar?.imageURL || ''
                            : undefined
                    }
                    icon={<EntriesIcon color={COLOR.white} />}
                />
                <Box
                    className="px-12 pb-7"
                    sx={{
                        overflow: 'auto',
                        pt: 3,
                    }}
                >
                    <Box className="flex flex-col gap-5">
                        <Box className="flex gap-3">
                            <DesktopDatePicker
                                label={t('AddEntryForm:labels:date')}
                                value={form.values.date}
                                onChange={onDateChanged}
                                inputFormat={dateInputFormat}
                                renderInput={renderDateInput}
                                onClose={() => form.validateField('date')}
                                disableMaskedInput
                            />
                            <TimePicker
                                label={t('AddEntryForm:labels:time')}
                                value={form.values.time}
                                onChange={onTimeChanged}
                                inputFormat="hh:mm a"
                                renderInput={renderTimeInput}
                            />
                        </Box>
                        <TextField
                            select
                            id="title"
                            label={t('AddEntryForm:labels:title')}
                            error={!!form.errors.title && !!form.touched.title}
                            helperText={
                                form.touched.title ? form.errors.title : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            name="title"
                            value={form.values.title}
                            data-test-id="AddEditEntryForm-Title"
                            sx={{ flex: 1 }}
                        >
                            {entryTitlesList.map((entryTitle) => (
                                <MenuItem
                                    key={entryTitle.value}
                                    value={entryTitle.value}
                                    data-test-id={`AddEditEntryForm-Title${entryTitle.name}`}
                                >
                                    {entryTitle.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {organisationUsers.length > 0 ? (
                            <MembersSelection
                                label={t('AddEventForm:labels:members')}
                                members={organisationUsers}
                                selectedMembersIds={form.values.membersIds}
                                id="membersIds"
                                error={
                                    !!form.errors.membersIds &&
                                    !!form.touched.membersIds
                                }
                                helperText={
                                    form.touched.membersIds
                                        ? form.errors.membersIds
                                        : ''
                                }
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                name="membersIds"
                                entity="entries"
                                sx={{ flex: 2 }}
                            />
                        ) : null}
                        <SelectEntryProceduresSection
                            allowToInteract={!entry?.invoiceId}
                            onRemoveProcedure={handleRemoveProcedures}
                            onSavePriceButtonClick={
                                handleSaveProcedurePriceButtonClick
                            }
                            onSaveQuantityButtonClick={
                                handleSaveProcedureQuantityButtonClick
                            }
                            onSelectProcedures={handleSelectProcedures}
                            selectedProcedures={form.values.entryProcedures}
                            testIdPrefix="AddEditEntryForm"
                            showPrice={isOrgUserAdmin}
                            editable={isOrgUserAdmin}
                        />
                        <SelectHorsesSection
                            allowToInteract={!entry?.invoiceId}
                            onRemoveHorse={handleRemoveHorse}
                            onSelectHorses={handleSelectHorses}
                            selectedHorses={
                                form.values.horse ? [form.values.horse] : []
                            }
                            CreateHorseModal={AddEditHorseModal}
                            testIdPrefix="AddEditEntryForm"
                            multiSelect={false}
                            variant="outlined"
                            allowContactsSelection
                            CreateContactModal={AddEditContactModal}
                            buttonsPosition="bottom"
                            horsesToSelect={horsesToSelect}
                        />
                        <SelectEntryProductsSection
                            entryProducts={form.values.entryProducts}
                            onSaveQuantityButtonClick={
                                handleSaveProductQuantityButtonClick
                            }
                            onSelect={handleSelectProducts}
                            testIdPrefix="AddEditEntryForm"
                            onRemove={handleRemoveProduct}
                        />
                        <TextField
                            multiline
                            rows={3}
                            fullWidth
                            id="notes"
                            data-test-id="AddEditEntryForm-Notes"
                            label={t('AddEntryForm:labels:notes')}
                            error={!!form.errors.notes && !!form.touched.notes}
                            helperText={
                                form.touched.notes ? form.errors.notes : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            name="notes"
                            value={form.values.notes}
                        />
                        <TextField
                            multiline
                            rows={3}
                            fullWidth
                            id="privateNotes"
                            data-test-id="AddEditEntryForm-PrivateNotes"
                            label={t('AddEntryForm:labels:private_notes')}
                            error={
                                !!form.errors.privateNotes &&
                                !!form.touched.privateNotes
                            }
                            helperText={
                                form.touched.privateNotes
                                    ? form.errors.privateNotes
                                    : ''
                            }
                            onBlur={form.handleBlur}
                            onChange={form.handleChange}
                            name="privateNotes"
                            value={form.values.privateNotes}
                        />
                        <DividerSelectPhotoSection
                            selectLabel={t('AddEntryForm:select_photos')}
                            title={t('AddEntryForm:labels:photos')}
                            onPhotosChange={handlePhotosChanged}
                            selectedPhotosUrls={selectedPhotosUrls}
                            buttonsPosition="top"
                            emptyStateMessage={t(
                                'AddEntryForm:photos_blankslate',
                            )}
                            onPhotoRemoved={handlePhotoRemoved}
                        />
                    </Box>
                </Box>
                {isEditMode && (
                    <>
                        <TooltipSyncButton
                            tooltip={t('AddEditModalHeader:button_tooltip', {
                                defaultValue:
                                    'Please wait for the synchronization to complete',
                            })}
                            disabled={isSyncInProgress}
                            showTooltip={isSyncInProgress}
                            color="error"
                            startIcon={<DeleteOutlineRoundedIcon />}
                            onClick={showRemoveEntryAlert}
                            testID="AddEntryForm-RemoveButton"
                            sx={{ mt: 3, alignSelf: 'center' }}
                            stylesAt="container"
                        >
                            {t('Entry:button:remove')}
                        </TooltipSyncButton>
                        <RemoveEntityAlert
                            dialogText={t('Entry:alert:remove', {
                                title: entry?.title
                                    ? t(ENTRIES_TITLES_DICTIONARY[entry.title])
                                    : '',
                            })}
                            isOpen={isRemoveEntryAlertOpen}
                            onClose={closeRemoveEntryAlert}
                            onRemove={handleRemoveEntry}
                            testID="RemoveEntryAlert"
                        />
                    </>
                )}
            </Box>
            <AddEntryAlert
                isOpen={isEntryAlertOpen}
                onClose={closeEntryAlert}
                onConfirm={handleEntryAlertConfirm}
                testID="AddEditEntryForm-EntryAlertModal"
            />
            <AddEntryAlert
                isOpen={isProcedureAlertOpen}
                onClose={closeProcedureAlert}
                onConfirm={handleProcedureAlertConfirm}
                isProceduresMissingAlert
                testID="AddEditEntryForm-ProcedureAlertModal"
            />
            {showCancelAlert ? renderCancelAlert() : null}
        </Modal>
    );
}

export default AddEditEntryModal;
