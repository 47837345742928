import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventModel } from 'shared/types/Events';

import {
    SchedulesList,
    SchedulesListProps,
    TruncatedListSection,
} from '@/components';
import { ROUTE } from '@/router/routes';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function SchedulesSection({
    buttonText,
    emptyMessage,
    events,
    isLoading,
    onAddButtonClick,
    onButtonClick,
    showAddButton,
    showButtonAlways,
    testIdPrefix,
    title,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToAppointment = useCallback(
        (event: EventModel) => {
            const path = getRoutePath(ROUTE.appointment, { id: event.id });

            navigate(path);
        },
        [navigate],
    );

    return (
        <TruncatedListSection<Partial<SchedulesListProps>>
            ListComponent={SchedulesList}
            listComponentProps={{
                type: 'compact',
                items: events,
                testIdPrefix,
                onItemClick: navigateToAppointment,
            }}
            title={
                title ||
                t('Contact:section:appointments_with_length', {
                    length: events.length || 0,
                })
            }
            emptyMessage={emptyMessage || t('Contact:no_appointments')}
            entityName={t('Entities:event').toLowerCase()}
            buttonText={buttonText || t('Contact:more_appointments')}
            isLoading={isLoading}
            onAddButtonClick={onAddButtonClick}
            onButtonClick={onButtonClick}
            showAddButton={showAddButton}
            showButtonAlways={showButtonAlways}
        />
    );
}

export default SchedulesSection;
