import { Box, SxProps, Theme, Typography } from '@mui/material';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import DatabaseService from 'shared/db/services/Database.web';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import {
    calculateInvoiceDetails,
    getInvoiceStatus,
    getInvoiceStatusColor,
} from 'shared/utils/invoicing';
import { capitalizeFirstLetter } from 'shared/utils/string';

import { SelectableListItem } from '@/components';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { isFutureDate } from '@/utils/date';
import { calculateInvoicePriceSum, calculateOverdue } from '@/utils/invoicing';

import { InvoiceStatusAvatar } from './components';
import { Props } from './types';

const blockCellStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    py: 0.5,
    pr: 2,
    gap: 1,
};

const referenceStyles: SxProps<Theme> = {
    fontSize: 16,
    fontWeight: 700,
};

const draftedReferenceStyles: SxProps<Theme> = {
    ...referenceStyles,
    color: COLOR.regentGray,
};

const externalReferenceStyles: SxProps<Theme> = {
    fontSize: 12,
    fontWeight: 300,
};
const draftedExternalReferenceStyles: SxProps<Theme> = {
    ...externalReferenceStyles,
    color: COLOR.regentGray,
};

const cellTextStyle: SxProps<Theme> = {
    fontSize: 12,
    fontWeight: 300,
};

function InvoicesListItem({
    clickable,
    invoice,
    organisationAccountingsData,
    isSelected,
    onClick,
    onRemove,
    removable,
    selectable,
    wrapperStyles,
    size,
    testID,
}: Props) {
    const database = useDatabase();
    const { ImagesService } = useImagesContext();
    const { invoiceSystemEnabled } = useFeatureFlagsContext();

    const [sumTotal, setSumTotal] = useState('');

    const userRegion = useRegion();

    const currency = userRegion ? userRegion.currencySymbol : '$';

    const accountings = organisationAccountingsData
        ? organisationAccountingsData[0]
        : null;

    const getInvoiceSumTotal = useCallback(async () => {
        const { subtotalSum, totalSum } = await calculateInvoiceDetails(
            {
                database: database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            },
            invoice,
        );

        const taxIncluded =
            invoiceSystemEnabled &&
            invoice.provider === 'internal' &&
            invoice.applyTaxes;

        const priceSum = taxIncluded ? totalSum : subtotalSum;

        const price = calculateInvoicePriceSum({
            priceSum,
            invoice,
            currency,
            taxIncluded,
        });
        setSumTotal(price);
    }, [ImagesService, currency, database, invoice, invoiceSystemEnabled]);

    useEffect(() => {
        getInvoiceSumTotal();
    }, [getInvoiceSumTotal]);

    const buildOverdueField = useMemo(() => {
        if (invoice.status === INVOICE_STATUS.paid) return null;

        const isDateInTheFuture = isFutureDate(invoice.dueDateTime);
        const overdue = calculateOverdue(
            invoice.dueDateTime,
            isDateInTheFuture,
        );

        return (
            <Typography
                sx={{
                    color: isDateInTheFuture
                        ? COLOR.warningYellow
                        : COLOR.errorRed,
                    fontSize: 13,
                    fontWeight: 400,
                }}
            >
                {overdue}
            </Typography>
        );
    }, [invoice.dueDateTime, invoice.status]);

    const priceAndOverdueElement = useMemo(() => {
        return (
            <Box
                sx={{
                    ...blockCellStyles,
                    textAlign: 'end',
                }}
            >
                {buildOverdueField}
                <Typography sx={cellTextStyle}>
                    {sumTotal ? `${sumTotal}` : '-'}
                </Typography>
            </Box>
        );
    }, [buildOverdueField, sumTotal]);

    const displayPriceAndOverdueElement = useCallback(
        () => priceAndOverdueElement,
        [priceAndOverdueElement],
    );

    const invoiceStatusAvatar = useMemo(() => {
        const statusColor = getInvoiceStatusColor(
            invoice.status,
            invoice.dueDateTime,
        ).color;
        return <InvoiceStatusAvatar statusColor={statusColor} />;
    }, [invoice.status, invoice.dueDateTime]);

    const isExternalNumber = !!invoice.number;

    return (
        <SelectableListItem
            clickable={clickable}
            isSelected={isSelected}
            item={invoice}
            onClick={onClick}
            onRemove={onRemove}
            avatarVariant="circular"
            defaultAvatar={invoiceStatusAvatar}
            removable={removable}
            renderExtraInfo={displayPriceAndOverdueElement}
            selectable={selectable}
            showExtraInfo
            avatarSize={size}
            testID={`${testID}-${invoice.reference}`}
            wrapperStyles={wrapperStyles}
        >
            <Box
                sx={{
                    ...blockCellStyles,
                    ...(isExternalNumber ? { gap: 0.3 } : undefined),
                }}
            >
                <Box>
                    <Typography
                        sx={
                            invoice.status === 'DRAFT'
                                ? draftedReferenceStyles
                                : referenceStyles
                        }
                    >
                        {`Ref: ${invoice.reference}`}
                    </Typography>
                    {isExternalNumber ? (
                        <Typography
                            sx={
                                invoice.status === 'DRAFT'
                                    ? draftedExternalReferenceStyles
                                    : externalReferenceStyles
                            }
                        >
                            {`(${invoice.number})`}
                        </Typography>
                    ) : null}
                </Box>
                <Typography
                    sx={{
                        color: getInvoiceStatusColor(
                            invoice.status,
                            invoice.dueDateTime,
                        ).color,
                        fontSize: 13,
                        fontWeight: 400,
                    }}
                >
                    {capitalizeFirstLetter(
                        getInvoiceStatus({
                            hasAccountingProvider: !!(
                                accountings && accountings.accountingProvider
                            ),
                            translateFn: t,
                            sentToContact: invoice.sentToContact,
                            sentToContactTime: invoice.sentToContactTime,
                            status: invoice.status,
                        }),
                    )}
                </Typography>
            </Box>
        </SelectableListItem>
    );
}

const enhance = withObservables<Props, unknown>(['invoice'], () => {
    const database = DatabaseService.getDatabase();

    const accountingsService = new OrganisationAccountings({
        database,
        logDBAction: Logger.logRecordActivity,
    });

    return {
        organisationAccountingsData: accountingsService.get(),
    };
});

export default enhance(InvoicesListItem);
