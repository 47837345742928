import * as yup from 'yup';

import { EMAIL_REGEXP, PHONE_REGEXP } from '@/constants/regex';
import { t } from '@/services/translations/config';

const signInForm = yup.object().shape({
    email: yup
        .string()
        .matches(
            EMAIL_REGEXP,
            t('SignIn:error:email_must_be_valid_email_adresss'),
        )
        .required(
            t('SignIn:error:field_required', {
                field: t('SignIn:label:email_address'),
            }),
        ),
    password: yup.string().required(
        t('SignIn:error:field_required', {
            field: t('SignIn:label:password'),
        }),
    ),
});

const resetPasswordForm = yup.object().shape({
    email: yup
        .string()
        .matches(
            EMAIL_REGEXP,
            t('SignIn:error:email_must_be_valid_email_adresss'),
        ),
    phone: yup
        .string()
        .matches(PHONE_REGEXP, t('SignUp:error:phone_must_be_valid_phone')),
});

const signUpForm = yup.object().shape({
    firstName: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('SignUp:label:first_name'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:first_name'),
            }),
        ),
    lastName: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('SignUp:label:last_name'),
            }),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:last_name'),
            }),
        ),
    companyName: yup
        .string()
        .min(
            2,
            t('SignUp:error:field_must_contain_at_least_2_characters', {
                field: t('SignUp:label:business_name'),
            }),
        )
        .max(
            50,
            t('SignUp:error:field_must_contain_at_max_50_characters', {
                field: t('SignUp:label:business_name'),
            }),
        ),
    email: yup
        .string()
        .matches(
            EMAIL_REGEXP,
            t('SignUp:error:email_must_be_valid_email_adresss'),
        )
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:email_address'),
            }),
        ),
    password: yup
        .string()
        .min(12, t('SignUp:error:password_must_contain_at_least_12_characters'))
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:password'),
            }),
        ),
    phone: yup
        .string()
        .matches(PHONE_REGEXP, t('SignUp:error:phone_must_be_valid_phone'))
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:phone_number'),
            }),
        ),
    acceptTerms: yup.boolean().isTrue(),
});

const xeroSignUpForm = yup.object().shape({
    phone: yup
        .string()
        .matches(PHONE_REGEXP, t('SignUp:error:phone_must_be_valid_phone'))
        .required(
            t('SignUp:error:field_required', {
                field: t('SignUp:label:phone_number'),
            }),
        ),
    acceptTerms: yup.boolean().isTrue(),
});

const newPasswordForm = yup.object().shape({
    newPassword: yup
        .string()
        .min(12, t('SignUp:error:password_must_contain_at_least_12_characters'))
        .required(t('NewPassword:error:new_password_required')),
    newPasswordConfirmation: yup
        .string()
        .oneOf([yup.ref('newPassword')], () =>
            t('NewPassword:error:password_must_match'),
        )
        .required(t('NewPassword:error:new_password_confirmation')),
});

export const AuthValidationSchema = {
    signInForm,
    signUpForm,
    resetPasswordForm,
    newPasswordForm,
    xeroSignUpForm,
};
