import {
    CloudDoneOutlined,
    Schedule,
    SyncDisabled,
    SyncOutlined,
} from '@mui/icons-material';
import React from 'react';

import { getStatusColor } from '@/utils/sync';

import { Props } from './types';

function StatusIcon({ status, size = 18 }: Props) {
    switch (status) {
        case 'offline':
        case 'error':
            return (
                <SyncDisabled
                    sx={{
                        color: getStatusColor('offline'),
                        width: size,
                        height: size,
                    }}
                />
            );

        case 'waiting':
            return (
                <Schedule
                    sx={{
                        color: getStatusColor('waiting'),
                        width: size,
                        height: size,
                    }}
                />
            );

        case 'in_progress':
            return (
                <SyncOutlined
                    sx={{
                        color: getStatusColor('in_progress'),
                        animation: 'spin 2s linear infinite',
                        '@keyframes spin': {
                            '0%': {
                                transform: 'rotate(360deg)',
                            },
                            '100%': {
                                transform: 'rotate(0deg)',
                            },
                        },
                        width: size,
                        height: size,
                    }}
                />
            );

        case 'synced':
        default:
            return (
                <CloudDoneOutlined
                    sx={{
                        color: getStatusColor('synced'),
                        width: size,
                        height: size,
                    }}
                />
            );
    }
}

export default StatusIcon;
