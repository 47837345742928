import { Box, Button, TableRow, TableCell, Typography } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { of, switchMap } from 'rxjs';
import { ENTRIES_TITLES_DICTIONARY } from 'shared/constants/entries/titles';
import Database from 'shared/db/services/Database.web';
import OrganisationAccountingsService from 'shared/db/services/OrganisationAccountings';
import User from 'shared/db/services/User';
import { DBWriterLoggerFunction } from 'shared/types/logger';
import { ROLE } from 'shared/types/OrganisationUser';
import { isFuture } from 'shared/utils/date';

import { HorseIcon, PersonIcon } from '@/assets/svg';
import { OrganisationMembersAvatarGroup } from '@/components';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { renderTableItemContactsText } from '@/helpers/contacts/data';
import { withSyncContext } from '@/hoc';
import { useFormatDate, useInvoiceStatusLabel } from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseImagesService } from '@/services/firebase/images';
import { COLOR } from '@/theme/colors';
import { getRoutePath } from '@/utils/router';

import { EntryAvatar, ProceduresBadge } from './components';
import { Props } from './types';

const database = Database.getDatabase();

function EntriesTableItem({
    entry,
    entryHorse,
    entryUser,
    entryHorseContacts,
    entryInvoice,
    entryProcedures,
    organisationAccountingsData,
    isPhotosLoading,
    onEditItem,
    onAddInvoice,
}: Props) {
    const { images, ImagesService } = useImagesContext();
    const formatDate = useFormatDate();
    const navigate = useNavigate();
    const { orgUser } = useOrgUserContext();

    const { t } = useTranslation();

    const [organisationAccountings] = organisationAccountingsData || [];

    const invoiceStatusLabel = useInvoiceStatusLabel(
        entryInvoice || null,
        !!organisationAccountings.accountingProvider,
    );

    const entryCreator = useMemo(() => {
        if (!entryUser) {
            return null;
        }

        return (
            <OrganisationMembersAvatarGroup
                database={database}
                max={1}
                users={entryUser}
                size={26}
                fontSize={12}
                testID="SchedulesListItem-Members"
            />
        );
    }, [entryUser]);

    const entryHorseContactsTextElement = useMemo(() => {
        if (!entryHorseContacts?.length) {
            return (
                <Typography
                    className="text-xs"
                    sx={{
                        color: COLOR.accountSetupBlack,
                        fontWeight: 400,
                        lineHeight: '19.5px',
                    }}
                >
                    {t('EntriesList:no_contacts_added')}
                </Typography>
            );
        }

        return renderTableItemContactsText(entryHorseContacts, t, {
            testId: 'EntriesPage-TableRowContactName',
            fontSize: 12,
            fontWeight: 400,
            color: COLOR.accountSetupBlack,
        });
    }, [entryHorseContacts, t]);

    const entryImageData = useMemo(() => {
        if (!entry.id || !images) {
            return null;
        }

        const entryImages = ImagesService.getImagesByEntityId(images, entry.id);
        const entryImagesCount = entryImages?.length || 0;

        const firstImage =
            entryImages && entryImages.length > 0
                ? { uri: entryImages[0].imageURL }
                : {};

        return {
            entryImage: firstImage,
            entryImagesCount,
        };
    }, [entry.id, images, ImagesService]);

    const entryDateData = useMemo(() => {
        if (!entry.loggedTime) {
            return null;
        }

        const isFutureDate = isFuture(entry.loggedTime);
        const formattedDate = formatDate(entry.loggedTime, 'shortdatetime');

        return {
            formattedDate,
            isInFuture: isFutureDate,
        };
    }, [entry.loggedTime, formatDate]);

    const handleEditItem = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onEditItem(entry);
        },
        [entry, onEditItem],
    );

    const handleAddInvoice = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            onAddInvoice(entry);
        },
        [entry, onAddInvoice],
    );

    const navigateToEntry = useCallback(
        () => navigate(getRoutePath(ROUTE.entry, { id: entry.id })),
        [entry.id, navigate],
    );

    const entryTitleTranslated = t(ENTRIES_TITLES_DICTIONARY[entry.title]);

    const isOrgUserAdmin = orgUser?.role === ROLE.admin;

    return (
        <TableRow
            className="hover:bg-blue-50/50 child:border-0 last-child:rounded-r-lg first-child:rounded-l-lg cursor-pointer"
            data-test-id={`EntriesPage-TableRow${entryTitleTranslated}`}
            onClick={navigateToEntry}
        >
            <TableCell
                className="flex-1 max-w-[18rem]"
                component="th"
                scope="row"
                width="50%"
            >
                <Box className="flex flex-1 flex-row items-center min-w-0">
                    <EntryAvatar
                        isLoading={isPhotosLoading || false}
                        photosCount={entryImageData?.entryImagesCount || 0}
                        imageUrl={entryImageData?.entryImage.uri}
                    />
                    <Box className="flex flex-col self-start min-w-0">
                        {entry.title ? (
                            <Typography
                                data-test-id="EntriesPage-TableRowName"
                                flex={1}
                                fontWeight={700}
                                noWrap
                                variant="subtitle1"
                            >
                                {entryTitleTranslated}
                            </Typography>
                        ) : null}
                        <div className="flex flex-row gap-[4px] items-center">
                            <HorseIcon width={14} height={14} />
                            <Typography
                                className="text-xs"
                                data-test-id="EntriesPage-TableRowHorseName"
                                sx={{
                                    color: COLOR.accountSetupBlack,
                                    fontWeight: 400,
                                    lineHeight: '19.5px',
                                }}
                            >
                                {entryHorse?.name
                                    ? entryHorse.name
                                    : t('EntriesList:no_horse_assigned')}
                            </Typography>
                        </div>
                        <div className="mt-2">
                            <ProceduresBadge
                                procedures={entryProcedures || []}
                            />
                        </div>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="right" width="10%">
                <div className="flex flex-row flex-1 justify-center items-center">
                    {entryCreator}
                </div>
            </TableCell>
            <TableCell align="left" width="15%">
                {entryDateData?.formattedDate ? (
                    <Typography
                        data-test-id="EntriesPage-TableRowDate"
                        fontSize={12}
                        fontStyle={
                            entryDateData.isInFuture ? 'italic' : 'inherit'
                        }
                        fontWeight={400}
                        color={COLOR.accountSetupBlack_50}
                    >
                        {entryDateData.formattedDate}
                    </Typography>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="left" width="20%">
                <div className="flex flex-row gap-1 items-center">
                    <PersonIcon width={14} height={14} />
                    {entryHorseContactsTextElement}
                </div>
            </TableCell>
            {isOrgUserAdmin ? (
                <TableCell align="left" width="15%">
                    {invoiceStatusLabel ? (
                        <div className="flex flex-row items-center">
                            {invoiceStatusLabel}
                        </div>
                    ) : (
                        <Button
                            className="min-w-0"
                            size="small"
                            onClick={handleAddInvoice}
                            data-test-id="EntriesPage-TableRowAddInvoiceButton"
                        >
                            {`+ ${t('Actions:add')}`}
                        </Button>
                    )}
                </TableCell>
            ) : null}
            <TableCell align="right" width="5%">
                <Button
                    className="min-w-0"
                    data-test-id="EntriesPage-TableRowEditButton"
                    size="small"
                    onClick={handleEditItem}
                >
                    {t('Actions:edit')}
                </Button>
            </TableCell>
        </TableRow>
    );
}

const enhance = withObservables<Props, unknown>(
    ['entry', 'isSyncInProgress'],
    ({ entry }) => {
        const database = Database.getDatabase();

        const logDBActionMock: DBWriterLoggerFunction = () => {
            return;
        };

        const organisationAccountingsService =
            new OrganisationAccountingsService({
                database,
                logDBAction: logDBActionMock,
            });

        const userService = new User({
            database,
            imageService: new FirebaseImagesService(),
            logDBAction: logDBActionMock,
        });

        return {
            entryInvoice: entry.invoice || of(),
            entryHorse: entry.horse || of(),
            entryUser: entry.userId
                ? userService.getById(entry.userId).observe()
                : of([]),
            entryHorseContacts: entry.horseId
                ? entry.horse.observe().pipe(
                      switchMap((entryHorse) => {
                          return (
                              entryHorse?.contacts
                                  .extend(
                                      Q.sortBy('first_name', 'asc'),
                                      Q.sortBy('last_name', 'asc'),
                                  )
                                  .observe() || of()
                          );
                      }),
                  )
                : of([]),
            entryProcedures: entry.entryProcedures || of([]),
            organisationAccountingsData: organisationAccountingsService.get(),
        };
    },
);

export default withSyncContext<Props>(enhance(EntriesTableItem));
