import { AddToCalendarButtonProps } from 'add-to-calendar-button-react/dist/AddToCalendarButton';
import { DateTime } from 'luxon';

type ReturnedProps = Pick<
    AddToCalendarButtonProps,
    'startDate' | 'endDate' | 'timeZone' | 'startTime' | 'endTime'
>;

const TIMEZONE = 'GMT';

export function getAddToCalendarDateProps(
    startDateISO?: string,
    endDateISO?: string,
): ReturnedProps {
    if (!startDateISO) {
        return {
            startDate: DateTime.now().toISODate(),
        };
    }

    const startDate = DateTime.fromISO(startDateISO).toUTC();
    const startTime = startDate.toFormat('HH:mm');

    const startDateISODate = startDate.toISODate();

    if (!endDateISO) {
        return {
            startDate: startDateISODate,
            startTime,
            timeZone: TIMEZONE,
        };
    }

    const endDate = DateTime.fromISO(endDateISO).toUTC();
    const endDateISODate = endDate.toISODate();
    const endTime = endDate.toFormat('HH:mm');

    return {
        startDate: startDateISODate,
        startTime,
        endDate: endDateISODate,
        endTime,
        timeZone: TIMEZONE,
    };
}
