import * as FirebaseAuth from 'firebase/auth';
import { OAuthProvider } from 'firebase/auth';
import * as FirebaseFirestore from 'firebase/firestore';

import { APP_CONFIG } from '@/constants/appConfig';
import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { firebaseApp } from '@/services/firebase';
import { FirebaseFirestoreAPI } from '@/services/firebase/firestore';
import Logger from '@/services/logger';
import { Auth } from '@/types/auth';
import { AuthProvider, AuthProviderScopes } from '@/types/firebase';

const providers = {
    [AuthProvider.Apple]: new OAuthProvider('apple.com'),
    [AuthProvider.Xero]: new OAuthProvider('oidc.xero'),
};

const auth = FirebaseAuth.getAuth(firebaseApp);

if (APP_CONFIG.runFirebaseEmulator && APP_CONFIG.firebaseEmulatorAuthPort) {
    FirebaseAuth.connectAuthEmulator(
        auth,
        `http://localhost:${APP_CONFIG.firebaseEmulatorAuthPort}`,
    );
}

function signInWithProvider(providerName: AuthProvider, email?: string) {
    const provider = providers[providerName];

    if (email) {
        provider.setCustomParameters({
            login_hint: email,
        });
    }

    AuthProviderScopes[providerName].forEach((scope) =>
        provider.addScope(scope),
    );

    return FirebaseAuth.signInWithPopup(auth, provider);
}

function linkProvider(providerName: AuthProvider) {
    if (auth.currentUser) {
        return FirebaseAuth.linkWithPopup(
            auth.currentUser,
            providers[providerName],
        );
    }
}

function signIn(
    payload: Auth.FirebaseAPI.SignInRequestPayload,
): Promise<Auth.FirebaseAPI.SignInResponsePayload> {
    const { email, password } = payload;

    return FirebaseAuth.signInWithEmailAndPassword(auth, email, password);
}

async function signUp(
    payload: Auth.FirebaseAPI.SignUpRequestPayload,
): Promise<Auth.FirebaseAPI.SignUpResponsePayload> {
    const { email, password } = payload;

    const data = await FirebaseAuth.createUserWithEmailAndPassword(
        auth,
        email,
        password,
    );

    return data;
}

function signOut(): Promise<void> {
    return FirebaseAuth.signOut(auth);
}

async function setUserOrganisationId(organisationId: string) {
    const user = auth.currentUser;

    if (user) {
        const { doc, collection, setDoc } = FirebaseFirestore;

        const userRef = doc(
            collection(
                FirebaseFirestoreAPI.firestoreDB,
                FIRESTORE_COLLECTION.users,
            ),
            user.uid,
        );

        await setDoc(userRef, { organisations: [organisationId] });
    }
}

async function getAuthToken(forceRefresh?: boolean) {
    try {
        return auth.currentUser?.getIdToken(forceRefresh);
    } catch (err) {
        Logger.logInfo(`(Firebase) get auth token, ${err}`);
    }
}

async function confirmPasswordReset(token: string, password: string) {
    await FirebaseAuth.confirmPasswordReset(auth, token, password);
}

async function removeAccount() {
    return auth.currentUser?.delete();
}

export const FirebaseAuthAPI = {
    auth,
    signIn,
    signUp,
    signOut,
    setUserOrganisationId,
    getAuthToken,
    confirmPasswordReset,
    signInWithProvider,
    linkProvider,
    removeAccount,
};
