import { SyncStatusType } from 'shared/types/sync';

import { COLOR } from '@/theme/colors';

export const getStatusColor = (status: SyncStatusType) => {
    switch (status) {
        case 'in_progress':
        case 'waiting':
            return COLOR.black60;

        case 'offline':
        case 'error':
            return COLOR.errorRed;

        case 'synced':
        default:
            return COLOR.black23;
    }
};
