import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Box,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    Typography,
} from '@mui/material';
import React, { memo, useCallback, useRef, useState } from 'react';
import { AppAnalytics } from 'shared/analytics/app';

import { InitialsAvatar } from '@/components';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { hasUnsyncedChanges } from '@/services/database/sync';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseAuthAPI } from '@/services/firebase/auth';
import { ServerAuthAPI } from '@/services/networking/auth';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';

import { Props } from './types';

function UserBox({ isLoading, userAvatarUrl, userProfileData }: Props) {
    const { invalidateUserAuth } = useAuthContext();
    const { setIsSyncModalVisible } = useDBSyncContext();
    const userMenuRef = useRef<SVGSVGElement | null>(null);

    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    const openUserMenu = useCallback(() => setIsUserMenuOpen(true), []);

    const closeUserMenu = useCallback(() => setIsUserMenuOpen(false), []);

    const handleSignOutButtonClick = useCallback(async () => {
        closeUserMenu();

        try {
            const isUnsynced = await hasUnsyncedChanges();

            if (isUnsynced) {
                setIsSyncModalVisible(true);
                return;
            }

            await ServerAuthAPI.signOut();
            await FirebaseAuthAPI.signOut();

            invalidateUserAuth();

            AppAnalytics.logUserLoggedOut(FirebaseAnalytics.logEvent);
        } catch (error) {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [closeUserMenu, invalidateUserAuth, setIsSyncModalVisible]);

    if (isLoading || !userProfileData) {
        return (
            <div
                className="h-full cursor-pointer flex items-center mx-3"
                data-test-id="UserBox-Skeletons"
            >
                <Skeleton
                    height={20}
                    sx={{ mr: 1 }}
                    variant="text"
                    width={80}
                />
                <Skeleton height={30} variant="circular" width={30} />
            </div>
        );
    }

    return (
        <Box
            sx={{
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: COLOR.white15,
                },
            }}
            data-test-id="UserBox"
            onClick={openUserMenu}
            ref={userMenuRef}
        >
            <div className="flex h-full items-center mx-8">
                <Typography
                    data-test-id="UserBox-Account-Label"
                    fontWeight={400}
                    sx={{ mr: 2, fontSize: 13, color: COLOR.white }}
                >
                    {t('Account:my_account')}
                </Typography>
                <InitialsAvatar
                    testID="UserBox-Avatar"
                    avatarUrl={userAvatarUrl}
                    size={30}
                    sx={{
                        fontSize: 13,
                        fontWeight: 'normal',
                    }}
                    firstName={userProfileData?.first_name ?? ''}
                    lastName={userProfileData?.last_name ?? ''}
                />
                <ArrowDropDownIcon sx={{ color: COLOR.white }} />
                {isUserMenuOpen ? (
                    <Popper
                        open
                        anchorEl={userMenuRef?.current}
                        placement="bottom"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'bottom-start',
                                }}
                            >
                                <Paper
                                    sx={{
                                        mb: 2,
                                        mt: -1,
                                        width: 142,
                                        boxShadow: '0px 3px 6px #00000029',
                                    }}
                                    data-test-id="UserBox-Menu"
                                >
                                    <ClickAwayListener
                                        onClickAway={closeUserMenu}
                                    >
                                        <MenuList>
                                            <MenuItem
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            COLOR.white,
                                                    },
                                                }}
                                                onClick={
                                                    handleSignOutButtonClick
                                                }
                                                data-test-id="UserBox-SignOutOption"
                                            >
                                                <LogoutIcon
                                                    sx={{
                                                        width: 20,
                                                        height: 18,
                                                        color: COLOR.ebonyClay,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                        color: COLOR.ebonyClay,
                                                        justifySelf: 'center',
                                                    }}
                                                >
                                                    {t('Actions:sign_out')}
                                                </Typography>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                ) : null}
            </div>
        </Box>
    );
}

export default memo(UserBox);
