import { useEffect, useMemo, useState } from 'react';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { ExternalAccountingProviderType } from 'shared/types/Invoicing';

import Logger from '@/services/logger';

import { useDatabase } from './useDatabase';

export function useAccountingProvider() {
    const db = useDatabase();
    const [accountingProvider, setAccountingProvider] =
        useState<ExternalAccountingProviderType | null>(null);

    const database = useMemo(() => db.getDatabase(), [db]);

    const accountingsService = useMemo(
        () =>
            new OrganisationAccountings({
                database,
                logDBAction: Logger.logRecordActivity,
            }),
        [database],
    );
    useEffect(() => {
        const subscription = accountingsService
            .get()
            .observeWithColumns(['accounting_provider'])
            .subscribe(([accounting]) => {
                setAccountingProvider(accounting?.accountingProvider || null);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [accountingsService]);

    return accountingProvider;
}
