import { ProcedureModel } from '../types/Procedures';
import {
    CATEGORIES,
    CATEGORY_DICTIONARY,
    CATEGORY_HUMANIZED,
} from '../constants/procedure/CATEGORIES';
import { EntryModel, EntryProcedureToAdd } from '../types/Entries';
import { EntryProcedureModel } from '../types/EntryProcedure';
import { Identificator } from '../types/Identificator';
import Entry from '../db/services/Entry';
import { DBServiceOptionsWithImages } from '../types/dbService';

export type ProceduresGroup<
    T extends ProcedureModel | EntryProcedureToAdd = ProcedureModel,
> = {
    title: CATEGORY_HUMANIZED;
    procedures: T[];
};

export function getGroupedProcedures<
    T extends ProcedureModel | EntryProcedureToAdd = ProcedureModel,
>(procedures: T[]): ProceduresGroup<T>[] {
    const groupedProcedures: {
        [key: string]: T[];
    } = {};
    CATEGORIES.forEach((category) => (groupedProcedures[category] = []));

    procedures.forEach((procedure) => {
        const procedureCategory =
            CATEGORY_HUMANIZED[
                procedure?.category ?? CATEGORY_DICTIONARY.Other
            ];

        groupedProcedures[procedureCategory].push(procedure);
    });

    return Object.keys(groupedProcedures).map((category) => ({
        title: category as CATEGORY_HUMANIZED,
        procedures: groupedProcedures[category],
    }));
}

export function mapWatermelonDBProceduresObjectsToRawObjects(
    entryProcedure: EntryProcedureModel,
): EntryProcedureToAdd {
    return {
        name: entryProcedure.name,
        price: entryProcedure.price,
        procedureId: entryProcedure.procedureId,
        quantity: entryProcedure.quantity,
    };
}

export const getProceduresFromEntries = async (entries: EntryModel[]) => {
    let procedures: EntryProcedureModel[] = [];

    for (let entry of entries) {
        const entryProcedures = await entry.entryProcedures.fetch();

        procedures = procedures.concat(entryProcedures);
    }

    return procedures;
};

export const getProceduresFromEntriesIds = async ({
    entriesIds,
    options,
}: {
    entriesIds: Identificator[];
    options: DBServiceOptionsWithImages;
}) => {
    const entryService = new Entry({
        database: options.database,
        imageService: options.imageService,
        logDBAction: options.logDBAction,
    });

    let procedures: EntryProcedureModel[] = [];

    for (let entryId of entriesIds) {
        const entry = await entryService.getByID(entryId.id);
        const entryProcedures = await entry.entryProcedures.fetch();

        procedures = procedures.concat(entryProcedures);
    }

    return procedures;
};
