import { FreshchatUser } from 'shared/types/freshchat';

import Logger from '@/services/logger';

export const Freshchat = {
    setUser: (userId: string, user: FreshchatUser) => {
        window.fcWidget.user.clear();
        window.fcWidget.user.setProperties({});
        window.fcWidget.setExternalId(userId);
        window.fcWidget.user.setProperties(user);
    },
    clearUser: async (): Promise<any> => {
        if (await window.fcWidget.user.isExists()) {
            try {
                return window.fcWidget.user.clear();
            } catch (error) {
                Logger.logError('error clearing freshchat user');
                return null;
            }
        }
        window.fcWidget.user.setProperties({});
    },
    onUserCreated: (callback: (restoreId: string) => void) => {
        window.fcWidget.on('user:created', (response) => {
            const status = response && response.status;
            const data = response && response.data;
            if (status === 200) {
                if (data?.restoreId) {
                    callback(data.restoreId);
                }
            }
        });
    },
    identifyUser: (externalId: string, restoreId: string) => {
        window.fcWidgetMessengerConfig = {
            externalId,
            restoreId,
        };
    },
    isInitialized: () => window.fcWidget && window.fcWidget.isInitialized(),
    destroyWidget: () => {
        window.fcWidget.destroy();
        window.fcWidgetMessengerConfig = null;
        window.fcSettings = null;
    },
    onWidgetLoaded: (callback: VoidFunction) =>
        window.fcWidget.on('widget:loaded', () => {
            callback();
        }),
    onWidgetDestroyed: (callback: VoidFunction) =>
        window.fcWidget.on('widget:destroyed', () => {
            callback();
        }),
    onInit: (callback: VoidFunction) =>
        (window.fcSettings = {
            onInit: callback,
        }),
};
