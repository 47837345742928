import { DateTime } from 'luxon';

import { RangeTypes } from '../types/dates';
import moment from '../utils/moment';
import { Moment } from 'moment-timezone';

type DateDiffOptions = {
    measurementUnit?: any;
    difference?: any;
};

export const getLocal = (): DateTime => {
    return DateTime.local();
};

export const getNowISO = () => {
    return getLocal().toISO();
};

export const getFromISO = (date: string): DateTime => {
    return DateTime.fromISO(date);
};

export const getFromMillis = (millis: number): DateTime => {
    return DateTime.fromMillis(millis);
};

export const getCurrentTimestamp = (): number => new Date().getTime();

export const isDiffAtLeast = (
    startTime: number,
    endTime: number,
    options: DateDiffOptions,
): boolean => {
    try {
        const { measurementUnit = 'minutes', difference = 30 } = options;
        const start = getFromMillis(startTime);
        const end = getFromMillis(endTime);

        return (
            Math.floor(end.diff(start, measurementUnit).as(measurementUnit)) >=
            difference
        );
    } catch {
        return false;
    }
};

export const isFuture = (date, difference = 0) => {
    const now = getCurrentTimestamp();
    const formattedDate = getFromISO(date).toMillis();

    return isDiffAtLeast(now, formattedDate, {
        measurementUnit: 'days',
        difference,
    });
};

export const isInThePast = (
    startingDate: Moment,
    unit: moment.unitOfTime.StartOf = 'day',
) => {
    return moment().isAfter(startingDate, unit);
};

export const diffBetweenDatesByRange = (
    fromDate: string | moment.Moment,
    toDate: string | moment.Moment,
    range: RangeTypes,
    withDecimalPlaces = false,
) => {
    const from = moment(fromDate);
    const to = moment(toDate);

    return from.diff(to, range, withDecimalPlaces);
};

export const getLocaleCodeByLanguage = (lang: string) => {
    switch (lang) {
        case 'en-US':
            // there is no en-us in moment library but it can be repalced with en-ca
            return 'en-ca';
        case 'en-AU':
            return 'en-au';
        case 'nl-NL':
            return 'nl';

        default:
            return 'en-au';
    }
};

export const getLocaleCodeByRegion = (region: string) => {
    switch (region) {
        case 'US':
            // there is no en-us in moment library but it can be repalced with en-ca
            return 'en-ca';
        case 'AU':
            return 'en-au';
        case 'NL':
            return 'nl';
        case 'NZ':
            return 'en-nz';

        default:
            return 'en-au';
    }
};

const getStartingDayOfWeek = (regionLocale: string) => {
    // Someone mistakenly thought that moment doesn't have US locale and decided to use Canadian locale instead
    if (regionLocale === 'en-ca') {
        return 0;
    }

    return 1;
};
export const setMomentLocale = (language: string, regionCode?: string) => {
    const languageLocale = getLocaleCodeByLanguage(language);
    const regionLocale = getLocaleCodeByRegion(regionCode || 'AU');

    const localeData = moment.localeData(regionLocale);
    const week: moment.WeekSpec =
        languageLocale === 'en-au'
            ? { dow: 1, doy: 4 }
            : {
                  dow: getStartingDayOfWeek(languageLocale),
                  doy: localeData.firstDayOfYear(),
              };

    // cannot use moment.updateLocale() becuase it leads to bugs with localeData() used before
    // moment.updateLocale() updates default values of locale data and we cannot fetch them anymore
    moment.defineLocale(`${languageLocale}${regionLocale}`, {
        parentLocale: languageLocale,
        week,
    });
};

export const humanizeDateDiff = (
    date: Date | string | number,
    withoutSuffix?: boolean,
) => {
    const diff = moment(date).fromNow(withoutSuffix);

    return diff;
};
